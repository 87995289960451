export enum PMS_APPOINTMENTS {
    GET_PMS_APPOINTMENTS = 'GET_PMS_APPOINTMENTS',
    GET_PMS_APPOINTMENTS_ERROR = 'GET_PMS_APPOINTMENTS_ERROR',
    GET_PMS_APPOINTMENTS_SUCCESS = 'GET_PMS_APPOINTMENTS_SUCCESS',
    BOOK_PMS_APPOINTMENT = 'BOOK_PMS_APPOINTMENT',
    BOOK_PMS_APPOINTMENT_FAILURE = 'BOOK_PMS_APPOINTMENT_FAILURE',
    BOOK_PMS_APPOINTMENT_SUCCESS = 'BOOK_PMS_APPOINTMENT_SUCCESS',
    RESCHEDULE_PMS_APPOINTMENT = 'RESCHEDULE_PMS_APPOINTMENT',
    RESCHEDULE_PMS_APPOINTMENT_FAILURE = 'RESCHEDULE_PMS_APPOINTMENT_FAILURE',
    RESCHEDULE_PMS_APPOINTMENT_SUCCESS = 'RESCHEDULE_PMS_APPOINTMENT_SUCCESS',
    PMS_XCARE_APPOINTMENT_LINK = 'PMS_XCARE_APPOINTMENT_LINK',
    PMS_XCARE_APPOINTMENT_LINK_FAILURE = 'PMS_XCARE_APPOINTMENT_LINK_FAILURE',
    PMS_XCARE_APPOINTMENT_LINK_SUCCESS = 'PMS_XCARE_APPOINTMENT_LINK_SUCCESS'
}
export interface GetPMSAppointmentsRequest {
    clinicUniqueId: string;
    page: number;
    results: number;
    firstname?: string;
    lastname?: string;
    view?: string;
    date?: string;
    from?: string;
    to?: string;
}
export interface PMSAppointmentsItem {
    id: string;
    srNo: string;
    date: string;
    time: string;
    status: string;
    schedule: string;
    cancelled: string;
    selectedProvider: string;
    selectedPotentialAppointmentType: string;
    isEdit: boolean;
    relation: string;
    dependantName: string;
    dependantRelation: string;
    edtDate: string;
    edtTime: string;
    edtMobile: string;
    edtEmail: string;
    isEmailShow: boolean;
    isPhoneShow: boolean;
    isCheckedForVerify: boolean;
    providerName?: string;
    potentialAppointmentType?: string;
    changed: string;
    xcareId: string;
    error: any;
    errorType: any;
    patient: {
        id: string;
        sikkaId: string;
        lname: string;
        fname: string;
        email: string;
        phone: string;
    },
    guarantor: {
        id: string;
        sikkaId: string;
        fname: string;
        lname: string;
        email: string;
        cell: string;
    },
    provider: {
        id: string;
        fname: string;
        lname: string;
        sikkaId: string;
        type: string;
    },
    doctor: {
        id: string;
        uniqueid: string;
        fullname: string;
        providerid: string;
    },
    potentialAppointmentTypes: {
        id: string;
        name: string;
        duration: string;
        price: string;
        speciality: string;
        specialityId: string;
    }[];
    xcareAppointments: any[] | null;
}
export interface GetPMSAppointmentsResponse {
    appointments: PMSAppointmentsItem[];
    total: string;
    status: {
        error: boolean;
        msg: string;
    }
}
export interface BookPMSAppointmentItem {
    clinic: any;
    request: any;
    patient: any;
    appointmenttype: any;
    dateTime: any;
    isNewPatient: boolean;
    pmsAppointment: { id: any; }
}
export interface BookPMSAppointmentRequest {
    data: BookPMSAppointmentItem[];
    clinicUniqueId: string;
}
export interface BookPMSAppointmentResponseItem {
    appointment: number | null;
    patient: string | null;
}
export interface BookPMSAppointmentResponse {
    appointments: BookPMSAppointmentResponseItem[];
    requestData: BookPMSAppointmentItem[];
    status: { error: boolean; msg: string };
}
export interface ReschedulePMSAppointmentRequest {
    clinicUniqueId: string;
    pmsAppointmentId: string;
}
export interface ReschedulePMSAppointmentResponse {
    status: {
        error: boolean;
        msg: string;
    };
}
export interface PMSXcareAppointmentRequest {
    clinicUniqueId: string;
    pmsId: string;
    appointmentId: string;
    dateTime: any;
}
export interface PMSXcareAppointmentResponse {
    status: { error: boolean; msg: string; };
}
export interface PMSAppointmentState {
    pending: boolean;
    error: any;
    getPMSAppointmentsRequest?: GetPMSAppointmentsRequest;
    getPMSAppointmentsResponse?: GetPMSAppointmentsResponse;
    bookPMSAppointmentRequest?: BookPMSAppointmentRequest;
    bookPMSAppointmentResponse?: BookPMSAppointmentResponse;
    rescheduelPMSAppointmentRequest?: ReschedulePMSAppointmentRequest;
    rescheduelPMSAppointmentResponse?: ReschedulePMSAppointmentResponse;
    pmsxcareAppointmentLinkRequest?: PMSXcareAppointmentRequest;
    pmsxcareAppointmentLinkResponse?: PMSXcareAppointmentResponse;
}
export interface GetPMSAppointmentPendingAction {
    type: PMS_APPOINTMENTS.GET_PMS_APPOINTMENTS;
    payload: GetPMSAppointmentsRequest;
}
export interface GetPMSAppointmentsErrorAction {
    type: PMS_APPOINTMENTS.GET_PMS_APPOINTMENTS_ERROR;
    payload: any;
}
export interface GetPMSPendingSuccessAction {
    type: PMS_APPOINTMENTS.GET_PMS_APPOINTMENTS_SUCCESS;
    payload: GetPMSAppointmentsResponse;
}
export interface BookPMSAppointmentPendingAction {
    type: PMS_APPOINTMENTS.BOOK_PMS_APPOINTMENT;
    payload: BookPMSAppointmentRequest;
}
export interface BookPMSAppointmentSuccessAction {
    type: PMS_APPOINTMENTS.BOOK_PMS_APPOINTMENT_SUCCESS;
    payload: BookPMSAppointmentResponse;
}
export interface BookPMSAppointmentFailureAction {
    type: PMS_APPOINTMENTS.BOOK_PMS_APPOINTMENT_FAILURE;
    payload: any;
}
export interface ReschedulePMSAppointmentPendingAction {
    type: PMS_APPOINTMENTS.RESCHEDULE_PMS_APPOINTMENT;
    payload: ReschedulePMSAppointmentRequest;
}
export interface ReschedulePMSAppointmentFailureAction {
    type: PMS_APPOINTMENTS.RESCHEDULE_PMS_APPOINTMENT_FAILURE;
    payload: any;
}
export interface ReschedulePMSAppointmentSuccessAction {
    type: PMS_APPOINTMENTS.RESCHEDULE_PMS_APPOINTMENT_SUCCESS;
    payload: ReschedulePMSAppointmentResponse;
}
export interface PMSXcareAppointmentLinkPendingAction {
    type: PMS_APPOINTMENTS.PMS_XCARE_APPOINTMENT_LINK;
    payload: PMSXcareAppointmentRequest;
}
export interface PMSXcareAppointmentLinkFailureAction {
    type: PMS_APPOINTMENTS.PMS_XCARE_APPOINTMENT_LINK_FAILURE;
    payload: any;
}
export interface PMSXcareAppointmentLinkSuccessAction {
    type: PMS_APPOINTMENTS.PMS_XCARE_APPOINTMENT_LINK_SUCCESS;
    payload: PMSXcareAppointmentResponse;
}
export type PMSAppointmentTypes = GetPMSAppointmentsErrorAction | GetPMSAppointmentPendingAction | GetPMSPendingSuccessAction |
    BookPMSAppointmentFailureAction | BookPMSAppointmentPendingAction | BookPMSAppointmentSuccessAction |
    ReschedulePMSAppointmentSuccessAction | ReschedulePMSAppointmentFailureAction | ReschedulePMSAppointmentPendingAction |
    PMSXcareAppointmentLinkPendingAction | PMSXcareAppointmentLinkFailureAction | PMSXcareAppointmentLinkSuccessAction;