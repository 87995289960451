import React, { useState } from 'react'
import { Modal, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { fetchLoadingPending, fetchLoadingSuccess } from '../../../store/loadingIndicator/actions';
import { createDocument, uploadPDF } from '../../services/messageCenter';

export const DigitalDocDocumentModel = ({ hideModal, clinicId, fetchList }: any) => {
    const { register, handleSubmit, watch, errors, control, setValue, getValues, setError } = useForm();
    const [isLoading, setIsLoading] = useState(false);
    const [documentpath, setDocumentpath] = useState('');
    const [isupload, setIsUpload] = useState(false);
    const [url, setUrl] = useState('');

    const dispatch = useDispatch();
    const onSubmit = (data: any) => {
        const requestData = {
            clinicid: clinicId,
            documenttype: 'digitaldoc',
            documentname: data.documentName,
            documentpath: (!watch('documentUrl') || watch('documentUrl')?.trim() === '') ? documentpath: data.documentUrl,
            isDocument: (!watch('documentUrl') || watch('documentUrl')?.trim() === '') ? true : false
        };
        dispatch(fetchLoadingPending());
        createDocument(requestData).then((success) => {
            dispatch(fetchLoadingSuccess(false));
            if (success && success.error === false) {
                toast.success('create document successfully.');
                fetchList();
                hideModal();
            }
        }).catch((err) => {
            dispatch(fetchLoadingSuccess(false));
            console.log(err);
        })
    }
    const onDocumentChange = (event: any) => {
        const file = event.target.files[0];
        if (!file) {
            setError('document', { type: 'filerequired', message: 'document is required.' });
            setValue('document', null);
        } else {
            if (file.type === "application/pdf") {
                if (file.size > 5120000) {
                    setError('document', {
                        type: 'documentsize', message: 'Maximum file size should be 5 MB'
                    });
                    setValue('document', null);
                } else {
                    const files = event.target.files;
                    uploadPDF({ upload: event.target.files[0] }).then((success) => {
                        if (success && success.error === false) {
                            setValue('document', files);
                            setDocumentpath(success.data);
                            setIsUpload(true);
                            setError('document', {});
                        }
                    }).catch((err) => {
                        setError('document', {
                            type: 'documenttype', message: 'document upload failed.'
                        });
                        setValue('document', null);
                    })
                }
            } else {
                setError('document', {
                    type: 'documenttype', message: 'File extension is not valid, allowed pdf'
                });
                setValue('logo', null);
            }
        }
    };
    return (
        <Modal className="noti-popups-sec" show={true} onHide={hideModal} centered>
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className="modal d-block  position-relative">
                    <div className="modal-header">
                        <h5 className="modal-title h4">
                            Upload Digital Form
                        </h5>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={hideModal}></button>
                    </div>
                    <div className="modal-body">
                        <div className="row mb-4">
                            <label>Select Document Type</label>
                            <div className="col-12">
                                <label htmlFor="uploadradio" className="me-1">Document Upload</label>
                                <input id="uploadradio" type="radio" name="documenttype" value="upload" className="me-2" ref={register({
                                    required: true,
                                })}></input>
                                <label htmlFor="urlradio" className="me-2">Document Link</label>
                                <input id="urlradio" type="radio" name="documenttype" value="url" ref={register({
                                    required: true,
                                })}></input>
                            </div>
                            {
                                (errors && errors.documenttype && errors.documenttype.type === "required") &&
                                < div className="is-invalid">Document type is required</div>
                            }
                        </div>
                        <div className="row mb-4">
                            <div className="col-6">
                                <label htmlFor="documentName" className="form-label fw-bold">Document Name</label>
                                <input type="text" className="form-control" id="documentName" name="documentName" placeholder="Document Name" ref={register({
                                    required: true,
                                })} />
                                {
                                    (errors && errors.documentName && errors.documentName.type === "required") &&
                                    < div className="is-invalid">document name is required</div>
                                }
                            </div>
                            <div className={"col-6 " + (watch('documenttype') === 'upload' ? '' : 'd-none')}>
                                <div className="upload-box">
                                    <label className="fw-bold form-label d-block" htmlFor="exampleFormControlUpload">Upload File</label>
                                    <div className="">
                                        <input ref={register({
                                            required: (watch('documenttype') === 'upload') ? true : false,
                                        })} className="btn btn-upload" type="file" name="document" onChange={onDocumentChange} />
                                        <small className="text-muted mt-1 d-block">Max file size should be 5 MB</small>
                                        {
                                            errors && errors.document && errors.document.type === "documentsize" &&
                                            <div className="is-invalid">{errors.document.message}</div>
                                        }
                                        {
                                            errors && errors.document && errors.document.type === "documenttype" &&
                                            <div className="is-invalid">{errors.document.message}</div>
                                        }
                                        {
                                            errors && errors.document && errors.document.type === "filerequired" &&
                                            <div className="is-invalid">{errors.document.message}</div>
                                        }
                                        {
                                            documentpath !== '' &&
                                            <div className="is-success">document uploaded successfully</div>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={'row mb-4 ' + (watch('documenttype') === 'url' ? '' : 'd-none')}>
                            <div className='col-12'>
                                <label htmlFor="documentUrl" className="form-label fw-bold">Document Link</label>
                                <input type="text" className="form-control" id="documentUrl" name="documentUrl" placeholder="Document Link" ref={register({
                                    required: (watch('documenttype') === 'url') ? true : false,
                                })} />
                            </div>
                        </div>

                    </div>
                    <div className="modal-footer">
                        <button type="button" disabled={isLoading} className="btn btn-border" data-bs-dismiss="modal" onClick={() => hideModal()}>Cancel</button>
                        <button disabled={isLoading} type="submit" className="btn btn-primary">
                            {
                                isLoading &&
                                <span className="spinner-border spinner-border-sm me-1" role="status" aria-hidden="true"></span>
                            }
                            Submit
                        </button>
                    </div>
                </div>
            </form>
        </Modal>
    )
}