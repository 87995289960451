import React, { Component } from 'react';
import { billInfoCardList, deleteBillInfo, getState, getcity, updateBillInfo, getCountry, billInfoStatusChange, createBillInfo } from '../../services/subscription';
import { connect } from 'react-redux';
import { IAppState } from '../../../store';
import { toast } from 'react-toastify';
import { fetchLoadingPending, fetchLoadingSuccess } from '../../../store/loadingIndicator/actions';
import { Alert, Col, Form, Button, Modal } from 'react-bootstrap';
import { allowOnlyAlpha, allowOnlyDigit, alphanumericAndSpace } from '../../../utils/validators';
import { UserDataResponse } from '../../../store/userData/types';
import { fetchBillingPendingAction } from '../../../store/billing/actions';

interface Props {
    userDataResponse: UserDataResponse;
    loadingStart: typeof fetchLoadingPending;
    loadingEnd: typeof fetchLoadingSuccess;
}
interface State {
    billingList: any;
    id: any;
    name: any;
    address1: any;
    address2: any;
    city: any;
    zipCode: any;
    state: any;
    country: any;
    isNoRecord: boolean;
    countryList: any;
    stateList: any;
    cityList: any;
    isEdit: boolean;
    selectedBillInfo: boolean;
    isAddAddress: boolean;
}
export class BillingAddress extends Component<Props, State> {
    flag: any = 1;
    constructor(props: any) {
        super(props);
        this.state = {
            billingList: [],
            id: '', isEdit: false,
            name: '', address1: '', address2: '', city: '', zipCode: '', state: '', country: '', selectedBillInfo: false,
            countryList: [],
            stateList: [],
            cityList: [],
            isNoRecord: false, isAddAddress: false
        }
    }
    componentDidMount() {
        if (this.props.userDataResponse && this.props.userDataResponse.clinics && this.props.userDataResponse.clinics.length > 0 &&
            this.props.userDataResponse.clinics[0]) {
            this.fetchBillingInfoList();
        }
    }
    shouldComponentUpdate(prevProps: Props, prevState: State) {
        if (this.props.userDataResponse && this.props.userDataResponse.clinics && this.props.userDataResponse.clinics.length > 0) {
            if (this.flag === 1) {
                this.flag = 2;
                this.fetchBillingInfoList();
            }
        }
        return true;
    }
    fetchBillingInfoList = () => {
        this.props.loadingStart();
        let clinicUniqueId = this.props.userDataResponse.clinics[0].uniqueId;
        billInfoCardList(clinicUniqueId).then((success: any) => {
            if (success && success.response && success.response.data && success.response.status === 200) {
                if (success.response.data && success.response.data.status.error === false) {
                    this.setState({ billingList: success.response.data.billingData });
                    this.props.loadingEnd(false);
                } else {
                    // Error Msg
                    if (success.response.data && success.response.data.status.error === true) {
                        toast.error(success.response.data.status.msg)
                    }
                    this.props.loadingEnd(false);
                }
            }
        }).catch((err) => {
            this.props.loadingEnd(false);
            return '';
        });
    }
    validationForm = () => {
        if (this.state.name === '') {
            toast.warn('Please enter name');
            return true;
        }
        if (this.state.address1 === '') {
            toast.warn('Please enter address line 1');
            return true;
        }
        if (this.state.city === '') {
            toast.warn('Please enter city');
            return true;
        }
        if (this.state.zipCode === '') {
            toast.warn('Please enter postal code');
            return true;
        }
        if (this.state.state === '') {
            toast.warn('Please enter state');
            return true;
        }
        if (this.state.country === '') {
            toast.warn('Please enter country');
            return true;
        }
        return false;
    }
    clickEditButton = (value: any) => {
        this.setState({
            isEdit: true, id: value.id, name: value.name, address1: value.addressLine1,
            address2: value.addressLine2, city: value.city, state: value.state, zipCode: value.zipCode,
            country: value.country, selectedBillInfo: value.default_status === "active" ? true : false
        }, () => {
            this.getCountry();
            this.getState();
            this.getCity();
        });
    }
    updateBillInfoApi = () => {
        let isValid: boolean = false;
        isValid = this.validationForm();
        if (isValid) {
            return;
        }
        this.props.loadingStart();
        let data = {
            "id": this.state.id,
            "name": this.state.name,
            "addressLine1": this.state.address1,
            "addressLine2": this.state.address2 ? this.state.address2 : '',
            "city": this.state.city,
            "zipCode": this.state.zipCode,
            "state": this.state.state,
            "country": this.state.country,
            "default": this.state.selectedBillInfo
        }
        let clinicUniqueId = this.props.userDataResponse.clinics[0].uniqueId;
        updateBillInfo(clinicUniqueId, data).then((success: any) => {
            if (success && success.response && success.response.data && success.response.status === 200) {
                if (success.response.data && success.response.data.status.error === false) {
                    toast.success(success.response.data.status.msg);
                    this.fetchBillingInfoList()
                    this.setState({ isEdit: false, name: '', address1: '', address2: '', city: '', zipCode: '', state: '', country: '', selectedBillInfo: false });
                } else {
                    // Error Msg
                    if (success.response.data && success.response.data.status.error === true) {
                        toast.error(success.response.data.status.msg)
                    }
                    this.props.loadingEnd(false);
                }
            }
        }).catch((err) => {
            this.props.loadingEnd(false);
            return '';
        });
    }
    hideModal = () => {
        this.setState({ isEdit: false, isAddAddress: false, address1: '', address2: '', name: '', country: '', state: '', city: '', zipCode: '', selectedBillInfo: false });
    }
    selectCountry = (Id: any) => {
        this.setState({ country: Id }, () => {
            this.getState()
        });
    }
    selectState = (Id: any) => {
        this.setState({ state: Id }, () => {
            this.getCity()
        });
    }
    getCountry = () => {
        let clinicUniqueId = this.props.userDataResponse.clinics[0].uniqueId;
        this.props.loadingStart();
        getCountry(clinicUniqueId).then((success: any) => {
            if (success && success.response && success.response.data && success.response.status === 200) {
                if (success.response.data && success.response.data.status.error === false) {
                    this.setState({
                        countryList: success.response.data.countryData,
                        // state:'',stateList:'',city:'',cityList:''
                    });
                    // this.getState();
                    this.props.loadingEnd(false);
                } else {
                    // Error Msg
                    if (success.response.data && success.response.data.status.error === true) {
                        toast.error(success.response.data.status.msg)
                    }
                    this.props.loadingEnd(false);
                }
            }
        }).catch((err) => {
            this.props.loadingEnd(false);
            return '';
        });
    }
    getState = () => {
        let clinicUniqueId = this.props.userDataResponse.clinics[0].uniqueId;
        let data = {
            "countryId": this.state.country
        }
        this.props.loadingStart();
        getState(clinicUniqueId, data).then((success: any) => {
            if (success && success.response && success.response.data && success.response.status === 200) {
                if (success.response.data && success.response.data.status.error === false) {
                    this.setState({
                        stateList: success.response.data.stateData,
                        // cityList:'',city:''
                    });
                    // this.getCity();
                    this.props.loadingEnd(false);
                } else {
                    // Error Msg
                    if (success.response.data && success.response.data.status.error === true) {
                        toast.error(success.response.data.status.msg)
                    }
                    this.props.loadingEnd(false);
                }
            }
        }).catch((err) => {
            this.props.loadingEnd(false);
            return '';
        });
    }
    getCity = () => {
        let clinicUniqueId = this.props.userDataResponse.clinics[0].uniqueId;
        let data = {
            "countryId": this.state.country,
            "stateId": this.state.state
        }
        this.props.loadingStart();
        getcity(clinicUniqueId, data).then((success: any) => {
            if (success && success.response && success.response.data && success.response.status === 200) {
                if (success.response.data && success.response.data.status.error === false) {
                    this.setState({
                        cityList: success.response.data.cityData
                    });
                    this.props.loadingEnd(false);
                } else {
                    // Error Msg
                    if (success.response.data && success.response.data.status.error === true) {
                        toast.error(success.response.data.status.msg)
                    }
                    this.props.loadingEnd(false);
                }
            } else {
                this.props.loadingEnd(false);
            }
        }).catch((err) => {
            this.props.loadingEnd(false);
            return '';
        });
    }
    deleteBillInfo = (value: any) => {
        let data = {
            "billingAddressId": value.id,
        }
        let clinicUniqueId = this.props.userDataResponse.clinics[0].uniqueId;
        deleteBillInfo(clinicUniqueId, data).then((success: any) => {
            if (success && success.response && success.response.data && success.response.status === 200) {
                if (success.response.data && success.response.data.status.error === false) {
                    toast.success(success.response.data.status.msg);
                    this.fetchBillingInfoList()
                    this.setState({ isEdit: false, name: '', address1: '', address2: '', city: '', zipCode: '', state: '', country: '', selectedBillInfo: false });
                } else {
                    // Error Msg
                    if (success.response.data && success.response.data.status.error === true) {
                        toast.error(success.response.data.status.msg)
                    }
                    this.props.loadingEnd(false);
                }
            }
        }).catch((err) => {
            this.props.loadingEnd(false);
            return '';
        });
    }
    billInfoChangeStatus = (value: any) => {
        this.props.loadingStart();
        let data = {
            "billingAddressId": value.id,
        }
        let clinicUniqueId = this.props.userDataResponse.clinics[0].uniqueId;
        billInfoStatusChange(clinicUniqueId, data).then((success: any) => {
            if (success && success.response && success.response.data && success.response.status === 200) {
                if (success.response.data && success.response.data.status.error === false) {
                    toast.success(success.response.data.status.msg);
                    this.fetchBillingInfoList()
                } else {
                    // Error Msg
                    if (success.response.data && success.response.data.status.error === true) {
                        toast.error(success.response.data.status.msg)
                    }
                    this.props.loadingEnd(false);
                }
            }
        }).catch((err) => {
            this.props.loadingEnd(false);
            return '';
        });
    }
    createBillInfo = () => {
        let isValid: boolean = false;
        isValid = this.validationForm();
        if (isValid) {
            return;
        }
        this.props.loadingStart();
        let data = {
            "name": this.state.name,
            "addressLine1": this.state.address1,
            "addressLine2": this.state.address2 ? this.state.address2 : '',
            "city": this.state.city,
            "zipCode": this.state.zipCode,
            "state": this.state.state,
            "country": this.state.country,
            "default": this.state.selectedBillInfo
        }
        let clinicUniqueId = this.props.userDataResponse.clinics[0].uniqueId;
        createBillInfo(clinicUniqueId, data).then((success: any) => {
            if (success && success.response && success.response.data && success.response.status === 200) {
                if (success.response.data && success.response.data.status.error === false) {
                    toast.success(success.response.data.status.msg);
                    this.fetchBillingInfoList()
                    this.setState({ name: '', address1: '', address2: '', city: '', zipCode: '', state: '', country: '', selectedBillInfo: false, isAddAddress: false });
                } else {
                    // Error Msg
                    if (success.response.data && success.response.data.status.error === true) {
                        toast.error(success.response.data.status.msg)
                    }
                    this.props.loadingEnd(false);
                }
            }
        }).catch((err) => {
            this.props.loadingEnd(false);
            return '';
        });
    }
    handleIsAddAddress = () => {
        this.getCountry();
        this.setState({ isAddAddress: true });

    }
    handleCancel = () => {
        this.setState({ isAddAddress: false, address1: '', address2: '', name: '', country: '', state: '', city: '', zipCode: '', selectedBillInfo: false })
    }
    render() {
        return (
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-8 mt-4">
                        <div className="d-flex align-items-center justify-content-between">
                            <div className="fs-6 fw-medium">Saved address</div>
                            <button onClick={() => { this.handleIsAddAddress() }} className="btn btn-sm p-0">+ Add address</button>
                        </div>
                        {
                            (this.state.isAddAddress) &&
                            <div className="default-card card my-4 form-card">
                                <h5 className="card-header fs-5 fw-medium px-4 py-3">Billing information</h5>
                                <div className="card-body px-4 py-3">
                                    {/* <form className="needs-validation mx-3"> */}
                                    <div className="row bill-info-sec">
                                        <div className="col-12 mb-3">
                                            <label className="form-label">Name</label>
                                            <input type="text" className="form-control" placeholder="Name" value={this.state.name}
                                                onChange={(e) => {
                                                    if (allowOnlyAlpha(e.target.value)) {
                                                        this.setState({ name: e.target.value })
                                                    } else if (e.target.value === "") {
                                                        this.setState({ name: "" })
                                                    }
                                                }} />
                                            <div className="invalid-feedback">
                                                Required.
                                            </div>
                                        </div>
                                        <div className="col-12 mb-3">
                                            <label className="form-label">Address line 1</label>
                                            <input type="text" className="form-control" placeholder="AddressLine1" value={this.state.address1} onChange={(e) => { this.setState({ address1: e.target.value }) }} />
                                            <div className="invalid-feedback">
                                                Required.
                                            </div>
                                        </div>
                                        <div className="col-12 mb-3">
                                            <label className="form-label">Address line 2 (optional)</label>
                                            <input type="text" className="form-control" value={this.state.address2} placeholder="" onChange={(e) => { this.setState({ address2: e.target.value }) }} />
                                        </div>
                                        <div className="col-md-6 mb-3">
                                            <label className="form-label">Country</label>
                                            {/* <input type="text" className="form-control" value={this.state.city} placeholder="" onChange={(e) => { this.setState({ city: e.target.value }) }} /> */}
                                            {
                                                <select className="form-select" value={this.state.country} onChange={(e) => {
                                                    // this.setState({
                                                    //     country: e.target.value
                                                    // });
                                                    this.selectCountry(e.target.value);
                                                }}>
                                                    <option value={'Select'}>{'Select Country'}</option>
                                                    {
                                                        this.state.countryList && this.state.countryList.length > 0 &&
                                                        this.state.countryList.map((value: any) => {
                                                            return (
                                                                <option value={value.id}>{value.name}</option>
                                                            )
                                                        })
                                                    }
                                                </select>
                                            }
                                        </div>
                                        <div className="col-md-6 mb-3">
                                            <label className="form-label">State</label>
                                            {/* <input type="text" className="form-control" value={this.state.state} placeholder="" onChange={(e) => { this.setState({ state: e.target.value }) }} /> */}
                                            {
                                                <select className="form-select" value={this.state.state} onChange={(e) => {
                                                    // this.setState({
                                                    //     state: e.target.value
                                                    // });
                                                    this.selectState(e.target.value);
                                                }}>
                                                    <option value={'Select State'}>{'Select State'}</option>
                                                    {
                                                        this.state.stateList && this.state.stateList.length > 0 &&
                                                        this.state.stateList.map((value: any) => {
                                                            return (
                                                                <option value={value.id}>{value.name}</option>
                                                            )
                                                        })
                                                    }
                                                </select>
                                            }
                                        </div>
                                        <div className="col-md-6 mb-3">
                                            <label className="form-label">City</label>
                                            {/* <input type="text" className="form-control" value={this.state.country} placeholder="" onChange={(e) => { this.setState({ country: e.target.value }) }} /> */}
                                            {
                                                <select className="form-select" value={this.state.city} onChange={(e) => {
                                                    this.setState({
                                                        city: e.target.value
                                                    });
                                                }}>
                                                    <option value={'Select City'}>{'Select City'}</option>
                                                    {
                                                        this.state.cityList && this.state.cityList.length > 0 &&
                                                        this.state.cityList.map((value: any) => {
                                                            return (
                                                                <option value={value.id}>{value.name}</option>
                                                            )
                                                        })
                                                    }
                                                </select>
                                            }
                                        </div>
                                        <div className="col-md-6 mb-3">
                                            <label className="form-label">Zip code</label>
                                            <input maxLength={7} type="text" className="form-control" value={this.state.zipCode} placeholder=""
                                                onChange={(e) => {
                                                    if (alphanumericAndSpace(e.target.value)) {
                                                        this.setState({ zipCode: e.target.value })
                                                    } else if (e.target.value === "") {
                                                        this.setState({ zipCode: "" })
                                                    }
                                                    // this.setState({zipCode:e.target.value})

                                                }} />
                                        </div>
                                        <div className="col-12 mb-3">
                                            <div className="form-check">
                                                <input type="checkbox" className="form-check-input" id="saveBill1" checked={this.state.selectedBillInfo === true} onChange={(e) => { this.setState({ selectedBillInfo: !this.state.selectedBillInfo }) }} />
                                                <label className="form-check-label" htmlFor="saveBill1">Save this as a default billing address </label>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12">
                                        <button onClick={() => { this.handleCancel() }} className="btn btn-secondary"><span role="status" aria-hidden="true"></span> Cancel</button> &nbsp;
                                        <button onClick={() => { this.createBillInfo() }} className="btn btn-secondary">
                                            {/* <span className="spinner-border spinner-border-sm me-1" role="status" aria-hidden="true"></span> */}
                                            Save and continue</button>
                                    </div>
                                    {/* </form> */}
                                </div>
                            </div>
                        }
                        {
                            (this.state.billingList && this.state.billingList.length > 0) ?
                                <>
                                    {(this.state.billingList && this.state.billingList.length > 0) &&
                                        this.state.billingList.map((item: any) => {
                                            return (
                                                <div key={item.id} className="default-card my-4 save-address border-1 rounded d-flex p-4 justify-content-between border-1 border">
                                                    <div className="col-8">
                                                        <div className="">
                                                            <div className="saved-name">{item.name}</div>
                                                            <small className="text-muted">{item.addressLine1 + ' ' + item.addressLine2}</small>
                                                        </div>
                                                        <div className="mt-4 form-check">
                                                            <input onClick={() => { this.billInfoChangeStatus(item) }} type="checkbox" className="form-check-input" id={"billingAddress" + item.id} checked={item.default_status === "active" ? true : false} />
                                                            <label className="form-check-label ml-2" htmlFor={"billingAddress" + item.id}>Use this billing address</label>
                                                        </div>
                                                    </div>
                                                    <div className="btn-box text-center align-self-center">
                                                        <button onClick={() => { this.clickEditButton(item) }} className="btn btn-primary py-1 d-block rounded-pill my-3 px-4">Edit</button>
                                                        <button onClick={() => { this.deleteBillInfo(item) }} className="btn btn-light py-1 d-block rounded-pill my-3 border-1 border mx-auto px-4">Delete</button>
                                                    </div>
                                                </div>
                                            )
                                        })

                                    }
                                    {this.state.isEdit &&
                                        <Modal className="newcommon addnewappt" show={this.state.isEdit} onHide={this.hideModal.bind(this)} centered>
                                            <Modal.Header>
                                                <div className="modal-title">Update billing information</div>
                                                <button type="button" className="btn-close" aria-label="Close" onClick={this.hideModal.bind(this)}>
                                                </button>
                                            </Modal.Header>
                                            <Modal.Body>
                                                <div className="row g-3">
                                                    <div className="col-12">
                                                        <label className="form-label">Name</label>
                                                        <input type="text" className="form-control" placeholder="Name" value={this.state.name}
                                                            onChange={(e) => {
                                                                if (allowOnlyAlpha(e.target.value)) {
                                                                    this.setState({ name: e.target.value })
                                                                } else if (e.target.value === "") {
                                                                    this.setState({ name: "" })
                                                                }
                                                            }} />
                                                        <div className="invalid-feedback">Required.</div>

                                                    </div>

                                                    <div className="col-12">
                                                        <label className="form-label">Address line 1</label>
                                                        <input type="text" className="form-control" placeholder="AddressLine1" value={this.state.address1} onChange={(e) => { this.setState({ address1: e.target.value }) }} />
                                                        <div className="invalid-feedback">Required.</div>
                                                    </div>

                                                    <div className="col-12">
                                                        <label className="form-label">Address line 2 (optional)</label>
                                                        <input type="text" className="form-control" value={this.state.address2} placeholder="" onChange={(e) => { this.setState({ address2: e.target.value }) }} />
                                                    </div>

                                                    <div className="col-6">
                                                        <label className="form-label">Country</label>
                                                        {
                                                            <select className="form-select" value={this.state.country} onChange={(e) => {
                                                                // setState({
                                                                //     country: e.target.value
                                                                // });
                                                                this.selectCountry(e.target.value);
                                                            }}>
                                                                <option value={'Select'}>{'Select Country'}</option>
                                                                {
                                                                    this.state.countryList && this.state.countryList.length > 0 &&
                                                                    this.state.countryList.map((value: any) => {
                                                                        return (
                                                                            <option value={value.id}>{value.name}</option>
                                                                        )
                                                                    })
                                                                }
                                                            </select>
                                                        }
                                                    </div>
                                                    <div className="col-6">
                                                        <label className="form-label">State</label>
                                                        {/* <input type="text" className="form-control" value={state.state} placeholder="" onChange={(e) => { setState({ state: e.target.value }) }} /> */}
                                                        {
                                                            <select className="form-select" value={this.state.state} onChange={(e) => {
                                                                // setState({
                                                                //     state: e.target.value
                                                                // });
                                                                this.selectState(e.target.value);
                                                            }}>
                                                                <option value={'Select State'}>{'Select State'}</option>
                                                                {
                                                                    this.state.stateList && this.state.stateList.length > 0 &&
                                                                    this.state.stateList.map((value: any) => {
                                                                        return (
                                                                            <option value={value.id}>{value.name}</option>
                                                                        )
                                                                    })
                                                                }
                                                            </select>
                                                        }
                                                    </div>

                                                    <div className="col-6">
                                                        <label className="form-label">City</label>
                                                        {/* <input type="text" className="form-control" value={state.country} placeholder="" onChange={(e) => { setState({ country: e.target.value }) }} /> */}
                                                        {
                                                            <select className="form-select" value={this.state.city} onChange={(e) => {
                                                                this.setState({
                                                                    city: e.target.value
                                                                });
                                                            }}>
                                                                <option value={'Select City'}>{'Select City'}</option>
                                                                {
                                                                    this.state.cityList && this.state.cityList.length > 0 &&
                                                                    this.state.cityList.map((value: any) => {
                                                                        return (
                                                                            <option value={value.id}>{value.name}</option>
                                                                        )
                                                                    })
                                                                }
                                                            </select>
                                                        }
                                                    </div>

                                                    <div className="col-6">
                                                        <label className="form-label">Postal code</label>
                                                        <input type="text" className="form-control" value={this.state.zipCode} placeholder=""
                                                            onChange={(e) => {
                                                                if (alphanumericAndSpace(e.target.value)) {
                                                                    this.setState({ zipCode: e.target.value })
                                                                } else if (e.target.value === "") {
                                                                    this.setState({ zipCode: "" })
                                                                }

                                                            }} />
                                                    </div>

                                                    <div className="col-12">
                                                        <div className="form-check">
                                                            <input type="checkbox" className="form-check-input" id="saveBill1" checked={this.state.selectedBillInfo === true} onChange={(e) => { this.setState({ selectedBillInfo: !this.state.selectedBillInfo }) }} />
                                                            <label className="form-check-label" htmlFor="saveBill1">Save this as a default billing address </label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Modal.Body>
                                            <Modal.Footer>
                                                <button className="btn btn-outline-primary" onClick={this.hideModal.bind(this)}>Cancel</button>
                                                <button className="btn btn-primary" onClick={() => { this.updateBillInfoApi() }} >Save</button>
                                            </Modal.Footer>
                                        </Modal>
                                    }
                                </>
                                : this.state.isNoRecord && <h3>No record found.</h3>
                        }
                    </div>
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state: IAppState) => ({
    userDataResponse: state.userData.userDataResponse,
});
const mapDispatchToProps = (dispatch: any) => ({
    loadingStart: () => {
        dispatch(fetchLoadingPending());
    },
    loadingEnd: (status: any) => {
        dispatch(fetchLoadingSuccess(status));
    }
});
export default connect(mapStateToProps, mapDispatchToProps)(BillingAddress);
