import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import configs from "../configs/apiConfigs";
import { BODY_TYPE, CommonApiOptions, METHOD_TYPE } from "../constants/api";
import { commonApi } from "../helpers/api";
import { fetchLoadingPending, fetchLoadingSuccess } from "../store/loadingIndicator/actions";
import { createSubscriptionPlanFailure, createSubscriptionPlanSuccess, fetchSubscriptionPlanFailure, fetchSubscriptionPlanSuccess, myPlanSuccess, myPlanFailure, cancelSubscriptionFailure, cancelSubscriptionSuccess } from "../store/subscriptionplan/actions";
import { CreateSubscriptionPlanPendingAction, FetchSubscriptionPlanPendingAction, SUBSCRIPTION_PLAN, MyPlanPendingAction, CancelSubscriptionPendingAction } from "../store/subscriptionplan/types";
import { attachHeader } from "../utils/apiHandler";

function* fetchSubscriptionPlanRun(action: FetchSubscriptionPlanPendingAction) {
  try {
    yield put(fetchLoadingPending());
    let url = configs.API_URL + "prime/clinic/" + action.payload.clinicUniqueId + "/clinic-subscription-plans1/";
    const options: CommonApiOptions = {
      url: url,
      apiOptions: {
        headers: attachHeader(true),
        withCredentials: true
      },
      method: METHOD_TYPE.GET
    };
    //@ts-ignore
    const response = yield call(commonApi, options);
    yield put(fetchSubscriptionPlanSuccess(response));
  } catch (err) {
    yield put(fetchSubscriptionPlanFailure(err));
  }
  yield put(fetchLoadingSuccess(false));
}
function* fetchSubscriptionPlanWatcher() {
  yield takeEvery(SUBSCRIPTION_PLAN.SUBSCRIPTION_PLAN_FETCH, fetchSubscriptionPlanRun);
}

function* createSubscriptionPlanRun(action: CreateSubscriptionPlanPendingAction) {
  try {
    yield put(fetchLoadingPending());
    let url = configs.API_URL + "prime/clinic/" + action.payload.clinicUniqueId + "/create-subsciption1/";
    const options: CommonApiOptions = {
      url: url,
      apiOptions: {
        headers: attachHeader(true),
        withCredentials: true
      },
      method: METHOD_TYPE.POST,
      body: {
        type: BODY_TYPE.RAW,
        data: action.payload.data
      }
    };
    //@ts-ignore
    const response = yield call(commonApi, options);
    yield put(createSubscriptionPlanSuccess(response));
  } catch (err) {
    yield put(createSubscriptionPlanFailure(err));
  }
  yield put(fetchLoadingSuccess(false));
}
function* createSubscriptionPlanWatcher() {
  yield takeEvery(SUBSCRIPTION_PLAN.SUBSCRIPTION_PLAN_CREATE, createSubscriptionPlanRun);
}

function* myPlanRun(action: MyPlanPendingAction) {
  try {
    yield put(fetchLoadingPending());
    let url = configs.API_URL + "prime/clinic/" + action.payload.clinicUniqueId + "/clinic-my-plans/";
    const options: CommonApiOptions = {
      url: url,
      apiOptions: {
        headers: attachHeader(true),
        withCredentials: true
      },
      method: METHOD_TYPE.GET,
    };
    //@ts-ignore
    const response = yield call(commonApi, options);
    yield put(myPlanSuccess(response));
  } catch (err) {
    yield put(myPlanFailure(err));
  }
  yield put(fetchLoadingSuccess(false));
}
function* myPlanWatcher() {
  yield takeEvery(SUBSCRIPTION_PLAN.CLINIC_MYPLAN, myPlanRun);
}

function* cancelSubscription(action: CancelSubscriptionPendingAction) {
  try {
    yield put(fetchLoadingPending());
    let url = configs.API_URL + "prime/clinic/" + action.payload.clinicUniqueId + "/cancel-subsciption/";
    const options: CommonApiOptions = {
      url: url,
      apiOptions: {
        headers: attachHeader(true),
        withCredentials: true
      },
      method: METHOD_TYPE.POST,
      body: {
        type: BODY_TYPE.RAW,
        data: action.payload.data
      }
    };
    //@ts-ignore
    const response = yield call(commonApi, options);
    yield put(cancelSubscriptionSuccess(response));
  } catch (err) {
    yield put(cancelSubscriptionFailure(err));
  }
  yield put(fetchLoadingSuccess(false));
}
function* cancelSubscrptionWatcher() {
  yield takeEvery(SUBSCRIPTION_PLAN.CANCEL_SUBSCRIPTION, cancelSubscription);
}


function* SubscriptionPlan() {
  yield all([fork(fetchSubscriptionPlanWatcher), fork(createSubscriptionPlanWatcher), fork(myPlanWatcher), fork(cancelSubscrptionWatcher)]);
}
export default SubscriptionPlan;