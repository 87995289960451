import { DAY_CONSTANTS } from '../constants';
import moment from 'moment';
/**
 * 
 * @param uniqueId - uniqueid to check for permission
 * @param modelType - modeltype of unique id
 * @param permissions - permissions object array
 */
export const checkPermission = (uniqueId: string, modelType: string, permissions: any[]) => {
    try {
        // const login = getCookie();
        // const permissions: any[] = login.permissions;
        for (let item of permissions) {
            if (parseInt(item.modeluniqueId, 10) === parseInt(uniqueId, 10) && item.modelType.toLowerCase() === modelType.toLowerCase()) {
                return true;
            }
        }
        return false;
    } catch (err) {
        return false;
    }
}
/**
 * 
 * @param time - {00:00} (24hrs)
 * @returns - {00:00 AM} (12hrs)
 */
export const timeConvert = (time: any) => {
    try {
        // Check correct time format and split into components
        time = time.toString().match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time];
        if (time.length > 1) { // If time format correct
            time = time.slice(1);  // Remove full string match value
            time[5] = +time[0] < 12 ? ' AM' : ' PM'; // Set AM/PM
            time[0] = +time[0] % 12 || 12; // Adjust hours
            if (time[0] < 10) {
                time[0] = "0" + time[0];
            }
        }
        return time.join(''); // return adjusted time or original string
    } catch (err) {
        return [];
    }
}
/**
 * 
 * @param text - string
 * @returns masked US phone string
 */
export const maskUSPhoneInput = (text: string) => {
    let x: any = text.replace(/\D/g, '').match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
    text = !x[2] ? x[1] : '(' + x[1] + ') ' + x[2] + (x[3] ? '-' + x[3] : '');
    return text;
}
/**
 * 
 * @param maskedText - string
 * @returns return string with digit and plus (+)
 */
export const takeOnlyDigitAndPlus = (maskedText: string) => {
    return maskedText.replace(/[^0-9+]/g, "");
}
export const takeOnlyDigit = (text: string) => {
    return text.replace(/[^0-9]/g, '');
}
export const removeCountryCode = (text:string) => {
    return text.slice(-10);
}
/**
 * 
 * @param considerationNumber 
 * @param hoursLength 
 * @param appointmentLength 
 * @param servicesLength 
 */
export const calculateProviderPercentage = (considerationNumber: number, hoursLength: number, appointmentLength: number, servicesLength: number = 0) => {
    let percentage = 0;
    if (considerationNumber === 3) {
        if (hoursLength > 0 && appointmentLength > 0 && servicesLength > 0) {
            percentage = 100;
        } else if (hoursLength === 0 && appointmentLength === 0 && servicesLength === 0) {
            percentage = 0;
        } else if (hoursLength > 0 && appointmentLength === 0 && servicesLength === 0) {
            percentage = 33;
        } else if (hoursLength === 0 && appointmentLength > 0 && servicesLength === 0) {
            percentage = 33;
        } else if (hoursLength === 0 && appointmentLength === 0 && servicesLength > 0) {
            percentage = 33;
        } else if (hoursLength > 0 && appointmentLength > 0 && servicesLength === 0) {
            percentage = 66;
        } else if (hoursLength === 0 && appointmentLength > 0 && servicesLength > 0) {
            percentage = 66;
        } else if (hoursLength > 0 && appointmentLength === 0 && servicesLength > 0) {
            percentage = 66;
        }
    } else if (considerationNumber === 2) {
        if (hoursLength > 0 && appointmentLength > 0) {
            percentage = 100;
        } else if (hoursLength === 0 && appointmentLength === 0) {
            percentage = 0;
        } else if (hoursLength > 0 && appointmentLength === 0) {
            percentage = 50;
        } else if (hoursLength === 0 && appointmentLength > 0) {
            percentage = 50;
        }
    }
    return percentage;
}
/**
 * Sample Mocked data for hours for each day of week with start and end
 */
export const mockedHoursData = () => {
    let hoursObject: any = {};
    DAY_CONSTANTS.forEach((value, index) => {
        hoursObject[index + 1] = ["Closed", "Closed"];
    });
    return hoursObject;
}
/**
 * 
 * @param time - string
 * @returns time in minutes
 *
 */
export const getTimeAsNumberOfMinutes = (time: any) => {
    var timeParts = time.split(":");
    var timeInMinutes = (timeParts[0] * 60) + parseInt(timeParts[1], 10);
    return timeInMinutes;
}
/**
 * 
 * @param filename string
 * @returns image extension
 */
export const getImageExtension = (filename: string) => {
    return filename.split('.').pop();
}
/**
 * 
 * @param ext string
 * @returns true if valid image extension else false.
 */
export const checkImageExtension = (ext: string) => {
    if (ext === "jpeg") {
        return true;
    } else if (ext === "jpg") {
        return true;
    } else if (ext === "gif") {
        return true;
    } else if (ext === "png") {
        return true;
    }
    return false;
}
/**
 * 
 * @param monthIndex number for Jan pass 0, and so on
 * @returns month name for e.g January
 */
export const returnMonthName = (monthIndex: number) => {
    const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September',
        'October', 'November', 'December'];
    return months[monthIndex];
}

/**
 * Prints to console
 */
export const print = (msg?: any, ...optionalParams: any[]) => {
    if (msg && optionalParams.length > 0) {
        return console.log(msg, optionalParams);
    } else if (msg) {
        return console.log(msg);
    }
}

/**
 * 
 * @param givenNumber - any number
 * @param roundingFactor must be positive integer
 */
export const roundNumberWithRoundingFactorDecimalFix = (givenNumber: any, roundingFactor: number) => {
    if (isNaN(givenNumber)) {
        return '';
    } else if (isNaN(roundingFactor)) {
        return '';
    }
    let roundBy: string = "1";
    for (let start = 0; start < Math.floor(roundingFactor); start++) {
        roundBy += "0";
    }
    return Number(Math.round((Number(givenNumber) + Number.EPSILON) * Number(roundBy)) / Number(roundBy)).toFixed(roundingFactor);
}

/**
 * Tenth day of current month
 * @param date - Format YYYY-MM-DD
 * @returns string {YYYY}-{MM}-10
 */
export const tenthDayofMonth = (date: any = null): string => {
    if (date) {
        const currentDate = moment(date, 'YYYY-MM-DD');
        const tenthDay = '10';
        const currentMonth = currentDate.format('M');
        const currentYear = currentDate.format('YYYY');
        return currentYear + '-' + currentMonth + '-' + tenthDay;
    }
    const currentDate = moment(new Date(), 'YYYY/MM/DD');
    const tenthDay = '10';
    const currentMonth = currentDate.format('M');
    const currentYear = currentDate.format('YYYY');
    return currentYear + '-' + currentMonth + '-' + tenthDay;
}

//Return in MOnth, Year
export async function monthAndYear(monthYear: any) {
    let responseArray: any[] = [];
    for (let start = 0; start < monthYear.billableYears.length; start++) {
        const year = monthYear.billableYears[start];
        const monthsarr = monthYear.billableMonthsByYear[year];
        await (async () => {
            for (let monthStart = 0; monthStart < monthsarr.length; monthStart++) {
                await responseArray.push({ value: year + '-' + monthsarr[monthStart], label: returnMonthName(Number(monthsarr[monthStart] - 1)) + ' ' + year });
            }
        })();
    }
    return responseArray;
}

/* Returns true when valid */
export const checkValidDateTimePerDoctorSchedule = (pdate: any, ptime: any, pdoctor: any) => {
    try {
        if (!pdoctor || (pdoctor && !pdoctor.hours)) return false;
        // const date = moment(pdate).format("YYYY-MM-DD");
        const time = moment(ptime).format("HH:mm");
        // const momentDateTime = moment(date + " " + time).format("YYYY-MM-DD HH:mm");
        const dayOfWeek = moment(pdate).day();
        const hoursArr = [];
        for (let prop in pdoctor.hours) {
            hoursArr.push(pdoctor.hours[prop]);
        }
        const dayOfWeekHourPerDoctor = hoursArr[dayOfWeek - 1];
        if (dayOfWeekHourPerDoctor[0] === "Closed" || dayOfWeekHourPerDoctor[1] === "Closed") return false;
        const pTimeInMinutes = moment.duration(time).asMinutes();
        const startDocTimeInMinutes = moment.duration(dayOfWeekHourPerDoctor[0]).asMinutes();
        const endDocTimeInMinutes = moment.duration(dayOfWeekHourPerDoctor[1]).asMinutes();
        if (moment(startDocTimeInMinutes).isSameOrBefore(pTimeInMinutes) && moment(endDocTimeInMinutes).isSameOrAfter(pTimeInMinutes)) {
            return true;
        }
        return false;
    } catch (err) {
        return false;
    }
};

//encode query data from object.
export const encodeQueryData = (data: any) => {
    const ret = [];
    for (let d in data) {
        if (data[d]) {
            ret.push(d + '=' + data[d]);
        } else {
            continue;
        }

    }
    return ret.join('&');
}

export function getAge(dateString: any) {
    var today = new Date();
    var birthDate = new Date(dateString);
    var age = today.getFullYear() - birthDate.getFullYear();
    var m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
        age--;
    }
    return age;
}