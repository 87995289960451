import firebase from "firebase";
import moment from "moment";
import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { CHAT } from "../../../constants";
import { IAppState } from "../../../store";
import configs from "../../configs/apiConfigs";
import { getAppointmentDetail } from "../../services/appointment";
import _ from "lodash";
import { MyScheduleHeader } from "../../components/mySchedule/MyScheduleHeader";
import CONSTANT_LABELS from "../../../constants/labels";
import { removeCountryCode } from "../../../utils/global";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { storeChatData } from "../../../store/chat/action";
import { PatientDetailModel } from "../../components/mySchedule/PatientDetailModel";

const db = firebase.firestore();
export const Confirmed = () => {
    const clinics = useSelector((state: IAppState) => state.userData.userDataResponse.clinics);
    const clinic = clinics[0];
    const [appointmentList, setAppointmentList] = useState<any[]>([]);
    const [showPatientDetailModal, setShowPatientDetailModal] = useState(false);
    const [currentActiveModalAppointment, setCurrentActiveModalAppointment]: any = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const dispatch = useDispatch();
    useEffect(() => {
        setAppointmentList([]);
        getConfirmedFromFirestore()
    }, []);
    const getConfirmedFromFirestore = async () => {
        setIsLoading(true);
        const docs = await db.collection(configs.CHAT_COLLECTION)
            .where("sendToCId", "==", clinic.uniqueId)
            .where("actionType", 'in', [CHAT.ACTION_TYPE.CONFIRM_BY_PATIENT])
            .orderBy("createdAt", "desc").get();
            const promises =  docs.docs.filter((doc) => {
                const apptData = doc.data();
                if (apptData.appointmentId && apptData.appointmentId !== "" && ((apptData.addedFromMessageCenter || apptData.addedFromProvider || apptData.addedFromPatient))) {
                    return true;
                }
                return false;
            }).map( async (doc) => {
                const apptData = doc.data();
                return getAppointmentDetail(clinic.uniqueId, apptData.appointmentId).then((success) => {
                    return {
                        ...success, ...apptData, _id: doc.id, metadataFireStore: apptData.metadata,
                        sortTime: moment(moment.unix(apptData?.createdAt?.seconds)).toDate(),
                        rTime: moment(moment.unix(apptData?.createdAt?.seconds)).format("MMM DD, YYYY - LT")
                    };
                })
            });
            await Promise.all(promises).then((responses) => {
                for (let res of responses) {
                    setAppointmentList((currentState) => [...currentState, {
                        ...res
                    }]);
                }
            });
            setIsLoading(false);
            watchRealTime();
    }
    const watchRealTime = () => {
        db.collection(configs.CHAT_COLLECTION)
            .where("sendToCId", "==", clinic.uniqueId)
            .where("actionType", 'in', [CHAT.ACTION_TYPE.CONFIRM_BY_PATIENT])
            .orderBy("createdAt", "desc").onSnapshot((snapshot) => {
                snapshot.docChanges().forEach((change) => {
                    if (change.type === "modified") {
                        const apptData = change.doc.data();
                        if (!appointmentList.find((item) => item._id === change.doc.id)) {
                            if (apptData.appointmentId && apptData.appointmentId !== "" && ((apptData.addedFromMessageCenter || apptData.addedFromProvider || apptData.addedFromPatient))) {
                                setIsLoading(true);
                                getAppointmentDetail(clinic.uniqueId, apptData.appointmentId).then((success) => {
                                    setIsLoading(false);
                                    setAppointmentList((currentState) => [...currentState.filter((item) => item._id !== change.doc.id), {
                                        ...success,
                                        ...apptData,
                                        _id: change.doc.id,
                                        metadataFireStore: apptData.metadata,
                                        sortTime: moment(moment.unix(apptData?.createdAt?.seconds)).toDate(),
                                        rTime: moment(moment.unix(apptData?.createdAt?.seconds)).format("MMM DD, YYYY - LT")
                                    }]);
                                })
                            }
                        }
                    }
                })
            });
    }
    const handleMarkAsRead = async (item:any) => {
        try {
            const docRef = db.collection(configs.CHAT_COLLECTION).doc(item._id);
            const changeObj = {
                "metadata.markReadByClinicAt": firebase.firestore.FieldValue.serverTimestamp(),
            };
            await docRef.update(changeObj);
        } catch (err) {
            console.log(err);
        }
    };
    const handlePatientDetailAppointmentClick = (item: any) => {
        setShowPatientDetailModal(true);
        setCurrentActiveModalAppointment(item);
    }
    const addChatPin = (item: any) => {
        dispatch(storeChatData({
            ...item,
            fetched: true,
            pinType: "chat",
            openPin: true,
            appointmentId: item.uniqueid,
            sendToCId: item.clinic.uniqueid
        }));
    }
    let sortedApptList = _.sortBy(appointmentList, function (value: any) { return value.sortTime }).reverse();
    sortedApptList = _.filter(sortedApptList, (item) => {
        console.log(moment().diff(moment(item.sortTime), "days"))
        if (moment().diff(moment(item.sortTime), "days") <= 7) {
            return true;
        }
        return false;
    })
    return (
        <React.Fragment>
            <MyScheduleHeader></MyScheduleHeader>
            <section className="schedule-sec px-3">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12">
                            <table className="table upcoming-table">
                                <thead>
                                    <tr>
                                        <th scope="col">
                                            <div>NAME</div>
                                        </th>
                                        <th scope="col">
                                            <div>Provider</div>
                                        </th>
                                        <th scope="col">
                                            <div>Scheduled On</div>
                                        </th>
                                        <th className="text-end" scope="col" style={{ width: "215px" }}>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        isLoading &&
                                        <tr className="spinner-border" role="status"></tr>
                                    }
                                    {
                                        sortedApptList.map((item) => {
                                            return (
                                                <React.Fragment key={item.uniqueid}>
                                                    <ItemRow 
                                                        item={item} 
                                                        handleMarkAsRead={handleMarkAsRead}
                                                        handlePatientDetailAppointmentClick={handlePatientDetailAppointmentClick}
                                                        addChatPin={addChatPin}
                                                    ></ItemRow>
                                                </React.Fragment>
                                            )
                                        })
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                {
                    useMemo(() => {
                        if (showPatientDetailModal && currentActiveModalAppointment) {
                            return (
                                <PatientDetailModel
                                    hideModal={() => {
                                        setShowPatientDetailModal(false);
                                    }}
                                    appointment={currentActiveModalAppointment}
                                >
                                </PatientDetailModel>
                            )
                        }
                    }, [showPatientDetailModal, currentActiveModalAppointment])
                }
            </section>
        </React.Fragment>
    );
};

const ItemRow = ({ item, addChatPin, handlePatientDetailAppointmentClick, handleMarkAsRead }: any) => {
    return (
        <tr className={"table-row have-msg"} key={item.uniqueid}>
            <td>
                <span className="d-block fw-medium p-0 cursor-pointer btn-link text-capitalize" onClick={() => {
                    handlePatientDetailAppointmentClick(item)
                }}>
                    {
                        (item.dependant && item.dependant.fname && item.dependant.lname) ?
                            (item.dependant.fname + ' ' + item.dependant.lname).substring(0, 15) : ''
                    }
                </span>
                <span className="d-block text-muted">
                    {
                        item.dependant.phone && item.dependant.phone !== "" ? (CONSTANT_LABELS.PLUS_ONE + removeCountryCode(item.dependant.phone)) : item.dependant.email
                    }
                </span>
            </td>
            <td>
                <div className="d-flex p-0">
                    <div className="avatar flex-shrink-0 avatar-lg border rounded-circle me-3">
                        {
                            (item.doctor && item.doctor.photo && item.doctor.photo.name &&
                                item.doctor.photo.type) ?
                                <img src={configs.IMAGE_URL + 'doctor/' +
                                    item.doctor.photo.name + '-200x200.' + item.doctor.photo.type} alt="profile image" className="img-fluid" />
                                :
                                <>{item.doctor.fullname.split(" ")[0] ? item.doctor.fullname.split(" ")[0].charAt(0) : ''}
                                    {item.doctor.fullname.split(" ")[1] ? item.doctor.fullname.split(" ")[1].charAt(0) : ''}</>
                        }
                    </div>
                    <div>
                        <span className="d-block fw-medium"> {(item.doctor && item.doctor.fullname) ? item.doctor.fullname : ''}</span>
                        <small className="d-block text-muted">{item.appointmentType ? item.appointmentType : ''}</small>
                    </div>
                </div>
            </td>
            <td>
                <small className="d-block p-0">{moment(item.date + " " + item.time, 'YYYY-MM-DD LT').format("MMM DD, YYYY - LT")}</small>
            </td>
            <td className="text-end">
                <OverlayTrigger
                    placement="auto"
                    overlay={
                        <Tooltip id="tooltip">Quick chat</Tooltip>
                    }
                >
                    <button className="btn mx-1 p-1"
                        type="button"
                        onClick={(e) => { addChatPin(item); }}>
                        <i className="bi bi-chat-right-text"></i></button>
                </OverlayTrigger>
                {
                    (!item?.metadataFireStore?.markReadByClinicAt) &&
                    <OverlayTrigger
                        placement="auto"
                        overlay={
                            <Tooltip id="tooltip">
                                Mark as Read
                            </Tooltip>
                        }
                    >
                        <button className="btn mx-1 p-1"
                            type="button"
                            onClick={() => {
                                handleMarkAsRead(item);
                            }}>
                            <i className="bi bi-eye"></i></button>
                    </OverlayTrigger>
                }
            </td>
        </tr >
    )
}