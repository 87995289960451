import React from "react";
import { ReportHeader } from "./index";

export const ReportList = ({
    handleReportClick,
    onClose
}: any) => {
    return (
        <div className="app">

            <ReportHeader  showDone={true}
                // showBack={true}
                onDoneClick={() => {
                    onClose()
                }}
                // onBackClick={() => {}}
                pageTitle=""
            ></ReportHeader>    

            <div className="app-body">
                <ul className="list-group list-group-flush">
                    <li onClick={() => {
                        handleReportClick({
                            report: "analytics-reports",
                            type: "unconfirmed_patient_list"
                        });
                    }} className="list-group-item list-group-item-action cursor-pointer">Unconfirmed Patients List</li>
                    <li onClick={() => {
                        handleReportClick({
                            report: "analytics-reports",
                            type: "due_immediately_patients_list"
                        });
                    }} className="list-group-item list-group-item-action cursor-pointer">Due Immediately Patients List</li>
                    <li onClick={() => {
                        handleReportClick({
                            report: "analytics-reports",
                            type: "unscheduled_patients_list"
                        });
                    }} className="list-group-item list-group-item-action cursor-pointer">Unscheduled Patients List</li>
                    <li onClick={() => {
                        handleReportClick({
                            report: "analytics-reports",
                            type: "waitlisted_patient_list"
                        });
                    }} className="list-group-item list-group-item-action cursor-pointer">Waitlisted Patients List</li>
                    <li onClick={() => {
                        handleReportClick({
                            report: "analytics-reports",
                            type: "requested_patient_list"
                        });
                    }} className="list-group-item list-group-item-action cursor-pointer">Requested Patients List</li>
                    <li onClick={() => {
                        handleReportClick({
                            report: "analytics-reports",
                            type: "cancelled_patient_list"
                        });
                    }} className="list-group-item list-group-item-action cursor-pointer">Cancelled Patients List</li>
                    <li onClick={() => {
                        handleReportClick({
                            report: "analytics-reports",
                            type: "confirmed_patient_list"
                        });
                    }} className="list-group-item list-group-item-action cursor-pointer">Confirmed Patients List</li>
                    <li onClick={() => {
                        handleReportClick({
                            report: "analytics-reports",
                            type: "rescheduled_patient_list"
                        });
                    }} className="list-group-item list-group-item-action cursor-pointer">Rescheduled Patients List</li>
                    <li onClick={() => {
                        handleReportClick({
                            report: "analytics-reports",
                            type: "double_booked_patients_list"
                        });
                    }} className="list-group-item list-group-item-action cursor-pointer">Double Booked Patients List</li>
                    <li  onClick={() => {
                        handleReportClick({
                            report: "analytics-reports",
                            type: "pre-appointed_patients_list"
                        });
                    }} className="list-group-item list-group-item-action cursor-pointer">Pre-appointed Patients List</li>
                    <li  onClick={() => {
                        handleReportClick({
                            report: "analytics-reports",
                            type: "overdue_patients_list"
                        });
                    }} className="list-group-item list-group-item-action cursor-pointer">Overdue Patients List</li>
                    <li onClick={() => {
                        handleReportClick({
                            report: "patient-reports",
                            type: "active_patients_list"
                        });
                    }} className="list-group-item list-group-item-action cursor-pointer">Active Patients List</li>
                    <li  onClick={() => {
                        handleReportClick({
                            report: "patient-reports",
                            type: "in-active_patients_list"
                        });
                    }} className="list-group-item list-group-item-action cursor-pointer">In-active Patients List</li>
                    <li onClick={() => {
                        handleReportClick({
                            report: "analytics-reports",
                            type: "pre-screening_verified_patient_list"
                        });
                    }} className="list-group-item list-group-item-action cursor-pointer">Pre-screening Verified Patients List</li>
                    <li onClick={() => {
                        handleReportClick({
                            report: "analytics-reports",
                            type: "pre-screening_pending_patient_list"
                        });
                    }} className="list-group-item list-group-item-action cursor-pointer">Pre-screening Pending Patients List</li>
                    <li onClick={() => {
                        handleReportClick({
                            report: "analytics-reports",
                            type: "pre-screening_failed_patient_list"
                        });
                    }} className="list-group-item list-group-item-action cursor-pointer">Pre-screening Failed Patients List</li>
                    <li onClick={() => {
                        handleReportClick({
                            report: "notification-reports",
                            type: "notification_count_per_appointment"
                        });
                    }} className="list-group-item list-group-item-action cursor-pointer">Notification count per appointment</li>
                </ul>
            </div>
        </div>
    )
}