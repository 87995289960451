import {
    PROFILE,
    ProfileActionTypes,
    ProfileState
} from './types';

export const initialState: ProfileState = {
    pending: false,
    profile: { type: '', uniqueId: '' },
    profileResponse: {
        gender: '',
        status: { error: false, msg: '' }, id: '', uniqueId: '', name: '', phone: '',
        phoneVerified: '', email: '', emailVerified: '', primarySpeciality: '', website: '', logo: { name: '', type: '' },
        questionResponse: [], description: '', hours: {}, features: [], address: {}, photos: [], doctors: [],
        certificates: [], educations: [], assignment: false, languages: [],
        HygieneFeatures: []
    },
    question: { modelType: '' },
    questionResponse: { questions: [], status: { error: false, msg: '' } },
    feature: { speciality: '' },
    featureResponse: { features: [], status: { error: false, msg: '' } },
    language: {},
    languageResponse: { languages: [], status: { error: false, msg: '' } },
    profileUpdate: { description: '', type: '', uniqueId: '', userType: '', assignment: false },
    profileUpdateResponse: { status: { error: false, msg: '' }, udpateData: null, userType: '', type: '' },
    imageRemove: { image: '', uniqueId: '', userType: '' },
    imageRemoveResponse: { id: '', status: { error: false, msg: '' } },
    error: null,
    imageUpload: { type: '', uphoto: '', uniqueId: '', userType: '' },
    imageUploadResponse: { photos: { name: '', type: '' }, status: { error: false, msg: '' }, userType: '', uniqueId: '' },
};
export const profileReducer = (state = initialState, action: ProfileActionTypes) => {
    const NewState = { ...state }
    switch (action.type) {
        case PROFILE.LOAD_ERROR:
            return {
                ...state,
                pending: false,
                error: action.error
            }
        case PROFILE.LOAD_PENDING:
            return {
                ...state,
                pending: true,
                profile: action.payload
            }
        case PROFILE.LOAD_SUCCESS:
            return {
                ...state,
                pending: false,
                profileResponse: action.profile
            }
        case PROFILE.QUESTION_ERROR:
            return {
                ...state,
                pending: false,
                error: action.error
            }
        case PROFILE.QUESTION_PENDING:
            return {
                ...state,
                pending: true,
                question: action.payload
            }
        case PROFILE.QUESTION_SUCCESS:
            return {
                ...state,
                pending: false,
                questionResponse: action.question
            }
        case PROFILE.FEATURE_ERROR:
            return {
                ...state,
                pending: false,
                error: action.error
            }
        case PROFILE.FEATURE_PENDING:
            return {
                ...state,
                pending: true,
                feature: action.payload
            }
        case PROFILE.FEATURE_SUCCESS:
            return {
                ...state,
                pending: false,
                featureResponse: action.feature
            }
        case PROFILE.LANGUAGE_ERROR:
            return {
                ...state,
                pending: false,
                error: action.error
            }
        case PROFILE.LANGUAGE_PENDING:
            return {
                ...state,
                pending: true,
                language: action.payload
            }
        case PROFILE.LANGUAGE_SUCCESS:
            return {
                ...state,
                pending: false,
                languageResponse: action.language
            }
        case PROFILE.PROFILE_UPDATE_ERROR:
            return {
                ...state,
                pending: false,
                error: action.error
            }
        case PROFILE.PROFILE_UPDATE_PENDING:
            return {
                ...state,
                pending: true,
                profileUpdate: action.payload
            }
        case PROFILE.PROFILE_UPDATE_SUCCESS:
            if (action.profileUpdate.type === 'question') {
                return {
                    ...state,
                    pending: false,
                    profileUpdateResponse: action.profileUpdate,
                    profileResponse: {
                        ...state.profileResponse,
                        status: {
                            ...state.profileResponse.status,
                            error: false,
                        },
                        questionResponse: state.profileResponse.questionResponse.map((value: any, index: number) => {
                            if (value.id === action.profileUpdate.udpateData.questionResponse.id) {
                                return {
                                    id: action.profileUpdate.udpateData.questionResponse.id,
                                    response: action.profileUpdate.udpateData.questionResponse.response
                                }
                            }
                            return value;
                        })
                    }
                }
            } else if (action.profileUpdate.type === 'features') {
                return {
                    ...state,
                    pending: false,
                    profileUpdateResponse: action.profileUpdate,
                    profileResponse: {
                        ...state.profileResponse,
                        status: {
                            ...state.profileResponse.status,
                            error: false,
                        },
                        features: action.profileUpdate.udpateData.features
                    }
                }
            } else if (action.profileUpdate.type === 'hygieneFeatures') {
                return {
                    ...state,
                    pending: false,
                    profileUpdateResponse: action.profileUpdate,
                    profileResponse: {
                        ...state.profileResponse,
                        status: {
                            ...state.profileResponse.status,
                            error: false,
                        },
                        HygieneFeatures: action.profileUpdate.udpateData.HygieneFeatures
                    }
                }
            } else if (action.profileUpdate.type === 'removeCertificate') {
                return {
                    ...state,
                    pending: false,
                    profileUpdateResponse: action.profileUpdate,
                    profileResponse: {
                        ...state.profileResponse,
                        status: {
                            ...state.profileResponse.status,
                            error: false,
                        },
                        certificates: state.profileResponse.certificates.filter((value) => {
                            return value.id !== action.profileUpdate.udpateData.removeCertificate.id
                        })
                    }
                }
            } else if (action.profileUpdate.type === 'removeEducation') {
                return {
                    ...state,
                    pending: false,
                    profileUpdateResponse: action.profileUpdate,
                    profileResponse: {
                        ...state.profileResponse,
                        status: {
                            ...state.profileResponse.status,
                            error: false,
                        },
                        educations: state.profileResponse.educations.filter((value) => {
                            return value.id !== action.profileUpdate.udpateData.removeEducation.id
                        })
                    }
                }
            } else if (action.profileUpdate.type === 'certificate') {
                return {
                    ...state,
                    pending: false,
                    profileUpdateResponse: action.profileUpdate,
                    profileResponse: {
                        ...state.profileResponse,
                        status: {
                            ...state.profileResponse.status,
                            error: false,
                        },
                        certificates: [...state.profileResponse.certificates, action.profileUpdate.udpateData.certificate]
                    }
                }
            } else if (action.profileUpdate.type === 'education') {
                return {
                    ...state,
                    pending: false,
                    profileUpdateResponse: action.profileUpdate,
                    profileResponse: {
                        ...state.profileResponse,
                        status: {
                            ...state.profileResponse.status,
                            error: false,
                        },
                        educations: [...state.profileResponse.educations, action.profileUpdate.udpateData.education]
                    }
                }
            } else if (action.profileUpdate.type === "assignment") {
                if (state.profileResponse.metadata) {
                    return {
                        ...state,
                        pending: false,
                        profileUpdateResponse: action.profileUpdate,
                        profileResponse: {
                            ...state.profileResponse,
                            status: {
                                ...state.profileResponse.status,
                                error: false,
                            },
                            metadata: {
                                ...state.profileResponse.metadata,
                                acceptAssignment: action.profileUpdate.udpateData.assignment
                            }
                        }
                    }
                }
                return {
                    ...state,
                    pending: false,
                    profileUpdateResponse: action.profileUpdate,
                    profileResponse: {
                        ...state.profileResponse,
                        status: {
                            ...state.profileResponse.status,
                            error: false,
                        },
                        metadata: {
                            acceptAssignment: action.profileUpdate.udpateData.assignment
                        }
                    }
                }
            } else {
                return {
                    ...state,
                    pending: false,
                    profileUpdateResponse: action.profileUpdate,
                    profileResponse: {
                        ...state.profileResponse,
                        status: {
                            ...state.profileResponse.status,
                            error: false,
                        },
                        ...action.profileUpdate.udpateData
                    }
                }
            }
        case PROFILE.IMAGE_REMOVE_ERROR:
            return {
                ...state,
                pending: false,
                error: action.error
            }
        case PROFILE.IMAGE_REMOVE_PENDING:
            return {
                ...state,
                pending: true,
                imageRemove: action.payload
            }
        case PROFILE.IMAGE_REMOVE_SUCCESS:
            return {
                ...state,
                pending: false,
                imageRemoveResponse: action.imageRemove,
                profileResponse: {
                    ...state.profileResponse,
                    status: {
                        ...state.profileResponse.status,
                        error: false,
                    },
                    photos: state.profileResponse.photos.filter((value: any, index) => {
                        return value.id !== action.imageRemove.id
                    })
                }
            }
        case PROFILE.IMAGE_UPLOAD_ERROR:
            return {
                ...state,
                pending: false,
                error: action.error
            }
        case PROFILE.IMAGE_UPLOAD_PENDING:
            return {
                ...state,
                pending: true,
                imageUpload: action.payload
            }
        case PROFILE.IMAGE_UPLOAD_SUCCESS:
            return {
                ...state,
                pending: false,
                imageUploadResponse: action.imageUpload,
                profileResponse: {
                    ...state.profileResponse,
                    status: {
                        ...state.profileResponse.status,
                        error: false
                    },
                    photos: [...state.profileResponse.photos, action.imageUpload.photos]
                }
            }
        case PROFILE.EMAIL_VERIFICATION_ERROR:
            return {
                ...state,
                pending: false,
                error: action.error
            }
        case PROFILE.EMAIL_VERIFICATION_PENDING:
            return {
                ...state,
                pending: true,
                emailVerificationRequest: action.payload
            }
        case PROFILE.EMAIL_VERIFICATION_SUCCESS:
            return {
                ...state,
                pending: false,
                emailVerificationResponse: action.emailVerification
            }
        case PROFILE.HYGIENE_FEATURE_PENDING:
            return {
                ...state,
                pending: true,
                error: null,
                hygieneFeatureRequest: action.payload
            }
        case PROFILE.HYGIENE_FEATURE_ERROR:
            return {
                ...state,
                pending: false,
                error: action.payload,
            }
        case PROFILE.HYGIENE_FEATURE_SUCCESS:
            return {
                ...state,
                pending: false,
                error: null,
                hygieneFeatureResponse: action.payload
            }
        default:
            return NewState;

    }
};