import { put, all, fork, takeEvery, call } from "redux-saga/effects";
import { PMS_APPOINTMENTS, GetPMSAppointmentPendingAction, BookPMSAppointmentPendingAction, ReschedulePMSAppointmentPendingAction, PMSXcareAppointmentLinkPendingAction } from "../store/pmsappointments/types";
import { fetchLoadingPending, fetchLoadingSuccess } from "../store/loadingIndicator/actions";
import configs from "../configs/apiConfigs";
import { CommonApiOptions, METHOD_TYPE, BODY_TYPE } from "../constants/api";
import { attachHeader } from "../utils/apiHandler";
import { commonApi } from "../helpers/api";
import { getPMSAppointmentsSuccess, getPMSAppointmentsError, bookAppointmentSuccess, bookAppointmentFailure, reschedulePMSAppointmentSuccess, reschedulePMSAppointmentFailure, pmsxcareAppointmentSuccess, pmsxcareAppointmentFailure } from "../store/pmsappointments/actions";
function* getPMSAppointments(action: GetPMSAppointmentPendingAction) {
    try {
        yield put(fetchLoadingPending());
        let url='';
        if(action.payload.firstname || action.payload.lastname ){
            url = configs.API_URL + "pms/" + action.payload.clinicUniqueId + "/appointments/?page=" + action.payload.page +
            "&results=" + action.payload.results + '&fname='+action.payload.firstname+'&lname='+action.payload.lastname;
        }else if(action.payload.view && action.payload.date){
            url = configs.API_URL + "pms/" + action.payload.clinicUniqueId + "/appointments/?view=" + action.payload.view +
            "&date=" + action.payload.date;
        }else if(action.payload.view && action.payload.from && action.payload.to){
            url = configs.API_URL + "pms/" + action.payload.clinicUniqueId + "/appointments/?view=" + action.payload.view +
            "&from=" + action.payload.from + "&to="+action.payload.to;
        }
        else{
          url = configs.API_URL + "pms/" + action.payload.clinicUniqueId + "/appointments/?page=" + action.payload.page +
            "&results=" + action.payload.results;  
        }
        
        const options: CommonApiOptions = {
            url: url,
            apiOptions: {
                headers: attachHeader(true),
                withCredentials: true
            },
            method: METHOD_TYPE.GET
        };
        //@ts-ignore
        const response = yield call(commonApi, options);
        yield put(getPMSAppointmentsSuccess(response));
    } catch (err) {
        yield put(getPMSAppointmentsError(err));
    }
    yield put(fetchLoadingSuccess(false));
}
function* getPMSAppointmentsWatcher() {
    yield takeEvery(PMS_APPOINTMENTS.GET_PMS_APPOINTMENTS, getPMSAppointments);
}
function* bookPMSAppointments(action: BookPMSAppointmentPendingAction) {
    try {
        yield put(fetchLoadingPending());
        let url = configs.API_URL + "pms/" + action.payload.clinicUniqueId + "/bookappointments/";
        const options: CommonApiOptions = {
            url: url,
            apiOptions: {
                headers: attachHeader(true),
                withCredentials: true
            },
            method: METHOD_TYPE.POST,
            body: {
                type: BODY_TYPE.RAW,
                data: action.payload.data
            }
        };
        //@ts-ignore
        const response = yield call(commonApi, options);
        yield put(bookAppointmentSuccess({ ...response, requestData: action.payload.data }));
    } catch (err) {
        yield put(bookAppointmentFailure(err));
    }
    // yield put(fetchLoadingSuccess(false));
}
function* bookPMSAppointmentsWatcher() {
    yield takeEvery(PMS_APPOINTMENTS.BOOK_PMS_APPOINTMENT, bookPMSAppointments);
}
function* reschedulePMSAppointments(action: ReschedulePMSAppointmentPendingAction) {
    try {
        yield put(fetchLoadingPending());
        let url = configs.API_URL + "pms/" + action.payload.clinicUniqueId + "/appointment/" + action.payload.pmsAppointmentId + "/";
        const options: CommonApiOptions = {
            url: url,
            apiOptions: {
                headers: attachHeader(true),
                withCredentials: true
            },
            method: METHOD_TYPE.GET,
        };
        //@ts-ignore
        const response = yield call(commonApi, options);
        yield put(reschedulePMSAppointmentSuccess({ ...response }));
    } catch (err) {
        yield put(reschedulePMSAppointmentFailure(err));
    }
    // yield put(fetchLoadingSuccess(false));
}
function* reschedulePMSAppointmentsWatcher() {
    yield takeEvery(PMS_APPOINTMENTS.RESCHEDULE_PMS_APPOINTMENT, reschedulePMSAppointments);
}
function* PMSXcareAppointmentsLink(action: PMSXcareAppointmentLinkPendingAction) {
    try {
        yield put(fetchLoadingPending());
        let url = configs.API_URL + "pms/" + action.payload.clinicUniqueId + "/appointment/" + action.payload.pmsId + "/" + action.payload.appointmentId + "/";
        const options: CommonApiOptions = {
            url: url,
            body: {
                data: action.payload.dateTime,
                type: BODY_TYPE.RAW
            },
            apiOptions: {
                headers: attachHeader(true),
                withCredentials: true
            },
            method: METHOD_TYPE.POST,
        };
        //@ts-ignore
        const response = yield call(commonApi, options);
        yield put(pmsxcareAppointmentSuccess({ ...response }));
    } catch (err) {
        yield put(pmsxcareAppointmentFailure(err));
    }
    // yield put(fetchLoadingSuccess(false));
}
function* PMSXcareAppointmentsLinkWatcher() {
    yield takeEvery(PMS_APPOINTMENTS.PMS_XCARE_APPOINTMENT_LINK, PMSXcareAppointmentsLink);
}
function* PMSAppointments() {
    yield all([fork(getPMSAppointmentsWatcher), fork(bookPMSAppointmentsWatcher),
    fork(reschedulePMSAppointmentsWatcher), fork(PMSXcareAppointmentsLinkWatcher)]);
}
export default PMSAppointments;