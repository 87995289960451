import React, { useEffect, useState } from 'react';
import RichTextEditor from 'react-rte';
import { updateClinicProfile } from '../../../services/clinicProfile';
export const Description = ({ practiceDescription, clinicId, fetchProfile }: any) => {
  const [description, setDescription] = useState(RichTextEditor.createValueFromString(practiceDescription ? practiceDescription : '', 'html'));
  const [loading, setLoading] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const onCancel = () => {
    setDescription(RichTextEditor.createValueFromString(practiceDescription ? practiceDescription : '', 'html'));
    setIsEdit(false);
  };
  const onUpdate = () => {
    if (!description.getEditorState().getCurrentContent().hasText()) {
      return;
    } else {
      setLoading(true);
      updateClinicProfile('description', clinicId, description.toString('html')).then((success) => {
        if (success && success.status && success.status.error === false) {
          fetchProfile('description').then((success: any) => {
            setLoading(false);
            setIsEdit(false);
          });
        }
      }).catch((err) => { setLoading(false); setIsEdit(false); });
    }
  }
  return (
    <div className="practice-description-sec mb-4">
      <div className="card">
        <div className="card-header bg-light py-3 px-4">
          <div className="d-flex justify-content-between">
            <div className="fw-medium">Practice description</div>
            <div>
              {
                !isEdit && !loading &&
                <button className="btn p-0 btn-link" onClick={(e) => {
                  setIsEdit(true);
                }}>Edit</button>
              }
              {
                isEdit && !loading &&
                <>
                  <button className="btn p-0 btn-link me-2 text-secondary" onClick={(e) => { onCancel() }}><i className="bi bi-x-circle-fill text-danger"></i></button>
                  <button className="btn p-0 btn-link" onClick={(e) => { onUpdate() }}><i className="bi bi-check-circle-fill text-success"></i></button>
                </>
              }
              {
                loading &&
                <><span className="spinner-border spinner-border-sm text-primary" role="status" aria-hidden="true"></span></>
              }
            </div>
          </div>
        </div>
        <div className="card-body p-4">
          <div className="editor-box">
            <RichTextEditor
              disabled={!isEdit}
              value={description}
              onChange={setDescription}
            />
          </div>
          <div className="d-flex justify-content-between mt-3">
            <div className="align-self-center">
              <small className="d-block text-muted">This bio will help new patients find your clinic on google.</small>
            </div>
            {/* {
              showSave &&
              <div>
                <button className="btn btn-outline-primary me-2" onClick={onCancel}>Cancel</button>
                <button className="btn btn-primary" onClick={onUpdate}>
                  {
                    loading && !showSuccessMsg &&
                    <><span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> Updating</>
                  }
                  {
                    !loading && !showSuccessMsg &&
                    <>Update</>
                  }
                  {
                    !loading && showSuccessMsg &&
                    <><i className="bi bi-check-circle"></i> Updated</>
                  }
                </button>
              </div>
            } */}
          </div>
        </div>
      </div>
    </div>
  )
}