import {
    UserEmailState,
    UserEmailActionTypes,
    USER_EMAIL
} from './types';

const initialState: UserEmailState = {
    pending: false,
    userEmail: { email: '' },
    userEmailResponse: { email: '', emailVerified: '', status: { error: false, msg: '' } },
    error: null
};

export const userEmailReducer = (state = initialState, action: UserEmailActionTypes) => {
    const NewState = { ...state }
    switch (action.type) {
        case USER_EMAIL.LOAD_ERROR:
            return {
                ...state,
                pending: false,
                error: action.error
            }
        case USER_EMAIL.LOAD_PENDING:
            return {
                ...state,
                pending: true,
                userEmail: action.payload
            }
        case USER_EMAIL.LOAD_SUCCESS:
            return {
                ...state,
                pending: false,
                userEmailResponse: action.userInfo
            }
        default:
            return NewState;

    }
};