import React from 'react';
import { IAppState } from '../../store';
import { connect } from 'react-redux';
interface Props {
}
interface State {
}
class NoPermission extends React.Component<Props, State> {
    constructor(props: any) {
        super(props);
        this.state = {
        }
    }
    render() {
        return (
            <>
                <div className="noper d-flex flex-row align-items-center">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-md-12 text-center">
                                <span className="display-1 d-block">404</span>
                                <div className="mb-4 lead">You don't have permission to access this page.</div>
                                <a href="/" className="btn btn-primary">Back to Home</a>
                            </div>
                        </div>
                    </div>
                </div>

            </>
        );
    }
}

const mapStateToProps = (state: IAppState) => ({
});
const mapDispatchToProps = (dispatch: any) => ({
});
export default connect(mapStateToProps, mapDispatchToProps)(NoPermission);