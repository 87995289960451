import { LOGINFORGOTSENT, LoginForgotSent, FetchLoginForgotSentSuccessAction, FetchLoginForgotSentErrorAction, FetchLoginForgotSentPendingAction } from './types';
/* Action Creators */
export const fetchLoginForgotSentPending = (loginForgotSent: LoginForgotSent): FetchLoginForgotSentPendingAction => {
    return {
        type: LOGINFORGOTSENT.LOAD_PENDING,
        payload: loginForgotSent
    };
};

export const fetchLoginForgotSentSuccess = (loginForgorSent: LoginForgotSent): FetchLoginForgotSentSuccessAction => {
    return {
        type: LOGINFORGOTSENT.LOAD_SUCCESS,
        loginforgotsent: loginForgorSent
    }
}

export const fetchLoginForgotSentError = (err: any): FetchLoginForgotSentErrorAction => {
    return {
        type: LOGINFORGOTSENT.LOAD_ERROR,
        error: err
    }
}