import configs from "../configs/apiConfigs";
import { CommonApiOptions, METHOD_TYPE } from "../constants/api";
import { commonApi } from "../helpers/api";
import { attachHeader } from "../utils/apiHandler";

export const getProviderByName = (searchText: any, clinicUniqueId: any) => {
    const request: CommonApiOptions = {
        url: configs.API_URL + 'clinic/' + clinicUniqueId + '/?q=' + searchText,
        method: METHOD_TYPE.GET,
        apiOptions: {
            headers: attachHeader(true),
            withCredentials: true
        }
    };
    return commonApi(request);
}