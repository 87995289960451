import React, { useEffect } from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import $ from 'jquery';
import { useDispatch } from 'react-redux';
import { removeChatPin } from '../../../store/chat/action';
import firebase from 'firebase';
import { useState } from 'react';
import configs from '../../configs/apiConfigs';
import { USER_TYPE } from '../../constants/constants';
import moment from 'moment';
import nl2br from 'react-nl2br';
import { Avatar } from '@material-ui/core';
import { chatNotificationProvider } from '../../services/chat';
let db = firebase.firestore();
let groupMessageListener: any;
export const GroupPin = (props: any) => {
    const { data, handleCloseChat } = props;
    const [patientDoc, setPatientDoc]: any = useState();
    const [providerDoc, setProviderDoc]: any = useState();
    const [clinicDoc, setClinicDoc]: any = useState();
    const [messageList, setMessageList] = useState([]);
    const [message, setMessage] = useState('');
    const [patientAvatarLetter, setpatientAvatarLetter] = useState('');
    const [providerAvatarLetter, setproviderAvatarLetter] = useState('');

    const dispatch = useDispatch();
    useEffect(() => {
        console.log('GroupData', props.data);
        const { data } = props;
        setPatientDoc(data.patient);
        setProviderDoc(data.doctor);
        setClinicDoc(data.clinic);
        groupMessage();
        if (props.data.patient.fname && props.data.patient.lname) {
            setpatientAvatarLetter(props.data.patient.fname.charAt(0) + props.data.patient.lname.charAt(0));
        }
        if (props.data.patient.fname && !props.data.patient.lname) {
            setpatientAvatarLetter(props.data.patient.fname.charAt(0));
        }
        if (props.data.doctor.fullname) {
            let doctorName: any = props.data.doctor.fullname;
            doctorName = doctorName.trim();
            doctorName = doctorName.split(" ");
            setproviderAvatarLetter(doctorName[0] ? doctorName[0].charAt(0) : '' + doctorName[1] ? doctorName[1].charAt(0) : '');
        }
        if (props.selectedAppointment) {
            props.handleCloseChat(data.appointmentId);
        }

    }, []);

    const groupMessage = () => {
        groupMessageListener = db.collection(configs.MESSAGE_COLLECTION).doc(`${data.groupId}`).onSnapshot((snapshot) => {
            const data = snapshot.data();
            console.log(data);
            setMessageList(data?.messages || []);
            scrollToBottom();
        });
    }

    const scrollToBottom = () => {
        $("#chat_fullscreen").animate({ scrollTop: $('#chat_fullscreen').prop("scrollHeight") }, 50);
    }

    const sendMessage = async () => {
        if (message.trim() !== '') {
            const docRef = db.collection(configs.MESSAGE_COLLECTION).doc(`${data.groupId}`);
            let clinicDoc = await db.collection(configs.USER_COLLECTION).where('uniqueId', '==', data.clinic.uniqueid).get();
            let clinicFirebaseId = clinicDoc.docs.map((doc) => ({ ...doc.data, id: doc.id }))[0].id;
            docRef.update('messages', firebase.firestore.FieldValue.arrayUnion({
                messageText: message,
                senderType: USER_TYPE.CLINIC,
                sentBy: clinicFirebaseId,
                sentAt: new Date(),
                readBy: [],
                isInfo: false
            }));
            if (!(props.data && props.data.requestAcceptedByProvider && props.data.requestAcceptedByProvider.accepted === false)) {
                chatNotificationProvider(data.doctor.uniqueid, {
                    title: "New message from " + data.clinic.name,
                    body: message,
                    notifications: {
                        appointmentmentUniqueId: data.appointmentUniqueId,
                        groupDocId: data.groupId
                    }
                }).then((success: any) => { }).catch((err: any) => { });
            }
            setMessage('');
            scrollToBottom()
        }
    }

    const handleRemoveChat = () => {
        $('.prime' + data.appointmentId).toggleClass('zmdi-comment-outline');
        $('.prime' + data.appointmentId).toggleClass('zmdi-close');
        $('.prime' + data.appointmentId).toggleClass('is-active');
        $('.prime' + data.appointmentId).toggleClass('is-visible');
        $('#prime' + data.appointmentId).toggleClass('is-float');
        $('.chat' + data.appointmentId).toggleClass('is-visible');
        $('.fab' + data.appointmentId).toggleClass('is-visible');
        dispatch(removeChatPin(data.appointmentId));
    }

    const closeGroup = async () => {
        let clinicDoc = await db.collection(configs.USER_COLLECTION).where('uniqueId', '==', data.clinic.uniqueid).get();
        let clinicFirebaseId = clinicDoc.docs.map((doc) => ({ ...doc.data, id: doc.id }))[0].id;
        db.collection(configs.GROUP_COLLECTION).doc(`${data.groupId}`).update({
            'isClosed': true,
            'closedAt': firebase.firestore.FieldValue.serverTimestamp(),
            'closedBy': clinicFirebaseId
        });
        const docRef = db.collection(configs.MESSAGE_COLLECTION).doc(`${data.groupId}`);
        docRef.update('messages', firebase.firestore.FieldValue.arrayUnion({
            messageText: 'Close',
            senderType: USER_TYPE.CLINIC,
            sentBy: clinicFirebaseId,
            sentAt: new Date(),
            readBy: [],
            isInfo: true
        }));
        $('.prime' + data.appointmentId).toggleClass('zmdi-comment-outline');
        $('.prime' + data.appointmentId).toggleClass('zmdi-close');
        $('.prime' + data.appointmentId).toggleClass('is-active');
        $('.prime' + data.appointmentId).toggleClass('is-visible');
        $('#prime' + data.appointmentId).toggleClass('is-float');
        $('.chat' + data.appointmentId).toggleClass('is-visible');
        $('.fab' + data.appointmentId).toggleClass('is-visible');
        dispatch(removeChatPin(data.appointmentId));
    }
    return (
        <div className={"fabs fabs" + data.appointmentId}
            id={'main' + data.appointmentId} key={"main" + data.appointmentId}>
            <div className={"chat chat" + data.appointmentId} id={'Chats' + data.appointmentId}>
                <div className="chat_header group-chat-header">
                    <div className="actionBtn">
                        <div className="d-flex justify-content-end">
                            {
                                data.isClosed !== true &&
                                <OverlayTrigger
                                    placement="auto"
                                    overlay={
                                        <Tooltip id="tooltip">
                                            Close group
                                        </Tooltip>
                                    }
                                >
                                    <a href="#" className="btn p-1 text-white ms-1 group-chat-link" onClick={() => {
                                        closeGroup();
                                    }}>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="19.502" height="12" viewBox="0 0 19.502 12"><g transform="translate(-13275.249 -6357)"><g transform="translate(13278 6355)"><path d="M7,14a.979.979,0,0,1-1-1c0-1,1-4,5-4s5,3,5,4a.979.979,0,0,1-1,1Zm4-6A3,3,0,1,0,8,5,3,3,0,0,0,11,8Z" fill="#fff" /><path d="M5.216,14A2.238,2.238,0,0,1,5,13,4.773,4.773,0,0,1,6.936,9.28,6.325,6.325,0,0,0,5,9c-4,0-5,3-5,4a.979.979,0,0,0,1,1Z" fill="#fff" fill-rule="evenodd" /><path d="M4.5,8A2.5,2.5,0,1,0,2,5.5,2.5,2.5,0,0,0,4.5,8Z" fill="#fff" /></g><line y1="11" x2="19" transform="translate(13275.5 6357.5)" fill="none" stroke="#fff" stroke-width="1" /></g></svg>
                                    </a>
                                </OverlayTrigger>
                            }

                            <OverlayTrigger
                                placement="auto"
                                overlay={
                                    <Tooltip id="tooltip">
                                        Minimize chat
                                    </Tooltip>
                                }
                            >
                                <button className="btn p-1 text-white ms-1" type="button" onClick={() => {
                                    handleCloseChat(data.appointmentId);
                                }}>
                                    <i className="bi bi-dash-circle"></i>
                                </button>
                            </OverlayTrigger>
                            <OverlayTrigger
                                placement="auto"
                                overlay={
                                    <Tooltip id="tooltip">
                                        Close chat
                                    </Tooltip>
                                }
                            >
                                <button className="btn p-1 text-white ms-1" type="button" onClick={() => {
                                    handleRemoveChat();
                                }}>
                                    <i className="bi bi-x-circle"></i>
                                </button>
                            </OverlayTrigger>
                        </div>
                    </div>
                    <div className="d-flex justify-content-between align-items-center">
                        <div className="d-flex align-items-center">
                            {
                                patientDoc && providerDoc && clinicDoc &&
                                <div className="avatar rounded-circle bg-white text-dark text-uppercase flex-shrink-0">{patientDoc.fname.charAt(0) + providerDoc.fullname.charAt(0) + clinicDoc.name.charAt(0)}</div>
                            }
                            <div className="flex-grow-1 ms-3">
                                <div id={"chat_head" + data.appointmentId} className="d-block fw-medium text-white charlimit lh-base">
                                    With - {patientDoc?.fname + " " + patientDoc?.lname}, {providerDoc?.fullname}
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
                <div id={"chat_fullscreen " + data.appointmentId} className="chat_conversion chat_converse">
                    <div>
                        {
                            moment(data.date, 'YYYY-MM-DD').format('MMM DD, YYYY') + " (" + data.time + " - " + data.endtime + ")"
                        }
                    </div>
                    <div>
                        {
                            `${data.dependant.fname + " " + data.dependant.lname + " - " + data.appointmentType} with ${data.doctor.fullname}`
                        }
                    </div>
                    <div className="alert alert-light border py-2 text-center">
                        <label className="group-chat-label">Three way chat activated, patient and provider can see chat now.</label>
                    </div>
                    {
                        messageList.map((message: any, index) => {
                            if (message.isInfo === true) return null;
                            return (
                                <div className={"chat_msg_item " + (message.senderType === USER_TYPE.CLINIC ? 'chat_msg_item_user' : 'chat_msg_item_admin')} key={index + data.appointmentId}>
                                    {
                                        (patientAvatarLetter && message.senderType === USER_TYPE.PATIENT) &&
                                        <div className="avatar avatar-icon avatar-md newAvtr">
                                            <Avatar>{patientAvatarLetter}</Avatar>
                                        </div>
                                    }
                                    {
                                        (providerAvatarLetter && message.senderType === USER_TYPE.PROVIDER) &&
                                        <div className="avatar avatar-icon avatar-md newAvtr">
                                            <Avatar>{providerAvatarLetter}</Avatar>
                                        </div>
                                    }
                                    <div className="chat-bubble">
                                        {nl2br(message.messageText)}
                                    </div>
                                    <span className="msg_date">
                                        {
                                            message &&
                                            message.sentAt &&
                                            <> {moment(moment.unix(message.sentAt.seconds)).format("MMM DD, YYYY - LT")}
                                                {
                                                    (message.senderType === USER_TYPE.PATIENT) &&
                                                    <><br />sent by {data.dependant.fname + " " + data.dependant.lname}</>
                                                }
                                                {
                                                    (message.senderType === USER_TYPE.PROVIDER) &&
                                                    <><br />sent by {data.doctor.fullname}</>
                                                }
                                            </>
                                        }
                                    </span>
                                </div>
                            )
                        })
                    }
                    {
                        props.data && props.data.requestAcceptedByProvider && props.data.requestAcceptedByProvider.accepted === false &&
                        <div className="alert alert-info py-2 text-center">
                            Provider has rejected the request - <span>{moment(moment.unix(props.data.requestAcceptedByProvider.updatedAt.seconds)).format("MMM DD, YYYY - LT")}</span>
                        </div>
                    }
                </div>
                <div className="fab_field">
                    <textarea id={"chatSend" + data.appointmentId} disabled={data.isClosed} name="chat_message"
                        onKeyDown={(e) => {
                            if (e.keyCode === 13) {
                                sendMessage();
                            }
                        }}
                        value={message}
                        onChange={(e) => {
                            if (e.target.value.length > 160) {
                                return;
                            }
                            setMessage(e.target.value);
                        }} placeholder="Send a message" className="chat_field chat_message"></textarea>
                    <a id="fab_send" className={`fab is-visible ${data.isClosed === true && 'disabled'}`}
                        onClick={(e) => {
                            sendMessage();
                        }}
                    >
                        <span className="material-icons">
                            send
                        </span>
                    </a>
                </div>

            </div>


            <div id={"prime" + data.appointmentId} className={"group-pin-icon fab avatar avatar-lg rounded-circle text-uppercase fab" + data.appointmentId} onClick={() => {
                handleCloseChat(data.appointmentId);
            }}>
                {
                    patientDoc && providerDoc && clinicDoc &&
                    <div className="fab-bcn">{patientDoc.fname.charAt(0) + providerDoc.fullname.charAt(0) + clinicDoc.name.charAt(0)}</div>
                }
                <div className="fab-bic"><i className="bi bi-chevron-down"></i></div>
                <span className="position-absolute pintype"><i className="bi bi-geo-alt-fill"></i></span>
            </div>

        </div>
    )
}