import React, { useEffect, useState } from 'react';
import { StandardEvent } from '@fullcalendar/react';
import { UserDataResponse } from '../../store/userData/types';
import { connect } from 'react-redux';
import { IAppState } from '../../store';
import { myPlan, cancelSubscription } from '../../store/subscriptionplan/actions';
import { ClinicMyplanRequest, SubscriptionState, CancelSubscriptionRequest } from '../../store/subscriptionplan/types';
import { toast } from 'react-toastify';
import moment from 'moment';
// import { Modal } from '@material-ui/core';
import { Alert, Col, Form, Button, Modal } from 'react-bootstrap';

interface Props {
  userDataResponse: UserDataResponse;
  myPlan: typeof myPlan;
  subscriptionState: SubscriptionState;
  cancelSubscription: typeof cancelSubscription;
  history: any;
};

interface State {
  myPlanList: any;
  selectedClinic: string;
  showConfirmationModal: boolean;
  selectedPlan?: any;
};

class MyPlans extends React.Component<Props, State>{
  constructor(props: any) {
    super(props);
    this.state = {
      selectedClinic: '',
      myPlanList: [],
      showConfirmationModal: false,
    }
  }
  componentDidUpdate(prevProps: Props, prevState: State) {
    if (prevProps.userDataResponse.clinics !== this.props.userDataResponse.clinics && this.props.userDataResponse.clinics.length > 0) {
      this.setState({ selectedClinic: this.props.userDataResponse.clinics[0].uniqueId });
      this.props.myPlan({ clinicUniqueId: this.props.userDataResponse.clinics[0].uniqueId });
    }
    if (this.props.subscriptionState !== prevProps.subscriptionState) {
      if (this.props.subscriptionState.error) {
        toast.error(this.props.subscriptionState.error);
      } else if (this.props.subscriptionState.clinicMyplanResponce !== prevProps.subscriptionState.clinicMyplanResponce &&
        this.props.subscriptionState.clinicMyplanResponce && this.props.subscriptionState.clinicMyplanResponce.status.error === false) {
        this.setState({
          myPlanList: this.props.subscriptionState.clinicMyplanResponce.plans ? this.props.subscriptionState.clinicMyplanResponce.plans : [],
        })
      } else if (this.props.subscriptionState.cancelSubscriptionResponse !== prevProps.subscriptionState.cancelSubscriptionResponse &&
        this.props.subscriptionState.cancelSubscriptionResponse && this.props.subscriptionState.cancelSubscriptionResponse.status.error === false) {
        this.props.myPlan({ clinicUniqueId: this.state.selectedClinic });
        toast.success(this.props.subscriptionState.cancelSubscriptionResponse.status.msg);
        this.hideModal();
      }
    }
  }
  selectedClinicChange = (uniqueId: string) => {
    this.setState({
      selectedClinic: uniqueId,
    });
    this.props.myPlan({ clinicUniqueId: uniqueId });
  }
  hideModal() {
    this.setState({ showConfirmationModal: false, selectedPlan: null });
  }
  render() {
    return (
      <>
        <div className="row">
          <div className="col-sm-12">
            <p>Coming soon</p>
            {(this.props.subscriptionState.pending === false

              && this.state.myPlanList && this.state.myPlanList.find((item: any) => { return item && item.subscribedStatus !== 'active' }) &&
              this.state.myPlanList.find((item: any) => { return item && item.subscribedStatus !== 'active' }).length > 0) &&
              <div className="alert alert-danger py-2 mt-4" role="alert">
                <div className="d-flex justify-content-between align-items-center">
                  <p className="m-0">Hey, You don't have any activated plan, please choose your plan.</p>
                  <button className="btn btn-danger ms-3">Choose your plan</button>
                </div>
              </div>
            }
            <div className="d-flex justify-content-end">
              <ClinicDropDown clinicList={this.props.userDataResponse.clinics} onChange={(id) => { this.selectedClinicChange(id); }}></ClinicDropDown>
            </div>
            <div className="table-responsive myplans">
              {!(this.props.subscriptionState.pending === false &&
                this.props.subscriptionState.clinicMyplanResponce &&
                this.state.myPlanList.length === 0) &&
                <table className="table">
                  <thead>
                    <tr>
                      <th>Sr No.</th>
                      <th className="w-20">Plan</th>
                      <th>Autorenew on</th>
                      <th>Price</th>
                      <th>Status</th>
                      <th className="w-20 text-right">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.myPlanList && this.state.myPlanList.map((value: any, index: number) =>
                      <tr className="odd">
                        <td>{index + 1}</td>
                        <td>{value.planName}</td>
                        <td>{moment.utc(value.subscribedEndDate).local().format('MMM DD, YYYY - LT')}</td>
                        <td>{value.amount}</td>
                        <td><span className="badge badge-success" style={{ textTransform: 'capitalize' }}>{value.subscribedStatus}</span></td>
                        <td className="text-right">
                          {
                            (value.subscribedStatus === "active") &&
                            <a href="#" className="btn btn-primary btn-xs m-r-5"
                              onClick={(e) => {
                                this.setState({ showConfirmationModal: true, selectedPlan: value });
                              }}>Cancel Subscription</a>
                          }
                        </td>
                      </tr>)}
                  </tbody>
                </table>}
              {
                (this.props.subscriptionState.pending === false &&
                  this.props.subscriptionState.clinicMyplanResponce &&
                  this.state.myPlanList.length === 0) &&
                <div className="alert alert-danger py-2 mt-4" role="alert">
                  <div className="d-flex justify-content-between align-items-center">
                    <p className="m-0">Hey, You don't have any activated plan, please choose your plan.</p>
                    <button className="btn btn-danger ms-3">Choose your plan</button>
                  </div>
                </div>
              }
            </div>

          </div>
        </div>
        <Modal show={this.state.showConfirmationModal} onHide={this.hideModal.bind(this)} centered>
          <Modal.Header closeButton>
            <Modal.Title>Cancel Subscription</Modal.Title>
          </Modal.Header>
          <Modal.Body>Are you sure you want to cancel subscription?</Modal.Body>
          <Modal.Footer>
            <Button onClick={() => {
              this.props.cancelSubscription(
                {
                  clinicUniqueId: this.state.selectedClinic,
                  data: { subscriptions: [{ subscribed_subscription_id: this.state.selectedPlan.subscribedId, id: this.state.selectedPlan.id }] }
                }
              )
            }} >Yes</Button>
            <Button onClick={() => { this.hideModal() }}>No</Button>
          </Modal.Footer>
        </Modal>
      </>
    )
  }

}
const mapStateToProps = (state: IAppState) => ({
  userDataResponse: state.userData.userDataResponse,
  subscriptionState: state.subscriptionPlan

});
const mapDispatchToProps = (dispatch: any) => ({
  myPlan: (request: ClinicMyplanRequest) => {
    dispatch(myPlan(request));
  },
  cancelSubscription: (request: CancelSubscriptionRequest) => {
    dispatch(cancelSubscription(request))
  }

});
export default connect(mapStateToProps, mapDispatchToProps)(MyPlans);

interface ClinicDropDownProps {
  clinicList: any[];
  onChange: (uniqueId: string) => void;
}
export const ClinicDropDown: React.FC<ClinicDropDownProps> = (props: ClinicDropDownProps) => {
  const [selectedClinic, selectClinic] = useState(props.clinicList[0] ? props.clinicList[0].uniqueId : '');
  useEffect(() => {
    if (selectedClinic) {
      props.onChange(selectedClinic);
    }
  }, [selectedClinic]);
  return (
    <select className="custom-select m-r-15" style={{ width: "240px" }} value={selectedClinic} onChange={(e) => {
      selectClinic(e.target.value);
    }}>
      {
        props.clinicList.map((value, index) => {
          return (
            <option value={value.uniqueId} key={index + Math.random()}>{value.name}</option>
          )
        })
      }
    </select>
  );
}


