import { USER_EMAIL, FetchUserEmailErrorAction, FetchUserEmailPendingAction, FetchUserEmailSuccessAction, UserEmailRequest, UserEmailResponse } from './types';
/* Action Creators */
export const fetchUserEmailPending = (userEmail: UserEmailRequest): FetchUserEmailPendingAction => {
    return {
        type: USER_EMAIL.LOAD_PENDING,
        payload: userEmail
    };
};

export const fetchUserEmailSuccess = (userEmailResponse: UserEmailResponse): FetchUserEmailSuccessAction => {
    return {
        type: USER_EMAIL.LOAD_SUCCESS,
        userInfo: userEmailResponse
    }
};

export const fetchUserEmailError = (err: any): FetchUserEmailErrorAction => {
    return {
        type: USER_EMAIL.LOAD_ERROR,
        error: err
    }
};