import { AppointmentRequest, AppointmentPendingAction, APPOINTMENT, AppointmentResponse, AppointmentSuccessAction, AppointmentErrorAction, AppointmentDetail, AppointmentDetailRequest, AppointmentDetailError, AppointmentDetailResponse, AppointmentDetailSuccess, ModalEmailInfoRequest, ModalEmailInfoPending, ModalEmailInfoError, ModalEmailInfoSuccess, ManualAppointmentRequest, ManualAppointmentPendingAction, ManualAppointmentFailureAction, ManualAppointmentSuccessAction, RequestForMedicalHistoryErrorAction, RequestForMedicalHistoryRequest, RequestForMedicalHistoryPendingAction, RequestForMedicalHistorySuccessAction, RequestForMedicalHistoryResponse, AppointmentEditRequest, AppointmentEditPendingAction, AppointmentEditFailureAction, AppointmentEditResponse, AppointmentEditSuccessAction, BookSlotRequest, BookSlotPendingAction, BookSlotFailureAction, BookSlotSuccessAction, BookSlotResponse, AppointmentUnverifiedPendingAction, AppointmentUnverifiedRequest, AppointmentUnverifiedResponse, AppointmentUnverifiedSuccesAction, AppointmentUnverifiedErrorAction } from './types';
export const appointmentPendingAction = (request: AppointmentRequest): AppointmentPendingAction => {
    return {
        type: APPOINTMENT.APPOINTMENT_LOAD_PENDING,
        payload: request
    }
}
export const appointmentSuccessAction = (response: AppointmentResponse): AppointmentSuccessAction => {
    return {
        type: APPOINTMENT.APPOINTMENT_LOAD_SUCCESS,
        appointment: response
    }
}
export const appointmentErrorAction = (error: any): AppointmentErrorAction => {
    return {
        type: APPOINTMENT.APPOINTMENT_LOAD_ERROR,
        error: error
    }
}

export const appointmentDetail = (request: AppointmentDetailRequest): AppointmentDetail => ({
    type: APPOINTMENT.APPOINTMENT_DETAIL,
    payload: request
});
export const appointmentDetailFailure = (error: any): AppointmentDetailError => ({
    type: APPOINTMENT.APPOINTMENT_DETAIL_FAILURE,
    error: error
});
export const appointmentDetailSuccess = (response: AppointmentDetailResponse): AppointmentDetailSuccess => ({
    type: APPOINTMENT.APPOINTMENT_DETAIL_SUCCESS,
    payload: response
});

export const modalEmailInfoPendingAction = (request: ModalEmailInfoRequest): ModalEmailInfoPending => ({
    type: APPOINTMENT.MODAL_EMAIL_INFO,
    payload: request
});
export const modalEmailInfoFailureAction = (error: any): ModalEmailInfoError => ({
    type: APPOINTMENT.MODAL_EMAIL_INFO_FAILURE,
    payload: error
});
export const modalEmailInfoSuccessAction = (response: any): ModalEmailInfoSuccess => ({
    type: APPOINTMENT.MODAL_EMAIL_INFO_SUCCESS,
    payload: response
});

export const manualAppointmentPendingAction = (request: ManualAppointmentRequest): ManualAppointmentPendingAction => ({
    type: APPOINTMENT.MANUAL_APPOINTMENT,
    payload: request
});
export const manualAppointmentFailureAction = (error: any): ManualAppointmentFailureAction => ({
    type: APPOINTMENT.MANUAL_APPOINTMENT_FAILURE,
    payload: error
});
export const manualAppointmentSuccessAction = (response: any): ManualAppointmentSuccessAction => ({
    type: APPOINTMENT.MANUAL_APPOINTMENT_SUCCESS,
    payload: response
});
export const requestForMedicalHistoryError = (error: any): RequestForMedicalHistoryErrorAction => ({
    type: APPOINTMENT.REQUEST_FOR_MEDICAL_HISTORY_ERROR,
    payload: error
});
export const requestForMedicalHistoryPending = (request: RequestForMedicalHistoryRequest): RequestForMedicalHistoryPendingAction => ({
    type: APPOINTMENT.REQUEST_FOR_MEDICAL_HISTORY,
    payload: request
});
export const requestForMedicalHistorySuccess = (response: RequestForMedicalHistoryResponse): RequestForMedicalHistorySuccessAction => ({
    type: APPOINTMENT.REQUEST_FOR_MEDICAL_HISTORY_SUCCESS,
    payload: response
});
export const appointmentEditPending = (request: AppointmentEditRequest): AppointmentEditPendingAction => ({
    type: APPOINTMENT.EDIT_APPOINTMENT,
    payload: request
});
export const appointmentEditFailure = (error: any): AppointmentEditFailureAction => ({
    type: APPOINTMENT.EDIT_APPOINTMENT_FAILURE,
    payload: error
});
export const appointmentEditSuccess = (response: AppointmentEditResponse): AppointmentEditSuccessAction => ({
    type: APPOINTMENT.EDIT_APPOINTMENT_SUCCESS,
    payload: response
});
export const bookSlotPending = (request: BookSlotRequest): BookSlotPendingAction => ({
    type: APPOINTMENT.BOOK_SLOT,
    payload: request
});
export const bookSlotFailure = (error: any): BookSlotFailureAction => ({
    type: APPOINTMENT.BOOK_SLOT_FAILURE,
    payload: error
});
export const bookSlotSuccess = (response: BookSlotResponse): BookSlotSuccessAction => ({
    type: APPOINTMENT.BOOK_SLOT_SUCCESS,
    payload: response
});
export const appointmentUnverifiedPending = (request: AppointmentUnverifiedRequest): AppointmentUnverifiedPendingAction => ({
    type: APPOINTMENT.APPOINTMENT_UNVERIFIED,
    payload: request
});
export const appointmentUnverifiedSuccess = (response: AppointmentUnverifiedResponse): AppointmentUnverifiedSuccesAction => ({
    type: APPOINTMENT.APPOINTMENT_UNVERIFIED_SUCCESS,
    payload: response
});
export const appointmentUnverifiedError = (error: any): AppointmentUnverifiedErrorAction => ({
    type: APPOINTMENT.APPOINTMENT_UNVERIFIED_FAILED,
    payload: error
});

export const callWaitlistApi = () => ({
    type: APPOINTMENT.CALL_WAITLIST_API
})

export const callEmergencyApi = () => ({
    type: APPOINTMENT.CALL_EMERGENCY_API
})