// import './_practiceSteps.scss';
import React, { useEffect, useRef, useState } from 'react';
import InputMask from 'react-input-mask';
import { takeOnlyDigitAndPlus } from '../../../utils/global';
import { useForm, Controller } from 'react-hook-form';
import { checkPracticeEmail, checkPracticeName, checkPracticePhone, createStep } from '../../services/clinicOnboard';
import { useDispatch } from 'react-redux';
import { fetchLoadingPending, fetchLoadingSuccess } from '../../../store/loadingIndicator/actions';
import RemoveBtn from "../../assets/images/remove-btn.svg";
export function PracticeApp2(props: any) {
	const [previewFile, setPreviewFile] = useState('');
	const [selectedFile, setSelectedFile] = useState('');
	const [imageError, setImageError] = useState(false);
	const dispatch = useDispatch();
	const { register, handleSubmit, errors, setError, setValue, control, getValues, trigger, clearErrors } = useForm();
	let uploadRef: any = useRef();
	const onFileChange = (event: any) => {
		const file = event.target.files[0];
		if (file && (file.type === "image/png" ||
			file.type === 'image/jpg' ||
			file.type == 'image/jpeg')) {
			setSelectedFile(event.target.files[0]);
			var reader: any = new FileReader();
			var url: any = reader.readAsDataURL(event.target.files[0]);
			reader.onloadend = function (e: any) {
				//Initiate the JavaScript Image object.
				var image = new Image();
				//Set the Base64 string return from FileReader as source.
				image.src = e.target.result;
				//Validate the File Height and Width.
				image.onload = function () {
					var height = image.height;
					var width = image.width;
					if (height < 256 || width < 256) {
						setImageError(true);
						return false;
					}
					setImageError(false);
					setPreviewFile(reader.result);
					return true;
				};
			};
			return;
		} else {
			setImageError(true);
		}
	}
	const onNext = (data: any) => {
		if (imageError) {
			return;
		}
		// if (selectedFile === '') {
		// 	setImageError(true);
		// 	return;
		// }
		setImageError(false);
		const request = {
			practicePhoto: selectedFile,
			practiceName: data.practiceName,
			phoneNumber: data.phoneNumber,
			email: data.email,
			website: data.website
		};
		const requestData = {
			logo: selectedFile,
			name: data.practiceName,
			phone: data.phoneNumber,
			email: data.email,
			website: data.website,
			uniqueid: props.clinicUniqueId
		};
		dispatch(fetchLoadingPending());
		createStep({
			stepNumber: 2,
			formData: requestData
		}).then((success) => {
			dispatch(fetchLoadingSuccess(false));
			if (success.status.error === false) {
				props.onNext(2, { generalInfo: request });
			}
		}).catch((err) => {
			dispatch(fetchLoadingSuccess(false));
		});
	}
	useEffect(() => {
		if (props.data) {
			setPreviewFile(props.data.practicePhoto);
			setSelectedFile(props.data.practicePhoto);
			setValue("practiceName", props.data.practiceName);
			setValue("email", props.data.email);
			setValue("phoneNumber", props.data.phoneNumber);
			setValue("website", props.data.website);
		}
	}, [props.data]);
	const onSubmit = (data: any) => {
		if (data.phoneNumber.length < 10) {
			setError("phoneNumber", { type: "minLength", message: "Mobile Number is invalid" });
			return;
		}
		onNext(data);
	};
	const checkPracticeNameFunc = async () => {
		try {
			const practiceName = getValues('practiceName');
			const isValid = await trigger('practiceName');
			if (isValid) {
				dispatch(fetchLoadingPending());
				await checkPracticeName(practiceName);
				dispatch(fetchLoadingSuccess(false));
				clearErrors('practiceName');
			}
		} catch (err: any) {
			dispatch(fetchLoadingSuccess(false));
			if (err && err.error) {
				setError('practiceName', { type: "manual", message: err.msg });
				return;
			}
		}
	};
	const checkPracticeEmailFunc = async () => {
		try {
			const email = getValues('email');
			const isValid = await trigger('email');
			if (isValid) {
				dispatch(fetchLoadingPending());
				await checkPracticeEmail(email);
				dispatch(fetchLoadingSuccess(false));
				clearErrors('email');
			}
		} catch (err: any) {
			dispatch(fetchLoadingSuccess(false));
			if (err && err.error) {
				setError('email', { type: "manual", message: err.msg });
				return;
			}
		}
	};
	const checkPracticePhoneFunc = async () => {
		try {
			const phoneNumber = getValues('phoneNumber');
			const isValid = !(phoneNumber.length < 10);
			if (isValid) {
				dispatch(fetchLoadingPending());
				await checkPracticePhone(phoneNumber);
				dispatch(fetchLoadingSuccess(false));
				clearErrors('phoneNumber');
			}
		} catch (err: any) {
			dispatch(fetchLoadingSuccess(false));
			if (err && err.error) {
				setError('phoneNumber', { type: "manual", message: err.msg });
				return;
			}
		}
	}
	return (
		<>
			<form onSubmit={handleSubmit(onSubmit)}>
				<div className="row">
					<div className="col-12">
						<h2 className="mb-3">General info</h2>
						<div className="fw-medium fs-5">Add your practice information.</div>
						<p className="mt-1">Please ensure that all of the following information is correct.We will use this profile for google listing and marketing purposes.</p>
						<hr className="my-4" />
					</div>
				</div>
				<div className="row">
					<div className="col-12 mb-4">
						<div className="profile-logo-sec d-flex">
							<div className="img-box rounded-circle border position-relative">
								{
									previewFile !== '' &&
									<button className="btn position-absolute top-0 end-0 p-0 rounded-circle" onClick={(e) => {
										setSelectedFile('');
										setPreviewFile('');
										uploadRef.current.value = '';
									}}>
										<img src={RemoveBtn} />
									</button>
								}
								{
									previewFile !== '' ?
										<img className="rounded-circle" src={previewFile} height={120} width={120}></img> :
										null
								}
							</div>
							<div className="txt-box align-self-center ms-2 ms-md-4 m">
								<input type="file" ref={uploadRef} style={{ display: 'none' }}
									onChange={onFileChange}></input>
								<button className="btn border d-block fw-normal" onClick={(e) => {
									uploadRef.current.click();
								}}>Upload profile logo</button>
								<span className="d-block mt-2 text-muted">At least 256 x 256px PNG or JPG file.</span>
								{
									imageError &&
									<div className="is-invalid">Required</div>
								}
							</div>
						</div>
					</div>
					<div className="col-12">
						<div className="row">
							<div className="col-sm-6 mb-4">
								<label htmlFor="practiceName" className="form-label">Practice name</label>
								<input type="text" className="form-control" id="practicetName" name="practiceName" placeholder="xyz clinic"
									ref={register({
										required: true,
										pattern: /^[ A-Za-z0-9_@./#&+-]*$/
									})} onBlur={checkPracticeNameFunc} />
								<small className="text-muted">Business name, Location name, etc...</small>
								{
									errors.practiceName && errors.practiceName.type === "required" &&
									<div className="is-invalid">Required</div>
								}
								{
									errors.practiceName && errors.practiceName.type === "pattern" &&
									<div className="is-invalid">Allow only A-Za-z0-9_@./#&+-</div>
								}
								{
									errors.practiceName && errors.practiceName.type === "manual" &&
									<div className="is-invalid">{errors.practiceName.message}</div>
								}
							</div>

							<div className="col-sm-6 mb-4">
								<label htmlFor="phoneNumber" className="form-label">Mobile Number</label>
								<Controller
									as={InputMask}
									control={control}
									mask="9999999999"
									name="phoneNumber"
									className="form-control"
									rules={{ required: true, minLength: { value: 10, message: 'Mobile Number is invalid' } }}
									onBlurCapture={checkPracticePhoneFunc}
								/>
								{
									errors.phoneNumber && errors.phoneNumber.type === "required" &&
									<div className="is-invalid">Required</div>
								}
								{
									errors.phoneNumber && errors.phoneNumber.type === "minLength" &&
									<div className="is-invalid">Mobile Number is invalid</div>
								}
								{
									errors.phoneNumber && errors.phoneNumber.type === "manual" &&
									<div className="is-invalid">{errors.phoneNumber.message}</div>
								}
							</div>
							<div className="col-sm-6 mb-4">
								<label htmlFor="emailAddress" className="form-label">Practice email address</label>
								<input type="email" className="form-control" id="emailAddress" name="email" placeholder="xyz@clinic.com" /* value={email} */
									/* onChange={(e) => { setEmail(e.target.value); }} */ ref={register({
									required: true,
									pattern: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
								})}
									onBlur={checkPracticeEmailFunc}
								/>
								<small className="text-muted">We’ll never share your email with anyone else.</small>
								{
									errors.email && errors.email.type === "required" &&
									<div className="is-invalid">Required</div>
								}
								{
									errors.email && errors.email.type === "pattern" &&
									<div className="is-invalid">Email is invalid</div>
								}
								{
									errors.email && errors.email.type === "manual" &&
									<div className="is-invalid">{errors.email.message}</div>
								}
							</div>

							<div className="col-sm-6 mb-4">
								<label htmlFor="website" className="form-label">Website</label>
								<input type="text" className="form-control" id="website" name="website" placeholder="www.xyz.com"
									ref={register({
										required: true,
										pattern: /^((https?|ftp|smtp):\/\/)?(www.)?[a-z0-9]+\.[a-z]+(\/[a-zA-Z0-9#]+\/?)*$/
									})} />
								{
									errors.website && errors.website.type === "required" &&
									<div className="is-invalid">Required</div>
								}
								{
									errors.website && errors.website.type === "pattern" &&
									<div className="is-invalid">Website is invalid</div>
								}
							</div>
						</div>
					</div>
				</div>
				<div className="row">
					<div className="col-12 my-4">
						<div className="bottom-btn-sec d-flex justify-content-between">
							<button className="btn border me-1" onClick={() => { props.onPrev(0) }}>Back</button>
							<button type="submit" disabled={Object.keys(errors).length !== 0} className="btn btn-primary ms-1">Next</button>
						</div>
					</div>
				</div>
			</form>
		</>
	);
}
