import { loadStripe, Stripe } from '@stripe/stripe-js';
import apiConfigs from '../../../configs/apiConfigs';
import { Elements, CardNumberElement, CardCvcElement, CardExpiryElement } from '@stripe/react-stripe-js';
import React, { useState } from 'react';
// import './_practiceSteps.scss';
import { useDispatch } from 'react-redux';
import { fetchUserDataSuccess } from '../../../store/userData/actions';
import { addStripeCard } from '../../services/clinicOnboard';
import { fetchLoadingPending, fetchLoadingSuccess } from '../../../store/loadingIndicator/actions';
const stripePromise = loadStripe(apiConfigs.STRIPE_PUBLIC_KEY);
let cardNumberElement: any = null;
export function PracticeApp10(props: any) {
	const [stripeReady, setStripeReady] = useState(false);
	const [cardHolderName, setCardHolderName] = useState('');
	const [cardValidation, setCardValidation] = useState({ invalid: false, msg: '' });
	const dispatch = useDispatch();
	const cardNumberRef = (element: any) => {
		if (element) {
			dispatch(fetchLoadingPending());
			cardNumberElement = element;
			dispatch(fetchLoadingSuccess(false));
			setStripeReady(true);
		}
	};
	const createStripeToken = () => {
		try {
			dispatch(fetchLoadingPending());
			stripePromise.then((success: Stripe | null) => {
				if (success) {
					const stripe = success;
					stripe.createToken(cardNumberElement, { name: cardHolderName }).then((success: any) => {
						dispatch(fetchLoadingSuccess(false));
						if (success.error && (success.error.code === "incomplete_number" || success.error.code === "invalid_number")) {
							setCardValidation({ invalid: true, msg: success.error.message });
						} else if (cardHolderName === "") {
							setCardValidation({ invalid: true, msg: 'Card Holder name required' });
						} else if (success.error && (success.error.code === "incomplete_expiry" ||
							success.error.code === "invalid_expiry_year_past" ||
							success.error.code === "invalid_expiry_year")) {
							setCardValidation({ invalid: true, msg: success.error.message });
						} else if (success.error && (success.error.code === "incomplete_cvc")) {
							setCardValidation({ invalid: true, msg: success.error.message });
						} else if (success.error) {
							setCardValidation({ invalid: true, msg: success.error.message });
						} else if (success.token) {
							setCardValidation({ invalid: false, msg: '' });
							dispatch(fetchLoadingPending());
							addStripeCard({ stripeToken: success.token.id }).then((success) => {
								dispatch(fetchLoadingSuccess(false));
								if (success.status && success.status.error === false) {
									props.onFinish();
								}
							}).catch((err) => {
								dispatch(fetchLoadingSuccess(false));
							});
						}
					}).catch((err) => {
						dispatch(fetchLoadingSuccess(false));
					});
				}
			}, (error) => {
				dispatch(fetchLoadingSuccess(false));
			});
		} catch (err) {
			dispatch(fetchLoadingSuccess(false));
		}
	}
	const onFinish = () => {
		createStripeToken();
	}
	return (
		<>

			<div className="row justify-content-center">
				<div className="col-12">
					<h2 className="mb-3">Credit card information</h2>
					<div className="fw-medium fs-5">Add Your Billing Details.</div>
					<p className="mt-1">Add credit details to activate and control your subscription.</p>
					<hr className="my-4" />
				</div>
				<div className="col-12 card-stripe">
					<div className="row g-3">
						<Elements stripe={stripePromise}>
							{/* <form className="needs-validation px-3"> */}

							<div className="col-md-6">
								<label className="form-label">Card number</label>
								<div className="input-group has-validation">
									<CardNumberElement className={"form-control"}
										options={{ showIcon: true, placeholder: "1234 1234 1234 1234" }}
										onReady={cardNumberRef}></CardNumberElement>
								</div>
							</div>

							<div className="col-md-6">
								<label className="form-label">Name on card</label>
								<input type="text" className="form-control" placeholder="Name on card" value={cardHolderName} onChange={e => setCardHolderName(e.target.value)} />
							</div>
							<div className="col-md-4">
								<label htmlFor="cc-expiration" className="form-label">Expiration date (mm/yy)</label>
								<CardExpiryElement className="form-control"></CardExpiryElement>
							</div>
							<div className="col-md-2">
								<label htmlFor="cc-expiration" className="form-label">CVV</label>
								<CardCvcElement options={{ placeholder: "CVV" }} className="form-control"></CardCvcElement>
							</div>
							{/* 	<div className="col-12">
									<button className="btn btn-secondary d-block my-3 px-3">Save and continue <img src={require("./images/spinner-icon.svg")} className="btn-img" /></button>
									<small className="text-muted"><i>XCare doesn't store your payment card information. We are use stripe as our payment processor.</i></small>
								</div> */}

							{/* </form> */}
							{
								cardValidation.invalid === true &&
								<div className="is-invalid">
									{cardValidation.msg}
								</div>
							}
						</Elements>
					</div>
				</div>

				<div className="col-12 my-4">
					<div className="bottom-btn-sec d-flex justify-content-between">
						<button className="btn border me-1" onClick={() => { props.onPrev(9) }}>Back</button>
						<button className="btn btn-primary ms-1" disabled={!stripeReady} onClick={() => {
							onFinish();
						}}>Finish</button>
					</div>
				</div>

			</div>




		</>
	);
}
