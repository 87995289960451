import React from 'react';
import { PaymentHeader } from './PaymentHeader';
import AllInvoice from './AllInvoices';
import { Switch, Route, Redirect } from 'react-router-dom';
import Settings from './Settings';
import StripeConnect from './StripeConnect';
import Paid from './Paid';
import Outstanding from './Outstanding';
import { connect } from 'react-redux';
import { IAppState } from '../../store';
import { FetchInitPaymentRequest, PaymentState, FetchInitePaymentResponse, ServicesTypeRequest, ServicesTypeResponse } from '../../store/payment/types';
import { fetchInitPaymentPending, servicesTypePending } from '../../store/payment/actions';
import LoadingIndicator from '../LoadingIndicator/LoadingIndicator';
import { toast } from 'react-toastify';
import { UserDataResponse } from '../../store/userData/types';
import { CreateInvoice } from './CreateInvoice';
import $ from 'jquery';
interface Props {
    match: any;
    paymentState: PaymentState;
    location: any;
    fetchInitPayment: typeof fetchInitPaymentPending;
    servicesType: typeof servicesTypePending;
    history: any;
    userDataState: UserDataResponse | any;

}
interface State {
    paymentRespose?: FetchInitePaymentResponse;
    servicesType?: ServicesTypeResponse["serviceType"];
}
class Payment extends React.Component<Props, State> {
    constructor(props: any) {
        super(props);
        this.state = {

        }
    }
    componentDidMount() {
        console.log("user state", this.props.userDataState);
        if (this.props.userDataState && this.props.userDataState.accessEnabled && this.props.userDataState.accessEnabled.contactless_payments) {
            this.props.fetchInitPayment({});
            this.props.servicesType({});
        } else {
            toast.error('You dont have subscription for contactless payments');
            $(".main-sidebar").toggleClass("shrink");
            $(".main-middle-sec").toggleClass("shrink");
            this.props.history.replace('/')
        }
    }
    shouldComponentUpdate(nextProps: Props, nextState: State) {
        if (nextProps !== this.props) {
            if (nextProps.paymentState !== this.props.paymentState) {
                if (nextProps.paymentState.error !== null) {
                    if (nextProps.paymentState.error.status && nextProps.paymentState.error.status.msg) {
                        toast.error(nextProps.paymentState.error.status.msg);
                        return true;
                    }
                    toast.error('Something went wrong');
                } else if (nextProps.paymentState.fetchInitPaymentResponse &&
                    nextProps.paymentState.fetchInitPaymentResponse !== this.props.paymentState.fetchInitPaymentResponse &&
                    nextProps.paymentState.fetchInitPaymentResponse.status.error === false &&
                    nextProps.paymentState.pending === false) {
                    this.setState({ paymentRespose: nextProps.paymentState.fetchInitPaymentResponse });
                } else if (nextProps.paymentState.servicesTypeResponse &&
                    nextProps.paymentState.servicesTypeResponse !== this.props.paymentState.servicesTypeResponse &&
                    nextProps.paymentState.pending === false) {
                    this.setState({ servicesType: nextProps.paymentState.servicesTypeResponse.serviceType });
                }
            }
        }
        return true;
    }
    render() {
        if (!this.props.userDataState.stripedata || (this.props.userDataState.stripedata && !this.props.userDataState.stripedata.accountID) && this.props.match.params.type !== "stripe-connect") {
            return <Redirect to="/user/payment/stripe-connect"></Redirect>
        }
        return (
            <>
                <PaymentHeader {...this.props}></PaymentHeader>

                {
                    this.props.paymentState.pending &&
                    <LoadingIndicator></LoadingIndicator>
                }

                {
                    (this.props.match.params.type === "all-invoices" && this.state.paymentRespose) &&
                    <AllInvoice {...this.props} invoiceType="all"></AllInvoice>
                }
                {
                    (this.props.match.params.type === "draft" && this.state.paymentRespose) &&
                    <AllInvoice {...this.props} invoiceType="draft"></AllInvoice>
                }
                {
                    (this.props.match.params.type === "outstandings" && this.state.paymentRespose) &&
                    <AllInvoice {...this.props} invoiceType="outstanding"></AllInvoice>
                }
                {
                    (this.props.match.params.type === "paid" && this.state.paymentRespose) &&
                    <AllInvoice {...this.props} invoiceType="paid"></AllInvoice>
                }
                {
                    (this.props.match.params.type === "stripe-connect" && this.state.paymentRespose) &&
                    <StripeConnect {...this.props}></StripeConnect>
                }
                {
                    (this.props.match.params.type === "create-invoice" && this.state.paymentRespose) &&
                    <CreateInvoice {...this.props}></CreateInvoice>
                }
            </>
        );
    }
}
const mapStateToProps = (state: IAppState) => ({
    paymentState: state.payment,
    userDataState: state.userData.userDataResponse
});
const mapDispatchToProps = (dispatch: any) => ({
    fetchInitPayment: (request: FetchInitPaymentRequest) => {
        dispatch(fetchInitPaymentPending(request));
    },
    servicesType: (request: ServicesTypeRequest) => {
        dispatch(servicesTypePending(request));
    }
});
export default connect(mapStateToProps, mapDispatchToProps)(Payment);