import _ from "lodash";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {  Modal } from "react-bootstrap";
import { toast } from "react-toastify";
import { ReportHeader } from ".";
import { IAppState } from "../../../store";
import { fetchLoadingPending, fetchLoadingSuccess } from "../../../store/loadingIndicator/actions";
import configs from "../../configs/apiConfigs";
import { convertToSystemDate } from "../../helpers/datetime";
import { getAppointmentListAnalytics } from "../../services/messageCenter";
import { titleCase } from "../../utils/global";
import { DatePicker } from "@material-ui/pickers";

export const AnalyticsPatientListDetail = ({
    type,
    onBackClick
}: any) => {
    const [date, setDate]:any = useState("");
    const [week, setWeek] = useState("");
    const [month, setMonth] = useState("");

    const [showCustomFilterDialog, setShowCustomFilterDialog] = useState(false);

    const getStartDate = () => {
        let startDate = convertToSystemDate(moment().subtract(1, 'years'));
        switch(type) {
            case "active_patients_list":
                startDate = convertToSystemDate(moment().subtract(1, 'years'));
                break;
            case "in-active_patients_list":
                startDate = convertToSystemDate(moment().subtract(1, 'years'));
                break;
        }
        return startDate;
    }
    const getEndDate = () => {
        let endDate = convertToSystemDate(moment());
        switch(type) {
            case "active_patients_list":
                endDate = convertToSystemDate(moment());
                break;
            case "in-active_patients_list":
                endDate = convertToSystemDate(moment());
                break;
        }
        return endDate;
    }
    const [startDate, setStartDate]:any = useState(getStartDate());
    const [endDate, setEndDate]:any = useState(getEndDate());

    const [appointmentList, setAppointmentList]:any = useState([]);

    const clinic: any = useSelector((state: IAppState) => state.userData.userDataResponse.clinics[0]);
    const dispatch = useDispatch();

    let pageTitle = type || "";
    pageTitle = pageTitle.replaceAll("_", " ");
    pageTitle = titleCase(pageTitle);

    const handleDateChange =(date:any) => {
        setDate(moment(date));
        setWeek("");
        setMonth("");
        setStartDate("");
        setEndDate("");
    }

    const handleWeekChange = (date:any, dateString:string) => {
        setWeek(date);
        setDate("");
        setMonth("");
        setStartDate("");
        setEndDate("");
    }

    const handleMonthChange = (date:any, dateString:string) => {
        setMonth(date);
        setDate("");
        setWeek("");
        setStartDate("");
        setEndDate("");
    }

    const generateQuery = () =>{
        let query:any = {
            clinicid:clinic.id,
        }
        if (date) {
            Object.assign(query, {
                startdate:convertToSystemDate(date),
                enddate:convertToSystemDate(date)
            });
        };
        if (week) {
            Object.assign(query, {
                startdate:convertToSystemDate(moment(week)),
                enddate:convertToSystemDate(moment(week).add(6, "day"))
            });
        }
        if (month) {
            Object.assign(query, {
                startdate:convertToSystemDate(moment(month).startOf('month')),
                enddate:convertToSystemDate(moment(month).endOf('month'))
            });
        }
        if (startDate && endDate) {
            Object.assign(query, {
                startdate:convertToSystemDate(startDate),
                enddate:convertToSystemDate(endDate)
            });
        }
        return query;
    }

    useEffect(() => {
        onFilter({
            clinicid:clinic.id,
            startdate: getStartDate(),
            enddate: getEndDate()
        });
    }, []);

    useEffect(() => {
        onFilter();
    }, [date, week, month]);

    const onFilter = (queryDefault:any = null) => {
        let query = generateQuery();
        if (queryDefault) query = queryDefault;
        if (!query.startdate || !query.enddate) {
            return;
        }
        let _type = "";
        switch(type) {
            case "active_patients_list":
                _type = "activeuserdatalist";
                break;
            case "in-active_patients_list":
                _type = "inactiveuserdatalist"
        }
        dispatch(fetchLoadingPending());
        getAppointmentListAnalytics(query, _type).then((success:any) => {
            if (success.error === false) {
                setAppointmentList(success.data);
            }
             dispatch(fetchLoadingSuccess(false));
        }).catch((err:any) => {
             dispatch(fetchLoadingSuccess(false));
        });
    }

    const handleCustomerFilterApply = () => {
        onFilter();
    }

    const generateDownloadLink = () => {
        try {
            let query = generateQuery();
            let type = "";
            switch(type) {
                case "active_patients_list":
                    type = "generateactiveuserdataxlsx";
                    break;
                case "in-active_patients_list":
                    type = "generateinactiveuserdataxlsx";
                    break;
            }
            if (type === "") return;
            let url = configs.MESSAGE_CENTER_API + 'analytics/'+type+'/'+query.clinicid;
            if (query.startdate) {
                url += '/'+query.startdate;
            }
            if (query.enddate) {
                url += '/'+query.enddate;
            }
            return (<a href={url} target={"_self"} download onClick={() => {
                toast.success("It might take couple of minutes to download this report. Thank you for your patience. We will show a pop up to save the file once it is ready for download.");
            }}>Download</a>);
        }catch (err) {
            return null
        }
    }

    return (
        <div className="app analytics-report">
            <ReportHeader showBack pageTitle={pageTitle} onBackClick={onBackClick}></ReportHeader>
            <div className="app-body" style={{ paddingTop: 0, marginTop: 20 }}>
            <div style={{
                    position: "sticky",
                    top: 0,
                    zIndex: 99,
                    backgroundColor: "#f7f5f0"
            }}>
                <div className="w-100 d-flex justify-content-end">
                    <DatePicker
                        format="MM/DD/YYYY"
                        placeholder="MM/DD/YYYY"
                        value={date && date !== '' ? moment(date).toDate() : null}
                        onChange={handleDateChange}
                    />
                  {/* <DatePicker style={{width: 170}} className="ms-1" value={week ? moment(week) : null} onChange={handleWeekChange} picker={"week"}></DatePicker> */}
                  {/* <DatePicker style={{width: 170}} className="ms-1" value={month ? moment(month) : null} onChange={handleMonthChange} picker={"month"}></DatePicker> */}
                </div>
                <div className="w-100 d-flex justify-content-end align-items-center">
                    <button type="button" className="btn btn-link" onClick={() => {
                        setShowCustomFilterDialog(true);
                    }}>Custom</button>
                    <button type="button" className="btn btn-link" onClick={() => {
                        setDate("");setWeek("");setMonth("");setStartDate(getStartDate());setEndDate(getEndDate());
                        setAppointmentList([]);
                        onFilter({
                            clinicid:clinic.id,
                            startdate: getStartDate(),
                            enddate: getEndDate()
                        });
                    }}>Reset</button>
                </div>
                <hr />
            </div>
                {
                    appointmentList.length === 0 &&
                    <>Please select date, week or month to view desirable data</>
                }
                {
                    appointmentList.length !== 0 &&
                    <div className="d-flex"><div className="me-2">{generateDownloadLink()}</div>
                        <div><small>{moment(startDate).isValid() && <>({<>{moment(startDate).format("MM/DD/YYYY")} to {moment(endDate).format("MM/DD/YYYY")}</>})</>}</small></div>
                    </div>
                }
                <div className="">
                    <div className="patienet_list" id="scrollableDiv">
                        {
                            Array.isArray(appointmentList) && appointmentList.map((appointment:any) => {
                                let category = appointment.category || "";
                                category = category.split(",");
                                return (
                                    <div className="patient_item d-flex border-bottom py-3 align-items-center" key={appointment.dependantid}>
                                        <div className="avatar avatar-lg rounded-circle flex-shrink-0 me-2">
                                            {appointment?.patientfname?.charAt(0)}{appointment?.patientlname?.charAt(0)}
                                        </div>
                                        <div className="av-txt flex-grow-1">
                                            <div className="av_title cursor-pointer">{appointment?.patientfname} {appointment?.patientlname}</div>
                                            {
                                                appointment?.dependantphone &&
                                                <div className="av_subtitle">
                                                    Mobile: <a href="javascript:void(0)">{appointment.dependantphone}</a>
                                                </div>
                                            }
                                            {
                                                appointment?.dependantemailaddress &&
                                                <div className="av_subtitle">
                                                    Email: <a href={"mailto:"+appointment?.dependantemailaddress}>{appointment?.dependantemailaddress}</a>
                                                </div>
                                            }
                                        </div>
                                        {/* <div className="item_action">
                                            <button type="button" className="btn btn-outline-dark ms-2"><i className="bi bi-info"></i></button>
                                        </div> */}
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>
            </div>
            <Modal show={showCustomFilterDialog} onHide={() => {
                    setShowCustomFilterDialog(false);
                }}
            >
                <Modal.Header>
                    <div className="modal-title">Custom Date</div>
                    <button
                        type="button"
                        className="btn-close"
                        aria-label="Close"
                        onClick={() => { setShowCustomFilterDialog(false) }}
                    ></button>
                </Modal.Header>
                <div className="date_picker">
                    <div className="input-group">
                        <label className="me-1">Start Date: </label>
                        <DatePicker
                            value={startDate && startDate!== '' ? moment(startDate) : null}
                            placeholder="MM/DD/YYYY"
                            format="MM/DD/YYYY"
                            onChange={(date) => {
                                setStartDate(moment(date));
                            if (moment(date).isAfter(endDate)) {
                                setEndDate(date);
                            }
                            }}
                        />
                    </div>
                    <div className="input-group ms-1">
                        <label className="me-1">End Date: </label>
                        <DatePicker
                            placeholder="MM/DD/YYYY"
                            format="MM/DD/YYYY"
                            value={endDate && endDate !== '' ? moment(endDate) : null}
                            onChange={(date) => {
                                setEndDate(moment(date));
                            }}
                        />
                    </div>
                </div>
                <Modal.Footer>
                    <button
                        className="btn btn-primary"
                        onClick={() => {
                            setWeek("");
                            setDate("");
                            setMonth("");
                            setShowCustomFilterDialog(false);
                            setTimeout(() => {
                                handleCustomerFilterApply();
                            }, 1000);
                        }}
                    >
                        Ok
                    </button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}
