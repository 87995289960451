import { takeEvery, put, call } from "redux-saga/effects";
import axios from 'axios';
import CONFIG from '../configs/apiConfigs';
import { LOGIN, FetchLoginPendingAction, LoginRequest } from "../store/login/types";
import { fetchLoginError, fetchLoginSuccess } from "../store/login/actions";
import Cookie from 'js-cookie';
import apiConfigs from "../configs/apiConfigs";
import { logoutUser } from "../utils/firebaseUtils";
import { commonApi } from "../helpers/api";
import { CommonApiOptions, BODY_TYPE, METHOD_TYPE } from "../constants/api";
function execute(login: LoginRequest) {
    const headers = {
        'Content-Type': 'application/json',
        // 'X-Auth-Token': '97e0d315477f435489cf04904c9d0e6co',
    };
    return axios.post(CONFIG.API_URL + "login/", login, {
        headers: headers,
    }).then(response => ({ response }), error => ({ error }));
}
function* loginPendingRun(action: FetchLoginPendingAction) {
    /*  //    yield delay(2000);
     const { response, error } = yield execute(action.payload);
     if (response) {
         if (response.status === 200) {
             yield put(fetchLoginSuccess(response.data));
         } else {
             yield put(fetchLoginError(response))
         }
     }
     if (error) {
         yield put(fetchLoginError(error))
     } */

    try {
        const headers = {
            'Content-Type': 'application/json',
        }
        const options: CommonApiOptions = {
            url: CONFIG.API_URL + "login/",
            apiOptions: {
                headers: headers,
            },
            body: {
                type: BODY_TYPE.RAW,
                data: action.payload
            },
            method: METHOD_TYPE.POST
        };
        //@ts-ignore
        const response = yield call(commonApi, options);
        yield put(fetchLoginSuccess(response));
    } catch (err) {
        yield put(fetchLoginError(err));
    }
}

export default function* loginPendingWatcher() {
    yield takeEvery(LOGIN.LOAD_PENDING, loginPendingRun);
}

function loggedOut() {
    // localStorage.removeItem('login');
    Cookie.remove('clinic_cookie');
    Cookie.remove("clinic_cookie", { domain: apiConfigs.COOKIE_DOMAIN, path: '/' });
    localStorage.removeItem('Chat');
    localStorage.removeItem('ChatClinicId');
    logoutUser();
}

export function* logoutWatcher() {
    yield takeEvery(LOGIN.LOGGED_OUT, loggedOut);
}