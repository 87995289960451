import { SubscriptionState, SubscriptionPlanActionTypes, SUBSCRIPTION_PLAN } from "./types";

const initState: SubscriptionState = {
  error: null,
  pending: false
};

export const subscriptionPlanReducer = (state = initState, action: SubscriptionPlanActionTypes): SubscriptionState => {
  switch (action.type) {
    case SUBSCRIPTION_PLAN.SUBSCRIPTION_PLAN_FETCH:
      return {
        ...state,
        pending: true,
        error: null,
        fetchSubscriptionPlanRequest: action.payload,
        fetchSubscriptionPlanResponse: undefined
      }
    case SUBSCRIPTION_PLAN.SUBSCRIPTION_PLAN_FETCH_FAILED:
      return {
        ...state,
        pending: false,
        error: action.payload,
        fetchSubscriptionPlanRequest: undefined,
        fetchSubscriptionPlanResponse: undefined
      }
    case SUBSCRIPTION_PLAN.SUBSCRIPTION_PLAN_FETCH_SUCCESS:
      return {
        ...state,
        pending: false,
        error: null,
        fetchSubscriptionPlanRequest: undefined,
        fetchSubscriptionPlanResponse: action.payload
      }
    case SUBSCRIPTION_PLAN.SUBSCRIPTION_PLAN_CREATE:
      return {
        ...state,
        pending: true,
        error: null,
        createSubscriptionPlanRequest: action.payload,
        createSubscriptionPlanResponse: undefined
      }
    case SUBSCRIPTION_PLAN.SUBSCRIPTION_PLAN_CREATE_FAILED:
      return {
        ...state,
        pending: false,
        error: action.payload,
        createSubscriptionPlanRequest: undefined,
        createSubscriptionPlanResponse: undefined
      }
    case SUBSCRIPTION_PLAN.SUBSCRIPTION_PLAN_CREATE_SUCCESS:
      return {
        ...state,
        pending: false,
        error: null,
        createSubscriptionPlanRequest: undefined,
        createSubscriptionPlanResponse: action.payload
      }
      case SUBSCRIPTION_PLAN.CLINIC_MYPLAN:
      return {
        ...state,
        pending: true,
        error: null,
        clinicMyplanRequest: action.payload,
        clinicMyplanResponce: undefined
      }
    case SUBSCRIPTION_PLAN.CLINIC_MYPLAN_FAILED:
      return {
        ...state,
        pending: false,
        error: action.payload,
        clinicMyplanRequest: undefined,
        clinicMyplanResponce: undefined
      }
    case SUBSCRIPTION_PLAN.CLINIC_MYPLAN_SUCCESS:
      return {
        ...state,
        pending: false,
        error: null,
        clinicMyplanRequest: undefined,
        clinicMyplanResponce: action.payload
      }
      case SUBSCRIPTION_PLAN.CANCEL_SUBSCRIPTION:
      return {
        ...state,
        pending: true,
        error: null,
        cancelSubscriptionRequest: action.payload,
        cancelSubscriptionResponse: undefined
      }
    case SUBSCRIPTION_PLAN.CANCEL_SUBSCRIPTION_FAILD:
      return {
        ...state,
        pending: false,
        error: action.payload,
        cancelSubscriptionRequest: undefined,
        cancelSubscriptionResponse: undefined
      }
    case SUBSCRIPTION_PLAN.CANCEL_SUBSCRIPTION_SUCCESS:
      return {
        ...state,
        pending: false,
        error: null,
        cancelSubscriptionRequest: undefined,
        cancelSubscriptionResponse: action.payload
      }
    default:
      return state;
  }
}