import firebase from 'firebase';
import moment from 'moment';
import React, { useEffect } from 'react';
import { useState } from 'react';
import nl2br from 'react-nl2br';
import configs from '../../../configs/apiConfigs';
import { USER_TYPE } from '../../../constants/constants';
import { chatNotificationProvider } from '../../../services/chat';
import { AvatarComp } from '../Avatar';

let groupMessageListener: any;
let db = firebase.firestore();

export const Messages = (props: any) => {
    const { selectedProvider } = props;
    const [messageList, setMessageList] = useState([]);
    const [message, setMessage] = useState('');
    useEffect(() => {
        if (selectedProvider && selectedProvider.group) {
            groupMessage();
        }
    }, [selectedProvider]);

    const groupMessage = () => {
        groupMessageListener = db.collection(configs.MESSAGE_COLLECTION).doc(selectedProvider.group.id).onSnapshot((snapshot: any) => {
            const data = snapshot.data();
            console.log(data);
            setMessageList(data?.messages || []);
        });
    }
    console.log('MessagesList', messageList, selectedProvider);

    const sendMessage = async () => {
        const docRef = db.collection(configs.MESSAGE_COLLECTION).doc(`${selectedProvider.group.id}`);
        console.log(selectedProvider);
        // let clinicDoc = await db.collection(configs.USER_COLLECTION).where('uniqueId', '==', selectedProvider.clinic.uniqueId).get();
        // let clinicFirebaseId = clinicDoc.docs.map((doc) => ({ ...doc.data, id: doc.id }))[0].id;
        docRef.update('messages', firebase.firestore.FieldValue.arrayUnion({
            messageText: message,
            senderType: USER_TYPE.CLINIC,
            sentBy: selectedProvider.clinic.id,
            sentAt: new Date(),
            readBy: []
        }));
        const groupRef = db.collection(configs.GROUP_COLLECTION).doc(selectedProvider.group.id).update({
            "recentMessage": {
                message: message,
                sentAt: firebase.firestore.FieldValue.serverTimestamp()
            },
            "modifiedAt": firebase.firestore.FieldValue.serverTimestamp(),
            "modifiedBy": selectedProvider.clinic.id
        });
        chatNotificationProvider(selectedProvider.provider.uniqueId, {
            title: "New message from " + selectedProvider.clinic.fullName,
            body: message,
            notifications: {
                groupDocId: selectedProvider.group.id,
                clinicName: selectedProvider.clinic.fullName,
                clinicUniqueId: selectedProvider.clinic.uniqueId,
                clinicEmail: selectedProvider.clinic.email
            }
        }).then((success) => { }).catch((err) => { });
        setMessage('');
    }

    if (selectedProvider && selectedProvider.group) {
        return (
            <div className={"col-9 border-end position-relative "}>
                <>
                    <div className="position-absolute top-0 start-0 border-bottom w-100">
                        <div className="d-flex align-items-center chat-cnt-header px-3">
                            <AvatarComp name={selectedProvider.provider.fullName}></AvatarComp>
                            <div className="flex-grow-1 chat-user-details">
                                <div className="fw-medium">{selectedProvider.provider.fullName}</div>
                                <small className="text-black-50">Email - <span className="fw-bold">{selectedProvider.provider.email}</span></small>
                            </div>
                        </div>
                    </div>
                    <div className="position-relative">
                        <div className="chatbox-sc" id="chat-box">
                            <div className="chat-messages p-4">
                                {
                                    messageList.map((message: any, index: number) => {
                                        return (
                                            <React.Fragment key={"messageprovider" + index}>
                                                {
                                                    message.senderType === USER_TYPE.CLINIC &&
                                                    <div className="chat-message-right cadmin pb-4">
                                                        <div className="chatbu rounded py-2 px-3 bg-light">
                                                            {nl2br(message.messageText)}
                                                        </div>
                                                        <div className="text-muted text-end small text-nowrap">
                                                            {
                                                                message &&
                                                                message.sentAt &&
                                                                <> {moment(moment.unix(message.sentAt.seconds)).format("MMM DD, YYYY - LT")}</>
                                                            }
                                                        </div>
                                                    </div>
                                                }
                                                {
                                                    message.senderType === USER_TYPE.PROVIDER &&
                                                    <div className="chat-message-left cuser pb-4">
                                                        <div className="chatbu rounded py-2 px-3 bg-primary text-white">
                                                            {nl2br(message.messageText)}
                                                        </div>
                                                        <div className="text-muted text-start small text-nowrap">
                                                            {
                                                                message &&
                                                                message.sentAt &&
                                                                <> {moment(moment.unix(message.sentAt.seconds)).format("MMM DD, YYYY - LT")}</>
                                                            }
                                                        </div>
                                                    </div>
                                                }
                                            </React.Fragment>
                                        )
                                    })
                                }
                            </div>
                        </div>
                    </div>
                </>
                <div className="position-absolute bottom-0 start-0 border-top w-100">
                    <div className="d-flex align-items-center ch-write-sec px-3">
                        <div className="input-group">
                            <input type="text" className="form-control" placeholder="Type your message" value={message} onChange={(e) => {
                                if (e.target.value.length > 160) {
                                    return;
                                }
                                setMessage(e.target.value);
                            }} />
                            <button className="btn btn-primary" disabled={false}
                                onClick={(e: any) => {
                                    sendMessage();
                                }}
                            >Send</button>
                        </div>
                    </div>
                </div>
            </div>
        );
    } else {
        return null;
    }
}