import { KeyboardDatePicker, KeyboardTimePicker } from '@material-ui/pickers';
import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import moment from 'moment';
import { blockTimeSlot } from '../../services/provider';
import { toast } from 'react-toastify';
export const BlockTime = ({ providerId, fetchProfile, blockTimes }: any) => {
    const [isEdit, setIsEdit] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [blockTimeList, setBlockTimeList] = useState([]);
    const { register, errors, setValue, getValues, setError, handleSubmit, control } = useForm({
        defaultValues: {
            startDate: new Date(),
            endDate: new Date(),
            startTime: new Date(),
            endTime: new Date(),
        }
    });
    useEffect(() => {
        if (blockTimes) {
            setBlockTimeList(blockTimes);
        } else {
            setBlockTimeList([]);
        }
    }, [blockTimes]);
    const onCancel = () => {
        setIsEdit(false);
    };
    const onSave = (data: any) => {
        const startDate = moment(getValues('startDate')).format('YYYY-MM-DD');
        const endDate = moment(getValues('endDate')).format('YYYY-MM-DD');
        const startTime = moment(getValues('startTime')).format('LT');
        const endTime = moment(getValues('endTime')).format('LT');
        if (moment().isAfter(moment(startDate + ' ' + startTime, 'YYYY-MM-DD LT'))) {
            setError('startDate', { type: 'custom', message: 'Start date and time should not be previous to current date and time.' });
            return;
        } else if (moment(startDate + ' ' + startTime, 'YYYY-MM-DD LT').isAfter(moment(endDate + ' ' + endTime, 'YYYY-MM-DD LT'))) {
            setError('startDate', { type: 'custom', message: 'Start date and time should not be previous to end date and time.' });
            return;
        }
        setError('startDate', {});
        let requestedData = {
            type: "slot",
            slot: {
                mode: "add",
                startdate: moment(getValues('startDate')).format('YYYY-MM-DD'),
                enddate: moment(getValues('endDate')).format('YYYY-MM-DD'),
                starttime: moment(getValues('startTime')).format('LT'),
                endtime: moment(getValues('endTime')).format('LT'),
            }
        };
        setIsEdit(true)
        setIsLoading(true);
        blockTimeSlot(providerId, requestedData).then((success) => {
            if (success && success.status && success.status.error === false) {
                fetchProfile().then((success: any) => {
                    setIsEdit(false);
                    setTimeout(() => {
                        setIsLoading(false);
                        setIsEdit(false);
                    }, 2000);
                });
            }
        }).catch((err) => {
            toast.error(err.msg);
            setIsLoading(false);
        });
    }
    const onRemove = (id: any) => {
        let requestedData = {
            type: "slot",
            slot: {
                mode: "remove",
                id: id
            }
        };
        setIsLoading(true);
        blockTimeSlot(providerId, requestedData).then((success) => {
            if (success && success.status && success.status.error === false) {
                fetchProfile().then((success: any) => {
                    setIsEdit(false);
                    setTimeout(() => {
                        setIsLoading(false);
                        setIsEdit(false);
                    }, 2000);
                });
            }
        }).catch((err) => {
            toast.error(err.msg);
            setIsLoading(false);
        });
    }
    return (
        <div className="provi-holiday mb-4">
            <div className="card">
                <div className="card-header bg-light py-3 px-4">
                    <div className="d-flex justify-content-between">
                        <div className="fw-medium">Block time</div>
                        <div>
                            {
                                !isEdit && !isLoading &&
                                <button className="btn p-0 btn-link" onClick={() => {
                                    setIsEdit(true);
                                }}>Edit</button>
                            }
                            {
                                isEdit && !isLoading &&
                                <>
                                    <button className="btn p-0 btn-link me-2 text-secondary" onClick={() => {
                                        onCancel();
                                    }}><i className="bi bi-x-circle-fill text-danger"></i></button>
                                </>
                            }
                            {
                                isEdit && isLoading &&
                                <><span className="spinner-border spinner-border-sm text-primary" role="status" aria-hidden="true"></span></>
                            }
                            {
                                isLoading && !isEdit &&
                                <i className="bi bi-check-circle-fill text-success"></i>
                            }
                        </div>
                    </div>
                </div>

                <ul className="list-group list-group-flush">


                    {
                        blockTimeList.map((item: any) => {
                            return (
                                <li className="list-group-item py-3 px-4 d-flex justify-content-between" key={item.id + "slot"}>
                                    <div>
                                        <div>
                                            <small className="text-muted">Date</small>
                                            <div>{item.startdate} to {item.enddate}</div>
                                        </div>
                                        <div>
                                            <small className="text-muted">Time</small>
                                            <div>{item.starttime} to {item.endtime}</div>
                                        </div>
                                    </div>
                                    {
                                        isEdit && !isLoading &&
                                        <div className="text-end">
                                            <button type="button" className="btn p-1" onClick={() => {
                                                onRemove(item.id)
                                            }}>
                                                <i className="bi bi-x text-danger"></i>
                                            </button>
                                        </div>
                                    }
                                </li>
                            )
                        })
                    }


                </ul>

                <div className={"card-body p-4 " + (isEdit ? '' : 'd-none')}>
                    <div className="row justify-content-end g-3 miuif_margin">
                        <div className="col-12">
                            <label className="form-label">Start Date</label>
                            <Controller
                                name="startDate"
                                control={control}
                                rules={{ required: true }}
                                render={(props) => {
                                    return (
                                        <KeyboardDatePicker
                                            margin="none"
                                            id="time-picker-start-date"
                                            value={props.value}
                                            format='DD-MM-YYYY'
                                            minDate={moment()}
                                            onChange={(date) => {
                                                props.onChange(date);
                                            }}
                                            KeyboardButtonProps={{
                                                'aria-label': 'change time',
                                            }}
                                        />
                                    )
                                }}
                            ></Controller>
                        </div>
                        <div className="col-12">
                            <label className="form-label">End Date</label>
                            <Controller
                                name="endDate"
                                control={control}
                                rules={{ required: true }}
                                render={(props) => {
                                    return (
                                        <KeyboardDatePicker
                                            margin="none"
                                            id="time-picker-end-date"
                                            value={props.value}
                                            format='DD-MM-YYYY'
                                            minDate={moment()}
                                            onChange={(date) => {
                                                props.onChange(date);
                                            }}
                                            KeyboardButtonProps={{
                                                'aria-label': 'change time',
                                            }}
                                        />
                                    )
                                }}
                            ></Controller>
                        </div>

                        <div className="col-12">
                            <label className="form-label">Start time</label>
                            <Controller
                                name="startTime"
                                control={control}
                                rules={{ required: true }}
                                render={(props) => {
                                    return (
                                        <KeyboardTimePicker
                                            margin="none"
                                            id="start-time-picker"
                                            label="Start time"
                                            value={props.value}
                                            onChange={(date) => props.onChange(date)}
                                            KeyboardButtonProps={{
                                                'aria-label': 'change time',
                                            }}
                                        />
                                    )
                                }}
                            ></Controller>
                        </div>
                        <div className="col-12">
                            <label className="form-label">End time</label>
                            <Controller
                                name="endTime"
                                control={control}
                                rules={{ required: true }}
                                render={(props) => {
                                    return (
                                        <KeyboardTimePicker
                                            margin="none"
                                            id="end-time-picker"
                                            label="End time"
                                            value={props.value}
                                            onChange={(date) => props.onChange(date)}
                                            KeyboardButtonProps={{
                                                'aria-label': 'change time',
                                            }}
                                        />
                                    )
                                }}
                            ></Controller>
                        </div>
                        {
                            (errors && errors.startDate && errors.startDate.type === 'custom') &&
                            <div className="is-invalid">{errors.startDate.message}</div>
                        }
                        <div className="col-12 text-end">
                            <button className="btn btn-link p-0" type="button" onClick={handleSubmit(onSave)}>Add</button>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    )
}