import { all, takeEvery, fork, put, call } from "redux-saga/effects";
import { EMPLOYEE, AddEmployeePendingAction, AddEmployeeRequest, EmployeeListPendingAction, EmployeeDeletePendingAction, EmployeePrescreeningPendingAction } from "../store/employee/types";
import { fetchLoadingPending, fetchLoadingSuccess } from "../store/loadingIndicator/actions";
import { CommonApiOptions, METHOD_TYPE, BODY_TYPE } from "../constants/api";
import configs from "../configs/apiConfigs";
import { attachHeader } from "../utils/apiHandler";
import { commonApi } from "../helpers/api";
import { addEmployeeSuccess, addEmployeeFailure, employeeListSuccess, employeeListFailure, employeeDeleteSuccess, employeeDeleteFailure, employeePrescreeningSuccess, employeePrescreeningError } from "../store/employee/actions";

function* employeeDelete(action: EmployeeDeletePendingAction) {
    try {
        yield put(fetchLoadingPending());
        const options: CommonApiOptions = {
            url: configs.API_URL + "clinic/" + action.payload.clinicUniqueId + "/delete-employee/" + action.payload.workId + "/",
            method: METHOD_TYPE.GET,
            apiOptions: {
                headers: attachHeader(true),
                withCredentials: true
            },
        };
        //@ts-ignore
        const response = yield call(commonApi, options);
        yield put(employeeDeleteSuccess({ ...response, workId: action.payload.workId }));
    } catch (err) {
        yield put(employeeDeleteFailure(err));
    }
    yield put(fetchLoadingSuccess(false));
}
function* employeeDeleteWatcher() {
    yield takeEvery(EMPLOYEE.DELETE_EMPLOYEE, employeeDelete);
}
function* employeeList(action: EmployeeListPendingAction) {
    try {
        yield put(fetchLoadingPending());
        const options: CommonApiOptions = {
            url: configs.API_URL + "clinic/" + action.payload.clinicUniqueId + "/employees/",
            method: METHOD_TYPE.GET,
            apiOptions: {
                headers: attachHeader(true),
                withCredentials: true
            }
        };
        //@ts-ignore
        const response = yield call(commonApi, options);
        yield put(employeeListSuccess(response));
    } catch (err) {
        yield put(employeeListFailure(err));
    }
    yield put(fetchLoadingSuccess(false));
}
function* employeeListWatcher() {
    yield takeEvery(EMPLOYEE.EMPLOYEE_LIST, employeeList);
}
function* addEmployee(action: AddEmployeePendingAction) {
    try {
        yield put(fetchLoadingPending());
        let bodyData: AddEmployeeRequest = JSON.parse(JSON.stringify(action.payload));
        //@ts-ignore
        delete bodyData.clinicUniqueId;
        const options: CommonApiOptions = {
            url: configs.API_URL + "clinic/" + action.payload.clinicUniqueId + "/new-employee/",
            apiOptions: {
                headers: attachHeader(true),
                withCredentials: true
            },
            body: {
                type: BODY_TYPE.RAW,
                data: bodyData
            },
            method: METHOD_TYPE.POST
        };
        //@ts-ignore
        const response = yield call(commonApi, options);
        yield put(addEmployeeSuccess(response));
    } catch (err) {
        yield put(addEmployeeFailure(err));
    }
    yield put(fetchLoadingSuccess(false));
}
function* addEmployeeWatcher() {
    yield takeEvery(EMPLOYEE.ADD_EMPLOYEE, addEmployee);
}

function* employeePrescreening(action: EmployeePrescreeningPendingAction) {
    try {
        yield put(fetchLoadingPending());
        let url = configs.API_URL + "clinic/" + action.payload.clinicUniqueId + "/prescreen/?start=" + action.payload.start +
            "&end=" + action.payload.end;
        if (action.payload.workid !== '') {
            url = url + "&workid=" + action.payload.workid;
        }
        const options: CommonApiOptions = {
            url: url,
            apiOptions: {
                headers: attachHeader(true),
                withCredentials: true
            },
            method: METHOD_TYPE.GET
        };
        //@ts-ignore
        const response = yield call(commonApi, options);
        yield put(employeePrescreeningSuccess(response));
    } catch (err) {
        yield put(employeePrescreeningError(err));
    }
    yield put(fetchLoadingSuccess(false));
}
function* employeePrescreeningWatcher() {
    yield takeEvery(EMPLOYEE.EMPLOYEE_PRESCREENING, employeePrescreening);
}

function* Employee() {
    yield all([fork(addEmployeeWatcher), fork(employeeListWatcher), fork(employeeDeleteWatcher), fork(employeePrescreeningWatcher)]);
}
export default Employee;