import React,{useEffect,useState} from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { IAppState } from '../../../store';
export const AdministrativeHeader = () => {
    /* const userData = useSelector((state: IAppState) => state.userData.userDataResponse, shallowEqual);
    const location = useLocation();
    const [isActive, setIsActive] = useState(false);
    useEffect(() => {
        if (location && (location.pathname === "/message-center/administrative/emaileditor" || location.pathname === "/message-center/administrative/templates")) {
            setIsActive(true);
        } else {
            setIsActive(false);
        }
    },[location]); */
    return (
        <section className="middle-navbar px-30">
            <div className="p-0 container-fluid">
                <div className="d-flex justify-content-between">
                    <div>
                        <div className="nav-title-txt">Administrative Notifications</div>
                        <ul className="nav nav-tabs">
                            <li className="nav-item">
                                <NavLink className="nav-link" exact={true} to={"/message-center/administrative"}>Administrative Notification</NavLink>
                            </li>
                            <li className="nav-item">
                                <NavLink className="nav-link" exact={true} to={"/message-center/administrative/patients"}>Patients</NavLink>
                            </li>
                            {/* {
                                (userData && userData.clinics && userData.clinics[0] && userData.clinics[0].isenableemailtemplate) &&
                                <li className="nav-item">
                                    <NavLink className={isActive ? "nav-link active" : "nav-link"} exact={true} to={"/message-center/administrative/templates"}>Email Template</NavLink>
                                </li>
                            } */}
                        </ul>
                    </div>
                </div>
            </div>
        </section>
    )
}