import React, { useEffect, useMemo, useState } from 'react'
import Pagination from 'react-js-pagination';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { CHAT } from '../../../constants';
import { IAppState } from '../../../store';
import { MyScheduleHeader } from '../../components/mySchedule/MyScheduleHeader';
import configs from '../../configs/apiConfigs';
import firebaseInstance from '../../helpers/firebase';
import { getAppointments } from '../../services/appointment';
import moment from 'moment';
import { PatientDetailModel } from '../../components/mySchedule/PatientDetailModel';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { storeChatData } from '../../../store/chat/action';
import CONSTANT_LABELS from '../../../constants/labels';
import { removeCountryCode } from '../../../utils/global';

let subscriptionToAppointmentChange: any = null;
export const Cancelled = () => {
    const [providerList, setProviderList] = useState([]);
    const [selectedProvider, setSelectedProvider] = useState('');
    const [page, setPage] = useState(1);
    const [results, setResults] = useState(30);
    const [totalCount, setTotalCount] = useState(0);
    const [appointmentItemList, setAppointmentItemList] = useState([]);
    const userData = useSelector((state: IAppState) => state.userData.userDataResponse, shallowEqual);
    const dispatch = useDispatch();
    const [isLoading, setIsLoading] = useState(false);
    const [isRefreshing, setIsRefreshing] = useState(false);
    const [currentActiveModalAppointment, setCurrentActiveModalAppointment]: any = useState(null);
    const [showPatientDetailModal, setShowPatientDetailModal] = useState(false);
    const [name, setName] = useState('');
    const [date, setDate] = useState('');
    useEffect(() => {
        return () => {
            if (subscriptionToAppointmentChange) subscriptionToAppointmentChange();
        }
    }, []);
    useEffect(() => {
        if (userData && userData.clinics && userData.clinics[0] && userData.status && userData.status.error === false && userData.status.msg !== "") {
            let providers = userData.clinics[0].provider;
            providers = providers.filter((item: any) => item.onBoarding.status === 1 && item.enableAppointment === "1");
            setProviderList(providers);
            listenRealtimeAppointmentChange();
        }
    }, [userData]);
    useEffect(() => {
        fecthAppointmentListAsync();
    }, [selectedProvider, page, results, date]);
    useEffect(() => {
        if (name.length > 3) {
            fecthAppointmentListAsync();
        } else if (name === '') {
            fecthAppointmentListAsync();
        }
    }, [name]);
    const fecthAppointmentListAsync = async (realTime = false) => {
        try {
            let modelType = 'clinic';
            let Id = userData.clinics[0].uniqueId;
            if (selectedProvider !== '') {
                Id = selectedProvider;
                modelType = 'doctor';
            }
            const request = {
                appointmentType: 'history',
                modelType: modelType,
                page: page,
                results: results,
                Id: Id
            };
            if (name !== '' && name.length > 3) {
                Object.assign(request, { fullname: name });
            }
            if (date !== '') {
                Object.assign(request, { searchdate: date });
            }
            if (realTime === true) {
                setIsRefreshing(true);
            } else {
                setIsLoading(true);
            }
            const response = await getAppointments(request);
            if (response && response.status && response.status.error === false) {
                setAppointmentItemList(response.appointments);
                if (page === 1) {
                    setTotalCount(response.total);
                }
            }
            if (realTime === true) {
                setIsRefreshing(false);
            } else {
                setIsLoading(false);
            }
        } catch (err) {
            if (realTime === true) {
                setIsRefreshing(false);
            } else {
                setIsLoading(false);
            }
            console.log(err);
        }
    }
    const handlePageChange = (page: any) => {
        setPage(page);
    }
    const handlePatientDetailAppointmentClick = (item: any) => {
        setShowPatientDetailModal(true);
        setCurrentActiveModalAppointment(item);
    }
    const listenRealtimeAppointmentChange = () => {
        subscriptionToAppointmentChange && subscriptionToAppointmentChange();
        subscriptionToAppointmentChange = firebaseInstance.db.collection(configs.CHAT_COLLECTION).where('actionType', 'in', [CHAT.ACTION_TYPE.STATUS])
            .where('sendToCId', '==', userData.clinics[0].uniqueId)
            .onSnapshot((snapshot: any) => {
                fecthAppointmentListAsync(true);
            });
    }
    const addChatPin = (item: any) => {
        dispatch(storeChatData({
            ...item,
            fetched: true,
            pinType: "chat",
            openPin: true,
            appointmentId: item.uniqueid,
            sendToCId: item.clinic.uniqueid
        }));
    }
    return (
        <React.Fragment>
            <MyScheduleHeader onFetchAppointment={fecthAppointmentListAsync}></MyScheduleHeader>
            <section className="schedule-sec px-3">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12">
                            <table className="table">
                                <thead>
                                    <tr>
                                        <th scope="col">
                                            <div>NAME</div>
                                            <div className="d-flex">
                                                <input className="form-control form-control-sm" placeholder="Search by name" value={name} onChange={(e) => {
                                                    setPage(1);
                                                    setName(e.target.value);
                                                }} />
                                                <button className="btn btn-sm btn-secondary ms-2" onClick={(e) => {
                                                    setPage(1);
                                                    setName('');
                                                }}><i className="bi bi-x"></i></button>
                                            </div>
                                        </th>
                                        <th scope="col">
                                            <div>Provider</div>
                                            {
                                                useMemo(() => {
                                                    return (
                                                        <select className="form-select form-select-sm" style={{ width: "200px" }} value={selectedProvider} onChange={(e) => {
                                                            setPage(1);
                                                            setSelectedProvider(e.target.value);
                                                        }}>
                                                            <option value=''>All provider</option>
                                                            {
                                                                providerList.map((value: any, index) => {
                                                                    return (
                                                                        <option value={value.uniqueid} key={value.uniqueid + "provider"}>{value.fullname}</option>
                                                                    )
                                                                })
                                                            }
                                                        </select>
                                                    )
                                                }, [providerList, selectedProvider])
                                            }
                                        </th>
                                        <th>INITIAL STATUS</th>
                                        <th scope="col">
                                            <div>FINAL STATUS</div>
                                            <div className="d-flex">
                                                <input className="form-control form-control-sm" type="date" id="datePicker" name="dateSchedule" value={date} onChange={(e) => {
                                                    setPage(1);
                                                    setDate(e.target.value);
                                                }}></input>
                                                <button className="btn btn-sm btn-secondary ms-2" onClick={(e) => {
                                                    setPage(1);
                                                    setDate('');
                                                    fecthAppointmentListAsync(true);
                                                }}><i className="bi bi-x"></i></button>
                                            </div>
                                        </th>
                                        {/* <th className="text-end" scope="col">Action</th> */}
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        isLoading && !isRefreshing &&
                                        <tr className="spinner-border" role="status"></tr>
                                    }
                                    {
                                        isRefreshing && !isLoading &&
                                        <tr className="spinner-border" role="status"></tr>
                                    }
                                    {
                                        isRefreshing && isLoading &&
                                        <tr className="spinner-border" role="status"></tr>
                                    }
                                    {
                                        useMemo(() => {
                                            if (!isLoading && appointmentItemList.length === 0) {
                                                return <tr>No records found</tr>
                                            }
                                            if (userData && userData.clinics[0] && !isLoading) {
                                                return (
                                                    <>
                                                        {
                                                            appointmentItemList.map((item: any) => {
                                                                return (
                                                                    <ItemRow item={item} key={item.uniqueid}
                                                                        addChatPin={addChatPin}
                                                                        handlePatientDetailAppointmentClick={handlePatientDetailAppointmentClick}></ItemRow>
                                                                )
                                                            })
                                                        }
                                                    </>
                                                )
                                            }
                                        }, [appointmentItemList, isLoading])
                                    }
                                </tbody>
                            </table>
                            <div className="d-flex justify-content-end">
                                <Pagination
                                    itemClass="page-item"
                                    linkClass="page-link"
                                    hideDisabled
                                    activePage={page}
                                    itemsCountPerPage={results}
                                    totalItemsCount={Number(totalCount)}
                                    onChange={handlePageChange}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {
                useMemo(() => {
                    if (showPatientDetailModal && currentActiveModalAppointment) {
                        return (
                            <PatientDetailModel
                                hideModal={() => {
                                    setShowPatientDetailModal(false);
                                }}
                                appointment={currentActiveModalAppointment}
                            >
                            </PatientDetailModel>
                        )
                    }
                }, [showPatientDetailModal, currentActiveModalAppointment])
            }
        </React.Fragment>
    );
};
const ItemRow = ({ item, handlePatientDetailAppointmentClick, addChatPin }: any) => {
    return (
        <tr className={"table-row have-msg"} key={item.uniqueid}>
            <td>
                <span className="d-block fw-medium p-0 cursor-pointer btn-link" onClick={() => {
                    handlePatientDetailAppointmentClick(item)
                }}>
                    {
                        (item.dependant && item.dependant.fname && item.dependant.lname) ?
                            (item.dependant.fname + ' ' + item.dependant.lname).substring(0, 15) : ''
                    }
                </span>
                {/*   <button className="btn p-0 btn-link"
                    type="button"
                    onClick={(e) => { handlePatientDetailAppointmentClick(item) }}>
                    {
                        (item.dependant && item.dependant.fname && item.dependant.lname) ?
                            (item.dependant.fname + ' ' + item.dependant.lname).substring(0, 15) : ''
                    }
                </button> */}
                <span className="d-block text-muted">
                    {
                        item.dependant.phone && item.dependant.phone !== "" ? (CONSTANT_LABELS.PLUS_ONE + removeCountryCode(item.dependant.phone)) : item.dependant.email
                    }
                </span>
            </td>
            <td>
                <div className="d-flex p-0">
                    <div className="avatar flex-shrink-0 avatar-lg border rounded-circle me-3">
                        {
                            (item.doctor && item.doctor.photo && item.doctor.photo.name &&
                                item.doctor.photo.type) ?
                                <img src={configs.IMAGE_URL + 'doctor/' +
                                    item.doctor.photo.name + '-200x200.' + item.doctor.photo.type} alt="profile image" className="img-fluid" />
                                :
                                <>{item.doctor.fullname.split(" ")[0] ? item.doctor.fullname.split(" ")[0].charAt(0) : ''}
                                    {item.doctor.fullname.split(" ")[1] ? item.doctor.fullname.split(" ")[1].charAt(0) : ''}</>
                        }
                    </div>
                    <div>
                        <span className="d-block fw-medium"> {(item.doctor && item.doctor.fullname) ?
                            item.doctor.fullname : ''}</span>
                        <small className="d-block text-muted">{item.appointmentType ? item.appointmentType : ''}</small>
                    </div>
                </div>
            </td>
            <td>
                <>
                    <span className="d-block fw-medium p-0">Waitlisted/Scheduled</span>
                    <small className="d-block p-0">{moment(item.date + " " + item.time, 'YYYY-MM-DD LT').format('MMM DD, YYYY - LT')}</small>
                </>
            </td>
            <td>
                {
                    (item.metadata && item.metadata.appointment
                        && item.metadata.appointment.cancellation && item.metadata.appointment.cancellation.cancelledOn) &&
                    <>
                        <span className="d-block fw-medium p-0">Cancelled</span>
                        <small className="d-block p-0">
                            Received on: {moment(item.metadata.appointment.cancellation.cancelledOn, 'YYYY-MM-DD HH:mm:ss ZZ').format("MMM DD, YYYY")} -
                            {moment(item.metadata.appointment.cancellation.cancelledOn, 'YYYY-MM-DD HH:mm:ss ZZ').format("LT")}
                        </small>
                    </>
                }
            </td>
            <td className="text-end">
                {
                    item.dependant && !item.dependant.phone && !item.patient.phone &&
                    <OverlayTrigger
                        placement="auto"
                        overlay={
                            <Tooltip id="tooltip">Manual followup</Tooltip>
                        }
                    >
                        <button className="btn mx-1 p-1"
                            type="button"
                            onClick={(e) => { }}>
                            <i className="bi bi-telephone"></i></button>
                    </OverlayTrigger>
                }
                {
                    item.dependant && (item.dependant.isSignInUser === true || item.dependant.phone) &&
                    <OverlayTrigger
                        placement="auto"
                        overlay={
                            <Tooltip id="tooltip">Quick chat</Tooltip>
                        }
                    >
                        <button className="btn mx-1 p-1"
                            type="button"
                            onClick={(e) => { addChatPin(item); }}>
                            <i className="bi bi-chat-right-text"></i></button>
                    </OverlayTrigger>
                }
            </td>
        </tr>
    )
}