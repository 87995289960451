import configs from "../../configs/apiConfigs";
import { BODY_TYPE, CommonApiOptions, METHOD_TYPE } from "../../constants/api"
import { commonApi } from "../../helpers/api";
import { attachHeader } from "../../utils/apiHandler";

export const getPatientList = (clinicUniqueId: any, dependentIds: any) => {
  const request: CommonApiOptions = {
    url: configs.API_URL + 'clinic/' + clinicUniqueId + '/chatpatientlist/?ids=' + dependentIds,
    method: METHOD_TYPE.GET,
    apiOptions: {
      headers: attachHeader(true),
      withCredentials: true
    }
  };
  return commonApi(request);
};

export const searchPatientApi = (clinicUniqueId: any, dependentName: any) => {
  const request: CommonApiOptions = {
    url: configs.API_URL + 'clinic/' + clinicUniqueId + '/chatpatientlist/?param=' + dependentName,
    method: METHOD_TYPE.GET,
    apiOptions: {
      headers: attachHeader(true),
      withCredentials: true
    }
  };
  return commonApi(request);
};

export const getAppointmentDetails = (clinicUniqueId: any, dependentId: any) => {
  const request: CommonApiOptions = {
    url: configs.API_URL + 'clinic/' + clinicUniqueId + '/chatappointmentlist/' + dependentId + '/',
    method: METHOD_TYPE.GET,
    apiOptions: {
      headers: attachHeader(true),
      withCredentials: true
    }
  };
  return commonApi(request);
};

export const chatNotificationProvider = (providerId: number, data: any) => {
  const request: CommonApiOptions = {
    url: configs.API_URL + 'chatNotificationProvider/' + providerId + '/',
    method: METHOD_TYPE.POST,
    body: {
      type: BODY_TYPE.RAW,
      data: {
        data: data
      }
    },
    apiOptions: {
      headers: attachHeader(true),
      withCredentials: true
    }
  };
  return commonApi(request);
}