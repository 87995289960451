import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import ReactInputMask from 'react-input-mask';
import Select from 'react-select';
import CONSTANT_LABELS from '../../../../constants/labels';
import CONSTANT_TITLES from '../../../../constants/titles';
import { removeCountryCode, takeOnlyDigitAndPlus } from '../../../../utils/global';
import { updateProviderProfile } from '../../../services/provider';
export const GeneralInfo = ({ name, email, phone, uniqueLIC, providerId, fetchProfile, gender, languageList, selectedLanguages }: any) => {
    return (
        <div className="Provider-contact-sec mb-4">
            <div className="card">

                <div className="card-header bg-light py-3 px-3">
                    <div className="d-flex justify-content-between">
                        <div className="fw-medium">Contact information</div>
                    </div>
                </div>


                <ul className="list-group list-group-flush">
                    <li className="list-group-item py-xl-3 py-2 px-2 px-xl-3">
                        {
                            useMemo(() => {
                                return (<PracticeName name={name} providerId={providerId} fetchProfile={fetchProfile}></PracticeName>)
                            }, [name])
                        }
                    </li>
                    <li className="list-group-item py-xl-3 py-2 px-2 px-xl-3">
                        {
                            useMemo(() => {
                                return (<PracticePhone phone={phone} providerId={providerId} fetchProfile={fetchProfile}></PracticePhone>)
                            }, [phone])
                        }
                    </li>
                    <li className="list-group-item py-xl-3 py-2 px-2 px-xl-3">
                        {
                            useMemo(() => {
                                return (
                                    <PracticeEmail email={email} providerId={providerId} fetchProfile={fetchProfile}></PracticeEmail>
                                )
                            }, [email])
                        }
                    </li>
                    <li className="list-group-item py-xl-3 py-2 px-2 px-xl-3">
                        {
                            useMemo(() => {
                                return (
                                    <Gender gender={gender} providerId={providerId} fetchProfile={fetchProfile}></Gender>
                                )
                            }, [gender])
                        }
                    </li>
                    <li className="list-group-item py-xl-3 py-2 px-2 px-xl-3">
                        {
                            useMemo(() => {
                                return (
                                    <Languages selectedLanguages={selectedLanguages} languageList={languageList}
                                        providerId={providerId} fetchProfile={fetchProfile}></Languages>
                                )
                            }, [selectedLanguages, languageList])
                        }
                    </li>
                    <li className="list-group-item py-xl-3 py-2 px-2 px-xl-3">
                        {
                            useMemo(() => {
                                return (
                                    <PracticeUniqueLIC uniqueLIC={uniqueLIC} providerId={providerId} fetchProfile={fetchProfile}></PracticeUniqueLIC>
                                )
                            }, [uniqueLIC])
                        }
                    </li>
                </ul>
            </div>
        </div>
    );
};

const PracticeName = ({ name, fetchProfile, providerId }: any) => {
    const { register, handleSubmit, errors, setError, setValue, control, getValues, trigger, clearErrors } = useForm();
    const [isEdit, setIsEdit] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    useEffect(() => {
        setValue('practiceName', name);
    }, []);
    const onSubmit = async () => {
        const practiceName = getValues('practiceName');
        try {
            const isValid = await trigger('practiceName');
            if (isValid) {
                setIsLoading(true);
                updateProviderProfile('name', providerId, practiceName).then((success) => {
                    if (success && success.status && success.status.error === false) {
                        fetchProfile('general').then((resp: any) => {
                            setIsLoading(false);
                            setIsEdit(false);
                        }).catch((err: any) => {
                            setIsLoading(false);
                        });
                    }
                }).catch((err) => {
                    setIsLoading(false);
                });
                clearErrors('practiceName');
            }
        } catch (err: any) {
            if (err && err.error) {
                const practiceName = getValues('practiceName');
                setError('practiceName', { type: "manual", message: err.msg });
                setValue('practiceName', practiceName);
                setIsLoading(false);
                return;
            }
        }
    }
    const onCancel = () => {
        setIsEdit(false);
        setValue('practiceName', name);
    }
    return (
        <form onSubmit={(e) => { e.preventDefault() }}>
            <div className="d-flex justify-content-between align-items-center">
                <div className="icon-box me-2"><i className="bi bi-person-fill"></i></div>
                <div className="flex-grow-1">
                    <small className="d-block text-muted">Name</small>
                    {name}
                </div>
                <div className="d-flex justify-content-end">
                    {
                        (isEdit && !isLoading) &&
                        <>
                            <button className="ms-2 btn btn-link p-0 text-secondary" onClick={(e) => { onCancel(); }}><i className="bi bi-x-circle-fill text-danger"></i></button>
                            <button className="ms-2 btn btn-link p-0" onClick={handleSubmit(onSubmit)}><i className="bi bi-check-circle-fill text-success"></i></button>
                        </>
                    }
                    {
                        (isEdit && isLoading) &&
                        <>
                            {/* <button disabled className="ms-3 btn btn-link p-0 text-secondary" onClick={(e) => { onCancel(); }}>Cancel</button>
                        <button disabled className="ms-3 btn btn-link p-0">Saving</button> */}
                            <span className="spinner-border spinner-border-sm text-primary" role="status" aria-hidden="true"></span>
                        </>
                    }
                    {
                        (!isEdit && !isLoading) &&
                        <button className="ms-3 btn btn-link p-0" onClick={(e) => { setIsEdit(true); }}>Edit</button>
                    }
                </div>

            </div>

            <>
                <input type="text" className={"form-control my-2 " + (!isEdit ? 'd-none' : '')} id="practicetName" name="practiceName" placeholder="xyz clinic"
                    ref={register({
                        required: true,
                        pattern: /^[a-zA-Z ]+$/
                    })} />
                {
                    errors.practiceName && errors.practiceName.type === "required" &&
                    <div className="is-invalid">Required</div>
                }
                {
                    errors.practiceName && errors.practiceName.type === "pattern" &&
                    <div className="is-invalid">Allow only alphabets</div>
                }
                {
                    errors.practiceName && errors.practiceName.type === "manual" &&
                    <div className="is-invalid">{errors.practiceName.message}</div>
                }
            </>

        </form>
    )
};

const PracticePhone = ({ phone, fetchProfile, providerId }: any) => {
    const { handleSubmit, errors, setError, setValue, control, getValues, trigger, clearErrors } = useForm();
    const [isEdit, setIsEdit] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    useEffect(() => {
        setValue('practicePhone', phone);
    }, []);
    const onSubmit = async () => {
        const practicePhone = getValues('practicePhone');
        try {
            const isValid = !(practicePhone.length < 10);
            if (isValid) {
                setIsLoading(true);
                updateProviderProfile('phone', providerId, practicePhone).then((success) => {
                    if (success && success.status && success.status.error === false) {
                        fetchProfile('general').then((resp: any) => {
                            setIsLoading(false);
                            setIsEdit(false);
                        }).catch((err: any) => {
                            setIsLoading(false);
                        });
                    }
                }).catch((err) => {
                    setIsLoading(false);
                });
                clearErrors('practicePhone');
            }
        } catch (err: any) {
            if (err && err.error) {
                const practicePhone = getValues('practicePhone');
                setError('practicePhone', { type: "manual", message: err.msg });
                setValue('practicePhone', practicePhone);
                setIsLoading(false);
                return;
            }
        }
    }
    const onCancel = () => {
        setIsEdit(false);
        setValue('practicePhone', phone);
    }
    return (

        <form onSubmit={(e) => { e.preventDefault() }}>

            <div className="d-flex justify-content-between align-items-center">
                <div className="icon-box me-2"><i className="bi bi-telephone-fill"></i></div>
                <div className="flex-grow-1">
                    <small className="d-block text-muted">Mobile Number</small>
                    {phone ? CONSTANT_LABELS.PLUS_ONE : ''}{removeCountryCode(phone)}
                </div>
                <div className="d-flex justify-content-end">
                    {
                        (isEdit && !isLoading) &&
                        <>
                            <button className="ms-2 btn btn-link p-0 text-secondary" onClick={(e) => { onCancel(); }}><i className="bi bi-x-circle-fill text-danger"></i></button>
                            <button className="ms-2 btn btn-link p-0" onClick={handleSubmit(onSubmit)}><i className="bi bi-check-circle-fill text-success"></i></button>
                        </>
                    }
                    {
                        (isEdit && isLoading) &&
                        <>
                            {/* <button disabled className="ms-3 btn btn-link p-0 text-secondary" onClick={(e) => { onCancel(); }}>Cancel</button>
                        <button disabled className="ms-3 btn btn-link p-0">Saving</button> */}
                            <span className="spinner-border spinner-border-sm text-primary" role="status" aria-hidden="true"></span>
                        </>
                    }
                    {
                        (!isEdit && !isLoading) &&
                        <button className="ms-3 btn btn-link p-0" onClick={(e) => { setIsEdit(true); }}>Edit</button>
                    }
                </div>
            </div>

            <>
                <Controller
                    as={ReactInputMask}
                    control={control}
                    mask="9999999999"
                    name="practicePhone"
                    className={"form-control my-2 " + (!isEdit ? 'd-none' : '')}
                    rules={{ required: true, minLength: { value: 10, message: 'Mobile Number is invalid' } }}
                />
                {
                    errors.practicePhone && errors.practicePhone.type === "required" &&
                    <div className="is-invalid">Required</div>
                }
                {
                    errors.practicePhone && errors.practicePhone.type === "minLength" &&
                    <div className="is-invalid">Mobile Number is invalid</div>
                }
                {
                    errors.practicePhone && errors.practicePhone.type === "manual" &&
                    <div className="is-invalid">{errors.practicePhone.message}</div>
                }
            </>

        </form>
    )
}

const PracticeEmail = ({ email, fetchProfile, providerId }: any) => {
    const { register, handleSubmit, errors, setError, setValue, control, getValues, trigger, clearErrors } = useForm();
    const [isEdit, setIsEdit] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    useEffect(() => {
        setValue('email', email);
    }, []);
    const onSubmit = async () => {
        const email = getValues('email');
        try {
            const isValid = await trigger('email');
            if (isValid) {
                setIsLoading(true);
                updateProviderProfile('email', providerId, email).then((success) => {
                    if (success && success.status && success.status.error === false) {
                        fetchProfile('general').then((resp: any) => {
                            setIsLoading(false);
                            setIsEdit(false);
                        }).catch((err: any) => {
                            setIsLoading(false);
                        });
                    }
                }).catch((err) => {
                    setIsLoading(false);
                });
                clearErrors('email');
            }
        } catch (err: any) {
            if (err && err.error) {
                const email = getValues('email');
                setError('email', { type: "manual", message: err.msg });
                setValue('email', email);
                setIsLoading(false);
                return;
            }
        }
    }
    const onCancel = () => {
        setIsEdit(false);
        setValue('email', email);
    }
    return (

        <form onSubmit={(e) => { e.preventDefault() }}>

            <div className="d-flex justify-content-between align-items-center">
                <div className="icon-box me-2"><i className="bi bi-envelope-fill"></i></div>
                <div className="flex-grow-1 charlmt">
                    <small className="d-block text-muted">Email</small>
                    {email}
                </div>
                <div className="d-flex justify-content-end">
                    {
                        (isEdit && !isLoading) &&
                        <>
                            <button className="ms-2 btn btn-link p-0 text-secondary" onClick={(e) => { onCancel(); }}><i className="bi bi-x-circle-fill text-danger"></i></button>
                            <button className="ms-2 btn btn-link p-0" onClick={handleSubmit(onSubmit)}><i className="bi bi-check-circle-fill text-success"></i></button>
                        </>
                    }
                    {
                        (isEdit && isLoading) &&
                        <>
                            {/* <button disabled className="ms-3 btn btn-link p-0 text-secondary" onClick={(e) => { onCancel(); }}>Cancel</button>
                            <button disabled className="ms-3 btn btn-link p-0">Saving</button> */}
                            <span className="spinner-border spinner-border-sm text-primary" role="status" aria-hidden="true"></span>
                        </>
                    }
                    {
                        (!isEdit && !isLoading) &&
                        <button className="ms-3 btn btn-link p-0" onClick={(e) => { setIsEdit(true); }}>Edit</button>
                    }
                </div>

            </div>


            <>
                <input type="email" className={"form-control my-2 " + (!isEdit ? 'd-none' : '')} id="emailAddress" name="email" placeholder="xyz@clinic.com" ref={register({
                    required: true,
                    pattern: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                })} />
                {
                    errors.email && errors.email.type === "required" &&
                    <div className="is-invalid">Required</div>
                }
                {
                    errors.email && errors.email.type === "pattern" &&
                    <div className="is-invalid">Email is invalid</div>
                }
                {
                    errors.email && errors.email.type === "manual" &&
                    <div className="is-invalid">{errors.email.message}</div>
                }
            </>

        </form>
    )
}

const PracticeUniqueLIC = ({ uniqueLIC, fetchProfile, providerId }: any) => {
    const { register, handleSubmit, errors, setError, setValue, control, getValues, trigger, clearErrors } = useForm();
    const [isEdit, setIsEdit] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    useEffect(() => {
        setValue('licence', uniqueLIC);
    }, []);
    const onSubmit = async () => {
        const license = getValues('licence');
        try {
            const isValid = await trigger('licence');
            if (isValid) {
                setIsLoading(true);
                updateProviderProfile('licence', providerId, license).then((success) => {
                    if (success && success.status && success.status.error === false) {
                        fetchProfile('licence').then((resp: any) => {
                            setIsLoading(false);
                            setIsEdit(false);
                        }).catch((err: any) => {
                            setIsLoading(false);
                        });
                    }
                }).catch((err) => {
                    setIsLoading(false);
                });
                clearErrors('licence');
            }
        } catch (err: any) {
            if (err && err.error) {
                const uniqueLIC = getValues('licence');
                setError('licence', { type: "licence", message: err.msg });
                setValue('licence', uniqueLIC);
                setIsLoading(false);
                return;
            }
        }
    }
    const onCancel = () => {
        setIsEdit(false);
        setValue('licence', uniqueLIC);
    }
    return (
        <div>
            <form onSubmit={(e) => { e.preventDefault() }}>
                <div>
                    <div className="d-flex justify-content-between align-items-center">
                        <div className="icon-box me-2">
                            <span className="material-icons">
                                medication
                            </span>
                        </div>
                        <div className="flex-grow-1">
                            <small className="d-block text-muted">License number</small>
                            {uniqueLIC}
                        </div>
                        <div className="d-flex justify-content-end">
                            {
                                (isEdit && !isLoading) &&
                                <>
                                    <button className="ms-2 btn btn-link p-0 text-secondary" onClick={(e) => { onCancel(); }}><i className="bi bi-x-circle-fill text-danger"></i></button>
                                    <button className="ms-2 btn btn-link p-0" onClick={handleSubmit(onSubmit)}><i className="bi bi-check-circle-fill text-success"></i></button>
                                </>
                            }
                            {
                                (isEdit && isLoading) &&
                                <>
                                    <span className="spinner-border spinner-border-sm text-primary" role="status" aria-hidden="true"></span>
                                    {/* <button disabled className="ms-3 btn btn-link p-0 text-secondary" onClick={(e) => { onCancel(); }}>Cancel</button>
                            <button disabled className="ms-3 btn btn-link p-0">Saving</button> */}
                                </>
                            }
                            {
                                (!isEdit && !isLoading) &&
                                <button className="ms-3 btn btn-link p-0" onClick={(e) => { setIsEdit(true); }}>Edit</button>
                            }
                        </div>
                    </div>
                </div>

                <>
                    <input type="text" className={"form-control my-2 " + (!isEdit ? 'd-none' : '')} id="licence" name="licence" placeholder="12345"
                        ref={register({
                            required: true
                        })} />
                    {
                        errors.licence && errors.licence.type === "required" &&
                        <div className="is-invalid">Required</div>
                    }
                    {
                        errors.licence && errors.licence.type === "pattern" &&
                        <div className="is-invalid">Website is invalid</div>
                    }
                </>


            </form>
        </div >
    )
}

export const Gender = ({ providerId, gender, fetchProfile }: any) => {
    const { register, handleSubmit, errors, setError, setValue, control, getValues, trigger, clearErrors } = useForm();
    const [isEdit, setIsEdit] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    useEffect(() => {
        setValue('gender', gender);
    }, []);
    const onSubmit = async () => {
        const gender = getValues('gender');
        try {
            const isValid = await trigger('gender');
            if (isValid) {
                setIsLoading(true);
                updateProviderProfile('gender', providerId, gender).then((success) => {
                    if (success && success.status && success.status.error === false) {
                        fetchProfile('gender').then((resp: any) => {
                            setIsLoading(false);
                            setIsEdit(false);
                        }).catch((err: any) => {
                            setIsLoading(false);
                        });
                    }
                }).catch((err) => {
                    setIsLoading(false);
                });
                clearErrors('gender');
            }
        } catch (err: any) {
            if (err && err.error) {
                const gender = getValues('gender');
                setError('gender', { type: "gender", message: err.msg });
                setValue('gender', gender);
                setIsLoading(false);
                return;
            }
        }
    }
    const onCancel = () => {
        setIsEdit(false);
        setValue('gender', gender);
    }
    return (
        <form onSubmit={(e) => { e.preventDefault() }}>
            <div>
                <div className="d-flex justify-content-between align-items-center">
                    <div className="icon-box me-2">
                        <span className="material-icons">
                            wc
                        </span>
                    </div>
                    <div className="flex-grow-1">
                        <small className="d-block text-muted">Gender</small>
                        {gender}
                    </div>

                    <div className="d-flex justify-content-end">
                        {
                            (isEdit && !isLoading) &&
                            <>
                                <button className="ms-2 btn btn-link p-0 text-secondary" onClick={(e) => { onCancel(); }}><i className="bi bi-x-circle-fill text-danger"></i></button>
                                <button className="ms-2 btn btn-link p-0" onClick={handleSubmit(onSubmit)}><i className="bi bi-check-circle-fill text-success"></i></button>
                            </>
                        }
                        {
                            (isEdit && isLoading) &&
                            <>
                                {/* <button disabled className="ms-3 btn btn-link p-0 text-secondary" onClick={(e) => { onCancel(); }}>Cancel</button>
                        <button disabled className="ms-3 btn btn-link p-0">Saving</button> */}
                                <span className="spinner-border spinner-border-sm text-primary" role="status" aria-hidden="true"></span>
                            </>
                        }
                        {
                            (!isEdit && !isLoading) &&
                            <button className="ms-3 btn btn-link p-0" onClick={(e) => { setIsEdit(true); }}>Edit</button>
                        }
                    </div>

                </div>
            </div>

            {
                (isEdit) &&
                <div className="mt-2 d-flex">
                    <div className="form-check me-3">
                        <input type="radio" className="form-check-input" id="male" name="gender" value="Male"
                            ref={register({
                                required: true
                            })} >
                        </input>
                        <label className="form-check-label" htmlFor="male">
                            Male
                        </label>

                    </div>
                    <div className="form-check">
                        <input type="radio" className="form-check-input" id="female" name="gender" value="Female"
                            ref={register({
                                required: true
                            })} >
                        </input>
                        <label className="form-check-label" htmlFor="female">
                            Female
                        </label>

                    </div>
                    {
                        errors.gender && errors.gender.type === "required" &&
                        <div className="is-invalid">Required</div>
                    }

                </div>
            }


        </form>
    )
};

export const Languages = ({ fetchProfile, providerId, languageList, selectedLanguages }: any) => {
    const { register, handleSubmit, errors, setError, setValue, control, getValues, trigger, clearErrors } = useForm();
    const [isEdit, setIsEdit] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    useEffect(() => {
        setValue('language', selectedLanguages.map((item: any) => ({ value: item.id, label: item.name })));
    }, []);
    const onSubmit = async () => {
        let languages = getValues('language');
        try {
            const isValid = await trigger('language');
            if (isValid) {
                setIsLoading(true);
                languages = languages.map((item: any) => item.value).toString();
                updateProviderProfile('languages', providerId, languages).then((success) => {
                    if (success && success.status && success.status.error === false) {
                        fetchProfile('languages').then((resp: any) => {
                            setIsLoading(false);
                            setIsEdit(false);
                        }).catch((err: any) => {
                            setIsLoading(false);
                        });
                    }
                }).catch((err) => {
                    setIsLoading(false);
                });
                clearErrors('language');
            }
        } catch (err: any) {
            if (err && err.error) {
                const language = getValues('language');
                setError('language', { type: "language", message: err.msg });
                setValue('language', language);
                setIsLoading(false);
                return;
            }
        }
    }
    const onCancel = () => {
        setIsEdit(false);
        setValue('language', selectedLanguages.map((item: any) => ({ value: item.id, label: item.name })));
    }
    return (
        <form onSubmit={(e) => { e.preventDefault() }}>
            <div>
                <div className="d-flex justify-content-between align-items-center">
                    <div className="icon-box me-2"><i className="bi bi-globe2"></i></div>
                    <div className="flex-grow-1 charlmt">
                        <small className="d-block text-muted">Language Spoken</small>
                        {selectedLanguages.map((item: any) => item.name).toString()}
                    </div>
                    <div className="d-flex justify-content-end">
                        {
                            (isEdit && !isLoading) &&
                            <>
                                <button className="ms-2 btn btn-link p-0 text-secondary" onClick={(e) => { onCancel(); }}><i className="bi bi-x-circle-fill text-danger"></i></button>
                                <button className="ms-2 btn btn-link p-0" onClick={handleSubmit(onSubmit)}><i className="bi bi-check-circle-fill text-success"></i></button>
                            </>
                        }
                        {
                            (isEdit && isLoading) &&
                            <>
                                {/* <button disabled className="ms-3 btn btn-link p-0 text-secondary" onClick={(e) => { onCancel(); }}>Cancel</button>
                        <button disabled className="ms-3 btn btn-link p-0">Saving</button> */}
                                <span className="spinner-border spinner-border-sm text-primary" role="status" aria-hidden="true"></span>
                            </>
                        }
                        {
                            (!isEdit && !isLoading) &&
                            <button className="ms-3 btn btn-link p-0" onClick={(e) => { setIsEdit(true); }}>Edit</button>
                        }
                    </div>

                </div>
            </div>

            <>
                {
                    <div className={"" + (!isEdit ? 'd-none' : '')}>
                        <Controller
                            name="language"
                            control={control}
                            rules={{ required: true }}
                            defaultValue={selectedLanguages ? selectedLanguages.map((item: any) => ({ value: item.id, label: item.name })) : []}
                            render={(props) => (
                                <Select
                                    isMulti
                                    value={props.value}
                                    onChange={(values: any) => props.onChange(values)}
                                    options={languageList}
                                />
                            )
                            }
                        ></Controller>
                    </div>
                }
                {
                    errors.language && errors.language.type === "required" &&
                    <div className="is-invalid">Required</div>
                }
            </>


        </form>
    )
}