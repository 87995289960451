import React, { useEffect } from 'react';
import { Modal } from 'react-bootstrap';
export const ShowCallOption = (props: any) => {
    const { appointment } = props;
    console.log(appointment);
    useEffect(() => {
        console.log("Here")
        if (props.appointment && props.communicationprovider === "mango") {
            console.log(props.appointment)
            props.call(props?.appointment?.dependant?.phone || props?.appointment?.patient?.phone, "mobile-patient");
        } else if (props.appointment && props.communicationprovider === "goto") {
            console.log(props.appointment)
            props.call(props?.appointment?.dependant?.phone || props?.appointment?.patient?.phone, "mobile-patient");
        }
    },[props]);
    const hideModal = () => {
        props.closeModal();
    }
    const handleVoiceCallToApp = () => {
        props.call(appointment.dependant.uniqueid, "web-app-patient");
    }
    const handleVoiceCallToMobile = () => {
        props.call(appointment?.dependant?.phone || appointment?.patient?.phone, "mobile-patient");
    }
    const handleVoiceCallToHomePhone = () => {
        props.call(appointment.dependant.homephone, "mobile-patient")
    }
    return (
        <Modal className="newcommon reschedule-popup" show={true} onHide={hideModal} centered>
            <Modal.Header>
                <div className="modal-title text-center flex-grow-1 ms-3">Call options</div>
                <button type="button" className="btn-close" aria-label="Close" onClick={hideModal} ></button>
            </Modal.Header>
            <Modal.Body>
                <div className="d-flex justify-content-center">
                    {
                        appointment && appointment.dependant && appointment.dependant.uniqueid &&
                        <button className="btn btn-outline-primary me-2" onClick={handleVoiceCallToApp}>
                            <i className="bi bi-telephone"></i> Call to patient on web
                        </button>
                    }
                    {
                        appointment && appointment.dependant && appointment.dependant.phone &&
                        <button className="btn btn-outline-primary me-2" onClick={handleVoiceCallToMobile}>
                            <i className="bi bi-telephone"></i> Call to patient on mobile
                        </button>
                    }
                    {
                        appointment && appointment.dependant && appointment.dependant.homephone &&
                        <button className="btn btn-outline-primary me-2" onClick={handleVoiceCallToHomePhone}>
                            <i className="bi bi-telephone"></i> Call to patient on home phone
                        </button>
                    }
                </div>
            </Modal.Body>
        </Modal>
    )
}