import { Data } from './../assets/vendors/popper.js/index.d';
import {
    fetchBillingErrorAction, fetchBillingSuccessAction, addCardSuccessAction, fetchBillingPendingAction,
    addCardErrorAction, removeCardErrorAction, removeCardSuccessAction, enrolCardErrorAction,
    enrolCardSuccessAction, billableEntryFetchErrorAction, billableEntryFetchSuccessAction,
    billableEntryAppointmentErrorAction, billableEntryAppointmentSuccessAction, deferRequestSuccessAction, deferRequestErrorAction
} from './../store/billing/actions';
import { fetchLoadingPending, fetchLoadingSuccess } from './../store/loadingIndicator/actions';
import { attachHeader } from './../utils/apiHandler';
import Axios from 'axios';
import { BILLING, BillingPendingAction, BillingRequest, AddCardRequest, AddCardPendingAction, RemoveCardRequest, RemoveCardPendingAction, EnrolCardRequest, EnrolCardPendingAction, BillableEntryFetchPendingAction, BillableEntryFetchRequest, BillableEntryAppointmentPendingAction, BillableEntryAppointmentRequest, DeferRequestType, DeferRequestResponsePending } from './../store/billing/types';
import { takeEvery, put } from 'redux-saga/effects';
import apiConfigs from '../configs/apiConfigs';
import { fetchUserDataPending } from '../store/userData/actions';
function executeBilling(request: BillingRequest) {
    return Axios.get(apiConfigs.API_URL + 'user/billing/',
        { headers: attachHeader(true), withCredentials: true }
    ).then(response => ({ response }), error => ({ error }));
}
function* fetchBillingPendingRun(action: BillingPendingAction) {
    // yield put(fetchLoadingPending());
    const { response, error } = yield executeBilling(action.payload);
    if (response) {
        if (response.status === 200) {
            if (response.data.status && response.data.status.error === false) {
                yield put(fetchBillingSuccessAction(response.data));
            } else {
                yield put(fetchBillingErrorAction(response));
            }
        } else {
            yield put(fetchBillingErrorAction(response));
        }
    }
    if (error) {
        yield put(fetchBillingErrorAction(error));
    }
    // yield put(fetchLoadingSuccess(false));
}
export default function* fetchBillingPendingWatcher() {
    yield takeEvery(BILLING.BILLING_LOAD_PENDING, fetchBillingPendingRun);
}

/* Add Card Side Effect */
function executeAddCard(request: AddCardRequest) {
    const formData = new FormData();
    formData.append('stripeToken', request.stripeToken);
    return Axios.post(apiConfigs.API_URL + 'user/card/', formData,
        { headers: attachHeader(true), withCredentials: true }
    ).then(response => ({ response }), error => ({ error }));
}
function* addCardPendingRun(action: AddCardPendingAction) {
    // yield put(fetchLoadingPending());
    const { response, error } = yield executeAddCard(action.payload);
    if (response) {
        if (response.status === 200) {
            if (response.data.status && response.data.status.error === false) {
                yield put(addCardSuccessAction(response.data));
                yield put(fetchUserDataPending({}));
            } else {
                yield put(addCardErrorAction(response));
            }
        } else {
            yield put(addCardErrorAction(response));
        }
    }
    if (error) {
        yield put(addCardErrorAction(error));
    }
    // yield put(fetchLoadingSuccess(false));
}
export function* addCardPendingWatcher() {
    yield takeEvery(BILLING.ADD_CARD_PENDING, addCardPendingRun);
}

/* Remove Card Side Effect */
function executeRemoveCard(request: RemoveCardRequest) {
    return Axios.get(apiConfigs.API_URL + 'user/card/remove/' + request.cardId + '/',
        { headers: attachHeader(true), withCredentials: true }
    ).then(response => ({ response }), error => ({ error }));
}
function* removeCardPendingRun(action: RemoveCardPendingAction) {
    // yield put(fetchLoadingPending());
    const { response, error } = yield executeRemoveCard(action.payload);
    if (response) {
        if (response.status === 200) {
            if (response.data.status && response.data.status.error === false) {
                yield put(removeCardSuccessAction({ ...response.data, cardId: action.payload.cardId }));
                yield put(fetchUserDataPending({}));
            } else {
                yield put(removeCardErrorAction(response));
            }
        } else {
            yield put(removeCardErrorAction(response));
        }
    }
    if (error) {
        yield put(removeCardErrorAction(error));
    }
    // yield put(fetchLoadingSuccess(false));
}
export function* removeCardPendingWatcher() {
    yield takeEvery(BILLING.REMOVE_CARD_PENDING, removeCardPendingRun);
}

/* Enrol card Success */
function executeEnrolCard(request: EnrolCardRequest) {
    return Axios.post(apiConfigs.API_URL + 'user/card/enrolled/', request.request,
        { headers: attachHeader(true), withCredentials: true }
    ).then(response => ({ response }), error => ({ error }));
}
function* enrolCardPendingRun(action: EnrolCardPendingAction) {
    // yield put(fetchLoadingPending());
    const { response, error } = yield executeEnrolCard(action.payload);
    if (response) {
        if (response.status === 200) {
            if (response.data.status && response.data.status.error === false) {
                yield put(enrolCardSuccessAction({ ...response.data, clinicId: action.payload.request.clinic }));
            } else {
                yield put(enrolCardErrorAction(response));
            }
        } else {
            yield put(enrolCardErrorAction(response));
        }
    }
    if (error) {
        yield put(enrolCardErrorAction(error));
    }
    // yield put(fetchLoadingSuccess(false));
}
export function* enrolCardPendingWatcher() {
    yield takeEvery(BILLING.ENROLL_CARD_PENDING, enrolCardPendingRun);
}


/* Billable entry fetch year/month */
function executeBillableEntryFetch(request: BillableEntryFetchRequest) {
    return Axios.get(apiConfigs.API_URL + 'prime/' + request.type + '/' + request.uniqueId + '/billableEntry/',
        { headers: attachHeader(true), withCredentials: true })
        .then(response => ({ response }), error => ({ error }));
}
function* billableEntryFetchPendingRun(action: BillableEntryFetchPendingAction) {
    // yield put(fetchLoadingPending());
    const { response, error } = yield executeBillableEntryFetch(action.payload);
    if (response) {
        if (response.status === 200) {
            if (response.data && response.data.status && response.data.status.error === true) {
                yield put(billableEntryFetchErrorAction(response));
            } else if (response.data && response.data.billableYears && response.data.billableMonthsByYear) {
                yield put(billableEntryFetchSuccessAction(response.data));
            } else {
                yield put(billableEntryFetchErrorAction(response));
            }
        } else {
            yield put(billableEntryFetchErrorAction(response));
        }
    }
    if (error) {
        yield put(billableEntryFetchErrorAction(error));
    }
    // yield put(fetchLoadingSuccess(false));
}
export function* billableEntryFetchWatcher() {
    yield takeEvery(BILLING.BILLABLE_YEAR_FETCH_PENDING, billableEntryFetchPendingRun);
}

function executeBillableEntryAppointment(request: BillableEntryAppointmentRequest) {
    return Axios.get(apiConfigs.API_URL + 'prime/' + request.type + '/' + request.uniqueId + '/billableEntry/' + request.year + '/' + request.month + '/',
        { headers: attachHeader(true), withCredentials: true })
        .then(response => ({ response }), error => ({ error }));
}

function* billableEntryAppointmentPendingRun(action: BillableEntryAppointmentPendingAction) {
    // yield put(fetchLoadingPending());
    const { response, error } = yield executeBillableEntryAppointment(action.payload);
    if (response) {
        if (response.status === 200) {
            if (response.data) {
                yield put(billableEntryAppointmentSuccessAction(response.data));
            } else if (!response.data) {
                yield put(billableEntryAppointmentErrorAction(response));
            }
        } else {
            yield put(billableEntryAppointmentErrorAction(response));
        }
    }
    if (error) {
        yield put(billableEntryAppointmentErrorAction(error));
    }
    // yield put(fetchLoadingSuccess(false));
}



export function* billableEntryAppointmentWatcher() {
    yield takeEvery(BILLING.BILLABLE_APPOINTMENT_FETCH_PENDING, billableEntryAppointmentPendingRun);
}


function deferRequestExecuteAPI(requestData: DeferRequestType) {

    return Axios.get(apiConfigs.API_URL + 'reconciled/' + requestData.clinicUniqueId + '/appointment/' + requestData.appointmentId + '/',
        { headers: attachHeader(true), withCredentials: true }
    )
        .then(response => ({ response }), error => ({ error }));
}

function* deferRequestPendingRun(reqData: DeferRequestResponsePending) {
    const { response, error } = yield deferRequestExecuteAPI(reqData.payload);


    if (response) {
        if (response.status === 200) {
            if (response.data && response.data.status.error === false) {
                yield put(deferRequestSuccessAction(response.data));
            } else if (response.data && response.data.length > 0) {
                yield put(deferRequestErrorAction(response));
            }
        } else {
            yield put(deferRequestErrorAction(response));
        }
    }
    if (error) {
        yield put(deferRequestErrorAction(error));
    }
    // yield put(fetchLoadingSuccess(false));
}
export function* deferRequestWatcher() {
    yield takeEvery(BILLING.DEFER_REQUEST_PENDING, deferRequestPendingRun);
}