import React, { Component } from 'react';
import moment from 'moment';
import RightArrowIcon from './images/right-arrow-icon.svg';
import TrustHorizonIcon from './images/trust-horizon.svg';
import InfoIcon from './images/info-icon.svg';
import ContactlesPayment from './images/contactless-payments.svg';

interface Props {
    goToPlans: any;
    selectedPlan: any;
    addons: any;
    selectedAddon: any;

}
interface State {
    selectedPlan: any;
    addons: any;

}
class Plans extends Component<Props, State>{
    constructor(props: any) {
        super(props);
        this.state = {
            selectedPlan: [], addons: []
        }
    }
    componentDidMount() {
        if (this.props.selectedPlan) {
            this.setState({ selectedPlan: this.props.selectedPlan })
        }
        if (this.props.addons) {
            if (this.props.selectedPlan && this.props.selectedPlan.active_plans && this.props.selectedPlan.active_plans[0].interval === 'month') {
                this.setState({ addons: this.props.addons.month });
            } else {
                this.setState({ addons: this.props.addons.year });
            }
        }
    }
    componentDidUpdate(prevProps: Props, prevState: State) {
        if (this.props.addons !== prevProps.addons) {
            if (this.props.selectedPlan && this.props.selectedPlan.active_plans && this.props.selectedPlan.active_plans[0].interval === 'month') {
                this.setState({ addons: this.props.addons.month });
            } else {
                this.setState({ addons: this.props.addons.year });
            }
        }
        if (this.props.selectedPlan !== prevProps.selectedPlan) {
            this.setState({ selectedPlan: this.props.selectedPlan });
            if (this.props.selectedPlan && this.props.selectedPlan.active_plans && this.props.selectedPlan.active_plans[0].interval === 'month') {
                this.setState({ addons: this.props.addons.month, });
            } else {
                this.setState({ addons: this.props.addons.year });
            }
        }
    }
    render() {
        return (
            <section className="subscription-sec">
                <div className="container">
                    <div className="row">
                        <div className="col-xl-8 col-xxl-9">
                            <div className="border p-4 border-1 rounded bg-light custom-card mt-4 default-card">
                                {
                                    (this.state.selectedPlan && this.state.selectedPlan.active_plans &&
                                        this.state.selectedPlan.active_plans.length > 0) &&
                                    this.state.selectedPlan.active_plans.map((item: any) => {
                                        if (item.name === "Basic Plan") {
                                            return (
                                                <div key={item.id} className="d-flex justify-content-between">
                                                    <div>
                                                        <div className="badge rounded-pill bg-dark fw-bolder mb-2">Current plan</div>
                                                        <div className="info-box fs-5 fw-bolder">{item.name}</div>
                                                    </div>
                                                    <div className="align-self-center">
                                                        <div className="d-flex">
                                                            <div className="pe-2 price-tag">
                                                                <span className="fs-6 pe-1 position-relative">$</span>
                                                                <span className="fw-bolder fs-1">{item.price}</span>
                                                                / {item.interval}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        }

                                    })
                                }
                                <div className="addon-list my-4">
                                    {(this.state.selectedPlan && this.state.selectedPlan.active_plans &&
                                        this.state.selectedPlan.active_plans.length > 1) &&
                                        <small className="fw-bolder">Addons</small>
                                    }
                                    {
                                        (this.state.selectedPlan && this.state.selectedPlan.active_plans &&
                                            this.state.selectedPlan.active_plans.length > 0) &&
                                        this.state.selectedPlan.active_plans.map((item: any) => {
                                            if (item.name !== "Basic Plan") {
                                                return (
                                                    <div className="d-flex mb-2 mt-2 border p-4 border-1 rounded bg-light custom-card default-card bg-white justify-content-between">
                                                        <div className="d-flex">
                                                            <img className="me-3 align-self-center" src={ContactlesPayment} />
                                                            <div>
                                                                <small className="fw-bolder text-muted">Customer payment</small>
                                                                <div className="info-box fs-5 fw-bolder">{item.name}</div>
                                                            </div>
                                                        </div>
                                                        <div className="pe-2 price-tag align-self-center">
                                                            <span className="fs-6 pe-1 position-relative">$</span>
                                                            <span className="fw-bolder fs-1">{item.price}</span>
                                                        </div>
                                                    </div>
                                                )
                                            }
                                        })
                                    }
                                </div>
                                <div className="d-flex justify-content-between mt-4">
                                    <div className="text-muted small align-self-center"><img src={InfoIcon} className="me-2" />You can add more addons form plan page</div>
                                    <div onClick={() => { this.props.goToPlans() }} className="btn btn-outline-primary fw-bold bg-white px-4">Upgrade plan</div>
                                </div>
                            </div>
                        </div>
                        {
                            (this.state.selectedPlan && this.state.selectedPlan.plans_exp &&
                                this.state.selectedPlan.plans_exp.length > 0) &&
                            this.state.selectedPlan.plans_exp.map((item: any) => {
                                return (
                                    <div className="col-xl-4 col-xxl-3 align-items-stretch">
                                        <div className="sidebar p-4 border border-1 rounded bg-light mt-4 default-card addon-sidebar">
                                            <div className="d-flex align-items-start flex-column bd-highlight mb-3 h-100">
                                                <div className="pe-2 price-tag bd-highlight">
                                                    <span className="fs-6 pe-1 position-relative">$</span>
                                                    <span className="fw-bolder fs-1">{item.price}</span>
                                                </div>
                                                <div className="next-date mt-2 bd-highlight">
                                                    <small className="d-block fw-bold text-muted">Next payment</small>
                                                    <div className="fw-bolder fs-5">on {moment(item.end_date).format('MMM DD, YYYY')}</div>
                                                </div>
                                                <button className="btn btn-outline-primary fw-bold bg-white px-4 bd-highlight mt-auto">Manage payments</button>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>
                    <div className="row">
                        <div className="col-xl-8 col-xxl-9">
                            {
                                (this.state.addons && this.state.addons.length > 0) &&
                                this.state.addons.map((item: any) => {
                                    if (item.planType === "custom") {
                                        return (
                                            <div className=" border p-4 border-1 rounded bg-white custom-card my-4 default-card">
                                                <div className="d-flex justify-content-between">
                                                    <div>
                                                        <div className="d-flex mb-2">
                                                            <img className="me-3 align-self-center" src={TrustHorizonIcon} />
                                                            <div>
                                                                <small className="fw-bolder text-muted">{item.title}</small>
                                                                <div className="info-box fs-5 fw-bolder">{item.name}</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="">
                                                        <div className="d-flex">
                                                            <div className="pe-2 price-tag">
                                                                <span className="fs-6 pe-1 position-relative">$</span>
                                                                <span className="fw-bolder fs-1">{item.plan_price}</span>
                                                            </div>
                                                            <small className="align-self-center">Per clinic <br />Per {item.plan_interval}</small>
                                                        </div>
                                                    </div>
                                                </div>
                                                {/* <p>A text message application that gets reviews from your customers after they leave your business.</p> */}
                                                <p>{item.short_desc}</p>
                                                <button className="btn btn-link ps-0">Read more details <img className="ms-2" src={RightArrowIcon} /></button>
                                                <div className="text-end">
                                                    <button onClick={() => {
                                                        this.props.selectedAddon(item);
                                                    }} className="btn btn-dark">Add </button>
                                                </div>
                                            </div>
                                        )
                                    }
                                })
                            }
                            {/* <div className=" border p-4 border-1 rounded bg-white custom-card my-4 default-card">
                                <div className="d-flex justify-content-between">
                                    <div>
                                        <div className="d-flex mb-2">
                                            <img className="me-3 align-self-center" src={require("./images/recall-recare.svg")} />
                                            <div>
                                                <small className="fw-bolder text-muted">Send notification</small>
                                                <div className="info-box fs-5 fw-bolder">RECALL & RECARE</div>
                                            </div>
                                        </div>							
                                    </div>
                                    <div className="">
                                        <div className="d-flex">
                                            <div className="pe-2 price-tag">
                                                <span className="fs-6 pe-1 position-relative">$</span>
                                                <span className="fw-bolder fs-1">99</span>
                                            </div>
                                            <small className="align-self-center">Per clinic <br/>Per month</small>
                                        </div>
                                    </div>
                                </div>
                                <p>A text message application that gets reviews from your customers after they leave your business.</p>
                                <button className="btn btn-link ps-0">Read more details <img className="ms-2" src="./images/right-arrow-icon.svg"/></button>
                                <div className="text-end">
                                    <button className="btn btn-dark">Add <img src={require("./images/spinner-icon.svg")} className="btn-img" /></button>
                                </div>
                            </div> */}
                        </div>
                    </div>
                </div>
            </section>
        )
    }
}
export default Plans;