import React, { useEffect, useMemo, useRef, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { useRouteMatch } from "react-router";
import { IAppState } from "../../../store";
import {
  createLogin,
  getDependantDetail,
  addMember,
  editMember,
  createGuarantor,
  guarantorEdit,
} from "../../services/patientsearch";
import moment from "moment";
import configs from "../../configs/apiConfigs";
import { toast } from "react-toastify";
import { Controller, useForm } from "react-hook-form";
import ReactInputMask from "react-input-mask";
import { KeyboardDatePicker } from "@material-ui/pickers";
import { AddMemberModal } from "../../components/patientsearch/AddMemberModal";
import { removeCountryCode, takeOnlyDigitAndPlus } from "../../../utils/global";
import { getInsuranceList } from "../../services/clinicOnboard";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { storeChatData } from "../../../store/chat/action";
import { ScheduleReScheduleModal } from "../myschedule/ScheduleReScheduleModal";
import CONSTANT_LABELS from "../../../constants/labels";
import { DigitalDoc } from "../../components/digitaldoc/DigitalDoc";
import { ChangeGuarantorInfoModal } from "./ChangeGuarantor";
import { DocumentUpload } from "../../components/documentsUpload/DocumentUpload";
import { uploadDocuments } from "../../services/documents";
import Thumb1Jpg from "../../../assets/images/avatars/thumb-1.jpg";
export const PatientDetail = () => {
  const match: any = useRouteMatch();
  const userData = useSelector(
    (state: IAppState) => state.userData.userDataResponse,
    shallowEqual
  );
  const [isLoading, setLoading] = useState(false);
  const [detail, setDetail]: any = useState(null);
  const [selectedPatient, setSelectedPatient]: any = useState(null);
  const [isEditPatient, setIsEditPatient] = useState(false);
  const [isEditPatientLoading, setIsEditPatientLoading] = useState(false);
  const [addMemberShow, setAddMemberShow] = useState(false);
  const [isCreateLoginLoading, setIsCreateLoginLoading] = useState(false);
  const [masterInsuranceList, setMasterInsuraceList] = useState([]);
  const [masterInsurancePlanList, setMasterInsuracePlanList]: any = useState(
    []
  );
  const [showScheduleReScheduleModal, setShowScheduleReScheduleModal] =
    useState(false);
  const [currentActiveModalAppointment, setCurrentActiveModalAppointment]: any =
    useState(null);
  const [digitalDocShow, setShowDigitalDocShow] = useState(false);
  const [showDocumentsModal, setShowDocumentsModal] = useState(false);
  const [showDocumentsModalPatient, setShowDocumentsModalPatient] = useState(false);
  const dispatch = useDispatch();
  const uploadRef = useRef(null);
  const {
    register,
    setValue,
    errors,
    getValues,
    handleSubmit,
    control,
    watch,
    setError,
  } = useForm({
    defaultValues: {
      firstName: "",
      lastName: "",
      relation: "Son",
      gender: "Male",
      automatedreminder: "no",
      pcontactmethod: "email",
      email: "",
      phone: "",
      dob: moment().subtract(1, "day"),
      insurance: "",
      insuranceplanname: "",
      insuranceothername: "",
      insuranceplanothername: "",
    },
  });
  useEffect(() => {
    if (
      match &&
      match.params &&
      match.params.id &&
      userData &&
      userData.clinics[0]
    ) {
      getDependantDetailFunc(userData.clinics[0].uniqueId, match.params.id);
    }
    getInsuranceList()
      .then((success) => {
        if (success && success.status && success.status.error == false) {
          const insurances = success.insurance;
          insurances.push({
            id: "other",
            name: "Other",
            plan: [{ id: "other", name: "Other" }],
          });
          setMasterInsuraceList(insurances);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, [match, userData]);
  const getDependantDetailFunc = (
    clinicId: any,
    dependantId: any,
    isRealTime = false,
    setPatientId = ""
  ) => {
    if (isRealTime === false) {
      setLoading(true);
    }
    getDependantDetail(clinicId, dependantId)
      .then((success) => {
        setLoading(false);
        if (success && success.status && success.status.error === false) {
          console.log(success);
          getInsuranceList()
            .then((success) => {
              if (success && success.status && success.status.error == false) {
                const insurances = success.insurance;
                insurances.push({
                  id: "other",
                  name: "Other",
                  plan: [{ id: "other", name: "Other" }],
                });
                setMasterInsuraceList(insurances);
              }
            })
            .catch((err) => {
              console.log(err);
            });
          setDetail(success);
          if (setPatientId !== "") {
            const patient = success.dependant.find(
              (item: any) => item.id === setPatientId
            );
            if (patient) {
              setSelectedPatient(patient);
            }
          } else {
            try {
              const selectedDependat = success.dependant.find((item: any) => {
                return item.uniqueId === match.params.id;
              });
              if (selectedDependat) {
                setSelectedPatient(selectedDependat);
              } else {
                let dependant = success.dependant[0];
                setSelectedPatient(dependant);
              }
            } catch (err) {
              let dependant = success.dependant[0];
              setSelectedPatient(dependant);
            }
          }
        }
      })
      .catch((err) => {
        setLoading(false);
      });
  };
  const createLoginFunc = () => {
    let clinicId = userData.clinics[0].uniqueId;
    let dependantId = selectedPatient.id;
    setIsCreateLoginLoading(true);
    createLogin(clinicId, dependantId)
      .then((success) => {
        setIsCreateLoginLoading(false);
        if (success && success.status && success.status.error === false) {
          toast.success(success.status.msg);
          getDependantDetailFunc(
            clinicId,
            match.params.id,
            true,
            selectedPatient.id
          );
          return;
        }
        toast.error("Signin creation failed");
      })
      .catch((err) => {
        setIsCreateLoginLoading(false);
        if (err && err.error === true) {
          toast.error(err.msg);
          return;
        }
        toast.error("Signin creation failed");
        console.log(err);
      });
  };
  const createMemberFunc = (data: any) => {
    const clinicId = userData.clinics[0].uniqueId;
    Object.assign(data, { guarantorId: detail.guarantorId });
    return addMember(data, clinicId)
      .then((success) => {
        if (success && success.status && success.status.error === false) {
          getDependantDetailFunc(
            clinicId,
            match.params.id,
            true,
            selectedPatient.id
          );
          setAddMemberShow(false);
          return;
        }
        toast.error("Add member failed");
      })
      .catch((err) => {
        if (err && err.error === true) {
          toast.error(err.msg);
          throw err;
        }
        toast.error("Add member failed");
        throw err;
      });
  };
  const editMemberFunc = (data: any) => {
    const clinicId = userData.clinics[0].uniqueId;
    Object.assign(data, {
      guarantorId: detail.guarantorId,
      dependantId: selectedPatient.id,
    });
    editMember(data, clinicId)
      .then((success) => {
        setIsEditPatientLoading(false);
        if (success && success.status && success.status.error === false) {
          getDependantDetailFunc(
            clinicId,
            match.params.id,
            true,
            selectedPatient.id
          );
          setIsEditPatient(false);
          return;
        }
        toast.error("Add member failed");
      })
      .catch((err) => {
        setIsEditPatientLoading(false);
        if (err && err.error === true) {
          toast.error(err.msg);
          return;
        }
        toast.error("Add member failed");
      });
  };
  const createGuarantorFunc = () => {
    let data = {
      guarantorId: selectedPatient.guarantorId,
    };
    let clinicId = userData.clinics[0].uniqueId;
    let dependantId = selectedPatient.id;
    createGuarantor(data, clinicId, dependantId)
      .then((success) => {
        if (success && success.status && success.status.error === false) {
          toast.success(success.status.msg);
          getDependantDetailFunc(
            clinicId,
            match.params.id,
            true,
            selectedPatient.id
          );
          return;
        }
        toast.error("Create guarantor failed");
      })
      .catch((err) => {
        if (err && err.error === true) {
          toast.error(err.msg);
          return;
        }
        toast.error("Create guarantor failed");
        console.log(err);
      });
  };
  const onEditClick = (data: any) => {
    try {
      let insurance: any = masterInsuranceList.find(
        (item: any) => item.id === getValues("insurance")
      );
      let insurancePlan: any = masterInsurancePlanList.find(
        (item: any) => item.id == getValues("insuranceplanname")
      );
      let requestData: any = {
        fname: getValues("firstName"),
        lname: getValues("lastName"),
        relation: getValues("relation"),
        email: getValues("email"),
        phone: getValues("phone"),
        gender: getValues("gender"),
        dob: moment(getValues("dob")).format("YYYY-MM-DD"),
        contactMethod: getValues("pcontactmethod")
          ? getValues("pcontactmethod")
          : "",
        reminder: getValues("automatedreminder"),
      };
      if (insurance && insurancePlan) {
        requestData = {
          ...requestData,
          insurance: {
            id: insurance.id,
            name:
              getValues("insurance") === "other"
                ? getValues("insuranceothername")
                : insurance.name,
            plan: insurancePlan.id,
            planName:
              getValues("insuranceplanname") === "other"
                ? getValues("insuranceplanothername")
                : insurancePlan.name,
          },
        };
      }
      setIsEditPatientLoading(true);
      editMemberFunc(requestData);
    } catch (err) {
      setIsEditPatientLoading(false);
    }
  };
  const handleScheduleAppointmentClick = (item: any) => {
    setShowScheduleReScheduleModal(true);
    setCurrentActiveModalAppointment(item);
  };
  const fecthAppointmentListAsync = async () => {
    getDependantDetailFunc(
      userData.clinics[0].uniqueId,
      match.params.id,
      false,
      selectedPatient.id
    );
  };
  useEffect(() => {
    if (selectedPatient) {
      setValue("firstName", selectedPatient.firstName);
      setValue("lastName", selectedPatient.lastName);
      setValue("email", selectedPatient.email);
      setValue("gender", selectedPatient.gender);
      setValue(
        "relation",
        selectedPatient.relation ? selectedPatient.relation : ""
      );
      setValue("automatedreminder", selectedPatient.reminder);
      setValue(
        "pcontactmethod",
        selectedPatient.contactMethod
          ? selectedPatient.contactMethod
            .replaceAll(" ", "")
            .replaceAll("&", ",")
            .toLowerCase()
          : ""
      );
      setValue(
        "dob",
        selectedPatient.dob
          ? moment(selectedPatient.dob, "YYYY-MM-DD")
          : moment().subtract(1, "day")
      );
      setValue("phone", selectedPatient.phone ? selectedPatient.phone : "");
      setValue(
        "insurance",
        selectedPatient.insuranceId ? selectedPatient.insuranceId : ""
      );
      setValue(
        "insuranceplanname",
        selectedPatient.insurancePlan ? selectedPatient.insurancePlan : ""
      );
    }
  }, [selectedPatient]);
  useEffect(() => {
    if (getValues("insurance") && getValues("insurance") !== "") {
      let insurance: any = masterInsuranceList.find(
        (item: any) => item.id == getValues("insurance")
      );
      if (insurance && insurance.plan) {
        if (insurance.id === "other") {
          insurance = {
            ...insurance,
            plan: [{ id: "other", name: "Other" }],
          };
        } else {
          insurance = {
            ...insurance,
            plan: [...insurance.plan, { id: "other", name: "Other" }],
          };
        }
        setMasterInsuracePlanList(insurance.plan);
        if (insurance.plan[0]) {
          setValue("insuranceplanname", insurance.plan[0].id);
        }
      }
    }
  }, [watch("insurance")]);
  let insurance: any = null;
  let insurancePlan: any = null;
  if (selectedPatient && selectedPatient.insuranceId) {
    insurance = masterInsuranceList.find(
      (item: any) => item.id === selectedPatient.insuranceId
    );
  }
  if (selectedPatient && selectedPatient.insurancePlan && insurance) {
    insurancePlan = insurance.plan.find(
      (item: any) => item.id === selectedPatient.insurancePlan
    );
  }
  const handleDigitalDocClick = (appointment: any) => {
    setCurrentActiveModalAppointment(appointment);
    setShowDigitalDocShow(true);
  }
  const handleDocumentsClick = (appointment: any) => {
    setCurrentActiveModalAppointment(appointment);
    setShowDocumentsModal(true);
  }
  const handleFileChange = (e: any) => {
    const file = e.target.files[0];
    uploadDocuments(userData.clinics[0].uniqueId, {
        type: "upload",
        "uphoto[]": file,
        "patientId" : selectedPatient.id,
    }).then((success) => {
        //@ts-ignore
        uploadRef.current.value = null;
        if (!success?.status?.error) {
            toast.success("Document uploaded successfully.");
        } else {
            toast.error("Document upload failed.");
        }
    }).catch((error) => {
        console.log(error);
        toast.error("Document upload failed.");
    })
}
  return (
    <>
      {useMemo(() => {
        if (currentActiveModalAppointment && showScheduleReScheduleModal) {
          return (
            <ScheduleReScheduleModal
              appointment={currentActiveModalAppointment}
              hideModal={() => {
                setShowScheduleReScheduleModal(false);
              }}
              fetchAppointment={fecthAppointmentListAsync}
              isReschedule={true}
            ></ScheduleReScheduleModal>
          );
        }
        return null;
      }, [currentActiveModalAppointment, showScheduleReScheduleModal])}
      <section className="patient-details-sec px-3 pt-4">
        {isLoading && <span className="spinner-border" role="status"></span>}
        {!isLoading && !detail && <p>No records found</p>}
        {!isLoading && detail && (
          <div className="container">
            <div className="familytree border-bottom pb-4 mb-4">
              <div className="d-flex align-items-center mb-4">
                <div className="flex-grow-1 ">
                  <div className="fs-5 fw-medium">Family tree</div>
                  <div className="text-muted">
                    Manage all family members details.
                  </div>
                </div>
                <div>
                  {
                    !userData?.accessEnabled?.pms_integration &&
                    <>
                      <button
                        type="button"
                        className="btn btn-outline-primary me-2"
                        onClick={() => {
                          setShowDocumentsModalPatient(true);
                        }}
                      >
                        View Uploaded Documents
                      </button>
                      <input type="file" style={{ display: 'none' }} ref={uploadRef} onChange={handleFileChange} />
                      <button
                        type="button"
                        className="btn btn-outline-primary me-2"
                        onClick={() => {
                          //@ts-ignore
                          uploadRef?.current?.click();
                        }}
                      >
                        Upload Documents
                      </button>
                    </>
                  }
                  {selectedPatient &&
                    selectedPatient.loginId !== detail.gurantorId &&
                    !userData?.accessEnabled?.pms_integration &&
                    userData &&
                    userData.clinics[0] &&
                    userData.clinics[0].writeBack &&
                    userData.clinics[0].writeBack === "off" && (
                      <button
                        type="button"
                        className="btn btn-outline-primary me-2"
                        onClick={() => {
                          createGuarantorFunc();
                        }}
                      >
                        Change family guarantor
                      </button>
                    )}
                  {
                    !userData?.accessEnabled?.pms_integration &&
                    <button
                      type="button"
                      className="btn btn-outline-primary"
                      onClick={() => {
                        setAddMemberShow(true);
                      }}
                    >
                      Add new member
                    </button>
                  }
                </div>
              </div>
              <div className="familycard">
                <div className="row">
                  {detail &&
                    detail.dependant &&
                    detail.dependant.map((value: any) => {
                      return (
                        <div
                          className={
                            "col-3 mb-3 cursor-pointer " +
                            (selectedPatient && value.id === selectedPatient.id
                              ? "active"
                              : "")
                          }
                          key={value.id + "dept"}
                          onClick={(e) => {
                            setIsEditPatient(false);
                            setSelectedPatient(value);
                          }}
                        >
                          <div className="card border bg-light">
                            <div className="card-body">
                              <div className="d-flex p-0 align-items-center">
                                <div className="avatar flex-shrink-0 avatar-lg border rounded-circle text-uppercase me-2">
                                  {value.firstName.charAt(0)}
                                  {value.lastName.charAt(0)}
                                </div>
                                <div className="yesav">
                                  <span className="d-block fw-medium charlmt">
                                    {value.firstName + " " + value.lastName}
                                  </span>
                                  {value.loginId === detail.guarantorId && (
                                    <small className="d-block text-muted">
                                      Guarantor
                                    </small>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                </div>
              </div>
            </div>
            {selectedPatient && (
              <div className="patient-info border-bottom pb-4 mb-4">
                {/* Non Edit view */}
                {
                  <div className={"row " + (!isEditPatient ? "" : "d-none")}>
                    <div className="col-4">
                      <div className="pati-logininfo text-center">
                        <div className="mb-4">
                          <img
                            className="rounded-circle"
                            src={Thumb1Jpg}
                            alt="profile image"
                            width="120"
                            height="120"
                          />
                        </div>
                        <div className="mb-4">
                          <div className="fw-medium">
                            {selectedPatient.firstName +
                              " " +
                              selectedPatient.lastName}
                          </div>
                          {selectedPatient.loginInfo && (
                            <div className="text-muted">
                              Patient ID:{" "}
                              {selectedPatient.loginInfo &&
                                selectedPatient.loginInfo.uniqueId}
                            </div>
                          )}
                        </div>
                        {selectedPatient.loginInfo && (
                          <div className="mb-4">
                            <div className="fw-medium mt-4">Xcare Sign in ID</div>
                            <div className="text-muted">
                              {selectedPatient.loginInfo.email}
                            </div>
                            <div className="text-muted">
                              {selectedPatient.loginInfo.phone}
                            </div>
                          </div>
                        )}
                        <div>
                          {selectedPatient.loginId === null && (
                            <button
                              disabled={isCreateLoginLoading}
                              className="btn btn-outline-primary me-2"
                              onClick={() => {
                                createLoginFunc();
                              }}
                            >
                              {isCreateLoginLoading && (
                                <span
                                  className="spinner-border spinner-border-sm me-1"
                                  role="status"
                                  aria-hidden="true"
                                ></span>
                              )}
                              Create Signin
                            </button>
                          )}
                          {/* <button className="btn btn-primary">Send message</button> */}
                        </div>
                      </div>
                    </div>
                    <div className="col-8">
                      <div className="d-flex align-items-center mb-4">
                        <div className="flex-grow-1 ">
                          <div className="fs-5 fw-medium">
                            Patient information
                          </div>
                        </div>
                        <div>
                          <button
                            type="button"
                            className="btn btn-outline-primary me-2"
                            onClick={(e) => {
                              setIsEditPatient(true);
                              setValue("firstName", selectedPatient.firstName);
                              setValue("lastName", selectedPatient.lastName);
                              setValue("email", selectedPatient.email);
                              setValue("gender", selectedPatient.gender);
                              setValue(
                                "relation",
                                selectedPatient.relation
                                  ? selectedPatient.relation
                                  : ""
                              );
                              setValue(
                                "automatedreminder",
                                selectedPatient.reminder
                              );
                              setValue(
                                "pcontactmethod",
                                selectedPatient.contactMethod
                                  ? selectedPatient.contactMethod
                                    .replaceAll(" ", "")
                                    .replaceAll("&", ",")
                                    .toLowerCase()
                                  : ""
                              );
                              setValue(
                                "dob",
                                selectedPatient.dob
                                  ? moment(selectedPatient.dob, "YYYY-MM-DD")
                                  : moment().subtract(1, "day")
                              );
                              setValue(
                                "phone",
                                selectedPatient.phone
                                  ? selectedPatient.phone
                                  : ""
                              );
                              setValue(
                                "insurance",
                                selectedPatient.insuranceId
                                  ? selectedPatient.insuranceId
                                  : ""
                              );
                              setValue(
                                "insuranceplanname",
                                selectedPatient.insurancePlan
                              );
                            }}
                          >
                            Edit
                          </button>
                        </div>
                      </div>
                      <div className="d-flex flex-wrap">
                        <div className="me-5 mb-4">
                          <div className="text-muted">Communication email</div>
                          <div className="fw-medium">
                            {selectedPatient.email
                              ? selectedPatient.email
                              : "-"}
                          </div>
                        </div>
                        <div className="me-5 mb-4">
                          <div className="text-muted">Mobile Number</div>
                          <div className="fw-medium">
                            {selectedPatient.phone
                              ? CONSTANT_LABELS.PLUS_ONE +
                              removeCountryCode(selectedPatient.phone)
                              : "-"}
                          </div>
                        </div>
                        <div className="me-5 mb-4">
                          <div className="text-muted">Date of birth</div>
                          <div className="fw-medium">
                            {selectedPatient.dob &&
                              moment(selectedPatient.dob).format(
                                "DD MMMM YYYY"
                              ) !== "Invalid date"
                              ? moment(selectedPatient.dob).format(
                                "MMM DD, YYYY"
                              )
                              : "-"}
                          </div>
                        </div>
                        <div className="me-5 mb-4">
                          <div className="text-muted">Gender</div>
                          <div className="fw-medium">
                            {selectedPatient.gender
                              ? selectedPatient.gender
                              : "-"}
                          </div>
                        </div>
                        <div className="me-5 mb-4">
                          <div className="text-muted">Automated Reminder</div>
                          <div className="fw-medium text-capitalize">
                            {selectedPatient.reminder
                              ? selectedPatient.reminder
                              : "-"}
                          </div>
                        </div>
                        {selectedPatient.reminder &&
                          selectedPatient.reminder === "yes" && (
                            <div className="me-5 mb-4">
                              <div className="text-muted">
                                Preffered contact method
                              </div>
                              <div className="fw-medium text-capitalize">
                                {selectedPatient.contactMethod
                                  ? selectedPatient.contactMethod
                                    .replaceAll(" ", "")
                                    .replaceAll("&", ",")
                                  : "-"}
                              </div>
                            </div>
                          )}
                        {(!selectedPatient.reminder ||
                          (selectedPatient.reminder &&
                            selectedPatient.reminder === "no")) && (
                            <div className="me-5 mb-4">
                              <div className="text-muted">
                                Preffered contact method
                              </div>
                              <div className="fw-medium text-capitalize">-</div>
                            </div>
                          )}
                        {selectedPatient.insuranceId &&
                          masterInsuranceList.length > 0 &&
                          insurance && (
                            <div className="me-5 mb-4">
                              <div className="text-muted">Insurance name</div>
                              <div className="fw-medium">{insurance.name}</div>
                            </div>
                          )}
                        {!selectedPatient.insuranceId && (
                          <div className="me-5 mb-4">
                            <div className="text-muted">Insurance name</div>
                            <div className="fw-medium">-</div>
                          </div>
                        )}
                        {masterInsurancePlanList.length > 0 &&
                          insurancePlan &&
                          selectedPatient.insurancePlan && (
                            <div className="me-5 mb-4">
                              <div className="text-muted">Insurance plan</div>
                              <div className="fw-medium text-capitalize">
                                {insurancePlan.name}
                              </div>
                            </div>
                          )}
                        {(!insurancePlan || !selectedPatient.insurancePlan) && (
                          <div className="me-5 mb-4">
                            <div className="text-muted">Insurance plan</div>
                            <div className="fw-medium text-capitalize">-</div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                }
                {/* Edit view */}
                {
                  <div className={"row " + (isEditPatient ? "" : "d-none")}>
                    <div className="col-4">
                      <div className="pati-logininfo text-center">
                        <div className="mb-4">
                          <img
                            className="rounded-circle"
                            src={Thumb1Jpg}
                            alt="profile image"
                            width="120"
                            height="120"
                          />
                        </div>
                        <div className="mb-4">
                          <div className="fw-medium">
                            {selectedPatient.firstName +
                              " " +
                              selectedPatient.lastName}
                          </div>
                          {selectedPatient.loginInfo && (
                            <div className="text-muted">
                              Patient ID:{" "}
                              {selectedPatient.loginInfo &&
                                selectedPatient.loginInfo.uniqueId}
                            </div>
                          )}
                        </div>
                        {selectedPatient.loginInfo &&
                          selectedPatient.loginInfo.email &&
                          selectedPatient.loginInfo.email !== "" && (
                            <div className="mb-4">
                              <div className="fw-medium mt-4">Xcare Sign in ID</div>
                              <div className="text-muted">
                                {selectedPatient.loginInfo.email}
                              </div>
                              <div className="text-muted">
                                {selectedPatient.loginInfo.phone}
                              </div>
                            </div>
                          )}
                      </div>
                    </div>
                    <div className="col-8">
                      <div className="d-flex align-items-center mb-4">
                        <div className="flex-grow-1 ">
                          <div className="fs-5 fw-medium">
                            Patient information
                          </div>
                        </div>
                        <div>
                          {isEditPatientLoading && (
                            <span
                              className="spinner-border spinner-border-sm text-primary"
                              role="status"
                              aria-hidden="true"
                            ></span>
                          )}
                          {!isEditPatientLoading && (
                            <>
                              <button
                                className="btn p-0 btn-link me-2 text-secondary"
                                onClick={(e) => {
                                  setIsEditPatient(false);
                                }}
                              >
                                <i className="bi bi-x-circle-fill text-danger"></i>
                              </button>
                              <button
                                className="btn p-0 btn-link"
                                onClick={handleSubmit(onEditClick)}
                              >
                                <i className="bi bi-check-circle-fill text-success"></i>
                              </button>
                            </>
                          )}
                        </div>
                      </div>
                      <div className="col-10">
                        <div className="row g-3">
                          <div className="col-6">
                            <label className="form-label">First name</label>
                            <input
                              type="text"
                              name="firstName"
                              className="form-control"
                              placeholder="First name"
                              disabled={userData?.accessEnabled?.pms_integration === true}
                              ref={register()}
                            />
                            {/* {errors &&
                              errors.firstName &&
                              errors.firstName.type === "required" && (
                                <div className="is-invalid">
                                  First name is required
                                </div>
                              )} */}
                          </div>
                          <div className="col-6">
                            <label className="form-label">Last name</label>
                            <input
                              type="text"
                              name="lastName"
                              className="form-control"
                              placeholder="Last Name"
                              disabled={userData?.accessEnabled?.pms_integration === true}
                              ref={register()}
                            />
                            {/* {errors &&
                              errors.lastName &&
                              errors.lastName.type === "required" && (
                                <div className="is-invalid">
                                  Last name is required
                                </div>
                              )} */}
                          </div>
                          <div className="col-6">
                            <label className="form-label">Mobile Number</label>
                            <Controller
                              as={ReactInputMask}
                              control={control}
                              mask="9999999999"
                              name="phone"
                              className="form-control"
                              placeholder="1234567890"
                              rules={{
                                // required: watch("email") === "",
                                minLength: {
                                  value: 10,
                                  message: "Mobile Number is invalid",
                                },
                                maxLength: {
                                  value: 10,
                                  message: "Mobile Number is invalid",
                                },
                              }}
                            />
                            {/* {errors &&
                              errors.phone &&
                              errors.phone.type === "required" && (
                                <div className="is-invalid">
                                  Mobile Number is required
                                </div>
                              )} */}
                            {errors &&
                              errors.phone &&
                              errors.phone.type === "minLength" && (
                                <div className="is-invalid">
                                  Mobile Number is required
                                </div>
                              )}
                            {errors &&
                              errors.phone &&
                              errors.phone.type === "maxLength" && (
                                <div className="is-invalid">
                                  Mobile Number is required
                                </div>
                              )}
                          </div>
                          <div className="col-6">
                            <label className="form-label">
                              Communication email
                            </label>
                            <input
                              type="email"
                              name="email"
                              className="form-control"
                              placeholder="Email"
                              ref={register({
                                // required: watch("phone") === "",
                                pattern:
                                  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                              })}
                            ></input>
                            {errors &&
                              errors.email &&
                              errors.email.type === "required" && (
                                <div className="is-invalid">
                                  Email is required
                                </div>
                              )}
                            {errors &&
                              errors.email &&
                              errors.email.type === "pattern" && (
                                <div className="is-invalid">
                                  Email is invalid
                                </div>
                              )}
                          </div>
                          <div className="col-6 miuif_margin">
                            <label className="form-label">Date of birth</label>&nbsp;&nbsp;&nbsp;&nbsp;
                            <Controller
                              name="dob"
                              control={control}
                              disabled={userData?.accessEnabled?.pms_integration === true}
                              render={(props) => {
                                return (
                                  <KeyboardDatePicker
                                    margin="none"
                                    id={"date-picker-dialog-dob"}
                                    name={"date-picker-dialog-dob"}
                                    format="YYYY/MM/DD"
                                    maxDate={moment().subtract(1, "days")}
                                    disabled={userData?.accessEnabled?.pms_integration === true}
                                    value={props.value}
                                    onChange={(date: any) => {
                                      props.onChange(date);
                                    }}
                                    KeyboardButtonProps={{
                                      "aria-label": "change date",
                                    }}
                                  />
                                );
                              }}
                            ></Controller>
                          </div>
                          <div className="col-6">
                            <label className="form-label">Gender</label>
                            <select
                              className="form-select"
                              name="gender"
                              disabled={userData?.accessEnabled?.pms_integration === true}
                              ref={register()}
                            >
                              <option value="Male">Male</option>
                              <option value="Female">Female</option>
                            </select>
                            {/* {errors &&
                              errors.gender &&
                              errors.gender.type === "required" && (
                                <div className="is-invalid">
                                  Gender is required
                                </div>
                              )} */}
                          </div>
                          {selectedPatient.loginId !==
                            selectedPatient.guarantorId && (
                              <div className="col-12">
                                <div className="col-6">
                                  <label className="form-label">Relation with Guarantor</label>
                                  <select
                                    className="form-select"
                                    name="relation"
                                    disabled={userData?.accessEnabled?.pms_integration === true}
                                    ref={register()}
                                  >
                                    <option value="Son">Son</option>
                                    <option value="Daughter">Daughter</option>
                                    <option value="Spouse">Spouse</option>
                                    <option value="Self">Self</option>
                                  </select>
                                  {/* {errors &&
                                    errors.relation &&
                                    errors.relation.type === "required" && (
                                      <div className="is-invalid">
                                        Relation with guarantor is required
                                      </div>
                                    )} */}
                                </div>
                              </div>
                            )}
                          <div className="col-6">
                            <label className="form-label">Insurance name</label>
                            <select
                              className="form-select"
                              name="insurance"
                              disabled={userData?.accessEnabled?.pms_integration === true}
                              ref={register}
                            >
                              {masterInsuranceList.map((item: any) => {
                                return (
                                  <option value={item.id} key={item.id}>
                                    {item.name}
                                  </option>
                                );
                              })}
                            </select>
                            {watch("insurance") === "other" && (
                              <>
                                <input
                                  type="text"
                                  disabled={userData?.accessEnabled?.pms_integration === true}
                                  className="form-control"
                                  placeholder="Insurance name"
                                  name="insuranceothername"
                                  ref={register({ required: userData?.accessEnabled?.pms_integration === true ? false : true })}
                                />
                                {errors &&
                                  errors.insuranceothername &&
                                  errors.insuranceothername.type ===
                                  "required" && (
                                    <div className="is-invalid">Required</div>
                                  )}
                              </>
                            )}
                          </div>
                          <div className="col-6">
                            <label className="form-label">Insurance plan</label>
                            <select
                              className="form-select"
                              name="insuranceplanname"
                              disabled={userData?.accessEnabled?.pms_integration === true}
                              ref={register}
                            >
                              {masterInsurancePlanList.map((item: any) => {
                                return (
                                  <option
                                    value={item.id}
                                    key={item.id + "plan"}
                                  >
                                    {item.name}
                                  </option>
                                );
                              })}
                            </select>
                            {watch("insuranceplanname") === "other" && (
                              <>
                                <input
                                  type="text"
                                  className="form-control"
                                  disabled={userData?.accessEnabled?.pms_integration === true}
                                  placeholder="Insurance plan name"
                                  name="insuranceplanothername"
                                  ref={register({ required: userData?.accessEnabled?.pms_integration === true ? false : true })}
                                />
                                {errors &&
                                  errors.insuranceplanothername &&
                                  errors.insuranceplanothername.type ===
                                  "required" && (
                                    <div className="is-invalid">Required</div>
                                  )}
                              </>
                            )}
                          </div>
                          <div className="col-12">
                            <label className="form-label">
                              Automated reminder
                            </label>
                            <div className="d-flex align-items-center">
                              <div className="form-check me-3">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  name="automatedreminder"
                                  id="a-yes"
                                  value="yes"
                                  ref={register({ required: true })}
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="a-yes"
                                >
                                  Yes
                                </label>
                              </div>
                              <div className="form-check me-3">
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  name="automatedreminder"
                                  id="a-no"
                                  value="no"
                                  ref={register({ required: true })}
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="a-no"
                                >
                                  No
                                </label>
                              </div>
                            </div>
                            {errors &&
                              errors.automatedreminder &&
                              errors.automatedreminder.type === "required" && (
                                <div className="is-invalid">
                                  Automated reminder is required
                                </div>
                              )}
                          </div>
                          {watch("automatedreminder") === "yes" && (
                            <div className="col-12">
                              <label className="form-label">
                                Preferred contact method?
                              </label>
                              <div className="d-flex align-items-center">
                                <div className="form-check me-3">
                                  <input
                                    className="form-check-input"
                                    type="radio"
                                    name="pcontactmethod"
                                    id="email-only"
                                    value={"email"}
                                    ref={register({ required: true })}
                                  />
                                  <label
                                    className="form-check-label"
                                    htmlFor="email-only"
                                  >
                                    Email Only
                                  </label>
                                </div>
                                <div className="form-check me-3">
                                  <input
                                    className="form-check-input"
                                    type="radio"
                                    name="pcontactmethod"
                                    id="text-only"
                                    value={"text"}
                                    ref={register({ required: true })}
                                  />
                                  <label
                                    className="form-check-label"
                                    htmlFor="text-only"
                                  >
                                    Text Only
                                  </label>
                                </div>
                                <div className="form-check me-3">
                                  <input
                                    className="form-check-input"
                                    type="radio"
                                    name="pcontactmethod"
                                    id="et-both"
                                    value={"email,text"}
                                    ref={register({ required: true })}
                                  />
                                  <label
                                    className="form-check-label"
                                    htmlFor="et-both"
                                  >
                                    Email & Text
                                  </label>
                                </div>
                              </div>
                              {errors &&
                                errors.pcontactmethod &&
                                errors.pcontactmethod.type === "required" && (
                                  <div className="is-invalid">
                                    Preferred contact method is required
                                  </div>
                                )}
                            </div>
                          )}
                          {watch("automatedreminder") === "no" && (
                            <div className="is-invalid">
                              This member will not receive notification.
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                }
              </div>
            )}
            <GuarantorInfo
              selectedPatient={selectedPatient}
              masterInsuranceList={masterInsuranceList}
              clinicId={userData?.clinics[0]?.uniqueId}
              fetchDetail={() => {
                getDependantDetailFunc(
                  userData?.clinics[0]?.uniqueId,
                  match?.params?.id,
                  true,
                  selectedPatient?.id
                );
              }}
              disabled={userData?.accessEnabled?.pms_integration === true || true}
              userData={userData}
            ></GuarantorInfo>
            {/* recent appointment */}
            <div className="recent-appointment-sec">
              <div className="mb-3">
                <div className="fs-5 fw-medium">Recent appointment</div>
              </div>
              <div className="row">
                <div className="col-12">
                  <div className="advances-table">
                    <table className="table table-hover">
                      <thead>
                        <tr>
                          <th>Apt ID</th>
                          <th>Name</th>
                          <th>Apt. with doctor</th>
                          <th>Status</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {selectedPatient &&
                          selectedPatient.appointment.map((value: any) => {
                            let doctorName: any = "";
                            let letterOne: any = "";
                            let letterTwo: any = "";
                            if (value.doctor && value.doctor.fullname) {
                              doctorName = value.doctor.fullname.replace(
                                "Dr.",
                                ""
                              );
                              doctorName = doctorName.trim();
                              doctorName = doctorName.split(" ");
                              letterOne = doctorName[0]
                                ? doctorName[0].charAt(0)
                                : "";
                              letterTwo = doctorName[1]
                                ? doctorName[1].charAt(0)
                                : "";
                            }
                            let takeWritebackBool = false;
                            let apptScheduled = true;
                            let apptReSchedule = true;
                            let apptConfirmPatient = true;
                            let apptComplete = true;
                            let apptEdit = true;
                            let apptCancel = true;
                            let apptPMSStatus = true;
                            let tempWriteBack = false;
                            if (
                              userData &&
                              userData.clinics &&
                              userData.clinics[0] &&
                              userData.clinics[0].tempwriteBack === "on"
                            ) {
                              tempWriteBack = true;
                            }
                            if (
                              userData?.accessEnabled?.pms_integration === true
                            ) {
                              if (userData.clinics[0].writeBack === "on" &&
                                userData.clinics[0].appointmentwriteback === "on") {
                                apptReSchedule = false;
                              }
                              if (userData.clinics[0].writeBack === "off") {
                                apptScheduled = false;
                                // apptReSchedule = true; // enabled for special case of SIKKA status sync issue
                                apptConfirmPatient = false;
                                apptComplete = true; // enabled for special case of SIKKA status sync issue
                                apptEdit = true; // enabled for special case of SIKKA status sync issue
                                apptCancel = true; //enabled for special case of SIKKA status sync issue
                                apptPMSStatus = false;
                              } else if (
                                userData.clinics[0].writeBack === "on" &&
                                userData.clinics[0].patientwriteback ===
                                "off" &&
                                userData.clinics[0].appointmentwriteback ===
                                "off" &&
                                userData.clinics[0].statuswriteback === "off"
                              ) {
                                apptScheduled = false;
                                // apptReSchedule = false;
                                apptConfirmPatient = false;
                                apptComplete = false;
                                apptEdit = false;
                                apptCancel = false;
                                apptPMSStatus = false;
                              } else if (
                                userData.clinics[0].writeBack === "on" &&
                                userData.clinics[0].patientwriteback === "on" &&
                                userData.clinics[0].appointmentwriteback ===
                                "off" &&
                                userData.clinics[0].statuswriteback === "off"
                              ) {
                                apptScheduled = false;
                                // apptReSchedule = false;
                                apptConfirmPatient = false;
                                apptComplete = false;
                                apptEdit = false;
                                apptCancel = false;
                                apptPMSStatus = false;
                              } else if (
                                userData.clinics[0].writeBack === "on" &&
                                userData.clinics[0].patientwriteback === "on" &&
                                userData.clinics[0].appointmentwriteback ===
                                "off" &&
                                userData.clinics[0].statuswriteback === "on"
                              ) {
                                apptScheduled = false;
                                // apptReSchedule = false;
                                apptEdit = false;
                              } else if (
                                userData.clinics[0].writeBack === "on" &&
                                userData.clinics[0].patientwriteback ===
                                "off" &&
                                userData.clinics[0].appointmentwriteback ===
                                "off" &&
                                userData.clinics[0].statuswriteback === "on"
                              ) {
                                apptScheduled = false;
                                // apptReSchedule = false;
                                apptEdit = false;
                              } else if (
                                userData.clinics[0].writeBack === "on" &&
                                userData.clinics[0].patientwriteback ===
                                "off" &&
                                userData.clinics[0].appointmentwriteback ===
                                "off" &&
                                userData.clinics[0].statuswriteback === "off"
                              ) {
                                apptScheduled = false;
                                // apptReSchedule = false;
                                apptConfirmPatient = false;
                                apptComplete = false;
                                apptEdit = false;
                                apptCancel = false;
                                apptPMSStatus = false;
                              }
                            }
                            if (
                              userData.clinics[0] &&
                              userData?.accessEnabled?.pms_integration ===
                              true &&
                              userData.clinics[0].writeBack === "on" &&
                              value.metadata &&
                              value.metadata.pms &&
                              value.metadata.pms.writeBackStatus &&
                              value.metadata.pms.writeBackStatus === "Success"
                            ) {
                              takeWritebackBool = true;
                            } else if (
                              userData.clinics[0] &&
                              userData?.accessEnabled?.pms_integration ===
                              true &&
                              userData.clinics[0].writeBack === "off"
                            ) {
                              takeWritebackBool = true; //enabled for special case sikka sync issue.
                            } else if (
                              userData.clinics[0] &&
                              userData?.accessEnabled?.pms_integration ===
                              false &&
                              userData.clinics[0].writeBack === "off" &&
                              (!(
                                value.metadata &&
                                value.metadata.pms &&
                                value.metadata.pms.writeBackStatus
                              ) ||
                                (value.metadata &&
                                  value.metadata.pms &&
                                  value.metadata.pms.writeBackStatus ===
                                  "Success"))
                            ) {
                              takeWritebackBool = true;
                            }
                            const getBadge = () => {
                              const data = value;
                              let badges = [];
                              if (data?.metadata?.appointment?.floating) {
                                badges.push(<span className="badge ms-2 rounded-pill bg-warning">Floating</span>)
                              } else {
                                if (!data?.metadata?.appointment?.prescreening?.attempt &&
                                  !data?.metadata?.appointment?.confirmation?.attempt && data.currentStatus === "scheduled") {
                                  badges.push(<span className="badge ms-2 rounded-pill bg-secondary">Scheduled</span>);
                                }
                                if (data?.metadata?.appointment?.checkin && data?.metadata?.appointment?.checkin.attempt === true) {
                                  badges.push(<span className="badge ms-2 rounded-pill bg-success">Checked-in</span>)
                                } else {
                                  if (data?.currentStatus === "scheduled" && data?.metadata?.appointment?.confirmation?.attempt &&
                                    data?.metadata?.appointment?.confirmation?.status === "confirmed") {
                                    badges.push(<span className="badge ms-2 rounded-pill bg-darkgreen">Confirmed</span>)
                                  }
                                }
                              }
                              return badges;
                            }
                            return (
                              <tr className={"table-row have-msg"} key={value.uniqueid + "appt"}>
                                <td>{value.uniqueid && value.uniqueid}</td>
                                <td>
                                  {value?.dependant?.firstName +
                                    " " +
                                    value?.dependant?.lastName}
                                </td>
                                <td>
                                  <div className="d-flex p-0 align-items-center">
                                    <div className="avatar flex-shrink-0 border rounded-circle text-uppercase me-2">
                                      {value.doctor &&
                                        value.doctor.photo &&
                                        value.doctor.photo.name ? (
                                        <img
                                          src={
                                            configs.IMAGE_URL +
                                            "doctor/" +
                                            value.doctor.photo.name +
                                            "-200x200." +
                                            value.doctor.photo.type
                                          }
                                          alt="doctor image"
                                        />
                                      ) : (
                                        <>
                                          {letterOne}
                                          {letterTwo}
                                        </>
                                      )}
                                    </div>
                                    <div>
                                      <span className="d-block fw-medium">
                                        {value.doctor &&
                                          value.doctor.fullname &&
                                          value.doctor.fullname}
                                      </span>
                                    </div>
                                  </div>
                                </td>
                                <td>
                                  {value.currentStatus === "waitlist" && (
                                    <>
                                      {value && value.date && value.time && (
                                        <>
                                          <span className="d-block fw-medium p-0">
                                            Waitlist
                                          </span>
                                          <small className="d-block p-0">
                                            Apt. date: {moment(
                                              value.date + " " + value.time,
                                              "YYYY-MM-DD LT"
                                            ).format("MMM DD, YYYY - LT")}
                                          </small>
                                        </>
                                      )}
                                    </>
                                  )}
                                  {value.currentStatus === "requested" && (
                                    <>
                                      {value && value.date && value.time && (
                                        <>
                                          <span className="d-block fw-medium p-0">
                                            Waitlist
                                          </span>
                                          <small className="d-block p-0">
                                            Apt. date: {moment(
                                              value.date + " " + value.time,
                                              "YYYY-MM-DD LT"
                                            ).format("MMM DD, YYYY - LT")}
                                          </small>
                                        </>
                                      )}
                                    </>
                                  )}
                                  {value.currentStatus === "scheduled" &&
                                    (!value.appointmentStatus ||
                                      (value.appointmentStatus &&
                                        value.appointmentStatus ===
                                        "success")) && (
                                      <>
                                        {value && value.date && value.time && (
                                          <>
                                            <span className="d-block fw-medium p-0">
                                              {value.metadata &&
                                                value.metadata.appointment &&
                                                value.metadata.appointment
                                                  .confirmation &&
                                                value.metadata.appointment
                                                  .confirmation.attempt ===
                                                true &&
                                                value.metadata.appointment
                                                  .confirmation.status ===
                                                "confirmed" && <>Upcoming</>}
                                              {value.metadata &&
                                                value.metadata.appointment &&
                                                value.metadata.appointment
                                                  .confirmation &&
                                                value.metadata.appointment
                                                  .confirmation.attempt ===
                                                true &&
                                                value.metadata.appointment
                                                  .confirmation.status ===
                                                "cancelled" && (
                                                  <>Cancel Request</>
                                                )}
                                              {value.metadata &&
                                                value.metadata.appointment &&
                                                (!value.metadata.appointment
                                                  .confirmation ||
                                                  (value.metadata.appointment
                                                    .confirmation &&
                                                    value.metadata.appointment
                                                      .confirmation.attempt ===
                                                    false)) && <>Upcoming</>}
                                            </span>
                                            <small className="d-block p-0">
                                              Apt. date: {moment(
                                                value.date + " " + value.time,
                                                "YYYY-MM-DD LT"
                                              ).format("MMM DD, YYYY - LT")}
                                            </small>
                                            {apptReSchedule &&
                                              !(
                                                value.metadata &&
                                                value.metadata.appointment &&
                                                value.metadata.appointment
                                                  .prescreening &&
                                                value.metadata.appointment
                                                  .prescreening.attempt
                                              ) &&
                                              !(
                                                value.metadata &&
                                                value.metadata.appointment &&
                                                value.metadata.appointment
                                                  .prescreening &&
                                                value.metadata.appointment
                                                  .prescreening.verifiedOn
                                              ) && (
                                                <button
                                                  className="btn p-0 btn-link"
                                                  type="button"
                                                  onClick={() => {
                                                    handleScheduleAppointmentClick(
                                                      value
                                                    );
                                                  }}
                                                >
                                                  Reschedule
                                                </button>
                                              )}
                                          </>
                                        )}
                                      </>
                                    )}
                                  {value.currentStatus === "cancelled" && (
                                    <>
                                      {value.metadata &&
                                        value.metadata.appointment &&
                                        value.metadata.appointment
                                          .cancellation &&
                                        value.metadata.appointment.cancellation
                                          .cancelledOn ? (
                                        <>
                                          <span className="d-block fw-medium p-0">
                                            Cancelled
                                          </span>
                                          <small className="d-block p-0">
                                            Apt. date:{" "}
                                            {moment(
                                              value.date + " " + value.time,
                                              "YYYY-MM-DD LT"
                                            ).format("MMM DD, YYYY - LT")}
                                          </small>
                                          <small className="d-block p-0">
                                            Received on:{" "}
                                            {moment(
                                              value.metadata.appointment
                                                .cancellation.cancelledOn,
                                              "YYYY-MM-DD HH:mm:ss ZZ"
                                            ).format("MMM DD, YYYY - LT")}
                                          </small>
                                        </>
                                      ) : (
                                        <>
                                          <span className="d-block fw-medium p-0">
                                            Cancelled
                                          </span>
                                          <small className="d-block p-0">
                                            Apt. date:{" "}
                                            {moment(
                                              value.date + " " + value.time,
                                              "YYYY-MM-DD LT"
                                            ).format("MMM DD, YYYY - LT")}
                                          </small>
                                        </>
                                      )}
                                    </>
                                  )}
                                  {value.currentStatus === "completed" && (
                                    <>
                                      {value.metadata &&
                                        value.metadata.appointment &&
                                        value.metadata.appointment
                                          .verifiedOn ? (
                                        <>
                                          <span className="d-block fw-medium p-0">
                                            Completed
                                          </span>
                                          <small className="d-block p-0">
                                            Apt. date:
                                            {moment(
                                              value.date + " " + value.time,
                                              "YYYY-MM-DD LT"
                                            ).format("MMM DD, YYYY - LT")}
                                          </small>
                                          <small className="d-block p-0">
                                            Received on: {moment(
                                              value.metadata.appointment
                                                .verifiedOn,
                                              "YYYY-MM-DD HH:mm:ss ZZ"
                                            ).format("MMM DD, YYYY - LT")}
                                          </small>
                                          {userData &&
                                            userData.accessEnabled &&
                                            userData.accessEnabled
                                              .contactless_payments ? (
                                            value.billingdata &&
                                              value.billingdata
                                                .clinicToPatientBilling &&
                                              value.billingdata
                                                .clinicToPatientBilling
                                                .status === "paid" ? (
                                              <span className="badge rounded-pill bg-success">
                                                Paid
                                              </span>
                                            ) : (
                                              <>
                                                {
                                                  userData.clinics[0]?.payment_feature ?
                                                    <span className="badge rounded-pill bg-danger">
                                                      Invoice Pending
                                                    </span> : null}
                                              </>
                                            )
                                          ) : null}
                                        </>
                                      ) : (
                                        <span className="d-block fw-medium p-0">
                                          Completed (
                                          {moment(
                                            value.date + " " + value.time,
                                            "YYYY-MM-DD LT"
                                          ).format("MMM DD, YYYY - LT")}
                                          )
                                        </span>
                                      )}
                                    </>
                                  )}
                                </td>
                                <td>
                                  {(value.currentStatus === "waitlist" || value.currentStatus === "requested" ||
                                    value.currentStatus === "scheduled" ||
                                    (value.currentStatus === "completed" &&
                                      !(
                                        value.billingdata &&
                                        value.billingdata
                                          .clinicToPatientBilling &&
                                        value.billingdata.clinicToPatientBilling
                                          .status === "paid"
                                      ))) &&
                                    (value.patient.isSignInUser === true || value.patient.phone) && (
                                      <OverlayTrigger
                                        placement="auto"
                                        overlay={
                                          <Tooltip id="tooltip">
                                            Quick chat
                                          </Tooltip>
                                        }
                                      >
                                        <button
                                          className="btn mx-1 p-1"
                                          type="button"
                                          onClick={(e) => {
                                            dispatch(
                                              storeChatData({
                                                ...value,
                                                fetched: false,
                                                pinType: "chat",
                                                openPin: true,
                                                appointmentId: value.uniqueid,
                                                sendToCId:
                                                  value.clinic.uniqueid,
                                              })
                                            );
                                          }}
                                        >
                                          <i className="bi bi-chat-right-text"></i>
                                        </button>
                                      </OverlayTrigger>
                                    )}
                                  {(value.currentStatus === "waitlist" || value.currentStatus === "requested" ||
                                    value.currentStatus === "scheduled") &&
                                    !value.dependant.phone && !value.patient.phone && (
                                      <OverlayTrigger
                                        placement="auto"
                                        overlay={
                                          <Tooltip id="tooltip">
                                            Manual followup
                                          </Tooltip>
                                        }
                                      >
                                        <button
                                          className="btn mx-1 p-1"
                                          type="button"
                                          onClick={(e) => { }}
                                        >
                                          <i className="bi bi-telephone"></i>
                                        </button>
                                      </OverlayTrigger>
                                    )}
                                  {
                                    (value.patient && value.patient.isSignInUser === true &&
                                      userData.accessEnabled?.digitaldoc === true && value.currentStatus !== "cancelled") &&
                                    <OverlayTrigger placement="auto" overlay={(props: any) => {
                                      return (
                                        <Tooltip id="button-tooltip" {...props}>Digital forms</Tooltip>
                                      )
                                    }}>
                                      <button onClick={() => {
                                        handleDigitalDocClick(value);
                                      }} className="btn mx-1 p-1">
                                        <i className="bi bi-file-medical"></i>
                                      </button>
                                    </OverlayTrigger>
                                  }
                                  {
                                    (value.currentStatus === "scheduled" && !userData?.accessEnabled?.pms_integration) &&
                                    <OverlayTrigger placement="auto" overlay={(props: any) => {
                                      return (
                                        <Tooltip id="button-tooltip" {...props}>Documents</Tooltip>
                                      )
                                    }}>
                                      <button onClick={() => {
                                        handleDocumentsClick(value);
                                      }} className="btn mx-1 p-1">
                                        <i className="bi bi-file-earmark"></i>
                                      </button>
                                    </OverlayTrigger>
                                  }
                                  <div className="inline-badges">
                                    {getBadge()}
                                  </div>
                                </td>
                              </tr>
                            );
                          })}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
        {addMemberShow && (
          <AddMemberModal
            hideModal={() => {
              setAddMemberShow(false);
            }}
            createMember={createMemberFunc}
          ></AddMemberModal>
        )}
        {(digitalDocShow && currentActiveModalAppointment) &&
          <DigitalDoc
            appointment={currentActiveModalAppointment}
            hideModal={() => {
              setShowDigitalDocShow(false);
            }}
            fetchAppointment={fecthAppointmentListAsync}
          ></DigitalDoc>
        }
        {
          showDocumentsModal &&
          <DocumentUpload
            data={currentActiveModalAppointment}
            type="appointment"
            id={currentActiveModalAppointment?.uniqueid}
            hideModal={() => {
              setShowDocumentsModal(false);
            }}
          />
        }
        {
          showDocumentsModalPatient &&
          <DocumentUpload
            data={selectedPatient}
            type="patient"
            id={selectedPatient?.id}
            hideModal={() => {
              setShowDocumentsModalPatient(false);
            }}
          />
        }
      </section>
    </>
  );
};
const GuarantorInfo = ({
  selectedPatient,
  masterInsuranceList,
  clinicId,
  fetchDetail,
  disabled,
  userData
}: any) => {
  const [isEdit, setIsEdit] = useState(false);
  const [isEditLoading, setIsEditLoading] = useState(false);
  const [guarantor, setGuarantor]: any = useState(null);
  const [xguarantor, setXGuarantor]: any = useState(null);
  const [gPlanList, setGPlanList]: any = useState([]);
  const [xPlanList, setXPlanList]: any = useState([]);
  const [showGuarantorModal, setShowGuarantorModal] = useState(false);
  const {
    register,
    setValue,
    errors,
    getValues,
    handleSubmit,
    control,
    watch,
  } = useForm({
    defaultValues: {
      gEmail: "",
      gPhone: "",
      gFirstName: "",
      gLastName: "",
      gDob: "",
      gGender: "",
      gInsurance: "",
      gInsuranceOtherName: "",
      gInsurancePlanName: "",
      gInsuranceOtherPlanName: "",
      xEmail: "",
      xPhone: "",
      xFirstName: "",
      xLastName: "",
      xDob: "",
      xGender: "",
      xInsurance: "",
      xInsuranceOtherName: "",
      xInsurancePlanName: "",
      xInsuranceOtherPlanName: "",
    },
  });
  useEffect(() => {
    setIsEdit(false);
    if (selectedPatient) {
      setGuarantor(selectedPatient.guarantor);
      setXGuarantor(selectedPatient.xguarantor);
    }
  }, [selectedPatient]);
  useEffect(() => {
    if (getValues("gInsurance") && getValues("gInsurance") !== "") {
      let insurance: any = masterInsuranceList.find(
        (item: any) => getValues("gInsurance") === item.id
      );
      if (insurance && insurance.plan) {
        if (insurance.id === "other") {
          insurance = {
            ...insurance,
            plan: [{ id: "other", name: "Other" }],
          };
        } else {
          insurance = {
            ...insurance,
            plan: [...insurance.plan, { id: "other", name: "Other" }],
          };
        }
        setGPlanList(insurance.plan);
        if (insurance.plan[0]) {
          setValue("gInsurancePlanName", insurance.plan[0].id);
        }
      }
    }
  }, [watch("gInsurance")]);
  useEffect(() => {
    if (getValues("xInsurance") && getValues("xInsurance") !== "") {
      let insurance: any = masterInsuranceList.find(
        (item: any) => getValues("xInsurance") === item.id
      );
      if (insurance && insurance.plan) {
        if (insurance.id === "other") {
          insurance = {
            ...insurance,
            plan: [{ id: "other", name: "Other" }],
          };
        } else {
          insurance = {
            ...insurance,
            plan: [...insurance.plan, { id: "other", name: "Other" }],
          };
        }
        setXPlanList(insurance.plan);
        if (insurance.plan[0]) {
          setValue("xInsurancePlanName", insurance.plan[0].id);
        }
      }
    }
  }, [watch("xInsurance")]);
  let guarantorInsurance: any = null;
  let guarantorInsurancePlan: any = null;
  if (masterInsuranceList && guarantor) {
    guarantorInsurance = masterInsuranceList.find(
      (item: any) => item.id === guarantor.insuranceId
    );
  }
  if (gPlanList && guarantor && guarantorInsurance) {
    guarantorInsurancePlan = guarantorInsurance.plan.find(
      (item: any) => item.id == guarantor.insurancePlan
    );
  }
  let xguarantorInsurance: any = null;
  let xguarantorInsurancePlan: any = null;
  if (masterInsuranceList && xguarantor) {
    xguarantorInsurance = masterInsuranceList.find(
      (item: any) => item.id === xguarantor.insuranceId
    );
  }
  if (xPlanList && xguarantor && xguarantorInsurance) {
    xguarantorInsurancePlan = xguarantorInsurance.plan.find(
      (item: any) => item.id == xguarantor.insurancePlan
    );
  }
  const editInfo = () => {
    setIsEdit(true);
    if (guarantor) {
      setValue("gEmail", guarantor.email);
      setValue("gPhone", guarantor.phone);
      setValue("gFirstName", guarantor.firstName);
      setValue("gLastName", guarantor.lastName);
      setValue("gDob", guarantor.dob);
      setValue("gGender", guarantor.gender);
      setValue("gInsurance", guarantor.insuranceId);
      setValue("gInsurancePlanName", guarantor.insurancePlan);
    }
    if (xguarantor) {
      setValue("xEmail", xguarantor.email);
      setValue("xPhone", xguarantor.phone);
      setValue("xFirstName", xguarantor.firstName);
      setValue("xLastName", xguarantor.lastName);
      setValue("xDob", xguarantor.dob);
      setValue("xGender", xguarantor.gender);
      setValue("xInsurance", xguarantor.insuranceId);
      setValue("xInsurancePlanName", xguarantor.insurancePlan);
    }
  };
  const onEditClick = (data: any) => {
    const request = {};
    if (guarantor) {
      let insurance: any = masterInsuranceList.find(
        (item: any) => item.id === getValues("gInsurance")
      );
      let insurancePlan: any = gPlanList.find(
        (item: any) => item.id == getValues("gInsurancePlanName")
      );
      Object.assign(request, {
        guarantor: {
          id: guarantor.id,
          fname: getValues("gFirstName"),
          lname: getValues("gLastName"),
          dob: moment(getValues("gDob")).format("YYYY-MM-DD"),
          gender: getValues("gGender"),
          insurance: {
            id: insurance.id,
            name:
              getValues("gInsurance") === "other"
                ? getValues("gInsuranceOtherPlanName")
                : insurance.name,
            plan: insurancePlan.id,
            planName:
              getValues("gInsurancePlanName") === "other"
                ? getValues("gInsuranceOtherPlanName")
                : insurancePlan.name,
          },
        },
      });
    }
    if (xguarantor) {
      let insurance: any = masterInsuranceList.find(
        (item: any) => item.id === getValues("xInsurance")
      );
      let insurancePlan: any = xPlanList.find(
        (item: any) => item.id == getValues("xInsurancePlanName")
      );
      Object.assign(request, {
        xguarantor: {
          id: xguarantor.id,
          fname: getValues("xFirstName"),
          lname: getValues("xLastName"),
          dob: moment(getValues("xDob")).format("YYYY-MM-DD"),
          gender: getValues("xGender"),
          insurance: {
            id: insurance.id,
            name:
              getValues("xInsurance") === "other"
                ? getValues("xInsuranceOtherPlanName")
                : insurance.name,
            plan: insurancePlan.id,
            planName:
              getValues("xInsurancePlanName") === "other"
                ? getValues("xInsuranceOtherPlanName")
                : insurancePlan.name,
          },
        },
      });
    }
    setIsEditLoading(true);
    guarantorEdit(request, clinicId)
      .then((success) => {
        if (success && success.status && success.status.error === false) {
          fetchDetail();
          setIsEditLoading(false);
          setIsEdit(false);
        }
      })
      .catch((err) => {
        setIsEditLoading(false);
        toast.error(err.msg);
      });
  };
  return (
    <div>
      <div className="d-flex align-items-right mb-4 align-items-center">
        <div className="flex-grow-1">
          <div className="fs-5 fw-medium">Guarantor info</div>
        </div>
        {isEditLoading && isEdit && (
          <span
            className="spinner-border spinner-border-sm text-primary"
            role="status"
            aria-hidden="true"
          ></span>
        )}
        {!isEditLoading && isEdit && (
          <>
            <button
              className="btn p-0 btn-link me-2 text-secondary"
              onClick={(e) => {
                setIsEdit(false);
              }}
            >
              <i className="bi bi-x-circle-fill text-danger"></i>
            </button>
            <button
              className="btn p-0 btn-link"
              onClick={handleSubmit(onEditClick)}
            >
              <i className="bi bi-check-circle-fill text-success"></i>
            </button>
          </>
        )}
        {
          !userData?.accessEnabled?.pms_integration &&
          <button
            type="button"
            className="btn btn-outline-primary me-2"
            onClick={() => {
              setShowGuarantorModal(true);
            }}
          >
            Change guarantor
          </button>
        }
        {!isEdit && (
          <button
            type="button"
            className="btn btn-outline-primary me-2"
            onClick={(e) => {
              editInfo();
            }}
          >
            Edit
          </button>
        )}
      </div>
      {guarantor && (
        <div className="card mb-5">
          <div className="card-body px-4">
            <div className="fs-6 fw-medium mb-4">Primary guarantor info</div>
            <>
              <div className={"d-flex flex-wrap " + (!isEdit ? "" : "d-none")}>
                <div className="me-5 mb-4">
                  <div className="text-muted">First Name</div>
                  <div className="fw-medium">
                    {guarantor.firstName ? guarantor.firstName : "-"}
                  </div>
                </div>
                <div className="me-5 mb-4">
                  <div className="text-muted">Last Name</div>
                  <div className="fw-medium">
                    {guarantor.lastName ? guarantor.lastName : "-"}
                  </div>
                </div>
                <div className="me-5 mb-4">
                  <div className="text-muted">Communication email</div>
                  <div className="fw-medium">
                    {guarantor.email ? guarantor.email : "-"}
                  </div>
                </div>
                <div className="me-5 mb-4">
                  <div className="text-muted">Mobile Number</div>
                  <div className="fw-medium">
                    {guarantor.phone
                      ? CONSTANT_LABELS.PLUS_ONE +
                      removeCountryCode(guarantor.phone)
                      : "-"}
                  </div>
                </div>
                <div className="me-5 mb-4">
                  <div className="text-muted">Date of birth</div>
                  <div className="fw-medium">
                    {guarantor.dob &&
                      moment(guarantor.dob).format("DD MMMM YYYY") !==
                      "Invalid date"
                      ? moment(guarantor.dob).format("MMM DD, YYYY - LT")
                      : "-"}
                  </div>
                </div>
                <div className="me-5 mb-4">
                  <div className="text-muted">Gender</div>
                  <div className="fw-medium">
                    {guarantor.gender ? guarantor.gender : "-"}
                  </div>
                </div>
                {guarantor.insuranceId &&
                  masterInsuranceList.length > 0 &&
                  guarantorInsurance && (
                    <div className="me-5 mb-4">
                      <div className="text-muted">Insurance name</div>
                      <div className="fw-medium">{guarantorInsurance.name}</div>
                    </div>
                  )}
                {!guarantor.insuranceId && (
                  <div className="me-5 mb-4">
                    <div className="text-muted">Insurance name</div>
                    <div className="fw-medium">-</div>
                  </div>
                )}
                {guarantor.insurancePlan && guarantorInsurancePlan && (
                  <div className="me-5 mb-4">
                    <div className="text-muted">Insurance plan</div>
                    <div className="fw-medium text-capitalize">
                      {guarantorInsurancePlan.name}
                    </div>
                  </div>
                )}
                {(!guarantor.insurancePlan || !guarantorInsurancePlan) && (
                  <div className="me-5 mb-4">
                    <div className="text-muted">Insurance plan</div>
                    <div className="fw-medium text-capitalize">-</div>
                  </div>
                )}
              </div>
              <div className={" " + (isEdit ? "" : "d-none")}>
                <div className="row g-3">
                  <div className="col-3">
                    <label className="form-label">First name</label>
                    <input
                      type="text"
                      name="gFirstName"
                      className="form-control"
                      placeholder="First name"
                      disabled={disabled}
                      ref={register({ required: true })}
                    />
                    {errors &&
                      errors.gFirstName &&
                      errors.gFirstName.type === "required" && (
                        <div className="is-invalid">First name is required</div>
                      )}
                  </div>
                  <div className="col-3">
                    <label className="form-label">Last name</label>
                    <input
                      type="text"
                      name="gLastName"
                      className="form-control"
                      placeholder="Last Name"
                      disabled={disabled}
                      ref={register({ required: true })}
                    />
                    {errors &&
                      errors.gLastName &&
                      errors.gLastName.type === "required" && (
                        <div className="is-invalid">Last name is required</div>
                      )}
                  </div>
                  <div className="col-3">
                    <label className="form-label">Mobile Number</label>
                    <Controller
                      as={ReactInputMask}
                      control={control}
                      mask="9999999999"
                      name="gPhone"
                      className="form-control"
                      placeholder="1234567890"
                      disabled={true}
                      rules={{
                        required: true,
                        minLength: {
                          value: 10,
                          message: "Mobile Number is invalid",
                        },
                      }}
                    />
                    {errors &&
                      errors.gPhone &&
                      errors.gPhone.type === "required" && (
                        <div className="is-invalid">
                          Mobile Number is required
                        </div>
                      )}
                  </div>
                  <div className="col-3">
                    <label className="form-label">Communication email</label>
                    <input
                      type="email"
                      name="gEmail"
                      disabled={true}
                      className="form-control"
                      placeholder="Email"
                      ref={register({ required: true })}
                    ></input>
                    {errors &&
                      errors.gEmail &&
                      errors.gEmail.type === "required" && (
                        <div className="is-invalid">Email is required</div>
                      )}
                  </div>
                  <div className="col-3 miuif_margin">
                    <label className="form-label">Date of birth</label>
                    <Controller
                      name="gDob"
                      control={control}
                      render={(props) => {
                        return (
                          <KeyboardDatePicker
                            margin="none"
                            id={"date-picker-dialog-dob"}
                            name={"date-picker-dialog-dob"}
                            format="YYYY/MM/DD"
                            maxDate={moment().subtract(1, "days")}
                            disabled={disabled}
                            value={props.value}
                            onChange={(date: any) => {
                              props.onChange(date);
                            }}
                            KeyboardButtonProps={{
                              "aria-label": "change date",
                            }}
                          />
                        );
                      }}
                    ></Controller>
                  </div>
                  <div className="col-3">
                    <label className="form-label">Gender</label>
                    <select
                      className="form-select"
                      name="gGender"
                      disabled={disabled}
                      ref={register({ required: true })}
                    >
                      <option value="Male">Male</option>
                      <option value="Female">Female</option>
                    </select>
                    {errors &&
                      errors.gGender &&
                      errors.gGender.type === "required" && (
                        <div className="is-invalid">Gender is required</div>
                      )}
                  </div>
                  <div className="col-3">
                    <label className="form-label">Insurance name</label>
                    <select
                      className="form-select"
                      name="gInsurance"
                      disabled={disabled}
                      ref={register}
                    >
                      {masterInsuranceList.map((item: any) => {
                        return (
                          <option value={item.id} key={item.id + "gInsurance"}>
                            {item.name}
                          </option>
                        );
                      })}
                    </select>
                    {watch("gInsurance") === "other" && (
                      <>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Insurance name"
                          name="gInsuranceOtherName"
                          disabled={disabled}
                          ref={register({ required: true })}
                        />
                        {errors &&
                          errors.gInsuranceOtherName &&
                          errors.gInsuranceOtherName.type === "required" && (
                            <div className="is-invalid">Required</div>
                          )}
                      </>
                    )}
                  </div>
                  <div className="col-3">
                    <label className="form-label">Insurance plan</label>
                    <select
                      className="form-select"
                      name="gInsurancePlanName"
                      disabled={disabled}
                      ref={register}
                    >
                      {gPlanList.map((item: any) => {
                        return (
                          <option value={item.id} key={item.id + "gPlan"}>
                            {item.name}
                          </option>
                        );
                      })}
                    </select>
                    {watch("gInsurancePlanName") === "other" && (
                      <>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Insurance name"
                          name="gInsuranceOtherPlanName"
                          disabled={disabled}
                          ref={register({ required: true })}
                        />
                        {errors &&
                          errors.gInsuranceOtherPlanName &&
                          errors.gInsuranceOtherPlanName.type ===
                          "required" && (
                            <div className="is-invalid">Required</div>
                          )}
                      </>
                    )}
                  </div>
                </div>
              </div>
            </>
          </div>
        </div>
      )}
      {xguarantor && (
        <div className="card mb-5">
          <div className="card-body px-4">
            <div className="fs-6 fw-medium mb-4">Secondary guarantor info</div>
            <>
              <div className={"d-flex flex-wrap " + (!isEdit ? "" : "d-none")}>
                <div className="me-5 mb-4">
                  <div className="text-muted">First Name</div>
                  <div className="fw-medium">
                    {xguarantor.firstName ? xguarantor.firstName : "-"}
                  </div>
                </div>
                <div className="me-5 mb-4">
                  <div className="text-muted">Last Name</div>
                  <div className="fw-medium">
                    {xguarantor.lastName ? xguarantor.lastName : "-"}
                  </div>
                </div>
                <div className="me-5 mb-4">
                  <div className="text-muted">Communication email</div>
                  <div className="fw-medium">
                    {xguarantor.email ? xguarantor.email : "-"}
                  </div>
                </div>
                <div className="me-5 mb-4">
                  <div className="text-muted">Mobile Number</div>
                  <div className="fw-medium">
                    {xguarantor.phone
                      ? CONSTANT_LABELS.PLUS_ONE +
                      removeCountryCode(xguarantor.phone)
                      : "-"}
                  </div>
                </div>
                <div className="me-5 mb-4">
                  <div className="text-muted">Date of birth</div>
                  <div className="fw-medium">
                    {xguarantor.dob &&
                      moment(xguarantor.dob).format("DD MMMM YYYY") !==
                      "Invalid date"
                      ? moment(xguarantor.dob).format("MMM DD, YYYY - LT")
                      : "-"}
                  </div>
                </div>
                <div className="me-5 mb-4">
                  <div className="text-muted">Gender</div>
                  <div className="fw-medium">
                    {xguarantor.gender ? xguarantor.gender : "-"}
                  </div>
                </div>
                {xguarantor.insuranceId &&
                  masterInsuranceList.length > 0 &&
                  xguarantorInsurance && (
                    <div className="me-5 mb-4">
                      <div className="text-muted">Insurance name</div>
                      <div className="fw-medium">
                        {xguarantorInsurance.name}
                      </div>
                    </div>
                  )}
                {!xguarantor.insuranceId && (
                  <div className="me-5 mb-4">
                    <div className="text-muted">Insurance name</div>
                    <div className="fw-medium">-</div>
                  </div>
                )}
                {xguarantor.insurancePlan && xguarantorInsurancePlan && (
                  <div className="me-5 mb-4">
                    <div className="text-muted">Insurance plan</div>
                    <div className="fw-medium text-capitalize">
                      {xguarantorInsurancePlan.name}
                    </div>
                  </div>
                )}
                {(!xguarantor.insurancePlan || !xguarantorInsurancePlan) && (
                  <div className="me-5 mb-4">
                    <div className="text-muted">Insurance plan</div>
                    <div className="fw-medium text-capitalize">-</div>
                  </div>
                )}
              </div>
              <div className={" " + (isEdit ? "" : "d-none")}>
                <div className="row g-3">
                  <div className="col-3">
                    <label className="form-label">First name</label>
                    <input
                      type="text"
                      name="xFirstName"
                      className="form-control"
                      placeholder="First name"
                      disabled={disabled}
                      ref={register({ required: true })}
                    />
                    {errors &&
                      errors.gFirstName &&
                      errors.gFirstName.type === "required" && (
                        <div className="is-invalid">First name is required</div>
                      )}
                  </div>
                  <div className="col-3">
                    <label className="form-label">Last name</label>
                    <input
                      type="text"
                      name="xLastName"
                      className="form-control"
                      placeholder="Last Name"
                      disabled={disabled}
                      ref={register({ required: true })}
                    />
                    {errors &&
                      errors.gLastName &&
                      errors.gLastName.type === "required" && (
                        <div className="is-invalid">Last name is required</div>
                      )}
                  </div>
                  <div className="col-3">
                    <label className="form-label">Mobile Number</label>
                    <Controller
                      as={ReactInputMask}
                      control={control}
                      mask="9999999999"
                      name="xPhone"
                      className="form-control"
                      placeholder="1234567890"
                      disabled={true}
                      rules={{
                        required: true,
                        minLength: {
                          value: 10,
                          message: "Mobile Number is invalid",
                        },
                      }}
                    />
                    {errors &&
                      errors.gPhone &&
                      errors.gPhone.type === "required" && (
                        <div className="is-invalid">
                          Mobile Number is required
                        </div>
                      )}
                  </div>
                  <div className="col-3">
                    <label className="form-label">Communication email</label>
                    <input
                      type="email"
                      name="xEmail"
                      disabled={true}
                      className="form-control"
                      placeholder="Email"
                      ref={register({ required: true })}
                    ></input>
                    {errors &&
                      errors.gEmail &&
                      errors.gEmail.type === "required" && (
                        <div className="is-invalid">Email is required</div>
                      )}
                  </div>
                  <div className="col-3 miuif_margin">
                    <label className="form-label">Date of birth</label>
                    <Controller
                      name="xDob"
                      control={control}
                      render={(props) => {
                        return (
                          <KeyboardDatePicker
                            margin="none"
                            id={"date-picker-dialog-dob"}
                            name={"date-picker-dialog-dob"}
                            format="YYYY/MM/DD"
                            maxDate={moment().subtract(1, "days")}
                            value={props.value}
                            disabled={disabled}
                            onChange={(date: any) => {
                              props.onChange(date);
                            }}
                            KeyboardButtonProps={{
                              "aria-label": "change date",
                            }}
                          />
                        );
                      }}
                    ></Controller>
                  </div>
                  <div className="col-3">
                    <label className="form-label">Gender</label>
                    <select
                      className="form-select"
                      name="xGender"
                      disabled={disabled}
                      ref={register({ required: true })}
                    >
                      <option value="Male">Male</option>
                      <option value="Female">Female</option>
                    </select>
                    {errors &&
                      errors.xGender &&
                      errors.xGender.type === "required" && (
                        <div className="is-invalid">Gender is required</div>
                      )}
                  </div>
                  <div className="col-3">
                    <label className="form-label">Insurance name</label>
                    <select
                      className="form-select"
                      name="xInsurance"
                      disabled={disabled}
                      ref={register}
                    >
                      {masterInsuranceList.map((item: any) => {
                        return (
                          <option value={item.id} key={"xInsurance" + item.id}>
                            {item.name}
                          </option>
                        );
                      })}
                    </select>
                    {watch("xInsurance") === "other" && (
                      <>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Insurance name"
                          name="xInsuranceOtherName"
                          disabled={disabled}
                          ref={register({ required: true })}
                        />
                        {errors &&
                          errors.xInsuranceOtherName &&
                          errors.xInsuranceOtherName.type === "required" && (
                            <div className="is-invalid">Required</div>
                          )}
                      </>
                    )}
                  </div>
                  <div className="col-3">
                    <label className="form-label">Insurance plan</label>
                    <select
                      className="form-select"
                      name="xInsurancePlanName"
                      disabled={disabled}
                      ref={register}
                    >
                      {xPlanList.map((item: any) => {
                        return (
                          <option value={item.id} key={"xPlan" + item.id}>
                            {item.name}
                          </option>
                        );
                      })}
                    </select>
                    {watch("xInsurancePlanName") === "other" && (
                      <>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Insurance name"
                          name="xInsuranceOtherPlanName"
                          disabled={disabled}
                          ref={register({ required: true })}
                        />
                        {errors &&
                          errors.xInsuranceOtherPlanName &&
                          errors.xInsuranceOtherPlanName.type ===
                          "required" && (
                            <div className="is-invalid">Required</div>
                          )}
                      </>
                    )}
                  </div>
                </div>
              </div>
            </>
          </div>
        </div>
      )}
      {
        showGuarantorModal &&
        <ChangeGuarantorInfoModal
          hideModal={(refetch: boolean) => {
            if (refetch) {
              fetchDetail();
            }
            setShowGuarantorModal(false);
          }}
          selectedPatient={selectedPatient}
          userData={userData}
        ></ChangeGuarantorInfoModal>
      }
    </div>
  );
};
