export enum LOGIN {
    LOAD_PENDING = 'LOAD_PENDING_LOGIN',
    LOAD_ERROR = 'LOAD_FAILED_LOGIN',
    LOAD_SUCCESS = 'LOAD_SUCCESS_LOGIN',
    LOGGED_OUT = 'USER_LOGGED_OUT'
};

export interface LoginRequest {
    email: string;
    password: string;
}
export interface Status {
    error: boolean;
    msg: string;
}
export interface Permission {
    user: string;
    uniqueId: string;
    modelType: string;
    modelId: string;
    modeluniqueId: string;
    permissionType: number;
}
export interface LoginResponse {
    id: string;
    uniqueId: string;
    firstName: string;
    lastName: string;
    email: string;
    emailVerified: string;
    userAccess: string;
    authParam: string;
    permissions: Permission[];
    status: Status;
    jwtToken: string;
}
export interface LoginState {
    pending: boolean;
    login: LoginRequest;
    loginResponse: LoginResponse
    error: any;
}

export interface FetchLoginPendingAction {
    type: LOGIN.LOAD_PENDING;
    payload: LoginRequest
}

export interface FetchLoginErrorAction {
    type: LOGIN.LOAD_ERROR;
    error: any;
}
export interface FetchLoginSuccessAction {
    type: LOGIN.LOAD_SUCCESS;
    login: LoginResponse
}

export type LoginActionTypes = FetchLoginErrorAction | FetchLoginPendingAction | FetchLoginSuccessAction;