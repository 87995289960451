import firebase from 'firebase';
import React, { useEffect, useState } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { CHAT } from '../../constants';
import { IAppState } from '../../store';
import configs from '../../v2/configs/apiConfigs';
import moment from 'moment';
export const NotificationTray = ({ notifications }: any) => {

    const markApptRead = (notification: any) => {
        try {
            const docRef = firebase.firestore().collection(configs.CHAT_COLLECTION).doc(notification.id);
            const changeObj = {
                "metadata.markReadByClinicAt": firebase.firestore.FieldValue.serverTimestamp(),
            };
            docRef.update(changeObj);
        } catch (err) {
            console.log(err);
        }
    }

    return (
        <div className="dropdown-menu pop-notification p-0">
            <div className="py-2 px-3 border-bottom d-flex justify-content-between align-items-center">
                <div>
                    <i className="bi bi-bell-fill"></i>
                    <span className="ms-1">Notification</span>
                </div>
                {/* <button className="btn">View All</button> */}
            </div>
            <div className="relative">
                <div className="overflow-auto relative" style={{ maxHeight: "300px", width: "460px" }}>

                    {
                        notifications.length === 0 && 'No notifications'
                    }

                    {
                        notifications.map((notification: any) => {
                            return (
                                <div className="dropdown-item d-block py-2 px-3 border-bottom" key={notification.id + "appt-notification"}>
                                    <div className="d-flex">
                                        <div className="avatar flex-shrink-0 avatar-blue avatar-icon">
                                            <i className="bi bi-envelope"></i>
                                        </div>
                                        <div className="ms-2 text-end">
                                            <p className="mb-0 text-break text-justify text-start">{notification.text}</p>
                                            <small className="mb-0 text-muted">received on {notification.time}</small>
                                        </div>
                                    </div>
                                    <div className="d-flex justify-content-end">
                                        <button className="btn p-0 btn-link" onClick={(e) => { markApptRead(notification); }}>Mark as read</button>
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>
            </div>
        </div>
    )
}