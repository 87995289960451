import firebase from 'firebase';
import React, { useEffect, useState } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { IAppState } from '../../../../store';
import configs from '../../../configs/apiConfigs';
import { getAppointmentDetail } from '../../../services/appointment';
import { AvatarComp } from '../Avatar';
import * as _ from 'lodash';
let groupListener: any;
let db = firebase.firestore();
let groupListTemp: any = [];
let userDocIdFirestore = '';
export const GroupList = (props: any) => {
    const { onClickGroup, selectedGroup } = props;
    const [groupList, setGroupList]: any = useState([]);
    const userData = useSelector((state: IAppState) => state.userData.userDataResponse, shallowEqual);
    useEffect(() => {
        getSetGroups();
    }, []);
    const getSetGroups = () => {
        const clinicUniqueId = userData.clinics[0].uniqueId;
        groupListener = db.collection(configs.USER_COLLECTION).where('uniqueId', '==', clinicUniqueId).get().then((snapshot) => {
            const user = snapshot.docs.map((item) => { return { id: item.id, ...item.data() } });
            const userDocId = user[0]?.id;
            if (userDocId) {
                userDocIdFirestore = userDocId;
                db.collection(configs.GROUP_COLLECTION).where('members', 'array-contains', userDocId).onSnapshot((snapshot) => {
                    snapshot.docChanges().forEach((change) => {
                        if (change.type === "added") {
                            const data = change.doc.data();
                            Object.assign(data, { groupId: change.doc.id });
                            console.log('---Data---', data, data.appointmentmentUniqueId);
                            if (data.appointmentUniqueId) {
                                getAppointmentDetail(clinicUniqueId, data.appointmentUniqueId).then((success) => {
                                    if (success && success.status && success.status.error === false) {
                                        Object.assign(data, success);
                                        setGroupList((groupList: any) => { return _.uniqBy([...groupList, data], 'appointmentUniqueId') });
                                        groupListTemp.push(data);
                                    }
                                }).catch((err) => {
                                    console.log(err);
                                });
                            }
                        }
                    });
                });
            }
        });
    }

    return (
        <>
            {
                groupList.map((item: any) => {
                    return (
                        <React.Fragment key={item.uniqueid}>
                            <GroupListListItem data={item} onClickGroup={onClickGroup} selectedGroup={selectedGroup}></GroupListListItem>
                        </React.Fragment>
                    )
                })
            }
        </>
    )
}

const GroupListListItem = (props: any) => {
    const { data, onClickGroup, selectedGroup } = props;
    const [count, setCount] = useState(0);
    useEffect(() => {
        getUnReadCount();
    }, []);
    const getUnReadCount = () => {
        db.collection(configs.MESSAGE_COLLECTION).doc(data.groupId).onSnapshot((snapshot) => {
            const docs: any = snapshot.data();
            console.log(docs.messages);
            let count = 0;
            if (docs && Array.isArray(docs.messages)) {
                for (let doc of docs?.messages) {
                    if (!doc.readBy.includes(userDocIdFirestore)) {
                        count = count + 1;
                    }
                }
            }
            setCount(count);
        })
    }
    return (
        <div id="userListBox">
            <ul className="list-group">
                <li className="border-bottom">
                    <a className={'list-group-item list-group-item-action border-0' + (data.groupId === selectedGroup?.groupId ? ' active' : '')}>
                        {
                            <>
                                <div className="d-flex align-items-center"
                                    onClick={(e) => {
                                        onClickGroup(data);
                                    }}
                                >
                                    <AvatarComp name={data.dependant.fname + " " + data.doctor.fullname}></AvatarComp>
                                    <div className="flex-grow-1">
                                        <div>{data.name}</div>
                                        {/* <div className="small chat-msg-inline">{data?.groupData?.recentMessage?.message}</div> */}
                                    </div>
                                    {
                                        (count !== 0) &&
                                        <div className="badge rounded-pill bg-primary">{count ? count : ''}</div>
                                    }
                                </div>
                            </>
                        }
                    </a>
                </li>
            </ul>
        </div>
    )
}