import React from 'react';
import { Redirect, Route, Switch } from 'react-router';
import { List } from './List';
import { ProfileRoute } from './ProfileRoute';
export const Provider = () => {
    return (
        <Switch>
            <Route path={'/provider/profile/:Id'} component={ProfileRoute}></Route>
            <Route path={'/provider/list'} component={List}></Route>
            <Redirect to="/provider/list"></Redirect>
        </Switch>
    )
}