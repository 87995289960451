import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { IAppState } from '../../store';
import { ClinicDropDown } from './Employee';
import moment from 'moment';
import { toast } from 'react-toastify';
import { employeePrescreeningPending, employeeListPending } from '../../store/employee/actions';
import { PrescreeningItem } from '../../store/employee/types';
import { isArray, initial } from 'lodash';
import { Modal, Alert } from 'react-bootstrap';
import { KeyboardDatePicker } from '@material-ui/pickers';
import CovidIcon from '../../assets/images/covid-icon.svg';
export const EmployeePrescreening = (props: any) => {
    const [state, setState] = useState({
        selectedClinic: '', prescreenStartDate: moment().toDate(), prescreenEndDate: moment().toDate(),
        showPrescreeningModal: false, selectedPrescreeningItem: {} as PrescreeningItem,
        selectedEmployee: '', initialCallDone: false, _isMount: false
    });
    const dispatch = useDispatch();
    const clinicListFromUserData = useSelector((state: IAppState) => state.userData.userDataResponse.clinics);
    const error = useSelector((state: IAppState) => state.employee.error);
    const employeePrescreeningResponse = useSelector((state: IAppState) => state.employee.employeePrescreeningResponse);
    const employeeListResponse = useSelector((state: IAppState) => state.employee.employeeListResponse, shallowEqual);
    useEffect(() => {
        if (clinicListFromUserData[0]) {
            setTimeout(() => {
                setState({
                    ...state, selectedClinic: clinicListFromUserData[0].uniqueId,
                    prescreenStartDate: moment().toDate(),
                    prescreenEndDate: moment().toDate()
                });
            }, 100);
            dispatch(employeePrescreeningPending({
                clinicUniqueId: clinicListFromUserData[0].uniqueId,
                start: moment().format("YYYY-MM-DD"), end: moment().format("YYYY-MM-DD"),
                workid: ''
            }));
        }
    }, [clinicListFromUserData]);
    useEffect(() => {
    }, [state.selectedClinic]);
    useEffect(() => {
        if (error) {
            toast.error(error);
        }
    }, [error]);
    const selectedClinicChange = (uniqueId: string) => {
        setState({
            ...state, selectedClinic: uniqueId
        });
        dispatch(employeeListPending({ clinicUniqueId: uniqueId }));
    }
    const onPrescreeningFetchSubmitClick = () => {
        dispatch(employeePrescreeningPending({
            clinicUniqueId: state.selectedClinic,
            start: moment(state.prescreenStartDate).format("YYYY-MM-DD"), end: moment(state.prescreenEndDate).format("YYYY-MM-DD"),
            workid: state.selectedEmployee
        }));
    }
    const onPrescreeingDateChange = (e: any, dateType: string) => {
        if (dateType === "start") {
            setState({ ...state, prescreenStartDate: e });
        } else if (dateType === "end") {
            setState({ ...state, prescreenEndDate: e });
        }
    }
    const clickShowPrescreeningModal = (value: PrescreeningItem) => {
        setState({ ...state, showPrescreeningModal: true, selectedPrescreeningItem: value });
    }
    const hideModal = () => {
        setState({ ...state, showPrescreeningModal: false, selectedPrescreeningItem: {} as any });
    }
    const selectEmployee = (workid: string) => {
        setState({ ...state, selectedEmployee: workid });
    }
    return (
        <>
            <div className="row">
                <div className="col-sm-12">

                    <div className="form-row emp-filt">

                        <div className="col-auto">
                            <label className="d-block">Select clinic</label>
                            <ClinicDropDown clinicList={clinicListFromUserData} onChange={selectedClinicChange}></ClinicDropDown>
                        </div>
                        <div className={"col-auto"}>
                            <label className="d-block">Select Employee</label>
                            <select className="custom-select m-r-15" style={{ width: "240px" }} value={state.selectedEmployee} onChange={(e) => {
                                selectEmployee(e.target.value);
                            }}>
                                <option value={''}>Select Employee</option>
                                {
                                    (employeeListResponse && isArray(employeeListResponse.employees) && employeeListResponse.employees.length > 0) &&
                                    employeeListResponse.employees.map((value, index: number) => {
                                        return (
                                            <option value={value.workid} key={index + Math.random()}>{value.fname + " " + value.lname}</option>
                                        )
                                    })
                                }
                            </select>
                        </div>
                        <div className="col-sm-2">
                            {/* <label>Start Date</label> */}
                            <KeyboardDatePicker
                                margin="normal"
                                variant="inline"
                                id="date-picker-dialog"
                                label="Start Date"
                                format="YYYY-MM-DD"
                                value={state.prescreenStartDate}
                                onChange={(e) => { onPrescreeingDateChange(e, "start") }}
                                KeyboardButtonProps={{
                                    'aria-label': 'change date',
                                }}
                            />
                            {/* <input className="form-control" type="date" name={"prescreenStartDate"} value={state.prescreenStartDate} onChange={(e) => { onPrescreeingDateChange(e, "start") }}></input> */}

                        </div>

                        <div className="col-sm-2">
                            {/* <label>End Date </label> */}
                            <KeyboardDatePicker
                                margin="normal"
                                variant="inline"
                                id="date-picker-dialog"
                                label="End Date"
                                format="YYYY-MM-DD"
                                minDate={moment(state.prescreenStartDate, 'YYYY-MM-DD').toDate()}
                                value={state.prescreenEndDate}
                                onChange={(e) => { onPrescreeingDateChange(e, "end") }}
                                KeyboardButtonProps={{
                                    'aria-label': 'change date',
                                }}
                            />
                            {/* <input className="form-control" type="date" name={"prescreenEndDate"} min={state.prescreenStartDate} value={state.prescreenEndDate} onChange={(e) => { onPrescreeingDateChange(e, "end") }}></input> */}

                        </div>

                        <div className="col-sm-2">
                            <button className="btn btn-primary ml-2" onClick={onPrescreeningFetchSubmitClick}
                                disabled={state.selectedClinic === ''
                                    || moment(moment(state.prescreenStartDate, 'YYYY-MM-DD').format('YYYY-MM-DD')).isAfter(moment(state.prescreenEndDate, 'YYYY-MM-DD').format('YYYY-MM-DD'))}>Submit</button>
                        </div>

                    </div>






                </div>

            </div>

            <div className="row">
                <div className="col-sm-12 m-t-20">
                    {
                        (employeePrescreeningResponse && isArray(employeePrescreeningResponse.list) &&
                            employeePrescreeningResponse.list.length > 0) &&
                        <div className="table-responsive">
                            <table className="table table-bordered m-0">
                                <thead>
                                    <tr>
                                        <th>First Name</th>
                                        <th>Last Name</th>
                                        <th>Email</th>
                                        <th>Date</th>
                                        <th>Prescreening</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        employeePrescreeningResponse.list.map((value, index) => {
                                            return (
                                                <tr key={index + Math.random()}>
                                                    <td>{value.fname}</td>
                                                    <td>{value.lname}</td>
                                                    <td>{value.email}</td>
                                                    <td>{value.date}</td>
                                                    <td>
                                                        <img src={CovidIcon} className="" onClick={(e) => { clickShowPrescreeningModal(value) }} />
                                                    </td>
                                                </tr>
                                            )
                                        })
                                    }
                                </tbody>
                            </table>
                        </div>


                    }
                    {
                        (employeePrescreeningResponse && isArray(employeePrescreeningResponse.list) &&
                            employeePrescreeningResponse.list.length === 0) &&
                        <label>No data available</label>
                    }
                    {
                        (state.showPrescreeningModal && state.selectedPrescreeningItem) &&
                        <PrescreeningModal hideModal={hideModal} prescreeninData={state.selectedPrescreeningItem}></PrescreeningModal>
                    }
                </div>
            </div>
        </>
    )
};

interface PreescreeningModalProps {
    hideModal: () => void;
    prescreeninData: PrescreeningItem;
}
function PrescreeningModal(props: PreescreeningModalProps) {
    return (
        <Modal show={true} onHide={props.hideModal} className="prespopup" centered>
            <Modal.Header closeButton>
                <Modal.Title>Pre-Screen Form</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {
                    (props.prescreeninData && props.prescreeninData.data) ?
                        <div className="covidPrescreen" id="covidPrescreen">
                            <p><b>Employee Name : </b>
                                {props.prescreeninData.fname + " " + props.prescreeninData.lname}
                            </p>
                            {
                                props.prescreeninData.data.map((value: any, index: number) => {
                                    if (value.options) {
                                        return (
                                            <p key={index + Math.random()}>{index + 1}. {value.question}  <span className={value.answer == "None" ? 'text-success' : 'text-danger'}> :  {value.answer}</span></p>
                                        );
                                    }
                                    if (props.prescreeninData.data.length - 1 === index) {
                                        return (
                                            <Alert key={index + Math.random()} variant={(value.answer === true || value.answer === "yes") ? 'success' : 'danger'}>
                                                <p>{value.question}</p>
                                                <p className="text-right">{(value.answer === true || value.answer === "yes") ? 'Yes' : 'No'}</p>
                                                <hr className="m-v-10 d-none" />
                                            </Alert>);
                                    }
                                    return (
                                        <Alert key={index + Math.random()} variant={(value.answer === true || value.answer === "yes") ? 'danger' : 'success'}>
                                            <p>{value.question}</p>
                                            <p className="text-right">{(value.answer === true || value.answer === "yes") ? 'Yes' : 'No'}</p>
                                            <hr className="m-v-10 d-none" />
                                        </Alert>
                                    )
                                })
                            }
                            <div className="clearfix"></div>

                        </div> :
                        <div>Fetching...</div>
                }
            </Modal.Body>
        </Modal>
    )
}