import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { CHAT } from '../../../constants';
import { APPOINTMENT_STATUS } from '../../../constants/status';
import firebaseInstance from '../../helpers/firebase';
import { getCookie } from '../../utils/cookies';
import * as firebase from 'firebase/app';
import 'firebase/firestore';
import configs from '../../configs/apiConfigs';
import { firestoreChangeNotificationToServer } from '../../services/appointment';
import moment from 'moment';
export const ViewPrescreeningModal = ({ hideModal, appointment, fetchAppointment }: any) => {
    const [prescreenData, setPrescreenData]: any = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [isVerifyStart, setIsVerifyStart] = useState(false);
    const [state, setState] = useState({ tempOnArrival: '', oxySatLevel: '', tempOnArrivalUpdatedAt: '', oxySatLevelUpdatedAt: '' });
    useEffect(() => {
        fectchPrescreenData();
    }, [appointment]);
    const fectchPrescreenData = async () => {
        try {
            if (appointment && appointment.metadata && appointment.metadata.appointment &&
                appointment.metadata.appointment.prescreening &&
                appointment.metadata.appointment.prescreening.attempt &&
                !appointment.metadata.appointment.prescreening.isPrescreenManual) {
                setIsLoading(true);
                const response = await firebaseInstance.getPrescreeningData(appointment.uniqueid);
                let prescreen = response.docs.map((item: any) => item.data());
                prescreen = prescreen.reverse();
                prescreen = prescreen[0];
                setPrescreenData(prescreen);
                setIsLoading(false);
                console.log(prescreen);
                let obj = {
                    tempOnArrival: prescreen.tempOnArrival,
                    oxySatLevel: prescreen.oxySatLevel,
                    tempOnArrivalUpdatedAt: '',
                    oxySatLevelUpdatedAt: ''
                }
                if (prescreen.tempOnArrivalUpdatedAt) {
                    Object.assign(obj, {
                        tempOnArrivalUpdatedAt: moment(moment.unix(prescreen.tempOnArrivalUpdatedAt.seconds)).format("DD MMM, YYYY - LT")
                    });
                }
                if (prescreen.oxySatLevelUpdatedAt) {
                    Object.assign(obj, {
                        oxySatLevelUpdatedAt: moment(moment(prescreen.oxySatLevelUpdatedAt.seconds)).format("DD MMM, YYYY - LT")
                    });
                }
                setState(obj);
            }
        } catch (err) {
            setIsLoading(false);
            toast.error('Prescreening data fetch failed.');
            console.log(err);
        }
    };
    const printCovidDiv = () => {
        var content = document.getElementById("covidPrescreen");
        //@ts-ignore
        var pri = document.getElementById("ifmcontentstoprint").contentWindow;
        pri.document.open();
        //@ts-ignore
        pri.document.write(content.innerHTML);
        pri.document.close();
        pri.focus();
        pri.print();
    };
    const setPrescreeningTempOnArrivalOrOxySatLevel = (appointmentId: number, value: string, type: string) => {
        setState({ ...state, [type]: value, [type + "UpdatedAt"]: moment().format('DD MMM, YYYY LT') } as any);
        firebaseInstance.db.collection(configs.CHAT_COLLECTION)
            .where("appointmentId", "==", Number(appointmentId))
            .where("actionType", "==", CHAT.ACTION_TYPE.PRESCREENING)
            .get().then((querySnapshot: any) => {
                querySnapshot.forEach((doc: any) => {
                    const docRef = firebaseInstance.db.collection(configs.CHAT_COLLECTION).doc(doc.id);
                    const changeObj = {
                        [type]: value,
                        [type + "UpdatedAt"]: firebase.firestore.FieldValue.serverTimestamp()
                    };
                    docRef.update(changeObj);
                });
            });
    }
    const handleVerifyPrescreening = async () => {
        setIsVerifyStart(true);
        if (appointment && prescreenData) {
            const obj = {
                "appointmentId": Number(appointment.uniqueid),
                "senderId": Number(getCookie().xpr_user_id),
                "sender": CHAT.SENDER.USER,
                "action": CHAT.ACTION.PVBC,
                "actionType": CHAT.ACTION_TYPE.PRESCREENING_STATUS,
                "text": 'Verified',
                "createdAt": firebase.firestore.FieldValue.serverTimestamp(),
                "updatedAt": firebase.firestore.FieldValue.serverTimestamp(),
                "sendToCId": appointment.clinic.uniqueid,
                "sendToDId": appointment.doctor.uniqueid,
                "dependantId": Number(appointment.dependant.uniqueid),
                "dateTime": {
                    "date": appointment.date,
                    "time": appointment.time
                }
            };
            try {
                firebaseInstance.getPrescreeningWithViewByNull(appointment.uniqueid).then((querySnapshot: any) => {
                    querySnapshot.forEach((doc: any) => {
                        const docRef = firebaseInstance.getDocRef(doc);
                        docRef.update({
                            "metadata.viewByClinicAt": firebase.firestore.FieldValue.serverTimestamp(),
                        });
                    });
                });
                const response = await firebaseInstance.addDocumentIntoCollection(configs.CHAT_COLLECTION, obj);
                if (response) {
                    response.get().then((docRef: any) => {
                        let data = {
                            ...docRef.data(),
                            mode: "create",
                            id: docRef.id
                        };
                        firestoreChangeNotificationToServer(data).then((success) => {
                            hideModal();
                            fetchAppointment(true);
                            toast.success('Prescreening verified.');
                        }).catch((err) => {
                            console.log(err);
                        });
                    });
                }
            } catch (err) {
                toast.error('Verify prescreening failed.');
                console.log(err);
                setIsVerifyStart(false);
            }
        } else {
            setIsVerifyStart(false);
        }
    }
    console.log(state);
    return (
        <Modal show={true} onHide={hideModal} className="newcommon prescreening-popup" centered>

            <Modal.Header>
                <div className="modal-title">Pre-screening</div>
                <button type="button" className="btn-close" aria-label="Close" onClick={hideModal}>
                </button>
            </Modal.Header>


            <Modal.Body>
                {
                    (isLoading) &&
                    <div className="mb-4 text-center">
                        <div className="spinner-border" role="status">
                            <span className="visually-hidden">Loading...</span>
                        </div>
                    </div>
                }
                {
                    (!prescreenData && !isLoading) &&
                    <div className="mb-4 text-center">
                        <span>No prescreening data available</span>
                    </div>
                }
                {
                    (prescreenData && !isLoading) &&
                    <div className="covidPrescreen" id="covidPrescreen">

                        <div className="d-flex p-0 justify-content-between mb-4">
                            <div>
                                <span className="d-block fw-medium">Patient name</span>
                                <small className="d-block text-muted">
                                    {
                                        (appointment.dependant && appointment.dependant.fname && appointment.dependant.lname) ?
                                            (appointment.dependant.fname + ' ' + appointment.dependant.lname) : ''
                                    }
                                </small>
                            </div>
                            <div>
                                Completed
                            </div>
                        </div>

                        <div className="alert alert-light border">
                            {appointment.appointmentType + " with " + appointment.doctor.fullname + " at " + appointment.clinic.name}
                        </div>
                        {
                            (prescreenData && prescreenData.text) &&
                            prescreenData.text.map((value: any, index: number) => {
                                if (value.options) {
                                    return (
                                        <p key={index + Math.random()}>{index + 1}. {value.question}  <span className={value.answer == "None" ? 'text-success' : 'text-danger'}> :  {value.answer}</span></p>
                                    );
                                }
                                if (prescreenData.text.length - 1 === index) {
                                    return (
                                        <div key={index + Math.random()} className={(value.answer === true || value.answer === "yes") ? 'alert alert-success' : 'alert alert-danger'}>
                                            <p>{value.question}</p>
                                            <div>Answer: {(value.answer === true || value.answer === "yes") ? 'Yes' : 'No'}</div>
                                        </div>);
                                }
                                return (
                                    <div key={index + Math.random()} className={(value.answer === true || value.answer === "yes") ? 'alert alert-danger' : 'alert alert-success'}>
                                        <p>{value.question}</p>
                                        <div>Answer: {(value.answer === true || value.answer === "yes") ? 'Yes' : 'No'}</div>
                                    </div>
                                )
                            })
                        }
                        <div className="tempr mt-4">

                            <table>
                                <tr className="row">
                                    <td className="col">
                                        <label className="form-label">Temperature upon arrival</label>
                                        <input type="text" className="form-control form-control-lg text-center py-4" value={state.tempOnArrival} onChange={(e) => {
                                            setPrescreeningTempOnArrivalOrOxySatLevel(Number(appointment.uniqueid), e.target.value, "tempOnArrival");
                                        }} />
                                    </td>
                                    <td className="col">
                                        <label className="form-label">Oxygen Saturation Level</label>
                                        <input type="text" className="form-control form-control-lg text-center py-4" value={state.oxySatLevel} onChange={(e) => {
                                            setPrescreeningTempOnArrivalOrOxySatLevel(Number(appointment.uniqueid), e.target.value, "oxySatLevel");
                                        }} />
                                    </td>
                                </tr>
                            </table>

                        </div>
                        <div className="signature mt-5">
                            {
                                (appointment && appointment.metadata &&
                                    appointment.metadata.appointment && appointment.metadata.appointment.prescreening
                                    && appointment.metadata.appointment.prescreening.attempt &&
                                    appointment.metadata.appointment.prescreening.verifiedOn) &&
                                <>Verified on {moment(appointment.metadata.appointment.prescreening.verifiedOn, 'YYYY-MM-DD HH:mm:ss ZZ').format('MMM DD, YYYY - LT')}</>
                            }
                        </div>
                        <div className="signature mt-1">
                            {
                                (state.tempOnArrivalUpdatedAt !== "") &&
                                <>Temperature on arrival last updated at {state.tempOnArrivalUpdatedAt}</>
                            }
                        </div>
                        <div className="signature mt-1">
                            {
                                (state.oxySatLevelUpdatedAt !== "") &&
                                <>Oxygen saturation level last updated at {state.oxySatLevelUpdatedAt}</>
                            }
                        </div>
                        {/* <div className="signature mt-5"><hr />Signature</div> */}
                        <div className="clearfix"></div>
                    </div>
                }
            </Modal.Body>
            {
                (!isLoading && prescreenData) &&
                <Modal.Footer>
                    <button className="btn btn-outline-primary" onClick={(e: any) => {
                        printCovidDiv();
                    }}>Print</button>

                    {
                        (!(appointment && appointment.metadata &&
                            appointment.metadata.appointment && appointment.metadata.appointment.prescreening
                            && appointment.metadata.appointment.prescreening.attempt &&
                            appointment.metadata.appointment.prescreening.verifiedOn) &&
                            (appointment && appointment.currentStatus !== APPOINTMENT_STATUS.CANCEL[0])) &&
                        <button className="btn btn-primary" disabled={isVerifyStart} onClick={(e: any) => {
                            handleVerifyPrescreening();
                        }}>
                            {
                                isVerifyStart &&
                                <span className="spinner-border spinner-border-sm me-1" role="status" aria-hidden="true"></span>
                            }
                            Verify pre-screening</button>
                    }
                </Modal.Footer>
            }
        </Modal>
    )
}