import React from 'react';
import { NavLink } from 'react-router-dom';
export const DigitalDocHeader = () => {
    return (
        <section className="middle-navbar px-30">
            <div className="p-0 container-fluid">
                <div className="d-flex justify-content-between">
                    <div>
                        <div className="nav-title-txt">Digital Forms</div>
                        <ul className="nav nav-tabs">
                            <li className="nav-item">
                                <NavLink className="nav-link" exact={true} to={"/message-center/digitaldoc"}>Digital Form Notifications</NavLink>
                            </li>
                            <li className="nav-item">
                                <NavLink className="nav-link" exact={true} to={"/message-center/digitaldoc/documents"}>Upload Documents</NavLink>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </section>
    );
}