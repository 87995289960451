import React, { useState, useEffect } from 'react';
import { currentPlan, getInvoiceList } from '../../services/subscription';
import { connect } from 'react-redux';
import { IAppState } from '../../../store';
import { toast } from 'react-toastify';
import { fetchLoadingPending, fetchLoadingSuccess } from '../../../store/loadingIndicator/actions';
function Invoices(props: any) {
    const [state, setState] = useState({
        invoiceList: [], isNoRecord: false
    });
    useEffect(() => {
        if (props.userDataResponse && props.userDataResponse.clinics && props.userDataResponse.clinics.length > 0) {
            let clinicUniqueId = props.userDataResponse.clinics[0].uniqueId;
            invoiceListApi(clinicUniqueId);
        }

    }, []);
    useEffect(() => {
        if (props.userDataResponse && props.userDataResponse.clinics && props.userDataResponse.clinics.length > 0) {
            let clinicUniqueId = props.userDataResponse.clinics[0].uniqueId;
            invoiceListApi(clinicUniqueId);
        }
    }, [props.userDataResponse]);
    const invoiceListApi = (clinicUniqueId: any) => {
        props.loadingStart();
        getInvoiceList(clinicUniqueId).then((success: any) => {
            if (success && success.response && success.response.data && success.response.status === 200) {
                if (success.response.data && success.response.data.invoicedetail && success.response.data.status.error === false) {
                    setState({ ...state, invoiceList: success.response.data.invoicedetail, isNoRecord: true });
                    props.loadingEnd(false);
                } else {
                    // Error Msg
                    if (success.response.data && success.response.data.status.error === true) {
                        // toast.error(success.response.data.status.msg)
                        setState({...state,isNoRecord:true,invoiceList:[]})
                    }
                    props.loadingEnd(false);
                }
            } else {
                toast.error('Something went wrong.')
                props.loadingEnd(false);
            }
        }).catch((err) => {
            props.loadingEnd(false);
            return '';
        });

    }
    return (
        <section className="subscription-sec px-3">
            <div className="container">
                {state.invoiceList && state.invoiceList.length > 0 ?
                    <>
                        <div className="row">
                            <div className="col-12">
                                <div className="advances-table">
                                    <table className="table">
                                        <thead>
                                            <tr>
                                                <th>Files name</th>
                                                <th>Created Date</th>
                                                <th>Total</th>
                                                <th>Status</th>
                                                <th></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                state.invoiceList.map((item: any, index: number) => {
                                                    return (
                                                        <tr>
                                                            <td>
                                                                <div className="d-flex">
                                                                    <span className="avatar avatar-48 img-circle me-2"><i className="bi bi-file-earmark-ppt-fill"></i></span>
                                                                    {/* <img src={require("../../assets/images/rounded-pdf.svg")} /> */}
                                                                    <span className="align-self-center">{item && item.invoice_name && item.invoice_name}</span>
                                                                </div>
                                                            </td>
                                                            <td><small>{item && item.created_date && item.created_date}</small></td>
                                                            <td><small>{item && item.total && item.total}</small></td>
                                                            <td><span className="badge rounded-pill bg-success">{item && item.status && item.status}</span></td>
                                                            <td><a href={item && item.invoice_url && item.invoice_url} className="btn mx-1"><i className="bi bi-download"></i></a></td>
                                                        </tr>
                                                    )
                                                })
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </>
                    : state.isNoRecord &&
                    // <h3>No Record found</h3>
                    <div className="alert alert-danger py-2 mt-4" role="alert">
                      <div className="d-flex justify-content-between align-items-center">
                        <p className="m-0">You don't have Invoices.</p>
                      </div>
                    </div>
                }
            </div>
        </section>
    )
}
const mapStateToProps = (state: IAppState) => ({
    userDataResponse: state.userData.userDataResponse,
});
const mapDispatchToProps = (dispatch: any) => ({
    loadingStart: () => {
        dispatch(fetchLoadingPending());
    },
    loadingEnd: (status: any) => {
        dispatch(fetchLoadingSuccess(status));
    },
});
export default connect(mapStateToProps, mapDispatchToProps)(Invoices);
