export enum MESSAGE_CENTER {
  MESSAGE_CENTER_RECARE = 'MESSAGE_CENTER_RECARE',
  MESSAGE_CENTER_RECARE_FAILED = 'MESSAGE_CENTER_RECARE_FAILED',
  MESSAGE_CENTER_RECARE_SUCCESS = 'MESSAGE_CENTER_RECARE_SUCCESS',
  MESSAGE_CENTER_RECALL = 'MESSAGE_CENTER_RECALL',
  MESSAGE_CENTER_RECALL_FAILED = 'MESSAGE_CENTER_RECALL_FAILED',
  MESSAGE_CENTER_RECALL_SUCCESS = 'MESSAGE_CENTER_RECALL_SUCCESS'
}
export interface MessageCenterRecareRequest {
  clinicUniqueId: string;
  data: {
    type: string;
    text?: string;
    subject?: string;
    body?: string;
  }
};
export interface MessageCenterRecareResponse {
  status: {
    error: boolean;
    msg: string;
  }
};
export interface MessageCenterRecallRequest {
  clinicUniqueId: string;
  data: {
    type: string;
    text?: string;
    subject?: string;
    body?: string;
    month?: number;
  }
};
export interface MessageCenterRecallResponse {
  status: {
    error: boolean;
    msg: string;
  }
};
export interface MessageCenterState {
  pending: boolean;
  error: any;
  messageCenterRecareRequest?: MessageCenterRecareRequest;
  messageCenterRecareResponse?: MessageCenterRecareResponse;
  messageCenterRecallRequest?: MessageCenterRecallRequest;
  messageCenterRecallResponse?: MessageCenterRecallResponse;
};
export interface MessageCenterRecarePendingAction {
  type: MESSAGE_CENTER.MESSAGE_CENTER_RECARE;
  payload: MessageCenterRecareRequest;
};
export interface MessageCenterRecareFailureAction {
  type: MESSAGE_CENTER.MESSAGE_CENTER_RECARE_FAILED;
  payload: any;
};
export interface MessageCenterRecareSuccessAction {
  type: MESSAGE_CENTER.MESSAGE_CENTER_RECARE_SUCCESS;
  payload: MessageCenterRecareResponse;
};
export interface MessageCenterRecallPendingAction {
  type: MESSAGE_CENTER.MESSAGE_CENTER_RECALL;
  payload: MessageCenterRecallRequest;
};
export interface MessageCenterRecallFailureAction {
  type: MESSAGE_CENTER.MESSAGE_CENTER_RECALL_FAILED;
  payload: any;
};
export interface MessageCenterRecallSuccessAction {
  type: MESSAGE_CENTER.MESSAGE_CENTER_RECALL_SUCCESS;
  payload: MessageCenterRecallResponse;
};
export type MessageCenterActionTypes = MessageCenterRecarePendingAction | MessageCenterRecareFailureAction | MessageCenterRecareSuccessAction |
  MessageCenterRecallPendingAction | MessageCenterRecallFailureAction | MessageCenterRecallSuccessAction;
