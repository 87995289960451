export enum ADD_PROVIDER {
    ADD_PROVIDER_PENDING = 'ADD_PROVIDER_PENDING',
    ADD_PROVIDER_ERROR = 'ADD_PROVIDER_ERROR',
    ADD_PROVIDER_SUCCESS = 'ADD_PROVIDER_SUCCESS',
    FETCH_SPECIALITY_PENDING = 'FETCH_SPECIALITY_PENDING',
    FETCH_SPECIALITY_ERROR = 'FETCH_SPECIALITY_ERROR',
    FETCH_SPECIALITY_SUCCESS = 'FETCH_SPECIALITY_SUCCESS',
};

export interface ProviderAddRequest {
    uniqueid: string;
    fullname: string;
    logo: any;
    gender: string;
    email: string;
    Languages: any[];
    speciality: any[];
    clinics: any[];
}
export interface Status {
    error: boolean;
    msg: string;
}
export interface FetchSpecialityRequest { }
export interface FetchSpecialityResponse {
    specialities: any[];
    status: Status;
}
export interface ProviderAddResponse {
    status: Status;
    permission: any;
}
export interface ProviderAddState {
    pending: boolean;
    providerAdd: ProviderAddRequest;
    providerAddResponse: ProviderAddResponse;
    specialityResponse: FetchSpecialityResponse;
    error: any;
}

export interface ProviderAddPendingAction {
    type: ADD_PROVIDER.ADD_PROVIDER_PENDING;
    payload: ProviderAddRequest;
}

export interface ProviderAddErrorAction {
    type: ADD_PROVIDER.ADD_PROVIDER_ERROR;
    error: any;
}
export interface ProviderAddSuccessAction {
    type: ADD_PROVIDER.ADD_PROVIDER_SUCCESS;
    providerAdd: ProviderAddResponse;
}

export interface FetchSpecialityPendingAction {
    type: ADD_PROVIDER.FETCH_SPECIALITY_PENDING;
}

export interface FetchSpecialityErrorAction {
    type: ADD_PROVIDER.FETCH_SPECIALITY_ERROR;
    error: any;
}
export interface FetchSpecialitySuccessAction {
    type: ADD_PROVIDER.FETCH_SPECIALITY_SUCCESS;
    speciality: FetchSpecialityResponse;
}
export type ProviderAddActionTypes = ProviderAddErrorAction | ProviderAddPendingAction | ProviderAddSuccessAction |
    FetchSpecialityErrorAction | FetchSpecialityPendingAction | FetchSpecialitySuccessAction;