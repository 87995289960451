import React, { useEffect, useRef, useState } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { IAppState } from '../../../store';
import configs from '../../configs/apiConfigs';
import { deleteOfferPhoto, listOfferPhoto, uploadOfferPhoto } from '../../services/clinicProfile';

export const Offers = () => {
    const uploadRef: any = useRef();
    const uploadRefNew: any = useRef();
    const userData = useSelector((state: IAppState) => state.userData.userDataResponse, shallowEqual);
    const [newPhoto, setNewPhoto] = useState([]);
    const [existingPhoto, setExistingPhoto] = useState([]);
    useEffect(() => {
        getOfferPhotos();
    }, [])
    const getOfferPhotos = () => {
        listOfferPhoto(userData.clinics[0].uniqueId).then((success: any) => {
            console.log(success);
            if (success && success.status && success.status.error === false) {
                setNewPhoto(success.new);
                setExistingPhoto(success.existing);
            }
        })
    }
    const deletePhoto = (photo: any) => {
        deleteOfferPhoto(photo.id, userData.clinics[0].uniqueId).then((success) => {
            getOfferPhotos();
        }).catch((err) => {

        });
    }
    const onChangeFileNew = (event: any) => {
        const file = event.target.files[0];
        if (file) {
            if (file.type === "image/png" ||
                file.type === 'image/jpg' ||
                file.type == 'image/jpeg') {
                var reader: any = new FileReader();
                var url: any = reader.readAsDataURL(event.target.files[0]);
                reader.onloadend = function (e: any) {
                    //Initiate the JavaScript Image object.
                    var image = new Image();
                    //Set the Base64 string return from FileReader as source.
                    image.src = e.target.result;
                    //Validate the File Height and Width.
                    image.onload = function () {
                        var height = image.height;
                        var width = image.width;
                        if (height < 256 || width < 256) {
                            return false;
                        }
                        uploadOfferPhoto(userData.clinics[0].uniqueId, { type: 'new', uphoto: file }).then((success) => {
                            if (success && success.status && success.status.error === false) {
                                getOfferPhotos();
                                return;
                            }
                        }).catch((err) => {
                            console.log(err)
                        })
                        return true;
                    };
                };
                return;
            } else {
            }
        }
    }
    const onChangeFileExisting = (event: any) => {
        const file = event.target.files[0];
        if (file) {
            if (file.type === "image/png" ||
                file.type === 'image/jpg' ||
                file.type == 'image/jpeg') {
                var reader: any = new FileReader();
                var url: any = reader.readAsDataURL(event.target.files[0]);
                reader.onloadend = function (e: any) {
                    //Initiate the JavaScript Image object.
                    var image = new Image();
                    //Set the Base64 string return from FileReader as source.
                    image.src = e.target.result;
                    //Validate the File Height and Width.
                    image.onload = function () {
                        var height = image.height;
                        var width = image.width;
                        if (height < 256 || width < 256) {
                            return false;
                        }
                        uploadOfferPhoto(userData.clinics[0].uniqueId, { type: 'existing', uphoto: file }).then((success) => {
                            if (success && success.status && success.status.error === false) {
                                getOfferPhotos();
                                return;
                            }
                        })
                        return true;
                    };
                };
                return;
            } else {
            }
        }
    }
    return (
        <>
            <div className="container pt-4">
                <div className="practice-photos-sec mb-4">
                    <div className="card">
                        <div className="card-header bg-light py-3 px-4">
                            <div className="d-flex justify-content-between">
                                <div className="fw-medium">Existing patient offers</div>
                                <button className="btn p-0 btn-link" onClick={() => { uploadRef.current.click() }}>Add</button>
                                <input id="myInputProfilePhotos" accept="image/png,image/gif,image/jpeg" type="file" ref={uploadRef} style={{ display: 'none' }} onChange={onChangeFileExisting} />
                            </div>
                        </div>

                        <div className="card-body p-4 pb-0">
                            <div className="alert alert-warning" role="alert">
                                <p className="m-0">
                                    Uploading practice offers helps boost your practice marketing on XCarePRO and by word of mouth.
                                    Offers show patients what you bring to the table as a practice and helps create new customer base and maintain loyal customer base at the same time.
                                </p>
                            </div>
                            <div className="clinic-img-sec">
                                <div className="row">
                                    {
                                        existingPhoto.map((item: any, index: number) => {
                                            return (
                                                <div className="col-3" key={"image" + item.id}>
                                                    <div className="img-box">
                                                        <img src={configs.IMAGE_URL + 'clinicoffer/' + item.image.name + '-500x230.' + item.image.type} />
                                                        <div className="hover-btns d-flex justify-content-center">
                                                            {/* <button className="btn mx-1 p-0 text-white fs-4" onClick={() => { }}><i className="bi bi-zoom-in"></i></button> */}
                                                            <button className="btn mx-1 p-0 text-white fs-4" onClick={() => {
                                                                deletePhoto(item);
                                                            }}><i className="bi bi-trash"></i></button>
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="practice-photos-sec mb-4">
                    <div className="card">
                        <div className="card-header bg-light py-3 px-4">
                            <div className="d-flex justify-content-between">
                                <div className="fw-medium">New patient offers</div>
                                <button className="btn p-0 btn-link" onClick={() => { uploadRefNew.current.click() }}>Add</button>
                                <input id="myInputProfilePhotos" accept="image/png,image/gif,image/jpeg" type="file" ref={uploadRefNew} style={{ display: 'none' }} onChange={onChangeFileNew} />
                            </div>
                        </div>

                        <div className="card-body p-4 pb-0">
                            <div className="alert alert-warning" role="alert">
                                <p className="m-0">
                                    Uploading practice offers helps boost your practice marketing on XCarePRO and by word of mouth.
                                    Offers show patients what you bring to the table as a practice and helps create new customer base and maintain loyal customer base at the same time.
                                </p>
                            </div>
                            <div className="clinic-img-sec">
                                <div className="row">
                                    {
                                        newPhoto.map((item: any, index: number) => {
                                            return (
                                                <div className="col-3" key={"image" + item.id}>
                                                    <div className="img-box">
                                                        <img src={configs.IMAGE_URL + 'clinicoffer/' + item.image.name + '-500x230.' + item.image.type} />
                                                        <div className="hover-btns d-flex justify-content-center">
                                                            {/* <button className="btn mx-1 p-0 text-white fs-4" onClick={() => { }}><i className="bi bi-zoom-in"></i></button> */}
                                                            <button className="btn mx-1 p-0 text-white fs-4" onClick={() => {
                                                                deletePhoto(item);
                                                            }}><i className="bi bi-trash"></i></button>
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}