import React, { useEffect, useState } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { IAppState } from '../../../store';
import { MyScheduleHeader } from '../../components/mySchedule/MyScheduleHeader';
import moment from 'moment';
import { getAppointments, getMasterPMSStatus } from '../../services/appointment';
import firebaseInstance from '../../helpers/firebase';
import configs from '../../configs/apiConfigs';
import { CHAT } from '../../../constants';
import { getCookie } from '../../utils/cookies';
import * as firebase from 'firebase/app';
import 'firebase/firestore';
import { fetchLoadingPending, fetchLoadingSuccess } from '../../../store/loadingIndicator/actions';
import { toast } from 'react-toastify';
import { useHistory, useLocation, useRouteMatch } from 'react-router';
import ScheduleNewCalendar from '../../../components/clinic/appointment/ScheduleNewCalendar';
let subscriptionToAppointmentChange: any;
let fromDate: any = moment().format('YYYY-MM-DD');
let toDate: any = moment().format('YYYY-MM-DD');
export const Calendar = () => {
    const isLoadingApi = useSelector((state: IAppState) => state.loading.pending, shallowEqual);
    const userData = useSelector((state: IAppState) => state.userData.userDataResponse, shallowEqual);
    const [providerList, setProviderList] = useState([]);
    // const [fromDate, setFromDate] = useState(moment().format('YYYY-MM-DD'));
    // const [toDate, setToDate] = useState(moment().format('YYYY-MM-DD'));
    const [isLoading, setIsLoading] = useState(false);
    const [isRefreshing, setIsRefreshing] = useState(false);
    const [appointmentResponse, setAppointmentResponse]: any = useState(null);
    const [masterPMSStatusList, setMasterPMSStatusList] = useState([]);
    const dispatch = useDispatch();
    const match = useRouteMatch();
    const location = useLocation();
    const history = useHistory();
    const serviceType = useSelector((state: IAppState) => state.payment.servicesTypeResponse?.serviceType);
    const paymentState = useSelector((state: IAppState) => state.payment);
    useEffect(() => {
        if (userData && userData.clinics && userData.clinics[0] && userData.status && userData.status.error === false && userData.status.msg !== "") {
            let providers = userData.clinics[0].provider;
            providers = providers.filter((item: any) => item.onBoarding.status === 1 && item.enableAppointment === "1");
            setProviderList(providers);
            listenRealtimeAppointmentChange();
            getMasterPMSStatusListFunc(userData.clinics[0].id);
        }
    }, [userData]);
    const listenRealtimeAppointmentChange = () => {
        subscriptionToAppointmentChange && subscriptionToAppointmentChange();
        subscriptionToAppointmentChange = firebaseInstance.db.collection(configs.CHAT_COLLECTION).where('actionType', 'in', [CHAT.ACTION_TYPE.STATUS,
        CHAT.ACTION_TYPE.PRESCREENING, CHAT.ACTION_TYPE.CONFIRM_BY_PATIENT, CHAT.ACTION_TYPE.CANCELLED_BY_PATIENT, CHAT.ACTION_TYPE.PRESCREENING_STATUS,
        CHAT.ACTION_TYPE.CHECKIN])
            .where('sendToCId', '==', userData.clinics[0].uniqueId)
            .onSnapshot((snapshot: any) => {
                setTimeout(() => {
                    fecthAppointmentListAsync(true);
                }, 1500);
            });
    }
    const fecthAppointmentListAsync = async (realTime = false, fromParam: any = null, toParam: any = null) => {
        try {
            let modelType = 'clinic';
            let Id = userData.clinics[0].uniqueId;
            const request = {
                viewCalendar: true,
                appointmentType: 'scheduled',
                modelType: modelType,
                page: 1,
                Id: Id,
                from: fromParam ? fromParam : fromDate,
                to: toParam ? toParam : toDate
            };
            if (realTime === true) {
                setIsRefreshing(true);
            } else {
                setIsLoading(true);
            }
            const response = await getAppointments(request);
            /* if (response && response.status && response.status.error === false && response.appointments) {
                setAppointmentResponse(response.appointments);
            } else {
                setAppointmentResponse([]);
            } */
            if (response && response.status && response.status.error === false) {
                setAppointmentResponse(response);
            }
            if (realTime === true) {
                setIsRefreshing(false);
            } else {
                setIsLoading(false);
            }
        } catch (err) {
            if (realTime === true) {
                setIsRefreshing(false);
            } else {
                setIsLoading(false);
            }
            console.log(err);
        }
    };
    const onChangeTime = (time: any, item: any) => {
        try {
            setAppointmentResponse({
                appointmentResponse: {
                    ...appointmentResponse,
                    appointments: appointmentResponse.appointments.map((value: any) => {
                        if (value.id === item.id)
                            return {
                                ...value,
                                edtTime: moment(time).format('LT'),
                                edtFullDate: time,
                            }
                        return value
                    })
                }
            });
        } catch (error) {
            console.log('error', error);
        }
    }
    const onChangeEndTime = (time: any, item: any) => {
        try {
            setAppointmentResponse({
                appointmentResponse: {
                    ...appointmentResponse,
                    appointments: appointmentResponse.appointments.map((value: any) => {
                        if (value.id === item.id)
                            return {
                                ...value,
                                edtEndTime: moment(time).format('LT'),
                                edtEndFullDate: time,
                            }
                        return value
                    })
                }
            });
        } catch (err) {
            console.log('error', err);
        }
    }
    const onDateChange = (date: any, item: any) => {
        try {
            setAppointmentResponse({
                appointmentResponse: {
                    ...appointmentResponse,
                    appointments: appointmentResponse.appointments.map((value: any) => {
                        if (value.id === item.id)
                            return {
                                ...value,
                                edtDate: date,
                            }
                        return value
                    })
                }
            });
        } catch (err) {
            console.log('error', err);
        }
    };
    const handleRequestForPrescreening = async (item: any) => {
        const obj = {
            "appointmentId": Number(item.uniqueid),
            "senderId": Number(getCookie().xpr_user_id),
            "sender": CHAT.SENDER.USER,
            "action": CHAT.ACTION.PRBC,
            "actionType": CHAT.ACTION_TYPE.PRESCREENING_REQUEST,
            "text": 'Requested',
            "createdAt": firebase.firestore.FieldValue.serverTimestamp(),
            "updatedAt": firebase.firestore.FieldValue.serverTimestamp(),
            "sendToCId": item.clinic.uniqueid,
            "sendToDId": item.doctor.uniqueid,
            "dependantId": Number(item.dependant.uniqueid),
            "dateTime": {
                "date": item.date,
                "time": item.time
            },
            "firstName": userData.firstName,
            "lastName": userData.lastName,
        };
        try {
            dispatch(fetchLoadingPending());
            await firebaseInstance.setDocumentIntoCollection(configs.CHAT_COLLECTION, obj);
            toast.success('Prescreening reminder initiated successfully.');
            dispatch(fetchLoadingSuccess(false));
        } catch (err) {
            dispatch(fetchLoadingSuccess(false));
            toast.success('Prescreening reminder initiation failed.');
        }
    }
    const getMasterPMSStatusListFunc = async (id: string) => {
        try {
            const response = await getMasterPMSStatus(id, true);
            if (response && response.status && response.status.error === false) {
                setMasterPMSStatusList(response.pmsStatuses);
            }
        } catch (err) {
            console.log(err);
        }
    }
    return (
        <React.Fragment>
            <MyScheduleHeader onFetchAppointment={fecthAppointmentListAsync}></MyScheduleHeader>
            {
                ((isLoading && !isRefreshing && isLoadingApi === false) ||
                    (isRefreshing && !isLoading && isLoadingApi === false) ||
                    (isRefreshing && isLoading && isLoadingApi === false)) &&
                <div className="content-spinner">
                    <div className="spinner-border" role="status"></div>
                </div>
            }
            <ScheduleNewCalendar
                history={history}
                match={match}
                location={location}
                data={appointmentResponse}
                modelType={'clinic'}
                clinics={providerList}
                selectedClinic={userData.clinics[0].uniqueId}
                onDateChange={(date: any, item: any) => { onDateChange(date, item) }}
                onChangeTime={(time: any, item: any) => { onChangeTime(time, item) }}
                onChangeEndTime={(time: any, item: any) => { onChangeEndTime(time, item) }}
                handleRequestForPrescreening={(appointment: any) => {
                    handleRequestForPrescreening(appointment);
                }}
                callFetchApi={(startDate: any, endDate: any, view: string) => {
                    if (view === 'Week' || view === 'Month') {
                        fromDate = moment(startDate).format('YYYY-MM-DD');
                        toDate = moment(endDate).format('YYYY-MM-DD');
                        // setFromDate(moment(startDate).format('YYYY-MM-DD'));
                        // setToDate(moment(endDate).format('YYYY-MM-DD'));
                        fecthAppointmentListAsync(true, moment(startDate).format('YYYY-MM-DD'), moment(endDate).format('YYYY-MM-DD'));
                    } else if (view == 'Day') {
                        fromDate = moment(startDate).format('YYYY-MM-DD');
                        toDate = moment(endDate).format('YYYY-MM-DD');
                        // setFromDate(moment(startDate).format('YYYY-MM-DD'));
                        // setToDate(moment(endDate).format('YYYY-MM-DD'));
                        fecthAppointmentListAsync(true, moment(startDate).format('YYYY-MM-DD'), moment(endDate).format('YYYY-MM-DD'));
                    }
                }}
                masterPMSStatusList={masterPMSStatusList}
                serviceType={serviceType}
                paymentState={paymentState}
            ></ScheduleNewCalendar>

        </React.Fragment>
    );
}