import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import { updateClinicProfile } from '../../../services/clinicProfile';
export const Insurance = ({ insurance, insuranceList, socialAssistanceList, clinicId, fetchProfile }: any) => {
  const [insuranceAcceptType, setInsuranceAcceptType] = useState("all");
  const [selectedInsurance, setSelectedInsurace]: any = useState([]);
  const [availableInsurance, setAvailableInsurace]: any = useState([]);
  const [selectedSearchInsurance, setSelectedSearchInsurance] = useState(null);

  const [acceptSocialAssistance, setSocialAssistance] = useState("no");
  const [assistanceTypes, setAssistanceTypes]: any = useState([]);
  const [selectedAssistance, setSelectedAssistance] = useState(null);
  const [assistanceSelected, setAssistanceSelected]: any = useState([]);
  const [socialAssistTypeAccept, setSocialAssistTypeAccept]: any = useState('');

  const [isEditInsurance, setIsEditInsurance] = useState(false);
  const [isLoadingInsurance, setIsLoadingInsurance] = useState(false);

  const [isEditSA, setIsEditSA] = useState(false);
  const [isLoadingSA, setIsLoadingSA] = useState(false);

  const [isEditAS, setIsEditAS] = useState(false);
  const [isLoadingAS, setIsLoadingAS] = useState(false);

  const [paymentMethods, setPaymentMethods]: any = useState([]);
  const [acceptAssignment, setAcceptAssignment]: any = useState("yes");
  useEffect(() => {
    setInsuranceAcceptType(insurance.insuranceAccept ? insurance.insuranceAccept : insurance.insurance.length > 0 ? 'specific' : 'all');
    const mappedInsurance = insuranceList.map((item: any) => ({ value: item.id, label: item.name }));
    setAvailableInsurace(mappedInsurance);
    if (Array.isArray(insurance.insurance)) {
      setSelectedInsurace(insurance.insurance.map((item: any) => ({ value: item.id, label: item.name })));
    }
    setSelectedSearchInsurance(mappedInsurance[0]);

    setSocialAssistance(insurance.socialAssistance);
    const mapped = socialAssistanceList.map((item: any) => ({ value: item.id, label: item.title }));
    setAssistanceTypes(mapped);
    setSelectedAssistance(mapped[0]);
    const assistanceFromApi = insurance.socialAssistType.split(",");
    if (insurance.socialAssistType && insurance.socialAssistType.length === 0 && assistanceFromApi.length > 0) {
      setSocialAssistTypeAccept("specific");
    } else {
      setSocialAssistTypeAccept(insurance.socialAssistTypeAccept);
    }
    setAssistanceSelected(mapped.filter((item: any) => assistanceFromApi.find((aItem: any) => aItem === item.value) !== undefined));

    setAcceptAssignment(insurance.acceptAssignment);
    setPaymentMethods(insurance.paymentMethod ? insurance.paymentMethod.split(",") : []);
  }, [insurance, insuranceList, socialAssistanceList]);
  const insuranceTypeChange = (type: string) => {
    if (type === 'all' || type === 'no') {
      setSelectedInsurace([]);
    }
    setInsuranceAcceptType(type);
  }
  const addInsurance = () => {
    if (selectedSearchInsurance !== null) {
      //@ts-ignore
      if (!(selectedInsurance.find((item: any) => item.value === selectedSearchInsurance.value))) {
        setSelectedInsurace([...selectedInsurance, selectedSearchInsurance]);
      }
    }
  };
  const onCancelInsurance = () => {
    setIsEditInsurance(false);
    setIsLoadingInsurance(false);
    setInsuranceAcceptType(insurance.insuranceAccept ? insurance.insuranceAccept : insurance.insurance.length > 0 ? 'specific' : 'all');
    const mappedInsurance = insuranceList.map((item: any) => ({ value: item.id, label: item.name }));
    setAvailableInsurace(mappedInsurance);
    if (Array.isArray(insurance.insurance)) {
      setSelectedInsurace(insurance.insurance.map((item: any) => ({ value: item.id, label: item.name })));
    }
    setSelectedSearchInsurance(mappedInsurance[0]);
  };
  const onSaveInsurance = () => {
    const request = {
      uniqueid: clinicId,
      insuranceAccept: insuranceAcceptType,
      insurance: selectedInsurance.map((item: any) => item.value).toString(),
      socialAssist: acceptSocialAssistance,
      socialAssistType: assistanceSelected.map((item: any) => item.value).toString(),
      socialAssistTypeAccept: socialAssistTypeAccept,
      assignment: acceptAssignment,
      paymentMethod: paymentMethods.length > 0 ? paymentMethods.toString() : ''
    };
    setIsLoadingInsurance(true);
    updateClinicProfile('socialAssignment', clinicId, request).then((success) => {
      fetchProfile('socialAssignment').then((success: any) => {
        setIsLoadingInsurance(false);
        setIsEditInsurance(false);
      });
    }).catch((err) => {
    });
  }
  const addSocialAssistance = () => {
    if (selectedAssistance !== null) {
      //@ts-ignore
      if (!(assistanceSelected.find((item: any) => item.value === selectedAssistance.value))) {
        setAssistanceSelected([...assistanceSelected, selectedAssistance]);
      }
    }
  };
  const onCancelSA = () => {
    setIsEditSA(false);
    setIsLoadingSA(false);
    setSocialAssistance(insurance.socialAssistance);
    const mapped = socialAssistanceList.map((item: any) => ({ value: item.id, label: item.title }));
    setAssistanceTypes(mapped);
    setSelectedAssistance(mapped[0]);
    const assistanceFromApi = insurance.socialAssistType.split(",");
    if (insurance.socialAssistType && insurance.socialAssistType.length === 0 && assistanceFromApi.length > 0) {
      setSocialAssistTypeAccept("specific");
    } else {
      setSocialAssistTypeAccept(insurance.socialAssistTypeAccept);
    }
    setAssistanceSelected(mapped.filter((item: any) => assistanceFromApi.find((aItem: any) => aItem === item.value) !== undefined));
  };
  const onSaveSA = () => {
    if (socialAssistTypeAccept === "specific" && assistanceSelected.length === 0) {
      return;
    }
    const request = {
      uniqueid: clinicId,
      insuranceAccept: insuranceAcceptType,
      insurance: selectedInsurance.map((item: any) => item.value).toString(),
      socialAssist: acceptSocialAssistance,
      socialAssistType: assistanceSelected.map((item: any) => item.value).toString(),
      socialAssistTypeAccept: socialAssistTypeAccept,
      assignment: acceptAssignment,
      paymentMethod: paymentMethods.length > 0 ? paymentMethods.toString() : ''
    };
    setIsLoadingSA(true);
    updateClinicProfile('socialAssignment', clinicId, request).then((success) => {
      fetchProfile('socialAssignment').then((success: any) => {
        setIsEditSA(false);
        setIsLoadingSA(false);
      });
    }).catch((err) => {

    });
  }
  const onCancelAS = () => {
    setIsEditAS(false);
    setIsLoadingAS(false);
    setAcceptAssignment(insurance.acceptAssignment);
    setPaymentMethods(insurance.paymentMethod ? insurance.paymentMethod.split(",") : []);
  };
  const onSaveAS = () => {
    const request = {
      uniqueid: clinicId,
      insuranceAccept: insuranceAcceptType,
      insurance: selectedInsurance.map((item: any) => item.value).toString(),
      socialAssist: acceptSocialAssistance,
      socialAssistType: assistanceSelected.map((item: any) => item.value).toString(),
      socialAssistTypeAccept: socialAssistTypeAccept,
      assignment: acceptAssignment,
      paymentMethod: paymentMethods.length > 0 ? paymentMethods.toString() : ''
    };
    setIsLoadingAS(true);
    updateClinicProfile('socialAssignment', clinicId, request).then((success) => {
      fetchProfile('socialAssignment').then((success: any) => {
        setIsEditAS(false);
        setIsLoadingAS(false);
      });
    }).catch((err) => {

    });
  }
  const paymentMethodChange = (type: any) => {
    if (!paymentMethods.find((item: any) => item === type)) {
      setPaymentMethods([...paymentMethods, type]);
    } else {
      setPaymentMethods(paymentMethods.filter((item: any) => item !== type));
    }
  }
  return (
    <>

      <div className="practice-insurance-sec mb-4">
        <div className="card">
          <div className="card-header bg-light py-3 px-4">
            <div className="d-flex justify-content-between">
              <div className="fw-medium">Insurance</div>
              <div>
                {
                  (isEditInsurance && !isLoadingInsurance) &&
                  <>
                    <button className="btn p-0 btn-link me-2 text-secondary" onClick={(e) => { onCancelInsurance(); }}><i className="bi bi-x-circle-fill text-danger"></i></button>
                    <button className="btn p-0 btn-link" onClick={(e) => { onSaveInsurance(); }}><i className="bi bi-check-circle-fill text-success"></i></button>
                  </>
                }
                {
                  (isEditInsurance && isLoadingInsurance) &&
                  <><span className="spinner-border spinner-border-sm text-primary" role="status" aria-hidden="true"></span></>
                }
                {
                  (!isEditInsurance && !isLoadingInsurance) &&
                  <button className="btn p-0 btn-link" onClick={(e) => { setIsEditInsurance(true); }}>Edit</button>
                }
              </div>
            </div>
          </div>

          <div className="card-body p-4">
            <div className="fw-normal fs-6">Which insurance companies do you accept?</div>
            <hr className="my-4" />

            <div className="insurance-type mb-3">
              <div className="form-check mb-2">
                <input className="form-check-input" type="radio" name="insuranceType" id="AllInsurance" value="all" checked={insuranceAcceptType === "all"}
                  disabled={!isEditInsurance}
                  onChange={(e) => { insuranceTypeChange(e.target.value) }}
                />
                <label className="form-check-label" htmlFor="AllInsurance">
                  All Insurance
							</label>
              </div>
              <div className="form-check mb-2">
                <input className="form-check-input" type="radio" name="insuranceType" id="SpecificCompanies" value="specific" checked={insuranceAcceptType === "specific"}
                  disabled={!isEditInsurance}
                  onChange={(e) => { insuranceTypeChange(e.target.value) }}
                />
                <label className="form-check-label" htmlFor="SpecificCompanies">
                  Specific companies
							</label>
              </div>
              <div className="form-check mb-2">
                <input className="form-check-input" type="radio" name="insuranceType" id="noInsurance" value="no" checked={insuranceAcceptType === "no"}
                  disabled={!isEditInsurance}
                  onChange={(e) => { insuranceTypeChange(e.target.value) }}
                />
                <label className="form-check-label" htmlFor="noInsurance">
                  No insurance
            	</label>
              </div>
            </div>

            {
              selectedInsurance.length > 0 &&
              <div className="insurance-list d-flex flex-wrap mb-3">
                {
                  selectedInsurance.map((item: any, index: number) => {
                    return (
                      <div className="badge border border-primary text-primary fw-normal rounded-pill py-0 mb-2 me-2" key={item.value + item.label}>{item.label}
                        <button className="btn p-0"
                          disabled={!isEditInsurance}
                          onClick={() => {
                            setSelectedInsurace(selectedInsurance.filter((iItem: any, iIndex: number) => {
                              return iIndex !== index;
                            }));
                          }}>
                          <i className="bi bi-x"></i>
                        </button>
                      </div>
                    )
                  })
                }
              </div>
            }

            {
              insuranceAcceptType === "specific" &&
              <div className="col-12">
                <label htmlFor="searchInsurance" className="form-label">Search insurance</label>
                <div className="d-flex mb-2 w-100">
                  <Select
                    className="w-75"
                    classNamePrefix="select"
                    isSearchable={true}
                    name="insuranceSearch"
                    disabled={!isEditInsurance}
                    value={selectedSearchInsurance}
                    options={availableInsurance}
                    onChange={(value: any) => { setSelectedSearchInsurance(value) }}
                  />
                  <button className="btn btn-primary fw-normal ms-3 rounded" disabled={!isEditInsurance} type="button"
                    onClick={() => { addInsurance(); }}>
                    <i className="bi bi-plus"></i></button>
                </div>
                <small className="text-muted">This data is pulled from our insurance database.</small>
              </div>
            }

          </div>
        </div>
      </div>

      <div className="practice-assignment-sec mb-4">
        <div className="card">
          <div className="card-header bg-light py-3 px-4">
            <div className="d-flex justify-content-between">
              <div className="fw-medium">Social assistance program</div>
              <div>
                {
                  (isEditSA && !isLoadingSA) &&
                  <>
                    <button className="btn p-0 btn-link me-2 text-secondary" onClick={(e) => { onCancelSA(); }}><i className="bi bi-x-circle-fill text-danger"></i></button>
                    <button className="btn p-0 btn-link" onClick={(e) => { onSaveSA(); }}><i className="bi bi-check-circle-fill text-success"></i></button>
                  </>
                }
                {
                  (isEditSA && isLoadingSA) &&
                  <><span className="spinner-border spinner-border-sm text-primary" role="status" aria-hidden="true"></span></>
                }
                {
                  (!isEditSA && !isLoadingSA) &&
                  <button className="btn p-0 btn-link" onClick={(e) => { setIsEditSA(true); }}>Edit</button>
                }
              </div>
            </div>
          </div>

          <div className="card-body p-4">
            <div className="mb-3">Do you accept social assistance programs?</div>
            <div className="d-flex">
              <div className="form-check">
                <input className="form-check-input" type="radio" name="socialAssistance" id="socialAssistanceYes" value={"yes"}
                  disabled={!isEditSA}
                  checked={acceptSocialAssistance === "yes"} onChange={() => {
                    setSocialAssistance("yes");
                    setAssistanceSelected([]);
                    setSocialAssistTypeAccept("all");
                  }} />
                <label className="form-check-label text-muted" htmlFor="socialAssistanceYes">
                  Yes
						      </label>
              </div>
              <div className="form-check ms-4">
                <input className="form-check-input" type="radio" name="socialAssistance" id="socialAssistanceNo" value={"no"}
                  disabled={!isEditSA}
                  checked={acceptSocialAssistance === "no"}
                  onChange={() => {
                    setSocialAssistance("no");
                    setAssistanceSelected([]);
                    setSocialAssistTypeAccept("");
                  }}
                />
                <label className="form-check-label text-muted" htmlFor="socialAssistanceNo">
                  No
						      </label>
              </div>
            </div>
            {/* {
              acceptSocialAssistance === "yes" &&
              <div className="mt-4 ins-select-type">
                <label className="form-label">Select type</label>
                <Select
                  className="w-100"
                  name="assistance-select"
                  options={assistanceTypes}
                  value={selectedAssistance}
                  onChange={(value: any) => { setSelectedAssistance(value) }}
                ></Select>
              </div>
            } */}
            {
              acceptSocialAssistance === 'yes' &&
              <div className="d-flex mt-3">
                <div className="d-flex insurance-type mb-4">
                  <div className="form-check">
                    <input className="form-check-input" type="radio" name="assistanceType" id="AllProgram" value="all" checked={socialAssistTypeAccept === "all"}
                      disabled={!isEditSA}
                      onChange={(e) => {
                        setSocialAssistTypeAccept(e.target.value);
                        setAssistanceSelected([]);
                      }}
                    />
                    <label className="form-check-label" htmlFor="AllProgram">All program</label>
                  </div>
                  <div className="form-check ms-4">
                    <input className="form-check-input" type="radio" name="assistanceType" id="specifcProgram" value="specific" checked={socialAssistTypeAccept === "specific"}
                      disabled={!isEditSA}
                      onChange={(e) => { setSocialAssistTypeAccept(e.target.value) }}
                    />
                    <label className="form-check-label" htmlFor="specifcProgram">Specific program</label>
                  </div>
                </div>
              </div>
            }
            {
              acceptSocialAssistance === "yes" && assistanceSelected.length > 0 &&
              <div className="insurance-list d-flex flex-wrap mb-3">
                {
                  assistanceSelected.map((item: any, index: number) => {
                    return (
                      <div className="badge border border-primary text-primary fw-normal rounded-pill py-0 mb-2 me-2" key={item.value}>{item.label}
                        <button className="btn p-0" disabled={!isEditSA} onClick={() => {
                          setAssistanceSelected(assistanceSelected.filter((iItem: any, iIndex: number) => {
                            return iIndex !== index;
                          }));
                        }}>
                          <i className="bi bi-x"></i>
                        </button>
                      </div>
                    )
                  })
                }
              </div>
            }
            {
              acceptSocialAssistance === "yes" && socialAssistTypeAccept === "specific" &&
              <div className="col-12">
                <label htmlFor="Selecttype" className="form-label">Select type</label>
                <div className="d-flex mb-2 w-100">
                  <Select
                    className="w-75"
                    name="assistance-select"
                    disabled={!isEditSA}
                    options={assistanceTypes}
                    value={selectedAssistance}
                    onChange={(value: any) => {
                      setSelectedAssistance(value)
                    }}
                  ></Select>
                  <button className="btn btn-primary fw-normal ms-3 rounded" disabled={!isEditSA} type="button" onClick={() => { addSocialAssistance(); }}>
                    <i className="bi bi-plus"></i></button>
                </div>
              </div>
            }
            <hr />
            <p className="m-0">e.g. ODSP, Ontario Works, Healthy Smiles, IFHP</p>
          </div>
        </div>
      </div>

      <div className="practice-assignment-sec mb-4">
        <div className="card">
          <div className="card-header bg-light py-3 px-4">
            <div className="d-flex justify-content-between">
              <div className="fw-medium">Assignment</div>
              <div>
                {
                  (isEditAS && !isLoadingAS) &&
                  <>
                    <button className="btn p-0 btn-link me-2 text-secondary" onClick={(e) => { onCancelAS(); }}><i className="bi bi-x-circle-fill text-danger"></i></button>
                    <button className="btn p-0 btn-link" onClick={(e) => { onSaveAS(); }}><i className="bi bi-check-circle-fill text-success"></i></button>
                  </>
                }
                {
                  (isEditAS && isLoadingAS) &&
                  <><span className="spinner-border spinner-border-sm text-primary" role="status" aria-hidden="true"></span></>
                }
                {
                  (!isEditAS && !isLoadingAS) &&
                  <button className="btn p-0 btn-link" onClick={(e) => { setIsEditAS(true); }}>Edit</button>
                }
              </div>
            </div>
          </div>
          <div className="card-body p-4">
            <label className="mb-3 fw-medium">When allowed by a patient's plan, does your office accept assignment (direct-billing to dental plans) for the portion of dental treatment that is covered under that dental plan?</label>
            <div className="form-check mb-3">
              <input className="form-check-input" type="radio" name="assignment" id="yeswe" value="yes" checked={acceptAssignment === 'yes'} onChange={(e) => {
                setAcceptAssignment('yes');
                setPaymentMethods([]);
              }}
                disabled={!isEditAS}
              />
              <label className="form-check-label" htmlFor="yeswe">
                Yes, we accept assignment
              </label>

            </div>
            <div className="form-check">
              <input className="form-check-input" type="radio" name="assignment" id="nowedont" value="yes" checked={acceptAssignment === 'no'} onChange={(e) => {
                setAcceptAssignment('no');
                setPaymentMethods(['cash', 'credit', 'debit', 'cheque']);
              }} disabled={!isEditAS} />
              <label className="form-check-label" htmlFor="nowedont">
                No, we require patients to pay the full amount and be reimbursed from their insurance company directly
            </label>
            </div>
            {
              acceptAssignment === "no" &&
              <div className="mt-3">
                <label className="form-label">Payment Methods</label>
                <div className="d-flex payment-mode-checkbox justify-content-between">
                  <div className="form-check">
                    <input className="form-check-input" name="paymentMethods" type="checkbox" value="cash" id="cash"
                      checked={paymentMethods.find((item: any) => item === 'cash') !== undefined}
                      onClick={(e: any) => { paymentMethodChange(e.target.value) }} disabled={!isEditAS} />
                    <label className="form-check-label" htmlFor="paymentMethods1">Cash</label>
                  </div>
                  <div className="form-check">
                    <input className="form-check-input" name="paymentMethods" type="checkbox" value="credit"
                      checked={paymentMethods.find((item: any) => item === 'credit') !== undefined}
                      id="credit" onClick={(e: any) => { paymentMethodChange(e.target.value) }} disabled={!isEditAS} />
                    <label className="form-check-label" htmlFor="paymentMethods2">Credit</label>
                  </div>
                  <div className="form-check">
                    <input className="form-check-input" name="paymentMethods" type="checkbox" value="debit"
                      checked={paymentMethods.find((item: any) => item === 'debit') !== undefined}
                      id="debit" onClick={(e: any) => { paymentMethodChange(e.target.value) }} disabled={!isEditAS} />
                    <label className="form-check-label" htmlFor="paymentMethods3">Debit</label>
                  </div>
                  <div className="form-check">
                    <input className="form-check-input" name="paymentMethods" type="checkbox" value="cheque"
                      checked={paymentMethods.find((item: any) => item === 'cheque') !== undefined}
                      id="cheque" onClick={(e: any) => { paymentMethodChange(e.target.value) }} disabled={!isEditAS} />
                    <label className="form-check-label" htmlFor="paymentMethods4">Cheque</label>
                  </div>
                </div>
              </div>
            }
          </div>
        </div>
      </div>
    </>
  )
}