import {
  MessageCenterRecallFailureAction,
  MessageCenterRecallPendingAction,
  MessageCenterRecallRequest,
  MessageCenterRecallResponse,
  MessageCenterRecallSuccessAction,
  MessageCenterRecareFailureAction, MessageCenterRecarePendingAction, MessageCenterRecareRequest,
  MessageCenterRecareResponse, MessageCenterRecareSuccessAction, MESSAGE_CENTER
} from "./types";

export const messageCenterRecarePending = (request: MessageCenterRecareRequest): MessageCenterRecarePendingAction => {
  return {
    type: MESSAGE_CENTER.MESSAGE_CENTER_RECARE,
    payload: request
  }
};
export const messageCenterRecareFailure = (error: any): MessageCenterRecareFailureAction => {
  return {
    type: MESSAGE_CENTER.MESSAGE_CENTER_RECARE_FAILED,
    payload: error
  }
};
export const messageCenterRecareSuccess = (response: MessageCenterRecareResponse): MessageCenterRecareSuccessAction => {
  return {
    type: MESSAGE_CENTER.MESSAGE_CENTER_RECARE_SUCCESS,
    payload: response
  }
};

export const messageCenterRecallPending = (request: MessageCenterRecallRequest): MessageCenterRecallPendingAction => {
  return {
    type: MESSAGE_CENTER.MESSAGE_CENTER_RECALL,
    payload: request
  }
};
export const messageCenterRecallFailure = (error: any): MessageCenterRecallFailureAction => {
  return {
    type: MESSAGE_CENTER.MESSAGE_CENTER_RECALL_FAILED,
    payload: error
  }
};
export const messageCenterRecallSuccess = (response: MessageCenterRecallResponse): MessageCenterRecallSuccessAction => {
  return {
    type: MESSAGE_CENTER.MESSAGE_CENTER_RECALL_SUCCESS,
    payload: response
  }
};