import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import InfiniteScroll from 'react-infinite-scroll-component';
import { shallowEqual, useSelector } from 'react-redux';
import { IAppState } from '../../../store';
import { getAppointments } from '../../services/appointment';
import moment from 'moment';
export const NotesModal = ({ hideModal }: any) => {
    const userData = useSelector((state: IAppState) => state.userData.userDataResponse, shallowEqual);
    const [page, setPage] = useState(1);
    const [results, setResults] = useState(30);
    const [totalCount, setTotalCount] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const [notesList, setNoteItemList]: any = useState([]);
    const getNotes = async () => {
        try {
            let modelType = 'clinic';
            let Id = userData.clinics[0].uniqueId;
            const request = {
                appointmentType: 'notes',
                modelType: modelType,
                page: page,
                results: results,
                Id: Id
            };
            setIsLoading(true);
            const response = await getAppointments(request);
            if (response && response.status && response.status.error === false) {
                if (page === 1) {
                    setNoteItemList(response.notes);
                    setTotalCount(Number(response.total));
                } else {
                    setNoteItemList([...notesList, ...response.notes]);
                }
            }
            setIsLoading(false);
        } catch (err) {
            setIsLoading(false);
            console.log(err);
        }
    }
    useEffect(() => {
        getNotes();
    }, [page]);
    const fetchMore = () => {
        console.log(page);
        setPage(page + 1);
    }
    console.log(totalCount, notesList.length);
    return (
        <Modal
            show={true}
            onHide={() => { hideModal() }}
            backdrop={true}
            className="right bgclick"
            backdropClassName="remove-trans"
        >
            <Modal.Header>
                <div className="modal-title">Notes</div>
                <button type="button" className="btn-close" aria-label="Close" onClick={() => {
                    hideModal();
                }}></button>
            </Modal.Header>
            <Modal.Body className="py-0 overflow-auto" id="scrollableDiv">
                {
                    totalCount === 0 && !isLoading &&
                    <label>No notes found</label>
                }
                {
                    isLoading && totalCount === 0 &&
                    <span className="spinner-border" role="status"></span>
                }
                <InfiniteScroll
                    dataLength={notesList.length}
                    next={fetchMore}
                    hasMore={notesList.length !== totalCount}
                    loader={<span className="spinner-border" role="status"></span>}
                    scrollableTarget="scrollableDiv"
                >
                    {
                        notesList.map((item: any, index: number) => {
                            return (
                                <div className="border-bottom py-3" key={item.id + "notes"}>

                                    <h6 className="badge bg-secondary">{
                                        moment(item.date).format('MMM DD, YYYY') + " " + item.time
                                    }</h6>
                                    <div>{item.note}</div>
                                    <div>{item.description}</div>
                                </div>
                            )
                        })
                    }
                </InfiniteScroll>
            </Modal.Body>
        </Modal>
    )
}