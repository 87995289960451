import {
    SETTINGS,
    SettingsActionTypes,
    SettingsState
} from './types';

export const initialState: SettingsState = {
    pending: false,
    settings: { uniqueId: '', type: '' },
    settingsResponse: { permission: [], status: { error: false, msg: '' }, metadata: '' },
    settingsAdd: { email: '', firstName: '', lastName: '', type: '', uniqueId: '' },
    settingsAddResponse: { data: { email: '', userid: '', emailNotification: '', emailVerified: '', firstName: '', id: '', lastName: '', uniqueId: '', userAccess: '' }, status: { error: false, msg: '' } },
    settingsRemove: { permission: { email: '', userid: '', emailNotification: '', emailVerified: '', firstName: '', id: '', lastName: '', uniqueId: '', userAccess: '' }, type: '', uniqueId: '' },
    settingsRemoveResponse: { status: { error: false, msg: '' }, uniqueId: '' },
    settingsNotificationChange: { permission: { email: '', userid: '', emailNotification: '', emailVerified: '', firstName: '', id: '', lastName: '', uniqueId: '', userAccess: '' }, type: '', uniqueId: '' },
    settingsNotificationChangeResponse: { status: { error: false, msg: '' }, uniqueId: '' },
    specialityActionRequest: { specialityId: '', type: '', uniqueId: '', userUniqueId: '' },
    specialityActionResponse: { status: { error: false, msg: '' } },
    error: null
};

export const settingsReducer = (state = initialState, action: SettingsActionTypes) => {
    const NewState = { ...state }
    switch (action.type) {
        case SETTINGS.LOAD_ERROR:
            return {
                ...state,
                pending: false,
                error: action.error
            }
        case SETTINGS.LOAD_PENDING:
            return {
                ...state,
                pending: true,
                settings: action.payload
            }
        case SETTINGS.LOAD_SUCCESS:
            return {
                ...state,
                pending: false,
                settingsResponse: action.settings
            }
        case SETTINGS.ADD_ERROR:
            return {
                ...state,
                pending: false,
                error: action.error
            }
        case SETTINGS.ADD_PENDING:
            return {
                ...state,
                pending: true,
                settingsAdd: action.payload
            }
        case SETTINGS.ADD_SUCCESS:
            return {
                ...state,
                pending: false,
                settingsAddResponse: action.settingsAdd,
                settingsResponse: {
                    ...state.settingsResponse,
                    permission: [...state.settingsResponse.permission, action.settingsAdd.data],
                    status: {
                        ...state.settingsResponse.status,
                        error: false
                    }
                }
            }
        case SETTINGS.REMOVE_ERROR:
            return {
                ...state,
                pending: false,
                error: action.error
            }
        case SETTINGS.REMOVE_PENDING:
            return {
                ...state,
                pending: true,
                settingsRemove: action.payload
            }
        case SETTINGS.REMOVE_SUCCESS:
            return {
                ...state,
                pending: false,
                settingsRemoveResponse: action.settingsRemove,
                settingsResponse: {
                    ...state.settingsResponse,
                    permission: state.settingsResponse.permission.filter((value: any, index) => {
                        if (value.uniqueid === action.settingsRemove.uniqueId) { return false; } return true;
                    }),
                    status: {
                        ...state.settingsResponse.status,
                        error: false
                    }
                }
            }
        case SETTINGS.CHANGE_ERROR:
            return {
                ...state,
                pending: false,
                error: action.error
            }
        case SETTINGS.CHANGE_PENDING:
            return {
                ...state,
                pending: true,
                settingsNotificationChange: action.payload
            }
        case SETTINGS.CHANGE_SUCCESS:
            return {
                ...state,
                pending: false,
                settingsNotificationChangeResponse: action.settingsNotification,
                settingsResponse: {
                    ...state.settingsResponse,
                    permission: state.settingsResponse.permission.map((value: any, index) => {
                        if (value.uniqueid === action.settingsNotification.uniqueId) {
                            if (value.emailNotification === "1") {
                                return {
                                    ...value,
                                    emailNotification: "0"
                                }
                            } else {
                                return {
                                    ...value,
                                    emailNotification: "1"
                                }
                            }
                        }
                        return value;
                    }),
                    status: {
                        ...state.settingsResponse.status,
                        error: false
                    }
                }
            }
        case SETTINGS.SPECIALITY_ACTION_ERROR:
            return {
                ...state,
                pending: false,
                error: action.error
            }
        case SETTINGS.SPECIALITY_ACTION_PENDING:
            return {
                ...state,
                pending: true,
                specialityActionRequest: action.payload
            }
        case SETTINGS.SPECIALITY_ACTION_SUCCESS:
            return {
                ...state,
                pending: false,
                specialityActionResponse: action.specialityAction
            }
        case SETTINGS.EMAIL_VERIFICATION_ERROR:
            return {
                ...state,
                pending: false,
                error: action.error
            }
        case SETTINGS.EMAIL_VERIFICATION_PENDING:
            return {
                ...state,
                pending: true,
                emailVerificationRequest: action.payload
            }
        case SETTINGS.EMAIL_VERIFICATION_SUCCESS:
            return {
                ...state,
                pending: false,
                emailVerificationResponse: action.emailVerification
            }
        case SETTINGS.ACCEPT_TC_ERROR:
            return {
                ...state,
                pending: false,
                error: action.error
            }
        case SETTINGS.ACCEPT_TC_PENDING:
            return {
                ...state,
                pending: true,
                acceptTCRequest: action.payload
            }
        case SETTINGS.ACCEPT_TC_SUCCESS:
            return {
                ...state,
                pending: false,
                acceptTCResponse: action.acceptTC,
                settingsResponse: {
                    ...state.settingsResponse,
                    status: {
                        ...state.settingsResponse.status,
                        error: false,
                    },
                    metadata: {
                        ...state.settingsResponse.metadata,
                        clinicTnC: true
                    }
                }
            }
        case SETTINGS.REVIEW_SAVE_ERROR:
            return {
                ...state,
                pending: false,
                error: action.error
            }
        case SETTINGS.REVIEW_SAVE_PENDING:
            return {
                ...state,
                pending: true,
                reviewSaveRequest: action.payload
            }
        case SETTINGS.REVIEW_SAVE_SUCCESS:
            return {
                ...state,
                pending: false,
                reviewSaveResponse: action.reviewSave,
                settingsResponse: {
                    ...state.settingsResponse,
                    status: {
                        ...state.settingsResponse.status,
                        error: false,
                    },
                    metadata: {
                        ...state.settingsResponse.metadata,
                        reviewOn: action.reviewSave.metadata.reviewOn.toString(),
                        trustApiKey: action.reviewSave.metadata.trustApiKey
                    }
                }
            }
            case SETTINGS.ADMIN_SAFETY_FEE_ERROR:
                return {
                    ...state,
                    pending: false,
                    error: action.error
                }
            case SETTINGS.ADMIN_SAFETY_FEE_PENDING:
                return {
                    ...state,
                    pending: true,
                    AdminSafetyFeeRequest: action.payload
                }
            case SETTINGS.ADMIN_SAFETY_FEE_SUCCESS:
                return {
                    ...state,
                    pending: false,
                    AdminSafetyFeeResponse: action.AdminSafetyFeeResponse
                }
        default:
            return NewState;

    }
};