import _ from "lodash";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {  Modal } from "react-bootstrap";
import AsyncSelect from "react-select/async";
import { IAppState } from "../../../store";
import { fetchLoadingPending, fetchLoadingSuccess } from "../../../store/loadingIndicator/actions";
import { convertToSystemDate, convertToSytemDisplayDate } from "../../helpers/datetime";
import { getAppointmentListAnalytics } from "../../services/messageCenter";
import { getPatientList } from "../../services/patientsearch";
import { titleCase } from "../../utils/global";
import {ReportHeader} from "./index";
import { DatePicker } from "@material-ui/pickers";

export const NotificationAndReportDetail = ({
    type,
    onBackClick
}: any) => {
    const [date, setDate]:any = useState("");
    const [week, setWeek] = useState("");
    const [month, setMonth] = useState("");

    const [showCustomFilterDialog, setShowCustomFilterDialog] = useState(false);
    const [patientOptions, setPatientOptions] = useState([]);
    const [selectedDependant, setSelectedDependant] = useState<{label: string, value: string}>(null as any);

    const getStartDate = () => {
        let startDate = convertToSystemDate(moment());
        switch(type) {
            case "notification_count_per_appointment":
                startDate = convertToSystemDate(moment().subtract(2, 'weeks'));
                break;
        }
        return startDate;
    }
    const getEndDate = () => {
        let endDate = convertToSystemDate(moment().add(2, "weeks"));
        switch(type) {
            case "notification_count_per_appointment":
                endDate = convertToSystemDate(moment().add(2, "weeks"));
                break;
        }
        return endDate;
    }
    const [startDate, setStartDate]:any = useState(getStartDate());
    const [endDate, setEndDate]:any = useState(getEndDate());

    const [appointmentList, setAppointmentList]:any = useState([]);

    const clinic: any = useSelector((state: IAppState) => state.userData.userDataResponse.clinics[0]);
    const dispatch = useDispatch();

    let pageTitle = type || "";
    pageTitle = pageTitle.replaceAll("_", " ");
    pageTitle = titleCase(pageTitle);

    const handleDateChange =(date:any) => {
        setDate(date);
        setWeek("");
        setMonth("");
        setStartDate("");
        setEndDate("");
    }

    const handleWeekChange = (date:any, dateString:string) => {
        setWeek(date);
        setDate("");
        setMonth("");
        setStartDate("");
        setEndDate("");
    }

    const handleMonthChange = (date:any, dateString:string) => {
        setMonth(date);
        setDate("");
        setWeek("");
        setStartDate("");
        setEndDate("");
    }

    const generateQuery = () =>{
        let query:any = {
            clinicid:clinic.id,
        }
        if (date) {
            Object.assign(query, {
                startdate:convertToSystemDate(date),
                enddate:convertToSystemDate(date)
            });
        };
        if (week) {
            Object.assign(query, {
                startdate:convertToSystemDate(moment(week)),
                enddate:convertToSystemDate(moment(week).add(6, "day"))
            });
        }
        if (month) {
            Object.assign(query, {
                startdate:convertToSystemDate(moment(month).startOf('month')),
                enddate:convertToSystemDate(moment(month).endOf('month'))
            });
        }
        if (startDate && endDate) {
            Object.assign(query, {
                startdate:convertToSystemDate(startDate),
                enddate:convertToSystemDate(endDate)
            });
        }
        if (selectedDependant) {
            Object.assign(query, {
                dependantid: Number(selectedDependant.value),
            });
        }
        return query;
    }

    useEffect(() => {
        fetchPatientList();
    }, []);

    const fetchPatientList = async (searchText= "") => {
        try {
            let results = await getPatientList({ fullname: searchText });
            results = results.patients.map((item:any) => {
                return {
                    label: item.dependant.fname + " " + item.dependant.lname,
                    value: item.dependant.id
                }
            });
            setPatientOptions(results);
        } catch (err) {
            setPatientOptions([]);
        }
    };

    useEffect(() => {
        onFilter();
    }, [date, week, month, selectedDependant]);

    const onFilter = (queryDefault:any = null) => {
        let query = generateQuery();
        if (queryDefault) query = queryDefault;
        if (!query.startdate || !query.enddate) {
            return;
        }
        if (!query.dependantid) {
            return;
        }
        let _type = "";
        switch(type) {
            case "notification_count_per_appointment":
                _type = "getpatientnotificationcount";
                break;
        }
        dispatch(fetchLoadingPending());
        getAppointmentListAnalytics(query, _type).then((success:any) => {
            if (success.error === false) {
                setAppointmentList(success.data);
            }
            dispatch(fetchLoadingSuccess(false));
        }).catch((err:any) => {
            dispatch(fetchLoadingSuccess(false));
        });
    }

    const handleCustomerFilterApply = () => {
        onFilter();
    }

    const promiseOptions = (inputValue: string) => {
        return new Promise(async (resolve) => {
            let results = await getPatientList({ fullname: inputValue });
            results = results.patients.map((item:any) => {
                return {
                    label: item.dependant.fname + " " + item.dependant.lname,
                    value: item.dependant.id
                }
            })
            resolve(results);
        });
    };

    const handlePatientChange = (newValue:any, action:any) => {
        setSelectedDependant(newValue);
    }

    return (
        <div className="app analytics-report">
            <ReportHeader showBack pageTitle={pageTitle} onBackClick={onBackClick}></ReportHeader>

            <div className="app-body" style={{ paddingTop: 0, marginTop: 20 }}>
            <div style={{
                    position: "sticky",
                    top: 0,
                    zIndex: 99,
                    backgroundColor: "#f7f5f0"
                }}>
                <div className="patient-search mb-2">
                    <AsyncSelect
                        value={selectedDependant}
                        //@ts-ignore
                        loadOptions={promiseOptions}
                        defaultOptions={patientOptions}
                        onChange={handlePatientChange}
                        placeholder={"Type patient name or select from drop down.."}
                    />
                </div>
                <div className="w-100 d-flex justify-content-end">
                    <DatePicker
                        format="MM/DD/YYYY"
                        placeholder="MM/DD/YYYY"
                        value={date && date !== '' ? moment(date).toDate() : null}
                        onChange={handleDateChange}
                    />
                  {/* <DatePicker style={{width: 170}} className="ms-1" value={week ? moment(week) : null} onChange={handleWeekChange} picker={"week"}></DatePicker> */}
                  {/* <DatePicker style={{width: 170}} className="ms-1" value={month ? moment(month) : null} onChange={handleMonthChange} picker={"month"}></DatePicker> */}
                </div>
                <div className="w-100 d-flex justify-content-end align-items-center">
                    <button type="button" className="btn btn-link" onClick={() => {
                        setShowCustomFilterDialog(true);
                    }}>Custom</button>
                    <button type="button" className="btn btn-link" onClick={() => {
                        setDate("");setWeek("");setMonth("");setStartDate(getStartDate());setEndDate(getEndDate());
                        setSelectedDependant(null as any);
                        setAppointmentList([]);
                    }}>Reset</button>
                </div>
                <hr />
            </div>
                {
                    appointmentList.length === 0 &&
                    <>Please search the patient and select the date, week or month to view the notification count data</>
                }
                {
                    appointmentList.map((appointment:any) => {
                        let category = appointment.category || "";
                        category = category.split(",");
                        return (
                            <div key={appointment.appointmentid} className="border-bottom pb-1 notification-and-report">
                                <div>
                                    <div className="appo_card card mb-4">
                                        <div className="appo_status">
                                            <span className="badge ms-2 rounded-pill bg-secondary">{appointment.doctorname}</span>
                                            <span className="badge ms-2 rounded-pill bg-secondary">{convertToSytemDisplayDate(appointment.appointmentdate)} {appointment.appointmentstime} - {appointment.appointmentetime}</span>
                                        </div>
                                        <div className="appo_body">
                                            <h6>{appointment.patientfname +" " + appointment.patientlname}</h6>
                                            <div className="d-flex ms-2">
                                                <div className="box-left">
                                                    {
                                                    appointment.dependantphone &&
                                                    <p className="m-0">
                                                        <a href="javascript:void(0)">{appointment.dependantphone}</a>
                                                    </p>
                                                    }
                                                    {
                                                    appointment.dependantemailaddress &&
                                                    <p className="m-0">
                                                        <a href={"mailto:"+appointment.dependantemailaddress}>{appointment.dependantemailaddress}</a>
                                                    </p>
                                                    }
                                                </div>
                                                <div className="box-right">
                                                    <p className="m-0">Email: {appointment.email}</p>
                                                    <p className="m-0">Sms: {appointment.sms}</p>
                                                    <p className="m-0">App: {appointment.appnotification}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )
                    })
                }
            </div>
            <Modal show={showCustomFilterDialog} onHide={() => {
                    setShowCustomFilterDialog(false);
                    setStartDate("");
                    setEndDate("");
                }}
            >
                <Modal.Header>
                    <div className="modal-title">Custom Date</div>
                    <button
                        type="button"
                        className="btn-close"
                        aria-label="Close"
                        onClick={() => { setShowCustomFilterDialog(false) }}
                    ></button>
                </Modal.Header>
                <div className="date_picker">
                    <div className="input-group">
                        <label className="me-1">Start Date: </label>
                        <DatePicker
                            placeholder="MM/DD/YYYY"
                            format="MM/DD/YYYY"
                            value={startDate && startDate !== '' ? moment(startDate) : null}
                            onChange={(date) => {
                                setStartDate(moment(date));
                            if (moment(date).isAfter(endDate)) {
                                setEndDate(date);
                            }
                            }}
                        />
                    </div>
                    <div className="input-group ms-1">
                        <label className="me-1">End Date: </label>
                        <DatePicker
                            placeholder="MM/DD/YYYY"
                            format="MM/DD/YYYY"
                            value={endDate && endDate !== '' ? moment(endDate) : null}
                            onChange={(date) => {
                                setEndDate(moment(date));
                            }}
                        />
                    </div>
                </div>
                <Modal.Footer>
                    <button
                        className="btn btn-primary"
                        onClick={() => {
                            setWeek("");
                            setDate("");
                            setMonth("");
                            setShowCustomFilterDialog(false);
                            setTimeout(() => {
                                handleCustomerFilterApply();
                            }, 1000);
                        }}
                    >
                        Ok
                    </button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}
