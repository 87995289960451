import React from 'react';
import TermsOfUseNew from '../TermsOfUseNew';
class TermsOfUse extends React.Component {
    constructor(props:any) {
        super(props);
    }
    render() {
        return (
            <>
                
                <div className="policyParent">
                    <div className="container">
                        <div className="row">
                            <div className="col-sm-12">
                                <div className="policy_cnt">
                                    <TermsOfUseNew></TermsOfUseNew>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}
export default TermsOfUse;