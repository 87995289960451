export enum USER_INFO {
    LOAD_PENDING = 'LOAD_PENDING_USER_INFO',
    LOAD_ERROR = 'LOAD_FAILED_USER_INFO',
    LOAD_SUCCESS = 'LOAD_SUCCESS_USER_INFO'
};

export interface UserInfoRequest {
    uniqueId?: number;
    firstName: string;
    lastName: string;
}
export interface Status {
    error: boolean;
    msg: string;
}
export interface UserInfoResponse {
    id: string;
    uniqueId: string;
    firstName: string;
    lastName: string;
    email: string;
    emailVerified: string;
    status: Status
}
export interface UserInfoState {
    pending: boolean;
    userInfo: UserInfoRequest;
    userInfoResponse: UserInfoResponse;
    error: any;
}

export interface FetchUserInfoPendingAction {
    type: USER_INFO.LOAD_PENDING;
    payload: UserInfoRequest
}

export interface FetchUserInfoErrorAction {
    type: USER_INFO.LOAD_ERROR;
    error: any;
}
export interface FetchUserInfoSuccessAction {
    type: USER_INFO.LOAD_SUCCESS;
    userInfo: UserInfoResponse
}

export type UserInfoActionTypes = FetchUserInfoErrorAction | FetchUserInfoPendingAction | FetchUserInfoSuccessAction;