import configs from "../configs/apiConfigs";
import { BODY_TYPE, CommonApiOptions, METHOD_TYPE } from "../constants/api"
import { commonApi } from "../helpers/api";
import { attachHeader } from "../utils/apiHandler";

export const getClinicProfile = (clinicId: any) => {
  const request: CommonApiOptions = {
    url: configs.API_URL + 'clinic/' + clinicId + '/profile/',
    method: METHOD_TYPE.GET,
    apiOptions: {
      headers: attachHeader(true),
      withCredentials: true
    }
  };
  return commonApi(request);
}

export const updateClinicProfile = (updateAttribute: string, clinicId: string, data: any) => {
  const request: CommonApiOptions = {
    url: configs.API_URL + 'clinic/' + clinicId + '/',
    method: METHOD_TYPE.POST,
    body: {
      type: BODY_TYPE.RAW,
      data: {
        type: updateAttribute,
        [`${updateAttribute}`]: data
      }
    },
    apiOptions: {
      headers: attachHeader(true),
      withCredentials: true
    }
  };
  return commonApi(request);
};

export const uploadLogo = (clinicId: string, data: any) => {
  const request: CommonApiOptions = {
    url: configs.API_URL + 'clinic/' + clinicId + '/upload/logo/',
    method: METHOD_TYPE.POST,
    body: {
      type: BODY_TYPE.FORM_DATA,
      data: data
    },
    apiOptions: {
      headers: attachHeader(true),
      withCredentials: true
    }
  };
  return commonApi(request);
};

export const uploadLogo1 = (clinicId: string, data: any) => {
  const request: CommonApiOptions = {
    url: configs.API_URL + 'clinic/' + clinicId + '/upload/logo2/',
    method: METHOD_TYPE.POST,
    body: {
      type: BODY_TYPE.FORM_DATA,
      data: data
    },
    apiOptions: {
      headers: attachHeader(true),
      withCredentials: true
    }
  };
  return commonApi(request);
};

export const uploadBanner = (clinicId: string, data: any) => {
  const request: CommonApiOptions = {
    url: configs.API_URL + 'clinic/' + clinicId + '/upload/banner/',
    method: METHOD_TYPE.POST,
    body: {
      type: BODY_TYPE.FORM_DATA,
      data: data
    },
    apiOptions: {
      headers: attachHeader(true),
      withCredentials: true
    }
  };
  return commonApi(request);
};

export const uploadPhoto = (clinicId: string, data: any) => {
  const request: CommonApiOptions = {
    url: configs.API_URL + 'clinic/' + clinicId + '/upload/photo/',
    method: METHOD_TYPE.POST,
    body: {
      type: BODY_TYPE.FORM_DATA,
      data: data
    },
    apiOptions: {
      headers: attachHeader(true),
      withCredentials: true
    }
  };
  return commonApi(request);
}

export const uploadOfferPhoto = (clinicId: string, data: any) => {
  const request: CommonApiOptions = {
    url: configs.API_URL + 'clinic/' + clinicId + '/upload/offer/',
    method: METHOD_TYPE.POST,
    body: {
      type: BODY_TYPE.FORM_DATA,
      data: data
    },
    apiOptions: {
      headers: attachHeader(true),
      withCredentials: true
    }
  };
  return commonApi(request);
}

export const deleteOfferPhoto = (photoId: any, clinicUniqueId: any) => {
  const request: CommonApiOptions = {
    url: configs.API_URL + 'clinic/' + clinicUniqueId + '/remove/offer/',
    method: METHOD_TYPE.POST,
    body: {
      type: BODY_TYPE.RAW,
      data: {
        id: photoId
      }
    },
    apiOptions: {
      headers: attachHeader(true),
      withCredentials: true
    }
  };
  return commonApi(request);
};

export const listOfferPhoto = (id: any) => {
  const request: CommonApiOptions = {
    url: configs.API_URL + 'clinic/' + id + '/offers/',
    method: METHOD_TYPE.GET,
    apiOptions: {
      headers: attachHeader(true),
      withCredentials: true
    }
  };
  return commonApi(request);
}

export const uploadGotoImage = (clinicId: string, data: any) => {
  const request: CommonApiOptions = {
    url: configs.API_URL + 'clinic/' + clinicId + '/upload/goto/',
    method: METHOD_TYPE.POST,
    body: {
      type: BODY_TYPE.FORM_DATA,
      data: data
    },
    apiOptions: {
      headers: attachHeader(true),
      withCredentials: true
    }
  };
  return commonApi(request);
};