import { takeEvery } from 'redux-saga/effects';
import { put } from 'redux-saga/effects';
import Axios from 'axios';
import { CheckClinicEmailRequest, CheckClinicEmailPendingAction, ADD_CLINIC, CheckClinicNameRequest, CheckClinicNamePendingAction, ClinicAddRequest, ClinicAddPendingAction, CheckClinicPhoneRequest, CheckClinicPhonePendingAction } from './../store/addclinic/types';
import apiConfigs from '../configs/apiConfigs';
import { fetchLoadingPending, fetchLoadingSuccess } from '../store/loadingIndicator/actions';
import { checkClinicEmailError, checkClinicEmailSuccess, checkClinicNameError, checkClinicNameSuccess, clinicAddError, clinicAddSuccess, checkClinicPhoneError, checkClinicPhoneSuccess } from '../store/addclinic/actions';
import { getCookie, prepareCookie } from '../utils/cookies';
function execute(request: CheckClinicEmailRequest) {
    const headers = {
        'Content-Type': 'application/json',
        'x-access-param': getCookie().xprAuthUser,
        'xpr_user_id': getCookie().xpr_user_id,
        'xcookie': prepareCookie()
        // 'X-Auth-Token': '97e0d315477f435489cf04904c9d0e6co',
    };
    return Axios.post(apiConfigs.API_URL + "clinic/valid/", request, { headers: headers, withCredentials: true }).then(response => ({ response }), error => ({ error }));
}
function* pendingRun(action: CheckClinicEmailPendingAction) {
    //    yield delay(2000);
    yield put(fetchLoadingPending());
    const { response, error } = yield execute(action.payload);
    if (response) {
        if (response.status === 200) {
            if (response.data.status.error === true) {
                response.data.type = 'email';
                yield put(checkClinicEmailError(response.data));
            } else {
                yield put(checkClinicEmailSuccess(response.data));
            }
        } else {
            yield put(checkClinicEmailError(response));
        }
    }
    if (error) {
        yield put(checkClinicEmailError(error))
    }
    yield put(fetchLoadingSuccess(false));
}

export function* checkClinicEmailPendingWatcher() {
    yield takeEvery(ADD_CLINIC.CHECK_CLINIC_EMAIL_PENDING, pendingRun);
}

function executeCheckName(request: CheckClinicNameRequest) {
    const headers = {
        'Content-Type': 'application/json',
        'x-access-param': getCookie().xprAuthUser,
        'xpr_user_id': getCookie().xpr_user_id,
        'xcookie': prepareCookie()
        // 'X-Auth-Token': '97e0d315477f435489cf04904c9d0e6co',
    };
    return Axios.post(apiConfigs.API_URL + "clinic/valid/", request, { headers: headers, withCredentials: true }).then(response => ({ response }), error => ({ error }));
}
function* pendingCheckNameRun(action: CheckClinicNamePendingAction) {
    //    yield delay(2000);
    yield put(fetchLoadingPending());
    const { response, error } = yield executeCheckName(action.payload);
    if (response) {
        if (response.status === 200) {
            if (response.data.status.error === true) {
                response.data.type = 'name';
                yield put(checkClinicNameError(response.data));
            } else {
                yield put(checkClinicNameSuccess(response.data));
            }
        } else {
            yield put(checkClinicNameError(response));
        }
    }
    if (error) {
        yield put(checkClinicNameError(error))
    }
    yield put(fetchLoadingSuccess(false));
}

export function* checkClinicNamePendingWatcher() {
    yield takeEvery(ADD_CLINIC.CHECK_CLINIC_NAME_PENDING, pendingCheckNameRun);
}

function executeClinicAdd(request: ClinicAddRequest) {
    const headers = {
        'Content-Type': 'multipart/form-data',
        // 'x-access-param': getCookie().authParam,
        'x-access-param': getCookie().xprAuthUser,
        'xpr_user_id': request.uniqueid,
        'xcookie': prepareCookie()
    };
    const formData = new FormData();
    formData.append('name', request.name);
    formData.append('logo', request.logo);
    formData.append('speciality', request.speciality);
    formData.append('phone', request.phone);
    formData.append('email', request.email);
    formData.append('website', request.website);
    formData.append('hours', JSON.stringify(request.hours));
    request.questionResponse = request.questionResponse.map((value, index) => {
        return { id: value.id, response: value.response };
    });
    formData.append('questionResponse', JSON.stringify(request.questionResponse));
    formData.append('address', JSON.stringify(request.address));
    return Axios.post(apiConfigs.API_URL + "clinic/" + request.uniqueid + "/create/", formData, {
        headers: headers,
        withCredentials: true,
    }).then(response => ({ response }), error => ({ error }));
}
function* pendingClinicAddRun(action: ClinicAddPendingAction) {
    //    yield delay(2000);
    yield put(fetchLoadingPending());
    const { response, error } = yield executeClinicAdd(action.payload);
    if (response) {
        if (response.status === 200) {
            if (response.data.status.error === true) {
                response.data.type = 'add';
                yield put(clinicAddError(response.data));
            } else {
                yield put(clinicAddSuccess(response.data));
            }
        } else {
            yield put(clinicAddError(response));
        }
    }
    if (error) {
        yield put(clinicAddError(error))
    }
    yield put(fetchLoadingSuccess(false));
}

export function* clinicAddPendingWatcher() {
    yield takeEvery(ADD_CLINIC.ADD_CLINIC_PENDING, pendingClinicAddRun);
}

/* Check Phone */
function executePhoneCheck(request: CheckClinicPhoneRequest) {
    const headers = {
        'Content-Type': 'application/json',
        'x-access-param': getCookie().xprAuthUser,
        'xpr_user_id': getCookie().xpr_user_id,
        'xcookie': prepareCookie()
        // 'X-Auth-Token': '97e0d315477f435489cf04904c9d0e6co',
    };
    return Axios.post(apiConfigs.API_URL + "clinic/valid/", request, { headers: headers, withCredentials: true }).then(response => ({ response }), error => ({ error }));
}
function* pendingRunPhone(action: CheckClinicPhonePendingAction) {
    //    yield delay(2000);
    yield put(fetchLoadingPending());
    const { response, error } = yield executePhoneCheck(action.payload);
    if (response) {
        if (response.status === 200) {
            if (response.data.status.error === true) {
                response.data.type = 'phone';
                yield put(checkClinicPhoneError(response.data));
            } else {
                yield put(checkClinicPhoneSuccess(response.data));
            }
        } else {
            yield put(checkClinicPhoneError(response));
        }
    }
    if (error) {
        yield put(checkClinicPhoneError(error))
    }
    yield put(fetchLoadingSuccess(false));
}

export function* checkClinicPhonePendingWatcher() {
    yield takeEvery(ADD_CLINIC.CHECK_CLINIC_PHONE_PENDING, pendingRunPhone);
}