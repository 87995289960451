import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { getPatientList } from '../../services/appointment';
import { createGuarantor } from '../../services/patientsearch';
import { toast } from 'react-toastify';
export const ChangeGuarantorInfoModal = ({ hideModal, selectedPatient, userData }: any) => {
    const [loadingSearch, setLoadingSearch] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [guarantorList, setGuarantorList]: any = useState([]);
    const { register, errors, setError, getValues, setValue, watch, control, handleSubmit } = useForm({
        defaultValues: {
            patientname: '',
            guarantorselect: ''
        }
    });
    const getPatietListOnSearch = () => {
        setLoadingSearch(true);
        if (getValues('patientname').trim() !== '') {
            let data = {};
            Object.assign(data, { fullname: getValues('patientname') });
            getPatientList(data).then((success) => {
                setLoadingSearch(false);
                if (success && success.status && success.status.error === false) {
                    setGuarantorList(success.patients.map((item: any) => {
                        return {
                            id: item.dependant.id,
                            fname: item.dependant.fname,
                            lname: item.dependant.lname,
                            email: item.dependant.email,
                        }
                    }));
                }
            }).catch((err) => {
                setLoadingSearch(false);
            })
        }
    }
    useEffect(() => {
        if (guarantorList[0] && guarantorList[0].id) {
            setValue('guarantorselect', guarantorList[0].id);
        }
    }, [guarantorList]);

    const handleSubmitBtn = () => {
        setIsLoading(true);
        let data = {
            guarantorId: getValues('guarantorselect'),
          };
          let clinicId = userData.clinics[0].uniqueId;
          let dependantId = selectedPatient.id;
          createGuarantor(data, clinicId, dependantId, true)
            .then((success) => {
                setIsLoading(false);
              if (success && success.status && success.status.error === false) {
                toast.success(success.status.msg);
                hideModal(true);
                return;
              }
              toast.error("Change guarantor failed");
            })
            .catch((err) => {
                setIsLoading(false);
              if (err && err.error === true) {
                toast.error(err.msg);
                return;
              }
              toast.error("Change guarantor failed");
              console.log(err);
            });
    }
    return (
        <Modal className="newcommon addnewappt" centered show={true} backdrop={true} onHide={() => { hideModal() }}>
            <Modal.Header>
                <div className="modal-title">Change guarantor</div>
                <button type="button" className="btn-close" aria-label="Close" onClick={() => { hideModal() }}></button>
            </Modal.Header>
            <Modal.Body>
                <div className="row mb-3">
                    <label className="form-label">Search guarantor</label>
                    <div className="col">
                        <input type="text" placeholder="patient name , email or mobile #" className="form-control" name="patientname" ref={register} />
                    </div>
                    <div className="col-auto">
                        <button className="btn btn-outline-primary" disabled={loadingSearch} onClick={() => {
                            getPatietListOnSearch();
                        }}>
                            {
                                loadingSearch &&
                                <span className="spinner-border spinner-border-sm me-1" role="status" aria-hidden="true"></span>
                            }Search</button>
                    </div>
                    <div className="form-text"><i className="bi bi-info-circle"></i> Email & mobile # should be registered on XCare</div>
                </div>
                {
                    <>

                        {
                            guarantorList.length !== 0 &&
                            <div className="row mb-3">
                                <div className="col">
                                    <label className="form-label">Select Guarantor:</label>
                                    <select className="form-select" name="guarantorselect" ref={register}>
                                        {
                                            guarantorList.map((item: any) => {
                                                return (<option key={item.id} value={item.id}>{item.fname + " " + item.lname + " - " + item.email}</option>)
                                            })
                                        }
                                    </select>
                                </div>
                            </div>
                        }
                    </>
                }
            </Modal.Body>

            <Modal.Footer>
                <button className="btn btn-outline-secondary" onClick={() => {
                    setValue('guarantorselect', '');
                }}>Reset</button>
                <button disabled={isLoading} className={"btn btn-primary"} type="button" onClick={handleSubmitBtn}>Submit</button>
            </Modal.Footer>
        </Modal>
    )
}