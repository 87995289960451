import React from 'react';
import Helmet from 'react-helmet';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { IAppState } from '../../store';
import { fetchBillingPendingAction } from '../../store/billing/actions';
import { BillingRequest, BillingResponse, BillingState } from '../../store/billing/types';
import HeaderBilling from '../billing/headerBilling/HeaderBilling';
import BillingSettings from '../billing/settings/BillingSettings';
import LoadingIndicator from '../LoadingIndicator/LoadingIndicator';
interface Props {
  billingState: BillingState;
  fetchBilling: typeof fetchBillingPendingAction;
  match: any;
  location: any;
  history: any;
}
interface State {
  billingResponse?: BillingResponse;
  isLoading: boolean;
}
class MyCards extends React.Component<Props, State> {
  constructor(props: any) {
    super(props);
    this.state = {
      isLoading: false,
    }
  }
  componentDidMount() {
    this.setState({ isLoading: true });
    this.props.fetchBilling({});
  }
  shouldComponentUpdate(nextProps: Props, nextState: State) {
    if (this.props !== nextProps) {
      if (this.props.billingState !== nextProps.billingState) {
        if (nextProps.billingState.error !== null) {
          if (nextProps.billingState.billingResponse && nextProps.billingState.billingResponse.status
            && nextProps.billingState.billingResponse.status.error === true
            && nextProps.billingState.billingResponse.status.msg !== '') {
            toast.error(nextProps.billingState.billingResponse.status.msg);
          } else {
            toast.error('Something went wrong');
          }
        } else if (nextProps.billingState.billingResponse && nextProps.billingState.pending === false &&
          nextProps.billingState.billingResponse.status.error === false) {
          this.setState({ billingResponse: nextProps.billingState.billingResponse, isLoading: false });
        }
      }
    }
    return true;
  }
  componentDidUpdate() {
    this.props.billingState.error = null;
    if (this.props.billingState && this.props.billingState.billingResponse) {
      this.props.billingState.billingResponse.status.error = true;
      this.props.billingState.billingResponse.status.msg = '';
    }
  }
  render() {
    return (
      <>
        {
          (this.props.billingState.pending === true || this.state.isLoading === true) &&
          <LoadingIndicator></LoadingIndicator>
        }
        <Helmet><title>My Cards</title></Helmet>
        {
          (this.state.billingResponse) &&
          <BillingSettings {...this.props}></BillingSettings>
        }
      </>
    );
  }
}
const mapStateToProps = (state: IAppState) => ({
  billingState: state.billing,
});
const mapDispatchToProps = (dispatch: any) => ({
  fetchBilling: (request: BillingRequest) => {
    dispatch(fetchBillingPendingAction(request));
  },
});
export default connect(mapStateToProps, mapDispatchToProps)(MyCards);