import config from "../configs/apiConfigs";
import { BODY_TYPE, CommonApiOptions, METHOD_TYPE } from "../constants/api";
import { commonApi } from "../helpers/api";
import { attachHeader } from "../utils/apiHandler";

export const reviewEdit = (body: any, clinicUniqueId: any) => {
    const request: CommonApiOptions = {
        url: config.API_URL + 'clinic/' + clinicUniqueId + '/review/',
        method: METHOD_TYPE.POST,
        body: {
            type: BODY_TYPE.RAW,
            data: body
        },
        apiOptions: {
            headers: attachHeader(true),
            withCredentials: true
        }
    };
    return commonApi(request);
}

export const emailVerification = (body: any, modelUniqueid: any,modelType:any) => {
    const request: CommonApiOptions = {
        url: config.API_URL + 'verify/' + modelType + '/'+modelUniqueid+'/',
        method: METHOD_TYPE.POST,
        body: {
            type: BODY_TYPE.RAW,
            data: body
        },
        apiOptions: {
            headers: attachHeader(true),
            withCredentials: true
        }
    };
    return commonApi(request);
}

export const notificationChangeSettings = (type: any, uniqueId: any,permission:any) => {
    const request: CommonApiOptions = {
        url: config.API_URL + type + "/" + uniqueId + "/setting/change/",
        method: METHOD_TYPE.POST,
        body: {
            type: BODY_TYPE.RAW,
            data: permission
        },
        apiOptions: {
            headers: attachHeader(true),
            withCredentials: true
        }
    };
    return commonApi(request);
}

export const addSettings = (type: any, uniqueId: any,body:any) => {
    const request: CommonApiOptions = {
        url: config.API_URL + type + "/" + uniqueId + "/setting/add/",
        method: METHOD_TYPE.POST,
        body: {
            type: BODY_TYPE.RAW,
            data: body
        },
        apiOptions: {
            headers: attachHeader(true),
            withCredentials: true
        }
    };
    return commonApi(request);
}
export const removeSettings = (type: any, uniqueId: any,body:any) => {
    const request: CommonApiOptions = {
        url: config.API_URL + type + "/" + uniqueId + "/setting/remove/",
        method: METHOD_TYPE.POST,
        body: {
            type: BODY_TYPE.RAW,
            data: body
        },
        apiOptions: {
            headers: attachHeader(true),
            withCredentials: true
        }
    };
    return commonApi(request);
}