import React from 'react';
import 'firebase/firestore';
import _ from 'lodash';
import { Redirect, Route, Switch } from 'react-router-dom';
import { PatientsChat } from './patients/PatientsChat';
import { ProvidersChat } from './providers/ProvidersChat';
import { GroupChat } from './group/GroupChat';

export const ChatList = () => {
    return (
        <Switch>
            <Route exact path="/chats/patient" component={PatientsChat}></Route>
            <Route exact path="/chats/provider" component={ProvidersChat}></Route>
            <Route exact path="/chats/group" component={GroupChat}></Route>
            <Redirect to="/chats/patient"></Redirect>
        </Switch>
    )
}
