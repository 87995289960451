import firebase from 'firebase';
import moment from 'moment';
import React, { useEffect } from 'react';
import { useState } from 'react';
import nl2br from 'react-nl2br';
import configs from '../../../configs/apiConfigs';
import { USER_TYPE } from '../../../constants/constants';
import { chatNotificationProvider } from '../../../services/chat';
import { AvatarComp } from '../Avatar';

let groupMessageListener: any;
let db = firebase.firestore();

export const Messages = (props: any) => {
    const { selectedGroup } = props;
    const [messageList, setMessageList] = useState([]);
    const [message, setMessage] = useState('');
    const [groupDoc, setGroupDoc]: any = useState(null);
    useEffect(() => {
        if (selectedGroup && selectedGroup) {
            groupMessage();
            getGroupDoc();
        }
    }, [selectedGroup]);

    const groupMessage = () => {
        groupMessageListener = db.collection(configs.MESSAGE_COLLECTION).doc(selectedGroup.groupId).onSnapshot((snapshot: any) => {
            const data = snapshot.data();
            console.log('---Messages---', data);
            if (data && data.messages && Array.isArray(data.messages)) {
                setMessageList(data?.messages || []);
                updateViewBy();
            }
        });
    }

    const updateViewBy = async () => {
        let clinicDoc = await firebase.firestore().collection(configs.USER_COLLECTION).where('uniqueId', '==', selectedGroup.clinic.uniqueid).get();
        let clinicFirebaseId = clinicDoc.docs.map((doc) => ({ ...doc.data, id: doc.id }))[0].id;
        if (clinicFirebaseId) {
            const docRef = await db.collection(configs.MESSAGE_COLLECTION).doc(selectedGroup.groupId);
            const snapshot = await docRef.get();
            const data = snapshot.data();
            let messages = [];
            if (data && Array.isArray(data.messages)) {
                messages = data.messages.map((item) => {
                    if (!item.readBy.includes(clinicFirebaseId)) {
                        return {
                            ...item,
                            readBy: [...item.readBy, clinicFirebaseId]
                        }
                    } else {
                        return item;
                    }
                })
            }
            docRef.update('messages', messages);
        }
    }
    console.log('MessagesList', messageList, selectedGroup);

    const getGroupDoc = () => {
        firebase.firestore().collection(configs.GROUP_COLLECTION).doc(selectedGroup.groupId).onSnapshot((snapshot) => {
            const data = snapshot.data();
            setGroupDoc(data);
        })
    }

    const sendMessage = async () => {
        if (message.trim() !== '') {
            const docRef = firebase.firestore().collection(configs.MESSAGE_COLLECTION).doc(`${selectedGroup.groupId}`);
            let clinicDoc = await firebase.firestore().collection(configs.USER_COLLECTION).where('uniqueId', '==', selectedGroup.clinic.uniqueid).get();
            let clinicFirebaseId = clinicDoc.docs.map((doc) => ({ ...doc.data, id: doc.id }))[0].id;
            docRef.update('messages', firebase.firestore.FieldValue.arrayUnion({
                messageText: message,
                senderType: USER_TYPE.CLINIC,
                sentBy: clinicFirebaseId,
                sentAt: new Date(),
                readBy: [],
                isInfo: false
            }));
            if (!(selectedGroup && selectedGroup.requestAcceptedByProvider && selectedGroup.requestAcceptedByProvider.accepted === false)) {
                chatNotificationProvider(selectedGroup?.doctor?.uniqueid, {
                    title: "New message from " + selectedGroup?.clinic?.name,
                    body: message,
                    notifications: {
                        appointmentmentUniqueId: selectedGroup?.uniqueid,
                        groupDocId: selectedGroup?.groupId
                    }
                }).then((success) => { }).catch((err) => { });
            }
            setMessage('');
        }
    }
    console.log('---groupDoc---', groupDoc);
    if (selectedGroup && selectedGroup) {
        return (
            <div className={"col-9 border-end position-relative "}>
                <>
                    <div className="position-absolute top-0 start-0 border-bottom w-100">
                        <div className="d-flex align-items-center chat-cnt-header px-3">
                            <AvatarComp name={selectedGroup.name}></AvatarComp>
                            <div className="flex-grow-1 chat-user-details">
                                <div className="fw-medium">{selectedGroup.name}</div>
                            </div>
                        </div>
                    </div>
                    <div className="position-relative">
                        <div className="chatbox-sc" id="chat-box">
                            <div>
                                {
                                    moment(selectedGroup.date, 'YYYY-MM-DD').format('MMM DD, YYYY') + " (" + selectedGroup.time + " - " + selectedGroup.endtime + ")"
                                }
                            </div>
                            <div>
                                {
                                    `${selectedGroup.dependant.fname + " " + selectedGroup.dependant.lname + " - " + selectedGroup.appointmentType} with ${selectedGroup.doctor.fullname}`
                                }
                            </div>
                            <div className="alert alert-light border py-2 text-center">
                                <label className="group-chat-label">Three way chat activated, patient and provider can see chat now.</label>
                            </div>
                            <div className="chat-messages p-4">
                                {
                                    messageList.map((message: any, index: number) => {
                                        if (message.isInfo === true) return null;
                                        return (
                                            <React.Fragment key={"messageprovider" + index}>
                                                {
                                                    message.senderType === USER_TYPE.CLINIC &&
                                                    <div className="chat-message-right cadmin pb-4">
                                                        <div className="chatbu rounded py-2 px-3 bg-light">
                                                            {nl2br(message.messageText)}
                                                        </div>
                                                        <div className="text-muted text-end small text-nowrap">
                                                            {
                                                                message &&
                                                                message.sentAt &&
                                                                <> {moment(moment.unix(message.sentAt.seconds)).format("MMM DD, YYYY - LT")}</>
                                                            }
                                                        </div>
                                                    </div>
                                                }
                                                {
                                                    message.senderType !== USER_TYPE.CLINIC &&
                                                    <div className="chat-message-left cuser pb-4">
                                                        <div className="chatbu rounded py-2 px-3 bg-primary text-white">
                                                            {nl2br(message.messageText)}
                                                        </div>
                                                        <div className="text-muted text-start small text-nowrap">
                                                            {
                                                                message &&
                                                                message.sentAt &&
                                                                <> {moment(moment.unix(message.sentAt.seconds)).format("MMM DD, YYYY - LT")}</>
                                                            }
                                                        </div>
                                                    </div>
                                                }
                                            </React.Fragment>
                                        )
                                    })
                                }
                                {
                                    groupDoc && groupDoc.requestAcceptedByProvider && groupDoc.requestAcceptedByProvider.accepted === false &&
                                    <div className="alert alert-info py-2 text-center">
                                        Provider has rejected the request - <span>{moment(moment.unix(groupDoc.requestAcceptedByProvider.updatedAt.seconds)).format("MMM DD, YYYY - LT")}</span>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </>
                <div className="position-absolute bottom-0 start-0 border-top w-100">
                    <div className="d-flex align-items-center ch-write-sec px-3">
                        <div className="input-group">
                            <input type="text" className="form-control" placeholder="Type your message" value={message} onChange={(e) => {
                                if (e.target.value.length > 160) {
                                    return;
                                }
                                setMessage(e.target.value);
                            }} />
                            <button className="btn btn-primary" disabled={false}
                                onClick={(e: any) => {
                                    sendMessage();
                                }}
                            >Send</button>
                        </div>
                    </div>
                </div>
            </div>
        );
    } else {
        return null;
    }
}