import { fetchLoadingPending, fetchLoadingSuccess } from './../store/loadingIndicator/actions';
import { takeEvery, put } from "redux-saga/effects";
import axios from 'axios';
import CONFIG from '../configs/apiConfigs';
import { USER_EMAIL, FetchUserEmailPendingAction, UserEmailRequest } from "../store/userEmail/types";
import { fetchUserEmailError, fetchUserEmailSuccess } from "../store/userEmail/actions";
import { attachHeader } from "../utils/apiHandler";
import { emailChangeAction } from '../store/userData/actions';

function execute(userEmailRequest: UserEmailRequest) {
    const body = {
        email: userEmailRequest.email
    };
    return axios.post(CONFIG.API_URL + "user/" + userEmailRequest.uniqueId + "/", body, {
        headers: attachHeader(true),
        withCredentials: true
    }).then(response => ({ response }), error => ({ error }));
}
function* userEmailPendingRun(action: FetchUserEmailPendingAction) {
    //    yield delay(2000);
    yield put(fetchLoadingPending());
    const { response, error } = yield execute(action.payload);
    if (response) {
        if (response.status === 200 && response.data.status.error === false) {
            yield put(fetchUserEmailSuccess(response.data));
            yield put(emailChangeAction(action.payload.email));
        } else {
            yield put(fetchUserEmailError(response.data));
        }
    }
    if (error) {
        yield put(fetchUserEmailError(error))
    }
    yield put(fetchLoadingSuccess(false));
}

export default function* userEmailPendingWatcher() {
    yield takeEvery(USER_EMAIL.LOAD_PENDING, userEmailPendingRun);
}