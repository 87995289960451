import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
class HeaderBilling extends Component {
    render() {
        return (
            <>
                <div className="btn-group mb-4">
                    <ul className="nav nav-tabs sbptab">
                        <li className="nav-item">
                            <NavLink exact={true} to={"/user/billing/appointments"} >Appointments</NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink exact={true} to={"/user/billing/invoices"} >Invoices</NavLink>
                        </li>
                        {/* <li className="nav-item">
                            <NavLink exact={true} to={"/user/billing/settings"}>My Packages</NavLink>
                        </li> */}

                    </ul>
                </div>
            </>
            // <div className="page-header no-gutters has-tab with_tab">
            //     <h2 className="font-weight-normal">My Account</h2>
            //     <ul className="nav nav-tabs sbptab">
            //         <li className="nav-item">
            //             <NavLink exact={true} to={"/user/plans"} >Plans</NavLink>
            //             {/* <a className="nav-link active" href="#">Plans</a> */}
            //         </li>
            //         <li className="nav-item">
            //             <NavLink exact={true} to={"/user/myplans"} >My Plans</NavLink>
            //             {/* <a className="nav-link active" href="#">Plans</a> */}
            //         </li>
            //         <li className="nav-item">
            //             <NavLink exact={true} to={"/user/appointments"} >Billing</NavLink>
            //             {/* <a className="nav-link" href="#">Edit Account</a> */}
            //         </li>
            //         <li className="nav-item">
            //             <NavLink exact={true} to={"/user/account"} >Edit Accounts</NavLink>
            //             {/* <a className="nav-link" href="#">Edit Account</a> */}
            //         </li>

            //         <li className="nav-item flex-grow-1 text-right">
            //             <a href="/"><i className="fas fa-arrow-alt-circle-left"></i> Back to Dashboard</a>
            //         </li>
            //     </ul>
            // </div>

        )
    }
}

export default HeaderBilling;