import React from 'react'
import { Alert, Dropdown, OverlayTrigger, Tooltip } from 'react-bootstrap';
export const FilterIcons = ({handleIsDateChange,handleIsCustomDateChange}:any) => {
  return (
    <div className="tool-box">
                    <OverlayTrigger
                        placement="auto"
                        overlay={
                            <Tooltip id="tooltip">
                                Filter
                            </Tooltip>
                        }
                    >
                        <i className="bi bi-calendar fs-6 mx-2" onClick={() => { handleIsDateChange() }}></i>
                    </OverlayTrigger>
                    <OverlayTrigger
                        placement="auto"
                        overlay={
                            <Tooltip id="tooltip">
                                Custom
                            </Tooltip>
                        }
                    >
                         <i className="bi bi-calendar-week fs-6" onClick={() => { handleIsCustomDateChange() }}></i>
                    </OverlayTrigger>
                   
                </div>
  )
}
