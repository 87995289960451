import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { updateClinicProfile } from '../../../services/clinicProfile';
export const AppointmentTypesHours = ({ masterAppointmentTypesList, potentialAppointmentTypes, clinicId, fetchProfile, specialities }: any) => {
    const [appointmentTypes, setAppointmentTypes] = useState([]);
    const [selectedEditRow, setSelectedEditRow]: any = useState(null);
    const [isEdit, setIsEdit] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const { register, errors, getValues, setValue, setError, watch, handleSubmit } = useForm({
        defaultValues: {
            speciality: '',
            appointmenttype: '',
            duration: '',
            editDuration: ''
        }
    });
    useEffect(() => {
        if (specialities && specialities[0] && specialities[0].id) {
            setValue('speciality', specialities[0].id);
        } else {
            setValue('speciality', '');
        }
    }, [specialities]);
    useEffect(() => {
        if (getValues('speciality') !== '' && masterAppointmentTypesList.length > 0) {
            const speciality = getValues('speciality');
            const filtered = masterAppointmentTypesList.filter((item: any) => item.speciality === speciality);
            if (filtered && filtered[0] && filtered[0].id) {
                setAppointmentTypes(filtered);
                setValue('appointmenttype', filtered[0].id);
                setError('appointmenttype', {});
            } else {
                setAppointmentTypes([]);
                setValue('appointmenttype', '');
            }
        } else {
            setAppointmentTypes([]);
            setValue('appointmenttype', '');
        }
    }, [watch('speciality'), masterAppointmentTypesList]);
    const onSubmit = (data: any) => {
        addAppointmentType();
    };
    const updateAppointmentType = (item: any) => {
        setIsLoading(true);
        updateClinicProfile('potentialAppointment', clinicId, {
            mode: "edit",
            id: item.id,
            duration: getValues('editDuration'),
        }).then((success) => {
            fetchProfile('appointmenttype').then((success: any) => {
                setValue('duration', '');
                setSelectedEditRow(null);
                setIsLoading(false);
            }).catch((err: any) => {
                setIsLoading(false);
            });
        }).catch((err) => {
            console.log(err);
        });
    };
    const removeAppointmentType = (item: any) => {
        setIsLoading(true);
        updateClinicProfile('potentialAppointment', clinicId, {
            mode: 'remove',
            id: item.id,
        }).then((success) => {
            fetchProfile('appointmenttype').then((success: any) => {
                setValue('duration', '');
                setIsLoading(false);
            }).catch((err: any) => {
                setIsLoading(false);
            });;
        }).catch((err) => {
            console.log(err);
            setIsLoading(false);
        });
    };
    const addAppointmentType = () => {
        setIsLoading(true);
        updateClinicProfile('potentialAppointment', clinicId, {
            mode: 'add',
            id: getValues('appointmenttype'),
            duration: getValues('duration')
        }).then((success) => {
            fetchProfile('appointmenttype').then((success: any) => {
                setValue('duration', '');
                setIsLoading(false);
            }).catch((err: any) => {
                setIsLoading(false);
            });
        }).catch((err) => {
            setIsLoading(false);
            if (err && err.msg !== "") {
                setError('speciality', { type: 'exists', message: err.msg });
            }
            console.log(err);
        });
    }
    useEffect(() => {
        if (selectedEditRow) {
            setValue('editDuration', selectedEditRow.duration);
        }
    }, [selectedEditRow]);
    const onCancel = () => {
        setIsEdit(false);
        setSelectedEditRow(null);
        setError('speciality', {});
    }
    return (
        <div className="aptype-duration-sec mb-4">
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className="card">
                    <div className="card-header bg-light py-3 px-4">
                        <div className="d-flex justify-content-between">
                            <div className="fw-medium">Appointment types and duration</div>
                            {
                                !isEdit &&
                                <button className="btn p-0 btn-link" onClick={(e) => {
                                    setIsEdit(true);
                                }}>Edit</button>
                            }
                            {
                                isEdit &&
                                <button className="btn p-0 btn-link me-2 text-secondary" onClick={(e) => { onCancel() }}><i className="bi bi-x-circle-fill text-danger"></i></button>
                            }
                        </div>
                    </div>
                    <div className="card-body p-4">

                        <div className="advances-table">
                            <table className="table table-hover">
                                <thead>
                                    <tr>
                                        <th style={{ width: "30%" }}>Speciality</th>
                                        <th style={{ width: "40%" }}>Appointment type</th>
                                        <th style={{ width: "15%" }}>Duration</th>
                                        {
                                            isEdit &&
                                            <th style={{ width: "15%" }} className="text-end">Action</th>
                                        }
                                    </tr>
                                </thead>

                                <tbody>
                                    {
                                        potentialAppointmentTypes.map((item: any, index: number) => {
                                            return (
                                                <tr key={item.id + "appointmentTypeHour"}>
                                                    <td>{item.speciality}</td>
                                                    <td>{item.name}</td>
                                                    {
                                                        (selectedEditRow && selectedEditRow.id === item.id) &&
                                                        <td>
                                                            <input type="text" className="form-control" id="durationmin" placeholder="30" name="editDuration"
                                                                ref={register({
                                                                    required: true,
                                                                    pattern: /^[0-9]+$/
                                                                })} />
                                                            {
                                                                errors && errors.editDuration && errors.editDuration.type === 'required' &&
                                                                <div className="is-invalid">Required</div>
                                                            }
                                                            {
                                                                errors && errors.editDuration && errors.editDuration.type === 'pattern' &&
                                                                <div className="is-invalid">Should be number</div>
                                                            }
                                                        </td>
                                                    }
                                                    {
                                                        !(selectedEditRow && selectedEditRow.id === item.id) &&
                                                        <td>
                                                            {item.duration}
                                                        </td>
                                                    }
                                                    {
                                                        isEdit &&
                                                        <td className="text-end">
                                                            {/* Cancel */}
                                                            {
                                                                (selectedEditRow && selectedEditRow.id === item.id) &&
                                                                <button type="button" className="btn p-1" onClick={(e) => {
                                                                    setSelectedEditRow(null);
                                                                }}>
                                                                    <i className="bi bi-x-circle-fill text-danger"></i>
                                                                </button>
                                                            }
                                                            {/* Save */}
                                                            {
                                                                (selectedEditRow && selectedEditRow.id === item.id) &&
                                                                <button disabled={isLoading} type="button" className="btn p-1" onClick={(e) => {
                                                                    updateAppointmentType(item);
                                                                }}>
                                                                    <i className="bi bi-check-circle-fill text-success"></i>
                                                                </button>
                                                            }
                                                            {/* Edit */}
                                                            {
                                                                !(selectedEditRow && selectedEditRow.id === item.id) &&
                                                                <button type="button" className="btn p-1" onClick={(e) => {
                                                                    setSelectedEditRow(item);
                                                                }}>
                                                                    <i className="bi bi-pencil"></i>
                                                                </button>
                                                            }
                                                            {
                                                                !(selectedEditRow && selectedEditRow.id === item.id) &&
                                                                <button disabled={isLoading} type="button" className="btn p-1"
                                                                    onClick={(e) => {
                                                                        removeAppointmentType(item);
                                                                    }}
                                                                ><i className="bi bi-trash-fill text-danger"></i></button>
                                                            }
                                                        </td>
                                                    }
                                                </tr>
                                            )
                                        })
                                    }
                                </tbody>
                            </table>
                        </div>

                        <div className={"addaptduetab " + (isEdit ? '' : 'd-none')}>
                            <table className="table">
                                <tr>
                                    <td style={{ width: "30%" }}>
                                        <div className="ps-0">
                                            <select className="form-select" id="sptype" aria-label="sptype" name="speciality" ref={register({ required: true })}>
                                                {
                                                    specialities.map((item: any) => {
                                                        return (
                                                            <option value={item.id} key={item.id + "speciality"}>{item.name}</option>
                                                        )
                                                    })
                                                }
                                            </select>
                                            {
                                                errors && errors.speciality && errors.speciality.type === 'required' &&
                                                <div className="is-invalid">Required</div>
                                            }
                                        </div>
                                    </td>
                                    <td style={{ width: "40%" }}>
                                        <div>
                                            <select className="form-select" id="aptype" aria-label="aptype" name="appointmenttype" ref={register({ required: true })}>
                                                {
                                                    appointmentTypes.map((item: any) => {
                                                        return (
                                                            <option value={item.id} key={item.id + "appointmenttype"}>{item.name}</option>
                                                        )
                                                    })
                                                }
                                            </select>
                                            {
                                                errors && errors.appointmenttype && errors.appointmenttype.type === 'required' &&
                                                <div className="is-invalid">Required</div>
                                            }
                                        </div>
                                    </td>
                                    <td style={{ width: "15%" }}>
                                        <div>
                                            <input type="text" className="form-control" id="durationmin" placeholder="30" name="duration" ref={register({
                                                required: true,
                                                pattern: /^[0-9]+$/
                                            })} />
                                            {
                                                errors && errors.duration && errors.duration.type === 'required' &&
                                                <div className="is-invalid">Required</div>
                                            }
                                            {
                                                errors && errors.duration && errors.duration.type === 'pattern' &&
                                                <div className="is-invalid">Should be number</div>
                                            }
                                        </div>
                                    </td>
                                    <td style={{ width: "15%" }} className="text-end">
                                        <button disabled={isLoading} type="submit" className="btn btn-outline-primary h-100 px-4">+</button>
                                    </td>
                                </tr>
                            </table>
                            {
                                (errors && errors.speciality && errors.speciality.type === "exists") &&
                                <div className="is-invalid">{errors.speciality.message}</div>
                            }
                        </div>
                    </div>
                </div>
            </form>
        </div >
    );
}