import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import { Modal, OverlayTrigger, Tooltip } from "react-bootstrap";
import { confirmAlert } from "react-confirm-alert";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { IAppState } from "../../../store";
import configs from "../../configs/apiConfigs";
import { deleteDocuments, getDocuments, uploadDocuments } from "../../services/documents";
import { attachHeader } from "../../utils/apiHandler";
export const DocumentUpload = ({
    hideModal,
    data,
    id,
    type
}: any) => {
    const clinic: any = useSelector((state: IAppState) => state.userData.userDataResponse.clinics[0]);
    const [documentList, setDocumentList] = useState([]);
    const [_hideModal, setHideModal] = useState(false);
    const uploadRef = useRef(null);
    console.log("data", data);
    useEffect(() => {
        _getDocuments();
    }, []);

    const _getDocuments = () => {
        getDocuments(clinic.uniqueId, type, id).then((success) => {
            setDocumentList(success?.documents);
        }).catch((error) => {
            console.log(error);
            toast.error("Documents get failed.");
        })
    };

    const handleFileChange = (e: any) => {
        const file = e.target.files[0];
        uploadDocuments(clinic.uniqueId, {
            type: "upload",
            "uphoto[]": file,
            patientId: type === "appointment" ? data?.dependant?.id : undefined,
            [type === "patient" ? "patientId" : "appointmentId"]: id,
        }).then((success) => {
            //@ts-ignore
            uploadRef.current.value = null;
            if (!success?.status?.error) {
                toast.success("Document uploaded successfully.");
                _getDocuments();
            } else {
                toast.error("Document upload failed.");
            }
        }).catch((error) => {
            console.log(error);
            toast.error("Document upload failed.");
        })
    }

    const handleDelete = (id: string) => {
        let title = "Please confirm, if you want to remove document?";
        setHideModal(true);
        confirmAlert({
            title: '',
            message: title,
            buttons: [
                {
                    label: 'Yes',
                    className: 'btn btn-outline-primary',
                    onClick: () => {
                        setHideModal(false);
                        deleteDocuments(clinic?.uniqueId, id).then((success) => {
                            if (!success?.status?.error) {
                                toast.success("Document deleted successfully.");
                                _getDocuments();
                            } else {
                                toast.error("Document delete failed.");
                            }
                        }).catch((err) => {
                            console.log(err);
                            toast.error("Document delete failed.");
                        })
                    }
                },
                {
                    label: 'No',
                    className: 'btn btn-outline-primary',
                    onClick: () => {
                        setHideModal(false);
                    }
                }
            ]
        });
    }

    const handleDownload = (item: any) => {
        axios({
            url: configs.API_URL + "clinic/" + clinic.uniqueId + "/documentlist/?download=" + item.id, //your url
            method: 'GET',
            headers: attachHeader(true),
            responseType: 'blob', // important
        }).then((response) => {
            console.log(response.headers);
            let filename = response.headers['content-disposition'].split("filename=")[1].replaceAll("\"", '');;
            // create file link in browser's memory
            const href = URL.createObjectURL(response.data);

            // create "a" HTML element with href to file & click
            const link = document.createElement('a');
            link.href = href;
            link.setAttribute('download', filename); //or any other extension
            document.body.appendChild(link);
            link.click();

            // clean up "a" element & remove ObjectURL
            document.body.removeChild(link);
            URL.revokeObjectURL(href);
        });
    }
    return (
        <Modal className={"newcommon reschedule-popup "} show={_hideModal ? false : true} onHide={hideModal} centered size="lg">
            <Modal.Header>
                <div className="modal-title">Documents</div>
                <div className="d-flex w-100">
                    <div className="d-flex w-100 justify-content-end">
                        <input type="file" style={{
                            display: 'none'
                        }} ref={uploadRef} onChange={handleFileChange} />
                        <button className="btn btn-link" onClick={() => {
                            //@ts-ignore
                            uploadRef?.current?.click();
                        }}>Upload Document</button>
                    </div>
                </div>
                <button type="button" className="btn-close" aria-label="Close" onClick={hideModal} ></button>
            </Modal.Header>
            <Modal.Body>
                <div className="d-flex w-100">
                    <div className="container w-100">
                        {
                            documentList.length === 0 &&
                            <b>No Documents Available</b>
                        }
                        <div className="container-fluid w-100">
                            {
                                documentList.map((item: any) => {
                                    return (
                                        <React.Fragment key={item.id}>
                                            <div className="row">
                                                <div className="col-8 p-0">
                                                    <p className="sub_text m-0 text-primary cursorPointer" onClick={() => {
                                                        handleDownload(item)
                                                    }}>{item?.name}</p>
                                                </div>
                                                <div className="col-3 p-0 text-center">
                                                    <small className="sub_text m-0">({item.username})</small>
                                                </div>
                                                <div className="col-1 p-0 d-flex justify-content-end">
                                                    <i className="bi bi-trash cursor-pointer" onClick={() => { handleDelete(item?.id) }}></i>
                                                </div>
                                            </div>
                                        </React.Fragment>
                                    )
                                })
                            }
                        </div>
                    </div>
                </div>
            </Modal.Body>
        </Modal >
    )
}