export enum PROFILE {
    LOAD_PENDING = 'LOAD_PENDING_PROFILE',
    LOAD_ERROR = 'LOAD_FAILED_PROFILE',
    LOAD_SUCCESS = 'LOAD_SUCCESS_PROFILE',
    QUESTION_PENDING = 'LOAD_PENDING_QUESTION',
    QUESTION_ERROR = 'LOAD_ERROR_QUESTION',
    QUESTION_SUCCESS = 'LOAD_SUCCESS_QUESTION',
    FEATURE_PENDING = 'LOAD_FEATURE_PENDING',
    FEATURE_ERROR = 'LOAD_FEATURE_ERROR',
    FEATURE_SUCCESS = 'LOAD_FEATURE_SUCCESS',
    LANGUAGE_PENDING = 'LOAD_LANGUAGE_PENDING',
    LANGUAGE_ERROR = 'LOAD_LANGUAGE_ERROR',
    LANGUAGE_SUCCESS = 'LOAD_LANGUAGE_SUCCESS',
    PROFILE_UPDATE_PENDING = 'PROFILE_UPDATE_PENDING',
    PROFILE_UPDATE_ERROR = 'PROFILE_UPDATE_ERROR',
    PROFILE_UPDATE_SUCCESS = 'PROFILE_UPDATE_SUCCESS',
    IMAGE_REMOVE_PENDING = 'IMAGE_REMOVE_PENDING',
    IMAGE_REMOVE_SUCCESS = 'IMAGE_REMVOE_SUCCESS',
    IMAGE_REMOVE_ERROR = 'IMAGE_REMOVE_ERROR',
    IMAGE_UPLOAD_PENDING = 'IMAGE_UPLOAD_PENDING',
    IMAGE_UPLOAD_ERROR = 'IMAGE_UPLOAD_ERROR',
    IMAGE_UPLOAD_SUCCESS = 'IMAGE_UPLOAD_SUCCESS',
    EMAIL_VERIFICATION_ERROR = 'PROFILE_EMAIL_VERIFICATION_ERROR',
    EMAIL_VERIFICATION_PENDING = 'PROFILE_EMAIL_VERIFICATION_PENDING',
    EMAIL_VERIFICATION_SUCCESS = 'PROFILE_EMAIL_VERIFICATION_SUCCESS',
    HYGIENE_FEATURE_PENDING = 'HYGIENE_FEATURE_PENDING',
    HYGIENE_FEATURE_ERROR = 'HYGIENE_FEATURE_ERROR',
    HYGIENE_FEATURE_SUCCESS = 'HYGIENE_FEATURE_SUCCESS',
};

export interface ProfileRequest {
    uniqueId: string;
    type: string;
}
export interface Status {
    error: boolean;
    msg: string;
}
export interface Permission {
    id: string;
    userid: string;
    uniqueId: string;
    firstName: string;
    lastName: string;
    email: string;
    emailVerified: string;
    userAccess: string;
    emailNotification: string;
}
export interface ProfileResponse {
    id: string;
    uniqueId: string;
    name: string;
    phone: string;
    phoneVerified: string;
    email: string;
    emailVerified: string;
    primarySpeciality: string;
    gender: string;
    website: string;
    logo: { name: string, type: string };
    questionResponse: any[];
    description: string;
    hours: any;
    features: any[];
    address: any;
    photos: any[];
    doctors: [];
    status: Status,
    certificates: any[];
    educations: any[];
    assignment: boolean;
    languages: any[];
    images?: any;
    HygieneFeatures: any[];
    metadata?:any;
}

export interface QuestionRequest {
    modelType: string;
}

export interface QuestionResponse {
    questions: any[];
    status: Status;
}

export interface FeatureRequest {
    speciality: string;
}

export interface FeatureResponse {
    features: any[];
    status: Status;
}

export interface LanguageRequest {

}

export interface LanguageResponse {
    languages: [];
    status: Status;
}

export interface ProfileUpdateRequest {
    type: string;
    uniqueId: string;
    userType: string;
    description?: string;
    assignment?: boolean;
    email?: string;
    website?: string;
    question?: {
        id: string;
        response: string;
        questionIndex: number;
    };
    features?: any[];
    hours?: any;
    phone?: string;
    gender?: string;
    boardCertificate?: {
        edtAssociateName: string;
        edtDate: string;
        edtRegistrationNumber: string;
    };
    education?: {
        edtDegree: string;
        edtUniversity: string;
        edtYear: string;
    },
    removeCertificate?: any;
    removeEducation?: any;
    languages?: any[];
    location?: any;
    HygieneFeatures?: any[];
}

export interface ProfileUpdateResponse {
    userType: string;
    status: Status;
    type: string;
    udpateData: any;
}

export interface ImageRemoveRequest {
    userType: string;
    uniqueId: string;
    image: any;
}

export interface ImageRemoveResponse {
    id: string,
    status: Status;
}
export interface ImageUploadRequest {
    type: string;
    uphoto: any;
    uniqueId: string;
    userType: string;
}
export interface ImageUploadResponse {
    photos: {
        name: string;
        type: string;
    },
    userType: string;
    uniqueId: string;
    status: Status
}

export interface EmailVerificationRequest {
    modelType: string;
    modelUniqueid: string;
    email: string;
}
export interface EmailVerificationResponse {
    status: Status;
}

export interface HygieneFeatureRequest { }
export interface HygieneFeatureResponse {
    hygieneFeatures: any[];
    status: Status;
}
export interface ProfileState {
    pending: boolean;
    profile: ProfileRequest;
    profileResponse: ProfileResponse;
    question: QuestionRequest;
    questionResponse: QuestionResponse;
    feature: FeatureRequest;
    featureResponse: FeatureResponse;
    language: LanguageRequest;
    languageResponse: LanguageResponse;
    profileUpdate: ProfileUpdateRequest;
    profileUpdateResponse: ProfileUpdateResponse;
    imageRemove: ImageRemoveRequest;
    imageRemoveResponse: ImageRemoveResponse;
    error: any;
    imageUpload: ImageUploadRequest;
    imageUploadResponse: ImageUploadResponse;
    emailVerificationRequest?: EmailVerificationRequest;
    emailVerificationResponse?: EmailVerificationResponse;
    hygieneFeatureRequest?: HygieneFeatureRequest;
    hygieneFeatureResponse?: HygieneFeatureResponse;
}

export interface FetchProfilePendingAction {
    type: PROFILE.LOAD_PENDING;
    payload: ProfileRequest
}

export interface FetchProfileErrorAction {
    type: PROFILE.LOAD_ERROR;
    error: any;
}
export interface FetchProfileSuccessAction {
    type: PROFILE.LOAD_SUCCESS;
    profile: ProfileResponse;
}

export interface FetchQuestionPendingAction {
    type: PROFILE.QUESTION_PENDING;
    payload: QuestionRequest;
}

export interface FetchQuestionErrorAction {
    type: PROFILE.QUESTION_ERROR;
    error: any;
}

export interface FetchQuestionSuccessAction {
    type: PROFILE.QUESTION_SUCCESS;
    question: QuestionResponse;
}

export interface FetchFeaturePendingAction {
    type: PROFILE.FEATURE_PENDING;
    payload: FeatureRequest;
}

export interface FetchFeatureErrorAction {
    type: PROFILE.FEATURE_ERROR;
    error: any;
}

export interface FetchFeatureSuccessAction {
    type: PROFILE.FEATURE_SUCCESS;
    feature: FeatureResponse;
}

export interface FetchLanguagePendingAction {
    type: PROFILE.LANGUAGE_PENDING;
    payload: LanguageRequest;
}

export interface FetchLanguageErrorAction {
    type: PROFILE.LANGUAGE_ERROR;
    error: any;
}

export interface FetchLanguageSuccessAction {
    type: PROFILE.LANGUAGE_SUCCESS;
    language: LanguageResponse;
}

export interface ProfileUpdatePendingAction {
    type: PROFILE.PROFILE_UPDATE_PENDING;
    payload: ProfileUpdateRequest;
}

export interface ProfileUpdateSuccessAction {
    type: PROFILE.PROFILE_UPDATE_SUCCESS;
    profileUpdate: ProfileUpdateResponse;
}

export interface ProfileUpdateErrorAction {
    type: PROFILE.PROFILE_UPDATE_ERROR;
    error: any
}

export interface ImageRemoveErrorAction {
    type: PROFILE.IMAGE_REMOVE_ERROR;
    error: any;
}
export interface ImageRemoveSuccessAction {
    type: PROFILE.IMAGE_REMOVE_SUCCESS;
    imageRemove: ImageRemoveResponse;
}
export interface ImageRemovePendingAction {
    type: PROFILE.IMAGE_REMOVE_PENDING;
    payload: ImageRemoveRequest;
}

export interface ImageUploadPendingAction {
    type: PROFILE.IMAGE_UPLOAD_PENDING,
    payload: ImageUploadRequest
}
export interface ImageUploadErrorAction {
    type: PROFILE.IMAGE_UPLOAD_ERROR,
    error: any
}
export interface ImageUploadSuccessAction {
    type: PROFILE.IMAGE_UPLOAD_SUCCESS,
    imageUpload: ImageUploadResponse
}
export interface EmailVerificationPendingAction {
    type: PROFILE.EMAIL_VERIFICATION_PENDING;
    payload: EmailVerificationRequest;
}
export interface EmailVerificationSuccessAction {
    type: PROFILE.EMAIL_VERIFICATION_SUCCESS;
    emailVerification: EmailVerificationResponse;
}
export interface EmailVerificationErrorAction {
    type: PROFILE.EMAIL_VERIFICATION_ERROR;
    error: any;
}
export interface HygieneFeaturePendingAction {
    type: PROFILE.HYGIENE_FEATURE_PENDING;
    payload: HygieneFeatureRequest;
}
export interface HygieneFeatureErrorAction {
    type: PROFILE.HYGIENE_FEATURE_ERROR;
    payload: any;
}
export interface HygieneFeatureSuccessAction {
    type: PROFILE.HYGIENE_FEATURE_SUCCESS;
    payload: HygieneFeatureResponse;
}
export type ProfileActionTypes =
    FetchProfileErrorAction | FetchProfilePendingAction | FetchProfileSuccessAction |
    FetchQuestionPendingAction | FetchQuestionErrorAction | FetchQuestionSuccessAction |
    FetchFeatureErrorAction | FetchFeaturePendingAction | FetchFeatureSuccessAction |
    FetchLanguageErrorAction | FetchLanguagePendingAction | FetchLanguageSuccessAction |
    ProfileUpdateErrorAction | ProfileUpdatePendingAction | ProfileUpdateSuccessAction |
    ImageRemoveErrorAction | ImageRemovePendingAction | ImageRemoveSuccessAction |
    ImageUploadErrorAction | ImageUploadPendingAction | ImageUploadSuccessAction |
    EmailVerificationErrorAction | EmailVerificationPendingAction | EmailVerificationSuccessAction |
    HygieneFeatureErrorAction | HygieneFeaturePendingAction | HygieneFeatureSuccessAction;

