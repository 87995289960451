import React from 'react';
import { NavLink } from 'react-router-dom';
export function PaymentHeader(props: any) {
    return (
        <section className="middle-navbar px-30">
            <div className="p-0 container-fluid">
                <div className="d-flex justify-content-between">
                    <div>
                        <div className="nav-title-txt">Payments</div>
                        <ul className="nav nav-tabs">
                            <li className="nav-item">
                                <NavLink className="nav-link" to="/user/payment/all-invoices">All Invoices</NavLink>
                            </li>
                            <li className="nav-item">
                                <NavLink className="nav-link" to="/user/payment/draft">Draft</NavLink>
                            </li>
                            <li className="nav-item">
                                <NavLink className="nav-link" to="/user/payment/outstandings">Outstanding</NavLink>
                            </li>
                            <li className="nav-item">
                                <NavLink className="nav-link" to="/user/payment/paid">Paid</NavLink>
                            </li>
                            <li className="nav-item">
                                <NavLink className="nav-link" to="/user/payment/stripe-connect">Configuration</NavLink>
                            </li>
                        </ul>
                    </div>

                    {/* <div className="stp-action">
                         
                    </div> */}

                </div>
            </div>
        </section>
    )
}