import { providerUpdateSuccessAction, providerMappingError, providerMappingSuccess, pmsProviderScheduleUpdateSuccess, pmsProviderScheduleUpdateError } from './../store/provider/actions';
import { attachHeader } from './../utils/apiHandler';
import { put, takeEvery, call } from 'redux-saga/effects';
import Axios from "axios";
import CONFIG from '../configs/apiConfigs';
import { FetchProviderPendingAction, PROVIDER, ProviderRequest, ServiceRequest, FetchServicePendingAction, ProviderUpdatePendingAction, ProviderUpdateRequest, ProviderMappingRequest, ProviderMappingPendingAction, PMSProviderScheduleUpdatePendingAction } from "../store/provider/types";
import { fetchLoadingPending, fetchLoadingSuccess } from '../store/loadingIndicator/actions';
import { fetchProviderError, fetchProviderSuccess, fetchServiceError, fetchServiceSuccess, providerUpdateErrorAction } from '../store/provider/actions';
import { getCookie, prepareCookie } from '../utils/cookies';
import apiConfigs from '../configs/apiConfigs';
import { CommonApiOptions, METHOD_TYPE, BODY_TYPE } from '../constants/api';
import configs from '../configs/apiConfigs';
import { commonApi } from '../helpers/api';

function execute(request: ProviderRequest) {
    return Axios.get(CONFIG.API_URL + request.type + "/" + request.uniqueId + "/schedule/",
        { headers: attachHeader(true), withCredentials: true }
    ).then(response => ({ response }), error => ({ error }));
}
function* providerPendingRun(action: FetchProviderPendingAction) {
    //    yield delay(2000);
    yield put(fetchLoadingPending());
    const { response, error } = yield execute(action.payload);
    if (response) {
        if (response.status === 200) {
            if (response.data.status.error === true) {
                yield put(fetchProviderError(response));
            } else {
                yield put(fetchProviderSuccess(response.data));
            }
        } else {
            yield put(fetchProviderError(response));
        }
    }
    if (error) {
        yield put(fetchProviderError(error))
    }
    yield put(fetchLoadingSuccess(false));
}

export default function* providerPendingWatcher() {
    yield takeEvery(PROVIDER.LOAD_PENDING, providerPendingRun);
}

function executeService(request: ServiceRequest) {
    return Axios.post(CONFIG.API_URL + "services/", request, {
        headers: attachHeader(true),
        withCredentials: true
    }).then(response => ({ response }), error => ({ error }));
}
function* servicePendingRun(action: FetchServicePendingAction) {
    //    yield delay(2000);
    yield put(fetchLoadingPending());
    const { response, error } = yield executeService(action.payload);
    if (response) {
        if (response.status === 200) {
            if (response.data.status.error === true) {
                yield put(fetchServiceError(response.data));
            } else {
                yield put(fetchServiceSuccess(response.data));
            }
        } else {
            yield put(fetchServiceError(response));
        }
    }
    if (error) {
        yield put(fetchServiceError(error))
    }
    yield put(fetchLoadingSuccess(false));
}

export function* servicePendingWatcher() {
    yield takeEvery(PROVIDER.LOAD_SERVICE_PENDING, servicePendingRun);
}

function executeProviderUpdate(request: ProviderUpdateRequest) {
    const body = {};
    if (request.type === "enableAppointment") {
        Object.assign(body, { id: request.id, enableAppointment: request.enableAppointment });
    } else if (request.type === 'services') {
        const serviceIdArray = request.services.map((value: any, index: number) => {
            return value.id;
        });
        Object.assign(body, { id: request.id, services: serviceIdArray })
    } else if (request.type === "hours") {
        Object.assign(body, { id: request.id, hours: request.hours });
    } else if (request.type === "appointment") {
        // if (request.actionType === "remove") {
        Object.assign(body, { id: request.id, type: request.actionType, potentialAppointment: request.potentialAppointment });
        // } else if (request.actionType === 'add') {

        // }
    }
    return Axios.post(CONFIG.API_URL + request.userType + "/" + request.uniqueId + "/schedule/update/", body, {
        headers: attachHeader(true),
        withCredentials: true
    }).then(response => ({ response }), error => ({ error }));
}
function* providerUpdatePendingRun(action: ProviderUpdatePendingAction) {
    //    yield delay(2000);
    yield put(fetchLoadingPending());
    const { response, error } = yield executeProviderUpdate(action.payload);
    if (response) {
        if (response.status === 200) {
            if (response.data.status.error === true) {
                yield put(providerUpdateErrorAction(response.data));
            } else {
                yield put(providerUpdateSuccessAction({ ...response.data, udpateData: action.payload }));
            }
        } else {
            yield put(providerUpdateErrorAction(response));
        }
    }
    if (error) {
        yield put(providerUpdateErrorAction(error));
    }
    yield put(fetchLoadingSuccess(false));
}

export function* providerUpdateWatcher() {
    yield takeEvery(PROVIDER.PROVIDER_UPDATE_PENDING, providerUpdatePendingRun);
}

function executeProviderMapping(request: ProviderMappingRequest) {
    const headers = {
        'Content-Type': 'application/json',
        // 'x-access-param': getCookie().authParam,
        'x-access-param': getCookie().xprAuthUser,
        'xpr_user_id': request.userUniqueId,
        'xcookie': prepareCookie()
    };
    const body = {
        userid: request.userUniqueId,
        relationid: request.id
    }
    return Axios.post(apiConfigs.API_URL + request.type + "/" + request.uniqueid + "/relation/update/", body, {
        headers: headers,
        withCredentials: true
    }).then(response => ({ response }), error => ({ error }));
}

export function* pendingProviderMapping(action: ProviderMappingPendingAction) {
    yield put(fetchLoadingPending());
    const { response, error } = yield executeProviderMapping(action.payload);
    if (response) {
        if (response.status === 200) {
            if (response.data.status.error === true) {
                yield put(providerMappingError(response.data));
            } else {
                yield put(providerMappingSuccess(response.data));
            }
        } else {
            yield put(providerMappingError(response));
        }
    }
    if (error) {
        yield put(providerMappingError(error))
    }
    yield put(fetchLoadingSuccess(false));
}

export function* providerMappingPendingWatcher() {
    yield takeEvery(PROVIDER.PROVIDER_MAPPING_PENDING, pendingProviderMapping);
}

export function* pmsProviderScheduleUpdate(action: PMSProviderScheduleUpdatePendingAction) {
    try {
        yield put(fetchLoadingPending());
        const bodyData = JSON.parse(JSON.stringify(action.payload));
        delete bodyData.clinicUniqueId;
        const options: CommonApiOptions = {
            url: configs.API_URL + "clinic/" + action.payload.clinicUniqueId + "/schedule/update/",
            apiOptions: {
                headers: attachHeader(true),
                withCredentials: true
            },
            method: METHOD_TYPE.POST,
            body: {
                type: BODY_TYPE.RAW,
                data: bodyData
            }
        };
        //@ts-ignore
        const response = yield call(commonApi, options);
        yield put(pmsProviderScheduleUpdateSuccess(response));
    } catch (err) {
        yield put(pmsProviderScheduleUpdateError(err));
    }
    yield put(fetchLoadingSuccess(false));
}
export function* pmsProviderScheduleUpdateWatcher() {
    yield takeEvery(PROVIDER.PMS_PROVIDER_SCHEDULE_UPDATE, pmsProviderScheduleUpdate);
}