import React from 'react';
import { Route, Switch } from 'react-router';
import { AdministrativeHeader } from '../../components/messageCenter/AdministrativeHeader';
import { EmailTemplates } from '../../components/messageCenter/EmailTemplates';
import { CustomEmailEditor } from '../emailEditor/EmailEditor';
import { AdministrativeNotification } from './AdministrativeNotifications';
import { AdministrativePatients } from './AdministrativePatients';
export const Administrative = () => {
    return (
        <>
            <AdministrativeHeader></AdministrativeHeader>
            <Switch>
                <Route exact path="/message-center/administrative" component={AdministrativeNotification}></Route>
                <Route exact path="/message-center/administrative/patients" component={AdministrativePatients}></Route>
                {/* <Route exact path="/message-center/administrative/templates" component={EmailTemplates}></Route>
                <Route exact path="/message-center/administrative/emaileditor" component={CustomEmailEditor}></Route> */}
            </Switch>
        </>
    );
}