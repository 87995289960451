import firebase, { firestore } from "firebase";
import configs from "../configs/apiConfigs";
import { BODY_TYPE, CommonApiOptions, METHOD_TYPE } from "../constants/api";
import { commonApi } from "../helpers/api";
import { attachHeader } from "../utils/apiHandler";
const db = firebase.firestore();
export const getBoard = (clinicId: string, query:any = {}) => {
    let url = configs.API_URL + 'clinic/' + clinicId + '/getboard/?';
    if (query.assigned) {
        url = url + 'assigned=' + query.assigned + '&';
    }
    if (query.created) {
        url = url + 'created=' + query.created + '&';
    }
    if (query.target) {
        url = url + 'target=' + query.target + '&';
    }
    if (query.status) {
        url = url + 'status=' + query.status + '&';
    }
    const request: CommonApiOptions = {
        url: url,
        method: METHOD_TYPE.GET,
        apiOptions: {
            headers: attachHeader(true),
            withCredentials: true
        }
    };
    return commonApi(request);
}

export const createCard = (clinicId: string, data: any) => {
    const request: CommonApiOptions = {
        url: configs.API_URL + 'clinic/' + clinicId + '/addtask/',
        method: METHOD_TYPE.POST,
        body: {
            type: BODY_TYPE.RAW,
            data: data
        },
        apiOptions: {
            headers: attachHeader(true),
            withCredentials: true
        }
    };
    return commonApi(request);
}

export const removeCard = (clinicId: string, cardId: any) => {
    const request: CommonApiOptions = {
        url: configs.API_URL + 'clinic/' + clinicId + '/removetask/' + cardId + '/',
        method: METHOD_TYPE.GET,
        apiOptions: {
            headers: attachHeader(true),
            withCredentials: true
        }
    };
    return commonApi(request);
}

export const moveTask = (clinicId: string, cardId: any, body: any) => {
    const request: CommonApiOptions = {
        url: configs.API_URL + 'clinic/' + clinicId + '/movetaskcard/' + cardId + '/',
        method: METHOD_TYPE.POST,
        body: {
            type: BODY_TYPE.RAW,
            data: body
        },
        apiOptions: {
            headers: attachHeader(true),
            withCredentials: true
        }
    };
    return commonApi(request);
}


export const addIntoTaskManagerHistory = async (data: any) => {
    return await db.collection(configs.TASK_MANAGER_HISTORY_COLLECTION).add({
        ...data,
        viewByUser: [],
        createdAt: firestore.FieldValue.serverTimestamp()
    });
}

export const addIntoTaskManagerDiscussion = async (data: any) => {
    return await db.collection(configs.TASK_MANAGER_DISCUSSION_COLLECTION).add({
        ...data,
        createdAt: firestore.FieldValue.serverTimestamp()
    });
}

export const archiveCard = async (clinicId: string, cardId: any) => {
    const request: CommonApiOptions = {
        url: configs.API_URL + 'clinic/' + clinicId + '/archivedtask/' + cardId + '/',
        method: METHOD_TYPE.GET,
        apiOptions: {
            headers: attachHeader(true),
            withCredentials: true
        }
    };
    return commonApi(request);
}

export const getArchiveBoard = (clinicId: string, query:any) => {
    let url = configs.API_URL + 'clinic/' + clinicId + '/getarchived/?';
    if (query.assigned) {
        url = url + 'assigned=' + query.assigned + '&';
    }
    if (query.created) {
        url = url + 'created=' + query.created + '&';
    }
    if (query.target) {
        url = url + 'target=' + query.target + '&';
    }
    if (query.status) {
        url = url + 'status=' + query.status + '&';
    }
    const request: CommonApiOptions = {
        url: url,
        method: METHOD_TYPE.GET,
        apiOptions: {
            headers: attachHeader(true),
            withCredentials: true
        }
    };
    return commonApi(request);
}

export const filterBoard = (clinicId: string, query: any) => {
    let url = configs.API_URL + 'clinic/' + clinicId + '/getboard/?';
    if (query.assigned) {
        url = url + 'assigned=' + query.assigned + '&';
    }
    if (query.created) {
        url = url + 'created=' + query.created + '&';
    }
    if (query.target) {
        url = url + 'target=' + query.target + '&';
    }
    if (query.status) {
        url = url + 'status=' + query.status + '&';
    }
    const request: CommonApiOptions = {
        url: url,
        method: METHOD_TYPE.GET,
        apiOptions: {
            headers: attachHeader(true),
            withCredentials: true
        }
    };
    return commonApi(request);
}