import { KeyboardDatePicker } from '@material-ui/pickers';
import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import { Controller, useForm } from 'react-hook-form';
import moment from 'moment';
import ReactInputMask from 'react-input-mask';
import { takeOnlyDigitAndPlus } from '../../../utils/global';
export const AddMemberModal = ({ hideModal, createMember }: any) => {
    const [isLoading, setIsLoading] = useState(false);
    const { register, setValue, errors, getValues, handleSubmit, control, watch } = useForm({
        defaultValues: {
            firstName: '',
            lastName: '',
            relation: 'Son',
            gender: 'Male',
            automatedreminder: 'no',
            pcontactmethod: 'email',
            email: '',
            phone: '',
            dob: moment().subtract(1, 'day'),
        }
    });
    const onSubmit = async (data: any) => {
        let requestData: any = {
            fname: getValues('firstName'),
            lname: getValues('lastName'),
            relation: getValues('relation'),
            email: getValues('email'),
            phone: getValues('phone'),
            gender: getValues('gender'),
            dob: moment(getValues('dob')).format('YYYY-MM-DD'),
            contactMethod: getValues('pcontactmethod') ? getValues('pcontactmethod') : '',
            reminder: getValues('automatedreminder'),
        };
        setIsLoading(true);
        try {
            await createMember(requestData);
        } catch (err) {
            setIsLoading(false);
        }
    }
    return (
        <Modal show={true} onHide={() => { hideModal() }} className="newcommon editmember-popup" centered>
            <Modal.Header>
                <div className="modal-title">{'Add new member'}</div>
                <button type="button" className="btn-close" aria-label="Close" onClick={() => { hideModal() }}>
                </button>
            </Modal.Header>

            <Modal.Body>

                <div className="row g-3">
                    <div className="col-6">
                        <label className="form-label">First name</label>
                        <input type="text" name="firstName" className="form-control" placeholder="First name" ref={register({ required: true })}></input>
                        {
                            (errors && errors.firstName && errors.firstName.type === 'required') &&
                            <div className="is-invalid">First name is required</div>
                        }
                    </div>
                    <div className="col-6">
                        <label className="form-label">Last name</label>
                        <input type="text" name="lastName" className="form-control" placeholder="Last name" ref={register({ required: true })}></input>
                        {
                            (errors && errors.lastName && errors.lastName.type === 'required') &&
                            <div className="is-invalid">Last name is required</div>
                        }
                    </div>
                    <div className="col-6">
                        <label className="form-label">Relation</label>
                        <select className="form-select" name="relation" ref={register({ required: true })}>
                            <option value="Son">Son</option>
                            <option value="Daughter">Daughter</option>
                            <option value="Spouse">Spouse</option>
                        </select>
                        {
                            (errors && errors.relation && errors.relation.type === 'required') &&
                            <div className="is-invalid">Relation is required</div>
                        }
                    </div>
                    <div className="col-6">
                        <label className="form-label">Gender</label>
                        <select className="form-select" name="gender" ref={register({ required: true })}>
                            <option value="Male">Male</option>
                            <option value="Female">Female</option>
                        </select>
                        {
                            (errors && errors.gender && errors.gender.type === 'required') &&
                            <div className="is-invalid">Gender is required</div>
                        }
                    </div>
                    <div className="col-6">
                        <label className="form-label">Email</label>
                        <input type="email" name="email" className="form-control" placeholder="Email" ref={register({ required: true })}></input>
                        {
                            (errors && errors.email && errors.email.type === 'required') &&
                            <div className="is-invalid">Email is required</div>
                        }
                    </div>
                    <div className="col-6">
                        <label className="form-label">Mobile Number</label>
                        <Controller
                            as={ReactInputMask}
                            control={control}
                            mask="9999999999"
                            name="phone"
                            className="form-control"
                            placeholder="+11234567890"
                            rules={{ required: true, minLength: { value: 10, message: 'Mobile Number is invalid' } }}
                        />
                        {
                            (errors && errors.phone && errors.phone.type === 'required') &&
                            <div className="is-invalid">Mobile Number is required</div>
                        }
                        {
                            (errors && errors.phone && errors.phone.type === 'minLength') &&
                            <div className="is-invalid">{errors.phone.message}</div>
                        }
                    </div>
                    <div className="col-6 miuif_margin">
                        <label className="form-label">Date of birth</label>
                        <Controller
                            name="dob"
                            control={control}
                            render={(props) => {
                                return (
                                    <KeyboardDatePicker
                                        margin="none"
                                        id={"date-picker-dialog-dob"}
                                        name={"date-picker-dialog-dob"}
                                        format="YYYY/MM/DD"
                                        maxDate={moment().subtract(1, 'days')}
                                        value={props.value}
                                        onChange={(date: any) => { props.onChange(date) }}
                                        KeyboardButtonProps={{
                                            'aria-label': 'change date',
                                        }}
                                    />
                                )
                            }}
                        ></Controller>
                    </div>
                    <div className="col-6">
                        <label className="form-label">Automated Reminder</label>
                        <div className="d-flex align-items-center">
                            <div className="form-check me-3">
                                <input className="form-check-input" type="radio" name="automatedreminder" id="a-yes" value="yes" ref={register({ required: true })} />
                                <label className="form-check-label" htmlFor="a-yes">Yes</label>
                            </div>
                            <div className="form-check me-3">
                                <input className="form-check-input" type="radio" name="automatedreminder" id="a-no" value="no" ref={register({ required: true })} />
                                <label className="form-check-label" htmlFor="a-no">No</label>
                            </div>
                        </div>
                        {
                            (errors && errors.automatedreminder && errors.automatedreminder.type === 'required') &&
                            <div className="is-invalid">Automated reminder is required</div>
                        }
                        {
                            watch('automatedreminder') === 'no' &&
                            <div className="is-invalid">This member will not receive notification.</div>
                        }
                    </div>
                    {
                        watch('automatedreminder') === 'yes' &&
                        <div className="col-12">
                            <label className="form-label">Preferred contact method?</label>
                            <div className="d-flex align-items-center">
                                <div className="form-check me-3">
                                    <input className="form-check-input" type="radio" name="pcontactmethod" id="email-only" value={"email"} ref={register({ required: true })} />
                                    <label className="form-check-label" htmlFor="email-only">Email Only</label>
                                </div>
                                <div className="form-check me-3">
                                    <input className="form-check-input" type="radio" name="pcontactmethod" id="text-only" value="text" ref={register({ required: true })} />
                                    <label className="form-check-label" htmlFor="text-only">Text Only</label>
                                </div>

                                <div className="form-check me-3">
                                    <input className="form-check-input" type="radio" name="pcontactmethod" id="et-both" value="email,text" ref={register({ required: true })} />
                                    <label className="form-check-label" htmlFor="et-both">Email & Text</label>
                                </div>
                            </div>
                            {
                                (errors && errors.pcontactmethod && errors.pcontactmethod.type === 'required') &&
                                <div className="is-invalid">Preferred contact method is required</div>
                            }
                        </div>
                    }

                </div>

            </Modal.Body>
            <Modal.Footer>
                <div className="text-end">
                    <button className="btn btn-outline-primary me-2" onClick={() => { hideModal() }}> Cancel</button>
                    <button disabled={isLoading} className="btn btn-primary" onClick={handleSubmit(onSubmit)}>
                        {
                            isLoading &&
                            <span className="spinner-border spinner-border-sm me-1" role="status" aria-hidden="true"></span>
                        }
                        Submit
                    </button>
                </div>
            </Modal.Footer>
        </Modal>
    )
}