import React, { useEffect,useState } from 'react';
import { Administrative } from '../../components/dashboardCharts/administrative/Administrative';
import { AdministrativeMessages } from '../../components/dashboardCharts/AdministrativeMessages';
import { AppointmentCancel } from '../../components/dashboardCharts/AppointmentCancel';
import { AppointmentConfirmaton } from '../../components/dashboardCharts/AppointmentConfirmaton';
import { AppointmentPreScreening } from '../../components/dashboardCharts/AppointmentPreScreening';
import { Marketing } from '../../components/dashboardCharts/marketing/Marketing';
import { MarketingMessages } from '../../components/dashboardCharts/MarketingMessages';
import { Reminder } from '../../components/dashboardCharts/Reminder/Reminder';
import { ReminderMessages } from '../../components/dashboardCharts/ReminderMessages';
import { KeyboardTimePicker, KeyboardDatePicker } from '@material-ui/pickers';
import moment from 'moment';
import CustomElements from './CustomElements';
import  Filter  from './Filter';
import { AppointmentConfirmationNew } from '../../components/dashboardCharts/appointment/AppointmentConfirmationNew';
import { AppointmentCancelNew } from '../../components/dashboardCharts/appointment/AppointmentCancelNew';
import { AppointmentPreScreeningNew } from '../../components/dashboardCharts/appointment/AppointmentPreScreeningNew';
import { MarketingBirthday } from '../../components/dashboardCharts/marketing/MarketingBirthday';
import { MarketingEvents } from '../../components/dashboardCharts/marketing/MarketingEvents';
import { MarketingRecall } from '../../components/dashboardCharts/marketing/MarketingRecall';
import { MarketingDigitalDoc } from '../../components/dashboardCharts/marketing/MarketingDigitalDoc';
import { ReminderScheduled } from '../../components/dashboardCharts/Reminder/ReminderScheduled';
import { ReminderConfirmation } from '../../components/dashboardCharts/Reminder/ReminderConfirmation';
import { ReminderPrescreening } from '../../components/dashboardCharts/Reminder/ReminderPrescreening';
import { AdministrativeCompleted } from '../../components/dashboardCharts/administrative/AdministrativeCompleted';
import { AdministrativeReserved } from '../../components/dashboardCharts/administrative/AdministrativeReserved';
import { AdministrativeScheduledRescheduled } from '../../components/dashboardCharts/administrative/AdministrativeScheduledRescheduled';
import { AdministrativeCancelled } from '../../components/dashboardCharts/administrative/AdministrativeCancelled';
import { AdministrativeConfirmation } from '../../components/dashboardCharts/administrative/AdministrativeConfirmation';
import { AdministrativePrescreening } from '../../components/dashboardCharts/administrative/AdministrativePrescreening';

export const DashboardCharts = () => {

    const [date, setDate] = useState(undefined);
    const [startDate, setStartDate] = useState(moment().subtract(1,'months').format('YYYY-MM-DD'));
    const [endDate, setEndDate] = useState(moment().format('YYYY-MM-DD'));

    const handleChangeStartEndDate = (sDate: any,eDate:any) => {
        setStartDate(sDate);
        setEndDate(eDate);
    };
    return (
        <div className="container-fluid p-4">
                {/* <Filter handleChangeStartEndDate={handleChangeStartEndDate} /> */}
            <div className="row temp-chart adjstimg ">
                <div className="col-12 d-flex flex-wrap">
                    <AppointmentConfirmationNew />
                    <AppointmentCancelNew />
                    <AppointmentPreScreeningNew />
                    {/* <AppointmentConfirmaton startDate={startDate} endDate={endDate} />
                    <AppointmentCancel startDate={startDate} endDate={endDate} />
                    <AppointmentPreScreening startDate={startDate} endDate={endDate} /> */}
                {/* </div>
            </div>
            <div className="row temp-chart adjstimg ">
                <div className="col-12 d-flex flex-wrap"> */}
                    <MarketingBirthday />
                    <MarketingEvents />
                    <MarketingRecall />
                    <MarketingDigitalDoc />
                    {/* <Marketing startDate={startDate} endDate={endDate} /> */}
                {/* </div>
            </div>
            <div className="row temp-chart adjstimg ">
                <div className="col-12 d-flex flex-wrap"> */}
                    <ReminderScheduled />
                    <ReminderConfirmation />
                    <ReminderPrescreening />
                    {/* <Reminder startDate={startDate} endDate={endDate} /> */}
                {/* </div>
            </div>
            <div className="row temp-chart adjstimg ">
                <div className="col-12 d-flex flex-wrap"> */}
                    <AdministrativeCompleted />
                    <AdministrativeReserved />
                    <AdministrativeScheduledRescheduled />
                    <AdministrativeCancelled />
                    <AdministrativeConfirmation />
                    <AdministrativePrescreening />
                    {/* <Administrative startDate={startDate} endDate={endDate} /> */}
                </div>
            </div>
            {/* <div className="row temp-chart mb-3">
                <div className="col-6">
                    <div className="card"><MarketingMessages /></div>
                </div>
                <div className="col-6">
                    <div className="card"><ReminderMessages /></div>
                </div>
            </div> */}
            {/* <div className="row temp-chart">
                <div className="col-6">
                    <div className="card"><AdministrativeMessages /></div>
                </div>
            </div> */}
        </div>
    )
}
