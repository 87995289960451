import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import { Modal, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { useForm, Controller } from "react-hook-form";
import RichTextEditor from 'react-rte';
import { paymentReminderNotificationTypeList, createPaymentReminderNotificationTemplate, updatePaymentReminderNotificationDetail, uploadPhotos,  } from '../../services/messageCenter';
import moment from 'moment';
import { KeyboardDatePicker, KeyboardTimePicker } from '@material-ui/pickers';
import { toast } from 'react-toastify';
import CreatableSelect from 'react-select/creatable';
import { values } from 'lodash';
import CopyToClipboard from 'react-copy-to-clipboard';
import { fetchLoadingPending, fetchLoadingSuccess } from '../../../store/loadingIndicator/actions';
import { useDispatch } from 'react-redux';
import ClipboardIconPopup from '../../assets/images/clipboard-icon-popup.svg';

export const PaymentReminderNotificationModal = ({ hideModal, clinicId, selectedTemplate, fetchList,
    emailTemplateList, showEmailTemplate,clinic }: any) => {
    const [isLoading, setIsLoading] = useState(false);
    const [isSameDay, setIsSameDay] = useState(false);
    const { register, handleSubmit, watch, errors, control, setValue, getValues, setError } = useForm();
    const components = {
        DropdownIndicator: null,
    };
    const [inputValue, setInputValue]: any = useState('');
    const [value, setCustomDayValue]: any = useState([]);
    const [notifiacationName, setNotificationName] = useState('');
    const [notificationTypes, setNotificationTypes] = useState([]);
    const dispatch = useDispatch();

    useEffect(() => {
        if (selectedTemplate === null) {
            dispatch(fetchLoadingPending());
            paymentReminderNotificationTypeList({ clinicid: clinicId, isrecurring: true }).then((success) => {
                dispatch(fetchLoadingSuccess(false));
                if (success && success.error === false) {
                    setNotificationTypes(success.data);
                    if (success.data[0]) {
                        setValue('notificationType', success.data[0].payment_notificationtemplate_type_id);
                    }
                }
            }).catch((err) => {
                dispatch(fetchLoadingSuccess(false));
                console.log(err);
            });
        }
    }, []);

    useEffect(() => {
        if (emailTemplateList && selectedTemplate === null && showEmailTemplate) {
            setValue("emailTemplate", "");
            if (showEmailTemplate) {
                setValue('subject', clinic.name);
                setValue('body', RichTextEditor.createValueFromString(clinic.name, 'html'));
            }
        }
        if (emailTemplateList && selectedTemplate && showEmailTemplate) setValue('emailTemplate', selectedTemplate.emailtemplate_id);
    }, [emailTemplateList,selectedTemplate]);


    useEffect(() => {
        if (selectedTemplate) {
            const custom = selectedTemplate.scheduledays.split(",").filter((item: any) => (item !== "0" && item !== "15" && item !== "30"));
            console.log(custom);
            if (custom[0] !== '' && custom.length > 0) {
                setCustomDayValue(custom.map((item: any) => ({ value: item, label: item })));
            }
            if (showEmailTemplate) {
                setValue('subject', clinic.name);
                setValue('body', RichTextEditor.createValueFromString(clinic.name, 'html'));
            } else {
                setValue('subject', selectedTemplate.subject);
                setValue('body', RichTextEditor.createValueFromString(selectedTemplate.body, 'html'));
            }
            setValue('textMsgInput', selectedTemplate.smstext);
            setValue('appNotificationInput', selectedTemplate.appnotificationtext);
            setValue('startDate', selectedTemplate.startdate ? moment(selectedTemplate.startdate) : moment());
            setValue('endDate', selectedTemplate.enddate ? moment(selectedTemplate.enddate) : moment());
            setValue('time', selectedTemplate.notificationtime ? moment(selectedTemplate.notificationtime, 'HH:mm:ss') : moment());
            setValue('bannerPreview', selectedTemplate.bannerurl);
            setValue('logoPreview', selectedTemplate.logourl);
            setValue('notificationName', selectedTemplate.notificationname);
            //   setValue('appointmentType', selectedTemplate.appointment_type);
            if (selectedTemplate && selectedTemplate.scheduledays) {
                let schedules = selectedTemplate.scheduledays.split(',');
                let isFind = schedules.find((item: any) => { return item === '0' });
                console.log('isFinnd', isFind, schedules)
                if (isFind) {
                    setValue('sameDay', true);
                    setIsSameDay(true);
                } else {
                    setValue('sameDay', false);
                    setIsSameDay(false);
                }

            }
        }
    }, [selectedTemplate]);
    
    const handleChange = (value: any, actionMeta: any) => {
        if (value === null) {
            setCustomDayValue([]);
        } else {
            setCustomDayValue(value);
        }
    };
    const createOption = (label: string) => ({
        label,
        value: label,
    });
    const handleInputChange = (inputValue: any) => {
        if (isNaN(inputValue)) {
            return;
        }
        setInputValue(inputValue);
    };
    const handleKeyDown = (event: any) => {
        if (!inputValue) return;
        switch (event.key) {
            case 'Enter':
            case 'Tab':
                setInputValue('');
                if (!value.find((item: any) => item.label == inputValue)) {
                    setCustomDayValue([...value, createOption(inputValue)]);
                }
                event.preventDefault();
            default:
                return;
        }
    };

    const onSubmit = (data: any) => {
        console.log(data);
        let scheduledays = "";
        if (data.sameDay) {
            scheduledays += "0,";
        }
        if (data.day15) {
            scheduledays += "15,";
        }
        if (data.day30) {
            scheduledays += "30,";
        }
        const mappedValue = value.map((item: any) => item.value);
        if (value) {
            scheduledays += mappedValue.toString();
        }
        const requestData = {
            clinicid: clinicId,
            scheduledays: scheduledays,
            logourl: getValues('logoPreview'),
            bannerurl: getValues('bannerPreview'),
            subject: data.subject,
            body: data.body ? data.body.toString('html') : '',
            smstext: data.textMsgInput,
            appnotificationtext: data.appNotificationInput,
            startdate: data.startDate ? data.startDate : '',
            enddate: data.endDate ? data.endDate : '',
            notificationtime: data.time ? moment(data.time).format("hh:mm") : '',
            emailtemplate_id: showEmailTemplate ? data.emailTemplate : undefined
        }
        setIsLoading(true);
        if (selectedTemplate) {
            Object.assign(requestData, { notificationname: selectedTemplate.notificationname, payment_reminder_notificationtemplate_id: selectedTemplate.payment_reminder_notificationtemplate_id });
            updatePaymentReminderNotificationDetail(requestData).then((success) => {
                setIsLoading(false);
                if (success && success.error === false) {
                    fetchList();
                    toast.success('Updated successfully.');
                    hideModal();
                    return;
                }
                toast.warn('Something went wrong');
            }).catch((err) => {
                setIsLoading(false);
                toast.warn('Something went wrong');
            });
        } else {
            let notificationType: any = notificationTypes.find((item: any) => { return item.payment_notificationtemplate_type_id.toString() === data.notificationType });
            if (notificationType) {
                Object.assign(requestData,
                    {
                        notificationname: notificationType.paymenttemplate_type,
                        notificationtemplatetypeid: data.notificationType
                    });
                createPaymentReminderNotificationTemplate(requestData).then((success) => {
                    setIsLoading(false);
                    if (success && success.error === false) {
                        fetchList();
                        toast.success('Notification added successfully.');
                        hideModal();
                        return;
                    }
                    toast.warn('Something went wrong');
                }).catch((err) => {
                    setIsLoading(false);
                    toast.warn('Something went wrong');
                });
            }
        }
    }

    const onLogoChange = (event: any) => {
        const file = event.target.files[0];
        if (!file) {
            setError('logo', { type: 'filerequired', message: 'Logo is required.' });
            setValue('logo', null);
        } else {
            if (file.type === "image/png" ||
                file.type === 'image/jpg' ||
                file.type == 'image/jpeg') {
                if (file.size > 512000) {
                    setError('logo', {
                        type: 'logosize', message: 'Maximum file size should be 512kb'
                    });
                    setValue('logo', null);
                } else {
                    const files = event.target.files;
                    uploadPhotos({ upload: event.target.files[0] }).then((success) => {
                        if (success && success.error === false) {
                            setValue('logoPreview', success.data);
                            setValue('logo', files);
                            setError('logo', {});
                        }
                    }).catch((err) => {
                        setError('logo', {
                            type: 'logotype', message: 'Image upload failed.'
                        });
                        setValue('logoPreview', '');
                        setValue('logo', null);
                    });
                }
            } else {
                setError('logo', {
                    type: 'logotype', message: 'Logo extension is not valid, allowed png,jpg,jpeg'
                });
                setValue('logo', null);
            }
        }
    };
    const onBannerChange = (event: any) => {
        const file = event.target.files[0];
        if (!file) {
            setError('banner', { type: 'filerequired', message: 'Logo is required.' });
            setValue('banner', null);
        } else {
            if (file.type === "image/png" ||
                file.type === 'image/jpg' ||
                file.type == 'image/jpeg') {
                if (file.size > 512000) {
                    setError('banner', {
                        type: 'bannersize', message: 'Maximum file size should be 512kb'
                    });
                    setValue('banner', null);
                } else {
                    const files = event.target.files;
                    uploadPhotos({ upload: event.target.files[0] }).then((success) => {
                        if (success && success.error === false) {
                            setValue('bannerPreview', success.data);
                            setValue('banner', files);
                            setError('banner', {});
                        }
                    }).catch((err) => {
                        setError('banner', {
                            type: 'bannertype', message: 'Image upload failed.'
                        });
                        setValue('bannerPreview', '');
                        setValue('banner', null);
                    });
                }
            } else {
                setError('banner', {
                    type: 'bannertype', message: 'Banner extension is not valid, allowed png,jpg,jpeg'
                });
                setValue('banner', null);
            }
        }
    }
    useEffect(() => {
        if (watch('notificationType')) {
            let notificationType: any = notificationTypes.find((item: any) => { return item.payment_notificationtemplate_type_id.toString() === watch('notificationType') });
            if (notificationType) {
                setNotificationName(notificationType.paymenttemplate_type);
            }
        }
    }, [watch('notificationType')]);
    watch('logo');
    watch('banner');
    watch('bannerPreview');
    watch('logoPreview');

    return (
        <Modal className="noti-popups-sec" show={true} onHide={hideModal}>
            {
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="position-relative">
                        <div className="modal-header">
                            <h5 className="modal-title h4">Notification</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={hideModal}></button>
                        </div>
                        <div className="modal-body">
                            <div className={"mb-3 " + (selectedTemplate ? 'd-none' : '')}>
                                <label className="mb-1">Notification type</label>
                                <select className="form-control" name="notificationType" placeholder="Notification type" ref={register({ required: !selectedTemplate ? true : false })}>
                                    {
                                        notificationTypes.map((item: any, index: number) => {
                                            return (
                                                <option key={item.payment_notificationtemplate_type_id + "notificationtype"}
                                                    value={item.payment_notificationtemplate_type_id}>{item.paymenttemplate_type}</option>
                                            )
                                        })
                                    }
                                </select>
                                {
                                    errors.notificationType && errors.notificationType.type === "required" &&
                                    <div className="is-invalid">Notification Name is Required</div>
                                }
                            </div>
                            {
                                <div className="set-time-box mb-3">
                                    <div className="mb-0"><span className="label-txt">Time interval config.</span><small className="text-muted">(Please select reminder interval)</small></div>
                                    <div className="features-check-list align-items-start custom-check-list d-flex">
                                        <div className="form-check">
                                            <input className="form-check-input" type="checkbox" name="sameDay" value="0" id="general-features-1"
                                                defaultChecked={isSameDay} ref={register} />
                                            <label className="form-check-label rounded-2 border w-100" htmlFor="general-features-1">
                                                Same day
                                            </label>
                                        </div>
                                        <div className="form-check">
                                            <input className="form-check-input" type="checkbox" name="day15" value="15" id="general-features-3"
                                                defaultChecked={selectedTemplate ? selectedTemplate.scheduledays.includes("15") : false}
                                                ref={register} />
                                            <label className="form-check-label rounded-2 border w-100" htmlFor="general-features-3">
                                                15 days
                                            </label>
                                        </div>
                                        <div className="form-check">
                                            <input className="form-check-input" type="checkbox" name="day30" value="30" id="general-features-2" ref={register}
                                                defaultChecked={selectedTemplate ? selectedTemplate.scheduledays.includes("30") : false}
                                            />
                                            <label className="form-check-label rounded-2 border w-100" htmlFor="general-features-2">
                                                30 days
                                            </label>
                                        </div>
                                        <div className="flex-grow-1 mt-2">
                                            {/* <label className="d-block mb-1">Custom Day</label> */}
                                            <CreatableSelect
                                                components={components}
                                                inputValue={inputValue}
                                                isClearable
                                                isMulti
                                                menuIsOpen={false}
                                                onChange={handleChange}
                                                onInputChange={handleInputChange}
                                                onKeyDown={handleKeyDown}
                                                placeholder="Custom Day"
                                                value={value}
                                            />
                                        </div>
                                    </div>
                                    {/* <div>
                                        <label className="form-select-label">Appointment state when to remind</label>
                                        <select className="form-select" name="appointmentType" ref={register}>
                                            <option value="">Not applicable</option>
                                            <option value="scheduled">Scheduled</option>
                                            <option value="prescreening">Prescreening Pending</option>
                                            <option value="confirmed">Patient Confirmation Pending</option>
                                            <option value="appt-confirmed">Already Confirmed by Patient</option>
                                        </select>
                                    </div> */}

                                </div>
                            }
                            <div className={"mb-3 " + (!showEmailTemplate ? "d-none" : "")}>
                                <label className="mb-1">Email template</label>
                                <select className="form-control" name="emailTemplate" placeholder="Email template" ref={register({required: showEmailTemplate ? true : false})}>
                                    <option value="">Select email template</option>
                                    {
                                        emailTemplateList.map((item: any, index: number) => {
                                            return (
                                                <option key={item.messagecenteremailtemplate_id + "emailtemplate"}
                                                    value={item.messagecenteremailtemplate_id}>{item.templatename}</option>
                                            )
                                        })
                                    }
                                </select>
                                {
                                    errors && errors.emailTemplate && errors.emailTemplate.type === "required" &&
                                    <div className="is-invalid">Email template is Required</div>
                                }
                            </div>
                            <div className="short-codes-box my-3">
                                <div className="mb-3"><span className="fw-bold">Short codes </span>
                                    <small className="text-muted">(click icon to copy code.)</small>
                                </div>
                                <div className="d-flex short-code-btns flex-wrap">
                                    <OverlayTrigger
                                        placement="auto"
                                        overlay={
                                            <Tooltip id="tooltip">
                                                {"Use {appointmentdate} in body to attach appointment date."}
                                            </Tooltip>
                                        }
                                    >
                                        <CopyToClipboard text={'{appointmentdate}'} onCopy={() => toast.success('appointment date copied!')}>
                                            <button className="btn p-0" type="button">Appointment date<img src={ClipboardIconPopup} className="ms-2" /></button>
                                        </CopyToClipboard>
                                    </OverlayTrigger>
                                    <OverlayTrigger
                                        placement="auto"
                                        overlay={
                                            <Tooltip id="tooltip">
                                                {"Use {appointmentstarttime} in body to attach appointment start time."}
                                            </Tooltip>
                                        }
                                    >
                                        <CopyToClipboard text={'{appointmentstarttime}'} onCopy={() => toast.success('appointment start time copied!')}>
                                            <button className="btn p-0" type="button">Appointment start time<img src={ClipboardIconPopup} className="ms-2" /></button>
                                        </CopyToClipboard>
                                    </OverlayTrigger>
                                    <OverlayTrigger
                                        placement="auto"
                                        overlay={
                                            <Tooltip id="tooltip">
                                                {"Use {appointmentstatus} in body to attach appointment status."}
                                            </Tooltip>
                                        }
                                    >
                                        <CopyToClipboard text={'{appointmentstatus}'} onCopy={() => toast.success('appointment status copied!')}>
                                            <button className="btn p-0" type="button">Appointment status<img src={ClipboardIconPopup} className="ms-2" /></button>
                                        </CopyToClipboard>
                                    </OverlayTrigger>
                                    <OverlayTrigger
                                        placement="auto"
                                        overlay={
                                            <Tooltip id="tooltip">
                                                {"Use {patientname} in body to attach patient name."}
                                            </Tooltip>
                                        }
                                    >
                                        <CopyToClipboard text={'{patientname}'} onCopy={() => toast.success('patientname copied!')}>
                                            <button className="btn p-0" type="button">Patient name<img src={ClipboardIconPopup} className="ms-2" /></button>
                                        </CopyToClipboard>

                                    </OverlayTrigger>
                                    <OverlayTrigger
                                        placement="auto"
                                        overlay={
                                            <Tooltip id="tooltip">
                                                {"Use {providername} in body to attach provider name."}
                                            </Tooltip>
                                        }
                                    >
                                        <CopyToClipboard text={'{providername}'} onCopy={() => toast.success('provider name copied!')}>
                                            <button className="btn p-0" type="button">Provider name<img src={ClipboardIconPopup} className="ms-2" /></button>
                                        </CopyToClipboard>
                                    </OverlayTrigger>
                                    {/* <OverlayTrigger
                                    placement="auto"
                                    overlay={
                                        <Tooltip id="tooltip">
                                            {"Use {amount} in body to attach provider name."}
                                        </Tooltip>
                                    }
                                >
                                    <CopyToClipboard text={'{amount}'} onCopy={() => toast.success('Amount copied!')}>
                                        <button className="btn p-0" type="button">Amount<img src={ClipboardIconPopup} className="ms-2" /></button>
                                    </CopyToClipboard>
                                </OverlayTrigger> */}
                                    <OverlayTrigger
                                        placement="auto"
                                        overlay={
                                            <Tooltip id="tooltip">
                                                {"Use {clinicname} in body to attach provider name."}
                                            </Tooltip>
                                        }
                                    >
                                        <CopyToClipboard text={'{clinicname}'} onCopy={() => toast.success('Clinic name copied!')}>
                                            <button className="btn p-0" type="button">Clinic name<img src={ClipboardIconPopup} className="ms-2" /></button>
                                        </CopyToClipboard>
                                    </OverlayTrigger>
                                    <OverlayTrigger
                                        placement="auto"
                                        overlay={
                                            <Tooltip id="tooltip">
                                                {"Use {speciality} in body to attach speciality."}
                                            </Tooltip>
                                        }
                                    >
                                        <CopyToClipboard text={'{speciality}'} onCopy={() => toast.success('Speciality is copied!')}>
                                            <button className="btn p-0" type="button">Speciality<img src={ClipboardIconPopup} className="ms-2" /></button>
                                        </CopyToClipboard>
                                    </OverlayTrigger>
                                    <OverlayTrigger
                                        placement="auto"
                                        overlay={
                                            <Tooltip id="tooltip">
                                                {"Use {totalamount} in body to attach totalamount."}
                                            </Tooltip>
                                        }
                                    >
                                        <CopyToClipboard text={'{totalamount}'} onCopy={() => toast.success('Total Amount is copied!')}>
                                            <button className="btn p-0" type="button">Total Amount<img src={ClipboardIconPopup} className="ms-2" /></button>
                                        </CopyToClipboard>
                                    </OverlayTrigger>
                                    <OverlayTrigger
                                        placement="auto"
                                        overlay={
                                            <Tooltip id="tooltip">
                                                {"Use {appointmentid} in body to attach appointmentid."}
                                            </Tooltip>
                                        }
                                    >
                                        <CopyToClipboard text={'{appointmentid}'} onCopy={() => toast.success('Appointment Id is copied!')}>
                                            <button className="btn p-0" type="button">Appointment Id<img src={ClipboardIconPopup} className="ms-2" /></button>
                                        </CopyToClipboard>
                                    </OverlayTrigger>
                                    {
                                        (notifiacationName === "Payment Recurring Reminder" || (selectedTemplate && selectedTemplate.notificationname === "Payment Recurring Reminder")) &&
                                        <>
                                            <OverlayTrigger
                                                placement="auto"
                                                overlay={
                                                    <Tooltip id="tooltip">
                                                        {"Use {paidamount} in body to attach paidamount."}
                                                    </Tooltip>
                                                }
                                            >
                                                <CopyToClipboard text={'{paidamount}'} onCopy={() => toast.success('Paid Amount is copied!')}>
                                                    <button className="btn p-0" type="button">Paid Amount<img src={ClipboardIconPopup} className="ms-2" /></button>
                                                </CopyToClipboard>
                                            </OverlayTrigger>
                                            <OverlayTrigger
                                                placement="auto"
                                                overlay={
                                                    <Tooltip id="tooltip">
                                                        {"Use {dueamount} in body to attach dueamount."}
                                                    </Tooltip>
                                                }>
                                                <CopyToClipboard text={'{dueamount}'} onCopy={() => toast.success('Due Amount is copied!')}>
                                                    <button className="btn p-0" type="button">Due Amount<img src={ClipboardIconPopup} className="ms-2" /></button>
                                                </CopyToClipboard>
                                            </OverlayTrigger>
                                            <OverlayTrigger
                                                placement="auto"
                                                overlay={
                                                    <Tooltip id="tooltip">
                                                        {"Use {totalrecurrance} in body to attach totalrecurrance."}
                                                    </Tooltip>
                                                }>
                                                <CopyToClipboard text={'{totalrecurrance}'} onCopy={() => toast.success('Total Recurrance is copied!')}>
                                                    <button className="btn p-0" type="button">Total Recurrance<img src={ClipboardIconPopup} className="ms-2" /></button>
                                                </CopyToClipboard>
                                            </OverlayTrigger>
                                            <OverlayTrigger
                                                placement="auto"
                                                overlay={
                                                    <Tooltip id="tooltip">
                                                        {"Use {remainingrecurrance} in body to attach remainingrecurrance."}
                                                    </Tooltip>
                                                }>
                                                <CopyToClipboard text={'{remainingrecurance}'} onCopy={() => toast.success('Remaining Recurrance is copied!')}>
                                                    <button className="btn p-0" type="button">Remaining Recurrance<img src={ClipboardIconPopup} className="ms-2" /></button>
                                                </CopyToClipboard>
                                            </OverlayTrigger>
                                            <OverlayTrigger
                                                placement="auto"
                                                overlay={
                                                    <Tooltip id="tooltip">
                                                        {"Use {completedrecurrance} in body to attach completedrecurrance."}
                                                    </Tooltip>
                                                }>
                                                <CopyToClipboard text={'{completedrecurrance}'} onCopy={() => toast.success('Completed Recurance is copied!')}>
                                                    <button className="btn p-0" type="button">Completed Recurance<img src={ClipboardIconPopup} className="ms-2" /></button>
                                                </CopyToClipboard>
                                            </OverlayTrigger>
                                            <OverlayTrigger
                                                placement="auto"
                                                overlay={
                                                    <Tooltip id="tooltip">
                                                        {"Use {recurrancetype} in body to attach recurrancetype."}
                                                    </Tooltip>
                                                }>
                                                <CopyToClipboard text={'{recurrancetype}'} onCopy={() => toast.success('Recurance Type is copied!')}>
                                                    <button className="btn p-0" type="button">Recurance Type<img src={ClipboardIconPopup} className="ms-2" /></button>
                                                </CopyToClipboard>
                                            </OverlayTrigger>
                                            <OverlayTrigger
                                                placement="auto"
                                                overlay={
                                                    <Tooltip id="tooltip">
                                                        {"Use {lastpaiddate} in body to attach lastpaiddate."}
                                                    </Tooltip>
                                                }>
                                                <CopyToClipboard text={'{lastpaiddate}'} onCopy={() => toast.success('Last Paid Date is copied!')}>
                                                    <button className="btn p-0" type="button">Last Paid Date<img src={ClipboardIconPopup} className="ms-2" /></button>
                                                </CopyToClipboard>
                                            </OverlayTrigger>
                                            <OverlayTrigger
                                                placement="auto"
                                                overlay={
                                                    <Tooltip id="tooltip">
                                                        {"Use {duedate} in body to attach lastduedate."}
                                                    </Tooltip>
                                                }>
                                                <CopyToClipboard text={'{nextpaymentdate}'} onCopy={() => toast.success('Next Payment Date is copied!')}>
                                                    <button className="btn p-0" type="button">Next Payment Date<img src={ClipboardIconPopup} className="ms-2" /></button>
                                                </CopyToClipboard>
                                            </OverlayTrigger>
                                            <OverlayTrigger
                                                placement="auto"
                                                overlay={
                                                    <Tooltip id="tooltip">
                                                        {"Use {clinicsmsnumber} in body to attach speciality."}
                                                    </Tooltip>
                                                }
                                            >
                                                <CopyToClipboard text={'{clinicsmsnumber}'} onCopy={() => toast.success('ClinicSMSNumber is copied!')}>
                                                    <button className="btn p-0" type="button">ClinicSMSNumber<img src={ClipboardIconPopup} className="ms-2" /></button>
                                                </CopyToClipboard>
                                            </OverlayTrigger>
                                            <OverlayTrigger
                                                placement="right"
                                                overlay={
                                                    <Tooltip id="tooltip">
                                                        {"Use {clinicadminemail} in body to attach speciality."}
                                                    </Tooltip>
                                                }
                                            >
                                                <CopyToClipboard text={'{clinicadminemail}'} onCopy={() => toast.success('ClinicAdminEmail is copied!')}>
                                                    <button className="btn p-0" type="button">ClinicAdminEmail<img src={ClipboardIconPopup} className="ms-2" /></button>
                                                </CopyToClipboard>
                                            </OverlayTrigger>
                                        </>
                                    }
                                </div>
                            </div>
                            <div className="mt-4">
                                {
                                    showEmailTemplate &&
                                    <div className={"my-3 "}>
                                        <label htmlFor="exampleFormControlInput1" className="form-label fw-bold">Subject line</label>
                                        <input type="text" className="form-control" id="exampleFormControlInput1" placeholder="Enter email subject line" name="subject" ref={register({
                                            required: true
                                        })} />
                                        {
                                            errors.subject && errors.subject.type === "required" &&
                                            <div className="is-invalid">Subject is required</div>
                                        }
                                    </div>
                                }
                                <nav>
                                    <div className="nav nav-tabs" id="nav-tab" role="tablist">
                                        {
                                            !showEmailTemplate &&
                                            <button className="nav-link active" id="nav-home-tab" data-bs-toggle="tab" data-bs-target="#nav-home" type="button" role="tab" aria-controls="nav-home" aria-selected="true">Email</button>
                                        }
                                        <button className={"nav-link " + (showEmailTemplate ? "active" : "")} id="nav-profile-tab" data-bs-toggle="tab" data-bs-target="#nav-profile" type="button" role="tab" aria-controls="nav-profile" aria-selected="false">Text SMS</button>
                                        <button className="nav-link" id="nav-contact-tab" data-bs-toggle="tab" data-bs-target="#nav-contact" type="button" role="tab" aria-controls="nav-contact" aria-selected="false">App notification</button>
                                    </div>
                                </nav>
                                <div className="tab-content" id="nav-tabContent">
                                    <div className={"tab-pane fade " + (!showEmailTemplate ? "show active" : "")} id="nav-home" role="tabpanel" aria-labelledby="nav-home-tab">
                                        <div className="row">
                                            <div className="col-6">
                                                <div className="upload-box">
                                                    <label className="fw-bold form-label d-block" htmlFor="exampleFormControlUpload">Upload logo</label>
                                                    <div className="">
                                                        {/* <button className="btn btn-upload">Chose File</button> */}
                                                        {/* <span className="file-txt align-self-center">No File Chosen</span> */}
                                                        <input ref={register} className="btn btn-upload" type="file" name="logo" onChange={onLogoChange} />
                                                        <small className="text-muted mt-1 d-block">Max file size should be 500kb</small>
                                                        {
                                                            errors && errors.logo && errors.logo.type === "logosize" &&
                                                            <div className="is-invalid">{errors.logo.message}</div>
                                                        }
                                                        {
                                                            errors && errors.logo && errors.logo.type === "logotype" &&
                                                            <div className="is-invalid">{errors.logo.message}</div>
                                                        }
                                                        {
                                                            errors && errors.logo && errors.logo.type === "filerequired" &&
                                                            <div className="is-invalid">{errors.logo.message}</div>
                                                        }
                                                        {/* <div className="img-box"> */}
                                                        <input type="hidden" name="logoPreview" ref={register}></input>
                                                        {
                                                            (getValues('logo') && getValues('logo')[0] && getValues('logo')[0].name) ?
                                                                <>
                                                                    <div className="img-box">
                                                                        <img id="logo" src={URL.createObjectURL(getValues('logo')[0])} /></div>
                                                                </>
                                                                :
                                                                (getValues('logoPreview') && getValues('logoPreview') !== '') &&
                                                                <>
                                                                    {
                                                                        (getValues('logoPreview')) &&
                                                                        <>
                                                                            <div className="img-box">
                                                                                <img id="logo" src={getValues('logoPreview')} />
                                                                            </div>
                                                                        </>
                                                                    }
                                                                </>
                                                        }
                                                        {/* </div> */}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-6">
                                                <div className="upload-box">
                                                    <label className="fw-bold form-label d-block" htmlFor="exampleFormControlFile1">Banner image</label>
                                                    <div className="">
                                                        {/* <button className="btn btn-upload">Chose File</button>
                                <span className="file-txt align-self-center">No File Chosen</span> */}
                                                        <input ref={register} className="btn btn-upload" type="file" name="banner" onChange={onBannerChange} />

                                                        <small className="text-muted mt-1 d-block">Max file size should be 500kb</small>
                                                        {
                                                            errors && errors.banner && errors.banner.type === "bannersize" &&
                                                            <div className="is-invalid">{errors.banner.message}</div>
                                                        }
                                                        {
                                                            errors && errors.banner && errors.banner.type === "bannertype" &&
                                                            <div className="is-invalid">{errors.banner.message}</div>
                                                        }
                                                        {
                                                            errors && errors.banner && errors.banner.type === "filerequired" &&
                                                            <div className="is-invalid">{errors.logo.message}</div>
                                                        }
                                                        {/* <div className="img-box"> */}
                                                        <input type="hidden" name="bannerPreview" ref={register}></input>
                                                        {
                                                            (getValues('banner') && getValues('banner')[0] && getValues('banner')[0].name) ?
                                                                <> <div className="img-box"><img id="banner" src={URL.createObjectURL(getValues('banner')[0])} /></div></> :
                                                                (getValues('bannerPreview') && getValues('bannerPreview') !== '') &&
                                                                <>
                                                                    {
                                                                        (getValues('bannerPreview')) &&
                                                                        <> <div className="img-box"><img id="banner" src={getValues('bannerPreview')} /></div></>
                                                                    }
                                                                </>
                                                        }
                                                        {/* </div> */}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {
                                            !showEmailTemplate &&
                                            <div className="my-3">
                                                <label htmlFor="exampleFormControlInput1" className="form-label fw-bold">Subject line</label>
                                                <input type="text" className="form-control" id="exampleFormControlInput1" placeholder="Enter email subject line" name="subject" ref={register({
                                                    required: showEmailTemplate ? true : false
                                                })} />
                                                {
                                                    errors.subject && errors.subject.type === "required" &&
                                                    <div className="is-invalid">subject is required</div>
                                                }
                                            </div>
                                        }
                                        <div className="my-3">
                                            <label className="form-label fw-bold">Content</label>
                                            <div className="editor-box form-control">
                                                <Controller
                                                    name="body"
                                                    control={control}
                                                    render={(props) => {
                                                        return (
                                                            <RichTextEditor value={props.value ? props.value : RichTextEditor.createValueFromString('', 'html')}
                                                                onChange={(value) => props.onChange(value)}
                                                            ></RichTextEditor>
                                                        )
                                                    }}
                                                ></Controller>
                                                {/* <textarea id="PracticeDescription" rows={3} name="body">When you need family dental care, you want a dentist that provides you with comfortable and convenient services in a relaxing atmosphere.</textarea> */}
                                            </div>

                                        </div>
                                        {/*  <div className="my-3">
                          <div className="d-flex form-check form-switch justify-content-between border p-3 rounded-10">
                            <label className="form-check-label" htmlFor="flexSwitchCheckDefault">Social media links</label>
                            <input className="form-check-input m-0 align-self-center" type="checkbox" id="flexSwitchCheckDefault" />
                          </div>
                        </div> */}
                                    </div>
                                    <div className={"tab-pane fade " + (showEmailTemplate ? "active show" : "")} id="nav-profile" role="tabpanel" aria-labelledby="nav-profile-tab">
                                        <div>
                                            <label htmlFor="exampleFormControlTextarea1" className="form-label fw-bold">Text message content</label>
                                            <textarea className="form-control mb-1" id="exampleFormControlTextarea1" rows={3} name="textMsgInput" ref={register({
                                                required: true
                                            })}></textarea>
                                            <small className="text-muted">The character limit for a single SMS message is 160 characters</small>
                                        </div>
                                    </div>
                                    <div className="tab-pane fade" id="nav-contact" role="tabpanel" aria-labelledby="nav-contact-tab">
                                        <div>
                                            <label htmlFor="exampleFormControlTextarea1" className="form-label fw-bold">App notification content</label>
                                            <textarea className="form-control mb-1" id="exampleFormControlTextarea1" rows={3} name="appNotificationInput" ref={register({
                                                required: true
                                            })}></textarea>
                                            <small className="text-muted">The character limit for a single app notification is 250 characters</small>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {
                                errors.textMsgInput && errors.textMsgInput.type === "required" &&
                                <div className="is-invalid">Text Message is Required</div>
                            }
                            {
                                errors.appNotificationInput && errors.appNotificationInput.type === "required" &&
                                <div className="is-invalid">App Notification isRequired</div>
                            }
                        </div>
                        <div className="modal-footer">
                            <button type="button" disabled={isLoading} className="btn btn-border" data-bs-dismiss="modal" onClick={hideModal}>Cancel</button>
                            <button disabled={isLoading} type="submit" className="btn btn-primary">
                                {
                                    isLoading &&
                                    <span className="spinner-border spinner-border-sm me-1" role="status" aria-hidden="true"></span>
                                }
                                {
                                    selectedTemplate && 'Save'
                                }
                                {
                                    !selectedTemplate && 'Submit'
                                }
                            </button>
                        </div>
                    </div>
                </form>
            }
        </Modal>
    )
}