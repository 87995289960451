import { USER_PASSWORD, FetchUserPasswordErrorAction, FetchUserPasswordPendingAction, FetchUserPasswordSuccessAction, UserPasswordRequest, UserPasswordResponse } from './types';
/* Action Creators */
export const fetchUserPasswordPending = (userPassword: UserPasswordRequest): FetchUserPasswordPendingAction => {
    return {
        type: USER_PASSWORD.LOAD_PENDING,
        payload: userPassword
    };
};

export const fetchUserPasswordSuccess = (userPasswordResponse: UserPasswordResponse): FetchUserPasswordSuccessAction => {
    return {
        type: USER_PASSWORD.LOAD_SUCCESS,
        userPassword: userPasswordResponse
    }
}

export const fetchUserPasswordError = (err: any): FetchUserPasswordErrorAction => {
    return {
        type: USER_PASSWORD.LOAD_ERROR,
        error: err
    }
}