// import './_practiceSteps.scss';
import { DatePicker, KeyboardDatePicker } from '@material-ui/pickers';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { fetchLoadingPending, fetchLoadingSuccess } from '../../../store/loadingIndicator/actions';
import { createStep } from '../../services/providerOnboard';
import AddEduBtn from "../../assets/images/add-edu-btn.svg";
import RemoveEduBtn from "../../assets/images/remove-edu-btn.svg";
export function ProviderApp6(props: any) {
	const [educationDetailList, setEducationDetailList]: any = useState([]);
	const [certificationList, setCertificationList]: any = useState([]);

	const [associationName, setAssociationName] = useState('');
	const [receviedDate, setreceviedDate] = useState(new Date());
	const [registrationNumber, setregistrationNumber] = useState('');
	const [degree, setDegree] = useState('');
	const [college, setCollege] = useState('');
	const [year, setYear] = useState(moment().format('YYYY'));
	const dispatch = useDispatch();
	const [associationNameValidation, setAssociationNameValidation] = useState({ associationNameValidation: false, msg: '' });
	const [registrationNumberValidation, setregistrationNumberValidation] = useState({ registrationNumberValidation: false, msg: '' });
	const [degreeValidation, setdegreeValidation] = useState({ degreeValidation: false, msg: '' });
	const [collegeValidation, setcollegeValidation] = useState({ collegeValidation: false, msg: '' });

	const addEducationDetail = () => {

		if (associationName === '') {
			setAssociationNameValidation({ associationNameValidation: true, msg: "Association Name Requried" });
			return;
		}
		if (registrationNumber === '') {
			setregistrationNumberValidation({ registrationNumberValidation: true, msg: "Register Number is Requried" });
			return;
		}
		const edurequest = {
			associationName: associationName,
			receviedDate: moment(receviedDate).format('DD-MM-YYYY'),
			registrationNumber: registrationNumber
		}
		setEducationDetailList([...educationDetailList, edurequest]);
		setAssociationName(''); setreceviedDate(new Date()); setregistrationNumber('');
		setAssociationNameValidation({ associationNameValidation: false, msg: "" });
		setregistrationNumberValidation({ registrationNumberValidation: false, msg: "" });
	};

	const addCertificationDetail = () => {

		if (degree === '') {
			setdegreeValidation({ degreeValidation: true, msg: "Degree is Requried" });
			return;
		}
		if (college === '') {
			setcollegeValidation({ collegeValidation: true, msg: "College is Requried" });
			return;
		}
		const certificationrequest = {
			degree: degree,
			college: college,
			year: year
		}

		setCertificationList([...certificationList, certificationrequest]);
		setDegree(''); setYear(moment().format('YYYY')); setCollege('');
		setdegreeValidation({ degreeValidation: false, msg: "" });
		setcollegeValidation({ collegeValidation: false, msg: "" });
	};

	const removeEducationDetail = (index: any) => {
		setEducationDetailList(educationDetailList.filter((item: any, iIndex: number) => {
			return iIndex !== index
		}));
	};

	const removeCertificationDetail = (index: any) => {
		setCertificationList(certificationList.filter((item: any, iIndex: number) => {
			return iIndex !== index
		}));
	};
	const onFinish = (skip: boolean) => {
		if (skip === true) {
			props.onFinish();
			props.setPercentage(100);
			return;
		}
		const request = {
			educations: JSON.stringify(educationDetailList),
			certificates: JSON.stringify(certificationList),
			uniqueid: props.request.clinicUniqueId,
			providerId: props.request.providerId
		};
		dispatch(fetchLoadingPending());
		createStep({ stepNumber: 6, formData: request }).then((success) => {
			dispatch(fetchLoadingSuccess(false));
			if (success.status && success.status.error === false) {
				props.onFinish();
				props.setPercentage(100);
			}
		}).catch((err) => {
			dispatch(fetchLoadingSuccess(false));
		});

	};

	const handleDateChange = (date: any) => {
		console.log(date);
		setreceviedDate(date);
	}

	useEffect(() => {
		if (props.data) {
			setEducationDetailList(props.data.educationDetailList);
			setCertificationList(props.data.certificationList);
		}
	}, [props.data]);
	return (
		<>
			<div className="row">
				<div className="col-12">
					<h2 className="mb-3">Education & Certifications</h2>
					<div className="fw-medium fs-5">Add {props.request.personalInfo ? props.request.personalInfo.fullname : null} board Certifications.</div>
					<p className="mt-1">Add {props.request.personalInfo ? props.request.personalInfo.fullname + '\’s' : null} Board Certifications Details.</p>
					<hr className="my-4" />
				</div>
			</div>
			<div className="row">
				<div className="col-12">
					{
						educationDetailList.map((item: any, index: number) => {
							return (
								<div className="d-flex border rounded justify-content-between edu-box p-4 my-3">
									<div className="association-name me-3">
										<small className="d-block text-muted">Association Name</small>
										{item.associationName}
									</div>
									<div className="received-date me-3">
										<small className="d-block text-muted">Received date</small>
										{item.receviedDate}
									</div>
									<div className="registration-no me-3">
										<small className="d-block text-muted">Registration Number</small>
										{item.registrationNumber}
									</div>
									<button className="btn p-0" onClick={() => { removeEducationDetail(index) }}><img src={RemoveEduBtn} /></button>
								</div>
							)
						})
					}
					<div className="d-flex justify-content-between align-items-start add-new-edu mt-4">
						<div className="col-3 me-3">
							<label htmlFor="medAssociation" className="form-label m-0">Association Name</label>
							<input type="text" className="form-control" id="medAssociation" placeholder="ex. Medical Association" value={associationName} onChange={(e) => { setAssociationName(e.target.value) }} />
							{
								associationNameValidation.associationNameValidation === true &&
								<div className="is-invalid">{associationNameValidation.msg}</div>
							}
						</div>

						<div className="col-3 me-3 miuif_margin">
							<label htmlFor="receivedDate" className="form-label m-0 d-block">Received date</label>
							{/* <input type="text" className="form-control" id="practicetName" placeholder="ex. Feb 09,1994" value={receviedDate} onChange={(e) => { setreceviedDate(e.target.value) }} /> */}
							<KeyboardDatePicker
								disableToolbar
								autoOk
								variant="inline"
								format={'DD-MM-YYYY'}
								margin="none"
								id="receivedDate"
								//label=""
								value={receviedDate}
								onChange={handleDateChange}
								KeyboardButtonProps={{
									'aria-label': 'change event date',
								}}
							/>
						</div>
						<div className="col-3 me-3">
							<label htmlFor="regNumber" className="form-label m-0">Registration Number</label>
							<input type="text" className="form-control" id="regNumber" placeholder="ex. DA-xxxx or xxxx etc..." value={registrationNumber} onChange={(e) => { setregistrationNumber(e.target.value) }} />
							{
								registrationNumberValidation.registrationNumberValidation === true &&
								<div className="is-invalid">{registrationNumberValidation.msg}</div>
							}
						</div>

						<button className="btn p-0 mt-4" onClick={() => { addEducationDetail() }}>
							<img src={AddEduBtn} className="img-fluid" />
						</button>
					</div>
				</div>
			</div>
			<div className="row">
				<div className="col-12 mt-5">
					<div className="fw-bold fs-5">Add {props.request.personalInfo ? props.request.personalInfo.fullname : null} education details.</div>
					<p className="mt-1">Add {props.request.personalInfo ? props.request.personalInfo.fullname + '\’s' : null} Education Details.</p>
					<hr className="my-4" />
				</div>
				<div className="col-12">


					{
						certificationList.map((item: any, index: number) => {
							return (
								<div className="d-flex border rounded justify-content-between edu-box p-4 my-3">
									<div className="association-name me-3">
										<small className="d-block text-muted">Degree</small>
										{item.degree}
									</div>
									<div className="received-date me-3">
										<small className="d-block text-muted">University/College</small>
										{item.college}
									</div>
									<div className="registration-no me-3">
										<small className="d-block text-muted">Year</small>
										{item.year}
									</div>
									<button className="btn p-0" onClick={() => { removeCertificationDetail(index) }}><img src={RemoveEduBtn} /></button>
								</div>
							)
						})
					}

					<div className="d-flex justify-content-between align-items-start add-new-edu mt-4">
						<div className="col-3 me-3">
							<label htmlFor="degree" className="form-label m-0">Degree</label>
							<input type="text" className="form-control" id="degree" placeholder="ex. Doctor of Dental Surgery" value={degree} onChange={(e) => { setDegree(e.target.value) }} />
							{
								degreeValidation.degreeValidation === true &&
								<div className="is-invalid">{degreeValidation.msg}</div>
							}
						</div>
						<div className="col-3 me-3">
							<label htmlFor="college" className="form-label m-0">University/College</label>
							<input type="text" className="form-control" id="college" placeholder="Canadian dentist " value={college} onChange={(e) => { setCollege(e.target.value) }} />
							{
								collegeValidation.collegeValidation === true &&
								<div className="is-invalid">{collegeValidation.msg}</div>
							}
						</div>
						<div className="col-3 me-3 miuif_margin">
							<label htmlFor="year" className="form-label m-0 d-block">Year</label>
							{/* <input type="text" className="form-control" id="practicetName" placeholder="1994" value={year} onChange={(e) => { setYear(e.target.value) }} /> */}
							<DatePicker
								views={["year"]}
								id="year"
								placeholder="1994"
								value={year}
								onChange={(year: any) => { setYear(moment(year).format('YYYY')) }}
							/>
						</div>
						<button className="btn p-0 mt-4" onClick={() => { addCertificationDetail() }}>
							<img src={AddEduBtn} className="img-fluid" />
						</button>
					</div>
				</div>
			</div>
			<div className="row">
				<div className="col-12 my-4">
					<div className="bottom-btn-sec d-flex justify-content-between">
						<div>
							<button className="btn border me-1" onClick={() => { props.onPrev(4) }}>Back</button>
						</div>
						<div>
							<button className="btn btn-primary me-2" onClick={() => { onFinish(true) }}>Skip & Finish</button>
							<button disabled={certificationList.length === 0 || educationDetailList.length === 0} className="btn btn-primary ms-1" onClick={() => { onFinish(false) }}>Finish</button>
						</div>
					</div>
				</div>
			</div>
		</>
	);
}
