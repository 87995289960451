import Axios from 'axios';
import { fetchSpecialityError, fetchSpecialitySuccess, providerAddError, providerAddSuccess } from './../store/addProvider/actions';
import {
    ADD_PROVIDER, ProviderAddRequest, ProviderAddPendingAction,
} from './../store/addProvider/types';
import { takeEvery, put } from "redux-saga/effects";
import axios from 'axios';
import CONFIG from '../configs/apiConfigs';
import { fetchLoadingPending, fetchLoadingSuccess } from '../store/loadingIndicator/actions';
import { getCookie, prepareCookie } from '../utils/cookies';
import apiConfigs from '../configs/apiConfigs';

function execute() {
    const headers = {
        'Content-Type': 'application/json',
        'x-access-param': getCookie().xprAuthUser,
        'xpr_user_id': getCookie().xpr_user_id,
        'xcookie': prepareCookie()
        // 'X-Auth-Token': '97e0d315477f435489cf04904c9d0e6co',
    };
    return axios.get(CONFIG.API_URL + "specialities/", { headers: headers, withCredentials: true }).then(response => ({ response }), error => ({ error }));
}
function* fetchSpecialityPendingRun() {
    //    yield delay(2000);
    yield put(fetchLoadingPending());
    const { response, error } = yield execute();
    if (response) {
        if (response.status === 200) {
            if (response.data.status.error === true) {
                response.data.type = 'speciality';
                yield put(fetchSpecialityError(response.data));
            } else {
                yield put(fetchSpecialitySuccess(response.data));
            }
        } else {
            yield put(fetchSpecialityError(response));
        }
    }
    if (error) {
        yield put(fetchSpecialityError(error));
    }
    yield put(fetchLoadingSuccess(false));
}

export default function* fetchSpecialityPendingWatcher() {
    yield takeEvery(ADD_PROVIDER.FETCH_SPECIALITY_PENDING, fetchSpecialityPendingRun);
}

function executeProviderAdd(request: ProviderAddRequest) {
    const headers = {
        'Content-Type': 'multipart/form-data',
        // 'x-access-param': getCookie().authParam,
        'x-access-param': getCookie().xprAuthUser,
        'xpr_user_id': request.uniqueid,
        'xcookie': prepareCookie()
    };
    const formData = new FormData();
    formData.append('logo', request.logo);
    formData.append('fullname', request.fullname);
    formData.append('gender', request.gender);
    formData.append('email', request.email);
    formData.append('languages', JSON.stringify(request.Languages));
    formData.append('speciality', JSON.stringify(request.speciality));
    formData.append('clinics', JSON.stringify(request.clinics));
    return Axios.post(apiConfigs.API_URL + "doctor/" + request.uniqueid + "/create/", formData, {
        headers: headers,
        withCredentials: true,
    }).then(response => ({ response }), error => ({ error }));
}
function* pendingProviderAddRun(action: ProviderAddPendingAction) {
    //    yield delay(2000);
    yield put(fetchLoadingPending());
    const { response, error } = yield executeProviderAdd(action.payload);
    if (response) {
        if (response.status === 200) {
            if (response.data.status.error === true) {
                response.data.type = 'add';
                yield put(providerAddError(response.data));
            } else {
                yield put(providerAddSuccess(response.data));
            }
        } else {
            yield put(providerAddError(response));
        }
    }
    if (error) {
        yield put(providerAddError(error))
    }
    yield put(fetchLoadingSuccess(false));
}

export function* providerAddPendingWatcher() {
    yield takeEvery(ADD_PROVIDER.ADD_PROVIDER_PENDING, pendingProviderAddRun);
}