// import './_practiceSteps.scss';
import React, { useEffect, useState } from 'react';
import { createStep, getFeatures, getHygiene } from '../../services/clinicOnboard';
import { useDispatch } from 'react-redux';
import { fetchLoadingPending, fetchLoadingSuccess } from '../../../store/loadingIndicator/actions';
export function PracticeApp6(props: any) {
	const [practiceHygieneFeature, setPracticeHygieneFeature]: any = useState([]);
	const [practiceGeneralFeature, setPracticeGeneralFeature]: any = useState([]);
	const dispatch = useDispatch();
	const onNext = () => {
		const request = {
			hygieneFeatures: practiceHygieneFeature.filter((item: any) => item.isChecked),
			generalFeatures: practiceGeneralFeature.filter((item: any) => item.isChecked)
		};
		const requestData = {
			uniqueid: props.clinicUniqueId,
			features: practiceGeneralFeature.filter((item: any) => item.isChecked).map((item: any) => item.id).toString(),
			hygiene: practiceHygieneFeature.filter((item: any) => item.isChecked).map((item: any) => item.id).toString(),
		};
		dispatch(fetchLoadingPending());
		createStep({ stepNumber: 8, formData: requestData }).then((success) => {
			dispatch(fetchLoadingSuccess(false));
			if (success.status && success.status.error === false) {
				props.onNext(8, {
					featureAndFacility: request
				});
			};
		}).catch((err) => {
			dispatch(fetchLoadingSuccess(false));
		});
	};
	useEffect(() => {
		dispatch(fetchLoadingPending());
		getHygiene().then((success) => {
			dispatch(fetchLoadingSuccess(false));
			if (success.status && success.status.error === false) {
				if (props.data && props.data.hygieneFeatures) {
					setPracticeHygieneFeature(success.hygieneFeatures.map((item: any) => {
						if (props.data.hygieneFeatures.find((hItem: any) => hItem.id === item.id)) {
							return { ...item, isChecked: true };
						}
						return { ...item, isChecked: false };
					}));
				} else {
					setPracticeHygieneFeature(success.hygieneFeatures.map((item: any) => ({ ...item, isChecked: false })));
				}
			}
		}).catch((err) => {
			dispatch(fetchLoadingSuccess(false));
		});
	}, []);
	useEffect(() => {
		let speciality: any = "";
		if (props.specialities && props.specialities.specialities) {
			speciality = props.specialities.specialities.map((item: any) => item.id).toString();
		}
		if (speciality !== "") {
			dispatch(fetchLoadingPending());
			getFeatures(speciality).then((success) => {
				dispatch(fetchLoadingSuccess(false));
				if (success.status && success.status.error === false) {
					if (props.data && props.data.generalFeatures) {
						setPracticeGeneralFeature(success.features.map((item: any) => {
							if (props.data.generalFeatures.find((gItem: any) => gItem.id === item.id)) {
								return { ...item, isChecked: true };
							}
							return { ...item, isChecked: false };
						}));
					} else {
						setPracticeGeneralFeature(success.features.map((item: any) => ({ ...item, isChecked: false })));
					}
				}
			}).catch((err) => {
				dispatch(fetchLoadingSuccess(false));
			});
		}
	}, [props.specialities]);
	useEffect(() => {
		if (props.data) {
			setPracticeHygieneFeature(practiceHygieneFeature.map((item: any) => {
				if (props.data.hygieneFeatures.find((hItem: any) => hItem.id === item.id)) {
					return {
						...item,
						isChecked: true
					}
				}
				return item;
			}));
			setPracticeGeneralFeature(practiceGeneralFeature.map((item: any) => {
				if (props.data.generalFeatures.find((hItem: any) => hItem.id === item.id)) {
					return {
						...item,
						isChecked: true
					}
				}
				return item;
			}));
		}
	}, [props.data]);
	let isNextEnable = false;
	if (practiceGeneralFeature.length > 0 && practiceGeneralFeature.filter((item: any) => item.isChecked === true).length === 0) {
		isNextEnable = true;
	}
	if (practiceHygieneFeature.length > 0 && practiceHygieneFeature.filter((item: any) => item.isChecked === true).length === 0) {
		isNextEnable = true;
	}
	return (
		<>
			<div className="row justify-content-center">
				<div className="col-12">
					<h2 className="mb-3">Practice Features</h2>
					<div className="fw-medium fs-5">Practice Hygiene Features.</div>
					<p className="mt-1">Please choose your clinic hygiene features below.</p>
					<hr className="my-4" />
				</div>
				{
					practiceHygieneFeature.length > 0 &&
					<div className="col-12 mb-5">
						<div className="fw-medium mb-3">Practice Hygiene Features</div>
						<div className="features-check-list custom-check-list d-flex flex-wrap">
							{
								practiceHygieneFeature.map((item: any, index: number) => {
									return (
										<div className="form-check" key={index}>
											<input className="form-check-input" type="checkbox" id={item.title + item.id} value={item.id} checked={item.isChecked} onChange={(e) => {
												setPracticeHygieneFeature(practiceHygieneFeature.map((pItem: any) => {
													if (pItem.id === item.id) {
														return {
															...pItem,
															isChecked: !pItem.isChecked
														}
													}
													return pItem;
												}));
											}} />
											<label className="form-check-label rounded-2 border w-100" htmlFor={item.title + item.id}>
												{item.title}
											</label>
										</div>
									)
								})
							}
						</div>
					</div>
				}
				{
					practiceGeneralFeature.length > 0 &&
					<div className="col-12">
						<div className="fw-medium mb-3">Practice general features</div>
						<div className="features-check-list custom-check-list d-flex flex-wrap">
							{
								practiceGeneralFeature.map((item: any, index: number) => {
									return (
										<div className="form-check" key={item.id + item.name}>
											<input className="form-check-input" type="checkbox" value={item.name} id={item.name + item.id} checked={item.isChecked} onChange={(e: any) => {
												setPracticeGeneralFeature(practiceGeneralFeature.map((gItem: any) => {
													if (gItem.id === item.id) {
														return {
															...gItem,
															isChecked: !gItem.isChecked
														}
													}
													return gItem;
												}));
											}} />
											<label className="form-check-label rounded-2 border w-100" htmlFor={item.name + item.id}>
												{item.name}
											</label>
										</div>
									)
								})
							}
						</div>
					</div>
				}
				<div className="col-12 my-4">
					<div className="bottom-btn-sec d-flex justify-content-between">
						<button className="btn border me-1" onClick={() => { props.onPrev(6) }}>Back</button>
						<button className="btn btn-primary ms-1" disabled={isNextEnable} onClick={() => {
							onNext();
						}}>Next</button>
					</div>
				</div>
			</div>
		</>
	);
}
