import {
    UserPasswordActionTypes,
    UserPasswordState,
    USER_PASSWORD
} from './types';

export const initialState: UserPasswordState = {
    pending: false,
    userPassword: { rpassword: '', npassword: '', cpassword: '' },
    userPasswordResponse: { status: { error: false, msg: '' } },
    error: null
};

export const userPasswordReducer = (state = initialState, action: UserPasswordActionTypes) => {
    const NewState = { ...state }
    switch (action.type) {
        case USER_PASSWORD.LOAD_ERROR:
            return {
                ...state,
                pending: false,
                error: action.error
            }
        case USER_PASSWORD.LOAD_PENDING:
            return {
                ...state,
                pending: true,
                userPassword: action.payload
            }
        case USER_PASSWORD.LOAD_SUCCESS:
            return {
                ...state,
                pending: false,
                userPasswordResponse: action.userPassword
            }
        default:
            return NewState;

    }
};