import React, { useEffect, useMemo, useState } from 'react';
import { ProgressBar } from './ProgressBar';
import { ProviderApp1 } from './provider-App-1';
import { ProviderApp2 } from './provider-App-2';
import { ProviderApp3 } from './provider-App-3';
import { ProviderApp4 } from './provider-App-4';
import { ProviderApp5 } from './provider-App-5';
import { ProviderApp6 } from './provider-App-6';
import './../onboardClinic/_practiceSteps.scss';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router';
import { IAppState } from '../../../store';
import { toast } from 'react-toastify';
import { confirmAlert } from 'react-confirm-alert';
export const OnboardDoctor = () => {
  const [step, setStep] = useState(0);
  const [request, setRequest]: any = useState({});
  const [percentage, setPercentage] = useState(0);
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  let userDataSuccess = useSelector((state: IAppState) => state.userData.userDataResponse);

  useEffect(() => {
    if (step === 0) {
      setRequest({
        clinicId: userDataSuccess.clinics[0].id,
        clinicUniqueId: userDataSuccess.clinics[0].uniqueId,
        clinicName: userDataSuccess.clinics[0].name
      })
    }
  }, []);
  const onNext = (nextStep: any, requestToAdd: any) => {
    setRequest({ ...request, ...requestToAdd });
    let isPmsTaken = userDataSuccess.accessEnabled.pms_integration;
    if (!isPmsTaken && nextStep === 3) {
      setStep(nextStep +1);
    } else {
      setStep(nextStep);
    }
    
  }

  const changePercentage = (percentage: any) => {
    setPercentage(percentage);
  }
  const onPrev = (prevStep: any) => {
     let isPmsTaken = userDataSuccess.accessEnabled.pms_integration;
    if (!isPmsTaken && prevStep === 3) {
      setStep(prevStep -1);
    } else {
      setStep(prevStep);
    }
  }

  const onFinish = (educationDetail: any) => {
    // toast.success("Provider added successfully");
    confirmAlert({
      title: 'Provider added successfully.',
      message: 'You can enable/disable provider from list or profile section of provider.',
      closeOnEscape: false,
      closeOnClickOutside: false,
      buttons: [
        {
          label: 'Ok',
          onClick: () => {
            history.replace('/provider/list');
          }
        }
      ]
    });
  };
  return (
    <>
      <section className="practice-step-sec">
        <div className="progress m-0 custom-progress overflow-visible" style={{ height: '3px', width: '100%' }}>
          <div className="progress-bar overflow-visible" role="progressbar" style={{ width: percentage + '%' }}> <span className="fw-medium text-primary progper">{percentage}%</span></div>
          <button type="button" className="btn-close" aria-label="Close" onClick={() => { history.replace('/provider/list') }} ></button>
        </div>

        <div className="container-fluid border-bottom py-5 mb-5">
          <ProgressBar step={step}></ProgressBar>
        </div>

        <div className="container">
          <div className="row justify-content-center">
            <div className="col-xl-8 col-lg-10 col-12">
              {
                step === 0 &&
                <ProviderApp1 nextStep={onNext} setPercentage={changePercentage} data={request.personalInfo} clinicId={request.clinicId} clinicUniqueId={request.clinicUniqueId}></ProviderApp1>
              }
              {
                useMemo(() => {
                  return (
                    <>
                      {
                        step === 1 &&
                        <ProviderApp2 nextStep={onNext} setPercentage={changePercentage} onPrev={onPrev} data={request.selectedServices} request={request}></ProviderApp2>
                      }
                    </>
                  )
                }, [request.speciality, onNext, changePercentage, onPrev, request.selectedServices])
              }
              {
                step === 2 &&
                <ProviderApp3 nextStep={onNext} onPrev={onPrev} setPercentage={changePercentage} data={request.hours} clinicUniqueId={request.clinicUniqueId} request={request} ></ProviderApp3>
              }
              {
                step === 3 &&
                <ProviderApp4 nextStep={onNext} onPrev={onPrev} setPercentage={changePercentage} data={request.selectedMapping} clinicId={request.clinicId} request={request}></ProviderApp4>
              }
              {
                step === 4 &&
                <ProviderApp5 nextStep={onNext} onPrev={onPrev} setPercentage={changePercentage} data={request.profileDescription} request={request} clinicId={request.clinicId}></ProviderApp5>
              }
              {
                step === 5 &&
                <ProviderApp6 onFinish={onFinish} onPrev={onPrev} setPercentage={changePercentage} data={request.educationDetail} request={request}></ProviderApp6>
              }
            </div>
          </div>
        </div>
      </section>
    </>
  );
}