import { Paper } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, Route, Switch, useHistory, useLocation } from 'react-router';
import { IAppState } from '../../../store';
import { Calendar } from './Calendar';
import { Cancelled } from './Cancelled';
import { Completed } from './Completed';
import { Upcoming } from './Upcoming';
import { Waitlist } from './Waitlist';
import { Scheduler } from '@devexpress/dx-react-scheduler-material-ui';
import { Appointments, WeekView } from '@devexpress/dx-react-scheduler-material-ui';
import { fetchUserDataPending } from '../../../store/userData/actions';
import { All } from './All';
import { Emergency } from './Emergency';
import { Requested } from './Requested';
import { Confirmed } from './Confirmed';
import { Reschedule } from './Reschedule';
import { fetchInitPaymentPending, servicesTypePending } from '../../../store/payment/actions';
export const MySchedule = () => {
    const [showNoProvider, setShowNoProvider] = useState(false);
    const userData = useSelector((state: IAppState) => state.userData.userDataResponse);
    const history = useHistory();
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(fetchUserDataPending({}));
    }, [])
    useEffect(() => {
        if (userData && userData.clinics && userData.clinics[0] && userData.status && userData.status.error === false && userData.status.msg !== "") {
            let providers = userData.clinics[0].provider;
            providers = providers.filter((item: any) => item.onBoarding.status === 1 && item.enableAppointment === "1");
            if (providers.length === 0) {
                setShowNoProvider(true);
            }
            dispatch(fetchInitPaymentPending({}));
            dispatch(servicesTypePending({}));
        }
    }, [userData]);
    return (
        <div className="position-relative" id="position-rel">
            {
                showNoProvider &&
                <div className="overlay-center">
                    <div className="vertical-center">
                        <div className="card">
                            <div className="card-body text-center p-5">
                                <div className="mb-3">You have no provider.</div>
                                <button className="btn btn-primary" type="button" onClick={(e) => {
                                    history.push('/doctor/create');
                                }}>Add provider</button>
                            </div>
                        </div>
                    </div>
                </div>
            }
            <Switch>
                <Route path="/myschedule/calendar" component={Calendar}></Route>
                <Route path="/myschedule/upcoming" component={Upcoming}></Route>
                <Route path="/myschedule/waitlist" component={Waitlist}></Route>
                <Route path="/myschedule/requests" component={Requested}></Route>
                <Route path="/myschedule/cancelled" component={Cancelled}></Route>
                <Route path="/myschedule/completed" component={Completed}></Route>
                <Route path="/myschedule/all" component={All}></Route>
                <Route path="/myschedule/confirmed" component={Confirmed}></Route>
                <Route path="/myschedule/reschedule" component={Reschedule}></Route>
                <Route path="/myschedule/emergency" component={Emergency}></Route>
                <Redirect to="/myschedule/calendar"></Redirect>
            </Switch>
            {
                showNoProvider &&
                <>
                    <Paper>
                        <Scheduler
                            data={[]}>
                            <WeekView startDayHour={7} endDayHour={23}></WeekView>
                            <Appointments />
                        </Scheduler>
                    </Paper>
                </>
            }
        </div>
    );
};