import React, { Component } from 'react';
import moment from 'moment';
import { ReadMore } from '../../components/subscription/ReadMore';
import { connect } from 'react-redux';
import { IAppState } from '../../../store';
import { myPlan } from '../../../store/subscriptionplan/actions';
import { ClinicMyplanRequest, SubscriptionState } from '../../../store/subscriptionplan/types';
import { UserDataResponse } from '../../../store/userData/types';
import { toast } from 'react-toastify';
import { OverlayTrigger, Popover } from 'react-bootstrap';
import { RenewPlanMessage } from '../../components/subscription/RenewPlanMessage';
import { roundNumberWithRoundingFactorDecimalFix } from '../../../utils/global';

interface Props {
    goToPlans: any;
    selectedPlan: any;
    addons: any;
    selectedAddon: any;
    history: any;
    userDataResponse: UserDataResponse;
    myPlan: typeof myPlan;
    subscriptionState: SubscriptionState;

}
interface State {
    selectedPlan: any;
    addons: any;
    showReadMore: boolean;
    myPlanList: any;
    isStandardPlanCancel: boolean;
    isStandardPlan: boolean;
    isReNewPlan: boolean;
    renewRequest: any;
    isReNewBtnShow: boolean;
    couponMessage: any;
    isAllInOnePlan: boolean;
    currentPlanType: any;
}
class Plans extends Component<Props, State>{
    constructor(props: any) {
        super(props);
        this.state = {
            selectedPlan: [], addons: [], showReadMore: false,
            myPlanList: [], isStandardPlanCancel: false, isStandardPlan: false, isReNewPlan: false, renewRequest: '',
            isReNewBtnShow: true, couponMessage: '',isAllInOnePlan:false,currentPlanType:''
        }
    }
    componentDidMount() {
        if (this.props.selectedPlan) {
            this.setState({ selectedPlan: this.props.selectedPlan, couponMessage: this.props.selectedPlan.coupon_msg });
            if (this.props.selectedPlan && this.props.selectedPlan.active_plans && this.props.selectedPlan.active_plans.length > 0) {
                for (let i = 0; i < this.props.selectedPlan.active_plans.length; i++) {
                    if (this.props.selectedPlan.active_plans[i].name === "Standard") {
                        this.setState({ isStandardPlan: true, currentPlanType: this.props.selectedPlan.active_plans[i].interval });
                        if (this.props.selectedPlan.active_plans[i].product_mode === "allinone") {
                            this.setState({ isAllInOnePlan: true });
                        }
                    }
                }
            }

        }
        if (this.props.addons) {
            if (this.props.selectedPlan && this.props.selectedPlan.active_plans && this.props.selectedPlan.active_plans[0].interval === 'month') {
                this.setState({ addons: this.props.addons.month });
            } else {
                this.setState({ addons: this.props.addons.year });
            }
        }
        if (this.props.userDataResponse && this.props.userDataResponse.clinics && this.props.userDataResponse.clinics.length > 0) {
            this.props.myPlan({ clinicUniqueId: this.props.userDataResponse.clinics[0].uniqueId });
        }
    }
    componentDidUpdate(prevProps: Props, prevState: State) {
        if (this.props.addons !== prevProps.addons) {
            if (this.props.selectedPlan && this.props.selectedPlan.active_plans && this.props.selectedPlan.active_plans[0].interval === 'month') {
                this.setState({ addons: this.props.addons.month });
            } else {
                this.setState({ addons: this.props.addons.year });
            }
        }
        if (this.props.selectedPlan !== prevProps.selectedPlan) {
            this.setState({ selectedPlan: this.props.selectedPlan, couponMessage: this.props.selectedPlan.coupon_msg });
            if (this.props.selectedPlan && this.props.selectedPlan.active_plans && this.props.selectedPlan.active_plans.length > 0) {
                for (let i = 0; i < this.props.selectedPlan.active_plans.length; i++) {
                    if (this.props.selectedPlan.active_plans[i].name === "Standard") {
                        this.setState({ isStandardPlan: true, currentPlanType: this.props.selectedPlan.active_plans[i].interval });
                        if (this.props.selectedPlan.active_plans[i].product_mode === "allinone") {
                            this.setState({ isAllInOnePlan: true });
                        }
                    }
                }
            }
            if (this.props.selectedPlan && this.props.selectedPlan.active_plans && this.props.selectedPlan.active_plans[0].interval === 'month') {
                this.setState({ addons: this.props.addons.month, });
            } else {
                this.setState({ addons: this.props.addons.year });
            }
        }
        if (this.props.subscriptionState !== prevProps.subscriptionState) {
            if (this.props.subscriptionState.error) {
                toast.error(this.props.subscriptionState.error);
            } else if (this.props.subscriptionState.clinicMyplanResponce !== prevProps.subscriptionState.clinicMyplanResponce &&
                this.props.subscriptionState.clinicMyplanResponce && this.props.subscriptionState.clinicMyplanResponce.status.error === false) {
                this.setState({
                    myPlanList: this.props.subscriptionState.clinicMyplanResponce.plans ? this.props.subscriptionState.clinicMyplanResponce.plans : [],
                }, () => {
                    for (let i = 0; i < this.state.myPlanList.length; i++) {
                        if (this.state.myPlanList[i].planName === "Standard Plan" && this.state.myPlanList[i].isCancelledPlan === true) {
                            this.setState({
                                isStandardPlanCancel: true,
                                renewRequest: {
                                    "subscriptions": [{ "subscribed_subscription_id": this.state.myPlanList[i].subscribedId, "id": this.state.myPlanList[i].id }]
                                }
                            });
                            if (this.state.myPlanList[i].trial_enddate && moment().format('YYYY-MM-DD') < moment(this.state.myPlanList[i].trial_enddate).format('YYYY-MM-DD')) {
                                let date = moment(this.state.myPlanList[i].trial_enddate).format('YYYY-MM-DD');
                                let diff = moment(date).diff(moment().format('YYYY-MM-DD'), 'days');
                                if (diff === 1) {
                                    this.setState({ isReNewBtnShow: false });
                                }
                            } else {
                                let date = moment(this.state.myPlanList[i].subscribedEndDate).format('YYYY-MM-DD');
                                let diff = moment(date).diff(moment().format('YYYY-MM-DD'), 'days');
                                if (diff === 1) {
                                    this.setState({ isReNewBtnShow: false });
                                }
                            }
                        }
                    }

                })
            }
        }
    }
    render() {
        return (
            (this.state.selectedPlan && this.state.selectedPlan.active_plans &&
                this.state.selectedPlan.active_plans.length > 0) ?
                <section className="subscription-sec px-3 pt-4">
                    <div className="container">
                        <div className="row align-items-start">
                            <div className="col-8">
                                <div className="border p-4 border-1 rounded bg-light custom-card default-card">
                                    {
                                        (this.state.selectedPlan && this.state.selectedPlan.active_plans &&
                                            this.state.selectedPlan.active_plans.length > 0) &&
                                        this.state.selectedPlan.active_plans.map((item: any) => {
                                            let diff: any = 0
                                            if (item.plan_type !== "custom") {
                                                if (item.trial_enddate !== null && item.plan_type !== 'basic') {
                                                    let date = moment(item.trial_enddate).format('YYYY-MM-DD');
                                                    diff = moment(date).diff(moment().format('YYYY-MM-DD'), 'days');
                                                }
                                                if (item.trial_enddate === null && item.end_date !== null && item.plan_type !== 'basic' && item.interval === "month") {
                                                    let date = moment(item.end_date).format('YYYY-MM-DD');
                                                    diff = moment(date).diff(moment().format('YYYY-MM-DD'), 'days');
                                                }
                                                return (
                                                    <div key={item.id} className="d-flex justify-content-between">
                                                        <div>
                                                            <div className="badge rounded-pill bg-warning mb-2">Current plan</div>
                                                            <div className="info-box fs-5 fw-medium">{item.name}</div>
                                                            {item.trial_enddate !== null && item.plan_type !== 'basic' &&
                                                                <div className="text-muted small align-self-center"><i className="bi bi-info-circle"></i> Your free trial expires in {diff} days</div>
                                                            }
                                                            {(item.trial_enddate === null && item.end_date !== null && item.plan_type !== 'basic' && item.interval === "month") &&
                                                                <div className="text-muted small align-self-center"><i className="bi bi-info-circle"></i> Your subscription will expires in {diff} days</div>
                                                            }
                                                        </div>
                                                        <div className="align-self-center">
                                                            <div className="d-flex">
                                                                <div className="pr-2 price-tag">
                                                                    <span className="fs-6 pr-1 position-relative">$</span>
                                                                    <span className="fw-medium fs-1">{item.price}</span>
                                                                    / {item.interval}
                                                                </div>
                                                            </div>
                                                            <div className="mt-2">
                                                                (Price before taxes and discounts)
                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                            }

                                        })
                                    }
                                    <div className="addon-list my-4">
                                        {(this.state.selectedPlan && this.state.selectedPlan.active_plans &&
                                            this.state.selectedPlan.active_plans.length <= 1) &&
                                            <small className="fw-medium">You don't have any activated addon.</small>
                                        }
                                        {
                                            (this.state.selectedPlan && this.state.selectedPlan.active_plans &&
                                                this.state.selectedPlan.active_plans.length > 0) &&
                                            this.state.selectedPlan.active_plans.map((item: any) => {
                                                let findIndex = this.state.selectedPlan.active_plans.map((item: any) => item.name === "Standard Plan")
                                                if (item.plan_type === "custom") {
                                                    return (
                                                        <div className="d-flex my-2 border p-4 py-3 border-1 rounded default-card bg-white justify-content-between">
                                                            <div className="d-flex">
                                                                <div className="avatar avatar-lg rounded-circle me-3">
                                                                    <img src={item.img_url} />
                                                                </div>
                                                                <div>
                                                                    <small className="fw-medium text-muted">{item.title}</small>
                                                                    <div className="info-box fs-5 fw-medium">{item.name}</div>
                                                                </div>
                                                            </div>
                                                            {
                                                                (item.product_mode !== 'allinone') ?
                                                                    <div className="pr-2 price-tag align-self-center">
                                                                        <span className="fs-6 pr-1 position-relative">$</span>
                                                                        <span className="fw-medium fs-1">{item.price}</span>
                                                                    </div>
                                                                    :
                                                                    (item.display_amount) &&
                                                                    <div className="pr-2 price-tag align-self-center">
                                                                        <span className="fs-6 pr-1 position-relative">$</span>
                                                                        <span className="fw-medium fs-1">{item.display_amount}</span>
                                                                    </div>
                                                            }
                                                        </div>
                                                    )
                                                }
                                            })
                                        }
                                    </div>
                                    <div className="d-flex justify-content-between mt-4 info-box-addons">
                                        <div className="text-muted small align-self-center"><i className="bi bi-info-circle"></i> {this.state.isAllInOnePlan ? 'This is all inclusive plan with all the features' : 'You can subscribe to add-ons from Subscription plans tab'}</div>
                                        <div onClick={() => { !this.state.isStandardPlanCancel ? this.props.goToPlans() : this.setState({ isReNewPlan: true }) }} className="btn btn-outline-primary">{this.state.isAllInOnePlan ? 'Manage plan' : !this.state.isStandardPlan ? 'Upgrade plan' : 'Add Add-ons'}</div>
                                    </div>
                                    <button className="btn btn-link p-0" onClick={() => {
                                        // this.setState({ showReadMore: true });
                                        window.open('https://www.xcare.app/pricing/')
                                    }}>Know more about plan</button>
                                    {this.state.couponMessage && <div className="small align-self-center"><span className="fw-medium">Note :</span> {this.state.couponMessage}</div>}
                                </div>
                            </div>
                            {
                                (this.state.selectedPlan && this.state.selectedPlan.plans_exp &&
                                    this.state.selectedPlan.amount) &&
                                <div className="col-4">
                                    <div className="sidebar p-4 border border-1 rounded bg-light default-card addon-sidebar">
                                        <div className="d-flex align-items-start flex-column bd-highlight mb-3 h-100">
                                            {/* {this.state.selectedPlan.plans_exp.map((item: any) => {
                                            return ( */}
                                            <>

                                                <div className="pr-2 price-tag bd-highlight w-100">
                                                    <div className="d-flex justify-content-between">
                                                        <div className="fw-medium fs-5">
                                                            Plan Price:
                                                        </div>
                                                        <div>
                                                            {/* <span className="fs-6 pr-1 position-relative">$</span> */}
                                                            <span className="fw-medium fs-3">${this.state.selectedPlan.amount}.00</span>
                                                        </div>
                                                    </div>
                                                    {
                                                        this.state.selectedPlan.discount > 0 &&

                                                        <div className="d-flex justify-content-between mt-3">
                                                            <div className="fw-medium fs-5">
                                                                Discount:
                                                            </div>
                                                            <div>

                                                                {/* <span className="fs-6 pr-1 position-relative">$</span> */}
                                                                <span className="fw-medium fs-3">- (${this.state.selectedPlan.discount})</span>

                                                            </div>
                                                        </div>
                                                    }
                                                    {
                                                        this.state.selectedPlan.tax_percentage !== '' && this.state.selectedPlan.tax_amount > 0 &&

                                                        <div className="d-flex justify-content-between mt-3">
                                                            <div className="fw-medium fs-5">
                                                                Tax ({this.state.selectedPlan.tax_percentage}%):
                                                            </div>
                                                            <div>

                                                                {/* <span className="fs-6 pr-1 position-relative">$</span> */}
                                                                <span className="fw-medium fs-3">${roundNumberWithRoundingFactorDecimalFix(this.state.selectedPlan.tax_amount, 2)}</span>

                                                            </div>
                                                        </div>
                                                    }
                                                    <div className="d-flex justify-content-between mt-3">
                                                        <div className="fw-medium fs-5">
                                                            Total Plan Price:
                                                        </div>
                                                        <div>
                                                            {/* <span className="fs-6 pr-1 position-relative">$</span> */}
                                                            <span className="fw-medium fs-1">${roundNumberWithRoundingFactorDecimalFix((Number(this.state.selectedPlan.amount) - Number(this.state.selectedPlan.discount) + Number(this.state.selectedPlan.tax_amount)), 2)}</span>
                                                        </div>
                                                    </div>

                                                </div>

                                                <div className="next-date mt-2 bd-highlight">
                                                    <small className="d-block fw-medium text-muted">Next payment</small>
                                                    <div className="fw-medium fs-5">On {moment(this.state.selectedPlan.plans_exp).format('MMMM DD, YYYY')}</div>
                                                    <div className="fw-medium fs-5 mt-2">Credit Balance : {this.state.selectedPlan.creditbalance ? `$${this.state.selectedPlan.creditbalance}` : '$0'}</div>
                                                </div>
                                            </>
                                            {/* )
                                        })
                                        } */}
                                            <button onClick={() => { this.props.history.push({ pathname: '/subscription/manage-plans' }) }} className="btn btn-outline-primary mt-5">Manage plan</button>
                                        </div>
                                    </div>
                                </div>
                            }
                        </div>
                        <div className="row">
                            <div className="col-8">
                                {
                                    (this.state.addons && this.state.addons.length > 0) &&
                                    this.state.addons.map((item: any) => {
                                        if (item.planType === "custom") {
                                            return (
                                                <div className=" border p-4 border-1 rounded bg-white custom-card my-4 default-card" key={item.name}>
                                                    <div className="d-flex justify-content-between">
                                                        <div>
                                                            <div className="d-flex mb-2">
                                                                <div className="avatar avatar-lg rounded-circle me-3">
                                                                    <img alt="" src={item.img_url + item.img_name} />
                                                                </div>
                                                                <div>
                                                                    <small className="fw-medium text-muted">{item.title}</small>
                                                                    <div className="info-box fs-5 fw-medium">{item.name}
                                                                        <OverlayTrigger placement="right" overlay={<Popover id={"popover-basic-" + item.name}>
                                                                            <Popover.Title as="h3">{item.name}</Popover.Title>
                                                                            <Popover.Content>
                                                                                {item.brief ? item.brief : item.name}
                                                                            </Popover.Content>
                                                                        </Popover>}>
                                                                            <i className="bi bi-info-circle ms-2 text-muted fs-6"></i>
                                                                        </OverlayTrigger>
                                                                    </div>

                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="">
                                                            <div className="d-flex">
                                                                {
                                                                    (item.product_mode !== 'allinone') ?
                                                                        <div className="pr-2 price-tag">
                                                                            <span className="fs-6 pr-1 position-relative">$</span>
                                                                            <span className="fw-medium fs-1">{item.plan_price}</span>
                                                                            / Per {item.plan_interval}
                                                                        </div>
                                                                        :
                                                                        (item.display_amount) &&
                                                                        <div className="pr-2 price-tag">
                                                                            <span className="fs-6 pr-1 position-relative">$</span>
                                                                            <span className="fw-medium fs-1">{item.display_amount}</span>
                                                                            / Per {item.plan_interval}
                                                                        </div>
                                                                }
                                                                {/* <small className="align-self-center">Per clinic <br />Per {item.plan_interval}</small> */}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {/* <p>A text message application that gets reviews from your customers after they leave your business.</p> */}
                                                    <div className="col-8">
                                                        <p>{item.short_desc}</p>
                                                    </div>
                                                    <button onClick={() => {
                                                        // this.setState({ showReadMore: true });
                                                        window.open('https://www.xcare.app/pricing/')
                                                    }} className="btn btn-link p-0">Read more details <i className="bi bi-box-arrow-in-up-right"></i></button>
                                                    {
                                                        (item.product_mode !== 'allinone') &&

                                                        <div className="d-flex justify-content-end">
                                                            <button disabled={item.isPlanActive} onClick={() => {
                                                                !this.state.isStandardPlanCancel ? this.props.selectedAddon(item) : this.setState({ isReNewPlan: true })
                                                            }} className="btn btn-dark">{item.isPlanActive ? 'Added' : 'Add to plan'}</button>
                                                        </div>
                                                    }
                                                </div>
                                            )
                                        }
                                    })
                                }
                                {/* <div className=" border p-4 border-1 rounded bg-white custom-card my-4 default-card">
                                <div className="d-flex justify-content-between">
                                    <div>
                                        <div className="d-flex mb-2">
                                            <img className="mr-3 align-self-center" src={require("../../assets/images/recall-recare.svg")} />
                                            <div>
                                                <small className="fw-medium text-muted">Send notification</small>
                                                <div className="info-box fs-5 fw-medium">RECALL & RECARE</div>
                                            </div>
                                        </div>							
                                    </div>
                                    <div className="">
                                        <div className="d-flex">
                                            <div className="pr-2 price-tag">
                                                <span className="fs-6 pr-1 position-relative">$</span>
                                                <span className="fw-medium fs-1">99</span>
                                            </div>
                                            <small className="align-self-center">Per clinic <br/>Per month</small>
                                        </div>
                                    </div>
                                </div>
                                <p>A text message application that gets reviews from your customers after they leave your business.</p>
                                <button className="btn btn-link pl-0">Read more details <img className="ml-2" src="../../assets/images/right-arrow-icon.svg"/></button>
                                <div className="text-right">
                                    <button className="btn btn-dark">Add <img src={require("../../assets/images/spinner-icon.svg")} className="btn-img" /></button>
                                </div>
                            </div> */}
                            </div>
                        </div>
                    </div>
                    <ReadMore show={this.state.showReadMore} onHide={() => { this.setState({ showReadMore: false }) }}></ReadMore>
                    <RenewPlanMessage
                        clinicUniqueId={this.props.userDataResponse.clinics[0].uniqueId}
                        history={this.props.history}
                        successReNewPlan={() => {
                            this.setState({ isStandardPlanCancel: false });
                            this.props.myPlan({ clinicUniqueId: this.props.userDataResponse.clinics[0].uniqueId });
                        }}
                        isReNewBtnShow={this.state.isReNewBtnShow}
                        renewRequest={this.state.renewRequest}
                        isReNewPlan={this.state.isReNewPlan} onHide={() => { this.setState({ isReNewPlan: false }) }}></RenewPlanMessage>
                </section>
                : null
        )
    }
}
const mapStateToProps = (state: IAppState) => ({
    userDataResponse: state.userData.userDataResponse,
    subscriptionState: state.subscriptionPlan

});
const mapDispatchToProps = (dispatch: any) => ({
    myPlan: (request: ClinicMyplanRequest) => {
        dispatch(myPlan(request));
    },
});
export default connect(mapStateToProps, mapDispatchToProps)(Plans);