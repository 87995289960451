// import './_practiceSteps.scss';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { createStep, getDomainList } from '../../services/clinicOnboard';
import { useDispatch } from 'react-redux';
import { fetchLoadingPending, fetchLoadingSuccess } from '../../../store/loadingIndicator/actions';

export function PracticeApp1(props: any) {
	const [practiceType, setPracticeType] = useState([]);
	const [selectedPracticeType, setSelectedPracticeType] = useState(null);
	const dispatch = useDispatch();
	const { t } = useTranslation();
	useEffect(() => {
		if (props.data) {
			setSelectedPracticeType(props.data.id);
		}
	}, [props.data]);
	useEffect(() => {
		dispatch(fetchLoadingPending());
		getDomainList().then((success) => {
			dispatch(fetchLoadingSuccess(false));
			if (success.status.error === false) {
				setPracticeType(success.domain);
				if (props.data) {
					setSelectedPracticeType(props.data.id);
				} else {
					setSelectedPracticeType(success.domain[0].id);
				}
				return;
			}
		}).catch((err) => {
			dispatch(fetchLoadingSuccess(false));
		});
	}, []);
	const onNext = () => {
		const selectedPracticeTypeFind: any = practiceType.find((item: any) => item.id === selectedPracticeType);
		const data = {
			domain: selectedPracticeTypeFind.id
		}
		dispatch(fetchLoadingPending());
		createStep({
			stepNumber: 1,
			formData: data
		}).then((success) => {
			dispatch(fetchLoadingSuccess(false));
			if (success.status.error === false) {
				props.onNext(1, {
					practiceType: selectedPracticeTypeFind,
					clinicUniqueId: success.permission.modeluniqueId
				});
			}
		}).catch((err) => {
			dispatch(fetchLoadingSuccess(false));
		});
	}
	return (
		<>

			<div className="row justify-content-center">
				<div className="col-12">
					<h2 className="mb-3">{t('clinicCreate.step1.header')}</h2>
					<div className="fw-medium fs-5">{t('clinicCreate.step1.title')}</div>
					<p className="mt-1">{t('Please ensure that all of the following information is correct.We will use this profile for google listing and marketing purposes.')}</p>
					<hr className="my-4" />
				</div>
			</div>
			<div className="row">
				{
					practiceType.map((item: any) => {
						return (
							<Icons item={item} 
							selectedPracticeType={selectedPracticeType}
							setSelectedPracticeType={setSelectedPracticeType}></Icons>
						);
					})
				}
			</div>
			<div className="row">
				<div className="col-12 my-4">
					<div className="bottom-btn-sec text-end">
						<button className="btn btn-primary ms-1" onClick={() => {
							onNext();
						}}>Next</button>
					</div>
				</div>

			</div>

		</>
	);
}

const Icons = (props: any) => {
	const {item, selectedPracticeType, setSelectedPracticeType } = props;
	const [image, setImage] = useState('');
	useEffect(() => {
		const loadImage = async () => {
			const _image = await import('../../assets/images/' + item.icon)
			setImage(_image.default);
		}
		loadImage();
	}, []);
	return (
		<div className="col-6 col-md-3" key={item.id}>
			<div className="select-practice-btn">
				<input type="radio" className="btn-check" name="select-practice-radio"
					checked={item.id === selectedPracticeType}
					id={item.id + item.title} value={item.id} onClick={(e) => { setSelectedPracticeType(item.id) }} />
				<label className="btn border rounded w-100" htmlFor={item.id + item.title}>
					<img src={image} className="m-3" />
					<small className="d-block mb-3 fw-medium">{item.title}</small>
				</label>
			</div>
		</div>
	)
}