import React, { useEffect } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { Redirect, Route, Switch, useHistory } from 'react-router';
import { toast } from 'react-toastify';
import { IAppState } from '../../../store';
import { PatientDetail } from './PatientDetail';
import { PatientList } from './PatientList';
export const Patient = () => {
    const history = useHistory();
    const userData: any = useSelector((state: IAppState) => state.userData.userDataResponse, shallowEqual);
    useEffect(() => {
        if (!(userData && userData.accessEnabled && userData.accessEnabled.patient_search && userData.accessEnabled.patient_search.patient_search)) {
            toast.error('You dont have subscription for patient search');
            history.replace('/');
        }
    }, [userData]);
    return (
        <Switch>
            <Route exact path="/patient" component={PatientList}></Route>
            <Route exact path="/patient/:id" component={PatientDetail}></Route>
            <Redirect to="/patient"></Redirect>
        </Switch>
    )
}