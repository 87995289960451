/* eslint-disable */
import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { Controller, useForm } from 'react-hook-form';
import { shallowEqual, useSelector } from 'react-redux';
import { IAppState } from '../../../store';
import * as _ from 'lodash'
import { checkValidDateTimePerDoctorSchedule, takeOnlyDigitAndPlus } from '../../../utils/global';
import ReactInputMask from 'react-input-mask';
import { KeyboardDatePicker, KeyboardTimePicker } from '@material-ui/pickers';
import moment from 'moment';
import { blockTime, bookManualAppointment, checkAppointmentBook, firestoreChangeNotificationToServer, getInsurancePlan, getPatientList } from '../../services/appointment';
import { GuarantorInfoModal } from './GuarantorInfoModal';
import { toast } from 'react-toastify';
import { CHAT } from '../../../constants';
import * as firebase from 'firebase/app';
import 'firebase/firestore';
import firebaseInstance from '../../helpers/firebase';
import configs from '../../configs/apiConfigs';
import { getCookie } from '../../utils/cookies';
import { getInsuranceList } from '../../services/clinicOnboard';
import { checkProviderAvailable } from '../../utils/apiHandler';
import { getAppointmentCategoryList, getProcedureCodeList } from '../../services/messageCenter';
import { cloneDeep } from 'lodash';
export const CreateAppointmentModal = ({ hideModal, fetchAppointment, date, startTime, endTime, providerId }: any) => {
    const userData = useSelector((state: IAppState) => state.userData.userDataResponse, shallowEqual);
    const [providerList, setProviderList] = useState([]);
    const [appointmentTypeList, setAppointmentTypeList] = useState([]);
    const [selectedDoctorName, setSelectedDoctorName] = useState('');
    const [patientList, setPatientList] = useState([]);
    const [guarantorList, setGuarantorList]: any = useState([]);
    const [loadingSearch, setLoadingSearch] = useState(false);
    const [loadingBlockTime, setLoadingBlockTime] = useState(false);
    const [loadingWaitlist, setLoadingWaitlist] = useState(false);
    const [loadingScheduled, setLoadingScheduled] = useState(false);
    const [showGurantorInfoModal, setShowGuarantorInfoModal] = useState(false);
    const [masterInsuranceList, setMasterInsuranceList] = useState([]);
    const [masterInsurancePlanList, setMasterInsurancePlanList]: any = useState([]);
    const [notes, setNotes] = useState('');
    const [appointmentCategoryList, setAppointmentCategoryList] = useState([]);
    const [category, setCategory] = useState('');
    const [categoryCodeList, setCategoryCodeList] = useState([]);
    const [proceedureCode, setProceedureCode] = useState('');
    const [amount, setAmount] = useState('');
    const [proceedureCodeTable, setProceedureCodeTable]: any[] = useState([]);
    const [selectedIndex, setSelectedIndex] = useState(-1);
    const { register, errors, setError, getValues, setValue, watch, control, handleSubmit } = useForm({
        defaultValues: {
            appointmentfor: 'yes',
            provider: '',
            appointmentType: '',
            patientname: '',
            scheduleDate: date ? date : new Date(),
            startTime: (startTime && startTime !== '' ? moment(startTime).toDate() : new Date()),
            endTime: (endTime && endTime !== '' ? moment(endTime).toDate() : new Date()),
            ptsearchradio: '',
            firstName: '',
            lastName: '',
            blockType: 'specificTime',
            patienttype: 'individual',
            automatedreminder: 'yes',
            pcontactmethod: 'email,text',
            phone: '',
            email: '',
            gender: 'Male',
            paythroughinsurance: 'no',
            dob: moment().subtract(1, 'day').toDate(),
            selfguarantor: 'yes',
            primaryguarantor: 'primary',
            secondaryguarantor: '',
            insurancename: '',
            insuranceothername: '',
            insuranceplanname: '',
            insuranceotherplanname: '',
            startDateBlockType: new Date(),
            endDateBlockType: new Date(),
            startTimeBlockType: new Date(),
            endTimeBlockType: new Date(),
            globalError: ''
        }
    });
    useEffect(() => {
        try {
            if (userData && userData.clinics && userData.clinics[0]) {
                console.log('UserDate', userData);
                const providers = userData.clinics[0].provider.filter((item: any) => item.onBoarding.status === 1 && item.enableAppointment === "1");
                setProviderList(providers);
                if (providers && providers[0]) {
                    setAppointmentTypeList(providers[0].potentialAppointmentTypes);
                }


                fecthMasterInsuranceList();
                fetchAppointmentCategory();
            }
        } catch (err) {
            console.log(err);
        }
    }, [userData]);
    const checkValidDateTimePerProvider = () => {
        let provider = {};
        const clinicFound = userData.clinics[0].uniqueId;
        if (clinicFound) {
            provider = _.find(clinicFound.provider, (value) => {
                return value.id === getValues('provider');
            });
        }
        if (checkValidDateTimePerDoctorSchedule(getValues('scheduleDate'), getValues('startTime'), provider)) return true;
        return false;
    }
    const getPatietListOnSearch = () => {
        setLoadingSearch(true);
        if (getValues('patientname').trim() !== '') {
            let data = {};
            Object.assign(data, { fullname: getValues('patientname') });
            getPatientList(data).then((success) => {
                setLoadingSearch(false);
                if (success && success.status && success.status.error === false) {
                    setPatientList(success.patients);
                }
            }).catch((err) => {
                setLoadingSearch(false);
            })
        }
    }
    useEffect(() => {
        if (getValues('provider') !== '') {
            const providerSelected: any = providerList.find((item: any) => item.id === getValues('provider'));
            console.log(providerSelected);
            if (providerSelected) {
                setValue('appointmentType', '');
                setAppointmentTypeList(providerSelected.potentialAppointmentTypes);
            }
        }
    }, [watch('provider')]);
    useEffect(() => {
        setGuarantorList([]);
    }, [watch('patienttype')]);
    const fecthMasterInsuranceList = () => {
        getInsuranceList().then((success) => {
            if (success && success.status && success.status.error === false) {
                const insurances = success.insurance;
                insurances.push({
                    id: "other",
                    name: "Other",
                    plan: [{ id: "other", name: "Other" }]
                });
                setMasterInsuranceList(insurances);
            }
        }).catch((err) => {
            console.log(err);
        })
    }
    useEffect(() => {
        setError('globalError', {});
        setError('scheduleDate', {});
    }, [watch('appontmentfor')]);
    useEffect(() => {
        const provider: any = providerList.find((item: any) => item.uniqueid == providerId);
        if (provider) {
            setValue('provider', provider.id);
        }
    }, [providerId, providerList]);
    useEffect(() => {
        const appointmenttype: any = appointmentTypeList.find((item: any) => item.id === getValues('appointmentType'));
        if (appointmenttype) {
            setValue('endTime', moment(getValues('startTime')).add(appointmenttype.duration, 'minutes').toDate());
        }
    }, [watch('appointmentType')]);
    useEffect(() => {
        if (getValues('insurancename') && getValues('insurancename') !== "") {
            let insurance: any = masterInsuranceList.find((item: any) => item.id === getValues('insurancename'));
            if (insurance && insurance.id === "other") {
                insurance = {
                    ...insurance,
                    plan: [{ id: 'other', name: 'Other' }]
                }
            } else {
                insurance = {
                    ...insurance,
                    plan: [...insurance.plan, { id: 'other', name: 'Other' }]
                }
            }
            setMasterInsurancePlanList(insurance.plan);
            if (insurance.plan[0]) {
                setValue('insuranceplanname', insurance.plan[0].id);
            }
        }
    }, [watch('insurancename')]);
    const handleWaitListClick = (data: any) => {
        const date = moment(getValues('scheduleDate')).format('YYYY-MM-DD');
        const startTime = moment(getValues('startTime')).format('LT');
        const endTime = moment(getValues('endTime')).format('LT');
        console.log(data, date, startTime, endTime);
        if (moment(date, 'YYYY-MM-DD').isBefore(moment().format('YYYY-MM-DD'))) {
            setError('scheduleDate', { type: 'custom', message: 'Please select date which is not previous.' });
            return;
        } else if (moment(date + " " + startTime, 'YYYY-MM-DD LT').isBefore(moment())) {
            setError('scheduleDate', { type: 'custom', message: 'Please select date and time which is not previous.' });
            return;
        } else if (moment(startTime, "LT").isSameOrAfter(moment(endTime, "LT"))) {
            setError('scheduleDate', { type: 'custom', message: 'Start time should be previous to end time.' });
            return;
        }
        const providerSelected: any = providerList.find((item: any) => item.id === getValues('provider'));
        let availableDoctor = checkProviderAvailable(userData.clinics[0], providerSelected, date, startTime, endTime);
        if (availableDoctor === false) {
            setError('globalError', { type: 'custom', message: 'Provider is not available on selected time.' });
            return;
        }
        setError('scheduleDate', {});
        const provider: any = providerList.find((item: any) => item.id === getValues('provider'));
        setLoadingWaitlist(true);
        // checkAppointmentBook({
        //     provider: provider.uniqueid,
        //     date: date,
        //     starttime: startTime,
        //     endtime: endTime
        // }).then((success) => {
        //     if (success && success.status && success.status.error === false) {
        try {
            const patient: any = patientList.find((item: any) => item.dependant.id === getValues('ptsearchradio'));
            if (patient && patient.dependant) {
                let data = {
                    appointmentStatus: 'requested',
                    appointmenttype: { id: getValues('appointmentType') },
                    clinic: {
                        id: userData.clinics[0].id,
                        doctor: {
                            id: getValues('provider')
                        }
                    },
                    dateTime: {
                        date: date,
                        startTime: startTime,
                        endTime: endTime,
                        starttime: startTime,
                        endtime: endTime,
                    },
                    dependant: patient.dependant,
                    isExistingClinic: true,
                    isNewPatient: false,
                    patient: patient.guarantor,
                    request: {
                        isExistingClinic: true,
                        isNewPatient: false
                    }
                };
                setLoadingWaitlist(true);
                bookManualAppointment(data, userData.clinics[0].uniqueId).then(async (success) => {
                    if (success && success.status && success.status.error === false) {
                        const obj = {
                            "appointmentId": Number(success.appopintment),
                            "senderId": Number(success.patient), //uniqueid
                            "sender": CHAT.SENDER.PATIENT,
                            "action": 'Changed status from CREATED to REQUESTED.',
                            "actionType": 'STATUS',
                            "createdAt": firebase.firestore.FieldValue.serverTimestamp(),
                            "updatedAt": firebase.firestore.FieldValue.serverTimestamp(),
                            "text": "manual request",
                            "fromStatus": 'CREATED',
                            "toStatus": 'REQUESTED',
                            "sendToCId": userData.clinics[0].uniqueId,
                            "dateTime": {
                                "date": date,
                                "startTime": startTime,
                                "endTime": endTime,
                                "time": startTime,
                            },
                            "dependantId": Number(success.dependant),
                            "firstName": userData.firstName,
                            "lastName": userData.lastName,
                        }
                        try {
                            const response = await firebaseInstance.addDocumentIntoCollection(configs.CHAT_COLLECTION, obj);
                            if (response) {
                                response.get().then((docRef: any) => {
                                    let data = {
                                        ...docRef.data(),
                                        mode: "create",
                                        id: docRef.id
                                    };
                                    firestoreChangeNotificationToServer(data).then((success) => {
                                        if (fetchAppointment) fetchAppointment(true);
                                        setLoadingWaitlist(false);
                                        hideModal();
                                    }).catch((err) => {
                                        setLoadingWaitlist(false);
                                        console.log(err);
                                    })
                                });
                            }
                        } catch (err) {
                            setLoadingWaitlist(false);
                        }
                    }
                }).catch((err) => {
                    setLoadingWaitlist(false);
                    toast.error('Appointment book errror, please try again.');
                    console.log(err);
                });
            }
        } catch (err) {
            setLoadingWaitlist(false);
            console.log(err);
        }
        //     } else {
        //         setLoadingWaitlist(false);
        //     }
        // }).catch((err) => {
        //     setLoadingWaitlist(false);
        //     toast.error(err.msg);
        // });
    }

    const handleScheduleClick = (data: any) => {
        const date = moment(getValues('scheduleDate')).format('YYYY-MM-DD');
        const startTime = moment(getValues('startTime')).format('LT');
        const endTime = moment(getValues('endTime')).format('LT');
        console.log(data, date, startTime, endTime);
        if (moment(date, 'YYYY-MM-DD').isBefore(moment().format('YYYY-MM-DD'))) {
            setError('scheduleDate', { type: 'custom', message: 'Please select date which is not previous.' });
            return;
        } else if (moment(date + " " + startTime, 'YYYY-MM-DD LT').isBefore(moment())) {
            setError('scheduleDate', { type: 'custom', message: 'Please select date and time which is not previous.' });
            return;
        } else if (moment(startTime, "LT").isSameOrAfter(moment(endTime, "LT"))) {
            setError('scheduleDate', { type: 'custom', message: 'Start time should be previous to end time.' });
            return;
        }
        const providerSelected: any = providerList.find((item: any) => item.id === getValues('provider'));
        let availableDoctor = checkProviderAvailable(userData.clinics[0], providerSelected, date, startTime, endTime);
        if (availableDoctor === false) {
            setError('globalError', { type: 'custom', message: 'Provider is not available on selected time.' });
            return;
        }
        setError('scheduleDate', {});
        const provider: any = providerList.find((item: any) => item.id === getValues('provider'));
        setLoadingScheduled(true);
        checkAppointmentBook({
            provider: provider.uniqueid,
            date: date,
            starttime: startTime,
            endtime: endTime
        }).then((success) => {
            if (success && success.status && success.status.error === false) {
                try {
                    const patient: any = patientList.find((item: any) => item.dependant.id === getValues('ptsearchradio'));
                    if (patient && patient.dependant) {
                        let data = {
                            appointmentStatus: 'scheduled',
                            appointmenttype: { id: getValues('appointmentType') },
                            clinic: {
                                id: userData.clinics[0].id,
                                doctor: {
                                    id: getValues('provider')
                                }
                            },
                            dateTime: {
                                date: date,
                                startTime: startTime,
                                endTime: endTime,
                                starttime: startTime,
                                endtime: endTime,
                            },
                            dependant: patient.dependant,
                            isExistingClinic: true,
                            isNewPatient: false,
                            patient: patient.guarantor,
                            request: {
                                isExistingClinic: true,
                                isNewPatient: false
                            }
                        };
                        setLoadingScheduled(true);
                        bookManualAppointment(data, userData.clinics[0].uniqueId).then(async (successManual) => {
                            if (successManual && successManual.status && successManual.status.error === false) {
                                const obj = {
                                    "appointmentId": Number(successManual.appopintment),
                                    "senderId": Number(successManual.patient), //uniqueid
                                    "sender": CHAT.SENDER.PATIENT,
                                    "action": 'Changed status from CREATED to REQUESTED.',
                                    "actionType": 'STATUS',
                                    "createdAt": firebase.firestore.FieldValue.serverTimestamp(),
                                    "updatedAt": firebase.firestore.FieldValue.serverTimestamp(),
                                    "text": "manual request",
                                    "fromStatus": 'CREATED',
                                    "toStatus": 'REQUESTED',
                                    "sendToCId": userData.clinics[0].uniqueId,
                                    "dateTime": {
                                        "date": date,
                                        "startTime": startTime,
                                        "endTime": endTime,
                                        "time": startTime,
                                    },
                                    "dependantId": Number(successManual.dependant),
                                    "firstName": userData.firstName,
                                    "lastName": userData.lastName,
                                }
                                try {
                                    const response = await firebaseInstance.addDocumentIntoCollection(configs.CHAT_COLLECTION, obj);
                                    if (response) {
                                        response.get().then((docRef: any) => {
                                            let data = {
                                                ...docRef.data(),
                                                mode: "create",
                                                id: docRef.id
                                            };
                                            firestoreChangeNotificationToServer(data).then(async (success) => {
                                                // toast.success('Appointment is requested.');
                                                const scheduleObj = {
                                                    "appointmentId": Number(successManual.appopintment),
                                                    "senderId": Number(getCookie().xpr_user_id), //login user id
                                                    "sender": CHAT.SENDER.USER,
                                                    "action": `Changed status from REQUESTED to SCHEDULED.`,
                                                    "actionType": 'STATUS',
                                                    "dateTime": {
                                                        "date": date,
                                                        "time": startTime,
                                                        "startTime": startTime,
                                                        "endTime": endTime
                                                    },
                                                    "text": "manual scheduled",
                                                    "createdAt": firebase.firestore.FieldValue.serverTimestamp(),
                                                    "updatedAt": firebase.firestore.FieldValue.serverTimestamp(),
                                                    "fromStatus": CHAT.FROM_STATUS.REQUESTED,
                                                    "toStatus": CHAT.TO_STATUS.SCHEDULED,
                                                    "sendToCId": userData.clinics[0].uniqueId,
                                                    "dependantId": Number(successManual.dependant),
                                                    "firstName": userData.firstName,
                                                    "lastName": userData.lastName,
                                                };
                                                const scheduleResponse = await firebaseInstance.addDocumentIntoCollection(configs.CHAT_COLLECTION, scheduleObj);
                                                if (scheduleResponse) {
                                                    scheduleResponse.get().then((docRef: any) => {
                                                        let data = {
                                                            ...docRef.data(),
                                                            mode: "create",
                                                            id: docRef.id
                                                        };
                                                        firestoreChangeNotificationToServer(data).then(async (success) => {
                                                            if (fetchAppointment) fetchAppointment(true);
                                                            setLoadingScheduled(false);
                                                            toast.success('Appointment is scheduled');
                                                            hideModal();
                                                        }).catch((err) => {
                                                            toast.error('Appointment book error');
                                                            setLoadingScheduled(false);
                                                        })
                                                    });
                                                }
                                            }).catch((err) => {
                                                setLoadingScheduled(false);
                                                toast.error('Appointment book error');
                                                console.log(err);
                                            })
                                        });
                                    }
                                } catch (err) {
                                    setLoadingScheduled(false);
                                    toast.error('Appointment book error');
                                }
                            }
                        }).catch((err) => {
                            setLoadingScheduled(false);
                            toast.error('Appointment book errror, please try again.');
                            console.log(err);
                        });
                    }
                } catch (err) {
                    setLoadingScheduled(false);
                    toast.error('Appointment book error');
                    console.log(err);
                }
            } else {
                setLoadingScheduled(false);
            }
        }).catch((err) => {
            setLoadingScheduled(false);
            toast.error(err.msg);
        })
    }
    const handleBlockTime = (data: any) => {
        console.log(data);
        const date = moment(getValues('scheduleDate')).format('YYYY-MM-DD');
        const startTime = moment(getValues('startTime')).format('LT');
        const endTime = moment(getValues('endTime')).format('LT');

        if (getValues('blockType') === 'specificTime') {
            if (moment(date, 'YYYY-MM-DD').isBefore(moment().format('YYYY-MM-DD'))) {
                setError('globalError', { type: 'custom', message: 'Please select date which is not previous.' });
                return;
            } else if (moment(date + " " + startTime, 'YYYY-MM-DD LT').isBefore(moment())) {
                setError('globalError', { type: 'custom', message: 'Please select date and time which is not previous.' });
                return;
            } else if (moment(startTime, "LT").isSameOrAfter(moment(endTime, "LT"))) {
                setError('globalError', { type: 'custom', message: 'Start time should be previous to end time.' });
                return;
            }
            const providerSelected: any = providerList.find((item: any) => item.id === getValues('provider'));
            let availableDoctor = checkProviderAvailable(userData.clinics[0], providerSelected, date, startTime, endTime);
            if (availableDoctor === false) {
                setError('globalError', { type: 'custom', message: 'Provider is not available on selected time.' });
                return;
            }
            setError('globalError', {});
            let data = {
                type: "slot",
                mode: "specific",
                doctor: {
                    id: getValues('provider')
                },
                patient: {
                    fname: getValues('firstName'),
                    lname: getValues('lastName'),
                },
                dateTime: {
                    date: date,
                    starttime: startTime, endtime: endTime,
                    startTime: startTime, endTime: endTime,
                },
                notes: getValues('notes')
            };
            setLoadingBlockTime(true);
            blockTime(data, userData.clinics[0].uniqueId).then((success) => {
                setLoadingBlockTime(false);
                if (success && success.status && success.status.error === false) {
                    if (fetchAppointment) fetchAppointment(true);
                    toast.success('Block time successfully');
                    hideModal();
                }
            }).catch((err) => {
                setLoadingBlockTime(false);
                console.log(err);
                toast.error(err.msg);
            });
        } else if (getValues('blockType') === 'fullTime') {
            if (moment(date, 'YYYY-MM-DD').isBefore(moment().format('YYYY-MM-DD'))) {
                setError('globalError', { type: 'custom', message: 'Please select date which is not previous.' });
                return;
            }
            setError('globalError', {});
            let data = {
                type: "slot",
                mode: "full",
                doctor: {
                    id: getValues('provider')
                },
                patient: {
                    fname: getValues('firstName'),
                    lname: getValues('lastName'),
                },
                dateTime: {
                    date: date,
                }
            };
            setLoadingBlockTime(true);
            blockTime(data, userData.clinics[0].uniqueId).then((success) => {
                setLoadingBlockTime(false);
                if (success && success.status && success.status.error === false) {
                    if (fetchAppointment) fetchAppointment(true);
                    toast.success('Block time successfully');
                    hideModal();
                }
            }).catch((err) => {
                setLoadingBlockTime(false);
                console.log(err);
                toast.error(err.msg);
            });
        }
    }
    const handleWaitListNew = (data: any) => {
        const date = moment(getValues('scheduleDate')).format('YYYY-MM-DD');
        const startTime = moment(getValues('startTime')).format('LT');
        const endTime = moment(getValues('endTime')).format('LT');
        if (moment(date, 'YYYY-MM-DD').isBefore(moment().format('YYYY-MM-DD'))) {
            setError('scheduleDate', { type: 'custom', message: 'Please select date which is not previous.' });
            return;
        } else if (moment(date + " " + startTime, 'YYYY-MM-DD LT').isBefore(moment())) {
            setError('scheduleDate', { type: 'custom', message: 'Please select date and time which is not previous.' });
            return;
        } else if (moment(startTime, "LT").isSameOrAfter(moment(endTime, "LT"))) {
            setError('scheduleDate', { type: 'custom', message: 'Start time should be previous to end time.' });
            return;
        } else if (getValues('paythroughinsurance') === 'yes' && getValues('patienttype') === 'individual' && getValues('secondaryguarantor')[0] !== undefined) {
            if (guarantorList.length === 0) {
                setError('scheduleDate', { type: 'custom', message: 'Please select secondary guarantor.' });
                return;
            }
        } else if (getValues('paythroughinsurance') === 'yes' && getValues('patienttype') === 'dependent' && getValues('secondaryguarantor')[0] !== undefined) {
            if (guarantorList.length === 0) {
                setError('scheduleDate', { type: 'custom', message: 'Please select primary and secondary guarantor.' });
                return;
            } else if (guarantorList.length === 1) {
                setError('scheduleDate', { type: 'custom', message: 'Please select secondary guarantor.' });
                return;
            }
        } else if (getValues('paythroughinsurance') === 'yes' && getValues('patienttype') === 'dependent' && getValues('primaryguarantor') !== undefined) {
            if (guarantorList.length === 0) {
                setError('scheduleDate', { type: 'custom', message: 'Please select primary guarantor.' });
                return;
            }
        }
        const providerSelected: any = providerList.find((item: any) => item.id === getValues('provider'));
        let availableDoctor = checkProviderAvailable(userData.clinics[0], providerSelected, date, startTime, endTime);
        if (availableDoctor === false) {
            setError('globalError', { type: 'custom', message: 'Provider is not available on selected time.' });
            return;
        }
        setError('scheduleDate', {});
        const provider: any = providerList.find((item: any) => item.id === getValues('provider'));
        setLoadingWaitlist(true);
        // checkAppointmentBook({
        //     provider: provider.uniqueid,
        //     date: date,
        //     startime: startTime,
        //     endtime: endTime
        // }).then((success) => {
        //     if (success && success.status && success.status.error === false) {
        try {
            let data = {
                appointmentStatus: "requested",
                appointmenttype: { id: getValues('appointmentType') },
                clinic: {
                    id: userData.clinics[0].id,
                    doctor: {
                        id: getValues('provider')
                    }
                },
                dateTime: {
                    date: date,
                    startTime: startTime,
                    endTime: endTime,
                    starttime: startTime,
                    endtime: endTime,
                },
                isExistingClinic: true,
                isNewPatient: true,
            }
            if (getValues('paythroughinsurance') === 'no') {
                if (getValues('patienttype') === 'individual') {
                    Object.assign(data, {
                        isIndividual: true,
                        patient: {
                            fname: getValues('firstName'),
                            lname: getValues('lastName'),
                            email: getValues('email'),
                            phone: getValues('phone'),
                            dob: moment(getValues('dob')).format('YYYY-MM-DD'),
                            gender: getValues('gender'),
                            contactMethod: getValues('pcontactmethod') ? getValues('pcontactmethod') : '',
                            reminder: getValues('automatedreminder'),
                        },
                        dependant: {
                            fname: getValues('firstName'),
                            lname: getValues('lastName'),
                            email: getValues('email'),
                            phone: getValues('phone'),
                            dob: moment(getValues('dob')).format('YYYY-MM-DD'),
                            gender: getValues('gender'),
                            contactMethod: getValues('pcontactmethod') ? getValues('pcontactmethod') : '',
                            reminder: getValues('automatedreminder'),
                        },
                        request: {
                            isExistingClinic: true,
                            isNewPatient: true,
                            paymode: 'cash,credit,card,cheque'
                        }
                    });
                } else if (getValues('patienttype') === 'dependent') {
                    Object.assign(data, {
                        isIndividual: false,
                        patient: {
                            fname: getValues('firstName'),
                            lname: getValues('lastName'),
                            email: getValues('email'),
                            phone: getValues('phone'),
                            dob: moment(getValues('dob')).format('YYYY-MM-DD'),
                            gender: getValues('gender'),
                            contactMethod: getValues('pcontactmethod') ? getValues('pcontactmethod') : '',
                            reminder: getValues('automatedreminder'),
                        },
                        dependant: {
                            fname: getValues('firstName'),
                            lname: getValues('lastName'),
                            email: getValues('email'),
                            phone: getValues('phone'),
                            dob: moment(getValues('dob')).format('YYYY-MM-DD'),
                            gender: getValues('gender'),
                            contactMethod: getValues('pcontactmethod') ? getValues('pcontactmethod') : '',
                            reminder: getValues('automatedreminder'),
                        },
                        request: {
                            isExistingClinic: true,
                            isNewPatient: true,
                            paymode: 'cash,credit,card,cheque'
                        }
                    });
                }
            } else {
                let insurance: any = masterInsuranceList.find((item: any) => item.id === getValues('insurancename'));
                let insurancePlan = masterInsurancePlanList.find((item: any) => item.id == getValues('insuranceplanname'));
                if (getValues('patienttype') === 'individual' && getValues('secondaryguarantor')) {
                    let secondaryguarantor: any = guarantorList[0];
                    Object.assign(data, {
                        isIndividual: true,
                        patient: {
                            fname: getValues('firstName'),
                            lname: getValues('lastName'),
                            email: getValues('email'),
                            phone: getValues('phone'),
                            dob: moment(getValues('dob')).format('YYYY-MM-DD'),
                            gender: getValues('gender'),
                            contactMethod: getValues('pcontactmethod') ? getValues('pcontactmethod') : '',
                            reminder: getValues('automatedreminder'),
                            insurance: {
                                id: insurance.id,
                                name: getValues('insurancename') === 'other' ? getValues('insuranceothername') : insurance.name,
                                plan: insurancePlan.id,
                                planName: getValues('insuranceplanname') === 'other' ? getValues('insuranceotherplanname') : insurancePlan.name
                            }
                            // insuranceplanname: insurancePlan ? insurancePlan.name : '',
                            // insuranceplanId: insurancePlan ? insurancePlan.id : '',
                            // insurancename: insurance ? insurance : '',
                        },
                        dependant: {
                            fname: getValues('firstName'),
                            lname: getValues('lastName'),
                            email: getValues('email'),
                            phone: getValues('phone'),
                            dob: moment(getValues('dob')).format('YYYY-MM-DD'),
                            gender: getValues('gender'),
                            contactMethod: getValues('pcontactmethod') ? getValues('pcontactmethod') : '',
                            reminder: getValues('automatedreminder'),
                            insurance: {
                                id: insurance.id,
                                name: getValues('insurancename') === 'other' ? getValues('insuranceothername') : insurance.name,
                                plan: insurancePlan.id,
                                planName: getValues('insuranceplanname') === 'other' ? getValues('insuranceotherplanname') : insurancePlan.name
                            }
                            // insuranceplanname: insurancePlan ? insurancePlan.name : '',
                            // insuranceplanId: insurancePlan ? insurancePlan.id : '',
                            // insurancename: insurance ? insurance : '',
                        },
                        xguarantor: secondaryguarantor,
                        request: {
                            isExistingClinic: true,
                            isNewPatient: true
                        }
                    });
                } else if (getValues('patienttype') === 'individual' && !getValues('secondaryguarantor')) {
                    Object.assign(data, {
                        isIndividual: true,
                        patient: {
                            fname: getValues('firstName'),
                            lname: getValues('lastName'),
                            email: getValues('email'),
                            phone: getValues('phone'),
                            dob: moment(getValues('dob')).format('YYYY-MM-DD'),
                            gender: getValues('gender'),
                            contactMethod: getValues('pcontactmethod') ? getValues('pcontactmethod') : '',
                            reminder: getValues('automatedreminder'),
                            insurance: {
                                id: insurance.id,
                                name: getValues('insurancename') === 'other' ? getValues('insuranceothername') : insurance.name,
                                plan: insurancePlan.id,
                                planName: getValues('insuranceplanname') === 'other' ? getValues('insuranceotherplanname') : insurancePlan.name
                            }
                            // insuranceplanname: insurancePlan ? insurancePlan.name : '',
                            // insuranceplanId: insurancePlan ? insurancePlan.id : '',
                            // insurancename: insurance ? insurance : '',
                        },
                        dependant: {
                            fname: getValues('firstName'),
                            lname: getValues('lastName'),
                            email: getValues('email'),
                            phone: getValues('phone'),
                            dob: moment(getValues('dob')).format('YYYY-MM-DD'),
                            gender: getValues('gender'),
                            contactMethod: getValues('pcontactmethod') ? getValues('pcontactmethod') : '',
                            reminder: getValues('automatedreminder'),
                            insurance: {
                                id: insurance.id,
                                name: getValues('insurancename') === 'other' ? getValues('insuranceothername') : insurance.name,
                                plan: insurancePlan.id,
                                planName: getValues('insuranceplanname') === 'other' ? getValues('insuranceotherplanname') : insurancePlan.name
                            }
                            // insuranceplanname: insurancePlan ? insurancePlan.name : '',
                            // insuranceplanId: insurancePlan ? insurancePlan.id : '',
                            // insurancename: insurance ? insurance : '',
                        },
                        request: {
                            isExistingClinic: true,
                            isNewPatient: true,
                        }
                    });
                } else if (getValues('patienttype') === 'dependent' && getValues('secondaryguarantor')[0] !== undefined) {
                    const primaryguarantor: any = guarantorList[0];
                    if (getValues('email') === primaryguarantor.email && getValues('phone') === primaryguarantor.phone) {
                        setError('globalError', { type: 'custom', message: 'Dependant email,mobile# and Primary guarantor email,mobile# can\'t be same.' });
                        setLoadingWaitlist(false);
                        return;
                    }
                    setError('globalError', {});
                    const secondaryguarantor: any = guarantorList[1];
                    Object.assign(data, {
                        isIndividual: false,
                        patient: primaryguarantor,
                        xguarantor: secondaryguarantor,
                        dependant: {
                            fname: getValues('firstName'),
                            lname: getValues('lastName'),
                            email: getValues('email'),
                            phone: getValues('phone'),
                            dob: moment(getValues('dob')).format('YYYY-MM-DD'),
                            gender: getValues('gender'),
                            contactMethod: getValues('pcontactmethod') ? getValues('pcontactmethod') : '',
                            reminder: getValues('automatedreminder'),
                            insurance: {
                                id: insurance.id,
                                name: getValues('insurancename') === 'other' ? getValues('insuranceothername') : insurance.name,
                                plan: insurancePlan.id,
                                planName: getValues('insuranceplanname') === 'other' ? getValues('insuranceotherplanname') : insurancePlan.name
                            }
                            // insuranceplanname: insurancePlan ? insurancePlan.name : '',
                            // insuranceplanId: insurancePlan ? insurancePlan.id : '',
                            // insurancename: insurance ? insurance : '',
                        },
                        request: {
                            isExistingClinic: true,
                            isNewPatient: true,
                        }
                    });
                } else if (getValues('patienttype') === 'dependent' && getValues('primaryguarantor') !== undefined) {
                    const primaryguarantor: any = guarantorList[0];
                    if (getValues('email') === primaryguarantor.email && getValues('phone') === primaryguarantor.phone) {
                        setError('globalError', { type: 'custom', message: 'Dependant email,mobile# and Primary guarantor email,mobile# can\'t be same.' });
                        setLoadingWaitlist(false);
                        return;
                    }
                    setError('globalError', {});
                    Object.assign(data, {
                        isIndividual: false,
                        patient: primaryguarantor,
                        dependant: {
                            fname: getValues('firstName'),
                            lname: getValues('lastName'),
                            email: getValues('email'),
                            phone: getValues('phone'),
                            dob: moment(getValues('dob')).format('YYYY-MM-DD'),
                            gender: getValues('gender'),
                            contactMethod: getValues('pcontactmethod') ? getValues('pcontactmethod') : '',
                            reminder: getValues('automatedreminder'),
                            insurance: {
                                id: insurance.id,
                                name: getValues('insurancename') === 'other' ? getValues('insuranceothername') : insurance.name,
                                plan: insurancePlan.id,
                                planName: getValues('insuranceplanname') === 'other' ? getValues('insuranceotherplanname') : insurancePlan.name
                            }
                            // insuranceplanname: insurancePlan ? insurancePlan.name : '',
                            // insuranceplanId: insurancePlan ? insurancePlan.id : '',
                            // insurancename: insurance ? insurance : '',
                        },
                        request: {
                            isExistingClinic: true,
                            isNewPatient: true,
                        }
                    });
                }
            }
            setLoadingWaitlist(true);
            bookManualAppointment(data, userData.clinics[0].uniqueId).then(async (success) => {
                if (success && success.status && success.status.error === false) {
                    const obj = {
                        "appointmentId": Number(success.appopintment),
                        "senderId": Number(success.patient), //uniqueid
                        "sender": CHAT.SENDER.PATIENT,
                        "action": 'Changed status from CREATED to REQUESTED.',
                        "actionType": 'STATUS',
                        "createdAt": firebase.firestore.FieldValue.serverTimestamp(),
                        "updatedAt": firebase.firestore.FieldValue.serverTimestamp(),
                        "text": "manual request",
                        "fromStatus": 'CREATED',
                        "toStatus": 'REQUESTED',
                        "sendToCId": userData.clinics[0].uniqueId,
                        "dateTime": {
                            "date": date,
                            "startTime": startTime,
                            "endTime": endTime,
                            "time": startTime,
                        },
                        "dependantId": Number(success.dependant),
                        "firstName": userData.firstName,
                        "lastName": userData.lastName,
                    }
                    try {
                        const response = await firebaseInstance.addDocumentIntoCollection(configs.CHAT_COLLECTION, obj);
                        if (response) {
                            response.get().then((docRef: any) => {
                                let data = {
                                    ...docRef.data(),
                                    mode: "create",
                                    id: docRef.id
                                };
                                firestoreChangeNotificationToServer(data).then((success) => {
                                    if (fetchAppointment) fetchAppointment(true);
                                    setLoadingWaitlist(false);
                                    hideModal();
                                }).catch((err) => {
                                    setLoadingWaitlist(false);
                                    console.log(err);
                                })
                            });
                        }
                    } catch (err) {
                        setLoadingWaitlist(false);
                    }
                }
            }).catch((err) => {
                setLoadingWaitlist(false);
                toast.error('Appointment book errror, please try again.');
                console.log(err);
            });
        } catch (err) {
            console.log(err);
            setLoadingWaitlist(false);
            toast.error('Appointment book failed.');
        }
        //     } else {
        //         setLoadingWaitlist(false);
        //     }
        // }).catch((err) => {
        //     toast.error(err.msg);
        // })
    }
    const handleScheduleNew = (data: any) => {
        const date = moment(getValues('scheduleDate')).format('YYYY-MM-DD');
        const startTime = moment(getValues('startTime')).format('LT');
        const endTime = moment(getValues('endTime')).format('LT');
        if (moment(date, 'YYYY-MM-DD').isBefore(moment().format('YYYY-MM-DD'))) {
            setError('scheduleDate', { type: 'custom', message: 'Please select date which is not previous.' });
            return;
        } else if (moment(date + " " + startTime, 'YYYY-MM-DD LT').isBefore(moment())) {
            setError('scheduleDate', { type: 'custom', message: 'Please select date and time which is not previous.' });
            return;
        } else if (moment(startTime, "LT").isSameOrAfter(moment(endTime, "LT"))) {
            setError('scheduleDate', { type: 'custom', message: 'Start time should be previous to end time.' });
            return;
        } else if (getValues('paythroughinsurance') === 'yes' && getValues('patienttype') === 'individual' && getValues('secondaryguarantor')[0] !== undefined) {
            if (guarantorList.length === 0) {
                setError('scheduleDate', { type: 'custom', message: 'Please select secondary guarantor.' });
                return;
            }
        } else if (getValues('paythroughinsurance') === 'yes' && getValues('patienttype') === 'dependent' && getValues('secondaryguarantor')[0] !== undefined) {
            if (guarantorList.length === 0) {
                setError('scheduleDate', { type: 'custom', message: 'Please select primary and secondary guarantor.' });
                return;
            } else if (guarantorList.length === 1) {
                setError('scheduleDate', { type: 'custom', message: 'Please select secondary guarantor.' });
                return;
            }
        } else if (getValues('paythroughinsurance') === 'yes' && getValues('patienttype') === 'dependent' && getValues('primaryguarantor') !== undefined) {
            if (guarantorList.length === 0) {
                setError('scheduleDate', { type: 'custom', message: 'Please select primary guarantor.' });
                return;
            }
        }
        const providerSelected: any = providerList.find((item: any) => item.id === getValues('provider'));
        let availableDoctor = checkProviderAvailable(userData.clinics[0], providerSelected, date, startTime, endTime);
        if (availableDoctor === false) {
            setError('globalError', { type: 'custom', message: 'Provider is not available on selected time.' });
            return;
        }
        setError('scheduleDate', {});
        const provider: any = providerList.find((item: any) => item.id === getValues('provider'));
        setLoadingScheduled(true);
        checkAppointmentBook({
            provider: provider.uniqueid,
            date: date,
            starttime: startTime,
            endtime: endTime
        }).then((success) => {
            if (success && success.status && success.status.error === false) {
                try {
                    let data = {
                        appointmentStatus: "scheduled",
                        appointmenttype: { id: getValues('appointmentType') },
                        clinic: {
                            id: userData.clinics[0].id,
                            doctor: {
                                id: getValues('provider')
                            }
                        },
                        dateTime: {
                            date: date,
                            startTime: startTime,
                            endTime: endTime,
                            starttime: startTime,
                            endtime: endTime,
                        },
                        isExistingClinic: true,
                        isNewPatient: true,
                        proceedureCode: proceedureCodeTable.map((item: any, index: number) => ({ ...item, number: index + 1, price: item.amount }))
                    }
                    if (getValues('paythroughinsurance') === 'no') {
                        if (getValues('patienttype') === 'individual') {
                            Object.assign(data, {
                                isIndividual: true,
                                patient: {
                                    fname: getValues('firstName'),
                                    lname: getValues('lastName'),
                                    email: getValues('email'),
                                    phone: getValues('phone'),
                                    dob: moment(getValues('dob')).format('YYYY-MM-DD'),
                                    gender: getValues('gender'),
                                    contactMethod: getValues('pcontactmethod'),
                                    reminder: getValues('automatedreminder'),
                                },
                                dependant: {
                                    fname: getValues('firstName'),
                                    lname: getValues('lastName'),
                                    email: getValues('email'),
                                    phone: getValues('phone'),
                                    dob: moment(getValues('dob')).format('YYYY-MM-DD'),
                                    gender: getValues('gender'),
                                    contactMethod: getValues('pcontactmethod') ? getValues('pcontactmethod') : '',
                                    reminder: getValues('automatedreminder'),
                                },
                                request: {
                                    isExistingClinic: true,
                                    isNewPatient: true,
                                    paymode: 'cash,credit,card,cheque'
                                }
                            });
                        } else if (getValues('patienttype') === 'dependent') {
                            Object.assign(data, {
                                isIndividual: false,
                                patient: {
                                    fname: getValues('firstName'),
                                    lname: getValues('lastName'),
                                    email: getValues('email'),
                                    phone: getValues('phone'),
                                    dob: moment(getValues('dob')).format('YYYY-MM-DD'),
                                    gender: getValues('gender'),
                                    contactMethod: getValues('pcontactmethod') ? getValues('pcontactmethod') : '',
                                    reminder: getValues('automatedreminder'),
                                },
                                dependant: {
                                    fname: getValues('firstName'),
                                    lname: getValues('lastName'),
                                    email: getValues('email'),
                                    phone: getValues('phone'),
                                    dob: moment(getValues('dob')).format('YYYY-MM-DD'),
                                    gender: getValues('gender'),
                                    contactMethod: getValues('pcontactmethod') ? getValues('pcontactmethod') : '',
                                    reminder: getValues('automatedreminder'),
                                },
                                request: {
                                    isExistingClinic: true,
                                    isNewPatient: true,
                                    paymode: 'cash,credit,card,cheque'
                                }
                            });
                        }
                    } else {
                        let insurance: any = masterInsuranceList.find((item: any) => item.id === getValues('insurancename'));
                        let insurancePlan = masterInsurancePlanList.find((item: any) => item.id == getValues('insuranceplanname'));
                        if (getValues('patienttype') === 'individual' && getValues('secondaryguarantor')) {
                            let secondaryguarantor: any = guarantorList[0];
                            Object.assign(data, {
                                isIndividual: true,
                                patient: {
                                    fname: getValues('firstName'),
                                    lname: getValues('lastName'),
                                    email: getValues('email'),
                                    phone: getValues('phone'),
                                    dob: moment(getValues('dob')).format('YYYY-MM-DD'),
                                    gender: getValues('gender'),
                                    contactMethod: getValues('pcontactmethod') ? getValues('pcontactmethod') : '',
                                    reminder: getValues('automatedreminder'),
                                    insurance: {
                                        id: insurance.id,
                                        name: getValues('insurancename') === 'other' ? getValues('insuranceothername') : insurance.name,
                                        plan: insurancePlan.id,
                                        planName: getValues('insuranceplanname') === 'other' ? getValues('insuranceotherplanname') : insurancePlan.name
                                    },
                                    // insuranceplanname: insurancePlan ? insurancePlan.name : '',
                                    // insuranceplanId: insurancePlan ? insurancePlan.id : '',
                                    // insurancename: insurance ? insurance : ''
                                },
                                dependant: {
                                    fname: getValues('firstName'),
                                    lname: getValues('lastName'),
                                    email: getValues('email'),
                                    phone: getValues('phone'),
                                    dob: moment(getValues('dob')).format('YYYY-MM-DD'),
                                    gender: getValues('gender'),
                                    contactMethod: getValues('pcontactmethod') ? getValues('pcontactmethod') : '',
                                    reminder: getValues('automatedreminder'),
                                    insurance: {
                                        id: insurance.id,
                                        name: getValues('insurancename') === 'other' ? getValues('insuranceothername') : insurance.name,
                                        plan: insurancePlan.id,
                                        planName: getValues('insuranceplanname') === 'other' ? getValues('insuranceotherplanname') : insurancePlan.name
                                    },
                                    // insuranceplanname: insurancePlan ? insurancePlan.name : '',
                                    // insuranceplanId: insurancePlan ? insurancePlan.id : '',
                                    // insurancename: insurance ? insurance : ''
                                },
                                xguarantor: secondaryguarantor,
                                request: {
                                    isExistingClinic: true,
                                    isNewPatient: true,
                                }
                            });
                        } else if (getValues('patienttype') === 'individual' && !getValues('secondaryguarantor')) {
                            Object.assign(data, {
                                isIndividual: true,
                                patient: {
                                    fname: getValues('firstName'),
                                    lname: getValues('lastName'),
                                    email: getValues('email'),
                                    phone: getValues('phone'),
                                    dob: moment(getValues('dob')).format('YYYY-MM-DD'),
                                    gender: getValues('gender'),
                                    contactMethod: getValues('pcontactmethod') ? getValues('pcontactmethod') : '',
                                    reminder: getValues('automatedreminder'),
                                    insurance: {
                                        id: insurance.id,
                                        name: getValues('insurancename') === 'other' ? getValues('insuranceothername') : insurance.name,
                                        plan: insurancePlan.id,
                                        planName: getValues('insuranceplanname') === 'other' ? getValues('insuranceotherplanname') : insurancePlan.name
                                    },
                                    // insuranceplanname: insurancePlan ? insurancePlan.name : '',
                                    // insuranceplanId: insurancePlan ? insurancePlan.id : '',
                                    // insurancename: insurance ? insurance : ''
                                },
                                dependant: {
                                    fname: getValues('firstName'),
                                    lname: getValues('lastName'),
                                    email: getValues('email'),
                                    phone: getValues('phone'),
                                    dob: moment(getValues('dob')).format('YYYY-MM-DD'),
                                    gender: getValues('gender'),
                                    contactMethod: getValues('pcontactmethod') ? getValues('pcontactmethod') : '',
                                    reminder: getValues('automatedreminder'),
                                    insurance: {
                                        id: insurance.id,
                                        name: getValues('insurancename') === 'other' ? getValues('insuranceothername') : insurance.name,
                                        plan: insurancePlan.id,
                                        planName: getValues('insuranceplanname') === 'other' ? getValues('insuranceotherplanname') : insurancePlan.name
                                    },
                                    // insuranceplanname: insurancePlan ? insurancePlan.name : '',
                                    // insuranceplanId: insurancePlan ? insurancePlan.id : '',
                                    // insurancename: insurance ? insurance : ''
                                },
                                request: {
                                    isExistingClinic: true,
                                    isNewPatient: true,
                                }
                            });
                        } else if (getValues('patienttype') === 'dependent' && getValues('secondaryguarantor')[0] !== undefined) {
                            const primaryguarantor: any = guarantorList[0];
                            if (getValues('email') === primaryguarantor.email && getValues('phone') === primaryguarantor.phone) {
                                setError('globalError', { type: 'custom', message: 'Dependant email,mobile# and Primary guarantor email,mobile# can\'t be same.' });
                                setLoadingScheduled(false);
                                return;
                            }
                            setError('globalError', {});
                            const secondaryguarantor: any = guarantorList[1];
                            Object.assign(data, {
                                isIndividual: false,
                                patient: primaryguarantor,
                                xguarantor: secondaryguarantor,
                                dependant: {
                                    fname: getValues('firstName'),
                                    lname: getValues('lastName'),
                                    email: getValues('email'),
                                    phone: getValues('phone'),
                                    dob: moment(getValues('dob')).format('YYYY-MM-DD'),
                                    gender: getValues('gender'),
                                    contactMethod: getValues('pcontactmethod') ? getValues('pcontactmethod') : '',
                                    reminder: getValues('automatedreminder'),
                                    insurance: {
                                        id: insurance.id,
                                        name: getValues('insurancename') === 'other' ? getValues('insuranceothername') : insurance.name,
                                        plan: insurancePlan.id,
                                        planName: getValues('insuranceplanname') === 'other' ? getValues('insuranceotherplanname') : insurancePlan.name
                                    },
                                    // insuranceplanname: insurancePlan ? insurancePlan.name : '',
                                    // insuranceplanId: insurancePlan ? insurancePlan.id : '',
                                    // insurancename: insurance ? insurance : ''
                                },
                                request: {
                                    isExistingClinic: true,
                                    isNewPatient: true,
                                }
                            });
                        } else if (getValues('patienttype') === 'dependent' && getValues('primaryguarantor') !== undefined) {
                            const primaryguarantor: any = guarantorList[0];
                            if (getValues('email') === primaryguarantor.email && getValues('phone') === primaryguarantor.phone) {
                                setError('globalError', { type: 'custom', message: 'Dependant email,mobile# and Primary guarantor email,mobile# can\'t be same.' });
                                setLoadingScheduled(false);
                                return;
                            }
                            setError('globalError', {});
                            Object.assign(data, {
                                isIndividual: false,
                                patient: primaryguarantor,
                                dependant: {
                                    fname: getValues('firstName'),
                                    lname: getValues('lastName'),
                                    email: getValues('email'),
                                    phone: getValues('phone'),
                                    dob: moment(getValues('dob')).format('YYYY-MM-DD'),
                                    gender: getValues('gender'),
                                    contactMethod: getValues('pcontactmethod') ? getValues('pcontactmethod') : '',
                                    reminder: getValues('automatedreminder'),
                                    insurance: {
                                        id: insurance.id,
                                        name: getValues('insurancename') === 'other' ? getValues('insuranceothername') : insurance.name,
                                        plan: insurancePlan.id,
                                        planName: getValues('insuranceplanname') === 'other' ? getValues('insuranceotherplanname') : insurancePlan.name
                                    },
                                },
                                request: {
                                    isExistingClinic: true,
                                    isNewPatient: true,
                                }
                            });
                        }
                    }
                    setLoadingScheduled(true);
                    bookManualAppointment(data, userData.clinics[0].uniqueId).then(async (successManual) => {
                        if (successManual && successManual.status && successManual.status.error === false) {
                            const obj = {
                                "appointmentId": Number(successManual.appopintment),
                                "senderId": Number(successManual.patient), //uniqueid
                                "sender": CHAT.SENDER.PATIENT,
                                "action": 'Changed status from CREATED to REQUESTED.',
                                "actionType": 'STATUS',
                                "createdAt": firebase.firestore.FieldValue.serverTimestamp(),
                                "updatedAt": firebase.firestore.FieldValue.serverTimestamp(),
                                "text": "manual request",
                                "fromStatus": 'CREATED',
                                "toStatus": 'REQUESTED',
                                "sendToCId": userData.clinics[0].uniqueId,
                                "dateTime": {
                                    "date": date,
                                    "startTime": startTime,
                                    "endTime": endTime,
                                    "time": startTime,
                                },
                                "dependantId": Number(successManual.dependant),
                                "firstName": userData.firstName,
                                "lastName": userData.lastName,
                            }
                            try {
                                const response = await firebaseInstance.addDocumentIntoCollection(configs.CHAT_COLLECTION, obj);
                                if (response) {
                                    response.get().then((docRef: any) => {
                                        let data = {
                                            ...docRef.data(),
                                            mode: "create",
                                            id: docRef.id
                                        };
                                        firestoreChangeNotificationToServer(data).then(async (success) => {
                                            // toast.success('Appointment is requested.');
                                            const scheduleObj = {
                                                "appointmentId": Number(successManual.appopintment),
                                                "senderId": Number(getCookie().xpr_user_id), //login user id
                                                "sender": CHAT.SENDER.USER,
                                                "action": `Changed status from REQUESTED to SCHEDULED.`,
                                                "actionType": 'STATUS',
                                                "dateTime": {
                                                    "date": date,
                                                    "time": startTime,
                                                    "startTime": startTime,
                                                    "endTime": endTime
                                                },
                                                "text": "manual scheduled",
                                                "createdAt": firebase.firestore.FieldValue.serverTimestamp(),
                                                "updatedAt": firebase.firestore.FieldValue.serverTimestamp(),
                                                "fromStatus": CHAT.FROM_STATUS.REQUESTED,
                                                "toStatus": CHAT.TO_STATUS.SCHEDULED,
                                                "sendToCId": userData.clinics[0].uniqueId,
                                                "dependantId": Number(successManual.dependant),
                                                "firstName": userData.firstName,
                                                "lastName": userData.lastName,
                                            };
                                            const scheduleResponse = await firebaseInstance.addDocumentIntoCollection(configs.CHAT_COLLECTION, scheduleObj);
                                            if (scheduleResponse) {
                                                scheduleResponse.get().then((docRef: any) => {
                                                    let data = {
                                                        ...docRef.data(),
                                                        mode: "create",
                                                        id: docRef.id
                                                    };
                                                    firestoreChangeNotificationToServer(data).then(async (success) => {
                                                        if (fetchAppointment) fetchAppointment(true);
                                                        setLoadingScheduled(false);
                                                        toast.success('Appointment is scheduled');
                                                        hideModal();
                                                    }).catch((err) => {
                                                        toast.error('Appointment book error');
                                                        setLoadingScheduled(false);
                                                    })
                                                });
                                            }
                                        }).catch((err) => {
                                            setLoadingScheduled(false);
                                            toast.error('Appointment book error');
                                            console.log(err);
                                        })
                                    });
                                }
                            } catch (err) {
                                setLoadingScheduled(false);
                                toast.error('Appointment book error');
                            }
                        }
                    }).catch((err) => {
                        setLoadingScheduled(false);
                        if (err.msg) {
                            toast.error(err.msg);
                        } else {

                        }
                        console.log(err);
                    });
                } catch (err) {
                    setLoadingScheduled(false);
                    toast.error('Appointment book failed.');
                }
            } else {
                setLoadingScheduled(false);
            }
        }).catch((err) => {
            setLoadingScheduled(false);
            toast.error(err.msg);
        })
    }

    const fetchAppointmentCategory = () => {
        getAppointmentCategoryList({
            clinicid: userData.clinics[0].id
        }).then((success) => {
            setAppointmentCategoryList(success.data);
            setCategory(success.data[0]?.procedure_code_category ?? '');
            fetchCategoryCode(success.data[0]?.procedure_code_category ?? '');
        }).catch((err) => {
            console.log("err", err);
        })
    }

    const fetchCategoryCode = (category = '') => {
        if (category === '') return;
        getProcedureCodeList({
            clinicid: userData.clinics[0].id,
            category: [category],
            type: 'custom'
        }).then((success) => {
            setCategoryCodeList(success.data[0]);
        }).catch((err) => {
            console.log("err", err);
        })
    }
    const handleAdd = () => {
        if (category === '') {
            toast.error("Please select category");
            return;
        } else if (proceedureCode === '') {
            toast.error("Please select proceedure code");
            return;
        } else if (amount === '') {
            toast.error("Please enter amount");
            return;
        } else if (isNaN(Number(amount))) {
            toast.error("Please enter valid amount");
            return;
        }
        if (selectedIndex !== -1) {
            // edit
            const cloned = cloneDeep(proceedureCodeTable);
            cloned.splice(selectedIndex, 1, {
                category: category,
                code: proceedureCode,
                amount: amount
            });
            setProceedureCodeTable(cloned);
        } else {
            // add
            setProceedureCodeTable([...proceedureCodeTable, {
                category: category,
                code: proceedureCode,
                amount: amount
            }]);
        }
        setCategory('');
        setProceedureCode('');
        setAmount('');
        fetchCategoryCode('');
        setSelectedIndex(-1);
    }
    return (
        <>
            <Modal className={"newcommon addnewappt " + (showGurantorInfoModal ? 'd-none' : '')} centered show={true} backdrop={true} onHide={() => { hideModal() }}>
                <form onSubmit={(e) => { e.preventDefault(); }}>
                    <Modal.Header>
                        <div className="modal-title">Create a new appointment</div>
                        <button type="button" className="btn-close" aria-label="Close" onClick={() => { hideModal() }}>
                        </button>
                    </Modal.Header>

                    <Modal.Body>
                        <div className="cal_pop">
                            {
                                (!checkValidDateTimePerProvider() && (getValues('appointmentfor') === "yes" || getValues('appointmentfor') === "no") && selectedDoctorName) &&
                                <div className="mb-4 border px-3 py-2 border-1 rounded d-flex bg-danger error-box justify-content-between d-none">
                                    <div className="mr-4">
                                        {selectedDoctorName} have appointment on selected time
                                    </div>
                                </div>
                            }
                            {/* Appointment for */}
                            <label className="form-label">Scheduling appointment for?</label>
                            <div className="d-flex align-items-center mb-3">
                                <div className="form-check me-3">
                                    <input className="form-check-input" type="radio" name="appointmentfor" value="yes"
                                        id="existing-patient" ref={register({ required: true })} />
                                    <label className="form-check-label" htmlFor="existing-patient">
                                        Existing Patient
                                    </label>
                                </div>
                                <div className="form-check me-3">
                                    <input className="form-check-input" type="radio" name="appointmentfor" value="no"
                                        id="new-patient" ref={register({ required: true })} />
                                    <label className="form-check-label" htmlFor="new-patient">
                                        New Patient
                                    </label>
                                </div>
                                <div className="form-check me-3">
                                    <input className="form-check-input" type="radio" name="appointmentfor" value="booktimeslot"
                                        id="block-slot" ref={register({ required: true })} />
                                    <label className="form-check-label" htmlFor="block-slot">
                                        Block Time
                                    </label>
                                </div>
                            </div>
                            {
                                errors && errors.appointmentfor && errors.appointmentfor.type === "required" &&
                                <div className="is-invalid">Please select atleast one.</div>
                            }
                            {
                                watch('appointmentfor') && watch('appointmentfor') !== '' &&
                                <>
                                    {/* if selected is existing patient render patient search input */}
                                    {
                                        watch('appointmentfor') === "yes" &&
                                        <div className="row mb-3">
                                            <label className="form-label">Search patient</label>
                                            <div className="col">
                                                <input type="text" placeholder="patient name , email or mobile #" className="form-control" name="patientname" ref={register({ required: true })} />
                                            </div>
                                            <div className="col-auto">
                                                <button className="btn btn-outline-primary" disabled={loadingSearch} onClick={() => {
                                                    getPatietListOnSearch();
                                                }}>
                                                    {
                                                        loadingSearch &&
                                                        <span className="spinner-border spinner-border-sm me-1" role="status" aria-hidden="true"></span>
                                                    }Search</button>
                                            </div>
                                            <div className="form-text d-none"><i className="bi bi-info-circle"></i> Email & mobile # should be registered on XCare</div>
                                        </div>
                                    }

                                    {/* if patient is existing */}
                                    {
                                        watch('appointmentfor') === 'yes' && patientList.length > 0 &&
                                        <>
                                            <div className="accordion fx-accordion-height shadow-sm" id="popupsearchlist">
                                                {
                                                    patientList.map((value: any, index: number) => {
                                                        return (
                                                            <div className="accordion-item" key={value.dependant.id}>
                                                                <h2 className="accordion-header" id={"ptsearchlist" + index}>
                                                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target={"#collapse" + index} aria-expanded="true" aria-controls={"collapse" + index}>
                                                                        <div className="d-flex align-items-center">
                                                                            <div className="me-3">
                                                                                <input className="form-check-input" type="radio" name="ptsearchradio" id={"ptsearchradio" + index}
                                                                                    value={value.dependant.id}
                                                                                    ref={register({ required: true })} />
                                                                            </div>
                                                                            <span className="avatar flex-shrink-0 avatar-48 img-circle me-2">{value.dependant.fname.charAt(0)}{value.dependant.lname.charAt(0)}</span>
                                                                            <div>
                                                                                <div className="fw-medium">{value.dependant.fname + " " + value.dependant.lname}</div>
                                                                                <span className="text-muted">{value.dependant.email}</span>
                                                                            </div>
                                                                        </div>
                                                                    </button>
                                                                </h2>
                                                                <div id={"collapse" + index} className="accordion-collapse collapse" aria-labelledby={"ptsearchlist" + index} data-bs-parent="#popupsearchlist">
                                                                    <div className="accordion-body">
                                                                        <div className="d-flex align-items-center">
                                                                            <div>
                                                                                <label>Guarantor info</label>
                                                                                <div className="fw-medium">{value.guarantor.fname + " " + value.guarantor.lname}</div>
                                                                                <span className="text-muted">{value.guarantor.email}</span>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        )
                                                    })
                                                }
                                            </div>
                                            {
                                                errors && errors.ptsearchradio && errors.ptsearchradio.type === 'required' &&
                                                <div className="is-invalid">Please select either of patient.</div>
                                            }
                                        </>
                                    }

                                    {/* if selected is new patient */}
                                    {
                                        watch('appointmentfor') === "no" &&
                                        <div className="mb-3">
                                            <label className="form-label">
                                                Is patient dependent or Individual ?
                                            </label>
                                            <div className="d-flex align-items-center mb-3">
                                                <div className="form-check me-3">
                                                    <input className="form-check-input" type="radio" name="patienttype" id="individual" value="individual" ref={register({ required: true })} />
                                                    <label className="form-check-label" htmlFor="individual">
                                                        Individual
                                                    </label>
                                                </div>
                                                <div className="form-check me-3">
                                                    <input className="form-check-input" type="radio" name="patienttype" id="dependent" value="dependent" ref={register({ required: true })} />
                                                    <label className="form-check-label" htmlFor="dependent">
                                                        Dependent
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                    }

                                    {/* if new patient accept patient detail */}
                                    {
                                        watch('appointmentfor') === "no" &&
                                        <div className="row g-3 mb-3">
                                            <div className="col-6">
                                                <label className="form-label">First Name:</label>
                                                <input type="text" className="form-control" placeholder="Enter first name" name="firstName" ref={register({ required: true })} />
                                                {
                                                    (errors && errors.firstName && errors.firstName.type === 'required') &&
                                                    <div className="is-invalid">First name is required.</div>
                                                }
                                            </div>

                                            <div className="col-6">
                                                <label className="form-label">Last Name:</label>
                                                <input type="text" className="form-control" placeholder="Enter last name" name="lastName" ref={register({ required: true })} />
                                                {
                                                    (errors && errors.lastName && errors.lastName.type === 'required') &&
                                                    <div className="is-invalid">Last name is required.</div>
                                                }
                                            </div>
                                            <div className="col-6">
                                                <label className="form-label">Enter Email:</label>
                                                <input type="email" className="form-control" placeholder="Enter email" name="email" ref={register({ required: true })} />
                                                {
                                                    (errors && errors.email && errors.email.type === 'required') &&
                                                    <div className="is-invalid">Email is required.</div>
                                                }
                                            </div>

                                            <div className="col-6">
                                                <label className="form-label">Enter Mobile Number:</label>
                                                <Controller
                                                    as={ReactInputMask}
                                                    control={control}
                                                    mask="9999999999"
                                                    name="phone"
                                                    className="form-control"
                                                    placeholder="1234567890"
                                                    rules={{ required: true, minLength: { value: 10, message: 'Mobile Number is invalid' } }}
                                                />
                                                {
                                                    (errors && errors.phone && errors.phone.type === 'required') &&
                                                    <div className="is-invalid">Mobile Number is required.</div>
                                                }
                                            </div>
                                            <div className="col-6">
                                                <label className="form-label">Gender</label>
                                                <select className="form-select" name="gender" ref={register({ required: true })}>
                                                    <option value="Male">Male</option>
                                                    <option value="Female">Female</option>
                                                </select>
                                                {
                                                    (errors && errors.gender && errors.gender.type === 'required') &&
                                                    <div className="is-invalid">Gender is required.</div>
                                                }
                                            </div>
                                            <div className="col-6 miuif_margin">
                                                <label className="form-label">Date of birth</label>
                                                <Controller
                                                    name="dob"
                                                    control={control}
                                                    render={(props) => {
                                                        return (
                                                            <KeyboardDatePicker
                                                                margin="none"
                                                                id={"date-picker-dialog-dob"}
                                                                name={"date-picker-dialog-dob"}
                                                                format="YYYY/MM/DD"
                                                                maxDate={moment().subtract(1, 'days')}
                                                                value={props.value}
                                                                onChange={(date: any) => { props.onChange(date) }}
                                                                KeyboardButtonProps={{
                                                                    'aria-label': 'change date',
                                                                }}
                                                            />
                                                        )
                                                    }}
                                                ></Controller>
                                            </div>
                                            <div className="col-12 d-none">
                                                <label className="form-label">
                                                    Automated Reminder?
                                                </label>
                                                <div className="d-flex align-items-center">
                                                    <div className="form-check me-3">
                                                        <input className="form-check-input" type="radio" name="automatedreminder" id="a-yes" value="yes" ref={register({ required: true })} />
                                                        <label className="form-check-label" htmlFor="a-yes">Yes</label>
                                                    </div>
                                                    <div className="form-check me-3">
                                                        <input className="form-check-input" type="radio" name="automatedreminder" id="a-no" value="no" ref={register({ required: true })} />
                                                        <label className="form-check-label" htmlFor="a-no">No</label>
                                                    </div>
                                                </div>
                                                {
                                                    (errors && errors.automatedreminder && errors.automatedreminder.type === 'required') &&
                                                    <div className="is-invalid">Automated reminder is required.</div>
                                                }

                                                {
                                                    watch('automatedreminder') === 'no' &&
                                                    <div className="is-invalid">This patient will not receive notification.</div>
                                                }
                                            </div>

                                            {
                                                watch('automatedreminder') === 'yes' &&
                                                <div className="col-12 d-none">
                                                    <label className="form-label">
                                                        Preferred contact method?
                                                    </label>
                                                    <div className="d-flex align-items-center">
                                                        <div className="form-check me-3">
                                                            <input className="form-check-input" type="radio" name="pcontactmethod" id="email-only" value={"email"} ref={register({ required: true })} />
                                                            <label className="form-check-label" htmlFor="email-only">
                                                                Email Only
                                                            </label>
                                                        </div>
                                                        <div className="form-check me-3">
                                                            <input className="form-check-input" type="radio" name="pcontactmethod" id="text-only" value="text" ref={register({ required: true })} />
                                                            <label className="form-check-label" htmlFor="text-only">
                                                                Text Only
                                                            </label>
                                                        </div>

                                                        <div className="form-check me-3">
                                                            <input className="form-check-input" type="radio" name="pcontactmethod" id="et-both" value="email,text" ref={register({ required: true })} />
                                                            <label className="form-check-label" htmlFor="et-both">
                                                                Email & Text
                                                            </label>
                                                        </div>
                                                    </div>
                                                    {
                                                        (errors && errors.pcontactmethod && errors.pcontactmethod.type === 'required') &&
                                                        <div className="is-invalid">Contact method is required.</div>
                                                    }
                                                </div>
                                            }

                                            <div className="col-12">
                                                <label className="form-label">
                                                    Payment through insurance ?
                                                </label>
                                                <div className="d-flex align-items-center">
                                                    <div className="form-check me-3">
                                                        <input className="form-check-input" type="radio" name="paythroughinsurance" id="payinsurance-yes" value="yes" ref={register({ required: true })} />
                                                        <label className="form-check-label" htmlFor="payinsurance-yes">Yes</label>
                                                    </div>
                                                    <div className="form-check me-3">
                                                        <input className="form-check-input" type="radio" name="paythroughinsurance" id="payinsurance-no" value="no" ref={register({ required: true })} />
                                                        <label className="form-check-label" htmlFor="payinsurance-no">No</label>
                                                    </div>
                                                </div>
                                                {
                                                    (errors && errors.paythroughinsurance && errors.paythroughinsurance.type === 'required') &&
                                                    <div className="is-invalid">Pay through insurance is required.</div>
                                                }

                                                {
                                                    watch('paythroughinsurance') === 'no' &&
                                                    <div className="small text-muted">Payment is being done through cash, credit or cheque.</div>
                                                }
                                            </div>
                                            {
                                                (watch('paythroughinsurance') === 'yes') &&
                                                <>
                                                    <div className="col-6">
                                                        <label className="form-label">Insurance name:</label>
                                                        <select className="form-select" name="insurancename" ref={register({ required: true })} placeholder="Insurance name">
                                                            {
                                                                masterInsuranceList.map((item: any) => (
                                                                    <option value={item.id} key={item.id + item.name}>{item.name}</option>
                                                                ))
                                                            }
                                                        </select>
                                                        {
                                                            (watch('insurancename') === 'other') &&
                                                            <>
                                                                <input type="text" className="form-control" placeholder="Enter insurance plan name" name="insuranceothername" ref={register({ required: true })} />
                                                                {
                                                                    (errors && errors.insuranceothername && errors.insuranceothername.type === 'required') &&
                                                                    <div className="is-invalid">Required.</div>
                                                                }
                                                            </>
                                                        }
                                                        {/* <input type="text" className="form-control" placeholder="Enter insurance name" name="insurancename" ref={register({ required: true })} /> */}
                                                        {
                                                            (errors && errors.insurancename && errors.insurancename.type === 'required') &&
                                                            <div className="is-invalid">Insurance name is required.</div>
                                                        }
                                                    </div>
                                                    <div className="col-6">
                                                        <label className="form-label">Insurance plan name:</label>
                                                        <select className="form-select" name="insuranceplanname" ref={register({ required: true })} placeholder="Insurance name">
                                                            {
                                                                masterInsurancePlanList.map((item: any) => (
                                                                    <option value={item.id} key={item.id + "insurancePlan"}>{item.name}</option>
                                                                ))
                                                            }
                                                        </select>
                                                        {
                                                            (watch('insuranceplanname') === 'other') &&
                                                            <>
                                                                <input type="text" className="form-control" placeholder="Enter insurance plan name" name="insuranceotherplanname" ref={register({ required: true })} />
                                                                {
                                                                    (errors && errors.insuranceotherplanname && errors.insuranceotherplanname.type === 'required') &&
                                                                    <div className="is-invalid">Required.</div>
                                                                }
                                                            </>
                                                        }
                                                        {/* <input type="text" className="form-control" placeholder="Enter insurance plan name" name="insuranceplanname" ref={register({ required: true })} /> */}
                                                        {
                                                            (errors && errors.insuranceplanname && errors.insuranceplanname.type === 'required') &&
                                                            <div className="is-invalid">Insurance plan name is required.</div>
                                                        }
                                                    </div>
                                                </>
                                            }
                                            {
                                                watch('paythroughinsurance') === 'yes' &&
                                                <div className="col-12">
                                                    <label className="form-label">
                                                        Guarantor info
                                                    </label>
                                                    {
                                                        watch('patienttype') === 'individual' &&
                                                        <div className="d-flex align-items-center">
                                                            <div className="form-check me-3">
                                                                <input className="form-check-input" type="checkbox" name="selfguarantor" id="selfguarantor" value="self" disabled
                                                                    ref={register({ required: true })} />
                                                                <label className="form-check-label" htmlFor="selfguarantor">Self</label>
                                                            </div>
                                                            <div className="form-check me-3">
                                                                <input className="form-check-input" type="checkbox" name="secondaryguarantor" id="secondaryguarantor"
                                                                    disabled={!watch('selfguarantor')}
                                                                    value="secondary" ref={register} />
                                                                <label className="form-check-label" htmlFor="secondaryguarantor">Secondary</label>
                                                            </div>
                                                            {
                                                                (watch('secondaryguarantor') && watch('selfguarantor')) &&
                                                                <a href="#" onClick={() => {
                                                                    setShowGuarantorInfoModal(true);
                                                                }}>Click to add guarantor info</a>
                                                            }
                                                        </div>
                                                    }
                                                    {
                                                        watch('patienttype') === 'dependent' &&
                                                        <>
                                                            <div className="d-flex align-items-center">
                                                                <div className="form-check me-3">
                                                                    <input className="form-check-input" type="checkbox" name="primaryguarantor" id="primaryguarantor" value="primary" disabled ref={register({ required: true })} />
                                                                    <label className="form-check-label" htmlFor="primaryguarantor">Primary Guarantor</label>
                                                                </div>
                                                                <div className="form-check me-3">
                                                                    <input className="form-check-input" type="checkbox" name="secondaryguarantor" id="secondaryguarantor" value="secondary" ref={register} />
                                                                    <label className="form-check-label" htmlFor="secondaryguarantor">Secondary Guarantor</label>
                                                                </div>

                                                            </div>

                                                            <div>
                                                                {
                                                                    (watch('primaryguarantor') || watch('secondaryguarantor')) &&
                                                                    <a href="#" onClick={() => {
                                                                        setShowGuarantorInfoModal(true);
                                                                    }}>Click to add guarantor info</a>
                                                                }
                                                            </div>
                                                        </>
                                                    }
                                                </div>
                                            }
                                            <>
                                                {
                                                    (watch('patienttype') === 'individual' && guarantorList.length === 1) &&
                                                    <div className="alert alert-success alert-dismissible">Secondary Guarantor : {
                                                        guarantorList[0].fname + " " + guarantorList[0].lname + " (" +
                                                        guarantorList[0].email + ")"
                                                    }</div>
                                                }

                                                {
                                                    (watch('patienttype') === 'dependent' && guarantorList.length > 0) &&
                                                    <>
                                                        {
                                                            (watch('primaryguarantor') && guarantorList[0]) &&
                                                            <div className="alert alert-success alert-dismissible">Primary Guarantor : {
                                                                guarantorList[0].fname + " " + guarantorList[0].lname + " (" +
                                                                guarantorList[0].email + ")"
                                                            }</div>
                                                        }
                                                        {
                                                            (watch('secondaryguarantor') && guarantorList[1]) &&
                                                            <div className="alert alert-success alert-dismissible">Secondary Guarantor : {
                                                                guarantorList[1].fname + " " + guarantorList[1].lname + " (" +
                                                                guarantorList[1].email + ")"
                                                            }</div>
                                                        }
                                                    </>
                                                }
                                            </>

                                        </div>
                                    }
                                    {
                                        watch('appointmentfor') === 'booktimeslot' &&
                                        <div>
                                            <div className="row g-3">
                                                <div className="col-6">
                                                    <label className="form-label">First Name:</label>
                                                    <input type="text" className="form-control" placeholder="Enter first name" name="firstName" ref={register({
                                                        required: true,
                                                        pattern: /^[a-zA-Z]+$/
                                                    })} />
                                                    {
                                                        (errors && errors.firstName && errors.firstName.type === 'required') &&
                                                        <div className="is-invalid">First name is required</div>
                                                    }
                                                    {
                                                        (errors && errors.firstName && errors.firstName.type === 'pattern') &&
                                                        <div className="is-invalid">First name is invalid</div>
                                                    }
                                                </div>
                                                <div className="col-6">
                                                    <label className="form-label">Last Name:</label>
                                                    <input type="text" className="form-control" placeholder="Enter last name" name="lastName" ref={register({ required: true })} />
                                                    {
                                                        (errors && errors.lastName && errors.lastName.type === 'required') &&
                                                        <div className="is-invalid">Last name is required</div>
                                                    }
                                                    {
                                                        (errors && errors.lastName && errors.lastName.type === 'pattern') &&
                                                        <div className="is-invalid">Last name is invalid</div>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    }
                                    {/* Common section for all. */}
                                    <div className="row g-3">
                                        <div className="col-6">
                                            <div className="mb-3">
                                                <label className="form-label">{watch('appointmentfor') === "booktimeslot" ? 'Block time with' : 'Book appointment with'}</label>
                                                <select className="form-select" name="provider" ref={register({ required: true })}>
                                                    <option value=''>Select provider</option>
                                                    {
                                                        providerList.map((value: any, index: number) => {
                                                            return (
                                                                <option value={value.id} key={value.id + "provider"}>{value.fullname}</option>
                                                            )
                                                        })
                                                    }
                                                </select>
                                                {
                                                    (errors && errors.provider && errors.provider.type === 'required') &&
                                                    <div className="is-invalid">Please select provider</div>
                                                }
                                            </div>
                                        </div>
                                        <div className="col-6">
                                            {
                                                (watch('appointmentfor') === 'yes' || watch('appointmentfor') === 'no') &&
                                                <div className="mb-3">
                                                    <label className="form-label">Appointment Type</label>
                                                    <select className="form-select" name="appointmentType" ref={register({ required: true })}>
                                                        <option value=''>Select appointment type</option>
                                                        {
                                                            appointmentTypeList.map((value: any) => {
                                                                return (
                                                                    <option value={value.id} key={value.id + "appt"}>{value.name} - {value.speciality}</option>
                                                                )
                                                            })
                                                        }
                                                    </select>
                                                    {
                                                        (errors && errors.appointmentType && errors.appointmentType.type === 'required') &&
                                                        <div className="is-invalid">Please select appointment type</div>
                                                    }
                                                </div>
                                            }
                                        </div>
                                    </div>
                                    {
                                        watch('appointmentfor') === 'booktimeslot' &&
                                        <>
                                            <label className="form-label">Notes</label>
                                            <textarea className="form-control" rows={3} name="notes" ref={register} />
                                        </>
                                    }
                                    {
                                        watch('appointmentfor') === 'booktimeslot' &&
                                        <div className="row mb-3">
                                            <div className="col-12">
                                                <label className="form-label">
                                                    Which time you want to block ?
                                                </label>
                                                <div className="d-flex align-items-center">
                                                    <div className="form-check me-3">
                                                        <input type="radio" id="specificTime" name="blockType" className="form-check-input" value="specificTime"
                                                            ref={register({ required: true })} />
                                                        <label className="form-check-label" htmlFor="specificTime">Specific time</label>
                                                    </div>

                                                    <div className="form-check me-3">
                                                        <input type="radio" id="fullTime" name="blockType" className="form-check-input" value="fullTime" ref={register({ required: true })} />
                                                        <label className="form-check-label" htmlFor="fullTime">Full time</label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    }

                                    <div className="row miuif_margin">
                                        <div className="col-5">
                                            <label className="form-label">Date</label>
                                            <Controller
                                                control={control}
                                                name="scheduleDate"
                                                rules={{ required: true }}
                                                render={(props) => {
                                                    return (
                                                        <KeyboardDatePicker
                                                            margin="none"
                                                            id="date-picker-dialog-schedule-date"
                                                            format="YYYY-MM-DD"
                                                            minDate={new Date()}
                                                            value={props.value}
                                                            onChange={(date) => props.onChange(date)}
                                                            KeyboardButtonProps={{
                                                                'aria-label': 'change schedule date',
                                                            }}
                                                        />
                                                    )
                                                }}
                                            ></Controller>
                                            {
                                                (errors && errors.scheduleDate && errors.scheduleDate.type === 'required') &&
                                                <div className="is-invalid">Please select date</div>
                                            }
                                        </div>
                                        {
                                            (watch('appointmentfor') === 'yes' || watch('appointmentfor') === 'no' || watch('blockType') === 'specificTime') &&
                                            <>
                                                <div className="col-7">
                                                    <label className="form-label">Schedule time</label>
                                                    <div className="row">
                                                        <div className="col-6">
                                                            <Controller
                                                                control={control}
                                                                name="startTime"
                                                                rules={{ required: true }}
                                                                render={(props) => {
                                                                    return (
                                                                        <KeyboardTimePicker
                                                                            margin="none"
                                                                            id="time-picker-start"
                                                                            value={props.value}
                                                                            onChange={(time) => { props.onChange(time) }}
                                                                            KeyboardButtonProps={{
                                                                                'aria-label': 'change start time',
                                                                            }}
                                                                        />
                                                                    )
                                                                }}
                                                            ></Controller>
                                                            {
                                                                (errors && errors.startTime && errors.startTime.type === 'required') &&
                                                                <div className="is-invalid">Please start time</div>
                                                            }
                                                        </div>
                                                        <div className="col-6">
                                                            <Controller
                                                                control={control}
                                                                name="endTime"
                                                                rules={{ required: true }}
                                                                render={(props) => {
                                                                    return (
                                                                        <KeyboardTimePicker
                                                                            margin="none"
                                                                            id="time-picker-end"
                                                                            value={props.value}
                                                                            onChange={(time) => { props.onChange(time) }}
                                                                            KeyboardButtonProps={{
                                                                                'aria-label': 'change end time',
                                                                            }}
                                                                        />
                                                                    )
                                                                }}
                                                            ></Controller>
                                                            {
                                                                (errors && errors.endTime && errors.endTime.type === 'required') &&
                                                                <div className="is-invalid">Please end time</div>
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                            </>
                                        }
                                        {
                                            (errors && errors.scheduleDate && errors.scheduleDate.type === 'custom') &&
                                            <div className="is-invalid">{errors.scheduleDate.message}</div>
                                        }
                                        <input type="hidden" name="globalError" ref={register} />
                                        {
                                            (errors && errors.globalError && errors.globalError.type === 'custom') &&
                                            <div className="is-invalid">{errors.globalError.message}</div>
                                        }
                                    </div>
                                </>
                            }
                            {/* Table html start*/}
                            {
                                watch('appointmentfor') !== 'booktimeslot' &&
                                <div className="proceedure-code-table">
                                    <div className="row">
                                        <div className="col-11">
                                            <div className="row">
                                                <div className="col-6">
                                                    <label className="form-label">Category</label>
                                                </div>
                                                <div className="col-4">
                                                    <label className="form-label">Code</label>
                                                </div>
                                                <div className="col-2">
                                                    <label className="form-label">Amount</label>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-1">
                                            <div className="row">
                                                <div className="col-12">
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    {
                                        proceedureCodeTable && proceedureCodeTable.map((item: any, index: number) => {
                                            const handleEdit = () => {
                                                setSelectedIndex(index);
                                                setCategory(item.category);
                                                setProceedureCode(item.code);
                                                setAmount(item.amount);
                                                fetchCategoryCode(item.category);
                                            }
                                            const handleDelete = () => {
                                                setProceedureCodeTable((currentState: any) => currentState.filter((_item: any, _index: number) => {
                                                    return index !== _index;
                                                }))
                                            }
                                            if (selectedIndex === index) {
                                                const handleEditRow = () => {
                                                    if (category === '') {
                                                        toast.error("Please select category");
                                                        return;
                                                    } else if (proceedureCode === '') {
                                                        toast.error("Please select proceedure code");
                                                        return;
                                                    } else if (amount === '') {
                                                        toast.error("Please enter amount");
                                                        return;
                                                    } else if (isNaN(Number(amount))) {
                                                        toast.error("Please enter valid amount");
                                                        return;
                                                    }
                                                    setProceedureCodeTable((currentState: any) => currentState.map((_item: any, _index: number) => {
                                                        if (_index === selectedIndex) {
                                                            return {
                                                                ..._item,
                                                                category: category,
                                                                code: proceedureCode,
                                                                amount: amount
                                                            }
                                                        }
                                                        return _item;
                                                    }));
                                                    setSelectedIndex(-1);
                                                    setCategory('');
                                                    setProceedureCode('');
                                                    setAmount('');
                                                }
                                                return (
                                                    <div className="row">
                                                        <div className="col-11">
                                                            <div className="row">
                                                                <div className="col-6">
                                                                    <select className='w-100 form-select' value={category} onChange={(e) => {
                                                                        setCategory(e.target.value);
                                                                        fetchCategoryCode(e.target.value);
                                                                    }}>
                                                                        <option value="" disabled>Select Category</option>
                                                                        {
                                                                            appointmentCategoryList.map((item: any, index: number) => {
                                                                                return (<option value={item.procedure_code_category}>{item.procedure_code_category}</option>)
                                                                            })
                                                                        }
                                                                    </select>
                                                                </div>
                                                                <div className="col-4">
                                                                    <select className='w-100 form-select' value={proceedureCode} onChange={(e) => {
                                                                        setProceedureCode(e.target.value);
                                                                        const code: any = categoryCodeList.find((_item: any) => _item.procedure_code_sikkaId === e.target.value);
                                                                        setAmount(code?.procedure_code_price ?? 0);
                                                                    }}>
                                                                        <option value="" disabled>Select Code</option>
                                                                        {
                                                                            categoryCodeList.map((item: any, index: number) => {
                                                                                return (<option value={item.procedure_code_sikkaId}>{item.procedure_code_sikkaId}</option>)
                                                                            })
                                                                        }
                                                                    </select>
                                                                </div>
                                                                <div className="col-2">
                                                                    <input className='w-100 form-control' value={amount} onChange={(e) => {
                                                                        setAmount(e.target.value);
                                                                    }}></input>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-1">
                                                            <div className="row">
                                                                <div className="col-12 text-end">
                                                                    <button className="btn btn-sm" onClick={handleEditRow}><i className="bi bi-plus-square"></i></button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                            }
                                            return (
                                                <div className="proceedure-code-data-row border-bottom mb-3">
                                                    <div className="row align-items-center">
                                                        <div className="col-11">
                                                            <div className="row align-items-center">
                                                                <div className="col-6">
                                                                    <div>{item.category}</div>
                                                                </div>
                                                                <div className="col-4">
                                                                    <div>{item.code}</div>
                                                                </div>
                                                                <div className="col-2">
                                                                    <div>{item.amount}</div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-1">
                                                            <div className="row">
                                                                <div className="col-12 text-end">
                                                                    {
                                                                        selectedIndex !== index &&
                                                                        <>
                                                                            <button className='btn btn-sm' onClick={handleEdit}><i className="bi bi-pencil"></i></button>
                                                                            <button className='btn btn-sm' onClick={handleDelete}><i className="bi bi-trash"></i></button>
                                                                        </>
                                                                    }
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        })
                                    }
                                    {
                                        selectedIndex === -1 &&
                                        <div className="row">
                                            <div className="col-11">
                                                <div className="row">
                                                    <div className="col-6">
                                                        <select className='w-100 form-select' value={category} onChange={(e) => {
                                                            setCategory(e.target.value);
                                                            fetchCategoryCode(e.target.value);
                                                        }}>
                                                            <option value="" disabled>Select Category</option>
                                                            {
                                                                appointmentCategoryList.map((item: any, index: number) => {
                                                                    return (<option value={item.procedure_code_category}>{item.procedure_code_category}</option>)
                                                                })
                                                            }
                                                        </select>
                                                    </div>
                                                    <div className="col-4">
                                                        <select className='w-100 form-select' value={proceedureCode} onChange={(e) => {
                                                            setProceedureCode(e.target.value);
                                                            const code: any = categoryCodeList.find((_item: any) => _item.procedure_code_sikkaId === e.target.value);
                                                            setAmount(code?.procedure_code_price ?? 0);
                                                        }}>
                                                            <option value="" disabled>Select Code</option>
                                                            {
                                                                categoryCodeList.map((item: any, index: number) => {
                                                                    return (<option value={item.procedure_code_sikkaId}>{item.procedure_code_sikkaId}</option>)
                                                                })
                                                            }
                                                        </select>
                                                    </div>
                                                    <div className="col-2">
                                                        <input className='w-100 form-control' value={amount} onChange={(e) => {
                                                            setAmount(e.target.value);
                                                        }}></input>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-1">
                                                <div className="row">
                                                    <div className="col-12 text-end">
                                                        <button className="btn btn-sm" onClick={handleAdd}><i className="bi bi-plus-square"></i></button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    }
                                </div>
                            }
                            {/* Table html end*/}
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        {
                            watch('appointmentfor') === 'booktimeslot' &&
                            <>
                                <button disabled={loadingBlockTime} className="btn btn-outline-primary" type="button" onClick={() => { hideModal(); }}>Cancel</button>
                                <button className="btn btn-primary" type="button" disabled={loadingBlockTime} onClick={handleSubmit(handleBlockTime)}>
                                    {
                                        loadingBlockTime &&
                                        <span className="spinner-border spinner-border-sm me-1" role="status" aria-hidden="true"></span>
                                    }
                                    Block
                                </button>
                            </>
                        }
                        {
                            (watch('appointmentfor') === 'yes') &&
                            <>
                                <button className="btn btn-outline-primary" type="submit" disabled={loadingWaitlist || !watch('ptsearchradio')}
                                    onClick={handleSubmit(handleWaitListClick)}>
                                    {
                                        loadingWaitlist &&
                                        <span className="spinner-border spinner-border-sm me-1" role="status" aria-hidden="true"></span>
                                    }
                                    Add to wait list
                                </button>
                                <button className="btn btn-primary" type="submit" disabled={loadingScheduled || !watch('ptsearchradio')} onClick={handleSubmit(handleScheduleClick)}>
                                    {
                                        loadingScheduled &&
                                        <span className="spinner-border spinner-border-sm me-1" role="status" aria-hidden="true"></span>
                                    }
                                    Schedule
                                </button>
                            </>
                        }
                        {
                            (watch('appointmentfor') === 'no') &&
                            <>
                                <button className="btn btn-outline-primary" type="submit" disabled={loadingWaitlist} onClick={handleSubmit(handleWaitListNew)}>
                                    {
                                        loadingWaitlist &&
                                        <span className="spinner-border spinner-border-sm me-1" role="status" aria-hidden="true"></span>
                                    }
                                    Add to wait list
                                </button>
                                <button className="btn btn-primary" type="submit" disabled={loadingScheduled} onClick={handleSubmit(handleScheduleNew)}>
                                    {
                                        loadingScheduled &&
                                        <span className="spinner-border spinner-border-sm me-1" role="status" aria-hidden="true"></span>
                                    }
                                    Schedule
                                </button>
                            </>
                        }
                    </Modal.Footer>
                </form>
            </Modal>
            {
                showGurantorInfoModal &&
                <GuarantorInfoModal hideModal={() => {
                    setShowGuarantorInfoModal(false);
                }}
                    patientType={watch('patienttype')}
                    selfguarantor={watch('selfguarantor')}
                    secondaryguarantor={watch('secondaryguarantor')}
                    primaryguarantor={watch('primaryguarantor')}
                    submit={(data: any) => {
                        if (getValues('patienttype') === 'dependent') {
                            setGuarantorList(data);
                        } else if (getValues('patienttype') === 'individual') {
                            setGuarantorList(data);
                        }
                        setShowGuarantorInfoModal(false);
                    }}
                    masterInsuranceList={masterInsuranceList}
                    selectedGuarantorList={guarantorList}
                ></GuarantorInfoModal>
            }
        </>
    );
};

