// import './_practiceSteps.scss';
import React, { useEffect, useState } from 'react';
import { fetchLoadingPending, fetchLoadingSuccess } from '../../../store/loadingIndicator/actions';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { createStep, getServiceList } from '../../services/providerOnboard';
import { IAppState } from '../../../store';
export function ProviderApp2(props: any) {

	const dispatch = useDispatch();
	const [services, setServices]: any = useState([
	]);
	const userData = useSelector((state: IAppState) => state.userData.userDataResponse, shallowEqual);
	const onNext = () => {
		const selectedServices = services.filter((item: any) => item.isChecked === true)

		const request = {
			uniqueid: props.request.clinicUniqueId,
			providerId: props.request.providerId,
			services: selectedServices.map((service: any) => {
				return service.id
			}).toString()
		};

		dispatch(fetchLoadingPending());
		createStep({
			stepNumber: 2,
			formData: request
		}).then((success) => {
			dispatch(fetchLoadingSuccess(false));
			if (success.status.error === false) {
				props.nextStep(2, { selectedServices: selectedServices });
				let isPmsTaken = userData.accessEnabled.pms_integration;
				if (isPmsTaken) {
					props.setPercentage(32);
				} else {
					props.setPercentage(40);
				}
			}
		}).catch((err) => {
			dispatch(fetchLoadingSuccess(false));
		});


	}

	useEffect(() => {
		if (props.request.personalInfo && props.request.personalInfo.speciality) {
			dispatch(fetchLoadingPending());
			getServiceList(props.request.personalInfo.speciality).then((success: any) => {
				dispatch(fetchLoadingSuccess(false))

				if (success.status.error === false) {
					if (props.request.selectedServices) {
						setServices(success.services.map((item: any) => {
							if (props.data.find((pitem: any) => {
								return pitem.id === item.id
							}) !== undefined) {
								return {
									id: item.id,
									value: item.name,
									isChecked: true
								};
							}
							return {
								id: item.id,
								value: item.name,
								isChecked: false
							};
						}))
					} else {
						setServices(success.services.map((item: any) => {
							return {
								id: item.id,
								value: item.name,
								isChecked: false
							}
						}))
					}
				}
			}).catch((err: any) => {
				dispatch(fetchLoadingSuccess(false));
			});
		}
	}, [props.request]);

	useEffect(() => {

	}, [props.data])
	return (
		<>
			<div className="row">
				<div className="col-12">
					<h2 className="mb-3">Services</h2>
					<div className="fw-medium fs-5">Which services are offered by {props.request.personalInfo ? props.request.personalInfo.fullname : null}</div>
					<p className="mt-1">Please set the services offered</p>
					<hr className="my-4" />
				</div>
			</div>
			<div className="row">
				<div className="col-12">
					<div className="features-check-list custom-check-list d-flex flex-wrap">
						{services.map((item: any) => {
							return (
								<div className="form-check">
									<input className="form-check-input" type="checkbox" checked={item.isChecked} value={item.id} id={item.value}
										onChange={(e) => {
											setServices(services.map((cItem: any) => {
												if (cItem.id === item.id) {
													return {
														...cItem,
														isChecked: !cItem.isChecked
													}
												}
												return cItem;
											}))
										}}
									/>
									<label className="form-check-label rounded-2 border w-100" htmlFor={item.value}>
										{item.value}
									</label>
								</div>
							)
						})
						}
					</div>
				</div>
			</div>
			<div className="row">
				<div className="col-12 my-4">
					<div className="bottom-btn-sec d-flex justify-content-between">
						<button className="btn border me-1" onClick={() => { props.onPrev(0) }}>Back</button>
						<button className="btn btn-primary ms-1" onClick={() => { onNext() }} disabled={services.filter((item: any) => item.isChecked === true).length === 0}>Next</button>
					</div>
				</div>
			</div>
		</>
	);
}
