import React, { useEffect, useMemo, useRef, useState } from 'react';
import moment from 'moment';
import {
  getNotificationTemplateListFollowup, updateActiveStatusEmergencyTemplate, updateActiveStatusNotificationTemplateFollowup,
  deleteNotificationTemplateFollowup,
  getEmailTemplateList
} from '../../services/messageCenter';
import { useDispatch } from 'react-redux';
import { fetchLoadingPending, fetchLoadingSuccess } from '../../../store/loadingIndicator/actions';
import { confirmAlert } from 'react-confirm-alert';
import { toast } from 'react-toastify';
import { NotificationCreateRepeatedFollowup } from './NotificationCreateRepeatedFollowup';
import AddBtnIcon from '../../assets/images/add-btn-icon.svg';
import NotificationIcon from '../../assets/images/notification-img.svg';

export const AppointmentRowFollowup = (props: any) => {
  const notificationCreateRepeatedRef: any = useRef(null);
  const [templateList, setTemplateList] = useState([]);
  const dispatch = useDispatch();
  useEffect(() => {
    getNotificationTemplateListFunc();
  }, []);
  console.log(props.emailTemplateList);
  const getNotificationTemplateListFunc = () => {
    dispatch(fetchLoadingPending())
    getNotificationTemplateListFollowup({ clinicid: props.clinicId, notificationtemplatetype_id: props.id }).then((success) => {
      dispatch(fetchLoadingSuccess(false));
      if (success && success.error === false && success.code === 200) {
        setTemplateList(success.data);
      }
    }).catch((err) => {
      dispatch(fetchLoadingSuccess(false));
      console.log(err);
    })
  };
  const changeStatus = (status: boolean, id: any) => {
    confirmAlert({
      title: '',
      message: 'Please confirm, if you want to ' + (status === true ? 'enable' : 'disable') + ' this configuration.',
      buttons: [
        {
          label: 'Yes',
          onClick: () => {
            console.log(props.id, props.isRepeated);
            if (!props.isRepeated && props.id === 3) {
              updateActiveStatusEmergencyTemplate({
                clinicid: props.clinicId, notificationtemplatetype_id: props.id,
                messagecenterfollowuptemplate_id: id, isactive: status
              }).then((success) => {
                if (success && success.error === false && success.code === 200) {
                  getNotificationTemplateListFunc();
                }
              }).catch((err) => {
              })
              return;
            }
            updateActiveStatusNotificationTemplateFollowup({
              clinicid: props.clinicId,
              messagecenterfollowuptemplate_id: id, isactive: status
            }).then((success) => {
              if (success && success.error === false && success.code === 200) {
                getNotificationTemplateListFunc();
              }
            }).catch((err) => {
            })
          }
        },
        {
          label: 'No',
          onClick: () => {

          }
        }
      ]
    });
  };
  const onRowClick = (item: any) => {
    notificationCreateRepeatedRef.current.showModal({
      ...props,
      item: item,
      clinicId: props.clinicId,
      getNotificationTemplateList: getNotificationTemplateListFunc,
      emailTemplateList: props.emailTemplateList 
    });
  }

  const handleOnDeleteClick = (id: any) => {
    confirmAlert({
      title: '',
      message: 'Are you sure you want to delete ?',
      buttons: [
        {
          label: 'Yes',
          onClick: () => {
            dispatch(fetchLoadingPending())
            deleteNotificationTemplateFollowup({
              clinicid: props.clinicId,
              messagecenterfollowuptemplate_id: id
            }).then((success) => {
              dispatch(fetchLoadingSuccess(false))
              if (success && success.error === false && success.code === 200) {
                getNotificationTemplateListFunc();
              }
            }).catch((err) => {
              dispatch(fetchLoadingSuccess(false))
              toast.error('Delete notification template error.')
            })
          }
        },
        {
          label: 'No',
          onClick: () => {

          }
        }
      ]
    });
  }
  return (
    <>
      <section className="automated-reminders" id="automatedReminders" key={props.id}>
        <div className="row mx-0">
          <div className="col-12">
            <div className="reminder-box">
              <div className="d-flex justify-content-between">
                <div className="me-3">
                  <div className="reminder-title">{props.title}</div>
                  <div className="reminder-desc">
                    {
                      props.index === 0 &&
                      <>Setup automated follow up messages</>
                    }
                    {
                      props.index === 1 &&
                      <>Set up automated emergency notifications</>
                    }
                  </div>
                </div>
                <div className="align-self-center">
                  <button className="btn btn-primary" onClick={() => {
                    console.log("NotificationRef", notificationCreateRepeatedRef.current)
                    notificationCreateRepeatedRef.current.showModal({ ...props, clinicId: props.clinicId, getNotificationTemplateList: getNotificationTemplateListFunc,
                      emailTemplateList: props.emailTemplateList });
                  }}><img src={AddBtnIcon} className="me-2" /> Create notification</button>
                </div>
              </div>
              {/* <div className="my-4 border px-4 py-3 border-1 rounded d-flex warning-box">
                <img src={require("../../assets/images/report_problem-icon.svg")} className="align-self-center" />
                <div className="ms-4">
                  <div>Create Scheduled appointment with custom text</div>
                  <small>eg. if there is a storm and the clinic is closed then you can notify your patient.</small>
                </div>
              </div> */}
              {
                useMemo(() => {
                  return (
                    templateList.map((item: any, index: number) => {
                      return (
                        <div className="my-4 border px-3 py-3 border-1 rounded d-flex custom-noti-box justify-content-between"
                          key={item.messagecenterfollowuptemplate_id} >
                          <div className="d-flex">
                            <div className="noti-title d-flex" >
                              <img src={NotificationIcon} className="me-2 align-self-center" />
                              <span className="fw-bold align-self-center ">{item.notificationname}</span>
                            </div>
                            <div className="noti-time">
                              <small className="text-muted">Time</small>
                              {
                                props.isRepeated &&
                                <div className="fw-bold">{"08:00 AM"}</div>
                              }
                              {
                                !props.isRepeated &&
                                <div className="fw-bold">immediate</div>
                              }
                            </div>
                            <div className="noti-reminder">
                              <small className="text-muted">Reminder</small>
                              {
                                props.isRepeated &&
                                <div className="fw-bold">
                                  {
                                    item.scheduledays.split(",").find((item: any) => item === "0") &&
                                    <>same day</>
                                  }
                                  {(item.scheduledays.split(",").filter((item: any) => item !== "0").length > 0 && 
                                    item.scheduledays.split(",").find((item: any) => item === "0")) && ', '}
                                  {
                                    item.scheduledays.split(",").filter((item: any) => item !== "0").map((sitem: any, index: number) => (
                                      <>
                                        {/* {(item.scheduledays.split(",").find((item: any) => item === "0")) && ', '} */}
                                        {/* {index !== 0 && ','} */}
                                        {sitem !== '' && sitem + (index + 1 !== item.scheduledays.split(",").filter((item: any) => item !== "0")?.length ? ',' : '')}
                                      </>
                                    ))
                                  }
                                  {/* {
                                    item.scheduledays.split(",").filter((item: any) => item !== "0").length > 0 && ' Day'
                                  } */}
                                </div>
                              }
                              {
                                !props.isRepeated &&
                                <div className="fw-bold">
                                  immediate
                                </div>
                              }
                            </div>
                            <div className="noti-type">
                              <small className="text-muted">Type</small>
                              <div className="fw-bold text-capitalize">{item.notification_type ?? ""}</div>
                            </div>
                            <div className="noti-createdon">
                              <small className="text-muted">CreatedOn</small>
                              <div className="fw-bold">{moment(item.created_at).format("MMM DD, YYYY - LT")}</div>
                            </div>
                          </div>
                          <div className="noti-btns d-flex ">
                            <div className="form-check form-switch d-inline-block align-self-center">
                              <input className="form-check-input" type="checkbox" id="flexSwitchCheckDefault" checked={item.isactive} onChange={() => { changeStatus(!item.isactive, item.messagecenterfollowuptemplate_id) }} />
                            </div>
                          </div>
                          <div className="noti-edit d-flex align-self-center btn-link">
                            <div onClick={() => {
                              onRowClick(item);
                            }}>Edit</div>
                            <button className="btn p-0 btn-link text-secondary" onClick={() => {
                              handleOnDeleteClick(item.messagecenterfollowuptemplate_id)
                            }}><i className="ms-2 bi bi-x-circle-fill text-danger"></i></button>
                          </div>
                        </div>
                      )
                    })
                  )
                }, [templateList, props.emailTemplateList])
              }
            </div>
          </div>
        </div>
        <NotificationCreateRepeatedFollowup ref={notificationCreateRepeatedRef}></NotificationCreateRepeatedFollowup>
      </section>
    </>
  )
}