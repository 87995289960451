import { BODY_TYPE, CommonApiOptions, METHOD_TYPE } from "../constants/api"
import { commonApi } from "../helpers/api"
import config from '../configs/apiConfigs';
import { attachHeader } from "../utils/apiHandler";
import { getCookie } from "../utils/cookies";

export const getDomainList = () => {
  const request: CommonApiOptions = {
    url: config.API_URL + 'master/domain/',
    method: METHOD_TYPE.GET,
    apiOptions: {
      headers: attachHeader(true),
      withCredentials: true
    }
  };
  return commonApi(request);
};

export const createStep = (data: any) => {
  const login: any = getCookie();
  const request: CommonApiOptions = {
    url: config.API_URL + 'clinic/' + login.xpr_user_id + '/create/step-' + data.stepNumber + '/',
    method: METHOD_TYPE.POST,
    body: {
      type: BODY_TYPE.FORM_DATA,
      data: data.formData
    },
    apiOptions: {
      headers: attachHeader(true),
      withCredentials: true
    }
  };
  return commonApi(request);
};

export const getQuestionsList = () => {
  const request: CommonApiOptions = {
    url: config.API_URL + 'questions/',
    method: METHOD_TYPE.POST,
    body: {
      type: BODY_TYPE.RAW,
      data: {
        modelType: "clinic"
      }
    },
    apiOptions: {
      headers: attachHeader(true),
      withCredentials: true
    }
  };
  return commonApi(request);
};

export const getSpeciality = (domainId: any) => {
  const request: CommonApiOptions = {
    url: config.API_URL + 'master/speciality/' + domainId + '/',
    method: METHOD_TYPE.GET,
    apiOptions: {
      headers: attachHeader(true),
      withCredentials: true
    }
  };
  return commonApi(request);
};

export const getHygiene = () => {
  const request: CommonApiOptions = {
    url: config.API_URL + 'master/hygiene/',
    method: METHOD_TYPE.GET,
    apiOptions: {
      headers: attachHeader(true),
      withCredentials: true
    }
  };
  return commonApi(request);
};

export const getFeatures = (speciality: any) => {
  const request: CommonApiOptions = {
    url: config.API_URL + 'master/features/',
    method: METHOD_TYPE.POST,
    body: {
      type: BODY_TYPE.RAW,
      data: {
        speciality: speciality
      }
    },
    apiOptions: {
      headers: attachHeader(true),
      withCredentials: true
    }
  };
  return commonApi(request);
};

export const getInsuranceList = () => {
  const request: CommonApiOptions = {
    url: config.API_URL + 'master/insurance/',
    method: METHOD_TYPE.GET,
    apiOptions: {
      headers: attachHeader(true),
      withCredentials: true
    }
  };
  return commonApi(request);
};

export const getSocialAssistanceList = () => {
  const request: CommonApiOptions = {
    url: config.API_URL + 'master/social-assistance/',
    method: METHOD_TYPE.GET,
    apiOptions: {
      headers: attachHeader(true),
      withCredentials: true
    }
  };
  return commonApi(request);
};

export const deletePhoto = (photoId: any, clinicUniqueId: any) => {
  const request: CommonApiOptions = {
    url: config.API_URL + 'clinic/' + clinicUniqueId + '/remove/photo/',
    method: METHOD_TYPE.POST,
    body: {
      type: BODY_TYPE.RAW,
      data: {
        id: photoId
      }
    },
    apiOptions: {
      headers: attachHeader(true),
      withCredentials: true
    }
  };
  return commonApi(request);
};

export const addStripeCard = (data: any) => {
  const request: CommonApiOptions = {
    url: config.API_URL + 'user/card/',
    method: METHOD_TYPE.POST,
    body: {
      type: BODY_TYPE.FORM_DATA,
      data: data
    },
    apiOptions: {
      headers: attachHeader(true),
      withCredentials: true
    }
  };
  return commonApi(request);
};

export const checkPracticeName = (name: string) => {
  const request: CommonApiOptions = {
    url: config.API_URL + "clinic/valid/",
    method: METHOD_TYPE.POST,
    body: {
      type: BODY_TYPE.RAW,
      data: {
        name: name
      }
    },
    apiOptions: {
      headers: attachHeader(true),
      withCredentials: true
    }
  };
  return commonApi(request);
};

export const checkPracticeEmail = (email: string) => {
  const request: CommonApiOptions = {
    url: config.API_URL + "clinic/valid/",
    method: METHOD_TYPE.POST,
    body: {
      type: BODY_TYPE.RAW,
      data: {
        email: email
      }
    },
    apiOptions: {
      headers: attachHeader(true),
      withCredentials: true
    }
  };
  return commonApi(request);
};

export const checkPracticePhone = (phone: string) => {
  const request: CommonApiOptions = {
    url: config.API_URL + "clinic/valid/",
    method: METHOD_TYPE.POST,
    body: {
      type: BODY_TYPE.RAW,
      data: {
        phone: phone
      }
    },
    apiOptions: {
      headers: attachHeader(true),
      withCredentials: true
    }
  };
  return commonApi(request);
};

export const getAppointmentTypes = (data: any) => {
  const request: CommonApiOptions = {
    url: config.API_URL + 'master/appointmenttype/',
    method: METHOD_TYPE.POST,
    body: {
      type: BODY_TYPE.RAW,
      data: data
    },
    apiOptions: {
      headers: attachHeader(true),
      withCredentials: true
    }
  };
  return commonApi(request);
};