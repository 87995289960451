// import './_practiceSteps.scss';
import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import { fetchLoadingPending, fetchLoadingSuccess } from '../../../store/loadingIndicator/actions';
import { createStep, getPMSProviderList, getSpecialityList } from '../../services/providerOnboard';
import { useDispatch } from 'react-redux';
import AddBtnIcon from "../../assets/images/add-btn-icon.svg";

export function ProviderApp4(props: any) {
	const dispatch = useDispatch();

	const [providerspecilityList, setproviderspecilityList]: any = useState([]);
	const [pMSProviderList, setpMSProviderList]: any = useState([]);
	const [selectedMapping, setSelectedMapping]: any = useState([]);
	const [selectedProvider, setSelectedProvider]: any = useState({});
	const [selectedPMSProvider, setSelectedPMSProvider]: any = useState({});

	const onAdd = () => {
		const request = {
			providerName: selectedProvider,
			pMSProvider: selectedPMSProvider
		}

		setSelectedMapping(
			[...selectedMapping, request]
		);
		setSelectedProvider({});
		setSelectedPMSProvider({});
	};

	useEffect(() => {
		if (props.request && props.request.selectedMapping) {
			setSelectedMapping(props.request.selectedMapping);
		}
	}, [props.request]);

	useEffect(() => {
		if (props.request.personalInfo && props.request.personalInfo.speciality) {
			dispatch(fetchLoadingPending());
			getSpecialityList(props.clinicId).then((success) => {
				dispatch(fetchLoadingSuccess(false))

				if (success.status.error === false) {
					setproviderspecilityList(success.speciality.filter((item: any) => {
						if (props.request.personalInfo.speciality.split(',').find((sitem: any) => {
							return sitem === item.id
						}) !== undefined) {
							return true;
						}
						return false;
					}).map((fitem: any) => {
						return {
							value: fitem.id,
							label: fitem.name
						}
					}));
				}
			}).catch((err) => {
				dispatch(fetchLoadingSuccess(false));
			});

			dispatch(fetchLoadingPending());
			getPMSProviderList(props.clinicId).then((success: any) => {
				dispatch(fetchLoadingSuccess(false))
				if (success.status.error === false) {
					setpMSProviderList(success.pmsProviders.map((item: any) => {
						return {
							value: item.sikkaId,
							label: item.fname + ' ' + item.lname + ' - ' + item.sikkaId
						}
					}))
				}
			}).catch((err: any) => {
				dispatch(fetchLoadingSuccess(false));
			});

		}
	}, [props.request]);
	const onNext = () => {
		const requestData = {
			providerId: props.request.providerId,
			uniqueid: props.request.clinicUniqueId,
			pmsProviders: JSON.stringify(selectedMapping.map((item: any) => {
				return {
					specialityId: item.providerName.value,
					sikkaId: item.pMSProvider.value
				}
			}))
		};

		dispatch(fetchLoadingPending());
		createStep({
			stepNumber: 4,
			formData: requestData
		}).then((success) => {
			dispatch(fetchLoadingSuccess(false));
			if (success.status && success.status.error === false) {
				props.nextStep(4, { selectedMapping: selectedMapping });
				props.setPercentage(64);
			}
		}).catch((err) => {
			dispatch(fetchLoadingSuccess(false));
		});
	};
	const removeItem = (index: number) => {
		setSelectedMapping(selectedMapping.filter((item: any, iIndex: number) => iIndex !== index));
	}
	console.log(selectedProvider, providerspecilityList)
	return (
		<>
			<div className="row">
				<div className="col-12">
					<h2 className="mb-3">Patient Management System Mapping</h2>
					<div className="fw-medium fs-5">Map provider speciality with PMS ID</div>
					<p className="mt-1">Please connect your provider with their Patient Management System ID to sync appointment and patient details.</p>
					<hr className="my-4" />
				</div>
			</div>
			<div className="row">
				<div className="col-12">
					<div className="insurance-list d-flex flex-wrap mb-4">
						{/* <div className="badge border rounded-pill">Walter Heidary - Dentist 14<button className="btn py-0 pe-0"><img src={require("../../assets/images/remove-blue.svg")} /></button></div>
						<div className="badge border rounded-pill">Walter Heidary - Orthodontist 12<button className="btn py-0 pe-0"><img src={require("./images/remove-blue.svg")} /></button></div> */}
						{selectedMapping.map((item: any, index: number) => {
							return (
								<div className="badge text-dark border rounded-pill me-2 ps-3">{props.request.personalInfo ? props.request.personalInfo.fullname + '-' + item.providerName.label + ' ' + item.pMSProvider.value : null}
									<button className="btn py-0 pe-0" onClick={(e) => {
										removeItem(index);
									}}><i className="bi bi-x"></i></button></div>
							)
						})}

					</div>
				</div>
				<div className="col-12">
					<div className="d-flex add-pms">
						<div className="input-box">
							<label className="form-label">Provider speciality</label>
							<div className="">
								<Select className="select2 myslt" options={providerspecilityList.filter((item: any) => {
									return selectedMapping.find((sItem: any) => {
										return item.value == sItem.providerName.value
									}) === undefined
								})}
									value={selectedProvider}
									onChange={(e: any) => { setSelectedProvider(e) }}
								>
								</Select>
							</div>
						</div>
						<div className="pb-2 mx-3 align-self-end"><i className="bi bi-arrow-left-right"></i></div>
						<div className="input-box">
							<label className="form-label">PMS provider list </label>
							<div className="">
								<Select className="select2 myslt" options={pMSProviderList}
									value={selectedPMSProvider}
									onChange={(e: any) => { setSelectedPMSProvider(e) }}
								>
								</Select>
							</div>
							{/* <small className="text-muted position-absolute mt-1">This data is pulled from our insurance database.</small> */}
						</div>
						<div className="align-self-end">
							<button className="btn btn-primary fw-normal ms-3 rounded align-self-center" type="button" onClick={() => { onAdd() }} disabled={!((selectedProvider && selectedProvider.value) && (selectedPMSProvider && selectedPMSProvider.value))}><img src={AddBtnIcon} className="" /> Add</button>
						</div>
					</div>

				</div>
			</div>
			<div className="row">
				<div className="col-12 my-4">
					<div className="bottom-btn-sec d-flex justify-content-between">
						<button className="btn border me-1" onClick={() => { props.onPrev(2) }}>Back</button>
						<button className="btn btn-primary ms-1" onClick={() => { onNext() }}>Next</button>
					</div>
				</div>
			</div>
		</>
	);
}
