import React, { useEffect, useState } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { useHistory, useLocation, useRouteMatch } from 'react-router';
import ScheduleCalendarAppointmentModal from '../../../components/mySchedule/ScheduleCalendarAppointmentModal';
import { CHAT } from '../../../constants';
import { IAppState } from '../../../store';
import configs from '../../configs/apiConfigs';
import firebaseInstance from '../../helpers/firebase';
import { getAppointments } from '../../services/appointment';
import { CreateAppointmentModal } from '../../components/mySchedule/CreateAppointmentModal';
import moment from 'moment';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { NotesModal } from './NotesModal';
let subscriptionToAppointmentChange: any;
export const MyScheduleHeader = ({ onFetchAppointment }: any) => {
    const [waitlistCount, setWaitlistCount] = useState(0);
    const [emergencyCount, setEmergencyCount] = useState(0);
    const [requestedCount, setRequestedCount] = useState(0);
    const [isFull, setFull] = useState(false);
    const userData = useSelector((state: IAppState) => state.userData.userDataResponse, shallowEqual);
    const waitlistCallApiCount = useSelector((state: IAppState) => state.appointment.callApiCount, shallowEqual);
    const emergencyCallApiCount = useSelector((state: IAppState) => state.appointment.callEmergencyCount, shallowEqual);
    const location = useLocation();
    const history = useHistory();
    const match = useRouteMatch();
    const [showScheduleCalendarModal, setShowScheduleCalendarModal] = useState(false);
    const [date, setDate] = useState(new Date());
    const [showNotes, setShowNotes] = useState(false);
    useEffect(() => {
        listenWaitlistChange();
    }, []);
    useEffect(() => {
        if (waitlistCallApiCount > 0) {
            fecthAppointmentListAsync(true);
            fecthWaitlistAppointmentListAsync(true);
        }
    }, [waitlistCallApiCount]);
    useEffect(() => {
        if (emergencyCallApiCount > 0) {
            fetchEmergencyAppointmentListAsync(true);
        }
    }, [emergencyCallApiCount]);
    const fecthAppointmentListAsync = async (realTime = false) => {
        try {
            let modelType = 'clinic';
            let Id = userData.clinics[0].uniqueId;
            const request = {
                appointmentType: 'requests',
                modelType: modelType,
                page: 1,
                results: 30,
                Id: Id
            };
            const response = await getAppointments(request);
            if (response && response.status && response.status.error === false) {
                setRequestedCount(response.total);
            }
        } catch (err) {
            console.log(err);
        }
    }
    const fecthWaitlistAppointmentListAsync = async (realTime = false) => {
        try {
            let modelType = 'clinic';
            let Id = userData.clinics[0].uniqueId;
            const request = {
                appointmentType: 'waitlist',
                modelType: modelType,
                page: 1,
                results: 30,
                Id: Id
            };
            const response = await getAppointments(request);
            if (response && response.status && response.status.error === false) {
                setWaitlistCount(response.total);
            }
        } catch (err) {
            console.log(err);
        }
    }
    const fetchEmergencyAppointmentListAsync = async (realTime = false) => {
        try {
            let modelType = 'clinic';
            let Id = userData.clinics[0].uniqueId;
            const request = {
                appointmentType: 'emergency',
                modelType: modelType,
                page: 1,
                results: 30,
                Id: Id
            };
            const response = await getAppointments(request);
            if (response && response.status && response.status.error === false) {
                setEmergencyCount(response.total);
            }
        } catch (err) {
            console.log(err);
        }
    }
    const listenWaitlistChange = () => {
        subscriptionToAppointmentChange && subscriptionToAppointmentChange();
        subscriptionToAppointmentChange = firebaseInstance.db.collection(configs.CHAT_COLLECTION)
            .where('actionType', 'in', [CHAT.ACTION_TYPE.STATUS])
            .where('sendToCId', '==', userData.clinics[0].uniqueId)
            .onSnapshot((snapshot: any) => {
                console.log("List fetch waitlist")
                setTimeout(() => {
                    fecthAppointmentListAsync(true);
                    fetchEmergencyAppointmentListAsync(true);
                    fecthWaitlistAppointmentListAsync(true);
                }, 2000);
            });
    }
    let apptScheduled = true;
    if (userData?.accessEnabled?.pms_integration === true) {
        if (userData.clinics[0].writeBack === "off") {
            apptScheduled = false;
        } else if ((userData.clinics[0].writeBack === "on" || userData.clinics[0].tempwriteBack === "on") &&
            userData.clinics[0].appointmentwriteback === "off") {
            apptScheduled = false;
        }
    }
    return (
        <section className="middle-navbar px-30">
            <div className="p-0 container-fluid">
                <div className="d-flex justify-content-between">
                    <div>
                        <div className="nav-title-txt d-flex align-items-center">
                            Clinic Schedule
                            <div className="guideline mx-3">
                                {/* <div className="text-muted small">Color guideline</div> */}
                                <div className="colour-guideline d-flex align-items-center">
                                    <OverlayTrigger
                                        placement="auto"
                                        overlay={
                                            <Tooltip id="tooltip">
                                                Completed
                                            </Tooltip>
                                        }
                                    >
                                        <div className="cguide completed"></div>
                                    </OverlayTrigger>

                                    <OverlayTrigger
                                        placement="auto"
                                        overlay={
                                            <Tooltip id="tooltip">
                                                Scheduled
                                            </Tooltip>
                                        }
                                    >
                                        <div className="cguide scheduled"></div>
                                    </OverlayTrigger>

                                    <OverlayTrigger
                                        placement="auto"
                                        overlay={
                                            <Tooltip id="tooltip">
                                                Waitlist
                                            </Tooltip>
                                        }
                                    >
                                        <div className="cguide waitlist"></div>
                                    </OverlayTrigger>

                                    <OverlayTrigger
                                        placement="auto"
                                        overlay={
                                            <Tooltip id="tooltip">
                                                Confirmed by patient
                                            </Tooltip>
                                        }
                                    >
                                        <div className="cguide confirmed-bp"></div>
                                    </OverlayTrigger>

                                    <OverlayTrigger
                                        placement="auto"
                                        overlay={
                                            <Tooltip id="tooltip">
                                                Cancelled by patient
                                            </Tooltip>
                                        }
                                    >
                                        <div className="cguide cancelled-bp"></div>
                                    </OverlayTrigger>

                                    <OverlayTrigger
                                        placement="auto"
                                        overlay={
                                            <Tooltip id="tooltip">
                                                Pre-screening - PASS
                                            </Tooltip>
                                        }
                                    >
                                        <div className="cguide prescreening-p"></div>
                                    </OverlayTrigger>

                                    <OverlayTrigger
                                        placement="auto"
                                        overlay={
                                            <Tooltip id="tooltip">
                                                Pre-screening - FAIL
                                            </Tooltip>
                                        }
                                    >
                                        <div className="cguide prescreening-f"></div>
                                    </OverlayTrigger>
                                    <OverlayTrigger
                                        placement="auto"
                                        overlay={
                                            <Tooltip id="tooltip">
                                                Floating
                                            </Tooltip>
                                        }
                                    >
                                        <div className="cguide flot"></div>
                                    </OverlayTrigger>
                                    <OverlayTrigger
                                        placement="auto"
                                        overlay={
                                            <Tooltip id="tooltip">
                                                Checked-in
                                            </Tooltip>
                                        }
                                    >
                                        <div className="cguide chkin"></div>
                                    </OverlayTrigger>
                                    <OverlayTrigger
                                        placement="auto"
                                        overlay={
                                            <Tooltip id="tooltip">
                                                Block time
                                            </Tooltip>
                                        }
                                    >
                                        <div className="cguide blockt"></div>
                                    </OverlayTrigger>

                                </div>
                            </div>
                        </div>
                        <ul className="nav nav-tabs">
                            <li className="nav-item">
                                <a className={(location.pathname === '/myschedule/calendar') ? "active nav-link" : 'nav-link'} onClick={() => {
                                    history.push('/myschedule/calendar');
                                }}>Calendar</a>
                            </li>
                            <li className="nav-item">
                                <a className={(location.pathname === '/myschedule/upcoming') ? "active nav-link" : 'nav-link'} onClick={() => {
                                    history.push('/myschedule/upcoming');
                                }}>Upcoming</a>
                            </li>
                            <li className="nav-item">
                                <a className={(location.pathname === '/myschedule/completed') ? "active nav-link" : 'nav-link'} onClick={() => {
                                    history.push('/myschedule/completed');
                                }}>Completed</a>
                            </li>
                            <li className="nav-item">
                                <a className={(location.pathname === '/myschedule/requests') ? "active nav-link" : 'nav-link'} onClick={() => {
                                    history.push('/myschedule/requests');
                                }}>
                                    Requested
                                    {
                                        requestedCount !== 0 &&
                                        <span className="badge rounded-pill bg-waitlist ms-2">{requestedCount}</span>
                                    }
                                </a>
                            </li>
                            <li className="nav-item">
                                <a className={(location.pathname === '/myschedule/waitlist') ? "active nav-link" : 'nav-link'} onClick={() => {
                                    history.push('/myschedule/waitlist');
                                }}>Waitlist
                                    {
                                        waitlistCount !== 0 &&
                                        <span className="badge rounded-pill bg-waitlist ms-2">{waitlistCount}</span>
                                    }
                                </a>
                            </li>
                            <li className="nav-item">
                                <a className={(location.pathname === '/myschedule/cancelled') ? "active nav-link" : 'nav-link'} onClick={() => {
                                    history.push('/myschedule/cancelled');
                                }}>Cancelled</a>
                            </li>
                            <li className="nav-item">
                                <a className={(location.pathname === '/myschedule/all') ? "active nav-link" : 'nav-link'} onClick={() => {
                                    history.push('/myschedule/all');
                                }}>All</a>
                            </li>
                            <li className="nav-item">
                                <a className={(location.pathname === '/myschedule/confirmed') ? "active nav-link" : 'nav-link'} onClick={() => {
                                    history.push('/myschedule/confirmed');
                                }}>Confirmed</a>
                            </li>
                            <li className="nav-item">
                                <a className={(location.pathname === '/myschedule/reschedule') ? "active nav-link" : 'nav-link'} onClick={() => {
                                    history.push('/myschedule/reschedule');
                                }}>Reschedule</a>
                            </li>
                            <li className="nav-item">
                                <a className={(location.pathname === '/myschedule/emergency') ? "active nav-link" : 'nav-link'} onClick={() => {
                                    history.push('/myschedule/emergency');
                                }}>Emergency
                                    {
                                        emergencyCount !== 0 &&
                                        <span className="badge rounded-pill bg-waitlist ms-2">{emergencyCount}</span>
                                    }
                                </a>
                            </li>
                        </ul>
                    </div>

                    <div className="stp-action d-flex align-items-center">
                        {
                            isFull ?
                                <button className="btn btn-outline-secondary me-2" onClick={() => {
                                    document.body.classList.remove('full-screen');
                                    setFull(false);
                                }}><i className="bi bi-fullscreen-exit"></i></button>
                                :
                                <button className="btn btn-outline-secondary me-2" onClick={() => {
                                    document.body.classList.add('full-screen');
                                    setFull(true);
                                }}><i className="bi bi-arrows-fullscreen"></i></button>
                        }
                        {
                            apptScheduled &&
                            <button type="button" className="btn btn-outline-primary me-2" onClick={() => {
                                setShowScheduleCalendarModal(true);
                            }}>
                                <i className="bi bi-plus"></i><small> Appointment</small>
                            </button>
                        }

                        <button type="button" className="btn btn-outline-secondary position-relative" onClick={() => {
                            setShowNotes(true);
                        }}>
                            Notes {/* <span className="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger">99</span> */}
                        </button>



                    </div>

                </div>
            </div>
            {
                showScheduleCalendarModal &&
                <CreateAppointmentModal
                    hideModal={() => {
                        setShowScheduleCalendarModal(false);
                    }}
                    fetchAppointment={onFetchAppointment}
                ></CreateAppointmentModal>
            }
            {
                showNotes &&
                <NotesModal hideModal={() => { setShowNotes(false); }}></NotesModal>
            }
        </section>
    )
}