import {
  CreateSubscriptionPlanErrorAction,
  CreateSubscriptionPlanPendingAction,
  CreateSubscriptionPlanRequest,
  CreateSubscriptionPlanResponse,
  CreateSubscriptionPlanSuccessAction,
  FetchSubscriptionPlanErrorAction, FetchSubscriptionPlanPendingAction, FetchSubscriptionPlanRequest,
  FetchSubscriptionPlanResponse, FetchSubscriptionPlanSuccessAction, SUBSCRIPTION_PLAN, MyPlanPendingAction, MyPlanSuccessAction, MyPlanErrorAction, ClinicMyplanRequest, ClinicMyplanResponce, CancelSubscriptionRequest, CancelSubscriptionPendingAction, CancelSubscriptionErrorAction, CancelSubscriptionResponse, CancelSubscriptionSuccessAction
} from "./types";

export const fetchSubscriptionPlan = (request: FetchSubscriptionPlanRequest): FetchSubscriptionPlanPendingAction => {
  return {
    type: SUBSCRIPTION_PLAN.SUBSCRIPTION_PLAN_FETCH,
    payload: request
  };
};
export const fetchSubscriptionPlanFailure = (error: any): FetchSubscriptionPlanErrorAction => {
  return {
    type: SUBSCRIPTION_PLAN.SUBSCRIPTION_PLAN_FETCH_FAILED,
    payload: error
  };
};
export const fetchSubscriptionPlanSuccess = (response: FetchSubscriptionPlanResponse): FetchSubscriptionPlanSuccessAction => {
  return {
    type: SUBSCRIPTION_PLAN.SUBSCRIPTION_PLAN_FETCH_SUCCESS,
    payload: response
  };
};
export const createSubscriptionPlan = (request: CreateSubscriptionPlanRequest): CreateSubscriptionPlanPendingAction => {
  return {
    type: SUBSCRIPTION_PLAN.SUBSCRIPTION_PLAN_CREATE,
    payload: request
  };
};
export const createSubscriptionPlanFailure = (error: any): CreateSubscriptionPlanErrorAction => {
  return {
    type: SUBSCRIPTION_PLAN.SUBSCRIPTION_PLAN_CREATE_FAILED,
    payload: error
  };
};
export const createSubscriptionPlanSuccess = (response: CreateSubscriptionPlanResponse): CreateSubscriptionPlanSuccessAction => {
  return {
    type: SUBSCRIPTION_PLAN.SUBSCRIPTION_PLAN_CREATE_SUCCESS,
    payload: response
  };
};
export const myPlan = (request: ClinicMyplanRequest): MyPlanPendingAction => {
  return {
    type: SUBSCRIPTION_PLAN.CLINIC_MYPLAN,
    payload: request
  };
};
export const myPlanFailure = (error: any): MyPlanErrorAction => {
  return {
    type: SUBSCRIPTION_PLAN.CLINIC_MYPLAN_FAILED,
    payload: error
  };
};
export const myPlanSuccess = (response: ClinicMyplanResponce): MyPlanSuccessAction => {
  return {
    type: SUBSCRIPTION_PLAN.CLINIC_MYPLAN_SUCCESS,
    payload: response
  };
};
export const cancelSubscription = (request: CancelSubscriptionRequest): CancelSubscriptionPendingAction => {
  return {
    type: SUBSCRIPTION_PLAN.CANCEL_SUBSCRIPTION,
    payload: request
  };
};
export const cancelSubscriptionFailure = (error: any): CancelSubscriptionErrorAction => {
  return {
    type: SUBSCRIPTION_PLAN.CANCEL_SUBSCRIPTION_FAILD,
    payload: error
  };
};
export const cancelSubscriptionSuccess = (response: CancelSubscriptionResponse): CancelSubscriptionSuccessAction => {
  return {
    type: SUBSCRIPTION_PLAN.CANCEL_SUBSCRIPTION_SUCCESS,
    payload: response
  };
};