// import './_practiceSteps.scss';
import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { KeyboardTimePicker } from '@material-ui/pickers';
import { fetchLoadingPending, fetchLoadingSuccess } from '../../../store/loadingIndicator/actions';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { createStep } from '../../services/providerOnboard';
import { IAppState } from '../../../store';
import { toast } from 'react-toastify';
export function ProviderApp3(props: any) {
	const Days = ["MON", "TUE", "WED", "THU", "FRI", "SAT", "SUN"];
	const [selectedDays, setSelectedDays]: any = useState([]);
	const dispatch = useDispatch();
	const userData = useSelector((state: IAppState) => state.userData.userDataResponse, shallowEqual);
	const [hours, setHours]: any = useState([]);
	let toCloseTimePicker = false;
	const handleDateChange = (date: any, hIndex: number, index: number) => {
		if (hIndex === 0) {
			let selectedDay: any = hours.find((item: any, hIndex: number) => hIndex === index);
			let clinicHour: any = userData.clinics[0].hours.find((item: any) => item.id === selectedDay.id);
			if (moment(clinicHour.startHour, 'LT').isAfter(moment(moment(date).format('LT'), 'LT'))) {
				toast.warn('Start time should be next to clinic start time.');
				return;
			} else if (moment(clinicHour.endHour, 'LT').isBefore(moment(moment(date).format('LT'), 'LT'))) {
				toast.warn('clinic end time at ' + clinicHour.endHour);
				return;
			}
			const mappedHours = hours.map((hItem: any, hIndex: number) => {
				if (hIndex === index) {
					toCloseTimePicker = true;
					return {
						...hItem,
						openStart: false,
						startHour: moment(date).format("LT"),
						startShow: date,
						endHour: moment(date).format("LT"),
						endShow: date
					}
				}
				return hItem;
			});
			setHours(mappedHours);
		} else {
			let selectedDay: any = hours.find((item: any, hIndex: number) => hIndex === index);
			let clinicHour: any = userData.clinics[0].hours.find((item: any) => item.id === selectedDay.id);
			if (moment(selectedDay.startHour, 'LT').isAfter(moment(moment(date).format('LT'), 'LT'))) {
				toast.warn('End time shoud be next to start time');
				return;
			} else if (moment(clinicHour.endHour, 'LT').isBefore(moment(moment(date).format('LT'), 'LT'))) {
				toast.warn('clinic end time at ' + clinicHour.endHour);
				return;
			}
			const mappedHours = hours.map((hItem: any, hIndex: number) => {
				if (hIndex === index) {
					toCloseTimePicker = true;
					return {
						...hItem,
						openEnd: false,
						endHour: moment(date).format("LT"),
						endShow: date,
					}
				}
				return hItem;
			});
			setHours(mappedHours);
		}
	};
	const onNext = () => {
		const request = hours;

		const requestData = {
			hours: JSON.stringify(hours.map((item: any) => {
				if (selectedDays.find((sDay: any) => sDay === item.id)) {
					return {
						...item
					}
				}
				return {
					...item,
					startHour: 'Closed',
					endHour: 'Closed'
				}
			})),
			providerId: props.request.providerId,
			uniqueid: props.clinicUniqueId
		};
		dispatch(fetchLoadingPending());
		createStep({
			stepNumber: 3,
			formData: requestData
		}).then((success) => {
			dispatch(fetchLoadingSuccess(false));
			if (success.status && success.status.error === false) {
				props.nextStep(3, { hours: request });
				let isPmsTaken = userData.accessEnabled.pms_integration;
				if (isPmsTaken) {
					props.setPercentage(48);
				} else {
					props.setPercentage(60);
				}
			}
		}).catch((err) => {
			dispatch(fetchLoadingSuccess(false));
		});

	}
	useEffect(() => {
	}, [props.data]);
	/* 	useEffect(() => {
			if (userData && userData.clinics[0] && userData.clinics[0].hours && userData.clinics[0].hours.length > 0) {
				const hoursClinic = userData.clinics[0].hours;
				setHours(hoursClinic.map((item: any, index: number) => {
					if (hoursClinic[index].startHour === 'Closed') {
						return {
							...item,
							active: false,
							clinicClose: true,
							startHour: 'Clinic closed',
							endHour: 'Clinic closed'
						}
					} else if (hoursClinic[index].startHour !== 'Closed') {
						return {
							...item,
							active: false,
							clinicClose: false,
							startHour: hoursClinic[index].startHour,
							endHour: hoursClinic[index].endHour
						}
					}
				}));
			}
		}, [userData]); */
	useEffect(() => {
		if (props.request.hours) {
			const filtered = props.request.hours.filter((item: any) => item.active);
			setSelectedDays(filtered.map((item: any) => item.id));
			const hoursClinic = userData.clinics[0].hours;
			const hours = props.data;
			setHours(props.data);
		} else {
			if (userData && userData.clinics[0] && userData.clinics[0].hours && userData.clinics[0].hours.length > 0) {
				const hoursClinic = userData.clinics[0].hours;
				setHours(hoursClinic.map((item: any, index: number) => {
					if (hoursClinic[index].startHour === 'Closed') {
						return {
							...item,
							active: false,
							clinicClose: true,
							startHour: 'Clinic closed',
							endHour: 'Clinic closed'
						}
					} else if (hoursClinic[index].startHour !== 'Closed') {
						return {
							...item,
							active: false,
							clinicClose: false,
							startHour: hoursClinic[index].startHour,
							endHour: hoursClinic[index].endHour
						}
					}
				}));
			}
		}
	}, [props.request, userData])
	console.log("HOurs", hours);
	
	return (
		<>
			<div className="row">
				<div className="col-12">
					<h2 className="mb-3">Availablity</h2>
					<div className="fw-medium fs-5">When does {props.request.personalInfo ? props.request.personalInfo.fullname : null} work at {props.request ? props.request.clinicName : null}?</div>
					<p className="mt-1">Please set the office hours to let patients know when you're open.</p>
					<hr className="my-4" />
				</div>
			</div>
			<div className="row">
				<div className="col-12">
					<ul className="d-flex days-checklist">
						{
							Days.map((item) => {
								return (
									<li className="form-check" key={item}>
										<input className="form-check-input" type="checkbox" value={item}
											id={item} checked={selectedDays.find((day: any) => day === item) !== undefined}
											onChange={(e) => {
												const dayIndex = Days.findIndex((day: any) => day === item);
												if (selectedDays.find((day: any) => day === item) !== undefined) {
													setSelectedDays(selectedDays.filter((day: any) => day !== item));
													setHours(hours.map((item: any, index: number) => {
														if (dayIndex === index) {
															const hoursClinic = userData.clinics[0].hours;
															if (hoursClinic[index].startHour === 'Closed') {
																return {
																	...item,
																	active: false,
																	clinicClose: true,
																	startHour: 'Clinic closed',
																	endHour: 'Clinic closed',
																	isShow: false
																}
															}
															else if (hoursClinic[index].startHour !== 'Closed') {
																return {
																	...item,
																	clinicClose: false,
																	startHour: hoursClinic[index].startHour,
																	endHour: hoursClinic[index].endHour,
																	isShow: false,
																	active: false
																}
															}
														}
														return item;
													}))
												} else {
													setSelectedDays([...selectedDays, item]);
													setHours(hours.map((item: any, index: number) => {
														if (dayIndex === index) {
															const hoursClinic = userData.clinics[0].hours;
															if (hoursClinic[index].startHour === 'Closed') {
																return {
																	...item,
																	active: true,
																	clinicClose: true,
																	startHour: 'Clinic closed',
																	endHour: 'Clinic closed',
																	isShow: true
																}
															} else if (hoursClinic[index].startHour !== 'Closed') {
																return {
																	...item,
																	active: true,
																	clinicClose: false,
																	startHour: hoursClinic[index].startHour,
																	endHour: hoursClinic[index].endHour,
																	isShow: true
																}
															}
														}
														return item;
													}))
												}
											}}
										/>
										<label className="form-check-label rounded-2 border w-100" htmlFor={item}>
											{item}
										</label>
									</li>
								)
							})
						}
					</ul>
				</div>
				<div className="col-12">
					<form className="open-hours-info mt-5 mb-4">
						{
							hours.map((hour: any, index: number) => {
								if (hour.active === true || hour.isShow === true) {
									return (
										<div className="d-flex hours-box my-3" key={index + hour.id}>
											<label className="day-title fw-normal align-self-center">{hour.label}</label>
											<div className="input-group flex-nowrap">
												<input type="text" className="form-control border-end-0" placeholder="Open"
													aria-label="Recipient's username" aria-describedby="basic-addon2" value={hour.startHour} disabled />
												<span className="input-group-text bg-white" id="basic-addon2">
													{
														hour.startHour !== 'Closed' && !hour.clinicClose &&
														<i className="bi bi-x me-2 cursor-pointer" onClick={() => {
															if (hour.clinicClose) {
																return;
															}
															setHours(hours.map((hItem: any, hIndex: number) => {
																if (index === hIndex) {
																	return {
																		...hItem,
																		startHour: 'Closed',
																		endHour: 'Closed',
																	}
																}
																return hItem;
															}));
														}}></i>
													}
													{
														!hour.clinicClose &&
														<i className="bi bi-clock cursor-pointer" onClick={() => {
															if (hour.clinicClose) {
																return;
															}
															setHours(hours.map((hItem: any, hIndex: number) => {
																if (hIndex === index) {
																	return {
																		...hItem,
																		openStart: true
																	}
																}
																return {
																	...hItem,
																	openStart: false
																}
															}));
														}}></i>
													}


													<KeyboardTimePicker
														margin="normal"
														id="time-picker"
														label="Time picker"
														value={hour.startShow}
														onChange={(date) => { handleDateChange(date, 0, index) }}
														TextFieldComponent={(props) => { return null }}
														open={hour.openStart ? true : false}
														KeyboardButtonProps={{
															'aria-label': 'change time',
														}}
														onClose={() => {
															if(!toCloseTimePicker){
															   setTimeout(() => {
															  setHours(hours.map((hItem: any, hIndex: number) => {
																if (hIndex === index ) {
																  return {
																	...hItem,
																	openStart: false
																  }
																}
																return hItem;
															  }));
															}, 1000);
															toCloseTimePicker=true;
															}
														  }}
													/>
												</span>
											</div>
											<div className="mx-4 align-self-center text-muted">To</div>
											<div className="input-group flex-nowrap">
												<input type="text" className="form-control border-end-0" placeholder="Closed" value={hour.endHour} disabled />
												<span className="input-group-text bg-white">
													{
														hour.endHour !== 'Closed' && !hour.clinicClose &&
														<i className="bi bi-x me-2 cursor-pointer" onClick={() => {
															if (hour.clinicClose) {
																return;
															}
															setHours(hours.map((hItem: any, hIndex: number) => {
																if (index === hIndex) {
																	return {
																		...hItem,
																		startHour: 'Closed',
																		endHour: 'Closed',
																	}
																}
																return hItem;
															}));
														}}></i>
													}
													{
														!hour.clinicClose &&
														<i className="bi bi-clock cursor-pointer" onClick={() => {
															if (hour.clinicClose) {
																return;
															}
															setHours(hours.map((hItem: any, hIndex: number) => {
																if (hIndex === index) {
																	return {
																		...hItem,
																		openEnd: true
																	}
																}
																return {
																	...hItem,
																	openEnd: false
																}
															}));
														}} />
													}
													<KeyboardTimePicker
														margin="normal"
														id="time-picker"
														label="Time picker"
														value={hour.endShow}
														onChange={(date) => { handleDateChange(date, 1, index) }}
														TextFieldComponent={(props) => { return null }}
														open={hour.openEnd ? true : false}
														KeyboardButtonProps={{
															'aria-label': 'change time',
														}}
														onClose={() => {
															if(!toCloseTimePicker){
															setTimeout(() => {
															  setHours(hours.map((hItem: any, hIndex: number) => {
																if (hIndex === index) {
																  return {
																	...hItem,
																	openEnd: false
																  }
																}
																return hItem;
															  }));
															}, 1000);
															toCloseTimePicker = true;
														  }
														  }}
													/>
												</span>
											</div>
										</div>
									)
								}
								return null;
							})
						}
					</form>
				</div>
			</div>
			<div className="row">
				<div className="col-12 my-4">
					<div className="bottom-btn-sec d-flex justify-content-between">
						<button className="btn border me-1" onClick={() => { props.onPrev(1) }}>Back</button>
						<button className="btn btn-primary ms-1" disabled={hours.find((item: any) => item.active) === undefined} onClick={() => { onNext() }}>Next</button>
					</div>
				</div>
			</div>
		</>
	);
}
