import moment from "moment";

export const SYSTEM_DATE_FORMAT = "YYYY-MM-DD";
export const SYSTEM_DATE_DISPLAY_FORMAT = "MMM DD, YYYY";
export const SYSTEM_DATE_TIME_DISPLAY_FORMAT = "MMM DD, YYYY - LT";
export const SYSTEM_TIME_FORMAT = "LT";
export const SYSTEM_TIME_FORMAT_TIMEONLY = "hh:mm";
export const SYSTEM_TIME_FORMAT_MEREDIANONLY = "A";

export const convertToSystemDate = (date:any) => {
    return moment(date).format(SYSTEM_DATE_FORMAT);
};

export const convertToSystemTime = (time:any) => {
    return moment(moment().format("YYYY-MM-DD") + " " + time).format(SYSTEM_TIME_FORMAT);
};

export const convertToSytemDisplayDate = (date:any) => {
    return moment(date).format(SYSTEM_DATE_DISPLAY_FORMAT);
};

export const convertToSytemDisplayDateTime = (date:any, time:any) => {
    return moment(date + " " + time).format(SYSTEM_DATE_TIME_DISPLAY_FORMAT);
};

export const convertToSystemTimeTimeOnly = (time:any) => {
  return moment(moment().format("YYYY-MM-DD") + " " + time).format(SYSTEM_TIME_FORMAT_TIMEONLY);
};

export const convertToSystemTimeMeredianOnly = (time:any) => {
  return moment(moment().format("YYYY-MM-DD") + " " + time).format(SYSTEM_TIME_FORMAT_MEREDIANONLY);
};
export const convertToSystemDisplayDateTimeAtom = (dateTime:any) => {
    return moment(dateTime).format(SYSTEM_DATE_TIME_DISPLAY_FORMAT);
}
