import { PAYMENT, FetchInitPaymentPendingAction, FetchInitPaymentRequest, PaymentBillableEntryRequest, PaymentBillableEntryPendingAction, PaymentBillableAppointmentPendingAction, PaymentBillableAppointmentRequest, ServicesTypePendingAction, ServicesTypeRequest, CreateInvoiceRequest, CreateInvoicePendingAction, StripeSaveAccountIdRequest, StripeSaveAccountIdPendingAction, SaveTaxPendingAction, SaveTaxRequest, PayamentAmountPendingAction } from "../store/payment/types";
import Axios from "axios";
import configs from "../configs/apiConfigs";
import { attachHeader } from "../utils/apiHandler";
import { takeEvery, put, call } from 'redux-saga/effects';
import { fetchInitPaymentSuccess, fetchInitPaymentError, paymentBillableEntrySuccess, paymentBillableEntryError, paymentBillableAppointmentSuccess, paymentBillableAppointmentError, servicesTypeError, servicesTypeSuccess, createInvoiveSuccess, createInvoiceError, stripeSaveAccountIdSuccess, stripeSaveAccountIdError, saveTaxSuccess, saveTaxError, paymenAmountSuccess, paymentAmountError } from "../store/payment/actions";
import { fetchLoadingPending, fetchLoadingSuccess } from "../store/loadingIndicator/actions";
import { CommonApiOptions, METHOD_TYPE, BODY_TYPE } from "../constants/api";
import { commonApi } from "../helpers/api";
function executeBilling(request: FetchInitPaymentRequest) {
    return Axios.get(configs.API_URL + 'user/patientBilling/',
        { headers: attachHeader(true), withCredentials: true }
    ).then(response => ({ response }), error => ({ error }));
}
function* fetchInitPaymentPendingRun(action: FetchInitPaymentPendingAction) {
    // yield put(fetchLoadingPending());
    const { response, error } = yield executeBilling(action.payload);
    if (response) {
        if (response.status === 200) {
            if (response.data.status && response.data.status.error === false) {
                yield put(fetchInitPaymentSuccess(response.data));
            } else {
                yield put(fetchInitPaymentError(response));
            }
        } else {
            yield put(fetchInitPaymentError(response));
        }
    }
    if (error) {
        yield put(fetchInitPaymentError(error));
    }
    // yield put(fetchLoadingSuccess(false));
}
export function* fetchInitPaymentPendingWatcher() {
    yield takeEvery(PAYMENT.FETCH_INIT_PAYMENT, fetchInitPaymentPendingRun);
}

function executeBillableEntry(request: PaymentBillableEntryRequest) {
    return Axios.get(configs.API_URL + 'prime/' + request.type + '/' + request.uniqueId + '/patientBillableEntry/',
        { headers: attachHeader(true), withCredentials: true }
    ).then(response => ({ response }), error => ({ error }));
}
function* paymentBillableEntryPendingRun(action: PaymentBillableEntryPendingAction) {
    // yield put(fetchLoadingPending());
    const { response, error } = yield executeBillableEntry(action.payload);
    if (response) {
        if (response.status === 200) {
            if (response.data !== "") {
                yield put(paymentBillableEntrySuccess(response.data));
            } else {
                yield put(paymentBillableEntryError(response));
            }
        } else {
            yield put(paymentBillableEntryError(response));
        }
    }
    if (error) {
        yield put(paymentBillableEntryError(error));
    }
    // yield put(fetchLoadingSuccess(false));
}
export function* paymentBillableEntryPendingWatcher() {
    yield takeEvery(PAYMENT.PAYMENT_BILLABLE_ENTRY, paymentBillableEntryPendingRun);
}

function executeBillableAppointment(request: PaymentBillableAppointmentRequest) {
    let url: any = '';
    if (request.fullname) {
        url =configs.API_URL + 'prime/' + request.type + '/' + request.uniqueId + '/patientBillableEntry/' + request.year + '/' + request.month + '/?type='+request.invoiceType+"&fullname="+request.fullname
    } else {
        url =configs.API_URL + 'prime/' + request.type + '/' + request.uniqueId + '/patientBillableEntry/' + request.year + '/' + request.month + '/?type='+request.invoiceType
    }
    return Axios.get(url,
        { headers: attachHeader(true), withCredentials: true }
    ).then(response => ({ response }), error => ({ error }));
}
function* paymentBillableAppointmentPendingRun(action: PaymentBillableAppointmentPendingAction) {
    // yield put(fetchLoadingPending());
    const { response, error } = yield executeBillableAppointment(action.payload);
    if (response) {
        if (response.status === 200) {
            if (response.data !== "") {
                yield put(paymentBillableAppointmentSuccess(response.data));
            } else {
                yield put(paymentBillableAppointmentError(response));
            }
        } else {
            yield put(paymentBillableAppointmentError(response));
        }
    }
    if (error) {
        yield put(paymentBillableAppointmentError(error));
    }
    // yield put(fetchLoadingSuccess(false));
}
export function* paymentBillableAppointmentPendingWatcher() {
    yield takeEvery(PAYMENT.PAYMENT_BILLABLE_APPOINTMENT, paymentBillableAppointmentPendingRun);
}
function executeServicesType(request: ServicesTypeRequest) {
    return Axios.get(configs.API_URL + 'serviceTypes/', {
        headers: attachHeader(true),
        withCredentials: true
    }).then(response => ({ response })).catch(error => ({ error }));
}
function* servicesTypePendingRun(action: ServicesTypePendingAction) {
    const { response, error } = yield executeServicesType(action.payload);
    if (response) {
        if (response.status === 200 && response.data.status.error === false) {
            yield put(servicesTypeSuccess(response.data));
            return;
        }
        yield put(servicesTypeError(response));
        return;
    }
    yield put(servicesTypeError(error ? error : 'Something went wrong'));
}
export function* servicesTypePendingWatcher() {
    yield takeEvery(PAYMENT.SERVICES_TYPE, servicesTypePendingRun);
}

function executeCreateInvoice(request: CreateInvoiceRequest) {
    const url = 'payment/' + request.provider + '/' + request.uniqueId + '/patientInvoice/' + request.appointmentId + '/';
    return Axios.post(configs.API_URL + url, request.requestInvoiceData, {
        headers: attachHeader(true),
        withCredentials: true
    }).then(response => ({ response })).catch(error => ({ error }));
}
function* createInvoicePendingRun(action: CreateInvoicePendingAction) {
    yield put(fetchLoadingPending());
    const { response, error } = yield executeCreateInvoice(action.payload);
    if (response) {
        if (response.status === 200 && response.data.status.error === false) {
            yield put(createInvoiveSuccess(response.data));
            yield put(fetchLoadingSuccess(false));
            return;
        }
        yield put(createInvoiceError(response));
        yield put(fetchLoadingSuccess(false));
        return;
    }
    yield put(createInvoiceError(error ? error : 'Something went wrong'));
    yield put(fetchLoadingSuccess(false));
}
export function* createInvoicePendingWatcher() {
    yield takeEvery(PAYMENT.CREATE_INVOICE, createInvoicePendingRun);
}

function executeStripeSaveAccountId(request: StripeSaveAccountIdRequest) {
    return Axios.post(configs.API_URL + 'stripe/clinic/' + request.uniqueId + '/', request.requestData, {
        headers: attachHeader(true),
        withCredentials: true
    }).then(response => ({ response })).catch(error => ({ error }));
}
function* stripeSaveAccountIdPendingRun(action: StripeSaveAccountIdPendingAction) {
    const { response, error } = yield executeStripeSaveAccountId(action.payload);
    if (response) {
        if (response.status === 200 && response.data.status.error === false) {
            yield put(stripeSaveAccountIdSuccess(response.data));
            return;
        }
        yield put(stripeSaveAccountIdError(response));
        return;
    }
    yield put(stripeSaveAccountIdError(error ? error : 'Something went wrong'));
}
export function* stripeSaveAccountIdPendingWatcher() {
    yield takeEvery(PAYMENT.STRIPE_SAVE_ACCOUNT_ID, stripeSaveAccountIdPendingRun);
}

function executeSaveTax(request: SaveTaxRequest) {
    return Axios.post(configs.API_URL + 'taxable/clinic/' + request.uniqueId + '/', request.requestData, {
        headers: attachHeader(true),
        withCredentials: true
    }).then(response => ({ response })).catch(error => ({ error }));
}
function* saveTaxPendingRun(action: SaveTaxPendingAction) {
    yield put(fetchLoadingPending());
    const { response, error } = yield executeSaveTax(action.payload);
    if (response) {
        if (response.status === 200 && response.data.status.error === false) {
            yield put(saveTaxSuccess(response.data));
            yield put(fetchLoadingSuccess(false));
            return;
        }
        yield put(saveTaxError(response));
        yield put(fetchLoadingSuccess(false));
        return;
    }
    yield put(saveTaxError(error ? error : 'Something went wrong'));
    yield put(fetchLoadingSuccess(false));
}
export function* saveTaxPendingWatcher() {
    yield takeEvery(PAYMENT.SAVE_TAX, saveTaxPendingRun);
}


function* paymentAmountRun(action: PayamentAmountPendingAction) {
    try {
        yield put(fetchLoadingPending());
        let url = configs.API_URL + "clinic/" + action.payload.clinicUniqueId + "/appointments/" + action.payload.appoinmentUniqueId + "/";
        const options: CommonApiOptions = {
            url: url,
            apiOptions: {
                headers: attachHeader(true),
                withCredentials: true
            },
            method: METHOD_TYPE.POST,
            body: {
                type: BODY_TYPE.RAW,
                data: { amount: action.payload.amount }
            }
        };
        //@ts-ignore
        const response = yield call(commonApi, options);
        yield put(paymenAmountSuccess(response));
    } catch (err) {
        yield put(paymentAmountError(err));
    }
    yield put(fetchLoadingSuccess(false));
}
export function* paymentAmountWatcher() {
    yield takeEvery(PAYMENT.PAYMENT_AMOUNT_UPDATE, paymentAmountRun);
}