import app from 'firebase/app';
import 'firebase/firestore';
import { firebaseConfig } from '../../configs/firebaseConfigs';
import { CHAT } from '../../constants';
import configs from '../configs/apiConfigs';
class FirebaseHelper {
    db: any;
    constructor() {
        app.initializeApp(firebaseConfig);
        this.db = app.firestore();
        if (app.messaging.isSupported()) {
            app.messaging().usePublicVapidKey("BJl3YIUHhsbjNY2hcv6f-QaFBqfYs50Nm2PJOUlBnYEhVyWX_DV_L82VaojLZJGID825VcmfiDv4asthS2yRVmQ");
        }
    }
    setDocumentIntoCollection = async (collection: any, data: any) => {
        return await this.db.collection(collection).doc().set(data);
    };
    addDocumentIntoCollection = async (collection: any, data: any) => {
        return await this.db.collection(collection).add(data);
    };
    /* Get prescreening data */
    getPrescreeningData = (appointmentId: any) => {
        return this.db.collection(configs.CHAT_COLLECTION)
            .where("appointmentId", "==", Number(appointmentId))
            .where("actionType", "==", CHAT.ACTION_TYPE.PRESCREENING)
            .get();
    }
    getDocRef = (doc: any) => {
        return this.db.collection(configs.CHAT_COLLECTION).doc(doc.id);
    };
    getPrescreeningWithViewByNull = (id: any) => {
        return this.db.collection(configs.CHAT_COLLECTION)
            .where("appointmentId", "==", id)
            .where("actionType", "==", CHAT.ACTION_TYPE.PRESCREENING)
            .where("metadata.viewByClinicAt", "==", null)
            .get()
    }
    getPendingMessageCount = async (clinicId: any) => {
        return await this.db.collection(configs.CHAT_COLLECTION)
            .where('actionType', '==', 'MESSAGE')
            .where('sender', '==', 'patient')
            .where('metadata.viewByClinicAt', '==', null)
            .onSnapshot(async (querySnapshot: any) => {
                if (querySnapshot.docs.length > 0) {
                    const mappedDocs = querySnapshot.docs.map((doc: any) => doc.data());
                    const unreadInClinic = mappedDocs.filter((doc: any) => {
                        return clinicId === doc.sendToCId
                    })
                    return new Promise((res, rej) => res(unreadInClinic.length));
                }
            });
    }
}
const firebaseInstance = new FirebaseHelper();
export default firebaseInstance;