import {
    UserInfoState,
    UserInfoActionTypes,
    USER_INFO
} from './types';

export const initialState: UserInfoState = {
    pending: false,
    userInfo: { firstName: '', lastName: '' },
    userInfoResponse: { email: '', emailVerified: '', firstName: '', id: '', lastName: '', uniqueId: '', status: { error: false, msg: '' } },
    error: null
};

export const userInfoReducer = (state = initialState, action: UserInfoActionTypes) => {
    const NewState = { ...state }
    switch (action.type) {
        case USER_INFO.LOAD_ERROR:
            return {
                ...state,
                pending: false,
                error: action.error
            }
        case USER_INFO.LOAD_PENDING:
            return {
                ...state,
                pending: true,
                userInfo: action.payload
            }
        case USER_INFO.LOAD_SUCCESS:
            return {
                ...state,
                pending: false,
                userInfoResponse: action.userInfo
            }
        default:
            return NewState;

    }
};