import React from 'react';
import { NavLink } from 'react-router-dom';
export function EmployeeHeader() {
    return (
        <div className="page-header no-gutters has-tab with_tab">
            <h2 className="font-weight-normal">Employee</h2>
            <ul className="nav nav-tabs sbptab">

                <li className="nav-item">
                    <NavLink exact className="nav-link" to="/user/employee">All Employee</NavLink>
                </li>
                <li className="nav-item">
                    <NavLink exact className="nav-link" to="/user/employee/prescreening">Prescreening</NavLink>
                </li>




                <li className="nav-item flex-grow-1 text-right">
                    <NavLink exact={true} to={"/"}><i className="fas fa-arrow-alt-circle-left"></i> Back to Dashboard</NavLink>
                </li>
            </ul>
        </div>
    )
}