import React, { useEffect, useState } from 'react';
import { StandardEvent } from '@fullcalendar/react';
import { UserDataResponse } from '../../../store/userData/types';
import { connect } from 'react-redux';
import { IAppState } from '../../../store';
import { myPlan, cancelSubscription } from '../../../store/subscriptionplan/actions';
import { ClinicMyplanRequest, SubscriptionState, CancelSubscriptionRequest } from '../../../store/subscriptionplan/types';
import { toast } from 'react-toastify';
import moment from 'moment';
import {renewPlan} from '../../services/subscription';
// import { Modal } from '@material-ui/core';
import { Alert, Col, Form, Button, Modal } from 'react-bootstrap';
import { fetchUserDataPending } from '../../../store/userData/actions';
interface Props {
  userDataResponse: UserDataResponse;
  myPlan: typeof myPlan;
  subscriptionState: SubscriptionState;
  cancelSubscription: typeof cancelSubscription;
  history: any;
  fetchUserData: typeof fetchUserDataPending;
};

interface State {
  myPlanList: any;
  selectedClinic: string;
  showConfirmationModal: boolean;
  selectedPlan?: any;
  isRenewPlan:boolean;
  reNewPlanRequest:any;
  isReNewBtnShow:boolean;
  isStandardPlanCancel:boolean;
};

class MyPlans extends React.Component<Props, State>{
  constructor(props: any) {
    super(props);
    this.state = {
      selectedClinic: '',
      myPlanList: [],
      showConfirmationModal: false,
      isRenewPlan:false,
      reNewPlanRequest:'',
      isReNewBtnShow:true,
      isStandardPlanCancel:false
    }
  }
  componentDidMount() {
    if (this.props.userDataResponse && this.props.userDataResponse.clinics && this.props.userDataResponse.clinics.length > 0) {
      this.props.myPlan({ clinicUniqueId: this.props.userDataResponse.clinics[0].uniqueId });
      this.setState({ selectedClinic: this.props.userDataResponse.clinics[0].uniqueId });
    }
  }
  componentDidUpdate(prevProps: Props, prevState: State) {
    if (prevProps.userDataResponse.clinics !== this.props.userDataResponse.clinics && this.props.userDataResponse.clinics.length > 0) {
      this.setState({ selectedClinic: this.props.userDataResponse.clinics[0].uniqueId });
      this.props.myPlan({ clinicUniqueId: this.props.userDataResponse.clinics[0].uniqueId });
    }
    if (this.props.subscriptionState !== prevProps.subscriptionState) {
      if (this.props.subscriptionState.error) {
        toast.error(this.props.subscriptionState.error);
      } else if (this.props.subscriptionState.clinicMyplanResponce !== prevProps.subscriptionState.clinicMyplanResponce &&
        this.props.subscriptionState.clinicMyplanResponce && this.props.subscriptionState.clinicMyplanResponce.status.error === false) {
        this.setState({
          myPlanList: this.props.subscriptionState.clinicMyplanResponce.plans ? this.props.subscriptionState.clinicMyplanResponce.plans : [],
        },()=>{
          for (let i = 0; i < this.state.myPlanList.length; i++) {
            if (this.state.myPlanList[i].planName === "Standard Plan" && this.state.myPlanList[i].isCancelledPlan === true) {
                this.setState({ isStandardPlanCancel: true});
            }
          }
        })
      } else if (this.props.subscriptionState.cancelSubscriptionResponse !== prevProps.subscriptionState.cancelSubscriptionResponse &&
        this.props.subscriptionState.cancelSubscriptionResponse && this.props.subscriptionState.cancelSubscriptionResponse.status.error === false) {
        this.props.fetchUserData({});
        this.props.myPlan({ clinicUniqueId: this.state.selectedClinic });
        toast.success(this.props.subscriptionState.cancelSubscriptionResponse.status.msg);
        this.hideModal();
      }
    }
  }
  selectedClinicChange = (uniqueId: string) => {
    this.setState({
      selectedClinic: uniqueId,
    });
    this.props.myPlan({ clinicUniqueId: uniqueId });
  }
  hideModal() {
    this.setState({ showConfirmationModal: false, selectedPlan: null,isRenewPlan:false });
  }
  clickRenewBtn = (item:any) =>{
    this.setState({isRenewPlan:true,
    reNewPlanRequest:{
      "subscriptions": [{ "subscribed_subscription_id": item.subscribedId, "id": item.id }] 
    }})

  }
  handleRenewPlan = () => {
    if(this.state.selectedClinic && this.state.reNewPlanRequest){
        renewPlan(this.state.selectedClinic, this.state.reNewPlanRequest).then((success: any) => {
        if (success && success.response && success.response.data && success.response.status === 200) {
            if (success.response.data && success.response.data.status.error === false) {
                toast.success(success.response.data.status.msg);
                this.setState({isRenewPlan:false});
                this.props.fetchUserData({});
                this.props.myPlan({ clinicUniqueId: this.props.userDataResponse.clinics[0].uniqueId });
                
                // this.props.loadingEnd(false);
            } else {
                // Error Msg
                if (success.response.data && success.response.data.status.error === true) {
                    toast.error(success.response.data.status.msg)
                }
                // this.props.loadingEnd(false);
            }
        } else {
            // this.props.loadingEnd(false);
        }
        }).catch((err) => {
            // this.props.loadingEnd(false);
            return '';
        });
    }
    
  }
  render() {
    return (
      <>

        <section className="subscription-sec px-3">
          <div className="container">
            <div className="row">
              <div className="col-12">
                {(this.props.subscriptionState.pending === false
                  && this.state.myPlanList && this.state.myPlanList.find((item: any) => { return item && item.subscribedStatus !== 'active' }) &&
                  this.state.myPlanList.find((item: any) => { return item && item.subscribedStatus !== 'active' }).length > 0) &&
                  <div className="alert alert-danger py-2 mt-4" role="alert">
                    <div className="d-flex justify-content-between align-items-center">
                      <p className="m-0">Hey, You don't have any activated plan, please choose your plan.</p>
                      <button className="btn btn-danger ms-3">Choose your plan</button>
                    </div>
                  </div>
                }
              </div>
            </div>

            <div className="row">
              <div className="col-12">

                {/* <div className="d-flex justify-content-end">
                <ClinicDropDown clinicList={this.props.userDataResponse.clinics} onChange={(id) => { this.selectedClinicChange(id); }}></ClinicDropDown>
                </div> */}
                <div className="advances-table">
                  {!(this.props.subscriptionState.pending === false &&
                    this.props.subscriptionState.clinicMyplanResponce &&
                    this.state.myPlanList.length === 0) &&
                    <table className="table">
                      <thead>
                        <tr>
                          <th>Sr No.</th>
                          <th className="w-20">Plan</th>
                          {/* <th>Autorenew on</th> */}
                          <th>Price</th>
                          <th>Billing Start Date</th>
                          <th>Expires on/Autorenew on</th>
                          <th>Status</th>
                          {/* <th>Trial days</th> */}
                          {this.state.myPlanList.length === 1 && this.state.myPlanList[0].planName === "Basic Plan"?
                          null
                          :
                          <th className="w-20 text-end">Action</th>
                          }
                          
                        </tr>
                      </thead>
                      <tbody>
                        {this.state.myPlanList && this.state.myPlanList.map((value: any, index: number) =>
                        {
                          let diff:any=0;
                          if(value.trial_enddate && moment().format('YYYY-MM-DD') < moment(value.trial_enddate).format('YYYY-MM-DD')){
                            let date = moment(value.trial_enddate).format('YYYY-MM-DD');
                            diff = moment(date).diff(moment().format('YYYY-MM-DD'),'days');
                            console.log('diff',diff);
                            // if(diff === 1){
                            //     this.setState({isReNewBtnShow:false});
                            // }
                          }else{
                              let date = moment(value.subscribedEndDate).format('YYYY-MM-DD');
                              diff = moment(date).diff(moment().format('YYYY-MM-DD'),'days');
                              // if(diff === 1){
                              //     this.setState({isReNewBtnShow:false});
                              // }
                          }
                          return(
                          <tr className="odd">
                            <td>{index + 1}</td>
                            <td>{value.planDisplayName?value.planDisplayName:value.planName}</td>
                            {/* <td>{moment.utc(value.subscribedEndDate).local().format('MM-DD-YYYY')}</td> */}
                            <td>{value.amount}</td>
                            <td>{moment.utc(value.subscribedStartDate).local().format('MM-DD-YYYY')}</td>
                            <td>{moment.utc(value.subscribedEndDate).local().format('MM-DD-YYYY')}</td>
                            <td><span className={(value.subscribedStatus === "active") ? 'badge rounded-pill bg-success text-capitalize' : 'badge rounded-pill bg-danger text-capitalize'}>{value.subscribedStatus}</span></td>
                            {/* <td>{value.trial_period ?value.trial_period:0 }</td> */}
                            <td className="text-end">
                              {
                                (value.subscribedStatus === "active" && value.planName !== "Basic Plan" && value.isCancelledPlan !== true) &&
                                <button type="button" className="btn btn-outline-secondary btn-sm"
                                  onClick={(e) => {
                                    this.setState({ showConfirmationModal: true, selectedPlan: value });
                                  }}>Cancel subscription</button>
                              }
                              {
                                (value.subscribedStatus === "active" && value.planName === "Standard Plan" && value.isCancelledPlan === true && diff !== 1) &&
                                <button type="button" className="btn btn-outline-secondary btn-sm"
                                  onClick={(e) => {
                                    this.clickRenewBtn(value);
                                    // this.setState({ showConfirmationModal: true, selectedPlan: value });
                                  }}>Renew Plan</button>
                              }
                              {
                                (value.subscribedStatus === "active" && value.planName !== "Standard Plan" && value.isCancelledPlan === true && diff !== 1 && this.state.isStandardPlanCancel !== true) &&
                                <button type="button" className="btn btn-outline-secondary btn-sm"
                                  onClick={(e) => {
                                    this.clickRenewBtn(value);
                                    // this.setState({ showConfirmationModal: true, selectedPlan: value });
                                  }}>Renew Plan</button>
                              }
                            </td>
                          </tr>
                          )}
                          )}
                      </tbody>
                    </table>}
                  {
                    (this.props.subscriptionState.pending === false &&
                      this.props.subscriptionState.clinicMyplanResponce &&
                      this.state.myPlanList.length === 0) &&
                    <div className="alert alert-danger py-2 mt-4" role="alert">
                      <div className="d-flex justify-content-between align-items-center">
                        <p className="m-0">Hey, You don't have any active paid subscription plan, please choose your plan.</p>
                        <button onClick={()=>{(this.props.history.push({pathname:'/subscription/upgrade/plan'}))}} className="btn btn-danger ms-3">Choose your plan</button>
                      </div>
                    </div>
                  }
                </div>

              </div>
            </div>
          </div>
        </section>

        <Modal className="newcommon cancelsubsc" show={this.state.showConfirmationModal} onHide={this.hideModal.bind(this)} centered>
          <Modal.Header>
            <div className="modal-title">Cancel subscription</div>
            <button type="button" className="btn-close" aria-label="Close" onClick={this.hideModal.bind(this)}>
            </button>
          </Modal.Header>

          <Modal.Body>
            <p>Are you sure you want to cancel subscription?</p>
            <p>All your Addons and Standard plans will be cancelled on one day before your auto-renewal date.</p>
            <p>If you are in trial period it will be cancelled one day before billing start date.</p>
            <p>If you wish to reactivate your plan and Addons, simply renew from the action tab.</p>
            </Modal.Body>
          <Modal.Footer>
            <button className="btn btn-outline-danger" onClick={() => {
              this.props.cancelSubscription(
                {
                  clinicUniqueId: this.state.selectedClinic,
                  data: { subscriptions: [{ subscribed_subscription_id: this.state.selectedPlan.subscribedId, id: this.state.selectedPlan.id }] }
                }
              )
            }} >Yes, Unsubscribe</button>
            <button className="btn btn-primary" onClick={() => { this.hideModal() }}>Close</button>
          </Modal.Footer>
        </Modal>
        {
          this.state.isRenewPlan &&
          <Modal className="newcommon cancelsubsc" show={this.state.isRenewPlan} onHide={this.hideModal.bind(this)} centered>
          <Modal.Header>
            <div className="modal-title">Renew subscription</div>
            <button type="button" className="btn-close" aria-label="Close" onClick={this.hideModal.bind(this)}>
            </button>
          </Modal.Header>

          <Modal.Body>
            Are you sure you want to Renew subscription?
            <p>All yours Addons and Standard plan will be reactivated.</p>

          </Modal.Body>
          <Modal.Footer>
            <button className="btn btn-outline-danger" onClick={() => {
             this.handleRenewPlan();
            }} >Yes, Subscribe</button>
            <button className="btn btn-primary" onClick={() => { this.hideModal() }}>Close</button>
          </Modal.Footer>
        </Modal>


        }
      </>
    )
  }

}
const mapStateToProps = (state: IAppState) => ({
  userDataResponse: state.userData.userDataResponse,
  subscriptionState: state.subscriptionPlan

});
const mapDispatchToProps = (dispatch: any) => ({
  myPlan: (request: ClinicMyplanRequest) => {
    dispatch(myPlan(request));
  },
  cancelSubscription: (request: CancelSubscriptionRequest) => {
    dispatch(cancelSubscription(request))
  },
  fetchUserData:({})=>{
    dispatch(fetchUserDataPending({}));
}

});
export default connect(mapStateToProps, mapDispatchToProps)(MyPlans);

interface ClinicDropDownProps {
  clinicList: any[];
  onChange: (uniqueId: string) => void;
}
export const ClinicDropDown: React.FC<ClinicDropDownProps> = (props: ClinicDropDownProps) => {
  const [selectedClinic, selectClinic] = useState(props.clinicList[0] ? props.clinicList[0].uniqueId : '');
  useEffect(() => {
    if (selectedClinic) {
      props.onChange(selectedClinic);
    }
  }, [selectedClinic]);
  return (
    <select className="custom-select m-r-15" style={{ width: "240px" }} value={selectedClinic} onChange={(e) => {
      selectClinic(e.target.value);
    }}>
      {
        props.clinicList.map((value, index) => {
          return (
            <option value={value.uniqueId} key={index + Math.random()}>{value.name}</option>
          )
        })
      }
    </select>
  );
}


