import React from 'react';
import Login from '../../components/login/Login';
import { IAppState } from '../../store';
import { connect } from 'react-redux';
import { LoginForgotSentState } from '../../store/loginforgotsent/types';
import ForgotPassword from '../../components/forgotpassword/ForgotPassword';
import CONSTANT_LABELS from '../../constants/labels';
import ResetPasswordLink from '../../components/resetpasswordlink/ResetPasswordLink';
import LoadingIndicator from '../../components/LoadingIndicator/LoadingIndicator';
import { LoginState } from '../../store/login/types';
import { NavLink, useLocation } from 'react-router-dom';
import { ForgotPasswordState } from '../../store/forgotpassword/types';
import NotSupported from '../../v2/assets/images/not-supported.png';
interface Props {
    loginForgotSentState: LoginForgotSentState,
    history: any;
    loginState: LoginState;
    forgotPasswordState: ForgotPasswordState;
    location:any;
}
interface State { }
class LoginContainer extends React.Component<Props, State> {
    constructor(props: any) {
        super(props);
        this.state = {};
    }
    render() {
        // const location = useLocation();
        const mode = new URLSearchParams(this.props.location.search).get("mode");
        const { loginforgotsent } = this.props.loginForgotSentState.loginforgotsent;
        return (
            <>
                {this.props.loginState.pending === true && <LoadingIndicator></LoadingIndicator>}
                {this.props.forgotPasswordState.pending === true && <LoadingIndicator></LoadingIndicator>}
                <div className={"d-flex d-lg-none not-supported-box justify-content-center "+ (mode && mode=='desktop' ? 'no-not-supported': '')}>
                    <div className="align-self-center">
                        <img src={NotSupported} className="img-fluid mb-5" />
                        <p className="text-center px-4">Oops !!! View for this resolution is not supported. Please use an iPad or Android tablet in landscape mode or Desktop to access Clinic ADMIN panel features.</p>
                    </div>
                </div>
                <div className={mode && mode=='desktop' ? "app login-container d-lg-flex min-vh-100 d-flex" : "app login-container d-none d-lg-flex min-vh-100 d-flex"}>
                    <div className="form-signin">
                        {loginforgotsent === 'login' ? <Login></Login> : <></>}
                        {loginforgotsent === 'forgot' ? <ForgotPassword></ForgotPassword> : <></>}
                        {loginforgotsent === 'resetlink' ? <ResetPasswordLink></ResetPasswordLink> : <></>}

                        <div className="mt-4 text-center">
                            <div className="mb-5">Don't have account? <a target="_blank" href="https://www.xcare.app/#scrollto_reg" className="link-primary text-decoration-none">Contact Us</a></div>
                            <span className="text-dark">{CONSTANT_LABELS.LINK.COPYRIGHT}</span>
                        </div>

                    </div>

                </div>
            </>
        )
    }
}
const mapStateToProps = (state: IAppState) => ({
    loginForgotSentState: state.loginForgotSent,
    loginState: state.login,
    forgotPasswordState: state.forgotPassword
});
export default connect(mapStateToProps)(LoginContainer);