import {
    GetPMSAppointmentsRequest, GetPMSAppointmentPendingAction, PMS_APPOINTMENTS,
    GetPMSAppointmentsErrorAction,
    GetPMSAppointmentsResponse,
    GetPMSPendingSuccessAction,
    BookPMSAppointmentRequest,
    BookPMSAppointmentPendingAction,
    BookPMSAppointmentFailureAction,
    BookPMSAppointmentResponse,
    BookPMSAppointmentSuccessAction,
    ReschedulePMSAppointmentRequest,
    ReschedulePMSAppointmentResponse,
    ReschedulePMSAppointmentPendingAction,
    ReschedulePMSAppointmentFailureAction,
    ReschedulePMSAppointmentSuccessAction, PMSXcareAppointmentRequest, PMSXcareAppointmentLinkPendingAction, PMSXcareAppointmentLinkFailureAction, PMSXcareAppointmentResponse, PMSXcareAppointmentLinkSuccessAction
} from "./types";

export const getPMSAppointments = (request: GetPMSAppointmentsRequest): GetPMSAppointmentPendingAction => ({
    type: PMS_APPOINTMENTS.GET_PMS_APPOINTMENTS,
    payload: request
});
export const getPMSAppointmentsError = (error: any): GetPMSAppointmentsErrorAction => ({
    type: PMS_APPOINTMENTS.GET_PMS_APPOINTMENTS_ERROR,
    payload: error
});
export const getPMSAppointmentsSuccess = (response: GetPMSAppointmentsResponse): GetPMSPendingSuccessAction => ({
    type: PMS_APPOINTMENTS.GET_PMS_APPOINTMENTS_SUCCESS,
    payload: response
});
export const bookAppointmentPending = (request: BookPMSAppointmentRequest): BookPMSAppointmentPendingAction => ({
    type: PMS_APPOINTMENTS.BOOK_PMS_APPOINTMENT,
    payload: request
});
export const bookAppointmentFailure = (error: any): BookPMSAppointmentFailureAction => ({
    type: PMS_APPOINTMENTS.BOOK_PMS_APPOINTMENT_FAILURE,
    payload: error
});
export const bookAppointmentSuccess = (response: BookPMSAppointmentResponse): BookPMSAppointmentSuccessAction => ({
    type: PMS_APPOINTMENTS.BOOK_PMS_APPOINTMENT_SUCCESS,
    payload: response
});
export const reschedulePMSAppointmentPending = (request: ReschedulePMSAppointmentRequest): ReschedulePMSAppointmentPendingAction => ({
    type: PMS_APPOINTMENTS.RESCHEDULE_PMS_APPOINTMENT,
    payload: request
});
export const reschedulePMSAppointmentFailure = (error: any): ReschedulePMSAppointmentFailureAction => ({
    type: PMS_APPOINTMENTS.RESCHEDULE_PMS_APPOINTMENT_FAILURE,
    payload: error
});
export const reschedulePMSAppointmentSuccess = (response: ReschedulePMSAppointmentResponse): ReschedulePMSAppointmentSuccessAction => ({
    type: PMS_APPOINTMENTS.RESCHEDULE_PMS_APPOINTMENT_SUCCESS,
    payload: response
});
export const pmsxcareAppointmentLink = (request: PMSXcareAppointmentRequest): PMSXcareAppointmentLinkPendingAction => ({
    type: PMS_APPOINTMENTS.PMS_XCARE_APPOINTMENT_LINK,
    payload: request
});
export const pmsxcareAppointmentFailure = (error: any): PMSXcareAppointmentLinkFailureAction => ({
    type: PMS_APPOINTMENTS.PMS_XCARE_APPOINTMENT_LINK_FAILURE,
    payload: error
});
export const pmsxcareAppointmentSuccess = (response: PMSXcareAppointmentResponse): PMSXcareAppointmentLinkSuccessAction => ({
    type: PMS_APPOINTMENTS.PMS_XCARE_APPOINTMENT_LINK_SUCCESS,
    payload: response
});