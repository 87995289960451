import React, { useState } from 'react';
import { toast } from 'react-toastify';
import { refreshPMSProviders } from '../../../services/pms';
export const PMSProviders = ({
    pmsProviderList,
    clinicUniqueId,
    fetchPMSProviderList
} : any) =>{
    const [isRefreshing, setIsRefreshing] = useState(false);
    const handleRefreshPMSProviders = async () => {
        try {
            setIsRefreshing(true);
            const response = await refreshPMSProviders(clinicUniqueId);
            setIsRefreshing(false);
            toast.success("Refresh successfull.");
            fetchPMSProviderList();
        } catch (err) {
            setIsRefreshing(false);
            toast.error("Refresh failed.");
        }
    }
    return (
        <div className="pms-maping-sec mb-4">
            <div className="card">
                <div className="card-header bg-light py-3 px-4">
                    <div className="d-flex justify-content-between">
                        <div className="fw-medium">PMS Providers</div>
                        <div>
                            {
                                isRefreshing && 
                                <span className="spinner-border spinner-border-sm text-primary" role="status" aria-hidden="true"></span>
                            }
                            {
                                !isRefreshing &&
                                <button className="btn p-0 me-2" onClick={handleRefreshPMSProviders}>
                                    <i className="bi bi-arrow-clockwise"></i>
                                </button>
                            }
                        </div>
                    </div>
                </div>
                <div className="card-body p-4">
                    <div className="row">
                    <div className="col-12">
                            <div className="insurance-list d-flex flex-wrap">
                                {
                                    pmsProviderList.map((provider : any) =>{
                                        return(
                                            <div className="badge d-flex align-items-center text-dark border text-wrap text-start rounded-pill me-2 mb-2 ps-3 w-100" key ={provider.value}>
                                                {provider.label}
                                            </div>
                                        )
                                    })
                                }
                                        
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    );
}