import configs from "../configs/apiConfigs";
import { CommonApiOptions, METHOD_TYPE } from "../constants/api";
import { commonApi } from "../helpers/api";
import { attachHeader } from "../utils/apiHandler";

export const pmsAppointments = (request: any) => {
    let url = '';
    url = configs.API_URL + "pms/" + request.clinicUniqueId + "/appointments/?page=" + request.page +
        "&results=" + request.results;

    const options: CommonApiOptions = {
        url: url,
        apiOptions: {
            headers: attachHeader(true),
            withCredentials: true
        },
        method: METHOD_TYPE.GET
    };
    return commonApi(options);
}

export const refreshPMSStatus = (clinicId:any) => {
    let url = '';
    url = configs.API_URL + "clinic/" + clinicId + "/refreshpmsstatus/";
    const options: CommonApiOptions = {
        url: url,
        apiOptions: {
            headers: attachHeader(true),
            withCredentials: true
        },
        method: METHOD_TYPE.GET
    };
    return commonApi(options);
}

export const refreshPMSProviders = (clinicId:any) => {
    let url = '';
    url = configs.API_URL + "clinic/" + clinicId + "/refreshpmsprovider/";
    const options: CommonApiOptions = {
        url: url,
        apiOptions: {
            headers: attachHeader(true),
            withCredentials: true
        },
        method: METHOD_TYPE.GET
    };
    return commonApi(options);
}