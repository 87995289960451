import React, { Component } from 'react';
import CardInfo from './CardInfo'
import { toPlainObject } from 'lodash';
import { removeCardPendingAction } from '../../../store/billing/actions';
import { toast } from 'react-toastify';
import { IAppState } from '../../../store';
import { createSubscriptionPlan } from '../../../store/subscriptionplan/actions';
import { SubscriptionState, CreateSubscriptionPlanRequest,ClinicMyplanRequest } from '../../../store/subscriptionplan/types';
import { connect } from 'react-redux';
import { TramRounded } from '@material-ui/icons';
import _ from 'lodash';
import { checkCouponCode, removeCouponCode } from '../../services/subscription';
import { UserDataResponse } from '../../../store/userData/types';
import { fetchLoadingPending, fetchLoadingSuccess } from '../../../store/loadingIndicator/actions';
import { NavLink, Link, Redirect } from 'react-router-dom';
import { ReadMore } from '../../components/subscription/ReadMore';
import { myPlan } from '../../../store/subscriptionplan/actions';
import { RenewPlanMessage }  from '../../components/subscription/RenewPlanMessage';
import { fetchUserDataPending } from '../../../store/userData/actions';
import { currentPlan, getInvoiceList } from '../../services/subscription';
import { confirmAlert } from 'react-confirm-alert';
import { roundNumberWithRoundingFactorDecimalFix } from '../../../utils/global';
interface Props {
    data: any;
    selectedPlan: any;
    history: any;
    type: any;
    interval: any;
    selectedAddon: any;
    createSubscriptionPlan: typeof createSubscriptionPlan;
    subscriptionState: SubscriptionState;
    clinicId: any;
    userDataResponse: UserDataResponse;
    loadingStart: typeof fetchLoadingPending;
    loadingEnd: typeof fetchLoadingSuccess;
    myPlan: typeof myPlan;
    fetchUserData: typeof fetchUserDataPending;
}
interface State {
    isCheckOut: boolean;
    planType: any; // Monthly or Annualy
    planList: any;
    addons: any;
    subTotal: any;
    coupenPrice: any;
    total: any;
    paymentDetails: any;
    planPrice: any;
    isIntervalChange: boolean;
    selectedCard: any;
    selectedAddressId: any;
    billingList: any;
    promocode: any;
    couponStripId: any;
    discountAmount: any;
    promocodeApplied: boolean;
    couponId: any;
    couponDetailId: any;
    showReadMore: boolean;
    isStandardPlanCancel:boolean;
    myPlanList:any;
    isReNewPlan: boolean;
    currentPlan: any;
    monthPlans: any;
    yearPlans: any;
    newAddedAdon: any;
    selectedPlanType: any;
    tax: any
    taxName: any;
    taxPercentage: any;
    productMode: any;
    currentPlanType: any;

}
class GetPlan extends Component<Props, State>{
    constructor(props: any) {
        super(props);
        this.state = {
            isCheckOut: false,
            planType: 'Monthly',
            planList: '', addons: [],
            subTotal: 0, coupenPrice: 0, total: 0,
            paymentDetails: '', planPrice: 0, isIntervalChange: true,
            selectedCard: '', selectedAddressId: '', billingList: [], promocode: '',
            couponStripId: '', discountAmount: 0, promocodeApplied: false, couponId: '',
            couponDetailId: '',
            showReadMore: false, isStandardPlanCancel: false, myPlanList: [], isReNewPlan: false,
            currentPlan: '', monthPlans: [], yearPlans: [], newAddedAdon: [], selectedPlanType: 'Monthly',
            tax: 0, taxName: '', taxPercentage: '',productMode:'',currentPlanType:''
        }
    }
    componentDidMount() {
        console.log('datacheck',this.props.history.location);
        let totalTemp: any = 0;
        if (this.props.history.location && this.props.history.location.state === undefined && this.props.history.location.pathname === "/subscription/upgrade/plan") {
            this.props.history.push({ pathname: '/subscription/overview' });
        } else {
            if (this.props.history.location && this.props.history.location.state && this.props.history.location.state.clinicId) {
            this.currentPlanApi(this.props.history.location.state.clinicId)
            }
            if (this.props.history.location.state && this.props.history.location.state.data && this.props.history.location.state.data.month) {
                this.setState({
                    monthPlans: this.props.history.location.state.data.month.map((item: any) => { return { ...item, isRemovable: true } }),
                    productMode: this.props.history.location.state.data.month[0].product_mode,
                    currentPlanType:this.props.history.location.state.data.month[0].interval === 'month' ? "Monthly" : 'Annualy'
                });
            }
            if (this.props.history.location.state && this.props.history.location.state.data && this.props.history.location.state.data.year) {
                this.setState({
                    yearPlans: this.props.history.location.state.data.year.map((item: any) => { return { ...item, isRemovable: true } }),
                    productMode: this.props.history.location.state.data.year[0].product_mode,
                    currentPlanType:this.props.history.location.state.data.year[0].interval === 'month' ? "Monthly" : 'Annualy'
                });
            }
            if (this.props.history.location.state.type === "standard" && this.props.history.location.state.interval) {
                this.setState({ planList: this.props.history.location.state.interval === "month" ? this.props.history.location.state.data.month : this.props.history.location.state.data.year }, () => {
                    this.state.planList.map((item: any) => {
                        if (item.planType !== "custom") {
                            if (!item.isPlanActive) {
                                totalTemp = Number(item.plan_price);
                            }
                            this.setState({
                                planPrice: item.isPlanActive ? 0 : Number(item.plan_price),
                                subTotal: item.isPlanActive ? 0 : Number(item.plan_price),
                                total: item.isPlanActive ? 0 : Number(item.plan_price),
                                // isIntervalChange: false,
                                planType: this.props.history.location.state.interval === 'month' ? "Monthly" : 'Annualy',
                                selectedPlanType: this.props.history.location.state.interval === 'month' ? "Monthly" : 'Annualy',
                                taxName: this.props.history.location.state.data.tax.taxrate_display_name ? this.props.history.location.state.data.tax.taxrate_display_name : '',
                                taxPercentage: this.props.history.location.state.data.tax.tax_percentage ? this.props.history.location.state.data.tax.tax_percentage : '',
                                tax:(totalTemp*Number(this.props.history.location.state.data.tax.tax_percentage))/100
                            });
                        }
                    });
                });
            } else if (this.props.history.location && this.props.history.location.state && this.props.history.location.state.type === "basic" && this.props.history.location.state.interval) {
                console.log('in2');
                this.setState({ planList: this.props.history.location.state.interval === "month" ? this.props.history.location.state.data.month : this.props.history.location.state.data.year }, () => {
                    this.state.planList.map((item: any) => {
                        if (item.planType !== "custom") {
                            if (!item.isPlanActive) {
                                totalTemp = Number(item.plan_price);
                            }
                            this.setState({
                                planPrice: item.isPlanActive ? 0 : Number(item.plan_price),
                                subTotal: item.isPlanActive ? 0 : Number(item.plan_price),
                                total: item.isPlanActive ? 0 : Number(item.plan_price),
                                isIntervalChange: true,
                                planType: this.props.history.location.state.interval === 'month' ? "Monthly" : 'Annualy',
                                selectedPlanType:this.props.history.location.state.interval === 'month' ? "Monthly" : 'Annualy',
                                taxName: this.props.history.location.state.data.tax.taxrate_display_name ? this.props.history.location.state.data.tax.taxrate_display_name : '',
                                taxPercentage: this.props.history.location.state.data.tax.tax_percentage ? this.props.history.location.state.data.tax.tax_percentage : '',
                                tax:(totalTemp*Number(this.props.history.location.state.data.tax.tax_percentage))/100
                            });
                        }
                    });
                });
            } else {
                if (this.props.history.location.state && this.props.history.location.state.data) {
                    if (this.state.planType === 'Monthly') {
                        this.setState({ planList: this.props.history.location.state.data.month })
                        this.props.history.location.state.data.month.map((item: any) => {
                            if (item.planType !== "custom") {
                            if (!item.isPlanActive) {
                                totalTemp = Number(item.plan_price);
                            }
                                this.setState({
                                    planPrice: item.isPlanActive ? 0 : Number(item.plan_price),
                                    subTotal: item.isPlanActive ? 0 : Number(item.plan_price),
                                    total: item.isPlanActive ? 0 : Number(item.plan_price),
                                    // isIntervalChange: !item.isPlanActive
                                    isIntervalChange: true,
                                    taxName: this.props.history.location.state.data.tax.taxrate_display_name ? this.props.history.location.state.data.tax.taxrate_display_name : '',
                                    taxPercentage: this.props.history.location.state.data.tax.tax_percentage ? this.props.history.location.state.data.tax.tax_percentage : '',
                                    tax: (totalTemp * Number(this.props.history.location.state.data.tax.tax_percentage)) / 100
                                });
                            }
                        })
                    } else {
                        this.setState({ planList: this.props.history.location.state.data.year });
                        this.props.history.location.state.data.month.map((item: any) => {
                            if (item.planType !== "custom") {
                                if (!item.isPlanActive) {
                                    totalTemp = Number(item.plan_price);
                                }
                                this.setState({
                                    planPrice: item.isPlanActive ? 0 : Number(item.plan_price),
                                    subTotal: item.isPlanActive ? 0 : Number(item.plan_price),
                                    total: item.isPlanActive ? 0 : Number(item.plan_price),
                                    // isIntervalChange: !item.isPlanActive
                                    isIntervalChange: true,
                                    taxName: this.props.history.location.state.data.tax.taxrate_display_name ? this.props.history.location.state.data.tax.taxrate_display_name : '',
                                    taxPercentage: this.props.history.location.state.data.tax.tax_percentage ? this.props.history.location.state.data.tax.tax_percentage : '',
                                    tax: (totalTemp * Number(this.props.history.location.state.data.tax.tax_percentage)) / 100
                                });
                            }
                        })
                    }
                }
            }
            if (this.props.history.location.state.selectedAddon) {
                let total: any = 0;
                let isIntervalChange: boolean = this.props.history && this.props.history.location &&
                    this.props.history.location.state && this.props.history.location.state.type === "basic" ? true : false;
                this.setState({
                    addons: [...this.state.addons, { ...this.props.history.location.state.selectedAddon, isRemovable: true }],
                    // newAddedAdon:[...this.state.newAddedAdon, { ...this.props.history.location.state.selectedAddon, isRemovable: true }]
                }, () => {
                    this.state.planList.map((item: any) => {
                        if (item.planType !== 'custom' && item.isPlanActive === false) {
                            total = Number(item.plan_price);
                            // isIntervalChange = this.props.history && this.props.history.location &&
                            //  this.props.history.location.state && this.props.history.location.state.type === "basic"?true:false

                        }
                    });
                    this.setState({
                        subTotal: total + Number(this.props.history.location.state.selectedAddon.plan_price),
                        total: total + Number(this.props.history.location.state.selectedAddon.plan_price),
                        // isIntervalChange: isIntervalChange
                        isIntervalChange: true,
                        taxName: this.props.history.location.state.data.tax.taxrate_display_name ? this.props.history.location.state.data.tax.taxrate_display_name : '',
                        taxPercentage: this.props.history.location.state.data.tax.tax_percentage ? this.props.history.location.state.data.tax.tax_percentage : '',
                        tax: ((total + Number(this.props.history.location.state.selectedAddon.plan_price)) * Number(this.props.history.location.state.data.tax.tax_percentage)) / 100
                    });
                });
            }
        }
        if (this.props.userDataResponse && this.props.userDataResponse.clinics && this.props.userDataResponse.clinics.length > 0) {
            this.props.myPlan({ clinicUniqueId: this.props.userDataResponse.clinics[0].uniqueId });
        }


    }
    currentPlanApi = (clinicUniqueId: any) => {
        currentPlan(clinicUniqueId).then((success: any) => {
            if (success && success.response && success.response.data && success.response.status === 200) {
                if (success.response.data && success.response.data.status.error === false) {
                    this.setState({ currentPlan: success.response.data });
                    if(success.response.data && success.response.data.active_plans && success.response.data.active_plans.length > 0){
                        this.setState({ currentPlan: success.response.data.active_plans.map((item: any) => { return { ...item, isRemovable: false } }) });
                        console.log('month', this.state.planType);
                        if (this.state.planType === 'Monthly') {
                            let addonsTemp: any = [];
                            let standardPlan = this.state.monthPlans.find((item: any) => { return item.planType !== "custom" && item.isPlanActive === false });
                            if (standardPlan) {
                                let totalTemp: number = 0;
                                console.log('addonTem',addonsTemp);
                                if (success.response.data.active_plans && success.response.data.active_plans[0].product_mode === 'allinone') {
                                    addonsTemp = []
                                } else {
                                    addonsTemp = _.intersectionBy(this.state.monthPlans, this.state.currentPlan, 'master_plan_id');
                                    _.remove(addonsTemp, (item: any) => { return item.master_plan_id === '1' });
                                    addonsTemp = addonsTemp.map((item: any) => { return { ...item, isRemovable: false } });
                                }
                                if (this.props.history.location.state.selectedAddon) {
                                    console.log('in');
                                    
                                    let find = success.response.data.active_plans.find((item: any) => item.master_plan_id === this.props.history.location.state.selectedAddon.master_plan_id);
                                    console.log('find',find);
                                    if (!find) {
                                        addonsTemp.push({ ...this.props.history.location.state.selectedAddon, isRemovable: true });
                                    }
                                    if (success.response.data.active_plans && success.response.data.active_plans[0].product_mode === 'allinone') {
                                        addonsTemp.push({ ...this.props.history.location.state.selectedAddon, isRemovable: true });
                                    }
                                }
                                // addonsTemp.push(standardPlan);
                                if (success.response.data.active_plans && success.response.data.active_plans[0].product_mode !== 'allinone') {
                                    addonsTemp = addonsTemp.filter((item: any) => { return item.product_mode !== 'allinone' });
                                }
                                console.log('addonTempChecks',addonsTemp);
                                
                                addonsTemp = _.uniqBy(addonsTemp, 'master_plan_id');
                                totalTemp = addonsTemp.reduce((a: any, b: any) => a + Number(b.plan_price), 0);
                                totalTemp = totalTemp + Number(standardPlan.plan_price);
                                console.log('selectesAddon',addonsTemp)
                                
                                this.setState({ currentPlan: success.response.data.active_plans.map((item: any) => { return { ...item, isRemovable: false } }), addons: addonsTemp,newAddedAdon:[...this.state.newAddedAdon,{ ...this.props.history.location.state.selectedAddon, isRemovable: true }],total:totalTemp });
                            } else {
                                if (this.props.history.location.state.selectedAddon) {
                                    let find = success.response.data.active_plans.find((item: any) => item.master_plan_id === this.props.history.location.state.selectedAddon.master_plan_id);
                                    if (!find) {
                                        addonsTemp = [{ ...this.props.history.location.state.selectedAddon, isRemovable: true }];
                                    }
                                    if (success.response.data.active_plans && success.response.data.active_plans[0].product_mode === 'allinone') {
                                        addonsTemp.push({ ...this.props.history.location.state.selectedAddon, isRemovable: true });
                                    }
                                    
                                }
                                this.setState({ currentPlan: success.response.data.active_plans.map((item: any) => { return { ...item, isRemovable: false } }),newAddedAdon:addonsTemp});
                            }
                        } else if (this.state.planType === 'Annualy') {
                            let addonsTemp: any = [];
                            let standardPlan = this.state.yearPlans.find((item: any) => { return item.planType !== "custom" && item.isPlanActive === false });
                            if (standardPlan) {
                                let totalTemp: number = 0;
                                
                                console.log('addonTem', addonsTemp);
                                if (success.response.data.active_plans && success.response.data.active_plans[0].product_mode === 'allinone') {
                                    addonsTemp = []
                                } else {
                                    addonsTemp = _.intersectionBy(this.state.yearPlans, this.state.currentPlan, 'master_plan_id');
                                    _.remove(addonsTemp, (item: any) => { return item.master_plan_id === '1' });
                                }
                                addonsTemp = addonsTemp.map((item: any) => { return { ...item, isRemovable: false } });
                                if (this.props.history.location.state.selectedAddon) {
                                    let find = success.response.data.active_plans.find((item: any) => item.master_plan_id === this.props.history.location.state.selectedAddon.master_plan_id);
                                    if (!find) {
                                        addonsTemp.push({ ...this.props.history.location.state.selectedAddon, isRemovable: true });
                                    }
                                    if (success.response.data.active_plans && success.response.data.active_plans[0].product_mode === 'allinone') {
                                        addonsTemp.push({ ...this.props.history.location.state.selectedAddon, isRemovable: true });
                                    }
                                }
                                // addonsTemp.push(standardPlan);
                                if (success.response.data.active_plans && success.response.data.active_plans[0].product_mode !== 'allinone') {
                                    addonsTemp = addonsTemp.filter((item: any) => { return item.product_mode !== 'allinone' });
                                }
                                // addonsTemp = addonsTemp.filter((item: any) => { return item.product_mode !== 'allinone' });
                                addonsTemp = _.uniqBy(addonsTemp, 'master_plan_id');
                                totalTemp = addonsTemp.reduce((a: any, b: any) => a + Number(b.plan_price), 0);
                                totalTemp = totalTemp + Number(standardPlan.plan_price);
                                this.setState({ currentPlan: success.response.data.active_plans.map((item: any) => { return { ...item, isRemovable: false } }), addons: addonsTemp, newAddedAdon: [...this.state.newAddedAdon, { ...this.props.history.location.state.selectedAddon, isRemovable: true }], total:totalTemp });
                            } else {
                                 if (this.props.history.location.state.selectedAddon) {
                                    let find = success.response.data.active_plans.find((item: any) => item.master_plan_id === this.props.history.location.state.selectedAddon.master_plan_id);
                                    if (!find) {
                                        addonsTemp = [{ ...this.props.history.location.state.selectedAddon, isRemovable: true }];
                                     }
                                     if (success.response.data.active_plans && success.response.data.active_plans[0].product_mode === 'allinone') {
                                        addonsTemp.push({ ...this.props.history.location.state.selectedAddon, isRemovable: true });
                                    }
                                }
                                this.setState({ currentPlan: success.response.data.active_plans.map((item: any) => { return { ...item, isRemovable: false } }),newAddedAdon:addonsTemp });
                            }
                        }
                    }
                    this.props.loadingEnd(false);
                } else {
                    // Error Msg
                    if (success.response.data && success.response.data.status.error === true) {
                        toast.error(success.response.data.status.msg)
                    }
                    this.props.loadingEnd(false);
                }
            }
        }).catch((err) => {
            this.props.loadingEnd(false);
            return '';
        });
    }

    componentDidUpdate(prevProps: Props, prevState: State) {
        if (this.props.history.location && this.props.history.location.state === undefined && this.props.history.location.pathname === "/subscription/upgrade/plan") {
            this.props.history.push({ pathname: '/subscription/overview' });
        }
        //  if (this.props.history.location && this.props.history.location.state && this.props.history.location.state.clinicId && this.props.history.location.state.clinicId !== prevProps.history.location.state.clinicId) {
        //     this.currentPlanApi(this.props.history.location.state.clinicId)
        // }
        if (this.props.history.location.state && this.props.history.location.state.data && this.props.history.location.state.data.month && this.props.history.location.state.data.month !== prevProps.history.location.state.data.month) {
            this.setState({
                monthPlans: this.props.history.location.state.data.month.map((item: any) => { return { ...item, isRemovable: true } }),
                productMode: this.props.history.location.state.data.month[0].product_mode,
                currentPlanType:this.props.history.location.state.data.month[0].interval === 'month' ? "Monthly" : 'Annualy'
            });
            }
            if (this.props.history.location.state && this.props.history.location.state.data && this.props.history.location.state.data.year && this.props.history.location.state.data.year !== prevProps.history.location.state.data.year) {
                this.setState({
                    yearPlans: this.props.history.location.state.data.year.map((item: any) => { return { ...item, isRemovable: true } }),
                    productMode: this.props.history.location.state.data.year[0].product_mode,
                currentPlanType:this.props.history.location.state.data.year[0].interval === 'month' ? "Monthly" : 'Annualy'
                });
            }
        if (this.props.data !== prevProps.data) {
            if (this.state.planType === 'Monthly') {
                this.setState({ planList: this.props.history.location.state.data.month });
                this.props.history.location.state.data.month.map((item: any) => {
                    if (item.planType !== "custom") {
                        this.setState({
                            planPrice: item.isPlanActive ? 0 : Number(item.plan_price),
                            subTotal: item.isPlanActive ? 0 : Number(item.plan_price),
                            total: item.isPlanActive ? 0 : Number(item.plan_price)
                        });
                    }
                })
            } else {
                this.setState({ planList: this.props.history.location.state.data.year });
                this.props.history.location.state.data.year.map((item: any) => {
                    if (item.planType !== "custom") {
                        this.setState({
                            planPrice: item.isPlanActive ? 0 : Number(item.plan_price),
                            subTotal: item.isPlanActive ? 0 : Number(item.plan_price),
                            total: item.isPlanActive ? 0 : Number(item.plan_price)
                        });
                    }
                })
            }
        }
        if (this.props.subscriptionState !== prevProps.subscriptionState) {
            if (this.props.subscriptionState.error) {
                toast.error(this.props.subscriptionState.error);
            } else if (this.props.subscriptionState.pending === false && this.props.subscriptionState.createSubscriptionPlanResponse !== prevProps.subscriptionState.createSubscriptionPlanResponse &&
                this.props.subscriptionState.createSubscriptionPlanResponse && this.props.subscriptionState.createSubscriptionPlanResponse.status.error === false) {
                this.props.fetchUserData({});
                this.props.history.push('/subscription/overview');
                toast.success(this.props.subscriptionState.createSubscriptionPlanResponse.status.msg)
            } else if (this.props.subscriptionState.clinicMyplanResponce !== prevProps.subscriptionState.clinicMyplanResponce &&
                this.props.subscriptionState.clinicMyplanResponce && this.props.subscriptionState.clinicMyplanResponce.status.error === false) {
                this.setState({
                    myPlanList: this.props.subscriptionState.clinicMyplanResponce.plans ? this.props.subscriptionState.clinicMyplanResponce.plans : [],
                }, () => {
                    for (let i = 0; i < this.state.myPlanList.length; i++) {
                        if (this.state.myPlanList[i].planName === "Standard Plan" && this.state.myPlanList[i].isCancelledPlan === true) {
                            this.setState({ isStandardPlanCancel: true });
                        }
                    }
        
                })
            }
        }
    }
    addAddon = (value: any) => {
        
        this.setState({
            addons: [...this.state.addons, {...value,isRemovable:true}],
            subTotal: Number(this.state.subTotal) + Number(value.plan_price),
            total: Number(this.state.total) + Number(value.plan_price),
            newAddedAdon:[...this.state.newAddedAdon,value]
        });

    }
    removeAddon = (value: any, i: any) => {
        let addon = this.state.addons
        let newAddon = this.state.newAddedAdon;
        let findIndex = this.state.addons.findIndex((item: any) => { return item.master_plan_id === value.master_plan_id });
        let findIndexNew = this.state.newAddedAdon.findIndex((item: any) => { return item.master_plan_id === value.master_plan_id });
        addon.splice(findIndex, 1);
        newAddon.splice(findIndexNew, 1);
        this.setState({
            addons: addon,
            subTotal: this.state.subTotal - Number(value.plan_price),
            total: this.state.total - Number(value.plan_price),
            newAddedAdon:newAddon
        });
    }
    handleProcessAndCheckout = () => {
        let obj: any = {}, taxTemp: any = 0,totalTemp:any=0;
        taxTemp = (this.state.total * Number(this.props.history.location.state.data.tax.tax_percentage)) / 100;
        taxTemp = parseFloat(taxTemp).toFixed(2);
        totalTemp = Number(this.state.total) + Number(taxTemp);
        totalTemp = parseFloat(totalTemp).toFixed(2);
        Object.assign(obj, { subTotal: this.state.subTotal, total: this.state.total, selectedPlan: this.props.history.location.state.selectedPlan });
        this.setState({
            isCheckOut: true, paymentDetails: obj, isIntervalChange: false,
            taxName: this.props.history.location.state.data.tax.taxrate_display_name ? this.props.history.location.state.data.tax.taxrate_display_name : '',
            taxPercentage: this.props.history.location.state.data.tax.tax_percentage ? this.props.history.location.state.data.tax.tax_percentage : '',
            tax: taxTemp,
            total:totalTemp
        });
    }
    createSubscription() {
        // if (this.props.location.data) {
        // let plans = this.props.location.data.plans.map((item: any) => {
        //     return { item_price: item.plan_price_id, plan_id: item.id, plan_type: item.planType }
        // })
        // let data = {
        //     plans: plans,
        //     card_id: this.state.selectedCard
        // };
        // if (this.state.promocodeApplied) {
        //     Object.assign(data, { is_coupon: true, coupon_code: this.state.promocode, discount_amount: this.state.promoCodeDetail.discount_amount });
        // }
        // this.props.createSubscriptionPlan({
        //     clinicUniqueId: this.props.location.data.clinicUniqueId, data: data
        // });
        // }
        let mainPlan: any = {};
        this.state.planList.map((item: any) => {
            if (item.planType !== "custom" && item.isPlanActive === false) {
                mainPlan = item;
            }
        });
        let addon = _.uniq(this.state.addons);
        if (mainPlan && mainPlan.id) {
            // addon.push(mainPlan);
            addon.splice(0,0,mainPlan);
        }
        let plans: any = [];
        plans = addon.map((item: any) => {
            return { item_price: item.plan_price_id, plan_id: item.id, plan_type: item.planType,master_plan_id:item.master_plan_id,product_mode:item.product_mode }
        });
        if (mainPlan && mainPlan.product_mode === 'allinone') {
            plans = [];
            this.state.planList.map((item: any) => {
                if (item.planType !== "custom" && item.isPlanActive === false) {
                    plans.push({item_price: item.plan_price_id, plan_id: item.id, plan_type: item.planType, master_plan_id: item.master_plan_id, product_mode: item.product_mode })
                }
            });
            this.state.planList.map((item: any) => {
                if (item.planType === "custom") {
                    plans.push({ item_price: item.plan_price_id, plan_id: item.id, plan_type: item.planType, master_plan_id: item.master_plan_id, product_mode: item.product_mode })
                }
            });
        }
        if (this.state.selectedCard === "") {
            toast.warn('Please select credit card');
            return;
        }
        if (this.state.selectedAddressId === '') {
            toast.warn('Please select Bill Address');
            return;
        }
        let data = {
            plans: _.uniqBy(plans,'master_plan_id'),
            card_id: this.state.selectedCard,
            billingAddressId: this.state.selectedAddressId,
            stripeCouponCode: '',
            discount_amount: '',
            stripeCouponId: '',
            tax_percentage: this.state.taxPercentage,
            tax_txsId:this.props.history.location.state.data.tax.tax_txr_id,
            total_tax: this.state.tax
        }
        if (this.state.promocodeApplied) {
            Object.assign(data, { stripeCouponCode: this.state.couponStripId, discount_amount: this.state.discountAmount, stripeCouponId: this.state.couponId });
        }
        // console.log('data', data);
        // return;
        
        this.props.createSubscriptionPlan({
            clinicUniqueId: this.props.history.location.state.clinicId, data: data
        });
    }
    selectedCard = (Id: any) => {
        this.setState({ selectedCard: Id });
    }
    selectedBillInfo = (Id: any) => {
        this.setState({ selectedAddressId: Id });
    }
    clickMonth = () => {
        let totalTemp:number = 0, addonsTemp: any = [];
        // if (this.state.planType === "Yearly" && this.state.currentPlan && this.state.currentPlan.length > 0) {
        //     addonsTemp = _.intersectionBy(this.state.monthPlans, this.state.currentPlan.active_plans, 'master_plan_id');
        //     addonsTemp = addonsTemp.map((item: any) => { return { ...item, isRemovable: false } });
        //     addonsTemp.push(..._.intersectionBy(this.state.monthPlans, this.state.addons, 'master_plan_id'));
        //     totalTemp = addonsTemp.reduce((a: any, b: any) => a + Number(b.plan_price), 0);
        // }
        // if (this.state.addons && this.state.addons.length > 0 && this.state.planType !== "Yearly" ) {
        //     addonsTemp = _.intersectionBy(this.state.monthPlans, this.state.addons, 'master_plan_id');
        //     addonsTemp =_.xorBy(addonsTemp, this.state.currentPlan, 'master_plan_id');
        //     totalTemp = addonsTemp.reduce((a: any, b: any) => a + Number(b.plan_price), 0);
        // }
        // this.state.monthPlans.map((item: any) => {
        //     if (item.planType !== "custom" && item.isPlanActive) {
        //         this.setState({
        //             planPrice: 0,
        //             subTotal: totalTemp,
        //             total: totalTemp,
        //             planType: 'Monthly', planList: this.state.monthPlans,
        //             addons: addonsTemp
        //         });
        //     } else if (item.planType !== "custom" && item.isPlanActive === false) {
        //         this.setState({
        //             planPrice: Number(item.plan_price),
        //             subTotal: Number(item.plan_price),
        //             total: Number(item.plan_price),
        //             planType: 'Monthly', planList: this.state.monthPlans,
        //             addons: addonsTemp
        //         });
        //     }
        // });
       console.log('checkAddon',this.state.newAddedAdon);
       
        let isStandardPlanActive = this.state.monthPlans.find((item: any) => { return item.planType !== "custom" && item.isPlanActive });
        let standardPlan = this.state.monthPlans.find((item: any) => { return item.planType !== "custom" && item.isPlanActive === false });
        if (this.state.currentPlan && this.state.currentPlan[0].product_mode !== 'allinone') {
            addonsTemp = _.intersectionBy(this.state.monthPlans, this.state.addons, 'master_plan_id');
        } 
        addonsTemp = addonsTemp.filter((item: any) => { return item.product_mode !== 'allinone' });
        totalTemp = addonsTemp.reduce((a: any, b: any) => a + Number(b.plan_price), 0);

        if (standardPlan) {
            totalTemp = Number(standardPlan.plan_price) + totalTemp;
        }
        
        if (this.state.currentPlan && this.state.currentPlan.length > 0 && this.state.currentPlan[0].plan_type === 'basic') {
            // addonsTemp = _.intersectionBy(this.state.monthPlans, this.state.addons, 'master_plan_id');
            // totalTemp = addonsTemp.reduce((a: any, b: any) => a + Number(b.plan_price), 0);
            // totalTemp = Number(standardPlan.plan_price) + totalTemp;
            if (standardPlan) {
                if (this.state.discountAmount && totalTemp > 0) {
                    this.checkCoupon(totalTemp,addonsTemp,'Monthly');
                } else {
                    this.setState({
                    total:totalTemp,
                    subTotal: totalTemp,
                    planList: this.state.monthPlans,
                    // planType: 'Monthly',
                        addons: addonsTemp,
                    selectedPlanType:'Monthly'
                });
                }
                
            }
        } else if (this.state.planType === "Monthly" || this.state.planType === "Annualy") {
            if (this.state.planType === "Annualy") {
                console.log('here',this.state.newAddedAdon);
                if (this.state.currentPlan && this.state.currentPlan[0].product_mode !== 'allinone') {
                    addonsTemp = _.intersectionBy(this.state.monthPlans, this.state.currentPlan, 'master_plan_id');
                    addonsTemp = addonsTemp.map((item: any) => { return { ...item, isRemovable: false } });
                } 
                // addonsTemp = _.intersectionBy(this.state.monthPlans, this.state.currentPlan, 'master_plan_id');
                addonsTemp.push(..._.intersectionBy(this.state.monthPlans, this.state.newAddedAdon, 'master_plan_id'));
                addonsTemp = _.uniqBy(addonsTemp, 'master_plan_id');
                // addonsTemp.push(..._.intersectionBy(this.state.monthPlans, this.state.addons, 'master_plan_id'));
                totalTemp = addonsTemp.reduce((a: any, b: any) => a + Number(b.plan_price), 0);
                if (this.state.currentPlan && this.state.currentPlan[0].product_mode === 'allinone' && standardPlan) {
                    totalTemp = Number(standardPlan.plan_price) + totalTemp;
                }
            } else if (this.state.planType === "Monthly") {
                if (standardPlan) {
                    let currentAddon: any = [];
                    if (this.state.currentPlan && this.state.currentPlan[0].product_mode !== 'allinone') {
                        currentAddon = _.intersectionBy(this.state.monthPlans, this.state.currentPlan, 'master_plan_id');
                        currentAddon = currentAddon.map((item: any) => { return { ...item, isRemovable: false } });
                    } 
                    addonsTemp = currentAddon;
                    addonsTemp.push(standardPlan);
                    addonsTemp.push(..._.intersectionBy(this.state.monthPlans, this.state.newAddedAdon, 'master_plan_id'));
                    addonsTemp = _.uniqBy(addonsTemp, 'master_plan_id');
                } else {
                    addonsTemp = _.intersectionBy(this.state.monthPlans, this.state.newAddedAdon, 'master_plan_id');
                }
                console.log('here',this.state.newAddedAdon);
                
                totalTemp = addonsTemp.reduce((a: any, b: any) => a + Number(b.plan_price), 0);
                
            }
            if (this.state.discountAmount && totalTemp > 0) {
                addonsTemp = addonsTemp.filter((item: any) => { return item.product_mode !== 'allinone' });
                this.checkCoupon(totalTemp, addonsTemp, 'Monthly');
            } else {
                addonsTemp = addonsTemp.filter((item: any) => { return item.product_mode !== 'allinone' });
                this.setState({
                    total: totalTemp,
                    subTotal: totalTemp,
                    planList: this.state.monthPlans,
                    // planType: 'Monthly',
                    addons: addonsTemp,
                    selectedPlanType:'Monthly'
                });
            }
            // if (isStandardPlanActive) {
            //     this.setState({
            //         total: totalTemp ,
            //         subTotal: totalTemp,
            //         planList: this.state.monthPlans,
            //         // planType: 'Monthly',
            //         addons: addonsTemp
            //     });
            // } else {
            //     this.setState({
            //         total: totalTemp ,
            //         subTotal: totalTemp,
            //         planList: this.state.monthPlans,
            //         // planType: 'Monthly',
            //         addons: addonsTemp
            //     });
            // }
            
        }
    }
    clickYear = () => {
        let totalTemp: number = 0, addonsTemp: any = [];
        // if (this.state.planType === "Monthly" && this.state.currentPlan && this.state.currentPlan.length > 0) {
        //     addonsTemp = _.intersectionBy(this.state.yearPlans, this.state.currentPlan, 'master_plan_id');
        //     addonsTemp = addonsTemp.map((item: any) => { return { ...item, isRemovable: false } });
        //     addonsTemp.push(..._.intersectionBy(this.state.yearPlans, this.state.addons, 'master_plan_id'));
        //     totalTemp = addonsTemp.reduce((a: any, b: any) => a + Number(b.plan_price), 0);
        // }
        // if (this.state.addons && this.state.addons.length > 0 && this.state.planType !== "Monthly") {
        //     addonsTemp = _.intersectionBy(this.state.yearPlans, this.state.addons, 'master_plan_id');
        //     addonsTemp =_.xorBy(addonsTemp, this.state.currentPlan, 'master_plan_id');
        //     totalTemp = addonsTemp.reduce((a:any, b:any) => a + Number(b.plan_price), 0);
        // }
        
        
        // this.state.yearPlans.map((item: any) => {
        //     if (item.planType !== "custom" && item.isPlanActive) {
        //         this.setState({
        //             planPrice: 0,
        //             subTotal: totalTemp,
        //             total: totalTemp,
        //             planType: 'Annualy', planList: this.state.yearPlans,
        //             addons: addonsTemp
        //         });
        //     } else if (item.planType !== "custom" && item.isPlanActive === false) {
        //         this.setState({
        //             planPrice: Number(item.plan_price),
        //             subTotal: Number(totalTemp),
        //             total: Number(totalTemp),
        //             planType: 'Annualy', planList: this.state.yearPlans,
        //             addons: addonsTemp
        //         });
        //     }
        // });
        let isStandardPlanActive = this.state.yearPlans.find((item: any) => { return item.planType !== "custom" && item.isPlanActive });
        let standardPlan = this.state.yearPlans.find((item: any) => { return item.planType !== "custom" && item.isPlanActive === false });
        console.log('addons',this.state.addons);
         if (this.state.currentPlan && this.state.currentPlan[0].product_mode !== 'allinone') {
            addonsTemp = _.intersectionBy(this.state.yearPlans, this.state.addons, 'master_plan_id');
        }
        // addonsTemp = _.intersectionBy(this.state.yearPlans, this.state.addons, 'master_plan_id');
        totalTemp = addonsTemp.reduce((a: any, b: any) => a + Number(b.plan_price), 0);
        if (standardPlan) {
            totalTemp = Number(standardPlan.plan_price) + totalTemp;
        }
        if (this.state.currentPlan && this.state.currentPlan.length > 0 && this.state.currentPlan[0].plan_type === 'basic') {
            // addonsTemp = _.intersectionBy(this.state.yearPlans, this.state.addons, 'master_plan_id');
            // totalTemp = addonsTemp.reduce((a: any, b: any) => a + Number(b.plan_price), 0);
            // totalTemp = Number(standardPlan.plan_price) + totalTemp;
            if (standardPlan) {
                if (this.state.discountAmount && totalTemp > 0) {
                    this.checkCoupon(totalTemp, addonsTemp, 'Annualy');
                } else {
                    addonsTemp = addonsTemp.filter((item: any) => { return item.product_mode !== 'allinone' });
                    this.setState({
                        total: totalTemp,
                        subTotal: totalTemp,
                        planList: this.state.yearPlans,
                        // planType: 'Monthly',
                        addons: addonsTemp,
                        selectedPlanType:'Annualy'
                    });
                }
                // this.setState({
                //     total: totalTemp,
                //     subTotal: totalTemp,
                //     planList: this.state.yearPlans,
                //     // planType: 'Annualy',
                //     addons: addonsTemp
                // });
            }
        } else if (this.state.planType === "Monthly" || this.state.planType === "Annualy") {
            
            if (this.state.planType === "Monthly") {
                console.log('here2', this.state.newAddedAdon);
                 if (this.state.currentPlan && this.state.currentPlan[0].product_mode !== 'allinone') {
                    addonsTemp = _.intersectionBy(this.state.yearPlans, this.state.currentPlan, 'master_plan_id');
                    addonsTemp = addonsTemp.map((item: any) => { return { ...item, isRemovable: false } });
                }
                addonsTemp.push(..._.intersectionBy(this.state.yearPlans, this.state.newAddedAdon, 'master_plan_id'));
                addonsTemp = _.uniqBy(addonsTemp, 'master_plan_id');
                // addonsTemp.push(..._.intersectionBy(this.state.monthPlans, this.state.addons, 'master_plan_id'));
                totalTemp = addonsTemp.reduce((a: any, b: any) => a + Number(b.plan_price), 0);
                if (this.state.currentPlan && this.state.currentPlan[0].product_mode === 'allinone' && standardPlan) {
                    totalTemp = Number(standardPlan.plan_price) + totalTemp;
                }
            } else if (this.state.planType === "Annualy") {
                if (standardPlan) {
                    let currentAddon: any = [];
                    if (this.state.currentPlan && this.state.currentPlan[0].product_mode !== 'allinone') {
                        currentAddon = _.intersectionBy(this.state.yearPlans, this.state.currentPlan, 'master_plan_id');
                        currentAddon = currentAddon.map((item: any) => { return { ...item, isRemovable: false } });
                    }
                    addonsTemp = currentAddon;
                    addonsTemp.push(standardPlan);
                    addonsTemp.push(..._.intersectionBy(this.state.yearPlans, this.state.newAddedAdon, 'master_plan_id'));
                    addonsTemp = _.uniqBy(addonsTemp, 'master_plan_id');
                } else {
                    addonsTemp = _.intersectionBy(this.state.yearPlans, this.state.newAddedAdon, 'master_plan_id');
                }
                totalTemp = addonsTemp.reduce((a: any, b: any) => a + Number(b.plan_price), 0);
                if (this.state.currentPlan && this.state.currentPlan[0].product_mode === 'allinone' && standardPlan) {
                    totalTemp = Number(standardPlan.plan_price) + totalTemp;
                 }
            }
            if (this.state.discountAmount && totalTemp > 0) {
                addonsTemp = addonsTemp.filter((item: any) => { return item.product_mode !== 'allinone' });
                this.checkCoupon(totalTemp, addonsTemp, 'Annualy');
            } else {
                addonsTemp = addonsTemp.filter((item: any) => { return item.product_mode !== 'allinone' });
                this.setState({
                    total: totalTemp,
                    subTotal: totalTemp,
                    planList: this.state.yearPlans,
                    // planType: 'Monthly',
                    addons: addonsTemp,
                    selectedPlanType:'Annualy'
                });
            }
            // if (isStandardPlanActive) {
            //     this.setState({
            //         total: totalTemp,
            //         subTotal: totalTemp,
            //         planList: this.state.yearPlans,
            //         // planType: 'Annualy',
            //         addons: addonsTemp
            //     });
            // } else {
            //     this.setState({
            //         total: totalTemp,
            //         subTotal: totalTemp,
            //         planList: this.state.yearPlans,
            //         // planType: 'Annualy',
            //         addons: addonsTemp
            //     });
            // }
            
        }
    }
    billingList = (billingList: any) => {
        if (billingList && billingList.length > 0) {
            billingList.map((item: any) => {
                if (item.default_status === "active") {
                    this.setState({ selectedAddressId: item.id, billingList: billingList });
                }
            })
        }
    }

    checkCoupon = (sbTotal?:any,addonTemp?:any,type?:any) => {
        // this.props.loadingStart();
        let mainPlan: any = {};
        if (type === 'Monthly') {
            this.state.monthPlans.map((item: any) => {
                if (item.planType !== "custom" && item.isPlanActive === false) {
                    mainPlan = item;
                }
            });
        } else if (type === 'Annualy') {
            this.state.yearPlans.map((item: any) => {
                if (item.planType !== "custom" && item.isPlanActive === false) {
                    mainPlan = item;
                }
            });
        } else {
            
            this.state.planList.map((item: any) => {
                if (item.planType !== "custom" && item.isPlanActive === false) {
                    mainPlan = item;
                }
            });
        }
        
        let addon = _.uniq(addonTemp?addonTemp:this.state.addons);
        if (mainPlan && mainPlan.id) {
            addon.push(mainPlan);
        }
        let plans = addon.map((item: any) => {
            return { plan_id: item.id }
        });
        plans = _.uniqBy(plans, 'plan_id');
        const data = {
            coupon: this.state.promocode,
            amount: sbTotal?roundNumberWithRoundingFactorDecimalFix(sbTotal,2):roundNumberWithRoundingFactorDecimalFix(this.state.subTotal,2),
            plans: plans
        }
        let clinicUniqueId = this.props.userDataResponse.clinics[0].uniqueId;
        this.props.loadingStart();
        checkCouponCode(clinicUniqueId, data).then((success: any) => {
            if (success && success.response && success.response.data && success.response.status === 200) {
                if (success.response.data && success.response.data.status.error === false) {
                    let totalTemp: any = success.response.data.couponapplied.after_discount_amount;
                    let taxTemp: any = 0;
                    taxTemp = (success.response.data.couponapplied.after_discount_amount * this.props.history.location.state.data.tax.tax_percentage) / 100;
                    taxTemp = parseFloat(taxTemp).toFixed(2);
                    this.setState({
                        discountAmount: parseFloat(success.response.data.couponapplied.discount_amount).toFixed(2),
                        total: Number(parseFloat(success.response.data.couponapplied.after_discount_amount).toFixed(2)) + Number(taxTemp),
                        couponStripId: success.response.data.couponapplied.coupon_stripeData,
                        promocodeApplied: true,
                        couponId: success.response.data.couponapplied.coupon_id,
                        couponDetailId: success.response.data.couponapplied.coupon_detail_id,
                        subTotal: sbTotal ? sbTotal : this.state.subTotal,
                        planList: type === 'Monthly' ? this.state.monthPlans :type === 'Annualy'? this.state.yearPlans:this.state.planList,
                        addons: addonTemp ? addonTemp : this.state.addons,
                        tax:taxTemp
                    });
                    if(!sbTotal){
                        toast.success(success.response.data.status.msg)
                    }
                    this.props.loadingEnd(false);
                } else {
                    // Error Msg
                    if (success.response.data && success.response.data.status.error === true) {
                        toast.error(success.response.data.status.msg)
                    }
                    this.props.loadingEnd(false);
                }
            }
        }).catch((err) => {
            this.props.loadingEnd(false);
            return '';
        });
    }
    removeCoupon = () => {
        this.props.loadingStart();
        let clinicUniqueId = this.props.userDataResponse.clinics[0].uniqueId;
        let mainPlan: any = {};
        this.state.planList.map((item: any) => {
            if (item.planType !== "custom" && item.isPlanActive === false) {
                mainPlan = item;
            }
        });
        let addon = _.uniq(this.state.addons);
        if (mainPlan && mainPlan.id) {
            addon.push(mainPlan);
        }
        let plans = addon.map((item: any) => {
            return { plan_id: item.id }
        });
        plans = _.uniqBy(plans, 'plan_id');
        let data = {
            coupon_detail_id: this.state.couponDetailId,
            plans: plans,
            amount: roundNumberWithRoundingFactorDecimalFix(this.state.subTotal,2),
            main_total: Number(this.state.total) - Number(this.state.tax),
            discount_amount: this.state.discountAmount
        }
        removeCouponCode(clinicUniqueId, data).then((success: any) => {
            if (success && success.response && success.response.data && success.response.status === 200) {
                if (success.response.data && success.response.data.status.error === false) {
                    let totalTemp: any = success.response.data.main_total;
                    let taxTemp: any = 0;
                    taxTemp = (success.response.data.main_total * this.props.history.location.state.data.tax.tax_percentage) / 100;
                    taxTemp = parseFloat(taxTemp).toFixed(2);
                    this.setState({
                        discountAmount: success.response.data.discount_amount,
                        total: Number(parseFloat(success.response.data.main_total).toFixed(2)) + Number(taxTemp),
                        promocodeApplied: false,
                        promocode: '',
                        tax:taxTemp
                    });
                    toast.success('Coupon remove successfully.')
                    this.props.loadingEnd(false);
                } else {
                    // Error Msg
                    if (success.response.data && success.response.data.status.error === true) {
                        toast.error(success.response.data.status.msg)
                    }
                    this.props.loadingEnd(false);
                }
            }
        }).catch((err) => {
            this.props.loadingEnd(false);
            return '';
        });
    }

    paymentConfirmation = (text:any) => {
        confirmAlert({
            title: '',
            message: text,
            buttons: [
                {
                    label: 'Confirm',
                    onClick: () => {
                        this.createSubscription();
                    }
                },
                {
                    label: 'Cancel',
                    onClick: () => {

                    }
                }
            ]
        });
    }

    handleCreateSubscription = () => {
        let text: String = "";
        if (this.state.currentPlan[0].interval === 'month' && this.state.selectedPlanType === "Monthly") {
            let standardPlan = this.state.monthPlans.find((item: any) => { return item.planType !== "custom" && item.isPlanActive === false });
            let currentStandardPlan = this.state.currentPlan.find((item: any) => { return item.plan_type !== "custom" });
            if (standardPlan && currentStandardPlan && standardPlan.plan_price > currentStandardPlan.plan_price) {
                text = "We will upgrade your plan, please confirm.";
            } else if (standardPlan && currentStandardPlan && standardPlan.plan_price < currentStandardPlan.plan_price) {
                text = "We will downgrade your plan, please confirm. You will be charged pro-rated basis and your refund will be available in account credit. You can use your credit against any future payments.";
            } else {
                text = "We will upgrade your plan, please confirm.";
            }
        } else if (this.state.currentPlan[0].interval === 'year' && this.state.selectedPlanType === "Annualy") {
             let standardPlan = this.state.yearPlans.find((item: any) => { return item.planType !== "custom" && item.isPlanActive === false });
            let currentStandardPlan = this.state.currentPlan.find((item: any) => { return item.plan_type !== "custom" });
            if (standardPlan && currentStandardPlan && standardPlan.plan_price > currentStandardPlan.plan_price) {
                text = "We will upgrade your plan, please confirm.";
            } else if (standardPlan && currentStandardPlan && standardPlan.plan_price < currentStandardPlan.plan_price) {
                text = "We will downgrade your plan, please confirm. You will be charged pro-rated basis and your refund will be available in account credit. You can use your credit against any future payments.";
            } else {
                text = "We will upgrade your plan, please confirm.";
            }
        } else if (this.state.currentPlan[0].interval === 'month' && this.state.selectedPlanType === "Annualy") {
            text = "We will upgrade your plan, please confirm.";
        } else if (this.state.currentPlan[0].interval === 'year' && this.state.selectedPlanType === "Monthly") {
            text = "We will downgrade your plan, please confirm. You will be charged pro-rated basis and your refund will be available in account credit. You can use your credit against any future payments.";
        }

        this.paymentConfirmation(text);
    }
    render() {
        let planPrice: any;
        return (
            <section className="subscription-sec py-5">

                <div className="backtohome position-fixed">
                    <NavLink to="/subscription/plans" type="button" className="btn btn-sm btn-outline-secondary rounded-pill px-3">
                        <i className="bi bi-arrow-left-short"></i> back to plan
                    </NavLink>
                </div>

                <div className="container max-width-center">
                    <div className="row justify-content-center">
                        <div className="col-12">
                            <div className="step-wizard">
                                <ul className="d-flex">
                                    <li className="active">
                                        <div id="step1" className="step-box">
                                            <div className="step"><i className="bi bi-bookmark-check"></i></div>
                                            <div className="title">Standard plan</div>
                                        </div>
                                    </li>
                                    <li className="active">
                                        <div id="step2" className="step-box">
                                            <div className="step"><i className="bi bi-ui-checks-grid"></i></div>
                                            <div className="title">Select addons</div>
                                        </div>
                                    </li>
                                    <li className={this.state.isCheckOut ? "active" : ""}>
                                        <div id="step3" className="step-box">
                                            <div className="step"><i className="bi bi-credit-card-2-front"></i></div>
                                            <div className="title">Process to checkout</div>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        {!this.state.isCheckOut && this.state.planList && this.state.planList.length > 0 &&
                            <div className="col-8">
                                {
                                    this.state.planList.map((item: any) => {
                                        if (item.planType === "basic" || item.planType === "standard") {
                                            planPrice = item.plan_price;
                                            return (
                                                <div key={item.id} className="border p-4 border-1 rounded bg-light custom-card my-4 default-card">
                                                    <div className="d-flex justify-content-between">
                                                        <div>
                                                            {/* <small className="fw-medium text-muted">{item.title}</small> */}
                                                            <div className="info-box fs-5 fw-medium">{item.name}</div>
                                                        </div>
                                                        <div className="align-self-center">
                                                            <div className="d-flex">
                                                                <div className="pr-2 price-tag">
                                                                    <span className="fs-6 pr-1 position-relative">$</span>
                                                                    <span className="fw-medium fs-1">{item.plan_price}</span>
                                                                    /Per {item.plan_interval}
                                                                </div>
                                                                {/* <small className="align-self-center">Per clinic <br />Per {item.plan_interval}</small> */}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="d-flex justify-content-between mt-4">
                                                        {(item.product_mode !== 'allinone') && <div className="text-muted small"><i className="bi bi-info-circle"></i> Choose addons below</div>}
                                                        <div className="badge rounded-pill bg-warning">Save 15% on yearly plan</div>
                                                    </div>
                                                </div>
                                            )
                                        }
                                    })
                                }
                                {
                                    (this.state.planList.map((item: any) => {
                                        let findIndex = this.state.addons.findIndex((value: any) => { return value.id === item.id });
                                        if (item.planType === 'custom')
                                            return (
                                                <div className=" border p-4 border-1 rounded bg-white custom-card my-4">
                                                    <div className="d-flex justify-content-between">
                                                        <div>
                                                            <div className="d-flex mb-2">
                                                                <div className="avatar avatar-lg rounded-circle me-3">
                                                                    <img alt="" src={item.img_url + item.img_name} />
                                                                </div>
                                                                <div>
                                                                    {/* <small className="fw-medium text-muted">{item.title}</small> */}
                                                                    <div className="info-box fs-5 fw-medium">{item.name}</div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="">
                                                            <div className="d-flex">
                                                                {
                                                                    (item.product_mode !== 'allinone') ?
                                                                        <div className="pr-2 price-tag">
                                                                            <span className="fs-6 pr-1 position-relative">$</span>
                                                                            <span className="fw-medium fs-1">{item.plan_price}</span>
                                                                            /Per {item.plan_interval}
                                                                        </div>
                                                                        :
                                                                        (item.display_amount) &&
                                                                        <div className="pr-2 price-tag">
                                                                            <span className="fs-6 pr-1 position-relative">$</span>
                                                                            <span className="fw-medium fs-1">{item.display_amount}</span>
                                                                            /Per {item.plan_interval}
                                                                        </div>
                                                                }
                                                                {/* <small className="align-self-center">Per clinic <br />Per {item.plan_interval}</small> */}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {/* <p>Contactless billing to handle all invoices in one easy to use dashboard</p> */}
                                                    <div className="col-8">
                                                        <p>{item.short_desc}</p>
                                                    </div>
                                                    <button 
                                                        onClick={()=>{
                                                            // this.setState({showReadMore:true})
                                                            window.open('https://www.xcare.app/pricing/')
                                                        }} 
                                                        className="btn btn-link p-0">Read more details <i className="bi bi-box-arrow-in-up-right"></i></button>
                                                    {
                                                        (item.product_mode !== 'allinone') &&
                                                        <div className="d-flex justify-content-end">
                                                            <button disabled={(findIndex > -1 || item.isPlanActive || this.state.isStandardPlanCancel)} className="btn btn-dark"
                                                                onClick={() => { !this.state.isStandardPlanCancel ? this.addAddon(item) : this.setState({ isReNewPlan: true }) }}>{(findIndex > -1 || item.isPlanActive) ? 'Added' : 'Add to plan'}
                                                            </button>
                                                        </div>
                                                    }
                                                </div>
                                            )
                                    }))
                                }
                            </div>}
                        {
                            this.state.isCheckOut &&
                            <CardInfo
                                selectedCard={this.selectedCard}
                                selectedBillInfo={this.selectedBillInfo}
                                billingList={this.billingList}
                            ></CardInfo>
                        }
                        <div className="col-4">
                            <div className="sidebar sticky-top p-4 border border-1 rounded bg-light mt-4 default-card">
                                <ul className="planTab nav nav-tabs rounded-pill border-0 bg-white overflow-hidden" id="myTab" role="tablist">
                                    <li className="nav-item w-50" role="presentation">
                                        <button disabled={!this.state.isIntervalChange} onClick={() => { this.clickMonth() }} className={this.state.planType === 'Monthly' ? "nav-link active w-100 rounded-0 border-0" : "nav-link w-100 rounded-0 border-0"} id="home-tab" data-bs-toggle="tab" data-bs-target="#home" type="button" role="tab" aria-controls="home" aria-selected={this.state.planType === 'Monthly' ? "true" : "false"}>Monthly</button>
                                    </li>
                                    <li className="nav-item w-50" role="presentation">
                                        <button disabled={!this.state.isIntervalChange} onClick={() => { this.clickYear() }} className={this.state.planType === 'Annualy' ? "nav-link active w-100 rounded-0 border-0" : "nav-link w-100 rounded-0 border-0"} id="profile-tab" data-bs-toggle="tab" data-bs-target="#profile" type="button" role="tab" aria-controls="profile" aria-selected={this.state.planType === 'Annualy' ? "true" : "false"}>Annually</button>
                                    </li>
                                </ul>
                                {/* <div className="text-muted text-center my-3"><i>Save 15% on annually plan</i></div> */}
                                {console.log('currentPlanCheck',this.state.currentPlan)
                                }
                                <div className="fw-medium text-muted mb-2">
                                    {
                                        (this.state.currentPlan && this.state.currentPlan[0] && this.state.currentPlan[0].interval === 'month' && this.state.selectedPlanType === 'Monthly' && (this.state.total <= 0)) ?
                                            "Please select Annual plan to change Subscription"
                                            :
                                            (this.state.currentPlan && this.state.currentPlan[0] && this.state.currentPlan[0].interval === 'year' && this.state.selectedPlanType === 'Annualy' && (this.state.total <= 0)) ?
                                                "Please select Annual plan to change Subscription"
                                                :
                                                "Bill summary"
                                    }
                                </div>
                                <div className="tab-content" id="nav-tabContent">
                                    <div className="tab-pane fade show active" id="nav-home" role="tabpanel" aria-labelledby="nav-home-tab">
                                        <ul className="list-group list-group-flush">
                                            {
                                                (this.state.planList) &&
                                                this.state.planList.map((item: any) => {
                                                    if (item.isPlanActive === false && item.planType !== "custom") {
                                                        return (
                                                            <li className="list-group-item d-flex justify-content-between lh-sm bg-transparent px-0">
                                                                <div>
                                                                    {/* <small className="text-muted fw-medium my-1">{item.title}</small> */}
                                                                    <h6 className="my-0 fw-medium my-1">{item.name}</h6>
                                                                </div>
                                                                <span className="text-muted">${item.plan_price}</span>
                                                            </li>
                                                        )
                                                    }
                                                })
                                            }
                                            {
                                                (this.state.addons.length > 0) &&
                                                this.state.addons.map((item: any,index:any) => {
                                                    if (item.planType === "custom") {
                                                        return (
                                                            <li key={item.id} className="list-group-item d-flex justify-content-between lh-sm bg-transparent px-0">
                                                                <div>
                                                                    {/* <small className="text-muted fw-medium my-1">{item.title}</small> */}
                                                                    <h6 className="my-0 fw-medium my-1">{item.name}</h6>
                                                                </div>
                                                                <div className="text-end">
                                                                    <div className="text-muted">${item.plan_price}</div>
                                                                    {!this.state.isCheckOut && item.isRemovable ? <div className="text-danger cursor-pointer" onClick={() => { this.removeAddon(item,index) }}><i className="bi bi-x"></i></div> : null}
                                                                </div>
                                                            </li>
                                                        )
                                                    }
                                                })
                                            }
                                            
                                            {
                                                // ((this.state.productMode !== 'allinone') || (this.state.productMode === 'allinone' && this.state.selectedPlanType === this.state.currentPlanType)) &&
                                                <li className="list-group-item d-flex justify-content-between lh-sm bg-transparent px-0">
                                                    <div>
                                                        <h6 className="my-0 my-1">Sub total</h6>
                                                        {this.state.promocodeApplied && this.state.total > 0 && <h6 className="my-0 my-1">Discount</h6>}
                                                        {(this.state.isCheckOut) && <h6 className="my-0 my-1">{this.state.taxName} Tax ({this.state.taxPercentage}%)</h6>}
                                                    </div>
                                                    <div className="text-end">
                                                        <span className="d-block my-1">${this.state.subTotal}</span>
                                                        {this.state.promocodeApplied && this.state.total > 0 && <span className="d-block my-1">- ${this.state.discountAmount}</span>}
                                                        {(this.state.isCheckOut) && <span className="d-block my-1">${this.state.tax}</span>}
                                                    </div>
                                                </li>
                                                
                                            }
                                            {
                                                // ((this.state.productMode !== 'allinone') || (this.state.productMode === 'allinone' && this.state.selectedPlanType === this.state.currentPlanType)) &&
                                                <li className="list-group-item d-flex justify-content-between bg-transparent px-0 border-0">
                                                    <div className="align-self-right fw-medium ">Total per {this.state.selectedPlanType === 'Monthly' ? 'month' : 'year'}</div>
                                                    <div className="text-end">
                                                        {/* <i className="d-block text-muted">Tax included</i> */}
                                                        <strong className="fs-5">${roundNumberWithRoundingFactorDecimalFix(this.state.total, 2)}</strong>
                                                    </div>
                                                </li>
                                            }
                                            {
                                                (this.state.isCheckOut) &&
                                                <li className="list-group-item  bg-light px-0">
                                                    <label className="form-label">Do you have coupon code ?</label>
                                                    <div className="input-group coupon-input my-2">
                                                        <input type="text" className="form-control border-start-0" placeholder="" value={this.state.promocode} onChange={(e) => { this.setState({ promocode: e.target.value }) }} />
                                                        {!this.state.promocodeApplied && <button disabled={!this.state.promocode} onClick={() => { this.checkCoupon() }} className="btn bg-white fw-medium border-end-0" type="button" id="button-addon2"><small>APPLY</small></button>}
                                                        {this.state.promocodeApplied && <button disabled={!this.state.promocode} onClick={() => { this.removeCoupon() }} className="btn bg-white fw-medium border-end-0" type="button" id="button-addon3"><small>Remove</small></button>}
                                                    </div>
                                                    {/* <div className="text-success">Success! You've done it.</div> */}
                                                </li>
                                            }
                                        </ul>
                                    </div>
                                </div>
                                {
                                    (this.state.isCheckOut) ?
                                        <button disabled={this.state.total <= 0} className="btn btn-primary w-100 fw-medium mt-4"
                                            // onClick={() => { this.props.history.push('/user/overview') }}
                                            onClick={() => { this.handleCreateSubscription() }}
                                        >
                                            {/* <span className="spinner-border spinner-border-sm me-1" role="status" aria-hidden="true"></span> */}
                                            {'Proceed to payment'}</button>
                                        :
                                        <button disabled={this.state.total <= 0} className="btn btn-primary w-100 fw-medium mt-4" onClick={() => { this.handleProcessAndCheckout() }}>
                                            {/* <span className="spinner-border spinner-border-sm me-1" role="status" aria-hidden="true"></span> */}
                                            {'Proceed to checkout'}</button>

                                }
                                {/* <button disabled={this.state.total<=0} className="btn btn-primary w-100 fw-medium mt-4" onClick={()=>{this.handleProcessAndCheckout()}}>{this.state.isCheckOut ?'Process to pay':'Process to checkout'} <img src={require("../../assets/images/spinner-icon.svg")} className="btn-img" /></button> */}
                            </div>
                        </div>
                    </div>
                </div>
                <ReadMore show={this.state.showReadMore} onHide={() => { this.setState({ showReadMore: false }) }}></ReadMore>
            </section>
        )
    }
}
const mapStateToProps = (state: IAppState) => ({
    subscriptionState: state.subscriptionPlan,
    userDataResponse: state.userData.userDataResponse,
});
const mapDispatchToProps = (dispatch: any) => ({
    createSubscriptionPlan: (request: CreateSubscriptionPlanRequest) => {
        dispatch(createSubscriptionPlan(request));
    },
    loadingStart: () => {
        dispatch(fetchLoadingPending());
    },
    loadingEnd: (status: any) => {
        dispatch(fetchLoadingSuccess(status));
    },
    myPlan: (request: ClinicMyplanRequest) => {
        dispatch(myPlan(request));
      },
      fetchUserData:({})=>{
          dispatch(fetchUserDataPending({}));
      }
      
});
export default connect(mapStateToProps, mapDispatchToProps)(GetPlan);