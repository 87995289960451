import React from 'react';
import { IAppState } from '../../../store';
import { connect } from 'react-redux';
import Helmet from 'react-helmet';
import { Elements, CardNumberElement, CardCvcElement, CardExpiryElement } from '@stripe/react-stripe-js';
import { loadStripe, Stripe } from '@stripe/stripe-js';
import apiConfigs from '../../../configs/apiConfigs';
import { BillingRequest, BillingState, BillingResponse, StripeCard, AddCardRequest, RemoveCardRequest, EnrolCardRequest } from '../../../store/billing/types';
import { fetchBillingPendingAction, addCardPendingAction, removeCardPendingAction, enrolCardPendingAction } from '../../../store/billing/actions';
import { toast } from 'react-toastify';
import { allowOnlyAlpha } from '../../../utils/validators';
import { print } from '../../../utils/global';
import { fetchLoadingPending, fetchLoadingSuccess } from '../../../store/loadingIndicator/actions';
interface Props {
    billingState: BillingState;
    fetchBilling: typeof fetchBillingPendingAction;
    addCard: typeof addCardPendingAction;
    removeCard: typeof removeCardPendingAction;
    enrolCard: typeof enrolCardPendingAction;
    loadingStart: typeof fetchLoadingPending;
    loadingEnd: typeof fetchLoadingSuccess;
}
interface State {
    cardHolderName: string;
    billingResponse?: BillingResponse;
    selectedCardIdRemove: string;
    showAddCard: boolean;
    showProviderEnrol: boolean;
    selectedClinic: string;
    doctorCardAdded: any[];
    doctorCardAdd: any[];
    allotedCardArr: any[];
    enrolCardArr: any[];
    cardNumberValidation: { isInvalid: boolean, msg: string };
    cardHolderNameValidation: { isInvalid: boolean, msg: string };
    cardExpiryValidation: { isInvalid: boolean, msg: string };
    cardCvvValidation: { isInvalid: boolean, msg: string };
    cardError: { isInvalid: boolean, msg: string };
    stripeReady: boolean;
}
// recreating the `Stripe` object on every render.
const stripePromise = loadStripe(apiConfigs.STRIPE_PUBLIC_KEY);
class BillingSettings extends React.Component<Props, State> {
    cardNumberElement: any;
    constructor(props: any) {
        super(props);
        this.state = {
            cardHolderName: '',
            selectedCardIdRemove: '',
            showAddCard: false,
            showProviderEnrol: false,
            selectedClinic: '',
            doctorCardAdd: [], doctorCardAdded: [], enrolCardArr: [],
            allotedCardArr: [],
            cardNumberValidation: { isInvalid: false, msg: '' },
            cardCvvValidation: { isInvalid: false, msg: '' },
            cardExpiryValidation: { isInvalid: false, msg: '' },
            cardHolderNameValidation: { isInvalid: false, msg: '' },
            cardError: { isInvalid: false, msg: '' },
            stripeReady: false
        }
    }
    componentDidMount() {
        if (this.props.billingState.pending === false && this.props.billingState.billingResponse
        ) {
            // fetch billing success
            this.setState({
                billingResponse: this.props.billingState.billingResponse, enrolCardArr: this.props.billingState.billingResponse.clinics.map((clinic) => {
                    return {
                        ...clinic,
                        doctors: clinic.doctors.map((doctor) => {
                            if (doctor.stripeCard === null) {
                                return {
                                    ...doctor,
                                    stripeCardEnrol: "notenrol"
                                }
                            }
                            return {
                                ...doctor,
                                stripeCardEnrol: doctor.stripeCard
                            }
                        })
                    }
                })
            });
            if (this.props.billingState.billingResponse.clinics.length > 0) {
                if (this.state.selectedClinic === '') {
                    this.setState({ selectedClinic: this.props.billingState.billingResponse.clinics[0].id });
                }
            }
        }
    }
    createStripeToken() {
        try {
            this.props.loadingStart();
            stripePromise.then((success: Stripe | null) => {
                if (success) {
                    const stripe = success;
                    stripe.createToken(this.cardNumberElement, { name: this.state.cardHolderName }).then((success) => {
                        if (success.error && (success.error.code === "incomplete_number" || success.error.code === "invalid_number")) {
                            this.setState({
                                cardNumberValidation: { isInvalid: true, msg: success.error.message ? success.error.message : '' },
                                cardCvvValidation: { isInvalid: false, msg: '' },
                                cardHolderNameValidation: { isInvalid: false, msg: '' },
                                cardExpiryValidation: { isInvalid: false, msg: '' },
                                cardError: { isInvalid: false, msg: '' }
                            });
                        } else if (this.state.cardHolderName === "") {
                            this.setState({
                                cardHolderNameValidation: { isInvalid: true, msg: 'Please enter card holder name.' },
                                cardNumberValidation: { isInvalid: false, msg: '' },
                                cardCvvValidation: { isInvalid: false, msg: '' },
                                cardExpiryValidation: { isInvalid: false, msg: '' },
                                cardError: { isInvalid: false, msg: '' }
                            });
                            return;
                        } else if (success.error && (success.error.code === "incomplete_expiry" ||
                            success.error.code === "invalid_expiry_year_past" ||
                            success.error.code === "invalid_expiry_year")) {
                            this.setState({
                                cardNumberValidation: { isInvalid: false, msg: '' },
                                cardCvvValidation: { isInvalid: false, msg: '' },
                                cardHolderNameValidation: { isInvalid: false, msg: '' },
                                cardExpiryValidation: { isInvalid: true, msg: success.error.message ? success.error.message : '' },
                                cardError: { isInvalid: false, msg: '' }
                            });
                        } else if (success.error && (success.error.code === "incomplete_cvc")) {
                            this.setState({
                                cardNumberValidation: { isInvalid: false, msg: '' },
                                cardCvvValidation: { isInvalid: true, msg: success.error.message ? success.error.message : '' },
                                cardHolderNameValidation: { isInvalid: false, msg: '' },
                                cardExpiryValidation: { isInvalid: false, msg: '' },
                                cardError: { isInvalid: false, msg: '' }
                            });
                        } else if (success.error) {
                            this.setState({
                                cardNumberValidation: { isInvalid: false, msg: '' },
                                cardCvvValidation: { isInvalid: false, msg: '' },
                                cardHolderNameValidation: { isInvalid: false, msg: '' },
                                cardExpiryValidation: { isInvalid: false, msg: '' },
                                cardError: { isInvalid: true, msg: success.error.message ? success.error.message : '' }
                            });
                        } else if (success.token) {
                            this.setState({
                                cardNumberValidation: { isInvalid: false, msg: '' },
                                cardCvvValidation: { isInvalid: false, msg: '' },
                                cardHolderNameValidation: { isInvalid: false, msg: '' },
                                cardExpiryValidation: { isInvalid: false, msg: '' },
                                cardError: { isInvalid: false, msg: '' }
                            });
                            this.props.loadingEnd(false);
                            this.props.addCard({ stripeToken: success.token.id });
                        }
                    });
                }
            }, (error) => {
                this.props.loadingEnd(false);
                print(error);
            });
        } catch (err) {
            this.props.loadingEnd(false);
            print(err);
        }
    }
    cardNumberRef = (element: any) => {
        if (element) {
            this.cardNumberElement = element;
            this.setState({ stripeReady: true });
        }
    };
    shouldComponentUpdate(nextProps: Props, nextState: State) {
        if (this.props !== nextProps) {
            if (this.props.billingState !== nextProps.billingState) {
                if (nextProps.billingState.error !== null) {
                    this.props.loadingEnd(false);
                    if (nextProps.billingState.billingResponse && nextProps.billingState.billingResponse.status
                        && nextProps.billingState.billingResponse.status.error === true) {
                        toast.error(nextProps.billingState.billingResponse.status.msg);
                    } else if (nextProps.billingState.addCardResponse && nextProps.billingState.addCardResponse.status
                        && nextProps.billingState.addCardResponse.status.error === true) {
                        toast.error(nextProps.billingState.addCardResponse.status.msg);
                    } else if (nextProps.billingState.removeCardResponse && nextProps.billingState.removeCardResponse.status
                        && nextProps.billingState.removeCardResponse.status.error === true) {
                        toast.error(nextProps.billingState.removeCardResponse.status.msg);
                    } else if (nextProps.billingState.enrolCardResponse && nextProps.billingState.enrolCardResponse.status
                        && nextProps.billingState.enrolCardResponse.status.error === true) {
                        toast.error(nextProps.billingState.enrolCardResponse.status.msg);
                    } else {
                        toast.error('Something went wrong');
                    }
                } else {
                    if (nextProps.billingState.pending === false && nextProps.billingState.billingResponse
                        && nextProps.billingState.billingResponse.status && nextProps.billingState.billingResponse.status.error === false) {
                        // fetch billing success
                        this.setState({
                            billingResponse: nextProps.billingState.billingResponse, enrolCardArr: nextProps.billingState.billingResponse.clinics.map((clinic) => {
                                return {
                                    ...clinic,
                                    doctors: clinic.doctors.map((doctor) => {
                                        if (doctor.stripeCard === null) {
                                            return {
                                                ...doctor,
                                                stripeCardEnrol: "notenrol"
                                            }
                                        }
                                        return {
                                            ...doctor,
                                            stripeCardEnrol: doctor.stripeCard
                                        }
                                    })
                                }
                            })
                        });
                        if (nextProps.billingState.billingResponse.clinics.length > 0) {
                            if (this.state.selectedClinic === '') {
                                this.setState({ selectedClinic: nextProps.billingState.billingResponse.clinics[0].id });
                            }
                        }
                    }
                    if (nextProps.billingState.pending === false && nextProps.billingState.addCardResponse &&
                        nextProps.billingState.addCardResponse.status && nextProps.billingState.addCardResponse.status.error === false) {
                        this.props.loadingEnd(false);
                        this.setState({
                            cardHolderName: '',
                            cardNumberValidation: { isInvalid: false, msg: '' },
                            cardCvvValidation: { isInvalid: false, msg: '' },
                            cardHolderNameValidation: { isInvalid: false, msg: '' },
                            cardExpiryValidation: { isInvalid: false, msg: '' },
                            cardError: { isInvalid: false, msg: '' },
                            showAddCard: false
                        });
                        toast.success(nextProps.billingState.addCardResponse.status.msg ? nextProps.billingState.addCardResponse.status.msg : 'Successfully added.');
                    }
                    if (nextProps.billingState.pending === false && nextProps.billingState.removeCardResponse &&
                        nextProps.billingState.removeCardResponse.status && nextProps.billingState.removeCardResponse.status.error === false) {
                        toast.success(nextProps.billingState.removeCardResponse.status.msg ? nextProps.billingState.removeCardResponse.status.msg : 'Successfully added.');
                    }
                    if (nextProps.billingState.pending === false && nextProps.billingState.enrolCardResponse &&
                        nextProps.billingState.enrolCardResponse.status && nextProps.billingState.enrolCardResponse.status.error === false) {
                        toast.success(nextProps.billingState.enrolCardResponse.status.msg ? nextProps.billingState.enrolCardResponse.status.msg : 'Successfully enrolled.');
                        this.setState({ showProviderEnrol: false });
                    }
                }
            }
        }
        return true;
    }
    componentDidUpdate() {
        this.props.billingState.error = null;
        if (this.props.billingState && this.props.billingState.billingResponse) {
            this.props.billingState.billingResponse.status.error = true;
            this.props.billingState.billingResponse.status.msg = '';
        }
        if (this.props.billingState && this.props.billingState.addCardResponse) {
            this.props.billingState.addCardResponse.status.error = true;
            this.props.billingState.addCardResponse.status.msg = '';
        }
        if (this.props.billingState && this.props.billingState.removeCardResponse) {
            this.props.billingState.removeCardResponse.status.error = true;
            this.props.billingState.removeCardResponse.status.msg = '';
        }
        if (this.props.billingState && this.props.billingState.enrolCardResponse) {
            this.props.billingState.enrolCardResponse.status.error = true;
            this.props.billingState.enrolCardResponse.status.msg = '';
        }
    }
    addCardToEnrol(doctorSelected: any, cardId: any) {
        if (this.state.selectedClinic && this.state.selectedClinic !== '' && this.state.enrolCardArr.length > 0) {
            const clinicIndex = this.state.enrolCardArr.findIndex((clinic, index) => {
                return clinic.id === this.state.selectedClinic;
            });
            if (clinicIndex !== -1) {
                this.setState({
                    ...this.state,
                    enrolCardArr: this.state.enrolCardArr.map((clinic) => {
                        if (clinic.id === this.state.selectedClinic) {
                            return {
                                ...clinic,
                                doctors: clinic.doctors.map((doctor: any, dIndex: number) => {
                                    if (doctor.id === doctorSelected.id) {
                                        return {
                                            ...doctor,
                                            stripeCardEnrol: cardId
                                        }
                                    } else {
                                        return doctor;
                                    }
                                })
                            }
                        }
                        return clinic;
                    })
                });
            }
        }
    }
    resetClinic() {
        if (this.state.billingResponse) {
            this.setState({
                enrolCardArr: this.state.billingResponse.clinics.map((clinic) => {
                    return {
                        ...clinic,
                        doctors: clinic.doctors.map((doctor) => {
                            if (doctor.stripeCard === null) {
                                return {
                                    ...doctor,
                                    stripeCardEnrol: "notenrol"
                                }
                            }
                            return {
                                ...doctor,
                                stripeCardEnrol: doctor.stripeCard
                            }
                        })
                    }
                })
            });
        }
    }
    handleSaveEnrolProviderCard() {
        if (this.state.enrolCardArr.length > 0) {
            const requestObj = this.state.enrolCardArr.filter((clinic, index) => {
                return clinic.id === this.state.selectedClinic;
            });
            const req = requestObj.map((clinic, index) => {
                return {
                    clinic: clinic.uniqueId,
                    providers: clinic.doctors.map((doctor: any) => {
                        return {
                            providerId: doctor.provider,
                            cardId: doctor.stripeCardEnrol === "notenrol" ? "" : doctor.stripeCardEnrol
                        }
                    })
                }
            });
            if (req[0]) {
                this.props.enrolCard({ request: req[0] });
            }
        }
    }
    removeCard(card: StripeCard) {
        this.props.removeCard({ cardId: card.id });
    }
    render() {
        let selectedClinic = null;
        if (this.state.selectedClinic !== '' && this.state.billingResponse && this.state.billingResponse.clinics.length > 0) {
            selectedClinic = this.state.billingResponse.clinics.find((clinic, index) => {
                return clinic.id.toLowerCase() === this.state.selectedClinic.toLowerCase();
            })
        }
        let selectedEnrolClinic = null;
        if (this.state.selectedClinic !== '') {
            selectedEnrolClinic = this.state.enrolCardArr.find((clinic, index) => {
                return clinic.id.toLowerCase() === this.state.selectedClinic.toLowerCase();
            })
        }
        return (
            <>
                <Helmet><title>Billing Account</title></Helmet>

                <div className="container">



                    <div className="row">


                        <div className="col-sm-6">


                            <div className="card">
                                <div className="card-header">
                                    <div className="d-flex align-items-center justify-content-between">
                                        <h4 className="card-title">Saved Cards</h4>
                                        <div className="card-toolbar"><ul><li>
                                            <a className="btn btn-sm btn-dark" href="#" onClick={(e) => {
                                                e.preventDefault();
                                                this.setState({ showAddCard: !this.state.showAddCard })
                                            }}>Add card
                                            </a>
                                        </li></ul></div>
                                    </div>
                                </div>
                                <div className="card-body">
                                    {
                                        (this.state.billingResponse && this.state.showAddCard === false) &&
                                        <>
                                            {
                                                (this.state.billingResponse.stripeCards) ?
                                                    <>{

                                                        this.state.billingResponse.stripeCards.map((activeCard, index) => {
                                                            if (activeCard.last4 && (activeCard.status.toLowerCase() === "active")) {
                                                                return (
                                                                    <div className="d-flex align-items-center justify-content-between m-b-20" key={index + Math.random()}>
                                                                        <div className="flex-grow-1">
                                                                            ●●●● ●●●● ●●●● {activeCard.last4} ({activeCard.brand})
                                                                        </div>
                                                                        {
                                                                            (this.state.selectedCardIdRemove.toLowerCase() !== activeCard.id.toLowerCase()) &&
                                                                            <a className="text-gray" href="#" onClick={(e) => {
                                                                                e.preventDefault();
                                                                                this.setState({ selectedCardIdRemove: activeCard.id });
                                                                            }}>
                                                                                <i className="anticon anticon-delete font-size-16"></i>
                                                                            </a>
                                                                        }
                                                                        {
                                                                            (this.state.selectedCardIdRemove.toLowerCase() === activeCard.id.toLowerCase()) &&
                                                                            <span className="cursor-pointer badge badge-danger" onClick={(e) => { this.removeCard(activeCard); }}>Remove</span>
                                                                        }
                                                                    </div>
                                                                )
                                                            } else {
                                                                return null;
                                                            }
                                                        })
                                                    }
                                                    </> : 'No card present'
                                            }
                                        </>
                                    }



                                    {
                                        <Elements stripe={stripePromise}>
                                            {/* <form> */}
                                            <div className={"editmod " + (this.state.showAddCard === true ? '' : 'd-none')}>
                                                <div className="form-group m-b-10">
                                                    <label>Card Number</label>
                                                    <CardNumberElement className={"form-control"}
                                                        options={{ showIcon: true, placeholder: "1234 1234 1234 1234" }}
                                                        onReady={this.cardNumberRef}></CardNumberElement>
                                                    {/* <input type="text" id="autocomplete" className="form-control" value="" /> */}
                                                    {
                                                        (this.state.cardNumberValidation.isInvalid) &&
                                                        <label className="text-danger">{this.state.cardNumberValidation.msg}</label>
                                                    }
                                                </div>

                                                <div className="form-group">
                                                    <label>Cardholder Name</label>
                                                    <input type="text" id="autocomplete" className="form-control"
                                                        placeholder={"Eg. John Doe"}
                                                        onInput={(e: any) => {
                                                            if (allowOnlyAlpha(e.target.value)) {
                                                                this.setState({ cardHolderName: e.target.value });
                                                            } else if (e.target.value === "") {
                                                                this.setState({ cardHolderName: "" });
                                                            }
                                                        }}
                                                        onChange={(e) => {
                                                            if (allowOnlyAlpha(e.target.value)) {
                                                                this.setState({ cardHolderName: e.target.value });
                                                            } else if (e.target.value === "") {
                                                                this.setState({ cardHolderName: "" });
                                                            }
                                                        }} value={this.state.cardHolderName} />
                                                    {
                                                        (this.state.cardHolderNameValidation.isInvalid) &&
                                                        <label className="text-danger">{this.state.cardHolderNameValidation.msg}</label>
                                                    }
                                                </div>

                                                <div className="form-row">
                                                    <div className="form-group col-sm-6">
                                                        <label>Expiry</label>
                                                        <CardExpiryElement className="form-control"></CardExpiryElement>
                                                        {
                                                            (this.state.cardExpiryValidation.isInvalid) &&
                                                            <label className="text-danger">{this.state.cardExpiryValidation.msg}</label>
                                                        }
                                                        {/* <input type="text" className="form-control" placeholder="MM/YY" /> */}
                                                    </div>
                                                    <div className="form-group col-sm-6">
                                                        <label>CVV</label>
                                                        <CardCvcElement options={{ placeholder: "CVV" }} className="form-control"></CardCvcElement>
                                                        {
                                                            (this.state.cardCvvValidation.isInvalid) &&
                                                            <label className="text-danger">{this.state.cardCvvValidation.msg}</label>
                                                        }
                                                        {/* <input type="text" className="form-control" placeholder="123" /> */}
                                                    </div>
                                                </div>

                                                <div className="text-right">
                                                    <button type="button" className="btn btn-default btn-sm m-r-10" onClick={(e) => {
                                                        this.setState({ showAddCard: false, cardHolderName: '' });
                                                    }}>Cancel</button>
                                                    <button type="submit" className="btn btn-primary btn-sm" disabled={!this.state.stripeReady || !this.state.showAddCard} onClick={(e) => {
                                                        this.createStripeToken();
                                                    }}>Save</button>
                                                </div>
                                            </div>
                                            {/*  </form> */}
                                        </Elements>
                                    }
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-6">
                            <div className="card">
                                <div className="card-header">
                                    <div className="d-flex align-items-center justify-content-between">
                                        <h4 className="card-title">Providers</h4>
                                        <div className="card-toolbar"><ul><li>
                                            <a className="text-gray" href="#"
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    this.setState({ showProviderEnrol: !this.state.showProviderEnrol });
                                                }}><i className="fas fa-pencil-alt font-size-12 m-l-5"></i></a>
                                        </li></ul></div>
                                    </div>
                                </div>
                                <div className="card-body">
                                    <div className="d-flex align-items-center justify-content-between m-b-20">
                                        <div className="flex-grow-1">Select Your Clinic</div>
                                        <div>
                                            <select className="form-control form-control-sm "
                                                onChange={(e) => {
                                                    this.setState({ selectedClinic: e.target.value }, () => {
                                                        this.resetClinic();
                                                    });
                                                }}
                                                value={this.state.selectedClinic}>
                                                {
                                                    (this.state.billingResponse && this.state.billingResponse.clinics) &&
                                                    <>
                                                        {
                                                            this.state.billingResponse.clinics.map((clinic, index) => {
                                                                return (
                                                                    <option value={clinic.id} key={index + Math.random()}>{clinic.name}</option>
                                                                )
                                                            })
                                                        }
                                                    </>
                                                }
                                            </select>
                                        </div>
                                    </div>
                                    {
                                        (this.state.showProviderEnrol === false) &&
                                        <>
                                            {
                                                (selectedClinic && selectedClinic.doctors.length > 0) ?
                                                    <>
                                                        {
                                                            selectedClinic.doctors.map((doctor: any, index: number) => {
                                                                if (doctor.stripeCard && this.state.billingResponse) {
                                                                    const stripeCard = this.state.billingResponse.stripeCards.find((card: any, index) => {
                                                                        return doctor.stripeCard === card.id;
                                                                    });
                                                                    if (stripeCard && (stripeCard.status.toLowerCase() === "active")) {
                                                                        return (
                                                                            <div className="d-flex align-items-center justify-content-between m-b-20" key={index + Math.random()}>
                                                                                <div className="flex-grow-1">{doctor.fullname ? doctor.fullname : ''}</div>
                                                                                <div className="">{stripeCard ? stripeCard.last4 ? <>{stripeCard.last4} ({stripeCard.brand ? stripeCard.brand : ''}) </> : '' : ''} </div>
                                                                            </div>
                                                                        );
                                                                    }
                                                                    return null;
                                                                }
                                                                return null;
                                                            })
                                                        }
                                                    </> :
                                                    <>No provider present.</>
                                            }
                                        </>
                                    }

                                    {
                                        (this.state.showProviderEnrol === true && selectedEnrolClinic) &&
                                        <div className="editmod">
                                            {
                                                (selectedEnrolClinic && selectedEnrolClinic.doctors.length > 0) ?
                                                    <>
                                                        {
                                                            selectedEnrolClinic.doctors.map((doctor: any, index: number) => {

                                                                return (
                                                                    <div className="d-flex align-items-center justify-content-between m-b-20" key={index + Math.random()}>
                                                                        <div className="flex-grow-1">{doctor.fullname}</div>
                                                                        <div className="">
                                                                            <select className="form-control form-control-sm" value={doctor.stripeCardEnrol} onChange={(e) => {
                                                                                this.addCardToEnrol(doctor, e.target.value);
                                                                            }}>
                                                                                {
                                                                                    (this.state.billingResponse && this.state.billingResponse.stripeCards) &&
                                                                                    <>
                                                                                        <option value="notenrol">Not Enrolled</option>
                                                                                        {
                                                                                            this.state.billingResponse.stripeCards.map((card, index) => {
                                                                                                if (card.status.toLowerCase() === "active") {
                                                                                                    return (
                                                                                                        <option value={card.id} key={index + Math.random()}>{card.last4} ({card.brand})</option>
                                                                                                    )
                                                                                                } else {
                                                                                                    return null;
                                                                                                }
                                                                                            })
                                                                                        }
                                                                                    </>
                                                                                }
                                                                            </select>
                                                                        </div>
                                                                    </div>
                                                                );
                                                            })
                                                        }
                                                    </> :
                                                    <>No provider present</>
                                            }

                                            <div className="text-right">
                                                <button type="button" className="btn btn-default btn-sm m-r-10" onClick={(e) => {
                                                    this.setState({ showProviderEnrol: false }, () => {
                                                        this.resetClinic();
                                                    })
                                                }}>Cancel</button>
                                                <button type="submit" className="btn btn-primary btn-sm" onClick={(e) => { this.handleSaveEnrolProviderCard(); }}>Save</button>
                                            </div>
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </>
        );
    }
}

const mapStateToProps = (state: IAppState) => ({
    billingState: state.billing,
});
const mapDispatchToProps = (dispatch: any) => ({
    fetchBilling: (request: BillingRequest) => {
        dispatch(fetchBillingPendingAction(request));
    },
    addCard: (request: AddCardRequest) => {
        dispatch(addCardPendingAction(request));
    },
    removeCard: (request: RemoveCardRequest) => {
        dispatch(removeCardPendingAction(request));
    },
    enrolCard: (request: EnrolCardRequest) => {
        dispatch(enrolCardPendingAction(request));
    },
    loadingStart: () => {
        dispatch(fetchLoadingPending());
    },
    loadingEnd: (status: any) => {
        dispatch(fetchLoadingSuccess(status));
    },
});
export default connect(mapStateToProps, mapDispatchToProps)(BillingSettings);