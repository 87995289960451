import { appointmentErrorAction, appointmentSuccessAction, appointmentDetailFailure, appointmentDetailSuccess, modalEmailInfoSuccessAction, modalEmailInfoFailureAction, manualAppointmentSuccessAction, manualAppointmentFailureAction, requestForMedicalHistorySuccess, requestForMedicalHistoryError, appointmentEditSuccess, appointmentEditFailure, bookSlotSuccess, bookSlotFailure, appointmentUnverifiedSuccess, appointmentUnverifiedError } from './../store/appointment/actions';
import { fetchLoadingPending, fetchLoadingSuccess } from './../store/loadingIndicator/actions';
import { attachHeader } from './../utils/apiHandler';
import Axios from 'axios';
import { APPOINTMENT, AppointmentPendingAction, AppointmentRequest, AppointmentDetail, AppointmentDetailRequest, ModalEmailInfoRequest, ModalEmailInfoPending, ManualAppointmentRequest, ManualAppointmentPendingAction, RequestForMedicalHistoryPendingAction, AppointmentEditPendingAction, BookSlotPendingAction, AppointmentUnverifiedPendingAction } from './../store/appointment/types';
import { takeEvery, put, call } from 'redux-saga/effects';
import apiConfigs from '../configs/apiConfigs';
import { CommonApiOptions, METHOD_TYPE, BODY_TYPE } from '../constants/api';
import configs from '../configs/apiConfigs';
import { commonApi } from '../helpers/api';
import { encodeQueryData } from '../utils/global';
function executeAppointment(request: AppointmentRequest) {
    let url = apiConfigs.API_URL + request.modelType + '/' + request.uniqueId + '/appointments/' + request.appointmentType + '/?';
    let queryData = {};
    if (request.viewCalendar) {
        Object.assign(queryData, { page: request.page, view: 'calendar' });
    } else {
        Object.assign(queryData, { page: request.page, results: request.results });
    }
    if (request.fname || request.lname) {
        Object.assign(queryData, { fname: request.fname, lname: request.lname });
    }
    if (request.from) {
        Object.assign(queryData, { from: request.from });
    }
    if (request.to) {
        Object.assign(queryData, { to: request.to });
    }
    url = url + encodeQueryData(queryData);
    return Axios.get(url,
        { headers: attachHeader(true), withCredentials: true }
    ).then(response => ({ response }), error => ({ error }));
}
function* appointmentPendingRun(action: AppointmentPendingAction) {
    if (action.payload.loader === false) {
        // do nothing
    } else {
        yield put(fetchLoadingPending());
    }
    const { response, error } = yield executeAppointment(action.payload);
    if (response) {
        if (response.status === 200) {
            if (response.data.status && response.data.status.error === false) {
                yield put(appointmentSuccessAction({
                    ...response.data, appointmentType: action.payload.appointmentType,
                    realTimeCall: action.payload.realTimeCall ? true : false,
                    fname: action.payload.fname ? action.payload.fname : '',
                    lname: action.payload.lname ? action.payload.lname : ''
                }));
            } else {
                yield put(appointmentErrorAction(response))
            }
        } else {
            yield put(appointmentErrorAction(response));
        }
    }
    if (error) {
        yield put(appointmentErrorAction(error));
    }
    if (action.payload.loader === false) {
        // do nothing
    } else {
        yield put(fetchLoadingSuccess(false));
    }
}
export default function* appointmentPendingWatcher() {
    yield takeEvery(APPOINTMENT.APPOINTMENT_LOAD_PENDING, appointmentPendingRun);
}

function executeAppointmentDetail(request: AppointmentDetailRequest) {
    const url = apiConfigs.API_URL + request.providerType + '/' + request.clinicId + '/appointments/' + request.appointmentId + '/';
    return Axios.get(url, { headers: attachHeader(true), withCredentials: true }).then((response) => {
        if (response.status !== 200) {
            throw response;
        } else if (response.data && response.data.status && response.data.status.error === true) {
            throw response.data;
        }
        return response.data;
    }).then((success) => {
        return success;
    }).catch((error) => {
        throw error;
    });
}

function* appointmentDetailPendingRun(action: AppointmentDetail) {
    try {
        //@ts-ignore
        const response = yield executeAppointmentDetail(action.payload);
        yield put(appointmentDetailSuccess(response));
    } catch (error: any) {
        let message = '';
        switch (error.status) {
            case 500:
                message = 'Internal server errror';
                break;
            case 401:
                message = 'Invalid request';
                break;
            default:
                message = error.data ? error.data.status.msg : 'Something went wrong'
        }
        yield put(appointmentDetailFailure(message));
    }
}
export function* appointmentDetailWatcher() {
    yield takeEvery(APPOINTMENT.APPOINTMENT_DETAIL, appointmentDetailPendingRun);
}

function executeModalEmailInfo(request: any) {
    const url = apiConfigs.API_URL + 'info/';
    return Axios.post(url, request, { headers: attachHeader(true), withCredentials: true }).then((response) => {
        if (response.status !== 200) {
            throw response;
        } else if (response.data && response.data.status && response.data.status.error === true) {
            throw response;
        } else if (response.data === "") {
            throw 'No data found';
        }
        return response.data;
    }).then((success) => {
        return success;
    }).catch((error) => {
        throw error;
    });
}

function* modalEmailInfoPendingRun(action: ModalEmailInfoPending) {
    // yield put(fetchLoadingPending());
    try {
        //@ts-ignore
        const response = yield executeModalEmailInfo(action.payload);
        yield put(modalEmailInfoSuccessAction(response));
    } catch (error: any) {
        let message = '';
        switch (error.status) {
            case 500:
                message = 'Internal server errror';
                break;
            case 401:
                message = 'Invalid request';
                break;
            default:
                message = error.data ? error.data.status.msg : 'Something went wrong'
        }
        yield put(modalEmailInfoFailureAction(message));
    }
    yield put(fetchLoadingSuccess(false));
}
export function* modalEmailInfoWatcher() {
    yield takeEvery(APPOINTMENT.MODAL_EMAIL_INFO, modalEmailInfoPendingRun);
}

function executeManualAppointment(request: ManualAppointmentRequest) {
    const url = apiConfigs.API_URL + request.type + '/' + request.uniqueId + '/manualappointment/';
    return Axios.post(url, request.body, { headers: attachHeader(true), withCredentials: true }).then((response) => {
        if (response.status !== 200) {
            throw response;
        } else if (response.data && response.data.status && response.data.status.error === true) {
            throw response.data;
        } else if (response.data === "") {
            throw 'No data found';
        }
        return response.data;
    }).then((success) => {
        return success;
    }).catch((error) => {
        throw error;
    });
}

function* manualAppointmentPendingRun(action: ManualAppointmentPendingAction) {
    // yield put(fetchLoadingPending());
    try {
        //@ts-ignore
        const response = yield executeManualAppointment(action.payload);
        yield put(manualAppointmentSuccessAction({ ...response, dateTime: action.payload.body.dateTime, isLastItem: action.payload.isLastItem }));
    } catch (error: any) {
        let message = '';
        switch (error.status) {
            case 500:
                message = 'Internal server errror';
                break;
            case 401:
                message = 'Invalid request';
                break;
            default:
                message = error.data ? error.data.status.msg : 'Something went wrong'
        }
        yield put(manualAppointmentFailureAction(message));
        yield put(fetchLoadingSuccess(false));
    }
    // yield put(fetchLoadingSuccess(false));
}
export function* manualAppointmentWatcher() {
    yield takeEvery(APPOINTMENT.MANUAL_APPOINTMENT, manualAppointmentPendingRun);
}

function* requestForMedicalHistoryPendingRun(action: RequestForMedicalHistoryPendingAction) {
    yield put(fetchLoadingPending());
    try {
        const options: CommonApiOptions = {
            url: configs.API_URL + "medical/" + action.payload.clinicUniqueId + "/" + action.payload.appointmentUniqueId + "/",
            apiOptions: {
                headers: attachHeader(true),
                withCredentials: true
            },
            method: METHOD_TYPE.GET
        };
        //@ts-ignore
        const response = yield call(commonApi, options);
        yield put(requestForMedicalHistorySuccess(response));
    } catch (err) {
        yield put(requestForMedicalHistoryError(err));
    }
    yield put(fetchLoadingSuccess(false));
}
export function* requestForMedicalHistoyWatcher() {
    yield takeEvery(APPOINTMENT.REQUEST_FOR_MEDICAL_HISTORY, requestForMedicalHistoryPendingRun);
}

export function* appointmentEdit(action: AppointmentEditPendingAction) {
    try {
        // yield put(fetchLoadingPending());
        const bodyData = JSON.parse(JSON.stringify(action.payload));
        delete bodyData.clinicUniqueId;
        delete bodyData.appointmentUniqueId;
        const options: CommonApiOptions = {
            url: configs.API_URL + "clinic/" + action.payload.clinicUniqueId + "/editappointment/" + action.payload.appointmentUniqueId + "/",
            apiOptions: {
                headers: attachHeader(true),
                withCredentials: true
            },
            method: METHOD_TYPE.POST,
            body: {
                type: BODY_TYPE.RAW,
                data: bodyData
            }
        };
        //@ts-ignore
        const response = yield call(commonApi, options);
        yield put(appointmentEditSuccess(response));
    } catch (err) {
        yield put(appointmentEditFailure(err));
    }
    yield put(fetchLoadingSuccess(false));
}
export function* appointmentEditWatcher() {
    yield takeEvery(APPOINTMENT.EDIT_APPOINTMENT, appointmentEdit);
}
export function* bookSlot(action: BookSlotPendingAction) {
    try {
        // yield put(fetchLoadingPending());
        const options: CommonApiOptions = {
            url: configs.API_URL + "clinic/" + action.payload.clinicUniqueId + "/bookslot/",
            apiOptions: {
                headers: attachHeader(true),
                withCredentials: true
            },
            method: METHOD_TYPE.POST,
            body: {
                type: BODY_TYPE.RAW,
                data: action.payload.data
            }
        };
        //@ts-ignore
        const response = yield call(commonApi, options);
        yield put(bookSlotSuccess(response));
    } catch (err) {
        yield put(bookSlotFailure(err));
    }
    yield put(fetchLoadingSuccess(false));
}
export function* bookSlotWatcher() {
    yield takeEvery(APPOINTMENT.BOOK_SLOT, bookSlot);
}
export function* appointmentUnverified(action: AppointmentUnverifiedPendingAction) {
    try {
        yield put(fetchLoadingPending());
        const options: CommonApiOptions = {
            url: configs.API_URL + "clinic/" + action.payload.clinicUniqueId + "/unverified/" + action.payload.appointmentUniqueId + "/",
            apiOptions: {
                headers: attachHeader(true),
                withCredentials: true
            },
            method: METHOD_TYPE.GET,
        };
        //@ts-ignore
        const response = yield call(commonApi, options);
        yield put(appointmentUnverifiedSuccess(response));
    } catch (err) {
        yield put(appointmentUnverifiedError(err));
    }
    yield put(fetchLoadingSuccess(false));
}
export function* appointmentUnverifiedWatcher() {
    yield takeEvery(APPOINTMENT.APPOINTMENT_UNVERIFIED, appointmentUnverified);
}