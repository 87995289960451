import { BillingState, BillingActionTypes, BILLING } from "./types";

export const initialState: BillingState = {
    pending: false,
    error: null,
}
export const billingReducer = (state = initialState, action: BillingActionTypes) => {
    switch (action.type) {
        case BILLING.BILLING_LOAD_PENDING:
            return {
                ...state,
                pending: true,
                billingRequest: action.payload
            }
        case BILLING.BILLING_LOAD_SUCCESS:
            return {
                ...state,
                pending: false,
                billingResponse: action.billing
            }
        case BILLING.BILLING_LOAD_ERROR:
            return {
                ...state,
                pending: false,
                error: action.error
            }
        case BILLING.ADD_CARD_PENDING:
            return {
                ...state,
                pending: true,
                addCardRequest: action.payload
            }
        case BILLING.ADD_CARD_ERROR:
            return {
                ...state,
                pending: false,
                error: action.error
            }
        case BILLING.ADD_CARD_SUCCESS:
            if (state.billingResponse && action.addCard.card) {
                return {
                    ...state,
                    pending: false,
                    addCardResponse: action.addCard,
                    billingResponse: {
                        ...state.billingResponse,
                        status: {
                            error: false,
                            msg: ''
                        },
                        stripeCards: [...state.billingResponse.stripeCards, action.addCard.card]
                    }
                }
            }
            return {
                ...state,
                pending: false,
                addCardResponse: action.addCard,
            }
        case BILLING.REMOVE_CARD_PENDING:
            return {
                ...state,
                pending: true,
                removeCardRequest: action.payload
            }
        case BILLING.REMOVE_CARD_ERROR:
            return {
                ...state,
                pending: false,
                error: action.error
            }
        case BILLING.REMOVE_CARD_SUCCESS:
            if (state.billingResponse) {
                return {
                    ...state,
                    pending: false,
                    removeCardResponse: action.removeCard,
                    billingResponse: {
                        ...state.billingResponse,
                        status: {
                            error: false,
                            msg: ''
                        },
                        stripeCards: state.billingResponse.stripeCards.filter((card, index) => {
                            return card.id !== action.removeCard.cardId
                        })
                    }
                }
            }
            return {
                ...state,
                pending: false,
                removeCardResponse: action.removeCard,
            }
        case BILLING.ENROLL_CARD_PENDING:
            return {
                ...state,
                pending: true,
                enrolCardRequest: action.payload
            }
        case BILLING.ENROLL_CARD_SUCCESS:
            if (state.billingResponse) {
                return {
                    ...state,
                    pending: false,
                    enrolCardResponse: action.enrolCard,
                    billingResponse: {
                        ...state.billingResponse,
                        status: {
                            error: false,
                            msg: ''
                        },
                        clinics: state.billingResponse.clinics.map((clinic) => {
                            if (clinic.uniqueId === action.enrolCard.clinicId) {
                                return {
                                    ...clinic,
                                    doctors: action.enrolCard.doctors
                                }
                            }
                            return clinic;
                        })
                    }
                }
            }
            return {
                ...state,
                pending: false,
                enrolCardResponse: action.enrolCard,
            }
        case BILLING.ENROLL_CARD_ERROR:
            return {
                ...state,
                pending: false,
                error: action.error
            }
        case BILLING.BILLABLE_YEAR_FETCH_PENDING:
            return {
                ...state,
                pending: true,
                error: null,
                billableEntryFetchRequest: action.payload
            }
        case BILLING.BILLABLE_YEAR_FETCH_ERROR:
            return {
                ...state,
                error: action.error,
                pending: false,
                billableEntryFetchRequest: undefined,
                billableEntryFetchResponse: undefined
            }
        case BILLING.BILLABLE_YEAR_FETCH_SUCCESS:
            return {
                ...state,
                error: null,
                pending: false,
                billableEntryFetchResponse: action.billableEntryFetch
            }
        case BILLING.BILLABLE_APPOINTMENT_FETCH_PENDING:
            return {
                ...state,
                pending: true,
                error: null,
                billableEntryAppointmentRequest: action.payload
            }

        case BILLING.BILLABLE_APPOINTMENT_FETCH_ERROR:
            return {
                ...state,
                error: action.error,
                pending: false,
                billableEntryAppointmentRequest: undefined,
                billableEntryAppointmentResponse: undefined
            }

        case BILLING.BILLABLE_APPOINTMENT_FETCH_SUCCESS:
            return {
                ...state,
                error: null,
                pending: false,
                billableEntryAppointmentResponse: action.billableEntryAppointment
            }

        case BILLING.DEFER_REQUEST_PENDING:
            return {
                ...state,
                pending: true,
                error: null,
                deferReq: action.payload
            }

        case BILLING.DEFER_REQUEST_SUCCESS:
            return {
                ...state,
                pending: false,
                error: null,
                deferRequestResponse: action.payload
            }
        case BILLING.DEFER_REQUEST_ERROR:
            return {
                ...state,
                pending: false,
                error: action.payload,
                deferReq: undefined,
                deferRequestResponse: undefined,

            }


        default:
            return state;
    }
};