import React, { useEffect, useState } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { IAppState } from '../../../store';
import { getSettings, saveAdminSafetyFee } from '../../services/payment';
export const AdminFee = ({ }) => {
    const [isLoading, setIsLoading]: any = useState(false);
    const [feeEnable, setFeeEnable] = useState(false);
    const [fee, setFee] = useState("2");
    const [currentFeeEnable, setCurrentFeeEnable] = useState(false);
    const [currentCharge, setCurrentCharge] = useState("");
    const userdata = useSelector((state: IAppState) => state.userData.userDataResponse, shallowEqual);
    useEffect(() => {
        getSettingsFunc();
    }, [userdata]);
    const getSettingsFunc = () => {
        setIsLoading(true);
        getSettings({ type: 'clinic', uniqueId: userdata.clinics[0].uniqueId }).then((success) => {
            setIsLoading(false);
            if (success && success.status && success.status.error === false) {
                console.log("Response", success);
                if (success.metadata && success.metadata.adminsafetyfee && success.metadata.adminFee) {
                    setFeeEnable(true);
                    setFee(success.metadata.adminFee);
                    setCurrentFeeEnable(true);
                    setCurrentCharge(success.metadata.adminFee);
                } else {
                    setFeeEnable(false);
                    setCurrentFeeEnable(false);
                    if (success.metadata && success.metadata.adminFee) {
                        setFee(success.metadata.adminFee);
                        setCurrentCharge(success.metadata.adminFee);
                    }
                }
            }
        }).catch((err) => {
            setIsLoading(false);
            console.log(err);
        })
    }
    const onSave = () => {
        setIsLoading(true);
        saveAdminSafetyFee({
            uniqueId: userdata.clinics[0].uniqueId,
            requestData: {
                adminsafetyfee: feeEnable,
                adminFee: fee
            }
        }).then((success) => {
            setIsLoading(false);
            if (success && success.status && success.status.error === false) {
                toast.success(success.status.msg);
                getSettingsFunc();
            }
        }).catch((err) => {
            setIsLoading(false);
            toast.error(err.msg);
        })
    }
    return (
        <div className="card bg-light rounded my-4 p-4">
            <div className="d-flex justify-content-between mb-3">
                <div>
                    <small className="text-muted">Extra fees from patient</small>
                    <div className="fw-medium ">Admin & Safety Fee</div>
                </div>
                {
                    !isLoading &&
                    <div>
                        <div className="d-flex align-self-center">
                            <div className="feesTxt align-self-center">
                                {
                                    currentCharge !== "" && currentFeeEnable &&
                                    <><small className="text-muted">Currently charge :</small><span className="feesVal">{currentCharge}%</span></>
                                }
                            </div>
                            <div className="form-check form-switch ps-3 align-self-center">
                                <input className="form-check-input m-0" type="checkbox" id="flexSwitchCheckDefault" checked={feeEnable} onChange={() => {
                                    setFeeEnable(!feeEnable);
                                }} />
                            </div>
                        </div>
                    </div>
                }
            </div>
            <div className="btn-sec d-flex mb-4">
                <select className="custom-select"
                    disabled={isLoading || !feeEnable}
                    value={fee} onChange={(e) => {
                        setFee(e.target.value);
                    }}>
                    <option value="2">2%</option>
                    <option value="3">3%</option>
                    <option value="4">4%</option>
                    <option value="5">5%</option>
                </select>
                <button className="btn btn-dark mx-2" disabled={isLoading} onClick={onSave}>
                    Save
                    {
                        isLoading &&
                        <span className="spinner-border spinner-border-sm me-1" role="status" aria-hidden="true"></span>
                    }
                </button>
                <div>
                    Fees <small className="text-muted d-block">(should be in %)</small>
                </div>
            </div>
            <small className="text-muted"><i className="bi bi-info-circle me-1"></i>You can select admin and safety fees from invoice creation</small>
        </div>
    )
}