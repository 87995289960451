/**
 * register - register the service worker for firebase messaging.
 */
export function register() {
    if ("serviceWorker" in navigator) {
        navigator.serviceWorker.getRegistrations().then((function (registrations) {
            for (let registration of registrations) {
                // de register the service worker old.
                registration.unregister()
            }
        }))
        navigator.serviceWorker
            .register(`${process.env.PUBLIC_URL}/firebase-messaging-sw.js`)
            .then(function (registration) {
                console.log("Registration successful, scope is:", registration.scope);
            })
            .catch(function (err) {
                console.log("Service worker registration failed, error:", err);
            });
    }
}