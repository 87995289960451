import React from 'react';
import { Redirect, Route, Switch } from 'react-router';
import { Discoverablity } from './profile/Discoveribility';
import { Profile } from './profile/Profile';
export const ProfileRoute = () => {
    return (
        <Switch>
            <Route exact path="/provider/profile/:Id/discoveribility" component={Discoverablity}></Route>
            <Route exact path="/provider/profile/:Id" component={Profile}></Route>
            <Redirect to={"/provider/profile"} />
        </Switch >
    )
}