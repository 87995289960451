import { PROFILE, FetchProfileErrorAction, FetchProfilePendingAction, FetchProfileSuccessAction, ProfileRequest, ProfileResponse, QuestionRequest, FetchQuestionPendingAction, QuestionResponse, FetchQuestionSuccessAction, FetchQuestionErrorAction, FetchFeaturePendingAction, FeatureRequest, FeatureResponse, FetchFeatureSuccessAction, FetchFeatureErrorAction, FetchLanguageErrorAction, FetchLanguagePendingAction, LanguageRequest, LanguageResponse, FetchLanguageSuccessAction, ProfileUpdateRequest, ProfileUpdatePendingAction, ProfileUpdateResponse, ProfileUpdateSuccessAction, ProfileUpdateErrorAction, ImageRemoveRequest, ImageRemovePendingAction, ImageRemoveErrorAction, ImageRemoveResponse, ImageRemoveSuccessAction, ImageUploadRequest, ImageUploadPendingAction, ImageUploadErrorAction, ImageUploadResponse, ImageUploadSuccessAction, EmailVerificationRequest, EmailVerificationPendingAction, EmailVerificationResponse, EmailVerificationSuccessAction, EmailVerificationErrorAction, HygieneFeatureRequest, HygieneFeatureResponse, HygieneFeaturePendingAction, HygieneFeatureSuccessAction, HygieneFeatureErrorAction } from './types';
/* Action Creators */
export const fetchProfilePending = (request: ProfileRequest): FetchProfilePendingAction => {
    return {
        type: PROFILE.LOAD_PENDING,
        payload: request
    };
};
export const fetchProfileSuccess = (response: ProfileResponse): FetchProfileSuccessAction => {
    return {
        type: PROFILE.LOAD_SUCCESS,
        profile: response
    }
}
export const fetchProfileError = (err: any): FetchProfileErrorAction => {
    return {
        type: PROFILE.LOAD_ERROR,
        error: err
    }
}
export const fetchQuestionPending = (request: QuestionRequest): FetchQuestionPendingAction => {
    return {
        type: PROFILE.QUESTION_PENDING,
        payload: request
    }
}
export const fetchQuestionSuccess = (response: QuestionResponse): FetchQuestionSuccessAction => {
    return {
        type: PROFILE.QUESTION_SUCCESS,
        question: response
    }
}
export const fetchQuestionError = (err: any): FetchQuestionErrorAction => {
    return {
        type: PROFILE.QUESTION_ERROR,
        error: err
    }
}
export const fetchFeaturePending = (request: FeatureRequest): FetchFeaturePendingAction => {
    return {
        type: PROFILE.FEATURE_PENDING,
        payload: request
    }
}
export const fetchFeatureSuccess = (response: FeatureResponse): FetchFeatureSuccessAction => {
    return {
        type: PROFILE.FEATURE_SUCCESS,
        feature: response
    }
}
export const fetchFeatureError = (err: any): FetchFeatureErrorAction => {
    return {
        type: PROFILE.FEATURE_ERROR,
        error: err
    }
}
export const fetchLanguageError = (err: any): FetchLanguageErrorAction => {
    return {
        type: PROFILE.LANGUAGE_ERROR,
        error: err
    }
}
export const fetchLanguagePending = (request: LanguageRequest): FetchLanguagePendingAction => {
    return {
        type: PROFILE.LANGUAGE_PENDING,
        payload: request
    }
}
export const fetchLanguageSuccess = (response: LanguageResponse): FetchLanguageSuccessAction => {
    return {
        type: PROFILE.LANGUAGE_SUCCESS,
        language: response
    }
}
export const descriptionUpdatePending = (request: ProfileUpdateRequest): ProfileUpdatePendingAction => {
    return {
        type: PROFILE.PROFILE_UPDATE_PENDING,
        payload: request
    }
}
export const descriptionUpdateSuccess = (response: ProfileUpdateResponse): ProfileUpdateSuccessAction => {
    return {
        type: PROFILE.PROFILE_UPDATE_SUCCESS,
        profileUpdate: response
    }
}
export const descriptionUpdateError = (err: any): ProfileUpdateErrorAction => {
    return {
        type: PROFILE.PROFILE_UPDATE_ERROR,
        error: err
    }
}
export const imageRemovePending = (request: ImageRemoveRequest): ImageRemovePendingAction => {
    return {
        type: PROFILE.IMAGE_REMOVE_PENDING,
        payload: request
    }
}
export const imageRemoveError = (err: any): ImageRemoveErrorAction => {
    return {
        type: PROFILE.IMAGE_REMOVE_ERROR,
        error: err
    }
}
export const imageRemoveSuccess = (response: ImageRemoveResponse): ImageRemoveSuccessAction => {
    return {
        type: PROFILE.IMAGE_REMOVE_SUCCESS,
        imageRemove: response
    }
}
export const fetchImageUploadPending = (request: ImageUploadRequest): ImageUploadPendingAction => {
    return {
        type: PROFILE.IMAGE_UPLOAD_PENDING,
        payload: request
    };
};
export const fetchImageUploadSuccess = (response: ImageUploadResponse): ImageUploadSuccessAction => {
    return {
        type: PROFILE.IMAGE_UPLOAD_SUCCESS,
        imageUpload: response,
    }
}
export const fetchImageUploadError = (err: any): ImageUploadErrorAction => {
    return {
        type: PROFILE.IMAGE_UPLOAD_ERROR,
        error: err
    }
}
export const emailVerificationPendingAction = (request: EmailVerificationRequest): EmailVerificationPendingAction => {
    return {
        type: PROFILE.EMAIL_VERIFICATION_PENDING,
        payload: request
    }
}
export const emailVerificationSuccessAction = (response: EmailVerificationResponse): EmailVerificationSuccessAction => {
    return {
        type: PROFILE.EMAIL_VERIFICATION_SUCCESS,
        emailVerification: response
    }
}
export const emailVerificationErrorAction = (error: any): EmailVerificationErrorAction => {
    return {
        type: PROFILE.EMAIL_VERIFICATION_ERROR,
        error: error
    }
}
export const fetchHygieneFeaturePending = (request: HygieneFeatureRequest): HygieneFeaturePendingAction => {
    return {
        type: PROFILE.HYGIENE_FEATURE_PENDING,
        payload: request
    };
};
export const fetchHygieneFeatureSuccess = (response: HygieneFeatureResponse): HygieneFeatureSuccessAction => {
    return {
        type: PROFILE.HYGIENE_FEATURE_SUCCESS,
        payload: response
    }
}
export const fetchHygieneFeatureError = (err: any): HygieneFeatureErrorAction => {
    return {
        type: PROFILE.HYGIENE_FEATURE_ERROR,
        payload: err
    }
}