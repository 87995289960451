import React from 'react';
export const ProgressBar = (props: any) => {
  return (
    <>
      <div className="row">
        <div className="col-12">
          <div className="top-menu-sec">
            <ul className="p-0 m-0 d-md-flex justify-content-center">
              <li className={"step-list " + (props.step === 0 ? 'active' : '') + (props.step > 0 ? ' completed' : '')}>
                <div className="d-xl-flex">
                  <div className="img-box">
                    <i className="bi bi-plus-circle-fill"></i>
                  </div>
                  <div className="txt-box">
                    <small>Step 1/11</small>
                    <span className="d-block fw-medium">Practice type</span>
                  </div>
                </div>
              </li>
              <li className={"step-list " + (props.step === 1 ? 'active' : '') + (props.step > 1 ? ' completed' : '')}>
                <div className="d-xl-flex">
                  <div className="img-box">
                    <i className="bi bi-person-lines-fill"></i>
                  </div>
                  <div className="align-self-center txt-box">
                    <small>Step 2/11</small>
                    <span className="d-block fw-medium">General info</span>
                  </div>
                </div>
              </li>
              <li className={"step-list " + (props.step === 2 ? 'active' : '') + (props.step > 2 ? ' completed' : '')}>
                <div className="d-xl-flex">
                  <div className="img-box">
                    <i className="bi bi-geo-alt-fill"></i>
                  </div>
                  <div className="align-self-center txt-box">
                    <small>Step 3/11</small>
                    <span className="d-block fw-medium">Location</span>
                  </div>
                </div>
              </li>
              <li className={"step-list " + (props.step === 3 ? 'active' : '') + (props.step > 3 ? ' completed' : '')}>
                <div className="d-xl-flex">
                  <div className="img-box">
                    <i className="bi bi-clock-fill"></i>
                  </div>
                  <div className="align-self-center txt-box">
                    <small>Step 4/11</small>
                    <span className="d-block fw-medium">Open hours</span>
                  </div>
                </div>
              </li>
              <li className={"step-list " + (props.step === 4 ? 'active' : '') + (props.step > 4 ? ' completed' : '')}>
                <div className="d-xl-flex">
                  <div className="img-box">
                    <i className="bi bi-search"></i>
                  </div>
                  <div className="align-self-center txt-box">
                    <small>Step 5/11</small>
                    <span className="d-block fw-medium">Discoverability score</span>
                  </div>
                </div>
              </li>
              <li className={"step-list " + ((props.step === 5) ? 'active' : '') + (props.step > 5 ? ' completed' : '')}>
                <div className="d-xl-flex">
                  <div className="img-box">
                    <i className="bi bi-bookmark-star-fill"></i>
                  </div>
                  <div className="align-self-center txt-box">
                    <small>Step 6/11</small>
                    <span className="d-block fw-medium">Add speciality</span>
                  </div>
                </div>
              </li>
              <li className={"step-list " + ((props.step === 6) ? 'active' : '') + (props.step > 6 ? ' completed' : '')}>
                <div className="d-xl-flex">
                  <div className="img-box">
                    <i className="bi bi-clock-fill"></i>
                  </div>
                  <div className="align-self-center txt-box">
                    <small>Step 7/11</small>
                    <span className="d-block fw-medium">Appointment types & hours</span>
                  </div>
                </div>
              </li>
              <li className={"step-list " + ((props.step === 7) ? 'active' : '') + (props.step > 7 ? ' completed' : '')}>
                <div className="d-xl-flex">
                  <div className="img-box">
                    <i className="bi bi-bookmark-heart-fill"></i>
                  </div>
                  <div className="align-self-center txt-box">
                    <small>Step 8/11</small>
                    <span className="d-block fw-medium">Features & facility </span>
                  </div>
                </div>
              </li>
              <li className={"step-list " + ((props.step === 8) ? 'active' : '') + (props.step > 8 ? ' completed' : '')}>
                <div className="d-xl-flex">
                  <div className="img-box">
                    <i className="bi bi-umbrella"></i>
                  </div>
                  <div className="align-self-center txt-box">
                    <small>Step 9/11</small>
                    <span className="d-block fw-medium">Insurance</span>
                  </div>
                </div>
              </li>
              <li className={"step-list " + (props.step === 9 ? 'active' : '') + (props.step > 9 ? ' completed' : '')}>
                <div className="d-xl-flex">
                  <div className="img-box">
                    <i className="bi bi-images"></i>
                  </div>
                  <div className="align-self-center txt-box">
                    <small>Step 10/11</small>
                    <span className="d-block fw-medium">Photo gallery</span>
                  </div>
                </div>
              </li>
              <li className={"step-list " + (props.step === 10 ? 'active' : '') + (props.step > 10 ? ' completed' : '')}>
                <div className="d-xl-flex">
                  <div className="img-box">
                    <i className="bi bi-credit-card-2-front-fill"></i>
                  </div>
                  <div className="align-self-center txt-box">
                    <small>Step 11/11</small>
                    <span className="d-block fw-medium">Credit Card Info</span>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </>
  )
}