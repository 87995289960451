import { PMSAppointmentState, PMSAppointmentTypes, PMS_APPOINTMENTS } from "./types";

const initialState: PMSAppointmentState = {
    pending: false,
    error: null,
}
export const pmsAppointmentReducer = (state = initialState, action: PMSAppointmentTypes): PMSAppointmentState => {
    switch (action.type) {
        case PMS_APPOINTMENTS.GET_PMS_APPOINTMENTS:
            return {
                ...state,
                pending: true,
                error: null,
                getPMSAppointmentsRequest: undefined,
                getPMSAppointmentsResponse: undefined
            }
        case PMS_APPOINTMENTS.GET_PMS_APPOINTMENTS_ERROR:
            return {
                ...state,
                pending: false,
                error: action.payload,
                getPMSAppointmentsRequest: undefined,
                getPMSAppointmentsResponse: undefined
            }
        case PMS_APPOINTMENTS.GET_PMS_APPOINTMENTS_SUCCESS:
            return {
                ...state,
                pending: false,
                error: null,
                getPMSAppointmentsRequest: undefined,
                getPMSAppointmentsResponse: action.payload
            }
        case PMS_APPOINTMENTS.BOOK_PMS_APPOINTMENT:
            return {
                ...state,
                pending: true,
                error: null,
                bookPMSAppointmentRequest: action.payload,
                bookPMSAppointmentResponse: undefined
            }
        case PMS_APPOINTMENTS.BOOK_PMS_APPOINTMENT_FAILURE:
            return {
                ...state,
                pending: false,
                error: action.payload,
                bookPMSAppointmentRequest: undefined,
                bookPMSAppointmentResponse: undefined
            }
        case PMS_APPOINTMENTS.BOOK_PMS_APPOINTMENT_SUCCESS:
            return {
                ...state,
                pending: false,
                error: null,
                bookPMSAppointmentRequest: undefined,
                bookPMSAppointmentResponse: action.payload
            }
        case PMS_APPOINTMENTS.RESCHEDULE_PMS_APPOINTMENT:
            return {
                ...state,
                pending: true,
                error: null,
                rescheduelPMSAppointmentRequest: action.payload,
                rescheduelPMSAppointmentResponse: undefined
            }
        case PMS_APPOINTMENTS.RESCHEDULE_PMS_APPOINTMENT_FAILURE:
            return {
                ...state,
                pending: false,
                error: action.payload,
                rescheduelPMSAppointmentRequest: undefined,
                rescheduelPMSAppointmentResponse: undefined
            }
        case PMS_APPOINTMENTS.RESCHEDULE_PMS_APPOINTMENT_SUCCESS:
            return {
                ...state,
                pending: false,
                error: null,
                rescheduelPMSAppointmentRequest: undefined,
                rescheduelPMSAppointmentResponse: action.payload
            }
        case PMS_APPOINTMENTS.PMS_XCARE_APPOINTMENT_LINK:
            return {
                ...state,
                pending: true,
                error: null,
                pmsxcareAppointmentLinkRequest: action.payload,
                pmsxcareAppointmentLinkResponse: undefined
            }
        case PMS_APPOINTMENTS.PMS_XCARE_APPOINTMENT_LINK_FAILURE:
            return {
                ...state,
                pending: false,
                error: action.payload,
                pmsxcareAppointmentLinkRequest: undefined,
                pmsxcareAppointmentLinkResponse: undefined
            }
        case PMS_APPOINTMENTS.PMS_XCARE_APPOINTMENT_LINK_SUCCESS:
            return {
                ...state,
                pending: false,
                error: null,
                pmsxcareAppointmentLinkRequest: undefined,
                pmsxcareAppointmentLinkResponse: action.payload
            }
        default:
            return state;
    }
}