import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import * as serviceWorker from './serviceWorker';
import * as firebaseWorker from './firebaseWorker';
import { BrowserRouter as Router, Route, Switch, useLocation } from 'react-router-dom';
import configureStore from './store/';
import { Provider } from 'react-redux';
import LoginContainer from './containers/LoginContainer/LoginContainer';
import MainContainer from './containers/MainContainer/MainContainer';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import NoPermission from './components/nopermission/NoPermission';
import AppointmentChat from './components/clinic/appointment/AppointmentChat/AppointmentChat';
import './configs/firebaseConfigs';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import TermsOfUseNew from './components/TermsOfUseNew';
import PrivacyPolicyNew from './components/PrivacyPolicyNew';
import "./i18nextConf";
import 'react-confirm-alert/src/react-confirm-alert.css';
import axios from 'axios';
import countryGlobal from './v2/global/country';
/**
 * Configures the redux store with saga.
 */
const store = configureStore();
/**
 * Configuration for toast
 */
toast.configure({
    closeButton: false, closeOnClick: true, className: 'toaster',
    autoClose: 5000, newestOnTop: true
});
/**
 * React app routing with store as a props to each component
 */

const AppRoutes = () => {
    const [ip, setIP] = useState('');
    const getData = async () => {
        const res = await axios.get('https://ipwho.is/')
        if (res.data.country_code === "US") {
            countryGlobal.country = res.data.country_code;
        }
    }
      
    useEffect( () => {
    //passing getData method to the lifecycle method
    getData()

    }, [])
    return (
        <Switch>
            <Route exact path="/login" component={LoginContainer}></Route>
            <Route exact path="/tnc" component={TermsOfUseNew}></Route>
            <Route exact path="/privacy" component={PrivacyPolicyNew}></Route>
            <Route exact path="/no-permission" component={NoPermission}></Route>
            <Route path="/" render={(props: any) => <MainContainer {...props}></MainContainer>}></Route>
        </Switch>
    );
}
const Routing = (
    <Provider store={store}>
        <MuiPickersUtilsProvider utils={MomentUtils}>
            <Router>
                {/* <Route path="/us" component={AppRoutes}></Route> */}
                <Route path="/" component={AppRoutes}></Route>
            </Router>
        </MuiPickersUtilsProvider>
    </Provider>
);
/**
 * Firebase service worker register
 */
firebaseWorker.register();
/**
 * Pass Routing for rendering the app to div root.
 */
ReactDOM.render(Routing, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();