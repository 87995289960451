import React, { useEffect, useState } from 'react';
import { shallowEqual, useSelector, useDispatch } from 'react-redux';
import { NavLink, useHistory } from 'react-router-dom';
import { IAppState } from '../../../store';
import { changeStatus, getProviderList } from '../../services/provider';
import { toast } from 'react-toastify';
import { fetchLoadingPending, fetchLoadingSuccess } from '../../../store/loadingIndicator/actions';
import configs from '../../configs/apiConfigs';
import { fetchUserDataPending } from '../../../store/userData/actions';
import { confirmAlert } from 'react-confirm-alert';
export const List = (props: any) => {
    const userData = useSelector((state: IAppState) => state.userData.userDataResponse, shallowEqual);
    const dispatch = useDispatch();
    const history = useHistory();
    const [providerList, setProviderList] = useState([]);
    useEffect(() => {
        let clinicId: any = '';
        clinicId = userData.clinics[0].uniqueId;
        if (clinicId) {
            providerListApi(clinicId);
        }
    }, []);
    const providerListApi = (clinicId: any) => {
        dispatch(fetchLoadingPending());
        getProviderList(clinicId).then((success: any) => {
            if (success.status.error === false) {
                dispatch(fetchUserDataPending({}));
                setProviderList(success.providers);

            } else if (success.status.error === true) {
                toast.warn(success.status.msg);
            }
            dispatch(fetchLoadingSuccess(false));
        }).catch((err) => {
            dispatch(fetchLoadingSuccess(false));
        });
    }

    const handleChangeStatus = (item: any) => {
        confirmAlert({
            title: '',
            message: 'Please confirm, if you want to ' + (item.enableAppointment === "1" ? 'disable' : 'enable') + ' this configuration.',
            buttons: [
                {
                    label: 'Yes',
                    className: 'btn btn-outline-primary',
                    onClick: () => {
                        let request = {
                            "type": "enableAppointment",
                            "enableAppointment": item.enableAppointment === "1" ? '0' : '1'
                        }
                        dispatch(fetchLoadingPending());
                        changeStatus(request, item.providerid).then((success: any) => {
                            if (success.status.error === false) {
                                toast.success(success.status.msg);
                                let clinicId = userData.clinics[0].uniqueId;
                                providerListApi(clinicId);
                            } else if (success.status.error === true) {
                                toast.warn(success.status.msg);
                            }
                            dispatch(fetchLoadingSuccess(false));
                        }).catch((err) => {
                            dispatch(fetchLoadingSuccess(false))
                        });
                    }
                },
                {
                    label: 'No',
                    className: 'btn btn-outline-primary',
                    onClick: () => {

                    }
                }
            ]
        });
    };

    const handleShowOnFrontChangeStatus = (item: any) => {
        confirmAlert({
            title: '',
            message: 'Please confirm, if you want to ' + (item.showOnFront === "1" ? 'disable' : 'enable') + ' this configuration.',
            buttons: [
                {
                    label: 'Yes',
                    className: 'btn btn-outline-primary',
                    onClick: () => {
                        let request = {
                            "type": "showOnFront",
                            "showOnFront": item.showOnFront === "1" ? '0' : '1'
                        }
                        dispatch(fetchLoadingPending());
                        changeStatus(request, item.providerid).then((success: any) => {
                            if (success.status.error === false) {
                                toast.success(success.status.msg);
                                let clinicId = userData.clinics[0].uniqueId;
                                providerListApi(clinicId);
                            } else if (success.status.error === true) {
                                toast.warn(success.status.msg);
                            }
                            dispatch(fetchLoadingSuccess(false));
                        }).catch((err) => {
                            dispatch(fetchLoadingSuccess(false))
                        });
                    }
                },
                {
                    label: 'No',
                    className: 'btn btn-outline-primary',
                    onClick: () => {

                    }
                }
            ]
        });
    }

    const onRowClick = (item: any) => {
        history.push('/provider/profile/' + item.uniqueid);
    }
    return (
        <>
            <section className="middle-navbar px-30">
                <div className="p-0 container-fluid">
                    <div className="d-flex justify-content-between">
                        <div>
                            <div className="nav-title-txt">Providers</div>
                            <ul className="nav nav-tabs">
                                <li className="nav-item">
                                    <NavLink className="nav-link" exact={true} to={"/provider/list"}>Providers</NavLink>
                                </li>
                                {/*                                 <li className="nav-item">
                                    <NavLink className="nav-link" exact={true} to={""}>Integration</NavLink>
                                </li> */}
                            </ul>
                        </div>

                        <div className="stp-action">
                            <button type="button" className="btn btn-outline-primary" onClick={() => {
                                history.push('/doctor/create');
                            }}>
                                <i className="bi bi-plus"></i><span> Add new provider</span>
                            </button>
                        </div>

                    </div>
                </div>
            </section>


            <section className="subscription-sec provider-list-sec px-3 pt-4">
                <div className="container">
                    {
                        (providerList.length === 0) &&
                        <label>No providers </label>
                    }
                    {
                        (providerList && providerList.length > 0) &&
                        <div className="advances-table">
                            <table className="table table-hover">
                                <tbody>
                                    {
                                        providerList.map((item: any, index: any) => {
                                            let specialities: any = '';
                                            if (item && item.specialities && item.specialities.length > 0) {
                                                specialities = item.specialities.map((val: any) => val.name);
                                            }
                                            return (
                                                <tr key={item.id + "provider"}>
                                                    <td onClick={() => {
                                                        onRowClick(item);
                                                    }} className="cursor-pointer">
                                                        <div className="d-flex">
                                                            {
                                                                (item.photo && item.photo.name && item.photo.type) ?
                                                                    <img src={configs.IMAGE_URL + '/doctor/' + item.photo.name + '-200x200.' + item.photo.type} className="avatar avatar-lg rounded-circle me-3"></img>
                                                                    :
                                                                    <div className="avatar avatar-lg border rounded-circle me-3 text-uppercase">
                                                                        {(item.fullname.split(" ")[0] && item.fullname.split(" ")[0].charAt(0))}
                                                                        {(item.fullname.split(" ")[1] && item.fullname.split(" ")[1].charAt(0))}
                                                                    </div>
                                                            }
                                                            <div>
                                                                <div className="fw-medium">{item.fullname && item.fullname}</div>
                                                                <div className="text-muted proId">Pro. ID - {item.providerid}</div>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div>
                                                            <div className="text-muted">Speciality</div>
                                                            <div className="fw-medium">
                                                                {specialities.toString()}
                                                            </div>
                                                        </div>
                                                    </td>
                                                    {
                                                        (userData && userData.accessEnabled && userData.accessEnabled.pms_integration) &&
                                                        <td>
                                                            <div>
                                                                <div className="text-muted">PMS ID.</div>
                                                                <div className="fw-medium">{(item.sikkaCode && item.sikkaCode !== "") ? item.sikkaCode : '-'}</div>
                                                            </div>
                                                        </td>
                                                    }
                                                    <td>
                                                        {
                                                            item.onBoarding.status !== 1 &&
                                                            <span className="badge rounded-pill bg-danger">Profile incomplete</span>
                                                        }
                                                    </td>
                                                    <td className="text-end">
                                                        <div className="form-check form-switch float-end">
                                                            <label className="form-check-label text-muted small me-3" htmlFor={item.id + "providerSwitch"}>Enable/Disable</label>
                                                            <input disabled={item.onBoarding.status !== 1} onClick={() => { handleChangeStatus(item) }} checked={item.enableAppointment === "1" ? true : false}
                                                                className="form-check-input" type="checkbox" id={item.id + "providerSwitch"} />
                                                        </div>
                                                    </td>
                                                    <td className="text-end">
                                                        <div className="form-check form-switch float-end">
                                                            <label className="form-check-label text-muted small me-3" htmlFor={item.id + "providerShowonFrontSwitch"}>XCarePro Search</label>
                                                            <input disabled={item.onBoarding.status !== 1} onClick={() => { handleShowOnFrontChangeStatus(item) }} checked={item.showOnFront === "1" ? true : false}
                                                                className="form-check-input" type="checkbox" id={item.id + "providerShowonFrontSwitch"} />
                                                        </div>
                                                    </td>
                                                </tr>
                                            )
                                        })
                                    }
                                </tbody>
                            </table>
                        </div>
                    }
                </div>
            </section>
        </>
    )
}