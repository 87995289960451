// import './_practiceSteps.scss';
import React, { useEffect, useRef, useState } from 'react';
import { createStep } from '../../services/clinicOnboard';
import { useDispatch } from 'react-redux';
import { fetchLoadingPending, fetchLoadingSuccess } from '../../../store/loadingIndicator/actions';
import ReportProblemIcon from "../../assets/images/report_problem-icon.svg";
export function PracticeApp3(props: any) {
	let googleMap: any;
	let maps: any;
	let marker: any;
	let autocomplete: any;
	let autcompleteRef: any = useRef();
	let googleMapsPromise: any;
	const [autoSuggest, setAutoSuggest] = useState('');
	const [componentAddress, setComponentAddress] = useState(null as any);
	const [addressValidation, setAddressValidation] = useState({ invalid: false, msg: '' });
	const dispatch = useDispatch();
	const getGoogleMaps = () => {
		// If we haven't already defined the promise, define it
		if (!googleMapsPromise) {
			googleMapsPromise = new Promise((resolve) => {
				// Add a global handler for when the API finishes loading
				//@ts-ignore
				window.resolveGoogleMapsPromise = () => {
					// Resolve the promise
					//@ts-ignore
					resolve(google);

					// Tidy up
					//@ts-ignore
					delete window.resolveGoogleMapsPromise;
				};

				// Load the Google Maps API
				const script = document.createElement("script");
				const API = 'AIzaSyDIDMklRxHmFYWU8Vvo1P-dVkB_nbHARj8';
				script.src = `https://maps.googleapis.com/maps/api/js?key=${API}&libraries=places&callback=resolveGoogleMapsPromise`;
				script.async = true;
				document.body.appendChild(script);
			});
		}

		// Return a promise for the Google Maps API
		return googleMapsPromise;
	}
	useEffect(() => {
		getGoogleMaps().then((google: any) => {
			const center = { lat: 43.6205, lng: -79.5132 };
			const map = new google.maps.Map(document.getElementById('map'), {
				zoom: 10,
				center: center
			});
			googleMap = map;
			maps = google;
			attachAutoComplete();
			if (props.data) {
				//@ts-ignore
				if (window.google) {
					//@ts-ignore
					const latLng = new window.google.maps.LatLng(props.data.address.latitude, props.data.address.longitude);
					if (marker && marker.setMap) {
						marker.setMap(null);
					}
					//@ts-ignore
					marker = new window.google.maps.Marker({
						position: latLng,
						map: googleMap
					});
					googleMap.setCenter(marker.getPosition());
				}
			}
		});
	}, []);
	useEffect(() => {
		if (props.data) {
			setAutoSuggest(props.data.textAddress);
			setComponentAddress(props.data.address);
		}
	}, [props.data, googleMap]);
	const attachAutoComplete = () => {
		try {
			//@ts-ignore
			autocomplete = new window.google.maps.places.Autocomplete(autcompleteRef.current);
			autocomplete.addListener('place_changed', () => {
				const place = autocomplete.getPlace();
				if (place && place.geometry) {
					//@ts-ignore
					const latLng = new window.google.maps.LatLng(place.geometry.location.lat(), place.geometry.location.lng());
					if (marker && marker.setMap) {
						marker.setMap(null);
					}
					//@ts-ignore
					marker = new window.google.maps.Marker({
						position: latLng,
						map: googleMap
					});
					googleMap.setCenter(marker.getPosition());
					let componentForm = {
						subpremise: '',
						street_number: '',
						route: '',
						sublocality_level_1: '',
						locality: '',
						administrative_area_level_1: '',
						country: '',
						postal_code: '',
						latitude: '',
						longitude: '',
						address_level_1_short: '',
						neighborhood: '',
						formatted_address: ''
					};
					for (let start = 0; start < place.address_components.length; start++) {
						const addressType = place.address_components[start].types[0];
						if (addressType === "street_number") {
							componentForm.street_number = place.address_components[start].long_name;
						} else if (addressType === "subpremise") {
							componentForm.subpremise = place.address_components[start].short_name;
						} else if (addressType === "route") {
							componentForm.route = place.address_components[start].long_name;
						} else if (addressType === "sublocality_level_1") {
							componentForm.sublocality_level_1 = place.address_components[start].long_name;
						} else if (addressType === "locality") {
							componentForm.locality = place.address_components[start].long_name;
						} else if (addressType === "administrative_area_level_1") {
							componentForm.administrative_area_level_1 = place.address_components[start].long_name;
							componentForm.address_level_1_short = place.address_components[start].short_name;
						} else if (addressType === "country") {
							componentForm.country = place.address_components[start].long_name;
						} else if (addressType === "postal_code") {
							componentForm.postal_code = place.address_components[start].short_name;
						} else if (addressType === 'neighborhood') {
							componentForm.neighborhood = place.address_components[start].long_name;
						}
						componentForm.latitude = place.geometry.location.lat();
						componentForm.longitude = place.geometry.location.lng();
						componentForm.formatted_address = place.formatted_address.trim();
					}
					setComponentAddress(componentForm);
					setAutoSuggest(place.formatted_address.trim());
					// { autoCompletePlace: componentForm, edtAddress: place.formatted_address.trim() }
				}
			});
		} catch (err) {
		}
	}
	const onNext = () => {
		if (autoSuggest === '') {
			setAddressValidation({ invalid: true, msg: 'Address Required' });
			return;
		}
		setAddressValidation({ invalid: false, msg: '' });
		const request = {
			address: componentAddress,
			textAddress: autoSuggest,
		};
		const requestData = {
			address: JSON.stringify(componentAddress),
			uniqueid: props.clinicUniqueId
		};
		dispatch(fetchLoadingPending());
		createStep({
			stepNumber: 3,
			formData: requestData
		}).then((success) => {
			dispatch(fetchLoadingSuccess(false));
			if (success.status && success.status.error === false) {
				props.onNext(3, { location: request });
			}
		}).catch((err) => {
		});
	}
	return (

		<>
			<div className="row justify-content-center">
				<div className="col-12">
					<h2 className="mb-3">Location</h2>
					<div className="fw-medium fs-5">What is your practice address?</div>
					<p className="mt-1">We want to ensure that we’re sending patients to the right place.</p>
					<hr className="my-4" />
				</div>

				<div className="col-md-6">
					<div id="map" className="map-sec" style={{ width: "100%", height: "260px" }}></div>
				</div>
				<div className="col-md-6">
					<label htmlFor="practiceName" className="form-label">Business location</label>
					<div className="input-group">
						<span className="input-group-text bg-white"><i className="bi bi-geo-alt-fill"></i></span>
						<input type="text" className="ps-0 form-control bg-white border-start-0" id="autocomplete" placeholder="1234 Main St"
							value={autoSuggest} onChange={(e) => { setAutoSuggest(e.target.value) }} ref={autcompleteRef} />
					</div>
					<small className="text-muted">Your business must be listed on google maps.</small>
					{
						addressValidation.invalid &&
						<div className="is-invalid">
							{addressValidation.msg}
						</div>
					}
				</div>
				<div className="col-12">
					<div className="my-4 border px-4 py-3 border-1 rounded d-flex warning-box">
						<img src={ReportProblemIcon} className="align-self-center" />
						<div className="ms-4 align-self-center">
							<div>If you don't have your practice listed on google maps please add or connect with our team.</div>
						</div>
					</div>
				</div>
				<div className="col-12 my-4">
					<div className="bottom-btn-sec d-flex justify-content-between">
						<button className="btn border me-1" onClick={() => { props.onPrev(1) }}>Back</button>
						<button className="btn btn-primary ms-1" onClick={() => {
							onNext();
						}}>Next</button>
					</div>
				</div>

			</div>
		</>

	);
}
