import React from 'react';
import { IAppState } from '../../../../store';
import { connect } from 'react-redux';
import * as _ from 'lodash';
import { billableEntryFetchPendingAction, billableEntryAppointmentPendingAction, deferRequestPendingAction } from '../../../../store/billing/actions';
import {
  BillableEntryFetchRequest, BillingState, BillableEntryAppointmentRequest, BillableEntryAppointmentResponse,
  DeferRequestType
} from '../../../../store/billing/types';
import { returnMonthName, roundNumberWithRoundingFactorDecimalFix, print, tenthDayofMonth } from '../../../../utils/global';
import { BILLING_STATUS, APPOINTMENT_STATUS, NOT_BILLING_STATUS } from '../../../../constants/status';
import { Modal, Form, Col, Button } from 'react-bootstrap';
import moment from 'moment';
import './BillingAppointment.scss';
import ReactDatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import TimePicker from 'react-time-picker';
import { toast } from 'react-toastify';
import { CHAT } from '../../../../constants';
import { getCookie } from '../../../../utils/cookies';
import apiConfigs from '../../../../configs/apiConfigs';
import * as firebase from 'firebase/app';
import 'firebase/firestore';
import { NavLink } from 'react-router-dom';
import { userLogout } from '../../../../store/login/actions';
import { UserDataResponse } from '../../../../store/userData/types';
interface Props {
  match: any;
  location: any;
  billableEntryFetch: typeof billableEntryFetchPendingAction;
  billingState: BillingState;
  billintEntryAppointment: typeof billableEntryAppointmentPendingAction;
  deferRequestFetch: typeof deferRequestPendingAction;
  history: any;
  logout: typeof userLogout;
  userData: UserDataResponse;
}
interface State {
  clinicSelect: any[];
  providerSelect: any[];
  yearMonthSelect: any[];
  selectedClinicSelect: any;
  selectedProviderSelect: any;
  selectedYearMonthSelect: any;
  billableAppointmentList?: BillableEntryAppointmentResponse[];
  editInfoModalshow: boolean,
  sub_total: number;
  tax: number;
  currentSelectedPatientStatus: string;
  currentShownStep: number;
  currentSelectedAppointment: any;
  showScheduleDiv: boolean;
  scheduleDatePicker: any;
  time: string;
  messageToPatient: string;
  allowRescheduling: number;
  selectedReasonForCancel: string;
}
class BillingAppointment extends React.Component<Props, State> {
  db: any;
  constructor(props: any) {
    super(props);
    this.state = {
      clinicSelect: [],
      providerSelect: [],
      yearMonthSelect: [],
      selectedClinicSelect: '',
      selectedProviderSelect: '',
      selectedYearMonthSelect: '',
      editInfoModalshow: false,
      sub_total: 0,
      tax: 0,
      currentSelectedPatientStatus: '',
      currentShownStep: 0,
      currentSelectedAppointment: '',
      showScheduleDiv: false,
      scheduleDatePicker: new Date(),
      time: '',
      messageToPatient: '',
      allowRescheduling: 1,
      selectedReasonForCancel: "1",
    }
    this.onPressEdit = this.onPressEdit.bind(this);
    this.notAttendedClick = this.notAttendedClick.bind(this);
    this.verifyAttendance = this.verifyAttendance.bind(this);
    this.deferAppointment = this.deferAppointment.bind(this);
    this.yesCorrect = this.yesCorrect.bind(this);
    this.resetModal = this.resetModal.bind(this);
    this.callAppointmentListCurrentSelection = this.callAppointmentListCurrentSelection.bind(this);
  }
  componentDidMount() {
    this.db = firebase.firestore();
    if (this.props.billingState.billingResponse && this.props.billingState.billingResponse.clinics) {
      this.setState({
        clinicSelect: this.props.billingState.billingResponse.clinics.map((value, index) => {
          return {
            value: value.uniqueId,
            label: value.name
          }
        })
      }, () => {
        this.setState({ selectedClinicSelect: this.state.clinicSelect[0].value }, () => {
          this.setDoctorListByClinic(this.state.selectedClinicSelect);
        });
      });
    }
  }
  onPressEdit = (appointment: any) => {
    this.setState({
      editInfoModalshow: true,
      currentSelectedAppointment: appointment,
      currentShownStep: 0,
      currentSelectedPatientStatus: ''
    });
  }
  setDoctorListByClinic(clinicUniqueId: string) {
    if (this.props.billingState.billingResponse && this.props.billingState.billingResponse.clinics) {
      const clinic = this.props.billingState.billingResponse.clinics.find((value, index) => {
        return value.uniqueId === clinicUniqueId;
      });
      if (clinic) {
        const doctorFiltered = clinic.doctors.filter((value) => {
          return value.stripeCard && value.stripeCard !== "";
        }).map((value) => {
          return {
            value: value.provider,
            label: value.fullname
          }
        });
        const doctors = [{ value: 'all', label: 'All Providers' }].concat(doctorFiltered);
        this.setState({
          providerSelect: doctors,
          selectedProviderSelect: 'all'
        }, () => {
          this.props.billableEntryFetch({ type: 'clinic', uniqueId: this.state.selectedClinicSelect });
        });
      }
    }
  }
  shouldComponentUpdate(nextProps: Props, nextState: State) {
    if (nextProps !== this.props) {
      if (nextProps.billingState.error !== null) {

      } else if (
        nextProps.billingState.billableEntryFetchResponse &&
        nextProps.billingState.billableEntryFetchResponse !== this.props.billingState.billableEntryFetchResponse
        && nextProps.billingState.billableEntryFetchResponse.billableMonthsByYear && nextProps.billingState.billableEntryFetchResponse.billableYears) {
        this.monthAndYear(nextProps.billingState.billableEntryFetchResponse).then((success) => {
          this.setState({
            yearMonthSelect: success,
            selectedYearMonthSelect: success[0] ? success[0].value : ''
          }, () => {
            const type = this.state.selectedProviderSelect === 'all' ? 'clinic' : 'provider';
            const uniqueId = type === 'clinic' ? this.state.selectedClinicSelect : this.state.selectedProviderSelect;
            const year = this.state.selectedYearMonthSelect.split("-")[0];
            const month = this.state.selectedYearMonthSelect.split("-")[1];
            if (year && month) {
              this.props.billintEntryAppointment({
                month: month,
                year: year,
                uniqueId: uniqueId,
                type: type
              });
            }
          });
        });
      } else if (nextProps.billingState.billableEntryAppointmentResponse &&
        nextProps.billingState.billableEntryAppointmentResponse !== this.props.billingState.billableEntryAppointmentResponse) {
        this.setState({
          billableAppointmentList: nextProps.billingState.billableEntryAppointmentResponse
        }, () => {
          if (this.state.billableAppointmentList) {
            const notInBillStatus: string[] = NOT_BILLING_STATUS;
            this.setState({
              sub_total: _.sumBy(this.state.billableAppointmentList, function (item) {
                // recouncelling status blank or (recouncelling status present and appointment status is completed.)
                if (item.billingdata && item.billingdata.billingPrice && item.billingStatus &&
                  notInBillStatus.findIndex((value) => { return item.billingStatus === value }) === -1
                ) {
                  if ((!item.reconciledStatus || (item.reconciledStatus && item.reconciledStatus === ""))
                    || (item.reconciledStatus && item.reconciledStatus !== "" && item.status === APPOINTMENT_STATUS.COMPLETE[0])) {
                    return +item.billingdata.billingPrice;
                  }
                }
                return 0;
              }),
              tax: _.sumBy(this.state.billableAppointmentList, function (item) {
                // recouncelling status blank or (recouncelling status present and appointment status is completed.)
                if (item.billingdata && item.billingdata.billingTax && item.billingStatus &&
                  notInBillStatus.findIndex((value) => { return item.billingStatus === value }) === -1
                ) {
                  if ((!item.reconciledStatus || (item.reconciledStatus && item.reconciledStatus === ""))
                    || (item.reconciledStatus && item.reconciledStatus !== "" && item.status === APPOINTMENT_STATUS.COMPLETE[0])) {
                    return +item.billingdata.billingTax;
                  }

                }
                return 0;
              })
            })
          }
        });
      }
      if (nextProps.billingState.deferRequestResponse && nextProps.billingState.deferRequestResponse !== this.props.billingState.deferRequestResponse && nextProps.billingState.deferRequestResponse.status.error === false) {
        this.resetModal();
        this.callAppointmentListCurrentSelection();
      }
    }
    return true;
  }
  async monthAndYear(monthYear: any) {
    let responseArray: any[] = [];
    for (let start = 0; start < monthYear.billableYears.length; start++) {
      const year = monthYear.billableYears[start];
      const monthsarr = monthYear.billableMonthsByYear[year];
      await (async () => {
        for (let monthStart = 0; monthStart < monthsarr.length; monthStart++) {
          await responseArray.push({ value: year + '-' + monthsarr[monthStart], label: returnMonthName(Number(monthsarr[monthStart] - 1)) + ' ' + year });
        }
      })();
    }
    return responseArray;
  }
  render() {
    const recouncileAppointment = _.filter(this.state.billableAppointmentList ? this.state.billableAppointmentList : [], function (item) {
      return !item.reconciledStatus || (item.reconciledStatus && item.reconciledStatus === "");
    });
    return (
      <>
        <div className="container">




          {/* <div className="btn-group mb-4">
            <ul className="nav nav-tabs sbptab">
              <li className="nav-item">
                <NavLink exact={true} to={"/user/billing/appointments"} >Appointments</NavLink>
              </li>
              <li className="nav-item">
                <NavLink exact={true} to={"/user/billing/invoices"} >Invoices</NavLink>
              </li>
              <li className="nav-item">
                <NavLink exact={true} to={"/user/billing/settings"}>Settings</NavLink>
              </li>

            </ul>
          </div> */}



          <div className="row">
            <div className="col-sm-12">
              <div className="d-md-flex">
                <div className="m-b-10 m-r-15">
                  <select className="custom-select" style={{ width: "240px" }} value={this.state.selectedClinicSelect} onChange={(e) => {
                    this.setState({
                      billableAppointmentList: [],
                      selectedClinicSelect: e.target.value
                    }, () => {
                      this.setDoctorListByClinic(this.state.selectedClinicSelect);
                    });
                  }}>
                    {
                      this.state.clinicSelect.map((value, index) => {
                        return (
                          <option value={value.value} key={index + Math.random()}>{value.label}</option>
                        )
                      })
                    }
                  </select>
                </div>
                <div className="m-b-10 m-r-15">
                  <select className="custom-select" style={{ width: "240px" }} value={this.state.selectedProviderSelect} onChange={(e) => {
                    this.setState({
                      billableAppointmentList: [],
                      selectedProviderSelect: e.target.value
                    }, () => {
                      if (this.state.selectedProviderSelect === 'all') {
                        this.props.billableEntryFetch({ type: 'clinic', uniqueId: this.state.selectedClinicSelect });
                      } else {
                        this.props.billableEntryFetch({ type: 'provider', uniqueId: this.state.selectedProviderSelect });
                      }
                    });
                  }}>
                    {
                      this.state.providerSelect.map((value, index) => {
                        return (
                          <option value={value.value} key={index + Math.random()}>{value.label}</option>
                        )
                      })
                    }
                  </select>
                </div>
                <div className="m-b-10">
                  <select className="custom-select" style={{ width: "240px" }} value={this.state.selectedYearMonthSelect} onChange={(e) => {
                    this.setState({ selectedYearMonthSelect: e.target.value }, () => {
                      const type = this.state.selectedProviderSelect === 'all' ? 'clinic' : 'provider';
                      const uniqueId = type === 'clinic' ? this.state.selectedClinicSelect : this.state.selectedProviderSelect;
                      const year = this.state.selectedYearMonthSelect.split("-")[0];
                      const month = this.state.selectedYearMonthSelect.split("-")[1];
                      if (year && month) {
                        this.props.billintEntryAppointment({
                          month: month,
                          year: year,
                          uniqueId: uniqueId,
                          type: type
                        });
                      }
                    });
                  }}>
                    {
                      this.state.yearMonthSelect.map((value, index) => {
                        return (
                          <option value={value.value} key={index + Math.random()}>{value.label}</option>
                        )
                      })
                    }
                  </select>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-12">
              {
                (this.state.billableAppointmentList && recouncileAppointment.length > 0) &&
                <div className="d-md-flex p-15">
                  {recouncileAppointment.length} appointments need to be reconciled.
                </div>
              }{
                (this.state.billableAppointmentList && recouncileAppointment.length === 0) &&
                <div className="d-md-flex p-15">
                  Thank you for reconciling all your appointments.
                </div>
              }
            </div>
          </div>
          <div className="row">

            <div className="col-sm-12">
              <div className="card">


                <div className="table-responsive">
                  <table className="table table-bordered m-0">
                    <thead>
                      <tr>
                        <th>Provider</th>
                        <th>Patient</th>
                        <th>Requested Date</th>
                        <th>Scheduled Date</th>
                        <th>Appointment Status</th>
                        <th></th>
                        <th>Reconciled Status</th>
                        <th>Billing Status</th>
                        {/* <th>Billing Actions</th> */}
                      </tr>
                    </thead>
                    <tbody>
                      {
                        (this.state.billableAppointmentList && this.state.billableAppointmentList.length > 0) &&
                        <>
                          {
                            this.state.billableAppointmentList.map((value, index) => {
                              let status = JSON.parse(JSON.stringify(value.status));
                              if (value.status === APPOINTMENT_STATUS.COMPLETE[0] && value.metadata.appointment.visited) {
                                status = 'Attended';
                              }
                              if (value.status === APPOINTMENT_STATUS.CANCEL[0] && value.metadata.appointment.visited === false) {
                                status = 'Not Attended';
                              }
                              return (
                                <tr key={index + Math.random()}>
                                  <td>{value.doctor.fullname}</td>
                                  <td>
                                    <a href={'/clinic/' + value.clinic.uniqueid + '/appointment/' + value.uniqueid} target={"_blank"}>{value.dependant.fname + ' ' + value.dependant.lname}</a>
                                  </td>
                                  <td>{moment(value.createdAt).format("MMM")} {moment(value.createdAt).format("DD")}
                                    <sup>{moment(value.createdAt).format("Do") ? moment(value.createdAt).format("Do").slice(-2) : ''}</sup></td>
                                  <td>
                                    {
                                      (value.scheduledDate && value.scheduledTime) ?
                                        <>
                                          {moment(value.scheduledDate, 'YYYY-MM-DD').format("MMM")} {moment(value.scheduledDate, 'YYYY-MM-DD').format("DD")}
                                          <sup>{moment(value.scheduledDate, 'YYYY-MM-DD').format("Do") ? moment(value.scheduledDate, 'YYYY-MM-DD').format("Do").slice(-2) : ''}</sup>
                                        </>
                                        : <>-</>
                                    }
                                  </td>

                                  <td style={{ textTransform: 'capitalize' }}>{status ? status : '-'}</td>
                                  {
                                    !value.reconciledStatus ?
                                      <td><span className="badge badge-danger" onClick={(e) => { this.onPressEdit(value) }}>Edit</span></td>
                                      : <td></td>
                                  }
                                  <td>{value.reconciledStatus ? value.reconciledStatus : '-'}</td>
                                  <td>{BILLING_STATUS[`${value.billingStatus}`]}</td>
                                  {/* <td>{(value.billableAction && value.billableAction !== "") ? value.billableAction : '-'}</td> */}
                                </tr>
                              )
                            })
                          }
                          <tr>
                            <td colSpan={7} align={"right"}>Subtotal</td>
                            <td>${roundNumberWithRoundingFactorDecimalFix(this.state.sub_total, 2)}</td>
                          </tr>
                          <tr>
                            <td colSpan={7} align={"right"}>Tax</td>
                            <td>${roundNumberWithRoundingFactorDecimalFix(this.state.tax, 2)}</td>
                          </tr>
                          <tr>
                            <td colSpan={7} align={"right"}><strong>Total</strong></td>
                            <td>${roundNumberWithRoundingFactorDecimalFix(this.state.sub_total + this.state.tax, 2)}</td>
                          </tr>
                        </>
                      }
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Modal centered className="billing_status" show={this.state.editInfoModalshow} onHide={() => {
          this.resetModal();
        }}>
          <Modal.Header>
            {
              (this.state.currentShownStep !== 0) &&
              <button type="button" className="close" onClick={(e) => {
                this.resetModal(true, this.state.currentShownStep - 1);
              }}><i className="fas fa-arrow-left"></i></button>
            }
            {
              this.state.currentShownStep === 0 &&
              <Modal.Title>What status should this patient be in?</Modal.Title>
            }
            {
              this.state.currentShownStep === 1 &&
              <>
                {
                  this.state.currentSelectedPatientStatus === "scheduled" &&
                  <Modal.Title><i className="far fa-calendar-alt"></i> Scheduled</Modal.Title>
                }
                {
                  this.state.currentSelectedPatientStatus === "attended" &&
                  <Modal.Title><i className="fas fa-user-check"></i> Attended</Modal.Title>
                }
                {
                  this.state.currentSelectedPatientStatus === "notattended" &&
                  <Modal.Title><i className="fas fa-user-slash"></i> Not Attended</Modal.Title>
                }
                {
                  this.state.currentSelectedPatientStatus === "defer" &&
                  <Modal.Title><i className="fas fa-redo"></i> Defer Request</Modal.Title>
                }
                {
                  this.state.currentSelectedPatientStatus === "cancel" &&
                  <Modal.Title><i className="far fa-times-circle"></i> Cancel Request</Modal.Title>
                }
              </>
            }
            <button type="button" className="close" onClick={(e) => {
              this.resetModal();
            }}><span aria-hidden="true">×</span><span className="sr-only">Close</span></button>
          </Modal.Header>

          <Modal.Body>
            {
              this.state.currentShownStep === 0 &&
              <div className="row justify-content-center billing_s_act">
                <div className="col-12 col-sm-4" onClick={(e) => {
                  this.setState({ currentSelectedPatientStatus: 'scheduled', currentShownStep: 1 });
                }}>
                  <button className="custom-click btn">
                    <div className="img-container">
                      <i className="far fa-calendar-alt"></i>
                    </div>
                    <div className="content-container">
                      <h5 className="main-text">Scheduled</h5>
                      <div className="sub-text">Patient has an appointment scheduled in the future</div>
                    </div>
                  </button>
                </div>
                <div className="col-12 col-sm-4" onClick={(e) => {
                  this.setState({ currentSelectedPatientStatus: 'attended', currentShownStep: 1 });
                }}>
                  <button className="custom-click btn">
                    <div className="img-container">
                      <i className="fas fa-user-check"></i>
                    </div>
                    <div className="content-container">
                      <h5 className="main-text">Attended</h5>
                      <div className="sub-text">Patient visited our practice for their appointment</div>
                    </div>
                  </button>
                </div>
                <div className="col-12 col-sm-4" onClick={(e) => {
                  this.setState({ currentSelectedPatientStatus: 'notattended', currentShownStep: 1 });
                }}>
                  <button className="custom-click btn">
                    <div className="img-container">
                      <i className="fas fa-user-slash"></i>
                    </div>
                    <div className="content-container">
                      <h5 className="main-text">Not Attended</h5>
                      <div className="sub-text">Patient not attended their appointment without cancelling</div>
                    </div>
                  </button>
                </div>
                {
                  this.state.currentSelectedAppointment.status !== APPOINTMENT_STATUS.SCHEDULE[0] &&
                  <div className="col-12 col-sm-4" onClick={(e) => {
                    this.setState({ currentSelectedPatientStatus: 'defer', currentShownStep: 1 });
                  }}>
                    <button className="custom-click btn">
                      <div className="img-container">
                        <i className="fas fa-redo"></i>
                      </div>
                      <div className="content-container">
                        <h5 className="main-text">Defer Request</h5>
                        <div className="sub-text">Our practice is still trying to schedule this patient</div>
                      </div>
                    </button>
                  </div>
                }
                {
                  (!(this.state.currentSelectedAppointment.status === APPOINTMENT_STATUS.SCHEDULE[0] && moment(this.state.currentSelectedAppointment.scheduledDate, 'YYYY-MM-DD').isAfter(moment().format('YYYY-MM-DD')))) &&
                  <div className="col-12 col-sm-4" onClick={(e) => {
                    this.setState({ currentSelectedPatientStatus: 'cancel', currentShownStep: 1 });
                  }}>
                    <button className="custom-click btn">
                      <div className="img-container">
                        <i className="far fa-times-circle"></i>
                      </div>
                      <div className="content-container">
                        <h5 className="main-text">Cancel Request</h5>
                        <div className="sub-text">Our practice was unable to schedule or see the patient</div>
                      </div>
                    </button>
                  </div>
                }
              </div>
            }
            {
              this.state.currentShownStep === 1 &&
              <>
                {
                  this.state.currentSelectedPatientStatus === "scheduled" &&
                  <div className="row justify-content-center billing_s_act">
                    {
                      (this.state.currentSelectedAppointment.scheduledDate && this.state.currentSelectedAppointment.scheduledTime) &&
                      <>
                        <div className="col-12">
                          <h5>Scheduled Appointment</h5>
                          <p>Appointment scheduleded on <span className="datetime">{this.state.currentSelectedAppointment.scheduledDate} {this.state.currentSelectedAppointment.scheduledTime}</span></p>
                          {
                            this.state.showScheduleDiv === false &&
                            <>
                              <button className="btn btn-custom" onClick={(e) => {
                                this.yesCorrect();
                              }}>Yes it is Correct</button>
                              <button className="btn btn-custom" onClick={(e) => {
                                this.setState({ showScheduleDiv: true });
                              }}>No, Need to Update</button>
                            </>
                          }
                        </div>
                      </>
                    }
                    {
                      ((!this.state.currentSelectedAppointment.scheduledDate
                        && !this.state.currentSelectedAppointment.scheduledTime
                        && this.state.currentSelectedAppointment.status === "requested") || this.state.showScheduleDiv) &&
                      <div className="col-12">
                        <h5>Add Appointment Time</h5>
                        <Form>
                          <Form.Row>
                            <Form.Group as={Col}>
                              <Form.Label>Date</Form.Label>
                              <ReactDatePicker
                                className={"form-control"}
                                dateFormat="MM/dd/yyyy"
                                minDate={new Date()}
                                selected={this.state.scheduleDatePicker}
                                onChange={this.handleChangeDate.bind(this)}
                              ></ReactDatePicker>
                            </Form.Group>

                            <Form.Group as={Col}>
                              <Form.Label>Time</Form.Label>
                              <TimePicker
                                format={"hh:mm a"}
                                disableClock={true}
                                clearIcon={null}
                                onChange={this.onChangeTime.bind(this)}
                                value={this.state.time}
                              />
                            </Form.Group>
                          </Form.Row>
                          <Form.Group>
                            <Form.Label>Message to Patient</Form.Label>
                            <Form.Control as="textarea" rows={3} value={this.state.messageToPatient} onChange={(e: any) => {
                              this.setState({ messageToPatient: e.target.value });
                            }} />
                          </Form.Group>
                        </Form>
                        {
                          (this.state.currentSelectedAppointment.scheduledDate && this.state.currentSelectedAppointment.scheduledTime) &&
                          <Button variant="secondary" onClick={(e: any) => {
                            this.setState({ showScheduleDiv: false });
                          }}>Cancle</Button>
                        }
                        <Button variant="primary" onClick={this.scheduleAppointment.bind(this)}>Confirm Appointment</Button>
                      </div>
                    }
                  </div>
                }
                {
                  this.state.currentSelectedPatientStatus === "attended" &&
                  <div className="row justify-content-center billing_s_act">
                    <div className="col-12">
                      <h5>Confirm attendance</h5>
                      {
                        (moment(this.state.currentSelectedAppointment.scheduledDate, 'YYYY-MM-DD').isAfter(moment().format('YYYY-MM-DD'))) ?
                          <p>Scheduled appointment date is in future, are you sure that patient visited to your practice till current date.</p>
                          :
                          <p>Please verify that patient visited to your practice.</p>
                      }
                      <button className="btn btn-custom" onClick={(e) => { this.verifyAttendance(); }}>Verify</button>
                    </div>
                  </div>
                }
                {
                  this.state.currentSelectedPatientStatus === "notattended" &&
                  <div className="row justify-content-center billing_s_act">
                    <div className="col-12">
                      <h5>Not Attended</h5>
                      {
                        (this.state.currentSelectedAppointment.scheduledDate && this.state.currentSelectedAppointment.scheduledTime) ?
                          <>
                            <p>Appointment scheduleded on <span className="datetime">{this.state.currentSelectedAppointment.scheduledDate} {this.state.currentSelectedAppointment.scheduledTime}</span></p>
                            {
                              (moment(this.state.currentSelectedAppointment.scheduledDate, 'YYYY-MM-DD').isBefore(tenthDayofMonth()) &&
                                moment(this.state.currentSelectedAppointment.scheduledDate, 'YYYY-MM-DD').isBefore(moment().format("YYYY-MM-DD"))) ?
                                <button className="btn btn-custom" onClick={(e) => { this.notAttendedClick(); }}>Not Attended</button>
                                :
                                <p>You can reconciled status of this appointment till 10th of this month.</p>
                            }
                          </>
                          :
                          <>
                            <p>Appointment is not scheduled.</p>
                          </>
                      }
                    </div>
                  </div>
                }
                {
                  this.state.currentSelectedPatientStatus === "defer" &&
                  <div className="row justify-content-center billing_s_act">
                    <div className="col-12">
                      <h5>Defer Request</h5>
                      {
                        this.state.currentSelectedAppointment.status === APPOINTMENT_STATUS.REQUEST[0] ?
                          <>
                            <p>Are you sure! Defer request to current month?</p>
                            <button className="btn btn-custom" onClick={(e) => { this.deferAppointment() }}>Yes, Sure</button>
                          </>
                          :
                          <>
                            <p>You can't defer this appointment.</p>
                          </>
                      }
                    </div>
                  </div>
                }
                {
                  this.state.currentSelectedPatientStatus === "cancel" &&
                  <div className="row justify-content-center billing_s_act">
                    <div className="col-12">
                      <h5>Cancelled</h5>
                      <Form className="cancle_que">
                        {
                          CHAT.CANCEL_REASON.map((reason: any, index: number) => {
                            return (
                              <div className="que_cover" key={index + Math.random()}>
                                <div className="custom-control custom-radio">
                                  <input type="radio" name="reasonRadio" className="custom-control-input" value={reason.id} id={"customRadio" + reason.id}
                                    checked={Number(this.state.selectedReasonForCancel) === Number(reason.id)}
                                    onChange={(e) => {
                                      this.setState({ selectedReasonForCancel: e.target.value });
                                    }}
                                    key={index + Math.random()}
                                  />
                                  <label className="custom-control-label" htmlFor="customRadio">{reason.reason}</label>
                                </div>
                              </div>
                            )
                          })
                        }
                        <div className="reopen_patient">
                          <div className="custom-control custom-checkbox">
                            <input type="checkbox" className="custom-control-input" id="reopen" value={this.state.allowRescheduling} checked={Number(this.state.allowRescheduling) === 1} onChange={(e: any) => {
                              if (Number(this.state.allowRescheduling) === 1) {
                                this.setState({ allowRescheduling: 0 });
                              } else {
                                this.setState({ allowRescheduling: 1 });
                              }
                            }} />
                            <label className="custom-control-label" htmlFor="reopen">Give this patient the ability to reopen their appointment request</label>
                          </div>
                        </div>

                      </Form>
                      <Button variant="primary" onClick={this.cancleAppointment.bind(this)}>Cancel Appointment</Button>
                    </div>
                  </div>
                }
              </>
            }
          </Modal.Body>
        </Modal>
      </>
    );
  }
  /* Schedule Date change */
  handleChangeDate(date: any) {
    this.setState({
      scheduleDatePicker: date
    });
  };
  onChangeTime(time: any) {
    this.setState({
      time: time
    });
  }
  /**
   * reset modal values
   */
  resetModal(hideModal: boolean = false, currentStep: number = 0) {
    this.setState({
      editInfoModalshow: hideModal,
      currentSelectedPatientStatus: '',
      currentShownStep: currentStep,
      currentSelectedAppointment: hideModal ? this.state.currentSelectedAppointment : '',
      showScheduleDiv: false,
      scheduleDatePicker: new Date(),
      time: '',
      messageToPatient: '',
      allowRescheduling: 1,
      selectedReasonForCancel: "1",
    });
  }
  /**
   * Call appointment list for current selection
   */
  callAppointmentListCurrentSelection() {
    const type = this.state.selectedProviderSelect === 'all' ? 'clinic' : 'provider';
    const uniqueId = type === 'clinic' ? this.state.selectedClinicSelect : this.state.selectedProviderSelect;
    const year = this.state.selectedYearMonthSelect.split("-")[0];
    const month = this.state.selectedYearMonthSelect.split("-")[1];
    if (year && month) {
      this.props.billintEntryAppointment({
        month: month,
        year: year,
        uniqueId: uniqueId,
        type: type
      });
    }
  }
  /**
   * Schedule Appointment event when click on confirm
   */
  scheduleAppointment() {
    if (!this.state.scheduleDatePicker || !this.state.time || this.state.scheduleDatePicker === "" || this.state.time === "") {
      toast.warn('Please select valid date or time');
      return;
    }
    const date: string = moment(this.state.scheduleDatePicker).format("YYYY-MM-DD");
    const timeChange: string = this.state.time;
    const dateTime: string = date + " " + timeChange;
    const time = moment(dateTime).format("LT");
    const beforeTime = moment(dateTime).utc();
    const afterTime = moment(moment().utc());
    if (!moment(beforeTime).isSameOrAfter(moment(afterTime))) {
      toast.warn('Date and time should be greater than current date and time');
      return;
    }
    var objIndex: string = this.state.currentSelectedAppointment.status.toUpperCase();
    const fromStatus = CHAT.FROM_STATUS[objIndex];
    const obj = {
      "appointmentId": Number(this.state.currentSelectedAppointment.uniqueid),
      "senderId": Number(getCookie().xpr_user_id),
      "sender": CHAT.SENDER.USER,
      "action": `Changed status from ${fromStatus} to ${CHAT.TO_STATUS.SCHEDULED}.`,
      "actionType": 'STATUS',
      "dateTime": {
        "date": date,
        "time": time,
      },
      "text": this.state.messageToPatient,
      "createdAt": firebase.firestore.FieldValue.serverTimestamp(),
      "updatedAt": firebase.firestore.FieldValue.serverTimestamp(),
      "fromStatus": fromStatus,
      "toStatus": CHAT.TO_STATUS.SCHEDULED,
      "dependantId": Number(this.state.currentSelectedAppointment.dependant.uniqueid),
      "firstName": this.props.userData.firstName,
      "lastName": this.props.userData.lastName,
    };
    this.db.collection(apiConfigs.CHAT_COLLECTION).doc().set(obj)
      .then(() => {
        print("Document successfully written!");
        if (moment(date, 'YYYY-MM-DD').isAfter(tenthDayofMonth())) {
          this.deferAppointment();
        } else {
          this.resetModal();
          this.callAppointmentListCurrentSelection();
        }
      })
      .catch((error: any) => {
        this.props.logout();
        this.props.history.push('/');
        console.error("Error writing document: ", error);
      });
  }
  /**
   * Cancle appointment
   */
  cancleAppointment() {
    const selectedReason = CHAT.CANCEL_REASON.find((value: any) => {
      return Number(value.id) === Number(this.state.selectedReasonForCancel);
    });
    var objIndex: string = this.state.currentSelectedAppointment.status.toUpperCase();
    const fromStatus = CHAT.FROM_STATUS[objIndex];
    const obj = {
      "appointmentId": Number(this.state.currentSelectedAppointment.uniqueid),
      "senderId": Number(getCookie().xpr_user_id),
      "sender": CHAT.SENDER.USER,
      "action": `Changed status from ${fromStatus} to ${CHAT.TO_STATUS.CANCELLED}.`,
      "actionType": 'STATUS',
      "createdAt": firebase.firestore.FieldValue.serverTimestamp(),
      "updatedAt": firebase.firestore.FieldValue.serverTimestamp(),
      "fromStatus": fromStatus,
      "toStatus": CHAT.TO_STATUS.CANCELLED,
      "text": selectedReason.reason,
      "allowRescheduling": Number(this.state.allowRescheduling) === 1,
      "dependantId": Number(this.state.currentSelectedAppointment.dependant.uniqueid),
      "dateTime": {
        "date": this.state.currentSelectedAppointment.date,
        "time": this.state.currentSelectedAppointment.time
      },
      "firstName": this.props.userData.firstName,
      "lastName": this.props.userData.lastName,
    };
    this.db.collection(apiConfigs.CHAT_COLLECTION).doc().set(obj)
      .then(() => {
        print("Document successfully written!");
        this.resetModal();
        this.callAppointmentListCurrentSelection();
      })
      .catch((error: any) => {
        this.props.logout();
        this.props.history.push('/');
        console.error("Error writing document: ", error);
      });
  }
  /**
   * Not attended click of modal
   */
  notAttendedClick() {
    var objIndex: string = this.state.currentSelectedAppointment.status.toUpperCase();
    const fromStatus = CHAT.FROM_STATUS[objIndex];
    const obj = {
      "appointmentId": Number(this.state.currentSelectedAppointment.uniqueid),
      "senderId": Number(getCookie().xpr_user_id),
      "sender": CHAT.SENDER.USER,
      "action": `Changed status from ${fromStatus} to ${CHAT.TO_STATUS.CANCELLED}.`,
      "actionType": 'STATUS',
      "createdAt": firebase.firestore.FieldValue.serverTimestamp(),
      "updatedAt": firebase.firestore.FieldValue.serverTimestamp(),
      "fromStatus": fromStatus,
      "toStatus": CHAT.TO_STATUS.CANCELLED,
      "text": "Not attended.",
      "dependantId": Number(this.state.currentSelectedAppointment.dependant.uniqueid),
      "dateTime": {
        "date": this.state.currentSelectedAppointment.date,
        "time": this.state.currentSelectedAppointment.time
      },
      "firstName": this.props.userData.firstName,
      "lastName": this.props.userData.lastName,
    };
    this.db.collection(apiConfigs.CHAT_COLLECTION).doc().set(obj)
      .then(() => {
        print("Document successfully written!");
        this.resetModal();
        this.callAppointmentListCurrentSelection();
      })
      .catch((error: any) => {
        this.props.logout();
        this.props.history.push('/');
        console.error("Error writing document: ", error);
      });
  }

  /**
   * Verify click of modal
   */
  verifyAttendance() {
    var objIndex: string = this.state.currentSelectedAppointment.status.toUpperCase();
    const fromStatus = CHAT.FROM_STATUS[objIndex];
    const obj = {
      "appointmentId": Number(this.state.currentSelectedAppointment.uniqueid),
      "senderId": Number(getCookie().xpr_user_id),
      "sender": CHAT.SENDER.USER,
      "action": `Changed status from ${fromStatus} to ${CHAT.TO_STATUS.COMPLETED}.`,
      "actionType": 'STATUS',
      "createdAt": firebase.firestore.FieldValue.serverTimestamp(),
      "updatedAt": firebase.firestore.FieldValue.serverTimestamp(),
      "fromStatus": fromStatus,
      "toStatus": CHAT.TO_STATUS.COMPLETED,
      "dependantId": Number(this.state.currentSelectedAppointment.dependant.uniqueid),
      "dateTime": {
        "date": this.state.currentSelectedAppointment.date,
        "time": this.state.currentSelectedAppointment.time
      },
      "firstName": this.props.userData.firstName,
      "lastName": this.props.userData.lastName,
    };
    this.db.collection(apiConfigs.CHAT_COLLECTION).doc().set(obj)
      .then(() => {
        print("Document successfully written!");
        this.resetModal();
        this.callAppointmentListCurrentSelection();
      })
      .catch((error: any) => {
        this.props.logout();
        this.props.history.push('/');
        console.error("Error writing document: ", error);
      });
  }

  /** 
   * Defer appointment    
   */
  deferAppointment() {
    this.props.deferRequestFetch({ clinicUniqueId: this.state.currentSelectedAppointment.clinic.uniqueid, appointmentId: this.state.currentSelectedAppointment.id })
  }
  /**
   * Yes Correct click
   */
  yesCorrect() {
    if (moment(this.state.currentSelectedAppointment.scheduledDate, 'YYYY-MM-DD').isAfter(tenthDayofMonth())) {
      this.deferAppointment();
      return;
    }
    this.resetModal();
    return;
  }
}
const mapStateToProps = (state: IAppState) => ({
  billingState: state.billing,
  userData: state.userData.userDataResponse,
});
const mapDispatchToProps = (dispatch: any) => ({
  billableEntryFetch: (request: BillableEntryFetchRequest) => {
    dispatch(billableEntryFetchPendingAction(request));
  },
  billintEntryAppointment: (request: BillableEntryAppointmentRequest) => {
    dispatch(billableEntryAppointmentPendingAction(request));
  },
  deferRequestFetch: (request: DeferRequestType) => {
    dispatch(deferRequestPendingAction(request));
  },
  logout: () => {
    dispatch(userLogout());
  }
});
export default connect(mapStateToProps, mapDispatchToProps)(BillingAppointment);