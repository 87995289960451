import { ADD_CLINIC, ClinicAddErrorAction, ClinicAddPendingAction, ClinicAddSuccessAction, ClinicAddRequest, ClinicAddResponse, CheckClinicEmailPendingAction, CheckClinicEmailSuccessAction, CheckClinicEmailErrorAction, CheckClinicEmailResponse, CheckClinicEmailRequest, CheckClinicNameRequest, CheckClinicNamePendingAction, CheckClinicNameResponse, CheckClinicNameSuccessAction, CheckClinicNameErrorAction, CheckClinicPhoneRequest, CheckClinicPhonePendingAction, CheckClinicPhoneResponse, CheckClinicPhoneSuccessAction, CheckClinicPhoneErrorAction } from './types';
/* Action Creators */
export const clinicAddPending = (request: ClinicAddRequest): ClinicAddPendingAction => {
    return {
        type: ADD_CLINIC.ADD_CLINIC_PENDING,
        payload: request
    };
};

export const clinicAddSuccess = (response: ClinicAddResponse): ClinicAddSuccessAction => {
    return {
        type: ADD_CLINIC.ADD_CLINIC_SUCCESS,
        clinicAdd: response
    }
}

export const clinicAddError = (err: any): ClinicAddErrorAction => {
    return {
        type: ADD_CLINIC.ADD_CLINIC_ERROR,
        error: err
    }
}

export const checkClinicEmailPending = (request: CheckClinicEmailRequest): CheckClinicEmailPendingAction => {
    return {
        type: ADD_CLINIC.CHECK_CLINIC_EMAIL_PENDING,
        payload: request
    };
};

export const checkClinicEmailSuccess = (response: CheckClinicEmailResponse): CheckClinicEmailSuccessAction => {
    return {
        type: ADD_CLINIC.CHECK_CLINIC_EMAIL_SUCCESS,
        checkClinicEmail: response
    }
}

export const checkClinicEmailError = (err: any): CheckClinicEmailErrorAction => {
    return {
        type: ADD_CLINIC.CHECK_CLINIC_EMAIL_ERROR,
        error: err
    }
}

export const checkClinicNamePending = (request: CheckClinicNameRequest): CheckClinicNamePendingAction => {
    return {
        type: ADD_CLINIC.CHECK_CLINIC_NAME_PENDING,
        payload: request
    };
};

export const checkClinicNameSuccess = (response: CheckClinicNameResponse): CheckClinicNameSuccessAction => {
    return {
        type: ADD_CLINIC.CHECK_CLINIC_NAME_SUCCESS,
        checkClinicName: response
    }
}

export const checkClinicNameError = (err: any): CheckClinicNameErrorAction => {
    return {
        type: ADD_CLINIC.CHECK_CLINIC_NAME_ERROR,
        error: err
    }
}

export const checkClinicPhonePending = (request: CheckClinicPhoneRequest): CheckClinicPhonePendingAction => {
    return {
        type: ADD_CLINIC.CHECK_CLINIC_PHONE_PENDING,
        payload: request
    };
};

export const checkClinicPhoneSuccess = (response: CheckClinicPhoneResponse): CheckClinicPhoneSuccessAction => {
    return {
        type: ADD_CLINIC.CHECK_CLINIC_PHONE_SUCCESS,
        checkClinicPhone: response
    }
}

export const checkClinicPhoneError = (err: any): CheckClinicPhoneErrorAction => {
    return {
        type: ADD_CLINIC.CHECK_CLINIC_PHONE_ERROR,
        error: err
    }
}