import { KeyboardDatePicker, KeyboardTimePicker } from '@material-ui/pickers';
import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { shallowEqual, useSelector } from 'react-redux';
import { IAppState } from '../../../store';
import moment from 'moment';
import { CHAT } from '../../../constants';
import { getCookie } from '../../utils/cookies';
import * as firebase from 'firebase/app';
import 'firebase/firestore';
import firebaseInstance from '../../helpers/firebase';
import configs from '../../configs/apiConfigs';
import { checkAppointmentBook, firestoreChangeNotificationToServer } from '../../services/appointment';
import { toast } from 'react-toastify';
import { checkProviderAvailable } from '../../utils/apiHandler';
import { getAppointmentCategoryList, getProcedureCodeList } from '../../services/messageCenter';
import { cloneDeep } from 'lodash';
export const ScheduleReScheduleModal = ({ hideModal, appointment, fetchAppointment, isReschedule }: any) => {
    const userData = useSelector((state: IAppState) => state.userData.userDataResponse, shallowEqual);
    const [providerList, setProviderList] = useState([]);
    const [selectedProvider, setSelectedProvider] = useState('');
    const [appointmentTypeList, setAppointmentTypeList] = useState([]);
    const [selectedAppointmentType, setSelectedAppointmentType] = useState('');
    const [scheduleDate, setScheduleDate] = useState(new Date());
    const [startTime, setStartTime] = useState(new Date());
    const [endTime, setEndTime] = useState(new Date());
    const [messageToPatient, setMesageToPatient] = useState('');
    const [sendNotification, setSendNotification] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState('');
    const [appointmentCategoryList, setAppointmentCategoryList] = useState([]);
    const [category, setCategory] = useState('');
    const [categoryCodeList, setCategoryCodeList] = useState([]);
    const [proceedureCode, setProceedureCode] = useState('');
    const [amount, setAmount] = useState('');
    const [proceedureCodeTable, setProceedureCodeTable]: any[] = useState([]);
    const [selectedIndex, setSelectedIndex] = useState(-1);
    useEffect(() => {
        if (userData && userData.clinics && userData.clinics[0].provider && appointment) {
            const providers = userData.clinics[0].provider.filter((item: any) => item.onBoarding.status === 1 && item.enableAppointment === "1");
            setProviderList(providers);
            const selectedProvider = providers.find((item: any) => {
                return appointment.doctor.uniqueid === item.uniqueid;
            });
            if (appointment.date) {
                setScheduleDate(appointment.date);
            }
            if (appointment.time) {
                let startTime: any = moment(appointment.time, 'LT');
                setStartTime(startTime);
                if (appointment.duration) {
                    let endTime: any = moment(appointment.time, 'LT').add(appointment.duration, 'minutes');
                    setEndTime(endTime);
                }
            }
            console.log(selectedProvider);
            /* When provider is available as active. */
            if (selectedProvider) {
                setSelectedProvider(appointment.doctor.uniqueid);
                setAppointmentTypeList(selectedProvider.potentialAppointmentTypes);
                setSelectedAppointmentType(appointment.appointmentTypeId);
            } else {
                /* When provider is not available, default select first. */
                setSelectedProvider(providers[0].id);
                setAppointmentTypeList(providers[0].potentialAppointmentTypes);
                setSelectedAppointmentType(providers[0].potentialAppointmentTypes[0].id);
            }
            fetchAppointmentCategory();
        }
    }, [userData, appointment]);
    const handleChangeProvider = (pUniqueId: any) => {
        const provider: any = providerList.find((value: any) => {
            return value.uniqueid === pUniqueId;
        });
        if (provider && provider.potentialAppointmentTypes && provider.potentialAppointmentTypes[0]) {
            setSelectedProvider(pUniqueId);
            setAppointmentTypeList(provider.potentialAppointmentTypes);
            setSelectedAppointmentType(provider.potentialAppointmentTypes[0].id);
        }
    }
    const handleAppointmentTypeChange = (id: any) => {
        setSelectedAppointmentType(id);
    }
    const scheduleAppointment = async () => {
        try {
            const date = moment(scheduleDate).format('YYYY-MM-DD');
            const startTimeLT = moment(startTime).format('LT');
            const endTimeLT = moment(endTime).format('LT');
            if (moment(date + ' ' + startTimeLT, 'YYYY-MM-DD LT').isBefore(moment().format('YYYY-MM-DD LT'))) {
                setError('Schedule date and time must be same as today or after todays date and time.');
                return;
            }
            if (moment(date + ' ' + startTimeLT, 'YYYY-MM-DD LT').isSameOrAfter(moment(date + ' ' + endTimeLT, 'YYYY-MM-DD LT'))) {
                setError('Start time must be after end time.');
                return;
            }
            const providerSelected: any = providerList.find((item: any) => item.uniqueid === selectedProvider);
            let availableDoctor = checkProviderAvailable(userData.clinics[0], providerSelected, date, startTimeLT, endTimeLT);
            if (availableDoctor === false) {
                setError('Provider is not available on selected time.');
                return;
            }
            setError('');
            setIsLoading(true);
            checkAppointmentBook({
                appointmentId: appointment.uniqueid,
                provider: selectedProvider,
                date: date,
                starttime: startTimeLT,
                endtime: endTimeLT
            }).then(async (success) => {
                const provider: any = providerList.find((item: any) => item.uniqueid === selectedProvider);
                if (success && success.status && success.status.error === false) {
                    setIsLoading(true);
                    var objIndex: string = appointment.currentStatus.toUpperCase();
                    const fromStatus = CHAT.FROM_STATUS[objIndex];
                    const obj = {
                        "appointmentId": Number(appointment.uniqueid),
                        "senderId": Number(getCookie().xpr_user_id),
                        "sender": CHAT.SENDER.USER,
                        "action": `Changed status from ${fromStatus} to ${CHAT.TO_STATUS.SCHEDULED}.`,
                        "actionType": 'STATUS',
                        "dateTime": {
                            "date": date,
                            "time": startTimeLT,
                            "startTime": startTimeLT,
                            "endTime": endTimeLT,
                        },
                        "appointmenttype": {
                            "id": selectedAppointmentType
                        },
                        "clinic": {
                            "id": appointment.clinic.uniqueid,
                            "doctor": provider.id
                        },
                        "sendNotificationToPatient": sendNotification,
                        "text": messageToPatient,
                        "createdAt": firebase.firestore.FieldValue.serverTimestamp(),
                        "updatedAt": firebase.firestore.FieldValue.serverTimestamp(),
                        "fromStatus": fromStatus,
                        "toStatus": CHAT.TO_STATUS.SCHEDULED,
                        "sendToCId": appointment.clinic.uniqueid,
                        "dependantId": Number(appointment.dependant.uniqueid),
                        "firstName": userData.firstName,
                        "lastName": userData.lastName,
                    };
                    const response = await firebaseInstance.addDocumentIntoCollection(configs.CHAT_COLLECTION, obj);
                    if (response) {
                        response.get().then((docRef: any) => {
                            let data = {
                                ...docRef.data(),
                                mode: "create",
                                id: docRef.id
                            };
                            data.proceedureCode = proceedureCodeTable.map((item: any, index: number) => ({ ...item, number: index + 1, price: item.amount }));
                            firestoreChangeNotificationToServer(data).then((success: any) => {
                                hideModal();
                                fetchAppointment(true);
                            }).catch((err: any) => {
                                setIsLoading(false);
                                console.log(err);
                            })
                        });
                    }
                } else {
                    setIsLoading(false);
                }
            }).catch((err) => {
                setIsLoading(false);
                console.log(err);
                toast.error(err.msg);
            });
        } catch (err) {
            setIsLoading(false);
        }

    }
    useEffect(() => {
        const appointmenttype: any = appointmentTypeList.find((item: any) => item.id === selectedAppointmentType);
        if (appointmenttype) {
            setEndTime(moment(startTime).add(appointmenttype.duration, 'minutes').toDate());
        }
    }, [selectedAppointmentType]);
    const fetchAppointmentCategory = () => {
        getAppointmentCategoryList({
            clinicid: userData.clinics[0].id
        }).then((success) => {
            setAppointmentCategoryList(success.data);
            setCategory(success.data[0]?.procedure_code_category ?? '');
            fetchCategoryCode(success.data[0]?.procedure_code_category ?? '');
        }).catch((err) => {
            console.log("err", err);
        })
    }

    const fetchCategoryCode = (category = '') => {
        if (category === '') return;
        getProcedureCodeList({
            clinicid: userData.clinics[0].id,
            category: [category],
            type: 'custom'
        }).then((success) => {
            setCategoryCodeList(success.data[0]);
        }).catch((err) => {
            console.log("err", err);
        })
    }
    const handleAdd = () => {
        if (category === '') {
            toast.error("Please select category");
            return;
        } else if (proceedureCode === '') {
            toast.error("Please select proceedure code");
            return;
        } else if (amount === '') {
            toast.error("Please enter amount");
            return;
        } else if (isNaN(Number(amount))) {
            toast.error("Please enter valid amount");
            return;
        }
        if (selectedIndex !== -1) {
            // edit
            const cloned = cloneDeep(proceedureCodeTable);
            cloned.splice(selectedIndex, 1, {
                category: category,
                code: proceedureCode,
                amount: amount
            });
            setProceedureCodeTable(cloned);
        } else {
            // add
            setProceedureCodeTable([...proceedureCodeTable, {
                category: category,
                code: proceedureCode,
                amount: amount
            }]);
        }
        setCategory('');
        setProceedureCode('');
        setAmount('');
        fetchCategoryCode('');
        setSelectedIndex(-1);
    }
    return (
        <Modal className="newcommon reschedule-popup" show={true} onHide={hideModal} centered>
            <Modal.Header>
                <div className="modal-title">{isReschedule ? 'Reschedule appointment' : 'Schedule appointment'}</div>
                <button type="button" className="btn-close" aria-label="Close" onClick={hideModal} >
                </button>
            </Modal.Header>
            <Modal.Body>
                <div className="row g-3">
                    <div className="col-12">
                        <label className="form-label">Select provider</label>
                        <select className="form-select" value={selectedProvider} onChange={(e) => { handleChangeProvider(e.target.value); }}>
                            {
                                providerList.map((item: any, index: number) => {
                                    return (
                                        <option value={item.uniqueid} key={"provider" + item.uniqueid}>{item.fullname}</option>
                                    )
                                })
                            }
                        </select>
                    </div>
                    <div className="col-12">
                        <label className="form-label">Appointment type</label>
                        <select className="form-select" value={selectedAppointmentType} onChange={(e) => {
                            handleAppointmentTypeChange(e.target.value);
                        }}>
                            {
                                appointmentTypeList.map((item: any, index: number) => {
                                    return (
                                        <option value={item.id} key={"appointmenttype" + item.id}>{item.name} - {item.speciality}</option>
                                    )
                                })
                            }
                        </select>
                    </div>
                    <div className="col-5 miuif_margin">
                        <label className="form-label">Select date</label>
                        <KeyboardDatePicker
                            margin="none"
                            id="date-picker-dialog"
                            label="Date"
                            format="YYYY/MM/DD"
                            minDate={new Date()}
                            value={scheduleDate}
                            onChange={(date: any) => {
                                setScheduleDate(date);
                            }}
                            KeyboardButtonProps={{
                                'aria-label': 'change date',
                            }}
                        />
                    </div>
                    <div className="col-7 miuif_margin">
                        <label className="form-label">Select time</label>
                        <div className="row">
                            <div className="col-6">
                                <KeyboardTimePicker
                                    margin="none"
                                    id="time-picker-start"
                                    value={startTime}
                                    onChange={(time: any) => {
                                        setStartTime(time);
                                    }}
                                    KeyboardButtonProps={{
                                        'aria-label': 'change time start',
                                    }}
                                />
                            </div>
                            <div className="col-6">
                                <KeyboardTimePicker
                                    margin="none"
                                    id="time-picker-end"
                                    value={endTime}
                                    onChange={(time: any) => {
                                        setEndTime(time);
                                    }}
                                    KeyboardButtonProps={{
                                        'aria-label': 'change time end',
                                    }}
                                />
                            </div>
                        </div>

                    </div>
                    <div className="col-12">
                        <label className="form-label">Notes:</label>
                        <textarea className="form-control" rows={3} value={messageToPatient} onChange={(e: any) => {
                            setMesageToPatient(e.target.value);
                        }}></textarea>
                    </div>
                    <div className="col-12">
                        <div className="form-check">
                            <input className="form-check-input" type="checkbox" id="notify" checked={sendNotification} onChange={(e) => {
                                setSendNotification(!sendNotification);
                            }} />
                            <label className="form-check-label" htmlFor="notify">
                                Yes, send notification to patient.
                            </label>
                        </div>
                    </div>
                    {
                        error !== '' &&
                        <div className='is-invalid'>{error}</div>
                    }
                </div>
                {/* Table html start*/}
                <div className="proceedure-code-table">
                    <div className="row">
                        <div className="col-10">
                            <div className="row">
                                <div className="col-4">
                                    <label className="form-label">Category</label>
                                </div>
                                <div className="col-4">
                                    <label className="form-label">Code</label>
                                </div>
                                <div className="col-4">
                                    <label className="form-label">Amount</label>
                                </div>
                            </div>
                        </div>
                        <div className="col-2">
                            <div className="row">
                                <div className="col-12">
                                </div>
                            </div>
                        </div>
                    </div>

                    {
                        proceedureCodeTable && proceedureCodeTable.map((item: any, index: number) => {
                            const handleEdit = () => {
                                setSelectedIndex(index);
                                setCategory(item.category);
                                setProceedureCode(item.code);
                                setAmount(item.amount);
                                fetchCategoryCode(item.category);
                            }
                            const handleDelete = () => {
                                setProceedureCodeTable((currentState: any) => currentState.filter((_item: any, _index: number) => {
                                    return index !== _index;
                                }))
                            }
                            if (selectedIndex === index) {
                                const handleEditRow = () => {
                                    if (category === '') {
                                        toast.error("Please select category");
                                        return;
                                    } else if (proceedureCode === '') {
                                        toast.error("Please select proceedure code");
                                        return;
                                    } else if (amount === '') {
                                        toast.error("Please enter amount");
                                        return;
                                    } else if (isNaN(Number(amount))) {
                                        toast.error("Please enter valid amount");
                                        return;
                                    }
                                    setProceedureCodeTable((currentState: any) => currentState.map((_item: any, _index: number) => {
                                        if (_index === selectedIndex) {
                                            return {
                                                ..._item,
                                                category: category,
                                                code: proceedureCode,
                                                amount: amount
                                            }
                                        }
                                        return _item;
                                    }));
                                    setSelectedIndex(-1);
                                    setCategory('');
                                    setProceedureCode('');
                                    setAmount('');
                                }
                                return (
                                    <div className="row">
                                        <div className="col-10">
                                            <div className="row">
                                                <div className="col-4">
                                                    <select className='w-100 form-select' value={category} onChange={(e) => {
                                                        setCategory(e.target.value);
                                                        fetchCategoryCode(e.target.value);
                                                    }}>
                                                        <option value="" disabled>Select Category</option>
                                                        {
                                                            appointmentCategoryList.map((item: any, index: number) => {
                                                                return (<option value={item.procedure_code_category}>{item.procedure_code_category}</option>)
                                                            })
                                                        }
                                                    </select>
                                                </div>
                                                <div className="col-4">
                                                    <select className='w-100 form-select' value={proceedureCode} onChange={(e) => {
                                                        setProceedureCode(e.target.value);
                                                        const code: any = categoryCodeList.find((_item: any) => _item.procedure_code_sikkaId === e.target.value);
                                                        setAmount(code?.procedure_code_price ?? 0);
                                                    }}>
                                                        <option value="" disabled>Select Code</option>
                                                        {
                                                            categoryCodeList.map((item: any, index: number) => {
                                                                return (<option value={item.procedure_code_sikkaId}>{item.procedure_code_sikkaId}</option>)
                                                            })
                                                        }
                                                    </select>
                                                </div>
                                                <div className="col-4">
                                                    <input className='w-100 form-control' value={amount} onChange={(e) => {
                                                        setAmount(e.target.value);
                                                    }}></input>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-2">
                                            <div className="row">
                                                <div className="col-12 text-end">
                                                    <button className="btn btn-sm" onClick={handleEditRow}><i className="bi bi-plus-square"></i></button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )
                            }
                            return (
                                <div className="proceedure-code-data-row border-bottom mb-3">
                                    <div className="row align-items-center">
                                        <div className="col-10">
                                            <div className="row align-items-center">
                                                <div className="col-4">
                                                    <div>{item.category}</div>
                                                </div>
                                                <div className="col-4">
                                                    <div>{item.code}</div>
                                                </div>
                                                <div className="col-4">
                                                    <div>{item.amount}</div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-2">
                                            <div className="row">
                                                <div className="col-12 text-end">
                                                    {
                                                        selectedIndex !== index &&
                                                        <>
                                                            <button className='btn btn-sm' onClick={handleEdit}><i className="bi bi-pencil"></i></button>
                                                            <button className='btn btn-sm' onClick={handleDelete}><i className="bi bi-trash"></i></button>
                                                        </>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )
                        })
                    }
                    {
                        selectedIndex === -1 &&
                        <div className="row">
                            <div className="col-10">
                                <div className="row">
                                    <div className="col-4">
                                        <select className='w-100 form-select' value={category} onChange={(e) => {
                                            setCategory(e.target.value);
                                            fetchCategoryCode(e.target.value);
                                        }}>
                                            <option value="" disabled>Select Category</option>
                                            {
                                                appointmentCategoryList.map((item: any, index: number) => {
                                                    return (<option value={item.procedure_code_category}>{item.procedure_code_category}</option>)
                                                })
                                            }
                                        </select>
                                    </div>
                                    <div className="col-4">
                                        <select className='w-100 form-select' value={proceedureCode} onChange={(e) => {
                                            setProceedureCode(e.target.value);
                                            const code: any = categoryCodeList.find((_item: any) => _item.procedure_code_sikkaId === e.target.value);
                                            setAmount(code?.procedure_code_price ?? 0);
                                        }}>
                                            <option value="" disabled>Select Code</option>
                                            {
                                                categoryCodeList.map((item: any, index: number) => {
                                                    return (<option value={item.procedure_code_sikkaId}>{item.procedure_code_sikkaId}</option>)
                                                })
                                            }
                                        </select>
                                    </div>
                                    <div className="col-4">
                                        <input className='w-100 form-control' value={amount} onChange={(e) => {
                                            setAmount(e.target.value);
                                        }}></input>
                                    </div>
                                </div>
                            </div>
                            <div className="col-2">
                                <div className="row">
                                    <div className="col-12 text-end">
                                        <button className="btn btn-sm" onClick={handleAdd}><i className="bi bi-plus-square"></i></button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    }
                </div>
                {/* Table html end*/}
            </Modal.Body>
            <Modal.Footer>
                <button className="btn btn-outline-primary" disabled={isLoading} type="button" onClick={hideModal}>Cancel</button>
                <button className="btn btn-primary" disabled={isLoading} onClick={scheduleAppointment}>
                    {
                        isLoading &&
                        <span className="spinner-border spinner-border-sm me-1" role="status" aria-hidden="true"></span>
                    }
                    {
                        isReschedule ? 'Reschedule' : 'Schedule'
                    }
                </button>
            </Modal.Footer>
        </Modal>
    )
}