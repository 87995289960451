import {
    BillingRequest, BillingPendingAction, BILLING, BillingResponse, BillingSuccessAction,
    BillingErrorAction, AddCardRequest, AddCardPendingAction, AddCardErrorAction, AddCardResponse,
    AddCardSuccessAction, RemoveCardRequest, RemoveCardPendingAction, RemoveCardErrorAction, RemoveCardSuccessAction,
    RemoveCardResponse, EnrolCardRequest, EnrolCardErrorAction, EnrolCardPendingAction, EnrolCardResponse,
    EnrolCardSuccessAction, BillableEntryFetchRequest, BillableEntryFetchPendingAction, BillableEntryFetchErrorAction,
    BillableEntryFetchResponse, BillableEntryFetchSuccessAction, BillableEntryAppointmentPendingAction,
    BillableEntryAppointmentRequest, BillableEntryAppointmentErrorAction, BillableEntryAppointmentResponse,
    BillableEntryAppointmentSuccessAction,
    DeferRequestResponse, DeferRequestResponsePending, DeferRequestSuccessAction, DeferRequestErrorAction, DeferRequestType
} from './types';
export const fetchBillingPendingAction = (request: BillingRequest): BillingPendingAction => {
    return {
        type: BILLING.BILLING_LOAD_PENDING,
        payload: request
    }
}
export const fetchBillingSuccessAction = (response: BillingResponse): BillingSuccessAction => {
    return {
        type: BILLING.BILLING_LOAD_SUCCESS,
        billing: response
    }
}
export const fetchBillingErrorAction = (error: any): BillingErrorAction => {
    return {
        type: BILLING.BILLING_LOAD_ERROR,
        error: error
    }
}

/* add card action creators */
export const addCardPendingAction = (request: AddCardRequest): AddCardPendingAction => {
    return {
        type: BILLING.ADD_CARD_PENDING,
        payload: request
    }
}
export const addCardErrorAction = (error: any): AddCardErrorAction => {
    return {
        type: BILLING.ADD_CARD_ERROR,
        error: error
    }
}
export const addCardSuccessAction = (response: AddCardResponse): AddCardSuccessAction => {
    return {
        type: BILLING.ADD_CARD_SUCCESS,
        addCard: response
    }
}

/* remove card action creator */
export const removeCardPendingAction = (request: RemoveCardRequest): RemoveCardPendingAction => {
    return {
        type: BILLING.REMOVE_CARD_PENDING,
        payload: request
    }
}
export const removeCardErrorAction = (error: any): RemoveCardErrorAction => {
    return {
        type: BILLING.REMOVE_CARD_ERROR,
        error: error
    }
}
export const removeCardSuccessAction = (response: RemoveCardResponse): RemoveCardSuccessAction => {
    return {
        type: BILLING.REMOVE_CARD_SUCCESS,
        removeCard: response
    }
}

/* enrol card to provider */
export const enrolCardPendingAction = (request: EnrolCardRequest): EnrolCardPendingAction => {
    return {
        type: BILLING.ENROLL_CARD_PENDING,
        payload: request
    }
}
export const enrolCardErrorAction = (error: any): EnrolCardErrorAction => {
    return {
        type: BILLING.ENROLL_CARD_ERROR,
        error: error
    }
}
export const enrolCardSuccessAction = (response: EnrolCardResponse): EnrolCardSuccessAction => {
    return {
        type: BILLING.ENROLL_CARD_SUCCESS,
        enrolCard: response
    }
}

/* billable entry fetch year/months */
export const billableEntryFetchPendingAction = (request: BillableEntryFetchRequest): BillableEntryFetchPendingAction => {
    return {
        type: BILLING.BILLABLE_YEAR_FETCH_PENDING,
        payload: request
    }
};
export const billableEntryFetchErrorAction = (error: any): BillableEntryFetchErrorAction => {
    return {
        type: BILLING.BILLABLE_YEAR_FETCH_ERROR,
        error: error
    }
};
export const billableEntryFetchSuccessAction = (response: BillableEntryFetchResponse): BillableEntryFetchSuccessAction => {
    return {
        type: BILLING.BILLABLE_YEAR_FETCH_SUCCESS,
        billableEntryFetch: response
    }
};

/* Billable entry appointment */
export const billableEntryAppointmentPendingAction = (request: BillableEntryAppointmentRequest): BillableEntryAppointmentPendingAction => {
    return {
        type: BILLING.BILLABLE_APPOINTMENT_FETCH_PENDING,
        payload: request
    }
};



export const billableEntryAppointmentErrorAction = (error: any): BillableEntryAppointmentErrorAction => {
    return {
        type: BILLING.BILLABLE_APPOINTMENT_FETCH_ERROR,
        error: error
    }
};
export const billableEntryAppointmentSuccessAction = (response: BillableEntryAppointmentResponse[]): BillableEntryAppointmentSuccessAction => {
    return {
        type: BILLING.BILLABLE_APPOINTMENT_FETCH_SUCCESS,
        billableEntryAppointment: response
    }
};


// for defer request


export const deferRequestPendingAction = (request: DeferRequestType): DeferRequestResponsePending => {
    return {
        type: BILLING.DEFER_REQUEST_PENDING,
        payload: request
    }
}
export const deferRequestSuccessAction = (response: DeferRequestResponse): DeferRequestSuccessAction => {
    return {
        type: BILLING.DEFER_REQUEST_SUCCESS,
        payload: response
    }
}

export const deferRequestErrorAction = (err: any): DeferRequestErrorAction => {
    return {
        type: BILLING.DEFER_REQUEST_ERROR,
        payload: err
    }
}