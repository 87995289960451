import { all } from 'redux-saga/effects';
import loginForgotSentPendingWatcher from './loginForgotSent';
import loginPendingWatcher, { logoutWatcher } from './login';
import forgotPasswordWatcher from './forgotPassword';
import userInfoPendingWatcher from './userInfo';
import userEmailPendingWatcher from './userEmail';
import userPasswordPendingWatcher from './userPassword';
import userDataPendingWatcher, {
  userUploadPhotoPendingWatcher, userNameChangePendingWatcher,
  emailVerificationPendingWatcherUserData
} from './userData';
import settingsPendingWatcher, {
  settingsAddPendingWatcher, settingsRemovePendingWatcher, settingsNotificationChangePendingWatcher, specialityActionPendingWatcher, adminSafetyFeePendingWatcher,
  emailVerificationPendingWatcherSettings, acceptTCPendingWatcher, reviewSavePendingWatcher
} from './settings';
import profilePendingWatcher, {
  questionPendingWatcher, featurePendingWatcher,
  laguagePendingWatcher, updateDescriptionPendingWatcher, imageRemoveWatcher, imageUploadPhotoPendingWatcher, emailVerificationPendingWatcher, hygieneFeaturePendingWatcher
} from './profile';
import providerPendingWatcher, { servicePendingWatcher, providerUpdateWatcher, providerMappingPendingWatcher, pmsProviderScheduleUpdateWatcher } from './provider';
import { checkClinicEmailPendingWatcher, checkClinicNamePendingWatcher, clinicAddPendingWatcher, checkClinicPhonePendingWatcher } from "./addClinic";
import fetchSpecialityPendingWatcher, { providerAddPendingWatcher } from './addProvider';
import appointmentPendingWatcher, { appointmentDetailWatcher, modalEmailInfoWatcher, manualAppointmentWatcher, requestForMedicalHistoyWatcher, appointmentEditWatcher, bookSlotWatcher, appointmentUnverifiedWatcher } from './appointment';
import fetchBillingPendingWatcher, { addCardPendingWatcher, removeCardPendingWatcher, enrolCardPendingWatcher, billableEntryFetchWatcher, billableEntryAppointmentWatcher, deferRequestWatcher } from './billing';
import { fetchInitPaymentPendingWatcher, paymentBillableEntryPendingWatcher, paymentBillableAppointmentPendingWatcher, servicesTypePendingWatcher, createInvoicePendingWatcher, stripeSaveAccountIdPendingWatcher, saveTaxPendingWatcher, paymentAmountWatcher } from './payment';
import Employee from './employee';
import PMSAppointments from './pmsappointments';
import SubscriptionPlan from './subscriptionPlan';
import MessageCenter from './messageCenter';

export default function* rootSaga() {
  yield all([
    loginForgotSentPendingWatcher(),
    loginPendingWatcher(),
    forgotPasswordWatcher(),
    userInfoPendingWatcher(),
    userEmailPendingWatcher(),
    userPasswordPendingWatcher(),
    userDataPendingWatcher(),
    logoutWatcher(),
    settingsPendingWatcher(),
    settingsAddPendingWatcher(),
    settingsRemovePendingWatcher(),
    settingsNotificationChangePendingWatcher(),
    imageUploadPhotoPendingWatcher(),
    userNameChangePendingWatcher(),
    profilePendingWatcher(),
    questionPendingWatcher(),
    featurePendingWatcher(),
    laguagePendingWatcher(),
    updateDescriptionPendingWatcher(),
    imageRemoveWatcher(),
    providerPendingWatcher(),
    servicePendingWatcher(),
    providerUpdateWatcher(),
    checkClinicEmailPendingWatcher(),
    checkClinicNamePendingWatcher(),
    clinicAddPendingWatcher(),
    fetchSpecialityPendingWatcher(),
    providerAddPendingWatcher(),
    providerMappingPendingWatcher(),
    userUploadPhotoPendingWatcher(),
    specialityActionPendingWatcher(),
    emailVerificationPendingWatcher(),
    emailVerificationPendingWatcherSettings(),
    emailVerificationPendingWatcherUserData(),
    checkClinicPhonePendingWatcher(),
    appointmentPendingWatcher(),
    fetchBillingPendingWatcher(),
    addCardPendingWatcher(),
    removeCardPendingWatcher(),
    enrolCardPendingWatcher(),
    acceptTCPendingWatcher(),
    reviewSavePendingWatcher(),
    appointmentDetailWatcher(),
    hygieneFeaturePendingWatcher(),
    billableEntryFetchWatcher(),
    billableEntryAppointmentWatcher(),
    deferRequestWatcher(),
    fetchInitPaymentPendingWatcher(),
    paymentBillableEntryPendingWatcher(),
    paymentBillableAppointmentPendingWatcher(),
    servicesTypePendingWatcher(),
    createInvoicePendingWatcher(),
    stripeSaveAccountIdPendingWatcher(),
    saveTaxPendingWatcher(),
    paymentAmountWatcher(),
    modalEmailInfoWatcher(),
    manualAppointmentWatcher(),
    requestForMedicalHistoyWatcher(),
    Employee(),
    appointmentEditWatcher(),
    pmsProviderScheduleUpdateWatcher(),
    PMSAppointments(),
    SubscriptionPlan(),
    MessageCenter(),
    bookSlotWatcher(),
    adminSafetyFeePendingWatcher(),
    appointmentUnverifiedWatcher()
    // add other watchers to the array
  ]);
}