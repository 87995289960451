import React from 'react';
import logo from '../../assets/images/logo/logo.svg';
import { connect } from 'react-redux';
import { fetchLoginForgotSentPending } from '../../store/loginforgotsent/actions';
import { IAppState } from '../../store';
import CONSTANT_LABELS from '../../constants/labels';
import { LABEL_CONSTANTS, TITLE_CONSTANTS } from '../../constants';
import Helmet from 'react-helmet';
import { checkIfEmptyOrNull, emailValidator } from '../../utils/validators';
import { ForgotPasswordRequest, ForgotPasswordState } from '../../store/forgotpassword/types';
import { fetchForgotPasswordPending } from '../../store/forgotpassword/actions';
import { NavLink } from 'react-router-dom';
interface Props {
    changeForgotPassword: typeof Function,
    forgotPassword: typeof fetchForgotPasswordPending;
    forgotPasswordState: ForgotPasswordState;
}
interface State {
    emailInput: string;
    emailInputRequired: boolean;
    emailInvalid: boolean;
    isLoading: boolean;
    femailValidation: { valid: boolean, msg: string };
}
class ForgotPassword extends React.Component<Props, State> {
    constructor(props: any) {
        super(props);
        this.state = {
            emailInput: '',
            emailInputRequired: false,
            emailInvalid: false,
            isLoading: false,
            femailValidation: { valid: true, msg: '' }
        }
    }
    handleClickSignIn(e: any) {
        this.props.changeForgotPassword('login');
    }
    handleSendLink(e: any) {
        e.preventDefault();
        if (checkIfEmptyOrNull(this.state.emailInput)) {
            this.setState({ emailInputRequired: true });
            return;
        } else if (!emailValidator(this.state.emailInput)) {
            this.setState({ emailInvalid: true });
            return;
        }
        this.setState({
            femailValidation: {
                valid: true,
                msg: ''
            },
            isLoading: true
        });
        const forgotPasswordData: ForgotPasswordRequest = {
            email: this.state.emailInput,
            modelType: 'user'
        }
        this.props.forgotPassword(forgotPasswordData);
        // this.props.changeForgotPassword('resetlink');
    }
    handleEmailChange(e: any) {
        if (this.state.emailInputRequired) {
            this.setState({ emailInputRequired: false });
        }
        if (this.state.emailInvalid) {
            this.setState({ emailInvalid: false });
        }
        this.setState({ emailInput: e.target.value });
    }
    shouldComponentUpdate(nextProps: Props, nextState: State) {
        if (this.props.forgotPasswordState !== nextProps.forgotPasswordState) {
            if (nextProps.forgotPasswordState.error !== null) {
                this.setState({ isLoading: false });
                if (nextProps.forgotPasswordState.error.status && nextProps.forgotPasswordState.error.status.error === true) {
                    this.setState({
                        femailValidation: {
                            valid: false,
                            msg: nextProps.forgotPasswordState.error.status.msg
                        }
                    });
                } else {
                    this.setState({
                        femailValidation: {
                            valid: false,
                            msg: 'Something went wrong'
                        }
                    });
                }
            } else {
                if (nextProps.forgotPasswordState.pending === false) {
                    if (nextProps.forgotPasswordState.forgotPasswordResponse && nextProps.forgotPasswordState.forgotPasswordResponse.status &&
                        nextProps.forgotPasswordState.forgotPasswordResponse.status.error === false) {
                        this.setState({ emailInput: '', isLoading: false });
                        this.setState({ femailValidation: { valid: false, msg: nextProps.forgotPasswordState.forgotPasswordResponse.status.msg } });
                    }
                }
            }
        }
        return true;
    }
    componentDidUpdate() {
        this.props.forgotPasswordState.error = null;
        this.props.forgotPasswordState.forgotPasswordResponse.status.error = true;
    }
    render() {
        return (
            <>
                <Helmet><title>{TITLE_CONSTANTS.TITLES.FORGOT_PASSWORD}</title></Helmet>
                <div className="card shadow-sm">

                    <div className="card-header bg-light px-4 py-3">
                        <div className="d-flex align-items-center justify-content-between">
                            <img src={logo} alt="Logo" width="100px" />
                            {/* <div className="fs-5 fw-medium">{LABEL_CONSTANTS.LABELS.FORGOT_PASSWORD}</div> */}
                        </div>
                    </div>

                    <div className="card-body pe-4">
                        <form>
                            {(this.state.femailValidation.valid === false) ?
                                <div className="alert alert-danger py-2" role="alert">
                                    {this.state.femailValidation.msg}
                                </div>

                                : <></>
                            }
                            <div className="mb-3">
                                <label className="form-label" htmlFor="email">{CONSTANT_LABELS.LABELS.EMAIL}</label>
                                <input type="email" className={"form-control " + (this.state.emailInvalid === true ? "is-invalid" : "")} placeholder="Email address"
                                    value={this.state.emailInput}
                                    onInput={(e: any) => { this.handleEmailChange(e) }}
                                    onChange={(e) => { this.handleEmailChange(e) }} />
                                {(this.state.emailInputRequired === true) ? <div className="is-invalid">{CONSTANT_LABELS.REQUIRED.EMAIL}</div> : <></>}
                            </div>
                            <div className="">
                                <div className="d-flex align-items-center justify-content-between">
                                    <span>
                                        <NavLink to="" className="text-decoration-none link-danger" onClick={(e) => this.handleClickSignIn(e)}><i className="bi bi-arrow-left-short"></i> {CONSTANT_LABELS.LINK.RETURN_TO_SIGNIN}</NavLink>
                                    </span>
                                    <button disabled={this.state.isLoading} className="btn btn-primary" onClick={(e) => this.handleSendLink(e)}>
                                        {
                                            this.state.isLoading &&
                                            <span className="spinner-border spinner-border-sm me-1" role="status" aria-hidden="true"></span>
                                        }
                                        {CONSTANT_LABELS.BUTTON_LABEL.SEND_LINK}</button>
                                </div>

                            </div>
                        </form>
                    </div>
                </div>
            </>
        );
    }
}
const mapDispatchToProps = (dispatch: any) => ({
    changeForgotPassword: (doShow: string) => {
        dispatch((fetchLoginForgotSentPending({ loginforgotsent: doShow })));
    },
    forgotPassword: (forgotPassword: ForgotPasswordRequest) => {
        dispatch(fetchForgotPasswordPending(forgotPassword));
    }
});
const mapStateToProps = (state: IAppState) => ({
    loginForgotSentState: state.loginForgotSent,
    forgotPasswordState: state.forgotPassword
});
export default connect(mapStateToProps, mapDispatchToProps)(ForgotPassword);