import React, { useEffect, useState } from 'react';
import { searchPatientApi } from '../../../services/chat';
import { IAppState } from '../../../../store';
import { useSelector, shallowEqual } from 'react-redux';
export const SearchDependent = (props: any) => {
    const [patientName, setPatientName] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const userData = useSelector((state: IAppState) => state.userData.userDataResponse, shallowEqual);
    useEffect(() => {
        if (patientName !== '') {
            props.loader(true);
            searchPatientApi(userData.clinics[0].uniqueId, patientName).then((success) => {
                if (success && success.status && success.status.error === false) {
                    setIsLoading(false);
                    props.searchDependentList(success.patients);
                }
            }).catch((err) => {
                console.log(err);
            })
        }
    }, [patientName]);
    return (
        <>
            <div className="flex-grow-1">
                <input type="text" className="form-control" placeholder="Search patient..." value={patientName}
                    onChange={(e: any) => {
                        if (e.target.value) {
                            setPatientName(e.target.value)
                        } else if (e.target.value === '') {
                            setPatientName(e.target.value)
                            props.searchDependentList([]);
                        }
                    }} />
            </div>
        </>
    )
}