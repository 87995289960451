import React from 'react';
import { NavLink } from 'react-router-dom';
export const SubscriptionHeader = () => {
  return (
    <section className="middle-navbar px-30">
      <div className="p-0 container-fluid">
        <div className="d-flex justify-content-between">
          <div>
            <div className="nav-title-txt">Subscription</div>
            <ul className="nav nav-tabs">
              <li className="nav-item">
                <NavLink className="nav-link" exact={true} to={"/subscription/overview"}>Overview</NavLink>
              </li>
              <li className="nav-item">
                <NavLink className="nav-link" exact={true} to={"/subscription/plans"}>Subscription plan</NavLink>
              </li>
              <li className="nav-item">
                <NavLink className="nav-link" exact={true} to={"/subscription/manage-plans"}>Manage plan</NavLink>
              </li>
              <li className="nav-item">
                <NavLink className="nav-link" exact={true} to={"/subscription/invoices"}>Invoices</NavLink>
              </li>
              <li className="nav-item">
                <NavLink className="nav-link" exact={true} to={"/subscription/manage-cards"}>Manage cards</NavLink>
              </li>
              <li className="nav-item">
                <NavLink className="nav-link" exact={true} to={"/subscription/billing-address"}>Billing address</NavLink>
              </li>
            </ul>
          </div>

          {/* <div className="stp-action">
            <button type="button" className="btn btn-outline-primary"><i className="bi bi-plus me-2"></i><span>Add new</span></button>
          </div> */}

        </div>
      </div>
    </section>
  )
}