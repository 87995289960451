import React from "react";
import { useEffect, useState } from "react";
import { Modal, Button } from 'react-bootstrap';
import { useDispatch, useSelector } from "react-redux";
import { IAppState } from "../../store";
import { convertToSystemDisplayDateTimeAtom } from "../../v2/helpers/datetime";
import { createNote, getNotesList } from "../../v2/services/notes";
export const NoteModal = ({ handleCancel, handleOk, data }: any) => {
    const state = useSelector((state: IAppState) => state);
    const clinic: any = state.userData.userDataResponse.clinics[0];
    const [noteList, setNoteList] = useState([]);
    const [note, setNote] = useState("");
    const [attentionRequired, setAttentionRequired] = useState(false);
    const dispatch = useDispatch();
    useEffect(() => {
        getNotesListFn();
    }, []);
    const getNotesListFn = () => {
        getNotesList(clinic.uniqueId, data.uniqueid).then((res) => {
            if (res) {
                setNoteList(res.notes);
            }
        }).catch((err) => {
        });
    }
    const createNoteFn = () => {
        if (note.length === 0) return;
        createNote(clinic.uniqueId, {
            appointmentId: data.uniqueid,
            text: note,
            attentionRequired: attentionRequired
        }).then((res) => {
            setNote("");
            setAttentionRequired(false);
            getNotesListFn();
        }).catch((err) => {
            console.log(err);
        })
    }
    return (
        <Modal centered show={true} onHide={handleCancel}>
            <Modal.Header>Appointment Notes</Modal.Header>
            <Modal.Body>
                <div className="d-flex flex-column overflow-y-auto">
                    <div className="w-100" style={{
                        height: 300,
                        overflowY: "auto"
                    }}>
                        {
                            noteList.map((item: any) => {
                                return (
                                    <div className="w-100 d-flex flex-column border-bottom" key={item.id}>
                                        <label>
                                            {item.note}
                                        </label>
                                        <div className="d-flex flex-column w-100 text-end">
                                            <label>By: {item.createdBy}</label>
                                            <small>Received on: {convertToSystemDisplayDateTimeAtom(item.createdAt)}</small>
                                        </div>
                                    </div>
                                )
                            })
                        }
                    </div>
                    <div className="mb-3">
                        <textarea rows={3} cols={3} className="form-control mt-4" placeholder="Type note" value={note} onChange={(e) => {
                            setNote(e.target.value);
                            if (e.target.value.length === 0) setAttentionRequired(false);
                        }} />
                    </div>
                    <div className="mb-3 form-check">
                        <input type="checkbox" className="form-check-input" id="attentionRequired" disabled={note.length === 0} checked={attentionRequired} onChange={(e) => {
                            setAttentionRequired(!attentionRequired);
                        }} />
                        <label className="form-check-label" htmlFor="attentionRequired">Attention required</label>
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={() => {
                    handleCancel();
                }}>Close</Button>
                <Button disabled={note.length === 0} variant="primary" onClick={createNoteFn}>Submit</Button>
            </Modal.Footer>
        </Modal>
    )
}