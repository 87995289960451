import React, { Component } from 'react';
import Axios from 'axios';
import { attachHeader } from '../../utils/apiHandler';
import configs from '../../configs/apiConfigs';
import { UserDataResponse } from '../../store/userData/types';
import { connect } from 'react-redux';
import { IAppState } from '../../store';
import moment from 'moment';
import { fetchLoadingPending, fetchLoadingSuccess } from '../../store/loadingIndicator/actions';
import DownloadPdf from './images/download-pdf.svg';
import RoundedPdf from './images/rounded-pdf.svg';
import ContactlessIcon from './images/contactless-payments.svg';
import InfoIcon from './images/info-icon.svg';
import UpgradePlan from './images/upgrade-plan-img.svg';

interface Props {
    userDataResponse: UserDataResponse;
    history: any;
    loadingStart: typeof fetchLoadingPending;
    loadingEnd: typeof fetchLoadingSuccess;
}
interface State {
    currentPlan: any;

}
class Overview extends Component<Props, State>{
    constructor(props: any) {
        super(props);
        this.state = {
            currentPlan: []
        }
    }
    componentDidMount() {
        if (this.props.userDataResponse && this.props.userDataResponse.clinics && this.props.userDataResponse.clinics.length > 0) {
            this.props.loadingStart();
            const url = configs.API_URL + 'prime/clinic' + '/' + this.props.userDataResponse.clinics[0].uniqueId + '/clinic-current-plans/';
            Axios.get(url, { headers: attachHeader(true), withCredentials: true }).then((success) => {
                if (success.data && success.data.active_plans.length > 0) {
                    this.setState({ currentPlan: success.data });
                    this.props.loadingEnd(false);
                }
            }).catch((error: any) => {
                this.props.loadingEnd(false);
                return ''
            });
        }
    }
    componentDidUpdate(prevProps: Props, prevState: State) {
        if (prevProps.userDataResponse !== this.props.userDataResponse) {
            if (this.props.userDataResponse && this.props.userDataResponse.clinics && this.props.userDataResponse.clinics.length > 0) {
                this.props.loadingStart();
                const url = configs.API_URL + 'prime/clinic' + '/' + this.props.userDataResponse.clinics[0].uniqueId + '/clinic-current-plans/';
                Axios.get(url, { headers: attachHeader(true), withCredentials: true }).then((success) => {
                    if (success.data && success.data.active_plans.length > 0) {
                        this.setState({ currentPlan: success.data });
                        this.props.loadingEnd(false);
                    }
                }).catch((error: any) => {
                    this.props.loadingEnd(false);
                    return ''
                });
            }
        }
    }
    advertiseUpgradePlan = () => {
        this.props.history.push('/user/plans', { screenname: 'overview', planName: 'Recall and Recare Monthly' })
    }
    render() {
        return (
            <section className="subscription-sec">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="my-5 px-4 py-3 border-1 rounded warning-box upgrade-banner-box">
                                <div className="row">
                                    <div className="align-self-center col-md-auto">
                                        <div className="fs-3 fw-bolder banner-txt">Re-Call & Re-Care</div>
                                    </div>
                                    <div className="align-self-center col">
                                        <div className="banner-desc"><p>Keep patients notified of their appointment details and increase return rate</p></div>
                                    </div>
                                    <div className="col-md-auto">
                                        <div className="position-relative banner-img h-100">
                                            <img src={UpgradePlan} className="position-absolute" />
                                        </div>
                                    </div>
                                    <div className="align-self-center col-md-auto">
                                        <button onClick={() => { this.advertiseUpgradePlan() }} className="btn btn-warning text-light px-4 py-2">Upgrade plan</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-xl-8 col-xxl-9">
                            <div className="border p-4 border-1 rounded bg-light custom-card mt-4 default-card">
                                {
                                    (this.state.currentPlan && this.state.currentPlan.active_plans &&
                                        this.state.currentPlan.active_plans.length > 0) &&
                                    <>
                                        <div className="d-flex justify-content-between">
                                            {
                                                this.state.currentPlan.active_plans.map((item: any) => {
                                                    if (item.plan_type !== "custom") {
                                                        return (
                                                            <>
                                                                <div>
                                                                    <div className="badge rounded-pill bg-dark fw-bolder mb-2">Current plan</div>
                                                                    <div className="info-box fs-5 fw-bolder">{item.name}</div>
                                                                </div>
                                                                <div className="align-self-center">
                                                                    <div className="d-flex">
                                                                        <div className="pe-2 price-tag">
                                                                            <span className="fs-6 pe-1 position-relative">$</span>
                                                                            <span className="fw-bolder fs-1">{item.price}</span>
                                                                            / {item.interval}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </>
                                                        )

                                                    }
                                                })
                                            }

                                        </div>
                                    </>
                                }
                                {
                                    (this.state.currentPlan && this.state.currentPlan.active_plans &&
                                        this.state.currentPlan.active_plans.length > 0) &&
                                    <div className="addon-list my-4">
                                        <small className="fw-bolder">Addons</small>
                                        {
                                            this.state.currentPlan.active_plans.map((item: any) => {
                                                if (item.plan_type === "cusome") {
                                                    return (
                                                        <div className="d-flex mb-2 mt-2 border p-4 border-1 rounded bg-light custom-card default-card bg-white justify-content-between">
                                                            <div className="d-flex">
                                                                <img className="me-3 align-self-center" src={ContactlessIcon} />
                                                                <div>
                                                                    <small className="fw-bolder text-muted">Customer payment</small>
                                                                    <div className="info-box fs-5 fw-bolder">{item.name}</div>
                                                                </div>
                                                            </div>
                                                            <div className="pe-2 price-tag align-self-center">
                                                                <span className="fs-6 pe-1 position-relative">$</span>
                                                                <span className="fw-bolder fs-1">{item.price}</span>
                                                            </div>
                                                        </div>
                                                    )
                                                }
                                            })
                                        }

                                    </div>
                                }

                                <div className="d-flex justify-content-between mt-4">
                                    <div className="text-muted small align-self-center"><img src={InfoIcon} className="me-2" />You can add more addons form plan page</div>
                                    <div onClick={() => { this.props.history.push('/user/plans', { screenname: 'overview', type: this.state.currentPlan.active_plans[0].plan_type, interval: this.state.currentPlan.active_plans[0].interval }) }} className="btn btn-outline-primary fw-bold bg-white px-4">Upgrade plan</div>
                                </div>
                            </div>
                        </div>
                        {
                            (this.state.currentPlan && this.state.currentPlan.plans_exp &&
                                this.state.currentPlan.plans_exp.length > 0) &&
                            <div className="col-xl-4 col-xxl-3 align-items-stretch">
                                <div className="sidebar p-4 border border-1 rounded bg-light mt-4 default-card addon-sidebar">
                                    {
                                        this.state.currentPlan.plans_exp.map((item: any) => {
                                            return (
                                                <div className="d-flex align-items-start flex-column bd-highlight mb-3 h-100">
                                                    <div className="pe-2 price-tag bd-highlight">
                                                        <span className="fs-6 pe-1 position-relative">$</span>
                                                        <span className="fw-bolder fs-1">{item.price}</span>
                                                    </div>
                                                    <div className="next-date mt-2 bd-highlight">
                                                        <small className="d-block fw-bold text-muted">Next payment</small>
                                                        <div className="fw-bolder fs-5">on {moment(item.end_date).format('MMM DD, YYYY')}</div>
                                                    </div>
                                                    <button className="btn btn-outline-primary fw-bold bg-white px-4 bd-highlight mt-auto">Manage payments</button>
                                                </div>
                                            )
                                        })
                                    }

                                </div>
                            </div>
                        }

                    </div>
                    <hr className="my-5" />
                    <div className="row">
                        <div className="col-12">
                            <div className="d-flex justify-content-between mb-3">
                                <div className="fw-bolder">Recent invoices</div>
                                <button className="btn btn-link p-0">View all invoices</button>
                            </div>
                        </div>
                        <div className="col-12">
                            <ul className="recent-invoice-list p-0">
                                <li className="d-flex justify-content-between px-4">
                                    <div className="li-box"></div>
                                    <div className="fw-bolder small">Created Date</div>
                                    <div className="fw-bolder small">Total</div>
                                    <div className="fw-bolder small">Status</div>
                                    <div className="li-box"></div>
                                </li>
                                <li className="content-box d-flex justify-content-between border border-1 rounded default-card px-4 py-3 my-2">
                                    <div className="d-flex">
                                        <img src={RoundedPdf} />
                                        <span className="align-self-center ms-3">FAC28D5C-0084.pdf</span>
                                    </div>
                                    <div className="align-self-center"><small>01 December 1996</small></div>
                                    <div className="align-self-center"><small>$445</small></div>
                                    <div className="align-self-center"><span className="badge badge-paid rounded-pill">Paid</span></div>
                                    <div className="align-self-center"><button className="btn"><img src={DownloadPdf} /></button></div>
                                </li>
                                <li className="content-box d-flex justify-content-between border border-1 rounded default-card px-4 py-3 my-2">
                                    <div className="d-flex">
                                        <img src={RoundedPdf} />
                                        <span className="align-self-center ms-3">FAC28D5C-0084.pdf</span>
                                    </div>
                                    <div className="align-self-center"><small>01 December 1996</small></div>
                                    <div className="align-self-center"><small>$445</small></div>
                                    <div className="align-self-center"><span className="badge badge-paid rounded-pill">Paid</span></div>
                                    <div className="align-self-center"><button className="btn"><img src={DownloadPdf} /></button></div>
                                </li>
                                <li className="content-box d-flex justify-content-between border border-1 rounded default-card px-4 py-3 my-2">
                                    <div className="d-flex">
                                        <img src={RoundedPdf} />
                                        <span className="align-self-center ms-3">FAC28D5C-0084.pdf</span>
                                    </div>
                                    <div className="align-self-center"><small>01 December 1996</small></div>
                                    <div className="align-self-center"><small>$445</small></div>
                                    <div className="align-self-center"><span className="badge badge-paid rounded-pill">Paid</span></div>
                                    <div className="align-self-center"><button className="btn"><img src={DownloadPdf} /></button></div>
                                </li>
                                <li className="content-box d-flex justify-content-between border border-1 rounded default-card px-4 py-3 my-2">
                                    <div className="d-flex">
                                        <img src={RoundedPdf} />
                                        <span className="align-self-center ms-3">FAC28D5C-0084.pdf</span>
                                    </div>
                                    <div className="align-self-center"><small>01 December 1996</small></div>
                                    <div className="align-self-center"><small>$445</small></div>
                                    <div className="align-self-center"><span className="badge badge-paid rounded-pill">Paid</span></div>
                                    <div className="align-self-center"><button className="btn"><img src={DownloadPdf} /></button></div>
                                </li>
                                <li className="content-box d-flex justify-content-between border border-1 rounded default-card px-4 py-3 my-2">
                                    <div className="d-flex">
                                        <img src={RoundedPdf} />
                                        <span className="align-self-center ms-3">FAC28D5C-0084.pdf</span>
                                    </div>
                                    <div className="align-self-center"><small>01 December 1996</small></div>
                                    <div className="align-self-center"><small>$445</small></div>
                                    <div className="align-self-center"><span className="badge badge-paid rounded-pill">Paid</span></div>
                                    <div className="align-self-center"><button className="btn"><img src={DownloadPdf} /></button></div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </section>
        )
    }
}
const mapStateToProps = (state: IAppState) => ({
    userDataResponse: state.userData.userDataResponse,

});
const mapDispatchToProps = (dispatch: any) => ({
    loadingStart: () => {
        dispatch(fetchLoadingPending());
    },
    loadingEnd: (status: any) => {
        dispatch(fetchLoadingSuccess(status));
    },

});
export default connect(mapStateToProps, mapDispatchToProps)(Overview);