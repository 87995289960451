export enum PROVIDER {
    LOAD_PENDING = 'LOAD_PENDING_PROVIDER',
    LOAD_ERROR = 'LOAD_FAILED_PROVIDER',
    LOAD_SUCCESS = 'LOAD_SUCCESS_PROVIDER',
    LOAD_SERVICE_PENDING = 'LOAD_PENDING_SERVICE',
    LOAD_SERVICE_ERROR = 'LOAD_FAILED_SERVICE',
    LOAD_SERVICE_SUCCESS = 'LOAD_SUCCESS_SERVICE',
    PROVIDER_UPDATE_ERROR = 'PROVIDER_UPDATE_ERROR',
    PROVIDER_UPDATE_PENDING = 'PROVIDER_UPDATE_PENDING',
    PROVIDER_UPDATE_SUCCESS = 'PROVIDER_UPDATE_SUCCESS',
    PROVIDER_MAPPING_PENDING = 'PROVIDER_MAPPING_PENDING',
    PROVIDER_MAPPING_SUCCESS = 'PROVIDER_MAPPING_SUCCESS',
    PROVIDER_MAPPING_ERROR = 'PROVIDER_MAPPING_ERROR',
    PMS_PROVIDER_SCHEDULE_UPDATE = 'PMS_PROVIDER_SCHEDULE_UPDATE',
    PMS_PROVIDER_SCHEDULE_UPDATE_ERROR = 'PMS_PROVIDER_SCHEDULE_UPDATE_ERROR',
    PMS_PROVIDER_SCHEDULE_UPDATE_SUCCESS = 'PMS_PROVIDER_SCHEDULE_UPDATE_SUCCESS'
};

export interface ProviderRequest {
    uniqueId: string;
    type: string;
}
export interface Status {
    error: boolean;
    msg: string;
}
export interface ProviderResponse {
    clinics: any[];
    doctors: any[];
    providers: any[];
    status: Status;
}
export interface ServiceRequest {
    speciality: string;
}
export interface ServiceResponse {
    services: any[];
    status: Status;
}
export interface ProviderUpdateRequest {
    type: string;
    uniqueId: string;
    id: string;
    userType: string;
    actionType: string;
    potentialAppointment?: any;
    hours?: any;
    services?: any;
    enableAppointment?: any;
}
export interface ProviderUpdateResponse {
    udpateData: any;
    potentialAppointmentTypes?: any;
    status: Status;
}
export interface ProviderMappingRequest {
    type: string;
    uniqueid: string;
    userUniqueId: string;
    id: string;
}
export interface ProviderMappingResponse {
    status: Status;
}
export interface PMSProviderScheduleUpdateRequest {
    clinicUniqueId: string;
    id: string;
    sikkaCode: string;
}
export interface PMSProviderScheduleUpdateResponse {
    status: Status;
}
export interface ProviderState {
    pending: boolean;
    provider: ProviderRequest;
    providerResponse: ProviderResponse;
    service: ServiceRequest;
    serviceResponse: ServiceResponse;
    providerUpdate: ProviderUpdateRequest;
    providerUpdateResponse: ProviderUpdateResponse;
    providerMapping: ProviderMappingRequest;
    providerMappingResponse: ProviderMappingResponse;
    pmsProviderScheduleUpdateRequest?: PMSProviderScheduleUpdateRequest;
    pmsProviderScheduleUpdateResponse?: PMSProviderScheduleUpdateResponse;
    error: any;
}

export interface FetchProviderPendingAction {
    type: PROVIDER.LOAD_PENDING;
    payload: ProviderRequest
}

export interface FetchProviderErrorAction {
    type: PROVIDER.LOAD_ERROR;
    error: any;
}
export interface FetchProviderSuccessAction {
    type: PROVIDER.LOAD_SUCCESS;
    provider: ProviderResponse;
}

export interface FetchServicePendingAction {
    type: PROVIDER.LOAD_SERVICE_PENDING;
    payload: ServiceRequest
}

export interface FetchServiceErrorAction {
    type: PROVIDER.LOAD_SERVICE_ERROR;
    error: any;
}
export interface FetchServiceSuccessAction {
    type: PROVIDER.LOAD_SERVICE_SUCCESS;
    service: ServiceResponse;
}

export interface ProviderUpdateErrorAction {
    type: PROVIDER.PROVIDER_UPDATE_ERROR,
    error: any;
}

export interface ProviderUpdateSuccessAction {
    type: PROVIDER.PROVIDER_UPDATE_SUCCESS,
    providerUpdate: ProviderUpdateResponse
}

export interface ProviderUpdatePendingAction {
    type: PROVIDER.PROVIDER_UPDATE_PENDING,
    payload: ProviderUpdateRequest
}
export interface ProviderMappingErrorAction {
    type: PROVIDER.PROVIDER_MAPPING_ERROR;
    error: any;
}
export interface ProviderMappingPendingAction {
    type: PROVIDER.PROVIDER_MAPPING_PENDING;
    payload: ProviderMappingRequest;
}
export interface ProviderMappingSuccessAction {
    type: PROVIDER.PROVIDER_MAPPING_SUCCESS;
    providerMapping: ProviderMappingResponse;
}
export interface PMSProviderScheduleUpdatePendingAction {
    type: PROVIDER.PMS_PROVIDER_SCHEDULE_UPDATE;
    payload: PMSProviderScheduleUpdateRequest;
}
export interface PMSProviderScheduleUpdateErrorAction {
    type: PROVIDER.PMS_PROVIDER_SCHEDULE_UPDATE_ERROR;
    payload: any;
}
export interface PMSProviderScheduleUpdateSuccessAction {
    type: PROVIDER.PMS_PROVIDER_SCHEDULE_UPDATE_SUCCESS;
    payload: PMSProviderScheduleUpdateResponse;
}
export type ProviderActionTypes =
    FetchProviderErrorAction | FetchProviderPendingAction | FetchProviderSuccessAction |
    FetchServicePendingAction | FetchServiceErrorAction | FetchServiceSuccessAction | ProviderUpdateErrorAction |
    ProviderUpdatePendingAction | ProviderUpdateSuccessAction |
    ProviderMappingPendingAction | ProviderMappingSuccessAction | ProviderMappingErrorAction |
    PMSProviderScheduleUpdatePendingAction | PMSProviderScheduleUpdateSuccessAction | PMSProviderScheduleUpdateErrorAction;

