import React, { useEffect, useMemo, useState } from 'react'
import Pagination from 'react-js-pagination';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { IAppState } from '../../../store';
import { MyScheduleHeader } from '../../components/mySchedule/MyScheduleHeader';
import { archiveEmergency, editAppointment, getAppointmentsEmergency } from '../../services/appointment';
import configs from '../../configs/apiConfigs';
import moment from 'moment';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { RequestForMedicalHistoryComp } from '../../components/mySchedule/RequestMedicalHistory';
import { storeChatData } from '../../../store/chat/action';
import { CancelAppointmentModal } from '../../components/mySchedule/CancelAppointmentModal';
import { AppointmentEditModal } from '../../components/mySchedule/AppointmentEditModal';
import { ScheduleReScheduleModal } from './ScheduleReScheduleModal';
import firebaseInstance from '../../helpers/firebase';
import { CHAT } from '../../../constants';
import { DigitalDoc } from '../../components/digitaldoc/DigitalDoc';
import { confirmAlert } from 'react-confirm-alert';
import { toast } from 'react-toastify';
import { callEmergencyApi, callWaitlistApi } from '../../../store/appointment/actions';
import CONSTANT_LABELS from '../../../constants/labels';
import { removeCountryCode } from '../../../utils/global';
import VideoImage from "../../assets/images/video-tutorial.png";
import { NoteModal } from '../../../components/note/NoteModal';
export const Emergency = () => {
    const [page, setPage] = useState(1);
    const [results, setResults] = useState(30);
    const [totalCount, setTotalCount] = useState(0);
    const [appointmentItemList, setAppointmentItemList] = useState([]);
    const userData = useSelector((state: IAppState) => state.userData.userDataResponse, shallowEqual);
    const dispatch = useDispatch();
    const [isLoading, setIsLoading] = useState(false);
    const [isRefreshing, setIsRefreshing] = useState(false);
    const [showNoteModal, setShowNoteModal] = useState(false);
    const [currentActiveModalAppointment, setCurrentActiveModalAppointment]: any = useState(null);
    useEffect(() => {
        return () => { }
    }, []);
    useEffect(() => { }, [userData]);
    useEffect(() => {
        fecthAppointmentListAsync();
    }, [page, results]);
    const fecthAppointmentListAsync = async (realTime = false) => {
        try {
            let modelType = 'clinic';
            let Id = userData.clinics[0].uniqueId;
            const request = {
                appointmentType: 'emergency',
                modelType: modelType,
                page: page,
                results: results,
                Id: Id
            };
            if (realTime === true) {
                setIsRefreshing(true);
            } else {
                setIsLoading(true);
            }
            const response = await getAppointmentsEmergency(request);
            if (response && response.status && response.status.error === false) {
                setAppointmentItemList(response.appointments);
                if (page === 1) {
                    setTotalCount(response.total);
                }
            }
            if (realTime === true) {
                setIsRefreshing(false);
            } else {
                setIsLoading(false);
            }
        } catch (err) {
            if (realTime === true) {
                setIsRefreshing(false);
            } else {
                setIsLoading(false);
            }
            console.log(err);
        }
    }
    const handlePageChange = (page: any) => {
        setPage(page);
    }
    const markApptDone = async (appointment: any) => {
        try {
            confirmAlert({
                title: '',
                message: "Are you sure you want to archive emergency triage request?",
                buttons: [
                    {
                        label: 'Yes',
                        className: 'btn btn-outline-primary',
                        onClick: async () => {
                            setIsRefreshing(true);
                            const response = await archiveEmergency({
                                change: "status",
                                status: "Archived",
                                id: appointment.id
                            }, userData.clinics[0].uniqueId);
                            setIsRefreshing(false);
                            if (response && response.status && response.status.error === false) {
                                fecthAppointmentListAsync(true);
                                dispatch(callEmergencyApi());
                                toast.success("Emergency request archived successfully.");
                            }
                        }
                    },
                    {
                        label: 'No',
                        className: 'btn btn-outline-primary',
                        onClick: () => {

                        }
                    }
                ]
            });
        } catch (err: any) {
            setIsRefreshing(false);
            if (err.msg) {
                toast.error(err.msg);
            } else {
                toast.error('Mark appt done failed.');
            }
        }
    }
    const handleShowNote = (appointment: any) => {
        setCurrentActiveModalAppointment(appointment);
        setShowNoteModal(true);
    }
    return (
        <React.Fragment>
            <MyScheduleHeader onFetchAppointment={fecthAppointmentListAsync}></MyScheduleHeader>
            <section className="schedule-sec waitlist-box px-3">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-12">
                            <table className="table waitlist-table">
                                <thead>
                                    <tr>
                                        <th scope="col">
                                            <div>NAME</div>
                                        </th>
                                        <th scope="col">
                                            <div>PROVIDER</div>
                                        </th>
                                        <th scope="col">APPT. DATE</th>
                                        <th scope="col">
                                            <div>Subject/Description</div>
                                        </th>
                                        <th scope="col">Photos/Videos</th>
                                        <th className="text-end" scope="col" style={{ width: "180px" }}>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        isLoading && !isRefreshing &&
                                        <tr className="spinner-border" role="status"></tr>
                                    }
                                    {
                                        isRefreshing && !isLoading &&
                                        <tr className="spinner-border" role="status"></tr>
                                    }
                                    {
                                        isRefreshing && isLoading &&
                                        <tr className="spinner-border" role="status"></tr>
                                    }
                                    {
                                        useMemo(() => {
                                            if (!isLoading && appointmentItemList.length === 0) {
                                                return <tr>No records found</tr>
                                            }
                                            if (userData && userData.clinics[0] && !isLoading) {
                                                return (
                                                    <>
                                                        {
                                                            appointmentItemList.map((item: any) => {
                                                                return (
                                                                    <ItemRow item={item} key={item.uniqueid}
                                                                        userData={userData}
                                                                        markApptDone={markApptDone}
                                                                        handleShowNote={handleShowNote}
                                                                    ></ItemRow>
                                                                )
                                                            })
                                                        }
                                                    </>
                                                )
                                            }
                                        }, [appointmentItemList, isLoading])
                                    }
                                </tbody>
                            </table>
                            <div className="d-flex justify-content-end">
                                <Pagination
                                    itemClass="page-item"
                                    linkClass="page-link"
                                    hideDisabled
                                    activePage={page}
                                    itemsCountPerPage={results}
                                    totalItemsCount={Number(totalCount)}
                                    onChange={handlePageChange}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {
                showNoteModal &&
                <NoteModal
                    data={currentActiveModalAppointment}
                    handleCancel={() => {
                        setShowNoteModal(false);
                    }} handleOk={() => {
                        setShowNoteModal(false);
                        toast.success("Note added successfully");
                    }}
                ></NoteModal>
            }
        </React.Fragment>
    );
}

const ItemRow = ({ item, userData, markApptDone, handleShowNote }: any) => {
    return (
        <tr className={"table-row have-msg"} key={item.uniqueid}>
            <td>
                <span className="d-block fw-medium p-0 text-capitalize">
                    {
                        (item.dependant && item.dependant.fname && item.dependant.lname) ?
                            (item.dependant.fname + ' ' + item.dependant.lname).substring(0, 15) : ''
                    }
                </span>
                <span className="d-block text-muted">
                    {
                        item.dependant.phone && item.dependant.phone !== "" ? (CONSTANT_LABELS.PLUS_ONE + removeCountryCode(item.dependant.phone)) : item.dependant.email
                    }
                </span>
            </td>
            <td>
                <div className="d-flex p-0">
                    <div className="">
                        <span className="d-block fw-medium charlmt"> {(item.appointment && item.appointment.appointmentWith) ?
                            item.appointment.appointmentWith : ''}</span>
                        <small className="text-muted">{item.appointment && item.appointment.appointmentType ? item.appointment.appointmentType : ''}</small>
                    </div>
                </div>
            </td>
            <td>
                {
                    (item && item.appointment && item.appointment.date && item.appointment.time) ?
                        <small className="d-block p-0">{moment(item.appointment.date + " " + item.appointment.time).format("MMM DD, YYYY - LT")}</small> :
                        '-'
                }
            </td>
            <td>
                <div className="d-flex p-0">
                    <div className="">
                        <span className="d-block fw-medium charlmt"> {(item.subject) ? item.subject : ''}</span>
                        <small className="text-muted">{item.description ? item.description : ''}</small>
                    </div>
                </div>
            </td>
            <td>
                {
                    item.photos && item.photos.map((photo: any) => {
                        return <span className="ms-2"><a href={configs.SOURCE_URL + photo.name} target="_blank"><img width={50} height={50} src={configs.SOURCE_URL + photo.name}></img></a></span>
                    })
                }
                {
                    item.video &&
                    <a href={configs.SOURCE_URL + item.video} target="_blank" className="ms-2"><img width={50} height={50} src={VideoImage} /></a>
                }
                <div>
                    <span className="text-muted">Received on:{" "}<span>{
                        (item && item.createdAt) ?
                            <small className="d-block p-0">{moment.utc(item.createdAt).local().format("MMM DD, YYYY - LT")}</small> :
                            '-'
                    }</span>
                    </span>
                </div>
            </td>

            <td className="text-end">
                {
                    /* (userData?.accessEnabled?.pms_integration === true) && */
                    <OverlayTrigger
                        placement="auto"
                        overlay={
                            <Tooltip id="tooltip">
                                Archive
                            </Tooltip>
                        }
                    >
                        <button className="btn mx-1 p-1"
                            type="button"
                            onClick={(e) => { markApptDone(item); }}>
                            <i className="bi bi-patch-check"></i>
                        </button>
                    </OverlayTrigger>
                }
                {
                    
                    <OverlayTrigger
                        placement="auto"
                        overlay={
                            <Tooltip id="tooltip">
                                Note
                            </Tooltip>
                        }
                    >
                        <button className="btn mx-1 p-1"
                            type="button"
                            onClick={() => {
                                handleShowNote(item);
                            }}>
                            <i className="bi bi-pencil-square"></i></button>
                    </OverlayTrigger>
                }
            </td>
        </tr >
    )
}