import React, { Component } from 'react';
import { connect } from 'react-redux';
import { IAppState } from '../../../store';
import { createSubscriptionPlan, fetchSubscriptionPlan } from '../../../store/subscriptionplan/actions';
import { CreateSubscriptionPlanRequest, FetchSubscriptionPlanRequest, PlanItem, SubscriptionState } from '../../../store/subscriptionplan/types';
import { UserDataResponse } from '../../../store/userData/types';
import { toast } from 'react-toastify';
import { Alert, Col, Form, Button, Modal, ModalBody } from 'react-bootstrap';
import { fetchBillingPendingAction } from '../../../store/billing/actions';
import { BillingRequest, BillingState } from '../../../store/billing/types';
import { id } from 'date-fns/locale';
import _ from "lodash";
import CardInfo from './CardInfo';
import GetPlan from './GetPlan';
import Plans from './Plans';
import Axios from 'axios';
import { attachHeader } from '../../../utils/apiHandler';
import configs from '../../../configs/apiConfigs';
import { currentPlan } from '../../services/subscription';
import { fetchLoadingPending, fetchLoadingSuccess } from '../../../store/loadingIndicator/actions';
import { Redirect } from 'react-router-dom';
import { runInThisContext } from 'vm';

interface Props {
  userDataResponse: UserDataResponse;
  fetchSubscriptionPlan: typeof fetchSubscriptionPlan;
  subscriptionState: SubscriptionState;
  createSubscriptionPlan: typeof createSubscriptionPlan;
  fetchBilling: typeof fetchBillingPendingAction;
  billingState: BillingState;
  history: any;
  location: any;
  loadingStart: typeof fetchLoadingPending;
  loadingEnd: typeof fetchLoadingSuccess;
}
interface State {
  selectedClinic: string;
  planListMonth: PlanItem[];
  planListYear: PlanItem[];
  monthPlans: any;
  yearPlans: any;
  showCardModal: boolean;
  planObj?: any;
  stripeCards: any[];
  showPlanCancellationModal: boolean;
  isCheckOut: boolean;
  planType: any; // Monthly or Annualy
  isPlanActive: boolean;
  planList: any;
  currentPlan: any;
  type: any;
  interval: any;
  selectedAddon: any;
  advertisePlanName: any;
}

class SubscriptionPlanNew extends Component<Props, State> {
  calledFirst: boolean;
  constructor(props: any) {
    super(props);
    this.state = {
      selectedClinic: '',
      planListMonth: [],
      planListYear: [],
      monthPlans: {} as any,
      yearPlans: {} as any,
      showCardModal: false,
      stripeCards: [],
      showPlanCancellationModal: false,
      isCheckOut: false,
      planType: 'Monthly',
      isPlanActive: true, planList: '', currentPlan: [], type: '', interval: '',
      selectedAddon: '',
      advertisePlanName: ''
    }
    this.calledFirst = false;
  }
  componentDidMount() {
    if (this.props.history && this.props.history.location && this.props.history.location.state &&
      this.props.history.location.state.screenname === 'overview' && this.props.history.location.state.data && this.props.history.location.state.planName === "") {
        this.setState({ planList: this.props.history.location.state.data, isPlanActive: false, type: this.props.history.location.state.type, interval: this.props.history.location.state.interval });
      if (this.props.userDataResponse && this.props.userDataResponse.clinics.length > 0 && this.props.userDataResponse.clinics[0].uniqueId) {
        // this.props.fetchSubscriptionPlan({ clinicUniqueId: this.props.userDataResponse.clinics[0].uniqueId ? this.props.userDataResponse.clinics[0].uniqueId : "", });
        this.setState({ planList: this.props.location.state.data, type: this.props.history.location.state.type, interval: this.props.history.location.state.interval },
          () => {
            this.props.history.push({
              pathname: '/subscription/upgrade/plan',
              state: {
                data: this.state.planList,
                selectedPlan: this.state.currentPlan,
                type: this.state.type,
                interval: this.state.interval,
                selectedAddon: this.state.selectedAddon,
                clinicId: this.props.userDataResponse && this.props.userDataResponse.clinics && this.props.userDataResponse.clinics[0] && this.props.userDataResponse.clinics[0].uniqueId
              }
            });
          });
      }

    } else if (this.props.history && this.props.history.location && this.props.history.location.state &&
      this.props.history.location.state.screenname === 'overview' && this.props.history.location.state.planName) {
      this.setState({ advertisePlanName: this.props.history.location.state.planName, planList: this.state.currentPlan, type: this.props.history.location.state.type, interval: this.props.history.location.state.interval }, () => {
        if (this.props.userDataResponse && this.props.userDataResponse.clinics.length > 0 && this.props.userDataResponse.clinics[0].uniqueId) {
          let clinicUniqueId = this.props.userDataResponse.clinics[0].uniqueId;
          this.props.loadingStart();
          this.props.fetchSubscriptionPlan({ clinicUniqueId: clinicUniqueId });
        }
      });


    }
    else {
      if (this.props.userDataResponse && this.props.userDataResponse.clinics.length > 0 && this.props.userDataResponse.clinics[0].uniqueId) {
        let clinicUniqueId = this.props.userDataResponse.clinics[0].uniqueId;
        this.props.loadingStart();
        this.currentPlanApi(clinicUniqueId);
      }
    }
  }
  currentPlanApi = (clinicUniqueId: any) => {
    this.props.fetchSubscriptionPlan({ clinicUniqueId: clinicUniqueId });
    currentPlan(clinicUniqueId).then((success: any) => {
      if (success && success.response && success.response.data && success.response.status === 200) {
        if (success.response.data && success.response.data.status.error === false) {
          this.setState({ currentPlan: success.response.data, isPlanActive: true }, () => {
            if (this.props.history && this.props.history.location && this.props.history.location.state &&
              this.props.history.location.state.screenname === 'overview') {
              this.props.history.push({
                pathname: '/subscription/upgrade/plan',
                state: {
                  data: this.props.location.state.data,
                  selectedPlan: this.state.currentPlan,
                  type: this.state.type,
                  interval: this.state.interval,
                  selectedAddon: this.state.selectedAddon,
                  clinicId: this.props.userDataResponse && this.props.userDataResponse.clinics && this.props.userDataResponse.clinics[0] && this.props.userDataResponse.clinics[0].uniqueId
                }
              });
            }
          });
          this.props.loadingEnd(false);
        } else {
          // Error Msg
          this.props.loadingEnd(false);
        }
      }
    }).catch((err) => {
      this.props.loadingEnd(false);
      return '';
    });
  }
  componentDidUpdate(prevProps: Props, prevState: State) {
    if (this.props.subscriptionState.fetchSubscriptionPlanResponse !== prevProps.subscriptionState.fetchSubscriptionPlanResponse &&
      this.props.subscriptionState.fetchSubscriptionPlanResponse && this.props.subscriptionState.fetchSubscriptionPlanResponse.status.error === false) {
      this.setState({ planList: this.props.subscriptionState.fetchSubscriptionPlanResponse },
        () => {
          if (this.state.advertisePlanName) {
            this.state.planList.month.map((item: any) => {
              if (this.state.advertisePlanName === item.name && item.isPlanActive === false) {
                this.setState({ selectedAddon: item, isPlanActive: false, type: this.props.history.location.state.type, interval: this.props.history.location.state.interval }, () => {
                  this.props.history.push({
                    pathname: '/subscription/upgrade/plan',
                    state: {
                      data: this.state.planList,
                      selectedPlan: this.state.currentPlan,
                      type: this.state.type,
                      interval: this.state.interval,
                      selectedAddon: this.state.selectedAddon,
                      clinicId: this.props.userDataResponse && this.props.userDataResponse.clinics && this.props.userDataResponse.clinics[0] && this.props.userDataResponse.clinics[0].uniqueId
                    }
                  });
                });
              } else if (this.state.advertisePlanName === item.name && item.isPlanActive !== false) {
                this.props.history.push({
                  pathname: '/subscription/upgrade/plan',
                  state: {
                    data: this.state.planList,
                    selectedPlan: this.state.currentPlan,
                    type: this.state.type,
                    interval: this.state.interval,
                    selectedAddon: '',
                    clinicId: this.props.userDataResponse && this.props.userDataResponse.clinics && this.props.userDataResponse.clinics[0] && this.props.userDataResponse.clinics[0].uniqueId
                  }
                });
              }
            });
          } else if (this.props.history && this.props.history.location && this.props.history.location.state &&
            this.props.history.location.state.screenname === 'overview') {
            this.props.history.push({
              pathname: '/subscription/upgrade/plan',
              state: {
                data: this.state.planList,
                selectedPlan: this.state.currentPlan,
                type: this.state.type,
                interval: this.state.interval,
                selectedAddon: this.state.selectedAddon,
                clinicId: this.props.userDataResponse && this.props.userDataResponse.clinics && this.props.userDataResponse.clinics[0] && this.props.userDataResponse.clinics[0].uniqueId
              }
            });
          }
        })
    }
    if (prevProps.userDataResponse.clinics !== this.props.userDataResponse.clinics) {
      let clinicUniqueId = this.props.userDataResponse.clinics[0].uniqueId;
      this.props.loadingStart();
      this.currentPlanApi(clinicUniqueId);
    }
    if (prevState.selectedAddon !== this.state.selectedAddon && (this.props.history.location.state === null || this.props.history.location.state === undefined)) {
      let interval = this.state.selectedAddon.plan_interval
      let type: any = 'basic';
      this.state.currentPlan.active_plans.map((item: any) => {
        if (item.plan_type !== 'custom') {
          type = item.plan_type;
        }
      })
      this.props.history.push({
        pathname: '/subscription/upgrade/plan',
        state: {
          data: this.props.subscriptionState.fetchSubscriptionPlanResponse,
          selectedPlan: this.state.currentPlan,
          type: type,
          interval: interval,
          selectedAddon: this.state.selectedAddon,
          clinicId: this.props.userDataResponse && this.props.userDataResponse.clinics && this.props.userDataResponse.clinics[0] && this.props.userDataResponse.clinics[0].uniqueId
        }
      })
    }
  }

  handlePlanActive = () => {
    // this.setState({ isPlanActive: !this.state.isPlanActive });
    console.log('currentPlan',this.state.currentPlan);
    
    this.props.history.push({
      pathname: '/subscription/upgrade/plan',
      state: {
        data: this.state.planList,
        selectedPlan: this.state.currentPlan,
        type: this.state.currentPlan.active_plans[0].plan_type,
        interval: this.state.currentPlan.active_plans[0].interval,
        selectedAddon: this.state.selectedAddon,
        clinicId: this.props.userDataResponse && this.props.userDataResponse.clinics && this.props.userDataResponse.clinics[0] && this.props.userDataResponse.clinics[0].uniqueId
      }
    })
  }
  addAddon = (value: any) => {
    this.setState({ isPlanActive: !this.state.isPlanActive, selectedAddon: value }, () => {
      let clinicUniqueId = this.props.userDataResponse.clinics[0].uniqueId;
      this.props.loadingStart();
      this.props.fetchSubscriptionPlan({ clinicUniqueId: clinicUniqueId });
    });
  }

  render() {
    return (
      (this.state.isPlanActive === true) &&
      <Plans
        goToPlans={this.handlePlanActive}
        selectedPlan={this.state.currentPlan}
        addons={this.state.planList}
        selectedAddon={this.addAddon}
        history={this.props.history}
      ></Plans>
      // <GetPlan
      //   data={this.state.planList}
      //   selectedPlan={this.state.currentPlan}
      //   history={this.props.history}
      //   type={this.state.type}
      //   interval={this.state.interval}
      //   selectedAddon={this.state.selectedAddon}
      //   clinicId={this.props.userDataResponse && this.props.userDataResponse.clinics && this.props.userDataResponse.clinics[0] && this.props.userDataResponse.clinics[0].uniqueId}
      // ></GetPlan>
    )
  }
}
const mapStateToProps = (state: IAppState) => ({
  userDataResponse: state.userData.userDataResponse,
  subscriptionState: state.subscriptionPlan,
  billingState: state.billing,
});
const mapDispatchToProps = (dispatch: any) => ({
  fetchSubscriptionPlan: (request: FetchSubscriptionPlanRequest) => {
    dispatch(fetchSubscriptionPlan(request));
  },
  createSubscriptionPlan: (request: CreateSubscriptionPlanRequest) => {
    dispatch(createSubscriptionPlan(request));
  },
  fetchBilling: (request: BillingRequest) => {
    dispatch(fetchBillingPendingAction(request));
  },
  loadingStart: () => {
    dispatch(fetchLoadingPending());
  },
  loadingEnd: (status: any) => {
    dispatch(fetchLoadingSuccess(status));
  },
});
export default connect(mapStateToProps, mapDispatchToProps)(SubscriptionPlanNew);