import React from 'react';
import { Helmet } from 'react-helmet';
import { IAppState } from '../../store';
import { connect } from 'react-redux';
import { TITLE_CONSTANTS } from '../../constants';
import CONSTANT_LABELS from '../../constants/labels';
interface Props { }
interface State { }
class ResetPasswordLink extends React.Component<Props, State> {
    constructor(props: any) {
        super(props);
        this.state = {}
    }
    render() {
        return (
            <>
                <Helmet><title>{TITLE_CONSTANTS.TITLES.RESET_PASSWORD_LINK}</title></Helmet>
                <div className="card shadow-sm">
                    <div className="card-body">
                        <div className="text-center">
                            <h4><i className="anticon anticon-check-circle"></i></h4>
                            <p>{CONSTANT_LABELS.MESSAGE.RESET_PASSWORD_LINK_SENT}</p>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}
const mapDispatchToProps = (dispatch: any) => ({});
const mapStateToProps = (state: IAppState) => ({});
export default connect(mapStateToProps, mapDispatchToProps)(ResetPasswordLink);