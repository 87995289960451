import React from 'react'
import './ChatStyle.css';
import { appointmentDetail } from '../../../store/appointment/actions';
import { AppointmentDetailRequest } from '../../../store/appointment/types';
import { connect } from 'react-redux';
import { IAppState } from '../../../store';
import apiConfigs from '../../../configs/apiConfigs';
import { userLogout } from '../../../store/login/actions';
import { Alert, Dropdown, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { CHAT } from '../../../constants';
import nl2br from 'react-nl2br';
import moment from 'moment';
import _ from 'lodash';
import * as firebase from 'firebase/app';
import 'firebase/firestore';
import { getCurrentUser } from '../../../utils/firebaseUtils';
import { getCookie } from '../../../utils/cookies';
import { fetchLoadingSuccess } from '../../../store/loadingIndicator/actions';
import { Avatar } from '@material-ui/core';
import Axios from 'axios';
import { attachHeader } from './../../../utils/apiHandler';
import { closeChatPin, removeChatPin, storeChatData } from '../../../store/chat/action';
import { UserDataResponse } from '../../../store/userData/types';
import { sendSMS } from '../../../v2/services/messageCenter';
import { toast } from 'react-toastify';
import { confirmAlert } from 'react-confirm-alert';
import configs from '../../../v2/configs/apiConfigs';
import { USER_TYPE } from '../../../v2/constants/constants';
import { getAppointmentDetail } from '../../../v2/services/appointment';
import { ShowCallOption } from '../../../v2/components/voice/ShowCallOption';
import { VoiceCall } from '../../../v2/components/voice/VoiceCall';
import { chatNotificationProvider } from '../../../v2/services/chat';
import { VoiceCallGoto } from '../../../v2/components/voice/VoiceCallGoto';
import { VoiceCallMango } from '../../../v2/components/voice/VoiceCallMango';
import { uploadGotoImage } from '../../../v2/services/clinicProfile';

interface Props {
  fetchAppointmentDetail: typeof appointmentDetail;
  chatProps: any;
  logout: typeof userLogout;
  history?: any;
  appointmentResponse: any;
  loadingSuccess: typeof fetchLoadingSuccess;
  handleCloseChat: (id: any) => void;
  removeChatPin: typeof removeChatPin;
  closeChatPin: typeof closeChatPin;
  userData: UserDataResponse;
  storeChat: typeof storeChatData
  handleAppointment: (Id: any) => void;
}
interface State {
  appointment: any;
  chatArray: any;
  chatMessageInput: any;
  openVoiceCallOption: boolean;
  openVoiceCall: boolean;
  callType: string;
  callTo: string;
  imageUrl: string[];
}
class Chat extends React.Component<Props, State> {
  db: any;
  unsubscribe: any;
  messageInput: any;
  upload: any = null;
  listener: any;
  constructor(props: any) {
    super(props);
    this.state = {
      appointment: [], chatArray: [], chatMessageInput: '',
      openVoiceCallOption: false,
      openVoiceCall: false,
      callType: '',
      callTo: '',
      imageUrl: []
    }
  }
  async componentDidMount() {
    this.db = firebase.firestore();
    if (this.props.chatProps.appointmentId && !this.props.chatProps.fetched) {
      const url = apiConfigs.API_URL + 'clinic' + '/' + this.props.chatProps.sendToCId + '/appointments/' + this.props.chatProps.appointmentId + '/';
      console.log(url)
      await Axios.get(url, { headers: attachHeader(true), withCredentials: true }).then((response) => {
        if (response.status !== 200) {
          throw response;
        } else if (response.data && response.data.status && response.data.status.error === true) {
          throw response.data;
        }
        return response.data;
      }).then((success: any) => {
        this.setState({ appointment: success })
      }).catch((error: any) => { });
    } else {
      this.setState({ appointment: this.props.chatProps });
    }
    this.getChatData(this.props.chatProps.appointmentId)
    console.log("dependant", this.props?.chatProps?.dependant)
    this.scrollToBottom();

    if (this.props.chatProps.openPin) {
      $('.prime' + this.props.chatProps.appointmentId).toggleClass('zmdi-comment-outline');
      $('.prime' + this.props.chatProps.appointmentId).toggleClass('zmdi-close');
      $('.prime' + this.props.chatProps.appointmentId).toggleClass('is-active');
      $('.prime' + this.props.chatProps.appointmentId).toggleClass('is-visible');
      $('#prime' + this.props.chatProps.appointmentId).toggleClass('is-float');
      $('.chat' + this.props.chatProps.appointmentId).toggleClass('is-visible');
      $('.fab' + this.props.chatProps.appointmentId).toggleClass('is-visible');
      $('#chatSend' + this.props.chatProps.appointmentId).focus();
    }
  }
  shouldComponentUpdate(nextProps: any, nextState: any) {
    if (this.props !== nextProps) {
      if (this.props.appointmentResponse !== nextProps.appointmentResponse) {
        this.setState({ appointment: nextProps.appointmentResponse });
        // delete nextProps.appointmentResponse.status;
        this.props.loadingSuccess(false);
      }
      if (nextProps.chatProps !== this.props.chatProps && nextProps.chatProps.openPin === true) {
        console.log(nextProps.chatProps)
        $('.prime' + nextProps.chatProps.appointmentId).removeClass('zmdi-comment-outline');
        $('.prime' + nextProps.chatProps.appointmentId).addClass('zmdi-close');
        $('.prime' + nextProps.chatProps.appointmentId).addClass('is-active');
        $('.prime' + nextProps.chatProps.appointmentId).addClass('is-visible');
        $('#prime' + nextProps.chatProps.appointmentId).addClass('is-float');
        $('.chat' + nextProps.chatProps.appointmentId).addClass('is-visible');
        $('.fab' + nextProps.chatProps.appointmentId).addClass('is-visible');
        $('#chatSend' + nextProps.appointmentId).focus();
      } else if (nextProps.chatProps !== this.props.chatProps && nextProps.chatProps.openPin === false) {
        $('.prime' + nextProps.chatProps.appointmentId).addClass('zmdi-comment-outline');
        $('.prime' + nextProps.chatProps.appointmentId).removeClass('zmdi-close');
        $('.prime' + nextProps.chatProps.appointmentId).removeClass('is-active');
        $('.prime' + nextProps.chatProps.appointmentId).removeClass('is-visible');
        $('#prime' + nextProps.chatProps.appointmentId).removeClass('is-float');
        $('.chat' + nextProps.chatProps.appointmentId).removeClass('is-visible');
        $('.fab' + nextProps.chatProps.appointmentId).removeClass('is-visible');
      }
    }
    return true;
  }
  fetchAppointmentDetails = async () => {
    if (this.props.chatProps.appointmentId) {
      const url = apiConfigs.API_URL + 'clinic' + '/' + this.props.chatProps.sendToCId + '/appointments/' + this.props.chatProps.appointmentId + '/';
      await Axios.get(url, { headers: attachHeader(true), withCredentials: true }).then((response) => {
        if (response.status !== 200) {
          throw response;
        } else if (response.data && response.data.status && response.data.status.error === true) {
          throw response.data;
        }
        return response.data;
      }).then((success: any) => {
        this.setState({ appointment: success })
      }).catch((error: any) => { });
    }
  }
  getChatData(appointmentId: any) {
    this.scrollToBottom();
    let roundOneFinish = false;
    this.unsubscribe = firebase.firestore().collection(apiConfigs.CHAT_COLLECTION)
      .where("appointmentId", "==", Number(appointmentId))
      .orderBy("createdAt")
      .onSnapshot((snapshot) => {
        const docsLength = snapshot.docs.length;
        snapshot.docChanges().forEach((change: any, index: number) => {
          if (change.type === "added") {
            const objFound = _.find(this.state.chatArray, (value) => {
              return value.id === change.doc.id;
            })
            if (!objFound) {
              const data = change.doc.data();
              if (data.actionType !== "MESSAGE" && data.actionType === "STATUS" &&
                data.toStatus === "COMPLETED" && roundOneFinish) {
                this.fetchAppointmentDetails();
              }
              this.setState({
                chatArray: _.uniqBy([...this.state.chatArray, {
                  ...change.doc.data(),
                  id: change.doc.id,
                  sortTime: moment(moment.unix(change.doc.data()?.createdAt?.seconds)).toDate()
                }
                ], 'id')
              }, () => {
                if ((!(this.props?.chatProps?.dependant?.isSignInUser && this.props.chatProps?.patient?.fcmtoken) && 
                  this.props?.chatProps?.dependant.phone && this.props?.chatProps?.dependant.phone !== ""
                )) {
                  //patient chat 
                  this.getMessages(this.props?.chatProps?.dependant);
                } else if ((!(this.props?.chatProps?.patient?.isSignInUser && this.props.chatProps?.patient?.fcmtoken) && this.props.chatProps?.patient?.phone)) {
                  this.getMessages(this.props?.chatProps?.dependant);
                }
              });
              this.scrollToBottom();
            }
          }
          if (change.type === "modified") {
            const id = change.doc.id;
            const data = change.doc.data();
            if (data.actionType !== "MESSAGE" && data.actionType === "STATUS" &&
              data.toStatus === "COMPLETED" && roundOneFinish) {
              this.fetchAppointmentDetails();
            }
            this.setState({
              chatArray: this.state.chatArray.map((value: any, index: any) => {
                if (value.id === id) {
                  return { ...change.doc.data(), id: id, sortTime: moment(moment.unix(change.doc.data()?.createdAt?.seconds)).toDate() };
                }
                return value;
              })
            }, () => {
              if ((!(this.props?.chatProps?.dependant?.isSignInUser && this.props.chatProps?.patient?.fcmtoken) && 
                  this.props?.chatProps?.dependant.phone && this.props?.chatProps?.dependant.phone !== ""
                )) {
                  //patient chat 
                  this.getMessages(this.props?.chatProps?.dependant);
                } else if ((!(this.props?.chatProps?.patient?.isSignInUser && this.props.chatProps?.patient?.fcmtoken) && this.props.chatProps?.patient?.phone)) {
                  this.getMessages(this.props?.chatProps?.dependant);
                }
            });
            this.scrollToBottom();
          }
          if (docsLength === index + 1) {
            roundOneFinish = true;
          }
        });
      }, (error: any) => { });
  };

  getMessages = (dependent: any) => {
    this.scrollToBottom();
    if (this.listener) {
      this.listener();
    }
    this.listener = firebase.firestore().collection(configs.CONVERSATION_DEV)
      .where('dependentUniqueId', '==', Number(dependent.uniqueid))
      .where('clinicId', '==', Number(this.props.userData.clinics[0].uniqueId))
      .orderBy("createdAt", 'asc')
      .onSnapshot((querySnapshot: any) => {
        const messages = querySnapshot.docs.map((item: any) => ({
          ...item.data(), id: item.id,
          sortTime: moment(moment.unix(item.data()?.createdAt?.seconds)).toDate()
        }));
        console.log("messages", messages);
        this.setState(({
          chatArray: _.uniqBy([...this.state.chatArray, ...messages], 'id')
        }));
      });
  }

  onMessageSendClick(e: any, Id: any) {
    e.preventDefault();
    if (this.state.appointment.dependant && this.state.appointment.dependant.isSignInUser === true) {
      if (this.state.appointment && (this.state.chatMessageInput !== "" || this.state.imageUrl.length > 0)) {
        if (this.state.appointment.patient.fcmtoken) {
          const currentUser = getCurrentUser();
          let uid = '';
          if (currentUser) {
            uid = currentUser.uid;
          }
          const obj = {
            "appointmentId": Number(this.props.chatProps.appointmentId),
            "senderId": Number(getCookie().xpr_user_id),
            "sender": CHAT.SENDER.USER,
            "action": CHAT.ACTION.SMTP,
            "actionType": CHAT.ACTION_TYPE.MESSAGE,
            "text": this.state.chatMessageInput,
            "createdAt": firebase.firestore.FieldValue.serverTimestamp(),
            "updatedAt": firebase.firestore.FieldValue.serverTimestamp(),
            "sendToCId": this.props.chatProps.sendToCId,
            "dependantId": Number(this.state.appointment.dependant.uniqueid),
            "firstName": this.props.userData.firstName,
            "lastName": this.props.userData.lastName,
            "medium": CHAT.SEND_MEDIUM.APP,
            "dateTime": {
              "date": this.state.appointment.date,
              "time": this.state.appointment.time
            },
            imageUrl: this.state.imageUrl
          };
          this.db.collection(apiConfigs.CHAT_COLLECTION).doc().set(obj)
            .then(() => {
              this.setState({ chatMessageInput: '', imageUrl: [] });
              this.scrollToBottom();
            })
            .catch((error: any) => { });
        } else if (
          this.props.userData.clinics[0].text_smschat === "on" && this.state.appointment.chatpinsms === true &&
          this.state.appointment.dependant
          && this.state.appointment.dependant.phone &&
          this.state.appointment.dependant.phone !== "") {
          const currentUser = getCurrentUser();
          let uid = '';
          if (currentUser) {
            uid = currentUser.uid;
          }
          let phone = this.state.appointment.dependant.phone;
          sendSMS({
            clinicid: this.props.userData.clinics[0].id,
            phonenumber: phone,
            message: this.state.chatMessageInput,
            imageUrl: this.state.imageUrl
          }).then((success) => {
            const obj = {
              "appointmentId": Number(this.props.chatProps.appointmentId),
              "senderId": Number(getCookie().xpr_user_id),
              "sender": CHAT.SENDER.USER,
              "action": CHAT.ACTION.SMTP,
              "actionType": CHAT.ACTION_TYPE.MESSAGE,
              // "actionType": CHAT.ACTION_TYPE.MESSAGE_TEXT, do not remove this
              "text": this.state.chatMessageInput,
              "createdAt": firebase.firestore.FieldValue.serverTimestamp(),
              "updatedAt": firebase.firestore.FieldValue.serverTimestamp(),
              "sendToCId": this.props.chatProps.sendToCId,
              "dependantId": Number(this.state.appointment.dependant.uniqueid),
              "firstName": this.props.userData.firstName,
              "lastName": this.props.userData.lastName,
              "medium": CHAT.SEND_MEDIUM.SMS,
              "dateTime": {
                "date": this.state.appointment.date,
                "time": this.state.appointment.time
              },
              imageUrl: this.state.imageUrl
            };
            this.db.collection(apiConfigs.CHAT_COLLECTION).doc().set(obj)
              .then(() => {
                this.setState({ chatMessageInput: '', imageUrl: [] });
                this.scrollToBottom();
              })
              .catch((error: any) => { });
          }).catch((err) => {
            toast.error('Please check linked phone, facing issue in sms send.');
            console.log(err);
          });
        } else if (this.state.appointment && this.state.appointment.patient.fcmtoken) {
          const currentUser = getCurrentUser();
          let uid = '';
          if (currentUser) {
            uid = currentUser.uid;
          }
          const obj = {
            "appointmentId": Number(this.props.chatProps.appointmentId),
            "senderId": Number(getCookie().xpr_user_id),
            "sender": CHAT.SENDER.USER,
            "action": CHAT.ACTION.SMTP,
            "actionType": CHAT.ACTION_TYPE.MESSAGE,
            "text": this.state.chatMessageInput,
            "createdAt": firebase.firestore.FieldValue.serverTimestamp(),
            "updatedAt": firebase.firestore.FieldValue.serverTimestamp(),
            "sendToCId": this.props.chatProps.sendToCId,
            "dependantId": Number(this.state.appointment.dependant.uniqueid),
            "firstName": this.props.userData.firstName,
            "lastName": this.props.userData.lastName,
            "medium": CHAT.SEND_MEDIUM.APP,
            "dateTime": {
              "date": this.state.appointment.date,
              "time": this.state.appointment.time
            },
            imageUrl: this.state.imageUrl
          };
          this.db.collection(apiConfigs.CHAT_COLLECTION).doc().set(obj)
            .then(() => {
              this.setState({ chatMessageInput: '', imageUrl: [] });
              this.scrollToBottom();
            })
            .catch((error: any) => { });
        } else if (
          this.props.userData.clinics[0].text_smschat === "on" && this.state.appointment.chatpinsms === true &&
          this.state.appointment.patient
          && this.state.appointment.patient.phone &&
          this.state.appointment.patient.phone !== "") {
          const currentUser = getCurrentUser();
          let uid = '';
          if (currentUser) {
            uid = currentUser.uid;
          }
          let phone = this.state.appointment.patient.phone;
          sendSMS({
            clinicid: this.props.userData.clinics[0].id,
            phonenumber: phone,
            message: this.state.chatMessageInput,
            imageUrl: this.state.imageUrl
          }).then((success) => {
            const obj = {
              "appointmentId": Number(this.props.chatProps.appointmentId),
              "senderId": Number(getCookie().xpr_user_id),
              "sender": CHAT.SENDER.USER,
              "action": CHAT.ACTION.SMTP,
              "actionType": CHAT.ACTION_TYPE.MESSAGE,
              // "actionType": CHAT.ACTION_TYPE.MESSAGE_TEXT, do not remove this
              "text": this.state.chatMessageInput,
              "createdAt": firebase.firestore.FieldValue.serverTimestamp(),
              "updatedAt": firebase.firestore.FieldValue.serverTimestamp(),
              "sendToCId": this.props.chatProps.sendToCId,
              "dependantId": Number(this.state.appointment.dependant.uniqueid),
              "firstName": this.props.userData.firstName,
              "lastName": this.props.userData.lastName,
              "medium": CHAT.SEND_MEDIUM.SMS,
              "dateTime": {
                "date": this.state.appointment.date,
                "time": this.state.appointment.time
              },
              imageUrl: this.state.imageUrl
            };
            this.db.collection(apiConfigs.CHAT_COLLECTION).doc().set(obj)
              .then(() => {
                this.setState({ chatMessageInput: '',imageUrl: [] });
                this.scrollToBottom();
              })
              .catch((error: any) => { });
          }).catch((err) => {
            toast.error('Please check linked phone, facing issue in sms send.');
            console.log(err);
          });
        } else {
          const currentUser = getCurrentUser();
          let uid = '';
          if (currentUser) {
            uid = currentUser.uid;
          }
          const obj = {
            "appointmentId": Number(this.props.chatProps.appointmentId),
            "senderId": Number(getCookie().xpr_user_id),
            "sender": CHAT.SENDER.USER,
            "action": CHAT.ACTION.SMTP,
            "actionType": CHAT.ACTION_TYPE.MESSAGE,
            "text": this.state.chatMessageInput,
            "createdAt": firebase.firestore.FieldValue.serverTimestamp(),
            "updatedAt": firebase.firestore.FieldValue.serverTimestamp(),
            "sendToCId": this.props.chatProps.sendToCId,
            "dependantId": Number(this.state.appointment.dependant.uniqueid),
            "firstName": this.props.userData.firstName,
            "lastName": this.props.userData.lastName,
            "medium": CHAT.SEND_MEDIUM.CHAT,
            "dateTime": {
              "date": this.state.appointment.date,
              "time": this.state.appointment.time
            },
            imageUrl: this.state.imageUrl
          };
          this.db.collection(apiConfigs.CHAT_COLLECTION).doc().set(obj)
            .then(() => {
              this.setState({ chatMessageInput: '', imageUrl: [] });
              this.scrollToBottom();
            })
            .catch((error: any) => { });
        }
      }
    } else if (this.state.appointment.dependant && this.state.appointment.dependant.isSignInUser === false &&
      this.state.appointment && (this.state.chatMessageInput !== "" || this.state.imageUrl.length > 0)) {
      if (this.props.userData.clinics[0].text_smschat === "on" && this.state.appointment.chatpinsms === true &&
        this.state.appointment.dependant
        && this.state.appointment.dependant.phone &&
        this.state.appointment.dependant.phone !== "") {
        const currentUser = getCurrentUser();
        let uid = '';
        if (currentUser) {
          uid = currentUser.uid;
        }
        let phone = this.state.appointment.dependant.phone;
        sendSMS({
          clinicid: this.props.userData.clinics[0].id,
          phonenumber: phone,
          message: this.state.chatMessageInput,
          imageUrl: this.state.imageUrl
        }).then((success) => {
          const obj = {
            "appointmentId": Number(this.props.chatProps.appointmentId),
            "senderId": Number(getCookie().xpr_user_id),
            "sender": CHAT.SENDER.USER,
            "action": CHAT.ACTION.SMTP,
            "actionType": CHAT.ACTION_TYPE.MESSAGE,
            // "actionType": CHAT.ACTION_TYPE.MESSAGE_TEXT, do not remove this
            "text": this.state.chatMessageInput,
            "createdAt": firebase.firestore.FieldValue.serverTimestamp(),
            "updatedAt": firebase.firestore.FieldValue.serverTimestamp(),
            "sendToCId": this.props.chatProps.sendToCId,
            "dependantId": Number(this.state.appointment.dependant.uniqueid),
            "firstName": this.props.userData.firstName,
            "lastName": this.props.userData.lastName,
            "medium": CHAT.SEND_MEDIUM.SMS,
            "dateTime": {
              "date": this.state.appointment.date,
              "time": this.state.appointment.time
            },
            imageUrl: this.state.imageUrl
          };
          this.db.collection(apiConfigs.CHAT_COLLECTION).doc().set(obj)
            .then(() => {
              this.setState({ chatMessageInput: '',imageUrl: [] });
              this.scrollToBottom();
            })
            .catch((error: any) => { });
        }).catch((err) => {
          toast.error('Please check linked phone, facing issue in sms send.');
          console.log(err);
        });
      } else if (this.state.appointment.patient.fcmtoken) {
        const currentUser = getCurrentUser();
        let uid = '';
        if (currentUser) {
          uid = currentUser.uid;
        }
        const obj = {
          "appointmentId": Number(this.props.chatProps.appointmentId),
          "senderId": Number(getCookie().xpr_user_id),
          "sender": CHAT.SENDER.USER,
          "action": CHAT.ACTION.SMTP,
          "actionType": CHAT.ACTION_TYPE.MESSAGE,
          "text": this.state.chatMessageInput,
          "createdAt": firebase.firestore.FieldValue.serverTimestamp(),
          "updatedAt": firebase.firestore.FieldValue.serverTimestamp(),
          "sendToCId": this.props.chatProps.sendToCId,
          "dependantId": Number(this.state.appointment.dependant.uniqueid),
          "firstName": this.props.userData.firstName,
          "lastName": this.props.userData.lastName,
          "medium": CHAT.SEND_MEDIUM.APP,
          "dateTime": {
            "date": this.state.appointment.date,
            "time": this.state.appointment.time
          },
          imageUrl: this.state.imageUrl
        };
        this.db.collection(apiConfigs.CHAT_COLLECTION).doc().set(obj)
          .then(() => {
            this.setState({ chatMessageInput: '',imageUrl: [] });
            this.scrollToBottom();
          })
          .catch((error: any) => { });
      } else if (
        this.props.userData.clinics[0].text_smschat === "on" && this.state.appointment.chatpinsms === true &&
        this.state.appointment.patient &&
        this.state.appointment.patient.phone && this.state.appointment.patient.phone !== "") {
        const currentUser = getCurrentUser();
        let uid = '';
        if (currentUser) {
          uid = currentUser.uid;
        }
        let phone = this.state.appointment.patient.phone;
        sendSMS({
          clinicid: this.props.userData.clinics[0].id,
          phonenumber: phone,
          message: this.state.chatMessageInput,
          imageUrl: this.state.imageUrl
        }).then((success) => {
          const obj = {
            "appointmentId": Number(this.props.chatProps.appointmentId),
            "senderId": Number(getCookie().xpr_user_id),
            "sender": CHAT.SENDER.USER,
            "action": CHAT.ACTION.SMTP,
            "actionType": CHAT.ACTION_TYPE.MESSAGE,
            // "actionType": CHAT.ACTION_TYPE.MESSAGE_TEXT, do not remove this
            "text": this.state.chatMessageInput,
            "createdAt": firebase.firestore.FieldValue.serverTimestamp(),
            "updatedAt": firebase.firestore.FieldValue.serverTimestamp(),
            "sendToCId": this.props.chatProps.sendToCId,
            "dependantId": Number(this.state.appointment.dependant.uniqueid),
            "firstName": this.props.userData.firstName,
            "lastName": this.props.userData.lastName,
            "medium": CHAT.SEND_MEDIUM.SMS,
            "dateTime": {
              "date": this.state.appointment.date,
              "time": this.state.appointment.time
            },
            imageUrl: this.state.imageUrl
          };
          this.db.collection(apiConfigs.CHAT_COLLECTION).doc().set(obj)
            .then(() => {
              this.setState({ chatMessageInput: '',imageUrl: [] });
              this.scrollToBottom();
            })
            .catch((error: any) => { });
        }).catch((err) => {
          toast.error('Please check linked phone, facing issue in sms send.');
          console.log(err);
        });
      } else {
        const currentUser = getCurrentUser();
        let uid = '';
        if (currentUser) {
          uid = currentUser.uid;
        }
        const obj = {
          "appointmentId": Number(this.props.chatProps.appointmentId),
          "senderId": Number(getCookie().xpr_user_id),
          "sender": CHAT.SENDER.USER,
          "action": CHAT.ACTION.SMTP,
          "actionType": CHAT.ACTION_TYPE.MESSAGE,
          "text": this.state.chatMessageInput,
          "createdAt": firebase.firestore.FieldValue.serverTimestamp(),
          "updatedAt": firebase.firestore.FieldValue.serverTimestamp(),
          "sendToCId": this.props.chatProps.sendToCId,
          "dependantId": Number(this.state.appointment.dependant.uniqueid),
          "firstName": this.props.userData.firstName,
          "lastName": this.props.userData.lastName,
          "medium": CHAT.SEND_MEDIUM.CHAT,
          "dateTime": {
            "date": this.state.appointment.date,
            "time": this.state.appointment.time
          },
          imageUrl: this.state.imageUrl
        };
        this.db.collection(apiConfigs.CHAT_COLLECTION).doc().set(obj)
          .then(() => {
            this.setState({ chatMessageInput: '',imageUrl: [] });
            this.scrollToBottom();
          })
          .catch((error: any) => { });
      }
    }
  }
  scrollToBottom = () => {
    $('#chat_fullscreen' + this.props.chatProps.appointmentId).animate({ scrollTop: $('#chat_fullscreen' + this.props.chatProps.appointmentId).prop("scrollHeight") }, 10);
  }
  handleChatClose = () => {
    this.props.closeChatPin(this.props.chatProps.appointmentId);
    $('.prime' + this.props.chatProps.appointmentId).toggleClass('zmdi-comment-outline');
    $('.prime' + this.props.chatProps.appointmentId).toggleClass('zmdi-close');
    $('.prime' + this.props.chatProps.appointmentId).toggleClass('is-active');
    $('.prime' + this.props.chatProps.appointmentId).toggleClass('is-visible');
    $('#prime' + this.props.chatProps.appointmentId).toggleClass('is-float');
    $('.chat' + this.props.chatProps.appointmentId).toggleClass('is-visible');
    $('.fab' + this.props.chatProps.appointmentId).toggleClass('is-visible');
  }
  handleRemoveChat = () => {
    if (this.props.chatProps.pinType === "checkin" && this.props.chatProps._id) {
      const docRef = this.db.collection(apiConfigs.CHAT_COLLECTION).doc(this.props.chatProps._id);
      docRef.update({
        "metadata.viewByClinicAt": firebase.firestore.FieldValue.serverTimestamp(),
      });
    }
    $('.prime' + this.props.chatProps.appointmentId).toggleClass('zmdi-comment-outline');
    $('.prime' + this.props.chatProps.appointmentId).toggleClass('zmdi-close');
    $('.prime' + this.props.chatProps.appointmentId).toggleClass('is-active');
    $('.prime' + this.props.chatProps.appointmentId).toggleClass('is-visible');
    $('#prime' + this.props.chatProps.appointmentId).toggleClass('is-float');
    $('.chat' + this.props.chatProps.appointmentId).toggleClass('is-visible');
    $('.fab' + this.props.chatProps.appointmentId).toggleClass('is-visible');
    this.props.removeChatPin(this.props.chatProps.appointmentId);
  }
  handleChatVerifyAndClose = () => {
    var objIndex: string = this.state.appointment.currentStatus.toUpperCase();
    const fromStatus = CHAT.FROM_STATUS[objIndex];
    const obj = {
      "appointmentId": Number(this.props.chatProps.appointmentId),
      "senderId": Number(getCookie().xpr_user_id),
      "sender": CHAT.SENDER.USER,
      "action": `Changed status from ${fromStatus} to ${CHAT.TO_STATUS.COMPLETED}.`,
      "actionType": 'STATUS',
      "createdAt": firebase.firestore.FieldValue.serverTimestamp(),
      "updatedAt": firebase.firestore.FieldValue.serverTimestamp(),
      "fromStatus": fromStatus,
      "toStatus": CHAT.TO_STATUS.COMPLETED,
      "sendToCId": this.state.appointment.clinic.uniqueid,
      "dependantId": Number(this.state.appointment.dependant.uniqueid),
      "firstName": this.props.userData.firstName,
      "lastName": this.props.userData.lastName,
      "dateTime": {
        "date": this.state.appointment.date,
        "time": this.state.appointment.time
      }
    };
    this.db.collection(apiConfigs.CHAT_COLLECTION).doc().set(obj)
      .then(() => {
        this.scrollToBottom();
        this.props.closeChatPin(this.props.chatProps.appointmentId);
        $('.prime' + this.props.chatProps.appointmentId).toggleClass('zmdi-comment-outline');
        $('.prime' + this.props.chatProps.appointmentId).toggleClass('zmdi-close');
        $('.prime' + this.props.chatProps.appointmentId).toggleClass('is-active');
        $('.prime' + this.props.chatProps.appointmentId).toggleClass('is-visible');
        $('#prime' + this.props.chatProps.appointmentId).toggleClass('is-float');
        $('.chat' + this.props.chatProps.appointmentId).toggleClass('is-visible');
        $('.fab' + this.props.chatProps.appointmentId).toggleClass('is-visible');
        this.props.removeChatPin(this.props.chatProps.appointmentId);
      }).catch((error: any) => { });
  }
  handleGroupChatStart = () => {
    this.handleChatClose();
    console.log(this.state.appointment);
    if (!this.state.appointment.doctor.isSignIn || !this.state.appointment.dependant.isSignInUser) {
      confirmAlert({
        title: 'Initialize group chat',
        message: 'Provide or patient does not have signin.',
        buttons: [
          {
            label: 'Ok',
            className: 'btn btn-outline-primary',
            onClick: () => { }
          }
        ]
      })
      return;
    }
    confirmAlert({
      title: 'Initialize group chat',
      message: 'Are you sure you want to start group chat between patient, provider and you.',
      buttons: [
        {
          label: 'Yes',
          className: 'btn btn-outline-primary',
          onClick: async () => {
            console.log(this.state.appointment);



            const { doctor, clinic, patient, dependant } = this.state.appointment;
            let patientDocRef = null;
            let providerDocRef = null;
            let clinicDocRef = null;

            const patientDoc = await this.db.collection(configs.USER_COLLECTION).where('uniqueId', '==', patient.uniqueid).where('userType', '==', USER_TYPE.PATIENT).get();
            const providerDoc = await this.db.collection(configs.USER_COLLECTION).where('uniqueId', '==', doctor.uniqueid).where('userType', '==', USER_TYPE.PROVIDER).get();
            const clinicDoc = await this.db.collection(configs.USER_COLLECTION).where('uniqueId', '==', clinic.uniqueid).where('userType', '==', USER_TYPE.CLINIC).get();
            console.log(patientDoc, providerDoc, patientDoc);
            /* Patient */
            if (patientDoc.size === 0) {
              patientDocRef = await this.db.collection(configs.USER_COLLECTION).add({
                fullName: patient.fname + " " + patient.lname,
                phone: patient.phone ? patient.phone : '',
                dob: patient.dob ? patient.dob : '',
                email: patient.email ? patient.email : '',
                fcmtoken: [patient.fcmtoken],
                groups: [],
                uniqueId: patient.uniqueid,
                userType: USER_TYPE.PATIENT,
                createdAt: firebase.firestore.FieldValue.serverTimestamp(),
                updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
              });
            } else {
              const id = patientDoc.docs.map((item: any) => { return { id: item.id, ...item.data() } })[0].id;
              patientDocRef = this.db.collection(configs.USER_COLLECTION).doc(id);
            }

            /* Provider === Doctor */
            if (providerDoc.size === 0) {
              providerDocRef = await this.db.collection(configs.USER_COLLECTION).add({
                fullName: doctor.fullname,
                email: doctor.email,
                groups: [],
                uniqueId: doctor.uniqueid,
                userType: USER_TYPE.PROVIDER,
                createdAt: firebase.firestore.FieldValue.serverTimestamp(),
                updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
              });
            } else {
              const id = providerDoc.docs.map((item: any) => { return { id: item.id, ...item.data() } })[0].id;
              providerDocRef = this.db.collection(configs.USER_COLLECTION).doc(id);
            }

            /* Clinic */
            if (clinicDoc.size === 0) {
              clinicDocRef = await this.db.collection(configs.USER_COLLECTION).add({
                fullName: clinic.name,
                email: clinic.email,
                groups: [],
                uniqueId: clinic.uniqueid,
                userType: USER_TYPE.CLINIC,
                createdAt: firebase.firestore.FieldValue.serverTimestamp(),
                updatedAt: firebase.firestore.FieldValue.serverTimestamp(),
              });
            } else {
              const id = clinicDoc.docs.map((item: any) => { return { id: item.id, ...item.data() } })[0].id;
              clinicDocRef = this.db.collection(configs.USER_COLLECTION).doc(id);
            }

            let groupDoc = await this.db.collection(configs.GROUP_COLLECTION).where('appointmentUniqueId', '==', this.state.appointment.uniqueid).get();
            console.log(groupDoc);
            if (groupDoc.size === 0) {
              const clinicData = await clinicDocRef.get();
              const providerData = await providerDocRef.get();
              const patientData = await patientDocRef.get();
              console.log(clinicDocRef, providerDocRef, patientDocRef, clinicData, providerData, patientData);
              const groupDocRef = await this.db.collection(configs.GROUP_COLLECTION).add({
                createdAt: firebase.firestore.FieldValue.serverTimestamp(),
                createdBy: clinicData.id,
                members: [clinicData.id, providerData.id, patientData.id],
                modifiedAt: firebase.firestore.FieldValue.serverTimestamp(),
                modifiedBy: clinicData.id,
                name: doctor.fullname + "," + patient.fname + " " + patient.lname,
                recentMessage: {},
                appointmentUniqueId: this.state.appointment.uniqueid,
                requestAcceptedByProvider: {
                  updatedAt: null,
                  accepted: null
                },
                isClosed: false,
                type: 0
              });
              const groupData = await groupDocRef.get();
              let clinicDoc = await this.db.collection(configs.USER_COLLECTION).where('uniqueId', '==', clinic.uniqueid).get();
              let clinicFirebaseId = clinicDoc.docs.map((doc: any) => ({ ...doc.data, id: doc.id }))[0].id;
              await this.db.collection(configs.MESSAGE_COLLECTION).doc(groupData.id).set({
                messages: [
                  {
                    messageText: 'Open',
                    senderType: USER_TYPE.CLINIC,
                    sentBy: clinicFirebaseId,
                    sentAt: new Date(),
                    readBy: [],
                    isInfo: true
                  }
                ]
              })
              patientDocRef.update({
                "groups": firebase.firestore.FieldValue.arrayUnion(groupData.id)
              });
              clinicDocRef.update({
                "groups": firebase.firestore.FieldValue.arrayUnion(groupData.id)
              });
              providerDocRef.update({
                "groups": firebase.firestore.FieldValue.arrayUnion(groupData.id)
              });
              this.props.handleAppointment(this.state.appointment.uniqueid);
              chatNotificationProvider(doctor.uniqueid, {
                title: "Group chat initiated by" + clinic.name,
                body: '',
                notifications: {
                  groupChatInitiate: true,
                  appointmentmentUniqueId: this.state.appointment.uniqueid,
                  groupDocId: groupData.id
                }
              }).then((success: any) => { }).catch((err: any) => { });
            } else {
              const groupdata: any = groupDoc.docs.map((doc: any) => ({ ...doc.data(), groupId: doc.id }))[0];
              if (groupdata) {
                await this.db.collection(configs.GROUP_COLLECTION).doc(groupdata.groupId).update({
                  ...groupdata,
                  isClosed: false,
                  requestAcceptedByProvider: {
                    updatedAt: null,
                    accepted: null
                  },
                });
                const docRef = await this.db.collection(configs.MESSAGE_COLLECTION).doc(`${groupdata.groupId}`);
                let clinicDoc = await this.db.collection(configs.USER_COLLECTION).where('uniqueId', '==', clinic.uniqueid).get();
                let clinicFirebaseId = clinicDoc.docs.map((doc: any) => ({ ...doc.data, id: doc.id }))[0].id;
                docRef.update('messages', firebase.firestore.FieldValue.arrayUnion({
                  messageText: 'Open',
                  senderType: USER_TYPE.CLINIC,
                  sentBy: clinicFirebaseId,
                  sentAt: new Date(),
                  readBy: [],
                  isInfo: true
                }));
                getAppointmentDetail(clinic.uniqueid, this.state.appointment.uniqueid).then((response) => {
                  if (response) {
                    this.props.storeChat({
                      ...response,
                      appointmentId: "g" + response.uniqueid,
                      sendToCId: response.clinic.uniqueid,
                      pinType: "group",
                      openPin: true,
                      fetched: true,
                      ...groupdata
                    });
                  }
                }).catch((err) => { });
                chatNotificationProvider(doctor.uniqueid, {
                  title: "Group chat initiated by" + clinic.name,
                  body: '',
                  notifications: {
                    groupChatInitiate: true,
                    appointmentmentUniqueId: this.state.appointment.uniqueid,
                    groupDocId: groupdata.groupId
                  }
                }).then((success: any) => { }).catch((err: any) => { });
              }
            }
          }
        },
        {
          label: 'No',
          className: 'btn btn-outline-primary',
          onClick: () => {

          }
        }
      ]
    });
  }
  handleVoiceCall = () => {
    this.setState({
      openVoiceCallOption: true
    });
  }
  onChangeFile = (event: any) => {
    console.log("event", event);
      if (event.target.files[0]) {
        uploadGotoImage(this.state.appointment.clinic.uniqueid, { type: 'upload', 
        uniqueId: this.state.appointment.clinic.uniqueid, 
        uphoto: event.target.files[0], userType: 'clinic' }).then((res) => {
          this.setState({
            imageUrl: [...this.state.imageUrl, res.url]
          });
          this.upload.value = '';
        });
      }
  }
  render() {
    console.log("chatArray",  this.state.chatArray)
    let patientName = '';
    let matches: any = [];
    if (this.state.appointment && this.state.appointment.dependant) {
      let fullname = this.state.appointment.dependant.fname + " " + this.state.appointment.dependant.lname
      matches = fullname.match(/\b(\w)/g);
      if (matches?.length > 0) {
        patientName = (matches[0] ? matches[1] ? (matches[0] + matches[1]) : matches[0] : '');
      }
    }
    this.scrollToBottom();
    const array = this.state.chatArray;
    const checkItemIndex = this.state.chatArray.findIndex((item: any) => {
      return item.actionType === 'CHECKIN';
    });
    if (checkItemIndex !== -1) {
      const item = array[checkItemIndex];
      array.splice(checkItemIndex, 1);
      array.push(item);
    }
    let appointmentStatusClass = '';
    let appointmentdata = this.state.appointment;
    if (appointmentdata.currentStatus === 'scheduled' &&
      appointmentdata.metadata && appointmentdata.metadata.appointment &&
      appointmentdata.metadata.appointment.checkin &&
      appointmentdata.metadata.appointment.checkin.attempt) {
      appointmentStatusClass = 'status-checkin';
    } else if (appointmentdata.currentStatus === 'scheduled' &&
      appointmentdata.metadata && appointmentdata.metadata.appointment &&
      appointmentdata.metadata.appointment.prescreening &&
      appointmentdata.metadata.appointment.prescreening.attempt &&
      appointmentdata.metadata.appointment.prescreening.result === 'Fail') {
      appointmentStatusClass = 'status-prescreen-fail-chat';
    } else if (appointmentdata.currentStatus === 'scheduled') {
      appointmentStatusClass = 'status-schedule-chat'
    }
    else if (appointmentdata.currentStatus === 'requested') {
      appointmentStatusClass = 'status-waitlist-chat';
    } else if (appointmentdata.currentStatus === 'waitlist') {
      appointmentStatusClass = 'status-waitlist-chat';
    }
    let takeWritebackBool = false;
    let apptScheduled = true;
    let apptReSchedule = true;
    let apptConfirmPatient = true;
    let apptComplete = true;
    let apptEdit = true;
    let apptCancel = true;
    let apptPMSStatus = true;
    let tempWriteBack = false;
    if (this.props.userData && this.props.userData.clinics && this.props.userData.clinics[0] &&
      this.props.userData.clinics[0].tempwriteBack === "on") {
      tempWriteBack = true;
    }
    if (this.props?.userData?.accessEnabled?.pms_integration === true) {
      if (this.props.userData.clinics[0].writeBack === "on" &&
        this.props.userData.clinics[0].appointmentwriteback === "on") {
        apptReSchedule = false;
      }
      if (this.props.userData.clinics[0].writeBack === "off") {
        apptScheduled = false;
        // apptReSchedule = true; // enabled for special case of SIKKA status sync issue
        apptConfirmPatient = false;
        apptComplete = true; // enabled for special case of SIKKA status sync issue
        apptEdit = true; // enabled for special case of SIKKA status sync issue
        apptCancel = true; //enabled for special case of SIKKA status sync issue
        apptPMSStatus = false;
      } else if (this.props.userData.clinics[0].writeBack === "on" &&
        this.props.userData.clinics[0].patientwriteback === "off" &&
        this.props.userData.clinics[0].appointmentwriteback === "off" &&
        this.props.userData.clinics[0].statuswriteback === "off") {
        apptScheduled = false;
        // apptReSchedule = false;
        apptConfirmPatient = false;
        apptComplete = false;
        apptEdit = false;
        apptCancel = false;
        apptPMSStatus = false;
      } else if (this.props.userData.clinics[0].writeBack === "on" &&
        this.props.userData.clinics[0].patientwriteback === "on" &&
        this.props.userData.clinics[0].appointmentwriteback === "off" &&
        this.props.userData.clinics[0].statuswriteback === "off") {
        apptScheduled = false;
        // apptReSchedule = false;
        apptConfirmPatient = false;
        apptComplete = false;
        apptEdit = false;
        apptCancel = false;
        apptPMSStatus = false;
      } else if (this.props.userData.clinics[0].writeBack === "on" &&
        this.props.userData.clinics[0].patientwriteback === "on" &&
        this.props.userData.clinics[0].appointmentwriteback === "off" &&
        this.props.userData.clinics[0].statuswriteback === "on") {
        apptScheduled = false;
        // apptReSchedule = false;
        apptEdit = false;
      } else if (this.props.userData.clinics[0].writeBack === "on" &&
        this.props.userData.clinics[0].patientwriteback === "off" &&
        this.props.userData.clinics[0].appointmentwriteback === "off" &&
        this.props.userData.clinics[0].statuswriteback === "on") {
        apptScheduled = false;
        // apptReSchedule = false;
        apptEdit = false;
      } else if (this.props.userData.clinics[0].writeBack === "on" &&
        this.props.userData.clinics[0].patientwriteback === "off" &&
        this.props.userData.clinics[0].appointmentwriteback === "off" &&
        this.props.userData.clinics[0].statuswriteback === "off") {
        apptScheduled = false;
        // apptReSchedule = false;
        apptConfirmPatient = false;
        apptComplete = false;
        apptEdit = false;
        apptCancel = false;
        apptPMSStatus = false;
      }
    }
    if (this.props.userData.clinics[0] &&
      this.props.userData?.accessEnabled?.pms_integration === true &&
      (this.props.userData.clinics[0].writeBack === "on") &&
      appointmentdata.metadata && appointmentdata.metadata.pms && appointmentdata.metadata.pms.writeBackStatus && appointmentdata.metadata.pms.writeBackStatus === "Success") {
      takeWritebackBool = true;
    } else if (this.props.userData.clinics[0] &&
      this.props.userData?.accessEnabled?.pms_integration === true &&
      (this.props.userData.clinics[0].writeBack === "off")) {
      takeWritebackBool = true; //enabled for special case of sikka sync issue.
    } else if (this.props.userData.clinics[0] &&
      this.props.userData?.accessEnabled?.pms_integration === false &&
      this.props.userData.clinics[0].writeBack === "off" &&
      (!(appointmentdata.metadata && appointmentdata.metadata.pms && appointmentdata.metadata.pms.writeBackStatus) ||
        (appointmentdata.metadata && appointmentdata.metadata.pms && appointmentdata.metadata.pms.writeBackStatus === "Success"))) {
      takeWritebackBool = true;
    }
    let sortedArray = _.sortBy(array, function (value: any) { return value.sortTime });
    return (
      <div
        className={
          "fabs fabs" +
          this.props.chatProps.appointmentId +
          " " +
          (this.props.chatProps.pinType === "chat" &&
            appointmentStatusClass !== "status-checkin"
            ? "pin-chat " + appointmentStatusClass
            : "pin-checkin ")
        }
        id={"main" + this.props.chatProps.appointmentId}
        key={"main" + this.props.chatProps.appointmentId}
      >
        <div
          className={"chat chat" + this.props.chatProps.appointmentId}
          id={"Chats" + this.props.chatProps.appointmentId}
        >
          <div className="chat_header">
            <div className="actionBtn">
              <div className="d-flex justify-content-end">
                {false &&
                  (takeWritebackBool || tempWriteBack) &&
                  apptComplete &&
                  this.state.appointment.currentStatus === "scheduled" &&
                  moment(
                    this.state.appointment.date +
                    " " +
                    this.state.appointment.time,
                    "YYYY-MM-DD LT"
                  ).isBefore(moment()) &&
                  !(
                    this.state.appointment.metadata &&
                    this.state.appointment.metadata.appointment &&
                    this.state.appointment.metadata.appointment
                      .confirmation &&
                    this.state.appointment.metadata.appointment.confirmation
                      .status === "cancelled"
                  ) &&
                  !(
                    this.state.appointment.metadata &&
                    this.state.appointment.metadata.appointment &&
                    this.state.appointment.metadata.appointment
                      .prescreening &&
                    this.state.appointment.metadata.appointment.prescreening
                      .attempt &&
                    this.state.appointment.metadata.appointment.prescreening
                      .result === "Fail"
                  ) && (
                    <OverlayTrigger
                      placement="auto"
                      overlay={
                        <Tooltip id="tooltip">
                          Mark Appt. Complete & Close
                        </Tooltip>
                      }
                    >
                      <button
                        className="btn p-1 text-white"
                        type="button"
                        onClick={() => {
                          this.handleChatVerifyAndClose();
                        }}
                      >
                        <i className="bi bi-patch-check"></i>
                      </button>
                    </OverlayTrigger>
                  )}
                  {
                    (this.state?.appointment?.dependant?.phone || this.state?.appointment?.dependant?.homephone || this.state?.appointment?.patient?.phone) &&
                    <OverlayTrigger
                      placement="auto"
                      overlay={<Tooltip id="tooltip">Voice Call</Tooltip>}
                    >
                      <button
                        className="btn p-1 text-white ms-1"
                        type="button"
                        onClick={() => {
                          this.handleVoiceCall();
                        }}
                      >
                        <i className="bi bi-telephone"></i>
                      </button>
                    </OverlayTrigger>
                  }

                <OverlayTrigger
                  placement="auto"
                  overlay={<Tooltip id="tooltip">Group Chat</Tooltip>}
                >
                  <a
                    href="#"
                    className={"p-1 text-white ms-1 " + (configs.SOURCE_URL !== "https://www.xcarepro.com/" ? "" : "d-none")}
                    onClick={this.handleGroupChatStart}
                  >
                    <i className="bi bi-people ms-1"></i>
                  </a>
                </OverlayTrigger>

                <OverlayTrigger
                  placement="auto"
                  overlay={<Tooltip id="tooltip">Minimize chat</Tooltip>}
                >
                  <button
                    className="btn p-1 text-white ms-1"
                    type="button"
                    onClick={() => {
                      this.handleChatClose();
                    }}
                  >
                    <i className="bi bi-dash-circle"></i>
                  </button>
                </OverlayTrigger>
                <OverlayTrigger
                  placement="auto"
                  overlay={<Tooltip id="tooltip">Close chat</Tooltip>}
                >
                  <button
                    className="btn p-1 text-white ms-1"
                    type="button"
                    onClick={() => {
                      this.handleRemoveChat();
                    }}
                  >
                    <i className="bi bi-x-circle"></i>
                  </button>
                </OverlayTrigger>
              </div>
            </div>

            <div className="d-flex justify-content-between align-items-center">
              <div className="d-flex align-items-center">
                <div className="avatar rounded-circle bg-white text-dark text-uppercase flex-shrink-0">
                  {patientName}
                </div>
                <div className="flex-grow-1 ms-3">
                  <div
                    id={"chat_head" + this.state.appointment.id}
                    className="d-block fw-medium text-white charlimit lh-base"
                  >
                    {this.state.appointment &&
                      this.state.appointment.dependant
                      ? this.state.appointment.dependant.fname +
                      " " +
                      this.state.appointment.dependant.lname
                      : ""}
                  </div>
                  <small className="d-block text-white lh-base">
                    {this.state.appointment
                      ? this.state.appointment.currentStatus
                      : ""}
                    {this.state.appointment.currentStatus === "scheduled" &&
                      this.state.appointment.metadata &&
                      this.state.appointment.metadata.appointment &&
                      this.state.appointment.metadata.appointment
                        .floating &&
                      "- floating"}
                    {this.state.appointment.currentStatus === "scheduled" &&
                      this.state.appointment.metadata &&
                      this.state.appointment.metadata.appointment &&
                      this.state.appointment.metadata.appointment
                        .confirmation &&
                      this.state.appointment.metadata.appointment
                        .confirmation.status === "cancelled" &&
                      "- cancelled"}
                    {this.state.appointment.currentStatus === "scheduled" &&
                      this.state.appointment.metadata &&
                      this.state.appointment.metadata.appointment &&
                      this.state.appointment.metadata.appointment
                        .confirmation &&
                      this.state.appointment.metadata.appointment
                        .confirmation.status === "confirmed" &&
                      "- confirmed"}
                  </small>
                </div>
              </div>
            </div>
          </div>
          <div
            id={"chat_fullscreen" + this.props.chatProps.appointmentId}
            className="chat_conversion chat_converse"
          >
            {sortedArray.map((value: any, index: number) => {
              if (value.actionType === CHAT.ACTION_TYPE.MESSAGE) {
                let letterOne = "",
                  letterTwo = "";
                if (value.firstName) {
                  letterOne = value.firstName.charAt(0);
                }
                if (value.lastName) {
                  letterTwo = value.lastName.charAt(0);
                }
                value.text = value?.text ?? value.message;
                return (
                  <div
                    className={
                      "pb-4 " +
                      (value.sender === CHAT.SENDER.PATIENT
                        ? "chat-message-left cuser"
                        : "") +
                      (value.sender === CHAT.SENDER.USER
                        ? "chat-message-right cadmin"
                        : "")
                    }
                    key={index + Math.random()}
                  >
                    
                    {(letterOne !== "" || letterTwo !== "") && (
                      <div className="avatar avatar-icon avatar-md newAvtr">
                        <Avatar>
                          {letterOne}
                          {letterTwo}
                        </Avatar>
                      </div>
                    )}
                    <div
                      className={
                        (value.sender === CHAT.SENDER.PATIENT
                          ? "chat-message-left cuser"
                          : "chatbu rounded py-2 px-3 bg-light text-white") +
                        (value.sender === CHAT.SENDER.USER
                          ? "chat-message-right cadmin"
                          : "chatbu rounded py-2 px-3 bg-light")
                      } 
                    >
                      <img src={value.imageUrl}></img>
                      {nl2br(value.text)}
                    </div>
                    <span className="msg_date">
                      {value && value.createdAt && (
                        <>
                          {" "}
                          {moment(
                            moment.unix(value.createdAt.seconds)
                          ).format("MMM DD, YYYY - LT")}
                          {value.medium &&
                            value.medium === CHAT.SEND_MEDIUM.APP && (
                              <> - sent via app</>
                            )}
                          {value.medium &&
                            value.medium === CHAT.SEND_MEDIUM.SMS && (
                              <> - sent via sms</>
                            )}
                          {value.medium &&
                            value.medium === CHAT.SEND_MEDIUM.CHAT && (
                              <> - not sent via sms/app</>
                            )}
                          {(value.firstName || value.lastName) && (
                            <>
                              {" "}
                              <br />
                              {/* Sent by{" "}
                              {value.firstName + " " + value.lastName} */}
                            </>
                          )}
                        </>
                      )}
                    </span>
                  </div>
                );
              } else if (
                value.actionType === CHAT.ACTION_TYPE.MESSAGE_TEXT
              ) {
                let letterOne = "",
                  letterTwo = "";
                if (value.firstName) {
                  letterOne = value.firstName.charAt(0);
                }
                if (value.lastName) {
                  letterTwo = value.lastName.charAt(0);
                }
                return (
                  <div
                    className={
                      "chat_msg_item " +
                      (value.sender === CHAT.SENDER.PATIENT
                        ? "chat_msg_item_admin"
                        : "") +
                      (value.sender === CHAT.SENDER.USER
                        ? "chat_msg_item_user"
                        : "")
                    }
                    key={index + Math.random()}
                  >
                    {(letterOne !== "" || letterTwo !== "") && (
                      <div className="avatar avatar-icon avatar-md newAvtr">
                        <Avatar>
                          {letterOne}
                          {letterTwo}
                        </Avatar>
                      </div>
                    )}

                    <div className="chat-bubble">{nl2br(value.text)}</div>
                    <span className="msg_date">
                      {value && value.createdAt && (
                        <>
                          {" "}
                          {moment(
                            moment.unix(value.createdAt.seconds)
                          ).format("MMM DD, YYYY - LT")}
                          {value.medium &&
                            value.medium === CHAT.SEND_MEDIUM.APP && (
                              <> - sent via app</>
                            )}
                          {value.medium &&
                            value.medium === CHAT.SEND_MEDIUM.SMS && (
                              <> - sent via sms</>
                            )}
                          {value.medium &&
                            value.medium === CHAT.SEND_MEDIUM.CHAT && (
                              <> - not sent via sms/app</>
                            )}
                          <br />
                          {(value.firstName || value.lastName) && (
                            <>
                              {" "}
                              {/* Sent by{" "}
                              {value.firstName + " " + value.lastName} */}
                            </>
                          )}
                        </>
                      )}
                    </span>
                  </div>
                );
              } else if (
                value.actionType === CHAT.ACTION_TYPE.MESSAGE_CENTER_TEXT
              ) {
                let text = value.text;
                const index = text.indexOf("Sent On");
                const newTxt = text.substr(index, value.text.length);
                text = text.replace(newTxt, "");
                const index1 = text.indexOf("Sent");
                const newTxt1 = text.substr(index1, text.length);
                text = text.replace(newTxt1, "");
                return (
                  <div
                    className="alert alert-light border py-2 text-center"
                    key={index + Math.random()}
                  >
                    <span>
                      {text} sent on
                      {value && value.createdAt && (
                        <>
                          {" "}
                          {moment(
                            moment.unix(value.createdAt.seconds)
                          ).format("MMM DD, YYYY - LT")}
                          {value.senderName && value.senderName !== "" && (
                            <></>
                          )}
                        </>
                      )}
                    </span>
                  </div>
                );
              } else if (
                value.actionType === CHAT.ACTION_TYPE.VOICE_CALL
              ) {
                return (
                  <div
                    className="alert alert-light border py-2 text-center"
                    key={index + Math.random()}
                  >
                    <span>
                      {value?.text ?? value?.message}
                    </span>
                  </div>
                );
              } else if (value.actionType === CHAT.ACTION_TYPE.STATUS) {
                if (
                  value.toStatus === CHAT.TO_STATUS.REQUESTED &&
                  value.fromStatus === CHAT.FROM_STATUS.CANCELLED
                ) {
                  return (
                    <div
                      className="alert alert-success py-2 text-center"
                      key={index + Math.random()}
                    >
                      {this.state.appointment &&
                        this.state.appointment.dependant &&
                        this.state.appointment.dependant.fname &&
                        this.state.appointment.dependant.fname && (
                          <>
                            {this.state.appointment.dependant.fname +
                              " " +
                              this.state.appointment.dependant.lname}
                          </>
                        )}{" "}
                      indicated that they need to reschedule. Reach out
                      to&nbsp;
                      {this.state.appointment &&
                        this.state.appointment.dependant &&
                        this.state.appointment.dependant.fname &&
                        this.state.appointment.dependant.fname && (
                          <>
                            {this.state.appointment.dependant.fname +
                              " " +
                              this.state.appointment.dependant.lname}
                          </>
                        )}{" "}
                      to reschedule.
                    </div>
                  );
                } else if (value.toStatus === CHAT.TO_STATUS.COMPLETED) {
                  return (
                    <div
                      className="alert alert-success py-2 text-center"
                      key={index + Math.random()}
                    >
                      Appointment {value.toStatus.toLowerCase()}
                      {value && value.createdAt && value.createdAt.seconds && (
                        <>
                          &nbsp;on&nbsp;
                          {moment(
                            moment.unix(value.createdAt.seconds)
                          ).format("MMM DD, YYYY") +
                            " at " +
                            moment(
                              moment.unix(value.createdAt.seconds)
                            ).format("LT")}
                        </>
                      )}
                    </div>
                  );
                } else if (value.toStatus === CHAT.TO_STATUS.CANCELLED) {
                  return (
                    <div
                      className="alert alert-danger py-2 text-center"
                      key={index + Math.random()}
                    >
                      The Appointment has been{" "}
                      {value.toStatus.toLowerCase()}
                      <>
                        {value &&
                          value.createdAt &&
                          value.createdAt.seconds && (
                            <>
                              &nbsp;on&nbsp;
                              {moment(
                                moment.unix(value.createdAt.seconds)
                              ).format("MMM DD, YYYY") +
                                " at " +
                                moment(
                                  moment.unix(value.createdAt.seconds)
                                ).format("LT")}
                            </>
                          )}
                      </>
                    </div>
                  );
                } else if (
                  value.fromStatus === CHAT.TO_STATUS.SCHEDULED &&
                  value.toStatus === CHAT.TO_STATUS.SCHEDULED
                ) {
                  return (
                    <div
                      className="alert alert-info py-2 text-center"
                      key={index + Math.random()}
                    >
                      Appointment rescheduled
                      {value &&
                        value.dateTime &&
                        value.dateTime.date &&
                        value.dateTime.time && (
                          <>
                            &nbsp;on&nbsp;
                            {moment(value.dateTime.date).format(
                              "MMM DD, YYYY"
                            ) +
                              " at " +
                              moment(
                                value.dateTime.date +
                                " " +
                                value.dateTime.time,
                                "YYYY-MM-DD LT"
                              ).format("LT")}
                          </>
                        )}
                    </div>
                  );
                } else if (value.toStatus === CHAT.TO_STATUS.SCHEDULED) {
                  return (
                    <div
                      className="alert alert-info py-2 text-center"
                      key={index + Math.random()}
                    >
                      Appointment {value.toStatus.toLowerCase()}
                      {value &&
                        value.dateTime &&
                        value.dateTime.date &&
                        value.dateTime.time && (
                          <>
                            &nbsp;on&nbsp;
                            {moment(value.dateTime.date).format(
                              "MMM DD, YYYY"
                            ) +
                              " at " +
                              moment(
                                value.dateTime.date +
                                " " +
                                value.dateTime.time,
                                "YYYY-MM-DD LT"
                              ).format("LT")}
                          </>
                        )}
                    </div>
                  );
                }
              } else if (
                value.actionType === CHAT.ACTION_TYPE.PRESCREENING
              ) {
                return (
                  <>
                    <div
                      className="alert alert-info py-2 text-center"
                      key={index + Math.random()}
                    >
                      <span> Pre-screening status:</span> completed{" "}
                      <>
                        {value &&
                          value.createdAt &&
                          value.createdAt.seconds && (
                            <>
                              &nbsp;at&nbsp;
                              {moment(
                                moment.unix(value.createdAt.seconds)
                              ).format("MMM DD, YYYY") +
                                " - " +
                                moment(
                                  moment.unix(value.createdAt.seconds)
                                ).format("LT")}
                            </>
                          )}
                      </>
                    </div>
                  </>
                );
              } else if (
                value.actionType === CHAT.ACTION_TYPE.PRESCREENING_STATUS
              ) {
                return (
                  <div
                    className="alert alert-success py-2 text-center"
                    key={index + Math.random()}
                  >
                    <span> Pre-screening status:</span> verified{" "}
                    <>
                      {value && value.createdAt && value.createdAt.seconds && (
                        <>
                          &nbsp;at&nbsp;
                          {moment(
                            moment.unix(value.createdAt.seconds)
                          ).format("MMM DD, YYYY") +
                            " - " +
                            moment(
                              moment.unix(value.createdAt.seconds)
                            ).format("LT")}
                        </>
                      )}
                    </>
                  </div>
                );
              } else if (
                value.actionType === CHAT.ACTION_TYPE.PRESCREENING_REQUEST
              ) {
                return (
                  <div
                    className="alert alert-warning py-2 text-center"
                    key={index + Math.random()}
                  >
                    <span> Pre-screening status:</span> requested{" "}
                    <>
                      {value && value.createdAt && value.createdAt.seconds && (
                        <>
                          &nbsp;at&nbsp;
                          {moment(
                            moment.unix(value.createdAt.seconds)
                          ).format("MMM DD, YYYY") +
                            " - " +
                            moment(
                              moment.unix(value.createdAt.seconds)
                            ).format("LT")}
                        </>
                      )}
                    </>
                  </div>
                );
              } else if (
                value.actionType === CHAT.ACTION_TYPE.PAYMENT_REQUEST
              ) {
                if (
                  this.state.appointment.billingdata &&
                  this.state.appointment.billingdata
                    .clinicToPatientBilling &&
                  this.state.appointment.billingdata.clinicToPatientBilling
                    .total
                ) {
                  return (
                    <div
                      className="alert alert-light border py-2 text-center"
                      key={index + Math.random()}
                    >
                      <span>
                        {" "}
                        You request $
                        {value.amount
                          ? value.amount
                          : this.state.appointment.billingdata
                            .clinicToPatientBilling.total}{" "}
                        from{" "}
                        {this.state.appointment.dependant.fname +
                          " " +
                          this.state.appointment.dependant.lname}{" "}
                      </span>
                      <>
                        {value &&
                          value.createdAt &&
                          value.createdAt.seconds && (
                            <>
                              &nbsp;on&nbsp;
                              {moment(
                                moment.unix(value.createdAt.seconds)
                              ).format("MMM DD, YYYY") +
                                " - " +
                                moment(
                                  moment.unix(value.createdAt.seconds)
                                ).format("LT")}
                            </>
                          )}
                      </>
                    </div>
                  );
                }
                return null;
              } else if (
                value.actionType === CHAT.ACTION_TYPE.PAYMENT_SUCCESSFUL
              ) {
                return (
                  <div
                    className="alert alert-light border py-2 text-center"
                    key={index + Math.random()}
                  >
                    <span>
                      {" "}
                      {this.state.appointment.dependant.fname +
                        " " +
                        this.state.appointment.dependant.lname}{" "}
                      paid on{" "}
                    </span>
                    <>
                      {value && value.createdAt && value.createdAt.seconds && (
                        <>
                          &nbsp;on&nbsp;
                          {moment(
                            moment.unix(value.createdAt.seconds)
                          ).format("MMM DD, YYYY") +
                            " - " +
                            moment(
                              moment.unix(value.createdAt.seconds)
                            ).format("LT")}
                        </>
                      )}
                    </>
                  </div>
                );
              } else if (
                value.actionType === CHAT.ACTION_TYPE.APPONINTMENT_ASSIGN
              ) {
                return (
                  <div className="alertparent" key={index + Math.random()}>
                    <div
                      className="alert alert-light border py-2 text-center"
                      key={index + Math.random()}
                    >
                      <span>
                        Appointment is assigned to {value.assignToEmail}
                      </span>
                    </div>
                  </div>
                );
              } else if (value.actionType === CHAT.ACTION_TYPE.CHECKIN) {
                return (
                  <>
                    <div
                      className="alert alert-warning py-2 text-center"
                      key={index + Math.random()}
                    >
                      <span> Checked in status:</span> {value.text}{" "}
                      <>
                        {value &&
                          value.createdAt &&
                          value.createdAt.seconds && (
                            <>
                              &nbsp;at&nbsp;
                              {moment(
                                moment.unix(value.createdAt.seconds)
                              ).format("MMM DD, YYYY") +
                                " - " +
                                moment(
                                  moment.unix(value.createdAt.seconds)
                                ).format("LT")}
                            </>
                          )}
                      </>
                    </div>
                  </>
                );
              } else if (
                value.actionType === CHAT.ACTION_TYPE.CONFIRM_BY_PATIENT
              ) {
                return (
                  <>
                    <div
                      className="alert alert-warning py-2 text-center"
                      key={index + Math.random()}
                    >
                      <span> Appointment:</span> {value.text}{" "}
                      <>
                        {value &&
                          value.createdAt &&
                          value.createdAt.seconds && (
                            <>
                              &nbsp;on&nbsp;
                              {moment(
                                moment.unix(value.createdAt.seconds)
                              ).format("MMM DD, YYYY") +
                                " at " +
                                moment(
                                  moment.unix(value.createdAt.seconds)
                                ).format("LT")}
                            </>
                          )}
                      </>
                    </div>
                  </>
                );
              } else if (
                value.actionType === CHAT.ACTION_TYPE.CANCELLED_BY_PATIENT
              ) {
                return (
                  <>
                    <div
                      className="alert alert-warning py-2 text-center"
                      key={index + Math.random()}
                    >
                      <span> Appointment:</span> {value.text}{" "}
                      <>
                        {value &&
                          value.createdAt &&
                          value.createdAt.seconds && (
                            <>
                              &nbsp;on&nbsp;
                              {moment(
                                moment.unix(value.createdAt.seconds)
                              ).format("MMM DD, YYYY") +
                                " at " +
                                moment(
                                  moment.unix(value.createdAt.seconds)
                                ).format("LT")}
                            </>
                          )}
                      </>
                    </div>
                  </>
                );
              } else if (
                value.actionType === CHAT.ACTION_TYPE.CONFIRM_BY_PATIENT
              ) {
                return (
                  <>
                    <div
                      className="alert alert-warning py-2 text-center"
                      key={index + Math.random()}
                    >
                      <span> Appointment:</span> {value.text}{" "}
                      <>
                        {value &&
                          value.createdAt &&
                          value.createdAt.seconds && (
                            <>
                              &nbsp;on&nbsp;
                              {moment(
                                moment.unix(value.createdAt.seconds)
                              ).format("MMM DD, YYYY") +
                                " at " +
                                moment(
                                  moment.unix(value.createdAt.seconds)
                                ).format("LT")}
                            </>
                          )}
                      </>
                    </div>
                  </>
                );
              } else if (value.sender === 'clinic') {
                let letterOne = '', letterTwo = '';
                if (value.firstName) {
                  letterOne = value.firstName.charAt(0);
                }
                if (value.lastName) {
                  letterTwo = value.lastName.charAt(0);
                }
                return (
                  <div className="chat-message-right cadmin pb-4" key={index + Math.random()}>
                    {
                      (letterOne !== '' || letterTwo !== '') &&
                      // <div className="avatar avatar-icon avatar-md newAvtr">
                      <Avatar>{letterOne}{letterTwo}</Avatar>
                      // </div>
                    }
                     
                    <div className="chatbu rounded py-2 px-3 bg-light">
                      <img src={value.imageUrl}></img>
                      {value.message}
                    </div>
                    <div className="text-muted text-end small text-nowrap">
                      {
                        value &&
                        value.createdAt &&
                        <> {moment(moment.unix(value.createdAt.seconds)).format("MMM DD, YYYY - LT")}
                          {
                            (value.fullName && value.fullName !== "")
                          }
                        </>
                      }
                      {
                        (value.medium && value.medium === CHAT.SEND_MEDIUM.SMS) &&
                        <> - sent via sms</>
                      }
                      {
                        (value.medium && value.medium === CHAT.SEND_MEDIUM.CHAT) &&
                        <> - not sent via sms/app</>
                      }
                    </div>
                  </div>
                )
              } else if (value.sender === 'patient') {
                let letterOne = '', letterTwo = '';
                if (value.firstName) {
                  letterOne = value.firstName.charAt(0);
                }
                if (value.lastName) {
                  letterTwo = value.lastName.charAt(0);
                }
                return (
                  <div className="chat-message-left cuser pb-4" key={index + Math.random()}>
                    {
                      (letterOne !== '' || letterTwo !== '') &&
                      // <div className="avatar avatar-icon avatar-md newAvtr">
                      <Avatar>{letterOne}{letterTwo}</Avatar>
                      // </div>
                    }
                    
                    <div className="chatbu rounded py-2 px-3 bg-primary text-white">
                      <img src={value.imageUrl}></img>
                      {value.message}
                    </div>
                    <div className="text-muted text-start small text-nowrap">
                      {
                        value &&
                        value.createdAt &&
                        <> {moment(moment.unix(value.createdAt.seconds)).format("MMM DD, YYYY - LT")}
                          {
                            (value.fullName && value.fullName !== "")
                          }
                        </>
                      }
                      {
                        (value.medium && value.medium === CHAT.SEND_MEDIUM.SMS) &&
                        <>- received via sms</>
                      }
                    </div>
                  </div>
                )
              }

              return null;
            })}
          </div>
          <div className="fab_field">
            <div className='preview-image'>{
                this.state?.imageUrl?.map((item, idx) => {
                  return (
                    <React.Fragment>
                      <img src={item}  width={200}/>
                      <i onClick={() => {
                        this.setState({
                          imageUrl: this.state.imageUrl?.filter((item, index) => index !== idx)
                        });
                      }} className="bi bi-x-circle-fill"></i>

                    </React.Fragment>
                  )
                })
              } 
            </div>
            <textarea
              value={this.state.chatMessageInput}
              id={"chatSend" + this.props.chatProps.appointmentId}
              name="chat_message"
              onKeyDown={(e) => {
                if (e.keyCode === 13) {
                  this.onMessageSendClick(
                    e,
                    this.props.chatProps.appointmentId
                  );
                }
              }}
              onChange={(e) => {
                if (e.target.value.length > 160) {
                  return;
                }
                this.setState({ chatMessageInput: e.target.value });
                this.scrollToBottom();
              }}
              ref={(message) => (this.messageInput = message)}
              placeholder="Type a message"
              className="chat_field chat_message"
            ></textarea>
            <input id="myInput" className='d-none' accept="image/png,image/gif,image/jpeg" type="file" ref={(ref) => this.upload = ref} style={{ display: 'none' }} onChange={this.onChangeFile.bind(this)} />
            <div className='fab-icon-adjust'>{
              this.props.userData?.clinics[0]?.clinicSetting.communicationprovider === "goto" &&
                <a
                  id="fab_send"
                  className={"fab is-visible"}
                  onClick={(e) => {
                    this.upload.click();
                  }}
                >
                  <i className="bi bi-upload"></i>
                </a> 
            }
              <a
                id="fab_send"
                className={"fab is-visible"}
                onClick={(e) => {
                  this.onMessageSendClick(
                    e,
                    this.props.chatProps.appointmentId
                  );
                }}
              >
              <span className="material-icons">send</span>
            </a>
            </div>
          </div>
        </div>

        <div
          id={"prime" + this.props.chatProps.appointmentId}
          className={
            "fab avatar avatar-lg rounded-circle text-uppercase fab" +
            this.props.chatProps.appointmentId
          }
          onClick={() => {
            this.props.handleCloseChat(this.props.chatProps.appointmentId);
            this.scrollToBottom();
          }}
        >
          <div className="fab-bcn">{patientName}</div>
          <div className="fab-bic">
            <i className="bi bi-chevron-down"></i>
          </div>
          {this.props.chatProps.pinType === "chat" ? (
            <span className="position-absolute pintype">
              <i className="bi bi-chat-text-fill"></i>
            </span>
          ) : (
            <span className="position-absolute pintype">
              <i className="bi bi-geo-alt-fill"></i>
            </span>
          )}
        </div>
        {this.state.openVoiceCallOption && (
          <ShowCallOption
            appointment={this.props.chatProps}
            closeModal={() => {
              this.setState({ openVoiceCallOption: false });
            }}
            call={(callTo: string, callType: string) => {
              document
                .getElementById("position-rel")
                ?.classList.add("call-start");
              this.setState({
                openVoiceCall: true,
                openVoiceCallOption: false,
                callTo: callTo,
                callType: callType,
              });
            }}
          ></ShowCallOption>
        )}
        {this.state.openVoiceCall && this.props?.userData?.clinics[0].clinicSetting.communicationprovider === "twilio" && (
          <VoiceCall
            appointment={this.props.chatProps}
            clientId={this.props.chatProps.clinic.uniqueId}
            callType={this.state.callType}
            callTo={this.state.callTo}
            closeModal={() => {
              this.setState({
                openVoiceCall: false,
                callType: "",
                callTo: "",
              });
            }}
            callerId={
              this.props?.userData?.clinics &&
                this.props?.userData?.clinics[0] &&
                this.props?.userData?.clinics[0].twilioCell
                ? this.props?.userData?.clinics[0].twilioCell
                : ""
            }
          ></VoiceCall>
        )}
        {this.state.openVoiceCall && this.props?.userData?.clinics[0].clinicSetting.communicationprovider === "mango" && (
          <VoiceCallMango
            appointment={this.props.chatProps}
            clientId={this.props.chatProps.clinic.uniqueId}
            callType={this.state.callType}
            callTo={this.state.callTo}
            closeModal={() => {
              this.setState({
                openVoiceCall: false,
                callType: "",
                callTo: "",
              });
            }}
            callerId={
              this.props?.userData?.clinics &&
                this.props?.userData?.clinics[0] &&
                this.props?.userData?.clinics[0].clinicSetting.mangoCell
                ? this.props?.userData?.clinics[0].clinicSetting.mangoCell
                : ""
            }
          ></VoiceCallMango>
        )}
        {this.state.openVoiceCall && this.props?.userData?.clinics[0].clinicSetting.communicationprovider === "goto" && (
          <VoiceCallGoto
            appointment={this.props.chatProps}
            clientId={this.props.chatProps.clinic.uniqueId}
            callType={this.state.callType}
            callTo={this.state.callTo}
            closeModal={() => {
              this.setState({
                openVoiceCall: false,
                callType: "",
                callTo: "",
              });
            }}
            callerId={
              this.props?.userData?.clinics &&
                this.props?.userData?.clinics[0] &&
                this.props?.userData?.clinics[0].clinicSetting.mangoCell
                ? this.props?.userData?.clinics[0].clinicSetting.mangoCell
                : ""
            }
          ></VoiceCallGoto>
        )}
      </div>
    );
  }
}
const mapStateToProps = (state: IAppState) => ({
  appointmentResponse: state.appointment.appointmentDetailResponse ? state.appointment.appointmentDetailResponse : undefined,
  userData: state.userData.userDataResponse,
});
const mapDispatchToProps = (dispatch: any) => ({
  fetchAppointmentDetail: (request: AppointmentDetailRequest) => dispatch(appointmentDetail(request)),
  logout: () => {
    dispatch(userLogout());
  },
  loadingSuccess: (status: boolean) => { dispatch(fetchLoadingSuccess(status)); },
  removeChatPin: (appointmentId: any) => {
    dispatch(removeChatPin(appointmentId));
  },
  closeChatPin: (appointmentId: any) => {
    dispatch(closeChatPin(appointmentId));
  },
  storeChat: (request: any) => {
    dispatch(storeChatData(request));
  }
});
export default connect(mapStateToProps, mapDispatchToProps)(Chat);