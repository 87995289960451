import React, { Component } from 'react';
import CardInfo from './CardInfo'
import { toPlainObject } from 'lodash';
import { removeCardPendingAction } from '../../store/billing/actions';
import { toast } from 'react-toastify';
import { IAppState } from '../../store';
import { createSubscriptionPlan } from '../../store/subscriptionplan/actions';
import { SubscriptionState, CreateSubscriptionPlanRequest } from '../../store/subscriptionplan/types';
import { connect } from 'react-redux';
import SpinnerIcon from './images/spinner-icon.svg';
import PlanIconProgress from './images/plan-icon-progress.svg';
import AddonIconProgress from './images/addon-icon-progress.svg';
import CreditCardIconProgress from './images/credit_card-icon-progress.svg';
import InfoIcon from './images/info-icon.svg';
import ContactlessPayments from './images/contactless-payments.svg';
import RightArrowIcon from './images/right-arrow-icon.svg';
import NotIncluderIcon from './images/not-includer-icon.svg';
interface Props {
    data: any;
    selectedPlan: any;
    history: any;
    type: any;
    interval: any;
    selectedAddon: any;
    createSubscriptionPlan: typeof createSubscriptionPlan;
    subscriptionState: SubscriptionState;
    clinicId: any;
}
interface State {
    isCheckOut: boolean;
    planType: any; // Monthly or Annualy
    planList: any;
    addons: any;
    subTotal: any;
    coupenPrice: any;
    total: any;
    paymentDetails: any;
    planPrice: any;
    isIntervalChange: boolean;
    selectedCard: any;
    selectedAddressId: any;
    billingList: any;

}
class GetPlan extends Component<Props, State>{
    constructor(props: any) {
        super(props);
        this.state = {
            isCheckOut: false,
            planType: 'Monthly',
            planList: '', addons: [],
            subTotal: 0, coupenPrice: 0, total: 0,
            paymentDetails: '', planPrice: 0, isIntervalChange: true,
            selectedCard: '', selectedAddressId: '', billingList: []
        }
    }
    componentDidMount() {

        if (this.props.type === "standard" && this.props.interval) {
            this.setState({ planList: this.props.interval === "month" ? this.props.data.month : this.props.data.year }, () => {
                this.state.planPrice.map((item: any) => {
                    if (item.planType === "standard") {
                        this.setState({
                            planPrice: Number(item.plan_price),
                            subTotal: Number(item.plan_price),
                            total: Number(item.plan_price),
                            isIntervalChange: false
                        });
                    }
                });
            });
        } else {
            if (this.props.data) {
                if (this.state.planType === 'Monthly') {
                    this.setState({ planList: this.props.data.month })
                    this.props.data.month.map((item: any) => {
                        if (item.planType === "basic") {
                            this.setState({
                                planPrice: Number(item.plan_price),
                                subTotal: Number(item.plan_price),
                                total: Number(item.plan_price),
                                isIntervalChange: true
                            });
                        }
                    })
                } else {
                    this.setState({ planList: this.props.data.year });
                    this.props.data.month.map((item: any) => {
                        if (item.planType === "basic") {
                            this.setState({
                                planPrice: Number(item.plan_price),
                                subTotal: Number(item.plan_price),
                                total: Number(item.plan_price),
                                isIntervalChange: true
                            });
                        }
                    })
                }
            }
        }
        if (this.props.selectedAddon) {
            this.setState({
                addons: [...this.state.addons, this.props.selectedAddon]
            }, () => {
                this.setState({
                    subTotal: this.state.subTotal + Number(this.props.selectedAddon.plan_price),
                    total: this.state.total + Number(this.props.selectedAddon.plan_price)
                });
            });
        }
    }
    componentDidUpdate(prevProps: Props, prevState: State) {
        if (this.props.data !== prevProps.data) {
            if (this.state.planType === 'Monthly') {
                this.setState({ planList: this.props.data.month });
                this.props.data.month.map((item: any) => {
                    if (item.planType === "basic") {
                        this.setState({
                            planPrice: Number(item.plan_price),
                            subTotal: Number(item.plan_price),
                            total: Number(item.plan_price)
                        });
                    }
                })
            } else {
                this.setState({ planList: this.props.data.year });
                this.props.data.month.map((item: any) => {
                    if (item.planType === "basic") {
                        this.setState({
                            planPrice: Number(item.plan_price),
                            subTotal: Number(item.plan_price),
                            total: Number(item.plan_price)
                        });
                    }
                })
            }
            if (this.props.subscriptionState !== prevProps.subscriptionState) {
                if (this.props.subscriptionState.error) {
                    toast.error(this.props.subscriptionState.error);
                } else if (this.props.subscriptionState.pending === false && this.props.subscriptionState.createSubscriptionPlanResponse !== prevProps.subscriptionState.createSubscriptionPlanResponse &&
                    this.props.subscriptionState.createSubscriptionPlanResponse && this.props.subscriptionState.createSubscriptionPlanResponse.status.error === false) {
                    this.props.history.push('/user/myplans');
                    toast.success(this.props.subscriptionState.createSubscriptionPlanResponse.status.msg)
                }
            }
        }
    }
    addAddon = (value: any) => {
        this.setState({
            addons: [...this.state.addons, value],
            subTotal: this.state.subTotal + Number(value.plan_price),
            total: this.state.subTotal + Number(value.plan_price)
        });

    }
    removeAddon = (value: any) => {
        let addon = this.state.addons
        let findIndex = this.state.addons.findIndex((item: any) => { return item.id === value.id });
        addon.splice(findIndex, 1);
        this.setState({
            addons: addon,
            subTotal: this.state.subTotal - Number(value.plan_price),
            total: this.state.subTotal - Number(value.plan_price)
        });
    }
    handleProcessAndCheckout = () => {
        let obj: any = {};
        Object.assign(obj, { subTotal: this.state.subTotal, total: this.state.total, selectedPlan: this.props.selectedPlan });
        this.setState({ isCheckOut: true, paymentDetails: obj, isIntervalChange: false });
    }
    createSubscription() {
        // if (this.props.location.data) {
        // let plans = this.props.location.data.plans.map((item: any) => {
        //     return { item_price: item.plan_price_id, plan_id: item.id, plan_type: item.planType }
        // })
        // let data = {
        //     plans: plans,
        //     card_id: this.state.selectedCard
        // };
        // if (this.state.promocodeApplied) {
        //     Object.assign(data, { is_coupon: true, coupon_code: this.state.promocode, discount_amount: this.state.promoCodeDetail.discount_amount });
        // }
        // this.props.createSubscriptionPlan({
        //     clinicUniqueId: this.props.location.data.clinicUniqueId, data: data
        // });
        // }
        let mainPlan: any = {};
        this.state.planList.map((item: any) => {
            if (item.planType === "basic" || item.planType === "standard") {
                mainPlan = item;
            }
        });
        let addon = this.state.addons;
        addon.push(mainPlan);
        let plans = addon.map((item: any) => {
            return { item_price: item.plan_price_id, plan_id: item.id, plan_type: item.planType }
        });
        if (this.state.selectedCard === "") {
            toast.warn('Please select credit card');
            return;
        }
        if (this.state.selectedAddressId === '') {
            toast.warn('Please select Bill Address');
            return;
        }
        let data = {
            plans: plans,
            card_id: this.state.selectedCard,
            billingAddressId: this.state.selectedAddressId
        }
    }
    selectedCard = (Id: any) => {
        this.setState({ selectedCard: Id });
    }
    selectedBillInfo = (Id: any) => {
        this.setState({ selectedAddressId: Id });
    }
    clickMonth = () => {
        this.props.data.month.map((item: any) => {
            if (item.planType === "basic") {
                this.setState({
                    planPrice: Number(item.plan_price),
                    subTotal: Number(item.plan_price),
                    total: Number(item.plan_price),
                    planType: 'Monthly', planList: this.props.data.month,
                    addons: []
                });
            }
        });
    }
    clickYear = () => {
        this.props.data.year.map((item: any) => {
            if (item.planType === "basic") {
                this.setState({
                    planPrice: Number(item.plan_price),
                    subTotal: Number(item.plan_price),
                    total: Number(item.plan_price),
                    planType: 'Annualy', planList: this.props.data.year,
                    addons: []
                });
            }
        });
    }

    billingList = (billingList: any) => {
        if (billingList && billingList.length > 0) {
            billingList.map((item: any) => {
                if (item.default_status === "active") {
                    this.setState({ selectedAddressId: item.id, billingList: billingList });
                }
            })
        }
    }

    render() {
        let planPrice: any;
        return (
            <section className="subscription-sec">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-12 col-md-10 col-xl-8 my-5">
                            <div className="step-wizard">
                                <ul>
                                    <li className="active">
                                        <div id="step1" className="step-box">
                                            <div className="step"><img src={PlanIconProgress} /></div>
                                            <div className="title">Basic + PMS integration</div>
                                        </div>
                                    </li>
                                    <li className="active">
                                        <div id="step2" className="step-box">
                                            <div className="step"><img src={AddonIconProgress} /></div>
                                            <div className="title">Select addons</div>
                                        </div>
                                    </li>
                                    <li className={this.state.isCheckOut ? "active" : ""}>
                                        <div id="step3" className="step-box">
                                            <div className="step"><img src={CreditCardIconProgress} /></div>
                                            <div className="title">Process to checkout</div>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        {!this.state.isCheckOut && this.state.planList.length > 0 &&
                            <div className="col-xl-8 col-xxl-9">
                                {
                                    this.state.planList.map((item: any) => {
                                        if (item.planType === "basic") {
                                            planPrice = item.plan_price;
                                            return (
                                                <div key={item.id} className="border p-4 border-1 rounded bg-light custom-card my-4 default-card">
                                                    <div className="d-flex justify-content-between">
                                                        <div>
                                                            <small className="fw-bolder text-muted">Front Plan</small>
                                                            <div className="info-box fs-5 fw-bolder">Standard Plan</div>
                                                        </div>
                                                        <div className="align-self-center">
                                                            <div className="d-flex">
                                                                <div className="pe-2 price-tag">
                                                                    <span className="fs-6 pe-1 position-relative">$</span>
                                                                    <span className="fw-bolder fs-1">{item.plan_price}</span>
                                                                </div>
                                                                <small className="align-self-center">Per clinic <br />Per {item.plan_interval}</small>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="d-flex justify-content-between mt-4">
                                                        <div className="text-muted small"><img src={InfoIcon} className="me-2" />Choose addons below</div>
                                                        <div className="badge rounded-pill bg-dark px-3 fw-bolder">Save 15% on yearly plan</div>
                                                    </div>
                                                </div>
                                            )
                                        }
                                    })
                                }
                                {
                                    (this.state.planList.map((item: any) => {
                                        let findIndex = this.state.addons.findIndex((value: any) => { return value.id === item.id });
                                        if (item.planType === 'custom')
                                            return (
                                                <div className=" border p-4 border-1 rounded bg-white custom-card">
                                                    <div className="d-flex justify-content-between">
                                                        <div>
                                                            <div className="d-flex mb-2">
                                                                <img className="me-3 align-self-center" src={ContactlessPayments} />
                                                                <div>
                                                                    <small className="fw-bolder text-muted">{item.title}</small>
                                                                    <div className="info-box fs-5 fw-bolder">{item.name}</div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="">
                                                            <div className="d-flex">
                                                                <div className="pe-2 price-tag">
                                                                    <span className="fs-6 pe-1 position-relative">$</span>
                                                                    <span className="fw-bolder fs-1">{item.plan_price}</span>
                                                                </div>
                                                                <small className="align-self-center">Per clinic <br />Per {item.plan_interval}</small>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {/* <p>Contactless billing to handle all invoices in one easy to use dashboard</p> */}
                                                    <p>{item.short_desc}</p>
                                                    <button className="btn btn-link ps-0">Read more details <img className="ms-2" src={RightArrowIcon} /></button>
                                                    <div className="text-end">
                                                        <button disabled={findIndex > -1} className="btn btn-dark" onClick={() => { this.addAddon(item) }}>{findIndex > -1 ? 'Added' : 'Add'}
                                                            {/* <img src={require("./images/spinner-icon.svg")} className="btn-img" /> */}
                                                        </button>
                                                    </div>
                                                </div>
                                            )
                                    }))
                                }
                            </div>}
                        {
                            this.state.isCheckOut &&
                            <CardInfo
                                selectedCard={this.selectedCard}
                                selectedBillInfo={this.selectedBillInfo}
                                billingList={this.billingList}
                            ></CardInfo>
                        }
                        <div className="col-xl-4 col-xxl-3">
                            <div className="sidebar p-4 border border-1 rounded bg-light mt-4 default-card">
                                <ul className="planTab nav nav-tabs rounded-pill border-0 bg-white overflow-hidden" id="myTab" role="tablist">
                                    <li className="nav-item w-50" role="presentation">
                                        <button disabled={!this.state.isIntervalChange} onClick={() => { this.clickMonth() }} className={this.state.planType === 'Monthly' ? "nav-link active w-100 rounded-0 border-0" : "nav-link w-100 rounded-0 border-0"} id="home-tab" data-bs-toggle="tab" data-bs-target="#home" type="button" role="tab" aria-controls="home" aria-selected={this.state.planType === 'Monthly' ? "true" : "false"}>Monthly</button>
                                    </li>
                                    <li className="nav-item w-50" role="presentation">
                                        <button disabled={!this.state.isIntervalChange} onClick={() => { this.clickYear() }} className={this.state.planType === 'Annualy' ? "nav-link active w-100 rounded-0 border-0" : "nav-link w-100 rounded-0 border-0"} id="profile-tab" data-bs-toggle="tab" data-bs-target="#profile" type="button" role="tab" aria-controls="profile" aria-selected={this.state.planType === 'Annualy' ? "true" : "false"}>Annualy</button>
                                    </li>
                                </ul>
                                <div className="text-muted text-center my-3"><i>Save 15% on annualy plan</i></div>
                                <div className="fw-bold text-muted mb-2">Bill summary</div>
                                <div className="tab-content" id="nav-tabContent">
                                    <div className="tab-pane fade show active" id="nav-home" role="tabpanel" aria-labelledby="nav-home-tab">
                                        <ul className="list-group list-group-flush">
                                            {
                                                (this.state.planList) &&
                                                <li className="list-group-item d-flex justify-content-between lh-sm bg-transparent px-0">
                                                    <div>
                                                        <small className="text-muted fw-bold my-1">Front plan</small>
                                                        <h6 className="my-0 fw-bold my-1">Standard Plan</h6>
                                                    </div>
                                                    <span className="text-muted">${this.state.planPrice ? this.state.planPrice : 0}</span>
                                                </li>
                                            }

                                            {
                                                (this.state.addons.length > 0) &&
                                                this.state.addons.map((item: any) => {
                                                    return (
                                                        <li key={item.id} className="list-group-item d-flex justify-content-between lh-sm bg-transparent px-0">
                                                            <div>
                                                                <small className="text-muted fw-bold my-1">Get reviews</small>
                                                                <h6 className="my-0 fw-bold my-1">{item.name}</h6>
                                                            </div>
                                                            <div className="text-end">
                                                                <div className="text-muted">${item.plan_price}</div>
                                                                <img onClick={() => { this.removeAddon(item) }} src={NotIncluderIcon} alt="not included" />
                                                            </div>
                                                        </li>
                                                    )
                                                })
                                            }
                                            <li className="list-group-item d-flex justify-content-between lh-sm bg-transparent px-0">
                                                <div>
                                                    <h6 className="my-0 my-1">Sub total</h6>
                                                    <h6 className="my-0 my-1">Coupon code</h6>
                                                </div>
                                                <div>
                                                    <span className="d-block my-1">${this.state.subTotal}</span>
                                                    <span className="d-block my-1">- ${this.state.coupenPrice}</span>
                                                </div>
                                            </li>
                                            <li className="list-group-item d-flex justify-content-between bg-transparent px-0 border-0">
                                                <div className="align-self-end fw-bolder ">Total per month</div>
                                                <div className="text-end">
                                                    <i className="d-block text-muted">Tax included</i>
                                                    <strong className="fs-5">${this.state.total}</strong>
                                                </div>
                                            </li>
                                            <li className="list-group-item  bg-light px-0">
                                                <label>Do you have coupon code ?</label>
                                                <div className="input-group my-2">
                                                    <input type="text" className="form-control border-end-0" placeholder="" value="FLAT10" />
                                                    <button className="btn bg-white border fw-bolder border-start-0" type="button" id="button-addon2"><small>APPLY</small></button>
                                                </div>
                                                <div className="text-success">Success! You've done it.</div>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                                {
                                    (this.state.isCheckOut) ?
                                        <button disabled={this.state.total <= 0} className="btn btn-primary w-100 fw-bold mt-4"
                                            // onClick={() => { this.props.history.push('/user/overview') }}
                                            onClick={() => { this.createSubscription() }}
                                        >{'Process to pay'} <img src={SpinnerIcon} className="btn-img" /></button>
                                        :
                                        <button disabled={this.state.total <= 0} className="btn btn-primary w-100 fw-bold mt-4" onClick={() => { this.handleProcessAndCheckout() }}>{'Process to checkout'} <img src={SpinnerIcon} className="btn-img" /></button>

                                }
                                {/* <button disabled={this.state.total<=0} className="btn btn-primary w-100 fw-bold mt-4" onClick={()=>{this.handleProcessAndCheckout()}}>{this.state.isCheckOut ?'Process to pay':'Process to checkout'} <img src={require("./images/spinner-icon.svg")} className="btn-img" /></button> */}
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        )
    }
}
const mapStateToProps = (state: IAppState) => ({
    subscriptionState: state.subscriptionPlan,
});
const mapDispatchToProps = (dispatch: any) => ({
    createSubscriptionPlan: (request: CreateSubscriptionPlanRequest) => {
        dispatch(createSubscriptionPlan(request));
    },
});
export default connect(mapStateToProps, mapDispatchToProps)(GetPlan);