import React from 'react';
import { Route, Switch } from 'react-router';
import { PaymentHeader } from '../../components/messageCenter/PaymentHeader';
import { RecurringPaymentNotification } from './RecurringPaymentNotification';
import { AdministrativePatients } from './AdministrativePatients';
import { PaymentPatients } from './PaymentPatients';
import { PaymentNotification } from './PaymentNotification';
import { PaymentReminder } from './PaymentReminder';
export const Payment = () => {
    return (
        <>
            <PaymentHeader></PaymentHeader>
            <Switch>
                <Route exact path="/message-center/payment" component={PaymentNotification}></Route>
                <Route exact path="/message-center/payment/reminder" component={PaymentReminder}></Route>
                <Route exact path="/message-center/payment/patients" component={PaymentPatients}></Route>
            </Switch>
        </>
    );
}