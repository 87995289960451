import React from 'react';
import { Modal } from 'react-bootstrap';
import CONSTANT_LABELS from '../../../constants/labels';
import { removeCountryCode } from '../../../utils/global';

export const PatientDetailModel = ({ hideModal, appointment }: any) => {
    {
        console.log(appointment)
    }
    return (
        <Modal show={true} onHide={() => { hideModal() }} className="newcommon editappt-popup" centered>
            <Modal.Header>
                <div className="modal-title text-capitalize">{appointment.dependant.fname + ' ' + appointment.dependant.lname}</div>
                <button type="button" className="btn-close" aria-label="Close" onClick={() => { hideModal() }} >
                </button>
            </Modal.Header>
            <Modal.Body>
                <div className="row g-3">
                    <div className='col-6'>
                        <label>Patient email</label>
                        <div className="d-block text-muted charlmt">{appointment.dependant.email}</div>
                    </div>
                    <div className='col-6'>
                        <label>Patient mobile#</label>
                        <div className="d-block text-muted charlmt">{(CONSTANT_LABELS.PLUS_ONE+removeCountryCode(appointment.dependant.phone))}</div>
                    </div>
                    <div className='col-6'>
                        <label>Provider name</label>
                        <div className="d-block text-muted text-capitalize">{appointment.doctor.fullname}</div>
                    </div>
                    <div className='col-6'>
                        <label>Current status</label>
                        <div className="d-block text-muted text-capitalize">{appointment.currentStatus}</div>
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>

            </Modal.Footer>
        </Modal>
    )
}