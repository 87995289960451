import React from 'react';
import queryString from 'query-string';
import { connect } from 'react-redux';
import { IAppState } from '../../store';
import { StripeSaveAccountIdRequest, PaymentState, FetchInitPaymentRequest } from '../../store/payment/types';
import { stripeSaveAccountIdPending, fetchInitPaymentPending } from '../../store/payment/actions';
import configs from '../../configs/apiConfigs';
import { toast } from 'react-toastify';
import { getCookie } from '../../utils/cookies';
import './contactless.scss';
import { AdminFee } from '../../v2/components/payments/AdminFee';
import { Tax } from '../../v2/components/payments/Tax';
import { UserDataRequest } from '../../store/userData/types';
import { fetchUserDataPending } from '../../store/userData/actions';
import StripeConnectIcon from '../../assets/images/stripe-icon.svg';
import CardListImg from '../../assets/images/card-list-img.svg';
interface Props {
    location: any;
    savestripeAccountId: typeof stripeSaveAccountIdPending;
    paymentState: PaymentState;
    fetchInitPayment: typeof fetchInitPaymentPending;
    userData: typeof fetchUserDataPending;
}
interface State { }
class StripeConnect extends React.Component<Props, State> {
    constructor(props: any) {
        super(props);
    }
    componentDidMount() {
        const parsedURL = queryString.parse(this.props.location.search);
        if (parsedURL.code && getCookie().xpr_user_id) {
            this.props.savestripeAccountId({
                uniqueId: getCookie().xpr_user_id,
                requestData: { code: parsedURL.code }
            });
        }
    }
    shouldComponentUpdate(nextProps: Props, nextState: State) {
        if (this.props.paymentState.stripeSaveAccountIdResponse !== nextProps.paymentState.stripeSaveAccountIdResponse &&
            nextProps.paymentState.stripeSaveAccountIdResponse?.status.error === false) {
            toast.success(nextProps.paymentState.stripeSaveAccountIdResponse?.status.msg);
            this.props.fetchInitPayment({});
            this.props.userData({});
        }
        return true;
    }
    render() {
        return (
            <>
                <div className="contactless-sec px-3">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-xl-12 col-xxl-9 my-4">
                                {
                                    (this.props.paymentState.fetchInitPaymentResponse && this.props.paymentState.fetchInitPaymentResponse.stripeAccount
                                        && this.props.paymentState.fetchInitPaymentResponse.stripeAccount.accountID) &&
                                    <>
                                        <div className="card bg-light rounded my-4 p-4">
                                            <div className="d-flex justify-content-between">
                                                <div className="d-flex">
                                                    <img src={StripeConnectIcon} />
                                                    <div className="ms-3 align-self-center w-100">
                                                        <div className="fw-medium  mb-1">Connect with stripe <span className="badge rounded-pill bg-success">Connected</span></div>
                                                        <small className="d-block text-muted">Connect your stripe standard account to take payments through stripe.</small>
                                                    </div>
                                                </div>
                                                <div className="align-self-center">
                                                    <a className="btn btn-outline-danger" href="https://dashboard.stripe.com/register" target="_blank">Disconnect stripe</a>
                                                </div>
                                            </div>
                                            <hr className="my-4 d-none" />

                                            <div className="d-none">
                                                <h5 className="fw-medium mb-1">Connected accounts</h5>
                                                <div className="d-flex justify-content-between">
                                                    <div>
                                                        <small className="text-muted">Name</small>
                                                        <div>Desired smiles</div>
                                                    </div>
                                                    <div>
                                                        <small className="text-muted">Email</small>
                                                        <div>payments@desiredsmiles.com</div>
                                                    </div>
                                                    <div>
                                                        <small className="text-muted">Connected on</small>
                                                        <div>20 January, 2020</div>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </>
                                }

                                {
                                    (!(this.props.paymentState.fetchInitPaymentResponse && this.props.paymentState.fetchInitPaymentResponse.stripeAccount
                                        && this.props.paymentState.fetchInitPaymentResponse.stripeAccount.accountID)) &&
                                    <>
                                        <div className="card bg-light rounded my-4 p-4">
                                            <div className="d-flex justify-content-between">
                                                <div className="d-flex">
                                                    <img src={StripeConnectIcon} />
                                                    <div className="ms-3 align-self-center w-100">
                                                        <div className="fw-medium  mb-1">Connect with stripe</div>
                                                        <small className="d-block text-muted">Connect your stripe standard account to take payments through stripe.</small>
                                                    </div>
                                                </div>
                                                <div className="align-self-center">
                                                    <a className="btn btn-primary" href={"https://connect.stripe.com/oauth/authorize?redirect_uri=" + window.location.href + "&response_type=code&client_id=" + configs.STRIPE_CLIENT_ID + "&scope=read_write"}>Connect with stripe</a>
                                                </div>
                                            </div>
                                            <hr className="my-4" />
                                            <div className="d-flex justify-content-between">
                                                <div>
                                                    <ul className="cashless-features">
                                                        <li><i className="text-success bi bi-check-circle"></i> Save time and get paid securely</li>
                                                        <li><i className="text-success bi bi-check-circle"></i> Allows your client to pay invoices with credit cards</li>
                                                        <li><i className="text-success bi bi-check-circle"></i> No setup fees, monthly fees, or hidden fees.</li>
                                                        <li><i className="text-success bi bi-check-circle"></i> 3% per successful card charges</li>
                                                    </ul>
                                                    <a className="btn btn-outline-secondary me-2" href="https://dashboard.stripe.com/register" target="_blank">Sign Up</a>
                                                </div>
                                                <div>
                                                    <img src={CardListImg} />
                                                </div>

                                            </div>
                                        </div>
                                    </>
                                }


                                <hr className="my-4" />
                                <div className="small text-muted">Invoice Customization</div>
                                <AdminFee></AdminFee>
                                <Tax></Tax>
                                <div className="card bg-light rounded my-4 p-4 d-none">
                                    <div className="d-flex justify-content-between">
                                        <div>
                                            <div className="fw-medium  mb-1">Discount</div>
                                            <small className="text-muted"><i className="bi bi-info-circle me-1"></i>You can select admin and safety fees from invoice creation</small>
                                        </div>
                                        <div className="form-check form-switch">
                                            <input className="form-check-input m-0" type="checkbox" id="flexSwitchCheckDefault" />
                                        </div>
                                    </div>
                                </div>
                                <div className="card bg-light rounded my-4 p-4 d-none">
                                    <div className="d-flex justify-content-between">
                                        <div>
                                            <div className="fw-medium  mb-1">Pay with EMI</div>
                                            <small className="text-muted"><i className="bi bi-info-circle me-1"></i>You can select admin and safety fees from invoice creation</small>
                                        </div>
                                        <div className="form-check form-switch">
                                            <input className="form-check-input m-0" type="checkbox" id="flexSwitchCheckDefault" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}
const mapStateToProps = (state: IAppState) => ({
    paymentState: state.payment
});
const mapDispatchToProps = (dispatch: any) => ({
    savestripeAccountId: (request: StripeSaveAccountIdRequest) => {
        dispatch(stripeSaveAccountIdPending(request));
    },
    fetchInitPayment: (request: FetchInitPaymentRequest) => {
        dispatch(fetchInitPaymentPending(request));
    },
    userData: (userData: UserDataRequest) => {
        dispatch(fetchUserDataPending(userData));
    }
});
export default connect(mapDispatchToProps, mapDispatchToProps)(StripeConnect);